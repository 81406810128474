/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { s } from '../../../style'

const Bullets = ({ array }) =>
  array.map((e, id) => (
    <React.Fragment key={id}>
      {id > 0 && (
        <React.Fragment>
          <span css={{ margin: '0 0.25rem', [s.sm]: { margin: '0 0.5rem' } }}>
            &bull;
          </span>
        </React.Fragment>
      )}
      {e}
    </React.Fragment>
  ))

export default Bullets
