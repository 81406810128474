/** @jsx jsx */
import React, { useContext } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import _ from 'lodash'
import { useGetUserNotifications } from '../../../hooks/useUser.queries'
import { useReadNotification } from '../../../hooks/useUser.mutations'
import { useError } from '../../../../hooks/errors.hook'
import Headline from '../../../../components/Headline/Headline'
import { sOuter, sGrid } from '../../../../style/style'
import { ButtonBorder } from '../../../../components/Elements'
import { Grid } from '@material-ui/core'
import { TopNav } from '../../../../components/TopNavFixed/TopNavFixed'
import SkeletonOrder from '../../../../components/SkeletonOrder/SkeletonOrder'
import Notification from './Notification'

const NotificationsPage = () => {
  return (
    <React.Fragment>
      <TopNav title={'Powiadomienia'} />
      <div css={sOuter}>
        <Headline
          itemChildren={
            <ButtonBorder link={`/orders`}>Zamówienia</ButtonBorder>
          }>
          Powiadomienia
        </Headline>
        <Notifications />
      </div>
    </React.Fragment>
  )
}

const Notifications = () => {
  const { errorHandling } = useError()
  const { onReadNotification } = useReadNotification()
  const { data, loading, error } = useGetUserNotifications()
  const onRead = (notifId) => onReadNotification({ notifId })

  if (loading)
    return (
      <Grid container css={sGrid} justify='space-between'>
        <SkeletonOrder />
        <SkeletonOrder />
        <SkeletonOrder />
        <SkeletonOrder />
      </Grid>
    )

  if (error) {
    errorHandling(error)
    return null
  }

  // console.log(data.ezcNotifications)

  const notificationsMerged = _(data.ezcNotifications)
    .groupBy('orderId')
    .map((objs, key) => {
      return {
        newest: objs[0],
        notifications: objs,
      }
    })
    .sortBy(['createdAt'])
    .value()
    .filter((notif) => notif.newest.orderId)

  const notificationsNoOrder = data.ezcNotifications
    .filter((notif) => !notif.orderId)
    .map((notif) => ({ newest: notif, notifications: [notif] }))

  return (
    <Grid container css={[sGrid]} justify='space-between'>
      {[...notificationsMerged, ...notificationsNoOrder].map(
        (notification, id) => (
          <Notification
            id={id}
            key={id}
            data={notification}
            onRead={() => onRead(notification.newest._id)}
          />
        )
      )}
    </Grid>
  )
}

export default NotificationsPage
