import React from 'react'

import AutocompleteInput from '../../../components/autocompletes/AutocompleteInput'
import { useProductContext } from '../context/product.provider'
import { cookMethods as cookMethodTypes } from '../../../../constants/types'


export default () => {
  const {
    cookMethod,
    handleCookMethod
  } = useProductContext()

  return (
    <AutocompleteInput
      language="pl"
      options={cookMethodTypes}
      label="Przyrządzenie"
      placeholder="Przyrządzenie"
      freeSolo={false}
      stateArr={cookMethod}
      setStateArr={handleCookMethod}
    />
  )
}