import React, { createContext, useContext, useState, useEffect, useCallback } from 'react'


function noop() { };
export const ActiveRestaurantContext = createContext({
  activeRestaurantId: '',
  handleActiveRestaurantId: noop
})

export const useActiveRestaurantContext = () => useContext(ActiveRestaurantContext)



export default ({ children }) => {
  const [activeRestaurantId, setActiveRestaurantId] = useState(null)

  const handleActiveRestaurantId = useCallback((_id) => {
    localStorage.setItem('activeRestaurant', _id)
    setActiveRestaurantId(_id)
  }, [])

  useEffect(() => {
    const restId = localStorage.getItem('activeRestaurant')
    if (restId) handleActiveRestaurantId(restId)
  }, [])

  return (
    <ActiveRestaurantContext.Provider value={{ activeRestaurantId, handleActiveRestaurantId }}>
      {children}
    </ActiveRestaurantContext.Provider>
  )
}