/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import SearchInput from '../../../components/SearchInput/SearchInput'
import { useProductsContext } from '../context/products.provider'


export default () => {
  const {
    searchString,
    onSearchString
  } = useProductsContext()

  return <SearchInput searchString={searchString} onChange={onSearchString} />
}