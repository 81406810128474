/** @jsx jsx */
import React, { useEffect, useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { s, colors } from '../../../../style'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { Collapsable, Bullet } from '../../../../components/Elements'
import { numberOfDayJS, daysOfWeek } from '../../../../constants/daysOfWeek'

const Availability = ({ hours, type, isRestActive, isProdArchived }) => {
  const now = new Date()
  const day = now.getDay()
  const todaysDay = numberOfDayJS[day]
  const tomorrowsDay = numberOfDayJS[(day + 1) % 7]
  const nowTime = now.getHours() * 60 + now.getMinutes()

  return (
    <Collapsable
      left
      noPadding
      oneLiner
      title={'Dostępność'}
      description={
        <RenderAvailableHoursDetails
          tomorrow={hours[tomorrowsDay]}
          hours={hours[todaysDay]}
          now={nowTime}
          type={type}
          isRestActive={isRestActive}
          isProdArchived={isProdArchived}
        />
      }
      icon={<AccessTimeIcon />}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '1rem 0',
          [s.sm]: {
            padding: '0 0 1.5rem',
          },
        }}>
        {isRestActive ? (
          isProdArchived ? (
            <span
              css={{
                fontSize: '0.75rem',
                fontWeight: 800,
                textAlign: 'center',
                padding: '1rem 1rem 0rem',
                [s.xs]: { padding: '0 1rem' },
              }}>
              Ten produkt został zarchiwizowany
            </span>
          ) : (
            Object.entries(hours)
              .filter((key, id) => id > 0)
              .map(([day, hour], id) => (
                <DayRow
                  key={id}
                  day={day}
                  hour={hour}
                  todaysDay={todaysDay}
                  type={type}
                />
              ))
          )
        ) : (
          <span
            css={{
              fontSize: '0.75rem',
              fontWeight: 800,
              textAlign: 'center',
              padding: '1rem 1rem 0rem',
              [s.xs]: { padding: '0 1rem' },
            }}>
            Restauracja została tymczasowo wyłączona
          </span>
        )}
      </div>
    </Collapsable>
  )
}

export const minToHour = (min) => {
  const m = min % 60
  const h = (min - m) / 60
  return (
    (h < 10 ? '0' : '') +
    h.toString() +
    ':' +
    (m < 10 ? '0' : '') +
    m.toString()
  )
}

export const hourOrMin = (min) =>
  min / 60 >= 1 ? Math.round(min / 60) + ' h' : min + ' min'

export const RenderAvailableHoursDetails = ({
  hours,
  now,
  tomorrow,
  type,
  isRestActive,
  isProdArchived,
}) => {
  const [openState, setOpenState] = useState(null) // SOON, OPEN, CLOSED
  const SOON = 'SOON'
  const OPEN = 'OPEN'
  const CLOSED = 'CLOSED'
  const INACTIVE = 'INACTIVE'
  const ARCHIVED = 'ARCHIVED'

  useEffect(() => {
    if (now < hours.open) setOpenState(SOON)
    if (now >= hours.open && now < hours.close && hours.close > 0)
      setOpenState(OPEN)
    if (now >= hours.close || hours.close === 0) setOpenState(CLOSED)
    if (isProdArchived) setOpenState(ARCHIVED)
    if (!isRestActive) setOpenState(INACTIVE)
  }, [hours.open, hours.close, now])

  if (openState === INACTIVE)
    return (
      <div>
        <span css={{ color: 'red' }}>Restauracja nieaktywna</span>
      </div>
    )

  if (openState === ARCHIVED)
    return (
      <div>
        <span css={{ color: 'red' }}>Produkt zarchiwizowany</span>
      </div>
    )

  return (
    <div css={{ overflowX: 'scroll' }}>
      {openState === SOON && <span css={{ color: 'orange' }}>Wkrótce</span>}
      {openState === OPEN && (
        <span css={{ color: 'green' }}>
          {type === 'product' && <>Dostępny</>}
          {type === 'restaurant' && <>Otwarta</>}
          {type === 'delivery' && <>Możliwa</>}
        </span>
      )}
      {openState === CLOSED && (
        <span css={{ color: 'red' }}>
          {type === 'product' && <>Niedostępny</>}
          {type === 'restaurant' && <>Zamknięta</>}
          {type === 'delivery' && <>Niemożliwa</>}
        </span>
      )}
      {/* {tomorrow.close > 0 && <Bullet />} */}
      <Bullet />
      {openState === SOON && <span>od {minToHour(hours.open)}</span>}
      {openState === OPEN && <span>do {minToHour(hours.close)}</span>}
      {openState === CLOSED && tomorrow.close > 0 && (
        <span>do {minToHour(tomorrow.open)}</span>
      )}

      {/* {tomorrow.close > 0 && <Bullet />} */}
      <Bullet />
      {openState === SOON && <span>za {hourOrMin(hours.open - now)}</span>}
      {openState === OPEN && (
        <span>jeszcze {hourOrMin(hours.close - now)}</span>
      )}
      {openState === CLOSED && tomorrow.close > 0 && (
        <span>za {hourOrMin(tomorrow.open + 24 * 60 - now)}</span>
      )}
    </div>
  )
}

export const DayRow = ({ day, hour, todaysDay, type }) => {
  return (
    <div
      css={[
        {
          transition: 'opacity 0.3s ease-out, background-color 0.3s ease-out',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '1rem 1.5rem',
          fontWeight: 800,
          [s.sm]: {
            padding: '1rem 2rem',
          },
          color: colors.text,
          fontSize: '0.875rem',
          opacity: 0.3,
          '&:hover': {
            opacity: 1,
            // backgroundColor: colors.gray300,
          },
        },
        todaysDay === day && {
          backgroundColor: colors.gray300,
          opacity: 1,
          '&:hover': {
            opacity: 1,
            backgroundColor: colors.gray400,
          },
        },
      ]}>
      <div css={{ fontWeight: 800 }}>{daysOfWeek[day]}</div>
      {hour.open === 0 && hour.close === 1440 ? (
        <div css={{ color: 'green' }}>
          {type === 'product' && <>Całodobowy</>}
          {type === 'restaurant' && <>Całodobowa</>}
          {type === 'delivery' && <>Całodobowa</>}
        </div>
      ) : hour.open === 0 && hour.close === 0 ? (
        <div css={{ color: 'red' }}>
          {type === 'product' && <>Niedostępny</>}
          {type === 'restaurant' && <>Zamknięta</>}
          {type === 'delivery' && <>Niemożliwa</>}
        </div>
      ) : (
        <div>
          {minToHour(hour.open)}
          <span css={{ margin: '0 0.5rem' }}>&bull;</span>
          {minToHour(hour.close)}
        </div>
      )}
    </div>
  )
}

export default Availability
