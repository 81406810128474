import { useState } from 'react'
import { toast } from 'react-toastify'
import { initOpenningHours } from './useOpenningHours'

export default () => {
  const [deliverySettings, setDeliverySettings] = useState(initDeliverySettings)

  const handleDeliverySettings = (key) => (e, availableHoursDay) => {
    let isError = null

    switch (key) {
      case 'deliveryRadius':
        isError = checkDistance(e)
        if (isError) break

        setDeliverySettings({
          ...deliverySettings,
          deliveryRadius: parseFloat(e.target.value),
        })
        break

      case 'minAmount':
        isError = checkAmount(e)
        if (isError) break

        setDeliverySettings({
          ...deliverySettings,
          minAmount: e.target.value,
        })
        break

      case 'availableHours':
        setDeliverySettings({
          ...deliverySettings,
          availableHours: {
            ...deliverySettings.availableHours,
            [e.target.name]: {
              ...deliverySettings.availableHours[e.target.name],
              [availableHoursDay]: parseInt(e.target.value, 10),
            },
          },
        })
        break

      case 'freeDeliveryAmountCheckbox':
        deliverySettings.freeDeliveryAmount === null
          ? setDeliverySettings({ ...deliverySettings, freeDeliveryAmount: 0 })
          : setDeliverySettings({
              ...deliverySettings,
              freeDeliveryAmount: null,
            })
        break

      case 'freeDeliveryAmount':
        isError = checkAmount(e)
        if (isError) break

        deliverySettings.freeDeliveryAmount !== null &&
          setDeliverySettings({
            ...deliverySettings,
            freeDeliveryAmount: e.target.value,
          })
        break

      default:
        throw new Error('Unknown delivery settings type')
    }
  }

  const onAddDeliveryCost = () => {
    if (deliverySettings.deliveryCost.length > 10) {
      toast.info(
        'Maksymalna liczba przedziałów wynosi 10. ' +
          'Skontaktuj się z nami, jeżeli uważasz, że to za mało'
      )
      return
    }
    setDeliverySettings({
      ...deliverySettings,
      deliveryCost: [
        ...deliverySettings.deliveryCost,
        [deliverySettings.deliveryRadius, 0],
      ],
    })
  }

  const onDeleteDeliveryCost = (index) => {
    setDeliverySettings({
      ...deliverySettings,
      deliveryCost: deliverySettings.deliveryCost.filter((_, i) => i !== index),
    })
  }

  const handleDeliveryCost = (type, index) => (e) => {
    let isError

    if (type === 'distance') {
      isError = checkDistance(e)
      if (isError) return

      setDeliverySettings({
        ...deliverySettings,
        deliveryCost: deliverySettings.deliveryCost.map(
          ([distance, amount], i) =>
            i === index
              ? [parseFloat(e.target.value), amount]
              : [distance, amount]
        ),
      })
    } else if (type === 'amount') {
      isError = checkAmount(e)
      if (isError) return

      setDeliverySettings({
        ...deliverySettings,
        deliveryCost: deliverySettings.deliveryCost.map(
          ([distance, amount], i) =>
            i === index ? [distance, e.target.value] : [distance, amount]
        ),
      })
    }
  }

  return {
    deliverySettings,
    setDeliverySettings,
    handleDeliverySettings,
    onDeleteDeliveryCost,
    onAddDeliveryCost,
    handleDeliveryCost,
  }
}

export const initDeliverySettings = {
  availableHours: initOpenningHours,
  deliveryCost: [[500, 0]],
  minAmount: 0,
  deliveryRadius: 500,
  freeDeliveryAmount: null,
}

// helper functions
function checkDistance(e) {
  if (isNaN(e.target.value)) return true
  if (!e.target.value) e.target.value = 0
}

/**
 * Returns true if we don't want to continue
 * function execution.
 * Or change the value.
 *
 * @param {Object} event
 */
function checkAmount(e) {
  e.target.value = e.target.value.replace(',', '.')

  if (isNaN(e.target.value)) return true

  // if decimals more then 2 - do nothing.
  if (e.target.value.split('.')[1] && e.target.value.split('.')[1].length > 2)
    return true

  // if number begins from 0 - do nothing.
  if (
    e.target.value.length > 1 &&
    e.target.value.charAt(0) === '0' &&
    e.target.value.charAt(1) !== '.'
  )
    e.target.value = e.target.value.replace('0', '')

  // if no value - make 0
  if (!e.target.value) e.target.value = 0
}
