/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { ButtonBorder } from '../../../components/Elements'
import Headline from '../../../components/Headline/Headline'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import { sOuter } from '../../../style/style'

const ModifiersPage = () => {
  return (
    <React.Fragment>
      <TopNav title={'Modyfikacje'} offset={60} />
      <div css={sOuter}>
        <Headline
        // itemChildren={<ButtonBorder>Przycisk</ButtonBorder>}
        >
          Modyfikacje
        </Headline>
      </div>
    </React.Fragment>
  )
}

export default ModifiersPage
