import { useMutation } from '@apollo/client'
import { useError } from '../../hooks/errors.hook'

import {
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT
} from '../graphql/mutations.graphql'

import {
  GET_PRODUCT,
  GET_PRODUCTS,
  GET_INGREDIENTS,
  GET_CATEGORIES
} from '../graphql/products.graphql'

import { useUploadImg } from '../../hooks/useUploadImg.hook'


const useProdMutations = (props = {}) => {
  const { prodId } = props
  const restId = localStorage.getItem('activeRestaurant')

  const { errorHandling } = useError()
  const { uploadImgAndGetURL } = useUploadImg()

  const [restAddProduct] = useMutation(
    ADD_PRODUCT,
    {
      refetchQueries: [
        { query: GET_PRODUCTS, variables: { restId } },
        { query: GET_INGREDIENTS },
        { query: GET_CATEGORIES }
      ]
    }
  )
  const [restUpdateProduct] = useMutation(
    UPDATE_PRODUCT,
    {
      refetchQueries: [
        { query: GET_PRODUCTS, variables: { restId } },
        { query: GET_INGREDIENTS },
        { query: GET_CATEGORIES }
      ]
    }
  )
  const [restDeleteProduct] = useMutation(
    DELETE_PRODUCT,
    { refetchQueries: [{ query: GET_PRODUCTS, variables: { restId } }] }
  )


  // AddProd
  async function handleAddProd(data, cb, errCb) {
    const { restId, input, categories, ingredients, imgFile } = data

    try {
      const s3url = await uploadImgAndGetURL(imgFile, 'PROD_AVATAR')

      await restAddProduct({
        variables: {
          restId,
          input,
          categories,
          ingredients,
          imgS3Path: s3url ? s3url.data.getS3ImgUrlLink.imgName : null
        }
      })

      if (cb && typeof cb === 'function') cb()

    } catch (err) {
      errorHandling(err)
      if (typeof errCb === 'function') errCb()
    }
  }


  // UpdateProd
  async function handleUpdateProd(data, cb, errCb) {
    const { prodId, input, categories, ingredients, imgFile } = data

    try {
      const s3url = await uploadImgAndGetURL(imgFile, 'PROD_AVATAR')

      await restUpdateProduct({
        variables: {
          prodId,
          input,
          categories,
          ingredients,
          imgS3Path: s3url ? s3url.data.getS3ImgUrlLink.imgName : null
        }
      })

      if (cb && typeof cb === 'function') cb()

    } catch (err) {
      errorHandling(err)
      if (typeof errCb === 'function') errCb()
    }
  }

  // archive product
  async function handleArchiveProd(data, cb, errCb) {
    const { prodId, isArchived } = data

    try {
      await restUpdateProduct({
        variables: {
          prodId,
          input: { isArchived },
        },
        refetchQueries: prodId
          ? [{ query: GET_PRODUCT, variables: { prodId } }]
          : undefined
      })

      if (cb && typeof cb === 'function') cb()

    } catch (err) {
      errorHandling(err)
      if (typeof errCb === 'function') errCb()
    }
  }

  // delete product
  async function handleDeleteProd(data, cb, errCb) {
    const { prodId } = data

    try {
      await restDeleteProduct({ variables: { prodId } })

      if (cb && typeof cb === 'function') cb()

    } catch (err) {
      errorHandling(err)
      if (typeof errCb === 'function') errCb()
    }
  }

  return {
    handleAddProd,
    handleUpdateProd,
    handleDeleteProd,
    handleArchiveProd
  }
}

export default useProdMutations