/** @jsx jsx */
import React, { useContext, useEffect, useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useParams, useHistory } from 'react-router-dom'

import { useError } from '../../../hooks/errors.hook'
import { useOrderInCartByRestaurant } from '../../context/OrderInCartByRestaurant/OrderInCartByRestaurant.provider'

// Components
// import Spinner from '../../../components/Spinner'
import PageLoader from '../../../components/PageLoader/PageLoader'
// import Layout from '../../components/Layout'
// import { ProdRestContainer } from '../../../components/Containers'
import OrderInfo from '../../components/BottomOrderInfo/BottomOrderInfo.component'

// Views
// import AboutProductView from '../../views/ProductPage/AboutProduct.view'
import { AuthContext } from '../../../context/auth.context'
import { useGetProduct } from '../../hooks/useProds.queries'
import MoneyEz from '../../../helpers/MoneyEz'

import { s, colors, globals, alpha } from '../../../style'

// import useDeliveryInfo from '../../hooks/useDeliveryInfo'

import { ButtonBase, Grid, Hidden } from '@material-ui/core'
import BackgroundBlur from '../../views/ProductPage/components/BackgroundBlur'
import Intro from '../../views/ProductPage/components/Intro'
import Location from '../../views/ProductPage/components/Location'
import Availability from '../../views/ProductPage/components/Availability'
import Delivery from '../../views/ProductPage/components/Delivery'
import ProductDetails from '../../views/ProductPage/components/ProductDetails'
import InActivity from '../../views/ProductPage/components/InActivity'

import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

import Carousel from '../../components/Carousel/Carousel.component'
import { ProdItemMini } from '../../components/ProdItem'
import RestContainer from '../../views/ProductPage/components/RestContainer'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import ConsumptionTypes from '../../views/ProductPage/components/ConsumptionTypes'

const Product = () => {
  const { errorHandling } = useError()
  const prodId = useParams().id
  const history = useHistory()

  const { isAuth } = useContext(AuthContext)
  const { data, loading, error } = useGetProduct(prodId)
  const {
    order,
    loadingOrders,
    errorOrders,
    setRestId,
    handleAddProd,
    handleDeleteProduct,
  } = useOrderInCartByRestaurant()

  useEffect(() => {
    if (data) setRestId(data.ezcProduct.restaurant._id)
  }, [data])

  if (loading || loadingOrders) return <PageLoader />

  if (error || errorOrders) {
    errorHandling(error || errorOrders)
    return null
  }
  if (!data) return <React.Fragment>Coś poszło nie tak...</React.Fragment>

  const { ezcProduct } = data

  // console.log(ezcProduct)

  const {
    photoUrl,
    name,
    price,
    categories,
    description,
    restaurant,
    availableHours,
    cookTime,
    cookMethod,
    flavors,
    ingredients,
    isArchived,
    consumptionTypes,
  } = ezcProduct

  if (!ezcProduct) {
    history.push('/404')
    return null
  }

  let noAuthRestData
  let noAuthProdData
  if (!isAuth) {
    noAuthRestData = {
      _id: ezcProduct.restaurant._id,
      name: ezcProduct.restaurant.name,
      avatarUrl: ezcProduct.restaurant.avatarUrl,
      address: ezcProduct.restaurant.address,
    }
    noAuthProdData = {
      _id: prodId,
      price: ezcProduct.price,
      name: ezcProduct.name,
      photoUrl: ezcProduct.photoUrl,
    }
  }

  const productInCart =
    order &&
    order.orderDetails[0].productsInCart.find(
      (cartProductObj) => cartProductObj.prodId._id === prodId
    )

  const products = order && order.orderDetails[0].productsInCart
  let sum = '0,00zł'
  if (products) {
    sum = MoneyEz(0)
      .sumProductArray(
        products.map(({ amount, prodId: { price } }) => ({ amount, price }))
      )
      .getAmountWithFullPrecisionAndZl()
  }

  const categoriesOrder = [
    'POPULAR',
    'MEALTYPE',
    'CUISINE',
    'DIET',
    'DAYTIME',
    'OTHER',
  ]
  const categoriesShow2 = categoriesOrder
    .map((order) => categories.filter(({ type }) => type === order))
    .flat()
    .map((e) => e.name || e)

  return (
    // <Layout darknav navNoBRadius>
    <React.Fragment>
      <TopNav
        title={name}
        subtitle={
          <>
            {/* <Hidden smDown> */}
            &nbsp;- {restaurant?.name}
            {/* </Hidden> */}
          </>
        }
        textCss={{ maxWidth: 'calc(100vw - 14rem)' }}
        photo={photoUrl}></TopNav>

      <BackgroundBlur title={name} photo={photoUrl} />

      {/* Intro */}
      <Intro
        title={name}
        photo={photoUrl}
        photoAvatar={photoUrl}
        categories={categoriesShow2}
        restAvatar={restaurant?.avatarUrl}
        description={description}>
        {isArchived ? (
          <InActivity>Produkt został zarchiwizowany</InActivity>
        ) : (
          <div
            css={{
              transition:
                'background-color 0.3s ease-out, transform 0.3s ease-out, box-shadow 0.3s ease-out',
              display: 'flex',
              borderRadius: '2rem',
              height: 48,
              minWidth: 300,
              position: 'relative',
              backgroundColor: colors.text,
              width: 'max-content',
              color: '#ffffff',
              fontSize: '1rem',
              fontWeight: 800,
              letterSpacing: '0.05em',
              [s.sss]: {
                minWidth: 272,
                maxWidth: 272,
              },
              [s.sm_down]: { width: '100%' },
              '&:hover': {
                [s.hover]: { backgroundColor: colors.green800 },
              },
            }}>
            <ButtonBase
              onClick={(e) => {
                if (!productInCart || productInCart.amount === 0)
                  handleAddProd(e, prodId, noAuthProdData, noAuthRestData)
              }}
              css={[
                sCartButtonNoItemButton,
                productInCart?.amount > 0 && { opacity: 0 },
              ]}>
              <span css={{ fontSize: '0.675rem' }}> do koszyka </span>&nbsp;+{' '}
              {price.toFixed(2)} zł
            </ButtonBase>
            {productInCart?.amount >= 1 && (
              <ButtonBase
                css={[sCartButtonAddRemoveBase, { borderRadius: 16 }]}>
                <div
                  css={sCartButtonAddRemove}
                  onClick={() => {
                    if (productInCart?.amount >= 1)
                      handleDeleteProduct({ orderId: order._id, prodId })
                  }}>
                  <RemoveIcon />
                </div>
                {productInCart?.amount} x {price.toFixed(2)} zł
                <div
                  css={sCartButtonAddRemove}
                  onClick={(e) =>
                    handleAddProd(e, prodId, noAuthProdData, noAuthRestData)
                  }>
                  <AddIcon />
                </div>
              </ButtonBase>
            )}
          </div>
        )}
      </Intro>

      {/* Location */}
      <Grid container css={sTabs}>
        {/* Availability */}
        {restaurant && (
          <Grid item xs={12} md={6}>
            <Availability
              hours={availableHours}
              type='product'
              isRestActive={restaurant.isActive}
              isProdArchived={isArchived}
            />
          </Grid>
        )}
        {restaurant && (
          <Grid item xs={12} md={6}>
            <Delivery
              restId={restaurant._id}
              isDelivery={restaurant.consumptionTypes.includes('delivery')}
              isRestActive={restaurant.isActive}
            />
          </Grid>
        )}
        {restaurant && (
          <Grid item xs={12} md={6}>
            <ConsumptionTypes consumptionTypes={consumptionTypes} />
          </Grid>
        )}
        {restaurant && (
          <Grid item xs={12} md={6}>
            <Location
              id={restaurant._id}
              address={restaurant.address}
              location={restaurant.location}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <ProductDetails
            ingredients={ingredients}
            cookMethod={cookMethod}
            cookTime={cookTime}
            flavors={flavors}
            openAlready
          />
        </Grid>
      </Grid>

      {/* <RestItemLarge data={restaurant} /> */}

      {restaurant && <RestContainer data={restaurant} />}

      <div
        css={{
          width: '100%',
          maxWidth: globals.maxWidthMediumCarousel,
          margin: '0 auto',
        }}>
        {restaurant && (
          <Carousel
            data={restaurant?.products.filter((el) => el._id !== prodId)}
            CarouselItem={ProdItemMini}
            link='/product/'
          />
        )}
      </div>

      {ezcProduct.restaurant?.isActive && !ezcProduct.isArchived && (
        <OrderInfo
          mainText={sum}
          btnOnClick={() => history.push(`/cart-checkout/${order?._id}`)}
          isShow={order}
        />
      )}

      {!isArchived && (
        <div
          css={{
            zIndex: 1001,
            position: 'fixed',
            top: 0,
            right: 0,
            width: '100vw',
            pointerEvents: 'none',
          }}>
          <div
            css={{
              width: '100%',
              maxWidth: globals.maxWidthMedium,
              margin: '0 auto',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              [s.sm_down]: {
                height: globals.topnavHeight.mobile,
              },
              height: globals.topnavHeight.desktop,
              padding: '0 1.5rem',
            }}>
            <div
              className='product-buy'
              css={{
                pointerEvents: 'auto',
                position: 'relative',
                transition: 'background-color 0.3s ease-out',
                height: 32,
                minWidth: 90,
                width: 'max-content',
                whiteSpace: 'nowrap',
                // padding: '0 0.5rem',
                fontWeight: 800,
                backgroundColor: colors.white.concat(alpha[30]),
                borderRadius: 16,
                fontSize: '1rem',
                '&:hover': {
                  [s.hover]: { backgroundColor: colors.gray400 },
                },
              }}>
              <ButtonBase
                onClick={(e) => {
                  if (!productInCart || productInCart.amount === 0)
                    handleAddProd(e, prodId, noAuthProdData, noAuthRestData)
                }}
                css={[
                  sCartButtonNoItemButton,
                  productInCart?.amount > 0 && { opacity: 0 },
                  {
                    fontWeight: 800,
                    fontSize: '0.875rem',
                    color: 'currentColor',
                    letterSpacing: '0em',
                  },
                ]}>
                + {price.toFixed(2)} zł
              </ButtonBase>
              {productInCart?.amount >= 1 && (
                <ButtonBase
                  css={[
                    sCartButtonAddRemoveBase,
                    { color: 'currentColor', borderRadius: 16 },
                  ]}>
                  <div
                    css={[sTopNavAddRemove]}
                    onClick={() => {
                      if (productInCart?.amount >= 1)
                        handleDeleteProduct({ orderId: order._id, prodId })
                    }}>
                    <RemoveIcon />
                  </div>
                  {productInCart.amount}
                  <div
                    css={[sTopNavAddRemove]}
                    onClick={(e) =>
                      handleAddProd(e, prodId, noAuthProdData, noAuthRestData)
                    }>
                    <AddIcon />
                  </div>
                </ButtonBase>
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

// const GetDeliveryInfo = ({ restId }) => {
//   const {
//     data,
//     loading,
//     error,

//     // availableHours,
//     // deliveryCost,
//     deliveryRadius,
//     distanceToUser,
//     freeDeliveryAmount,
//     minAmount,
//     _deliveryCostAmount,
//   } = useDeliveryInfo({ restId })

//   // console.log(data?.ezcOrderDeliveryCost)

//   return <React.Fragment>{deliveryRadius}</React.Fragment>
// }

const sTabs = {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '1.5rem',
  maxWidth: globals.maxWidthMedium,
  margin: '0 auto',
  paddingBottom: 0,
  [s.md]: { flexDirection: 'row', paddingTop: '2.5rem' },
}

const sCartButtonAddRemove = {
  transition: 'background-color 0.3s ease-out',
  height: 48,
  width: 48,
  borderRadius: 24,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    [s.hover]: { backgroundColor: colors.green400.concat(alpha[30]) },
  },
}

const sTopNavAddRemove = {
  height: 32,
  width: 32,
  borderRadius: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const sCartButtonAddRemoveBase = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '#ffffff',
  fontSize: '1rem',
  fontWeight: 800,
  letterSpacing: '0.05em',
}

const sCartButtonNoItemButton = {
  top: 0,
  left: 0,
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  jsutifyContent: 'center',
  alignItems: 'center',
  transition:
    'color 0.3s ease-out, background-color 0.3s ease-out, opacity 0.3s ease-out',
  textAlign: 'center',
  borderRadius: 24,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  color: '#ffffff',
  fontSize: '1rem',
  fontWeight: 800,
  letterSpacing: '0.05em',
}

export default Product
