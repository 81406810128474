/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars


export default ({ children, header, helperComponent }) => (
  <div css={{ padding: 36 }}>
    <div css={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 24
    }}>
      <h1>{header}</h1>
      {helperComponent}
    </div>
    {children}
  </div>
)