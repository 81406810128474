/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useError } from '../../../../../hooks/errors.hook'
import { useGeolocationContext } from '../../../../context/Geolocation/Geolocation.provider'
import { useGetRestaurants } from '../../../../hooks/useRests.queries'
import Carousel from '../../../../components/Carousel/Carousel.component'
import { RestItemLarge } from '../../../../components/RestItem'

import { s, globals } from '../../../../../style'

const RestaurantCarousel = () => {
  const { errorHandling } = useError()
  const { geolocation } = useGeolocationContext()
  const { data, loading, error } = useGetRestaurants({
    lng: geolocation.lng,
    lat: geolocation.lat,
  })

  if (error) {
    errorHandling(error)
    return null
  }
  if (loading) return <div></div>

  return (
    <React.Fragment>
      <div css={sContainer}>
        <h2 css={sTitle}>Restauracje</h2>
      </div>
      <div css={{ maxWidth: globals.maxWidthMediumCarousel, margin: '0 auto' }}>
        <Carousel
          scrollButtons={364}
          data={data.ezcRestaurants}
          CarouselItem={RestItemLarge}
          link='/restaurant/'
        />
      </div>
    </React.Fragment>
  )
}

const sContainer = {
  maxWidth: globals.maxWidthMedium,
  width: '100%',
  margin: '0 auto',
}

const sTitle = {
  margin: '1rem 0 1.5rem',
  fontSize: '1.5rem',
  fontWeight: 800,
  letterSpacing: '-0.03em',
  paddingLeft: '2rem',
  [s.md]: {
    margin: '3rem 0',
    fontSize: '2rem',
  },
}

export default RestaurantCarousel
