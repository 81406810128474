/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Grid, FormControlLabel, Switch } from '@material-ui/core'
import { Bullet, Collapsable } from '../../../../components/Elements'
import { CollapsableText } from '../../../../components/Elements/Collapsable/Collapsable'
// import CloseIcon from '@material-ui/icons/Close'
import { s, colors } from '../../../../style'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import LockIcon from '@material-ui/icons/Lock'
import { useProductContext } from '../context/product.provider'
import useProdMutations from '../../../hooks/useProd.mutations'
import { toast } from 'react-toastify'

const StatusActive = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { handleArchiveProd } = useProdMutations()

  const { productInfo } = useProductContext()

  const isArchived = productInfo.isArchived
  const prodId = productInfo._id

  const onArchive = (prodId, isArchived) => {
    handleArchiveProd({ prodId, isArchived: !isArchived }, () => {
      toast.info(
        isArchived
          ? 'Produkt został dearchiwizowany'
          : 'Produkt został zarchiwizowany'
      )
    })
  }

  // const onChangeSave = () => {
  //   setIsOpen(!isOpen)
  //   setRestaurantInfo({ ...restaurantInfo, isActive: !isActive })
  // }

  // if (isActive === undefined) return null

  return (
    <Grid item xs={12} md={6}>
      <Collapsable
        right
        noPadding
        oneLiner
        title={'Archiwum'}
        icon={
          !isArchived ? (
            <LockOpenIcon fontSize='inherit' />
          ) : (
            <LockIcon fontSize='inherit' color={'#ff0000'} />
          )
        }
        description={
          !isArchived ? (
            <>
              Dostępny
              <Bullet />
              <strong css={{ color: colors.green }}>Widoczny</strong>
            </>
          ) : (
            <>
              W Archiwum
              <Bullet />
              <strong css={{ color: colors.red }}>Niewidoczny</strong>
            </>
          )
        }
        parentIsOpen={isOpen}
        parentSetIsOpen={setIsOpen}>
        <CollapsableText>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={!isArchived}
                  onChange={() => onArchive(prodId, isArchived)}
                  name='isSendingClientPushes'
                  color='primary'
                />
              }></FormControlLabel>
            <span
              css={{
                fontWeight: 800,
                color: colors.text,
                fontSize: '0.875rem',
              }}>
              {!isArchived ? 'Archiwizuj produkt' : 'Dearchiwizuj produkt'}
            </span>
          </div>
        </CollapsableText>
      </Collapsable>
    </Grid>
  )
}

export default StatusActive
