/** @jsx jsx */
import React, { useState, useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useError } from '../../../../hooks/errors.hook'
import { useQuery } from '@apollo/client'
import { GET_USER } from '../../../graphql/user.graphql'
import useUserMutations from '../../../hooks/useUser.mutations'
import { sBottomLinks, sOuter, sGrid } from '../../../../style/style'
import { ButtonBorder } from '../../../../components/Elements'
import Headline from '../../../../components/Headline/Headline'
import { Grid } from '@material-ui/core'
import MoneyEz from '../../../../helpers/MoneyEz'
import isAdminHelper from '../../../../helpers/isAdmin'
import { Profile } from '../Account.page'
import SettingsPersonal from './components/SettingsPersonal'
import SettingsAvatar from './components/SettingsAvatar'
import SettingsPush from './components/SettingsPush'
import { TopNav } from '../../../../components/TopNavFixed/TopNavFixed'

const Settings = () => {
  const { errorHandling } = useError()
  const { handleChangeUserSettings, handleUserUpdate, handleUpdateAvatar } =
    useUserMutations()
  const { data, loading, error } = useQuery(GET_USER)

  if (loading) return <div />
  if (error) {
    errorHandling(error)
    return null
  }
  if (!data) return <p>Error</p>

  const {
    user: {
      personal,
      email,
      coinsBalance,
      coinsBlocked,
      isRestaurateur,
      _id,
      settings,
    },
  } = data

  const isAdmin = _id ? isAdminHelper(_id) : false

  const actualBalance = MoneyEz(coinsBalance)
    .subsctract(coinsBlocked)
    .getAmountWithFullPrecision()
    .split('.')

  return (
    <React.Fragment>
      <TopNav
        title={`Ustawienia`}
        subtitle={personal && `- ${personal.name} ${personal.surname}`}
      />
      <div css={sOuter}>
        <Headline
        // itemChildren={<ButtonBorder link={`/account`}>Konto</ButtonBorder>}
        >
          Ustawienia
        </Headline>
        <Grid container css={sGrid}>
          <Profile
            actualBalance={actualBalance}
            personal={personal || {}}
            isRestaurateur={isRestaurateur}
            isAdmin={isAdmin}
          />
          <SettingsPersonal
            data={{
              name: personal.name,
              surname: personal.surname,
              // avatarUrl: 'users' + personal.avatarUrl,
              email,
            }}
            handleUserUpdate={handleUserUpdate}
          />
          <SettingsAvatar
            data={personal.avatarUrl}
            handleUpdateAvatar={handleUpdateAvatar}
          />
          <SettingsPush
            data={settings}
            handleChangeUserSettings={handleChangeUserSettings}
          />
          <Grid item xs={12} css={sBottomLinks}>
            <ButtonBorder link={'/terms'}>Regulamin</ButtonBorder>
            <ButtonBorder link={'/privacy'}>Polityka Prywatności</ButtonBorder>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}

export default Settings
