/** @jsx jsx */
import React, { useEffect, useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { s, colors, alpha, globals } from '../../../../style'
import { Collapse } from '@material-ui/core'
import { Bullet } from '../../../../components/Elements'
// import CheckIcon from '@material-ui/icons/Check'

const Messages = ({
  subscribeToNewMssgs,
  userId,
  messages,
  chatId,
  chatHistory,
  isWaiter,
}) => {
  useEffect(() => {
    let unsubscribe
    if (subscribeToNewMssgs) {
      unsubscribe = subscribeToNewMssgs()
    }

    if (unsubscribe) return () => unsubscribe()
  }, [chatId])

  if (!messages) return null
  console.log(messages)

  return messages.map((mssg, index) => (
    <Message
      isWaiter={isWaiter}
      isLast={index === messages.length - 1}
      chatHistory={chatHistory}
      key={index}
      userId={userId}
      prevFromUser={index > 0 && messages?.[index - 1]?.fromUserId}
      {...mssg}
    />
  ))
}

export const Message = ({
  message,
  fromUserId,
  userId,
  prevFromUser,
  author,
  createdAt,
  chatHistory,
  isLast,
  isRead,
  isWaiter,
}) => {
  const [isDetailOpen, setIsDetailOpen] = useState(chatHistory && isLast)

  return (
    <>
      <div
        onClick={() => setIsDetailOpen(!isDetailOpen)}
        css={[
          {
            display: 'flex',
            justifyContent: userId === fromUserId ? 'flex-end' : 'flex-start',
            '&:first-of-type': {
              marginTop: globals.topnavHeight.desktop,
            },
          },
          chatHistory && {
            '&:first-of-type': {
              marginTop: 0,
            },
          },
        ]}>
        <div
          css={[
            {
              cursor: 'pointer',
              padding: '8px 12px 8px',
              borderRadius: 16,
              backgroundColor:
                userId === fromUserId
                  ? isWaiter
                    ? colors.orange.concat(alpha[16])
                    : colors.green.concat(alpha[16])
                  : colors.gray200,
              color:
                userId === fromUserId
                  ? isWaiter
                    ? colors.orange
                    : colors.green800
                  : colors.text,
              fontSize: '0.875rem',
              lineHeight: '1.2em',
              // margin: '2px 0',
              whiteSpace: 'pre-wrap',
              maxWidth: '80%',
              marginTop: '0.5rem',
              fontWeight: 500,
              [s.lg]: {
                maxWidth: '45%',
              },
            },
            userId === fromUserId
              ? prevFromUser === userId
                ? { marginTop: '0.25rem' }
                : { marginTop: '1.5rem' }
              : prevFromUser === userId
              ? { marginTop: '1.5rem' }
              : { marginTop: '0.25rem' },
            chatHistory &&
              userId !== fromUserId && { backgroundColor: colors.gray300 },
          ]}>
          {message}
        </div>
      </div>
      <div
        css={[
          {
            // cursor: 'pointer',
            display: 'flex',
            fontSize: 10,
            opacity: 0.6,
            '&:nth-last-of-type(2)': {
              marginBottom: '1.5rem',
              [s.md]: { marginBottom: '2rem' },
              // marginBottom: '4rem',
            },
          },
          userId === fromUserId && {
            justifyContent: 'flex-end',
          },
          userId !== fromUserId && {
            justifyContent: 'flex-start',
          },
        ]}>
        <Collapse in={isDetailOpen}>
          <div css={{ margin: '0.5rem 12px' }}>
            {/* {isRead && (
              <>
                <CheckIcon fontSize='inherit' />
                &nbsp;
              </>
            )} */}
            {author && (
              <>
                {author} <Bullet />
              </>
            )}

            {new Date(createdAt).toLocaleString('pl')}
          </div>
        </Collapse>
      </div>
    </>
  )
}

export default Messages
