export const dateFc = (date, time) => {
  // '2020-09-30', '17:29:02' ==> 30 wrz 17:29
  if (!date || typeof date !== 'string') return time ? time : ''

  const newDate = date.split('-').splice(1).reverse()
  const day = newDate[0]
  const month = getMonthName(newDate[1])
  const year = newDate[2] || date.slice(0, 4)
  const actualYear = new Date().getFullYear()
  const monthCapitalized = month.charAt(0).toUpperCase() + month.slice(1)

  const yearStr = actualYear.toString() !== year.toString() ? year : ''

  return `${day} ${monthCapitalized} ${yearStr} ${time ? time : ''}`
}

export function getFullDate(date) {
  const newDate = date.split('-').splice(1).reverse()
  const day = newDate[0]
  const month = getMonthNameLong(newDate[1])
  const year = newDate[2] || date.slice(0, 4)
  const actualYear = new Date().getFullYear()
  const monthCapitalized = month.charAt(0).toUpperCase() + month.slice(1)
  const yearStr = actualYear.toString() !== year.toString() ? year : ''
  return `${day} ${monthCapitalized} ${year}`
}

function getMonthNameLong(monthNum) {
  let month = ''

  switch (monthNum) {
    case '01':
      month = 'Stycznia'
      break

    case '02':
      month = 'Lutego'
      break

    case '03':
      month = 'Marca'
      break

    case '04':
      month = 'Kwietnia'
      break

    case '05':
      month = 'Maja'
      break

    case '06':
      month = 'Czerwca'
      break

    case '07':
      month = 'Lipca'
      break

    case '08':
      month = 'Sierpnia'
      break

    case '09':
      month = 'Września'
      break

    case '10':
      month = 'Października'
      break

    case '11':
      month = 'Listopada'
      break

    case '12':
      month = 'Grudnia'
      break

    default:
      month = 'Miesiąc'
      break
  }

  return month
}

function getMonthName(monthNum) {
  let month = ''

  switch (monthNum) {
    case '01':
      month = 'sty'
      break

    case '02':
      month = 'lut'
      break

    case '03':
      month = 'mar'
      break

    case '04':
      month = 'kwi'
      break

    case '05':
      month = 'maj'
      break

    case '06':
      month = 'cze'
      break

    case '07':
      month = 'lip'
      break

    case '08':
      month = 'sie'
      break

    case '09':
      month = 'wrz'
      break

    case '10':
      month = 'paź'
      break

    case '11':
      month = 'lis'
      break

    case '12':
      month = 'gru'
      break

    default:
      month = 'miesiąc nieznany'
      break
  }

  return month
}
