import { ORDER_STATUSES } from '../../constants/statuses'
import { consumptionTypeNames } from '../../constants/types'
import endWordHelper from '../../helpers/endWord.helper'
import { dateFc } from '../../helpers/date-format'
import MoneyEz from '../../helpers/MoneyEz'
import getStatusName from '../../helpers/get-status-name'



function createOrderSummary({
  status,
  shortId,
  ownerId,
  consumptionPersons,
  consumptionType,
  consumptionDate,
  consumptionTime,
  priceSum,
  isPaid,
}) {

  return {
    status: ORDER_STATUSES[getStatusName(status)],
    id: shortId,
    personal: `${ownerId.personal.name} ${ownerId.personal.surname}`,
    personAmount: endWordHelper('osoba', consumptionPersons),
    orderType: consumptionTypeNames[consumptionType],
    time: dateFc(consumptionDate, consumptionTime),
    moneyAmount: MoneyEz(priceSum).getAmountWithFullPrecisionAndZl(),
    paidStatus: isPaid ? 'Opłacone' : 'Czeka na opłatę'
  }
}

export default createOrderSummary