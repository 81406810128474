/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify'
import { useParams, useLocation, useHistory } from 'react-router-dom'

import OrderChatView from '../../../EZC/views/Chat/OrderChat.view'
import FinishedOrderChatView from '../../../EZC/views/Chat/FinishedOrderChat.view'
import DeclinedOrderChatView from '../../../EZC/views/Chat/DeclinedOrderChat.view'
import isCorrectId from '../../../helpers/check-correct-id'
import queryString from 'query-string'
import { sDescription, sGrid, sOuter } from '../../../style/style'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import Headline from '../../../components/Headline/Headline'
import { Grid } from '@material-ui/core'

const ChatPage = () => {
  const history = useHistory()
  const orderId = useParams().id
  const location = useLocation()
  const query = queryString.parse(location.search)

  const orderTypes = {
    active: '/waiter/notifications',
    declined: '/waiter/history?orderType=declined',
    finished: '/waiter/history?orderType=finished',
  }

  if (!orderId) {
    history.push('/waiter/notifications')
  }

  if (!isCorrectId(orderId)) {
    return (
      <React.Fragment>
        <TopNav title={'Chat'} />
        <div css={sOuter}>
          <Headline>Chat</Headline>
          <Grid container css={sGrid}>
            <p css={sDescription}>
              Czat nie został odnaleziony. Powiązane zamówienie prawdopodobnie
              przeniesione do Histori
            </p>
          </Grid>
        </div>
      </React.Fragment>
    )
  }

  if (query.orderType === 'finished')
    return (
      <FinishedOrderChatView
        orderId={orderId}
        name={decodeURI(query.name)}
        avatarUrl={decodeURI(query.avatarUrl)}
        onCloseChat={() => history.push(orderTypes.finished)}
        isWaiter
      />
    )

  if (query.orderType === 'declined')
    return (
      <DeclinedOrderChatView
        orderId={orderId}
        name={decodeURI(query.name)}
        avatarUrl={decodeURI(query.avatarUrl)}
        onCloseChat={() => history.push(orderTypes.declined)}
        isWaiter
      />
    )

  return (
    <OrderChatView
      orderId={orderId}
      name={decodeURI(query.name)}
      avatarUrl={decodeURI(query.avatarUrl)}
      onCloseChat={() => history.push(orderTypes.active)}
      isWaiter
    />
  )
}

export default ChatPage
