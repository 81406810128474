const s3bucketUrl = 'https://appclever-ezn.s3.eu-central-1.amazonaws.com/'

export const krakenUrls = {
  small: s3bucketUrl + s3EnvFolder() + 'kraken/small/',
  medium: s3bucketUrl + s3EnvFolder() + 'kraken/medium/',
  original: s3bucketUrl + s3EnvFolder(),
}

function s3EnvFolder() {
  let prefixFolderName = ''

  switch (process.env.REACT_APP_NODE_ENV) {
    case 'LOCAL_DEV':
      prefixFolderName = '__test/'
      break

    case 'DEV':
      prefixFolderName = '__dev/'
      break

    case 'TEST':
      prefixFolderName = '__test/'
      break

    case 'TEST2':
      prefixFolderName = '__test2/'
      break

    case 'PROD':
      prefixFolderName = 'prod/'
      break

    case 'UAT':
      prefixFolderName = '__uat/'
      break

    default:
      if (process.env.REACT_APP_NODE_ENV !== 'PROD') {
        throw new Error('/constants/s3-files.js unknown switch type')
      }
      prefixFolderName = '___other/'
      break
  }

  return prefixFolderName
}
