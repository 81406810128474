/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useNoAuthOrdersContext } from '../../context/NoAuthOrders/NoAuthOrders.provider'
import WidgetContainer from '../../../components/Widget/Widget.component'
import SignupWidget from './SignupWidget'
import LoginWidget from './LoginWidget'
import { Collapse } from '@material-ui/core'

export default function ({ isWidgetOpen, setIsWidgetOpen }) {
  const [authType, setAuthType] = useState('LOGIN')
  const {
    onSendOrdersNoAuthLogin,
    onSendOrdersNoAuthSignup,
  } = useNoAuthOrdersContext()

  const props = {
    isWidgetOpen,
    setIsWidgetOpen,
    setAuthType,
  }

  return (
    <WidgetContainer
      isWidgetOpen={isWidgetOpen}
      setIsWidgetOpen={setIsWidgetOpen}
      // authType={authType}
    >
      <Collapse
        in={authType === 'LOGIN'}
        timeout={{ appear: 600, enter: 600, exit: 300 }}>
        <LoginWidget onSubmit={onSendOrdersNoAuthLogin} {...props} />
      </Collapse>
      <Collapse
        in={authType === 'SIGNUP'}
        timeout={{ appear: 600, enter: 600, exit: 300 }}>
        <SignupWidget onSubmit={onSendOrdersNoAuthSignup} {...props} />
      </Collapse>
    </WidgetContainer>
  )
}
