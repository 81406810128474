/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import {
  isOrderNew as isOrderNewFn,
  hasOrderNewProducts as hasOrderNewProductsFn,
  wasOrderAnnuledByClient as wasOrderAnnuledByClientFn
} from '../../../helpers/order-checks'
import getUnreadMessagesAmount from '../../../helpers/unread-messages-amount'

import { ezwRoutePrefix } from '../../settings'

import { useOrderAttentionContext } from '../context/order-attention.context'

import OrderAttentionSummary from './OrderAttentionSummary'
import InvoiceDetails from './InvoiceDetails'
import OrderNote from './OrderNote'
import DeliveryDetails from './DeliveryDetails'

import ProductsToAccept from './ProductsToAccept'
import ProductsOrdered from './ProductsOrdered'
import ProductsPaid from './ProductsPaid'
import ProductsAnnuledOrder from './ProductsAnnuledOrder'

import OrderSum from './OrderSum'
import OrderAttentionHeader from './OrderAttentionHeader'
import WaiterNote from './WaiterNote'
import ChatButton from './ChatButton'


export default () => {
  const { orderWeWorkOn, } = useOrderAttentionContext()

  const isOrderNew = isOrderNewFn(orderWeWorkOn)
  const hasOrderNewProducts = hasOrderNewProductsFn(orderWeWorkOn)
  const wasOrderAnnuledByClient = wasOrderAnnuledByClientFn(orderWeWorkOn)

  const chatLink = `${ezwRoutePrefix}/orders-chat/`
    + orderWeWorkOn?._id
    + `?name=${orderWeWorkOn?.ownerId?.personal?.name} ${orderWeWorkOn?.ownerId?.personal?.surname}`
    + `&avatarUrl=${orderWeWorkOn?.ownerId?.personal?.avatarUrl}`

  return (
    <Container>
      <OrderAttentionHeader />
      <OrderAttentionSummary />

      <Padding size={16} />
      <WaiterNote />
      <ChatButton
        unreadMssgsAmount={getUnreadMessagesAmount(orderWeWorkOn.messages)}
        chatLink={chatLink}
      />

      <DeliveryDetails />
      <OrderNote />
      <InvoiceDetails />

      {(isOrderNew || hasOrderNewProducts)
        ? (
          <React.Fragment>
            <ProductsToAccept />
            <ProductsOrdered />
          </React.Fragment>
        )
        : wasOrderAnnuledByClient
          ? <ProductsAnnuledOrder />
          : <ProductsPaid />
      }

      <OrderSum />
    </Container>
  )
}

// Components
const Container = ({ children }) => (
  <div css={{
    width: '100%',
    maxWidth: 520,
    backgroundColor: '#fff',
    padding: 16,
    borderRadius: 4,
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
    maxHeight: '70%',
    overflow: 'scroll'
  }}>
    {children}
  </div>
)

const Padding = ({ size }) =>
  <span css={{ display: 'block', paddingTop: size ?? 0 }} />