/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Grid, Avatar } from '@material-ui/core'
import { Collapsable, Button } from '../../../../../components/Elements'
import FaceIcon from '@material-ui/icons/Face'
import { CollapsableText } from '../../../../../components/Elements/Collapsable/Collapsable'
import Input from '../../../../views/Auth/components/Input.component'
import CloseIcon from '@material-ui/icons/Close'
import { s, colors } from '../../../../../style'
import { imagePreview as imgPreviewUtil } from '../../../../../helpers/image-preview'
import { krakenUrls } from '../../../../../constants/s3-files'
import { toast } from 'react-toastify'

const SettingsAvatar = ({ data, handleUpdateAvatar }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [imgFile, setImgFile] = useState(null)
  const [imagePreview, setImagePreview] = useState(null)
  const [avatarUrl, setAvatarUrl] = useState(data)

  const onAddImage = (event) => {
    setImgFile(event.target.files[0])
    imgPreviewUtil(event.target.files[0])
      .then((file) => setImagePreview(file))
      .catch((e) => setImagePreview(null))
    event.target.value = ''
  }
  const onReset = () => {
    setAvatarUrl(data)
    setImgFile(null)
    setImagePreview(null)
  }
  const onSave = () => {
    setIsLoading(true)
    handleUpdateAvatar({ imgFile }, () => {
      toast.dark('Avatar został zaktualizowany')
      setIsLoading(false)
      setIsOpen(false)
    })
  }
  return (
    <Grid item xs={12} md={6}>
      <Collapsable
        right
        noPadding
        oneLiner
        title={'Avatar'}
        icon={<FaceIcon />}
        description={`Zdjęcie profilowe`}
        parentIsOpen={isOpen}
        parentSetIsOpen={setIsOpen}>
        <CollapsableText>
          <div
            css={{
              display: 'flex',
              gap: '1rem',
              alignItems: 'center',
              marginBottom: '1.5rem',
              [s.md]: { gap: '2rem', marginBottom: '2rem' },
            }}>
            <Avatar
              src={imagePreview || (avatarUrl && krakenUrls.small + avatarUrl)}
              alt=''
              css={{ width: 48, height: 48 }}
            />
            <div
              css={{ position: 'relative', cursor: 'pointer', width: '100%' }}>
              <Button
                loadingSpinner={isLoading}
                loading={isLoading}
                w100
                w100Desktop
                extraCss={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  fontSize: '0.75rem',
                  backgroundColor: colors.gray500,
                }}>
                {imgFile?.name || 'WYBIERZ PLIK'}
              </Button>
              <Input
                accept='image/*'
                multiple
                type='file'
                name='photofile'
                onChange={onAddImage}
                placeholder='plik'
                darker
                sInputExtra={{ opacity: 0, cursor: 'pointer' }}
                sBaseExtra={{ marginBottom: 0, cursor: 'pointer' }}
              />
            </div>
          </div>
          <div css={{ display: 'flex', gap: '1rem', [s.md]: { gap: '2rem' } }}>
            <Button size48 dimmed action={onReset} loading={isLoading}>
              <CloseIcon />
            </Button>
            <Button
              w100
              w100Desktop
              action={onSave}
              loadingSpinner={isLoading}
              loading={isLoading}
              isLoading={isLoading}>
              Zapisz
            </Button>
          </div>
        </CollapsableText>
      </Collapsable>
    </Grid>
  )
}

export default SettingsAvatar
