/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
import { s } from '../../../style'

const MapComponent = ({ location, google }) => (
  <div
    css={{
      position: 'relative',
      margin: 0,
      height: 300,
    }}>
    <Map
      google={google}
      zoom={14}
      initialCenter={{
        lat: location.coordinates[1],
        lng: location.coordinates[0],
      }}
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
      disableDefaultUI={true}>
      <Marker
        // title={'The marker`s title will appear as a tooltip.'}
        // name={'SOMA'}
        position={{
          lat: location.coordinates[1],
          lng: location.coordinates[0],
        }}
      />
    </Map>
  </div>
)

const LoadingContainer = () => <div />

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDyWZm088wLqg6PPXHrN-CXhxX8iIguv94',
  LoadingContainer,
})(MapComponent)
