import React, { useState, useContext, createContext } from 'react'
import { toast } from 'react-toastify'
import { useError } from '../../../hooks/errors.hook'

export const GeolocationContext = createContext({
  geolocation: { lat: 0, lng: 0, address: '' },
  setGeolocation: () => {},
  handleLocation: () => {},
})

export const GeolocationProvider = ({ children }) => {
  const { errorHandling } = useError()
  // If no coords => Sopot.location
  const [geolocation, setGeolocation] = useState({
    lat: 54.4419208,
    lng: 18.5125771,
    address: 'Sopot, Polska',
  })

  const handleLocation = (showError = false) => {
    if (navigator.geolocation) {
      try {
        if (showError)
          navigator.geolocation.getCurrentPosition(
            showPosition,
            showPositionError
          )
        else navigator.geolocation.getCurrentPosition(showPosition)
      } catch (error) {
        toast.error(error.message)
      }
    } else {
      toast.error('Geolokalizacja nie jest wspierana przez przeglądarkę')
    }

    async function showPositionError(response) {
      if (response.code === 1)
        return toast.error('Zezwól na udostępnianie lokalizacji')
      if (response.code === 2)
        return toast.error('Pozyskanie lokalizacji jest niemożliwe')
      if (response.code === 3)
        return toast.error('Pobieranie lokalizacji trwa zbyt długo')
      else return toast.error(response.message)
    }

    async function showPosition(position) {
      const location = {
        lng: position.coords.longitude,
        lat: position.coords.latitude,
      }

      let address = geolocation.address
      try {
        const addressResponse = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=AIzaSyDyWZm088wLqg6PPXHrN-CXhxX8iIguv94`
        )
        const res = await addressResponse.json()
        address = res.results[0].formatted_address
      } catch (err) {
        toast.error('Wystąpił problem z pozyskaniem lokalizacji')
        errorHandling(err)
      }

      setGeolocation({ ...location, address })
    }
  }

  return (
    <GeolocationContext.Provider
      value={{
        geolocation,
        setGeolocation,
        handleLocation,
      }}>
      {children}
    </GeolocationContext.Provider>
  )
}

export const useGeolocationContext = () => useContext(GeolocationContext)
