/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { ProductListHandle } from '../../../components'
import { useActiveOrdersContext } from '../context/orders-active.context'
import StatusButtons from './StatusesButtons'
import HeaderCheck from './HeaderCheck'


const FinishedProducts = () => {
  const {
    isLoading,
    finishedProducts,
    handleActiveProdStatus,
    onToggleProduct,
    handleAnnulFinishedProd,
  } = useActiveOrdersContext()

  return (
    <React.Fragment>
      <HeaderCheck condition={finishedProducts?.length}>Produkty potwierdzone</HeaderCheck>
      <ProductListHandle products={finishedProducts} onToggleProduct={onToggleProduct} />

      {!!finishedProducts?.length && <StatusButtons
        isLoading={isLoading}
        handleActiveProdStatus={handleActiveProdStatus}
        handleAnnulFinishedProd={handleAnnulFinishedProd}
      />}
    </React.Fragment>
  )
}

export default FinishedProducts