import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { consumptionTypeTypes } from '../../../../constants/types'
import { useGeolocationContext } from '../../../context/Geolocation/Geolocation.provider'
import { useGetOrdersInCart } from '../../../hooks/useOrders.queries'
import useOrdersMutations from '../../../hooks/useOrders.mutations'
import { usePlacesAutocomplete } from '../../../../components/GooglePlacesAutocomplete'
import { invoiceDataCheck, _getDate } from './utils'

const _storageName = (orderId) => 'cartOrderData'.concat(orderId)

export default ({ orderId }) => {
  const [deliveryCost, setDeliveryCost] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [orderWeWorkOn, setOrderWeWork] = useState(null)

  const setOrderWeWorkOn = (data) => {
    localStorage.setItem(_storageName(orderId), JSON.stringify(data))
    setOrderWeWork(data)
  }

  const { data, loading, error, refetch } = useGetOrdersInCart()
  const { onSelectAddress } = usePlacesAutocomplete()
  const { geolocation, setGeolocation } = useGeolocationContext()

  const history = useHistory()
  const { handleSendOrderToRest } = useOrdersMutations()

  const activeOrder = data?.ezcOrdersInCart.find(
    (order) => order._id === orderId
  )
  // useEffect(() => {
  //   if (!loading && !error && !activeOrder)
  //   history.push('/cart') // redirect here
  // }, [activeOrder])

  useEffect(() => {
    const storageData = JSON.parse(localStorage.getItem(_storageName(orderId)))
    if (storageData && activeOrder) {
      setOrderWeWork({
        ...storageData,
        deliveryAddress: geolocation?.address,
        orderDetails: activeOrder.orderDetails,
        restId: activeOrder.restId,
      })
    }

    if (activeOrder && !orderWeWorkOn && !storageData) {
      const { d, h } = _getDate()
      setOrderWeWorkOn({
        invoiceDetails: {},
        ...activeOrder,
        deliveryAddress: geolocation?.address,
        consumptionDate: activeOrder.consumptionDate || d,
        consumptionTime: activeOrder.consumptionTime || h,
      })
    }
  }, [activeOrder, orderId])

  const onDeleteCartOrderFromLocalStorage = (_orderId) => {
    localStorage.removeItem(_storageName(_orderId))
  }

  return {
    isLoading,
    loading,
    error,
    orderWeWorkOn,
    deliveryCost,
    setDeliveryCost,

    onDeleteCartOrderFromLocalStorage,

    onChange: (e) =>
      setOrderWeWorkOn({ ...orderWeWorkOn, [e.target.name]: e.target.value }),

    handleSelectAddress(address) {
      onSelectAddress(address).then(({ lng, lat }) => {
        setGeolocation({ address, lat, lng })
        setOrderWeWorkOn({ ...orderWeWorkOn, deliveryAddress: address })
      })
    },

    onChangeAddres(address) {
      setOrderWeWorkOn({ ...orderWeWorkOn, deliveryAddress: address })
    },

    onSaveNote(note) {
      setOrderWeWorkOn({ ...orderWeWorkOn, note })
    },

    onIsInvoiceChange() {
      setOrderWeWorkOn({
        ...orderWeWorkOn,
        isInvoice: !orderWeWorkOn.isInvoice,
      })
    },

    onInvoiceDetailsChange(e) {
      setOrderWeWorkOn({
        ...orderWeWorkOn,
        invoiceDetails: {
          ...orderWeWorkOn.invoiceDetails,
          [e.target.name]: e.target.value,
        },
      })
    },

    onSendOrder() {
      try {
        setIsLoading(true)
        const {
          consumptionDate,
          consumptionPersons,
          consumptionTime,
          consumptionType,

          deliveryAddress,
          deliveryDetails,
          note,

          isInvoice,
          invoiceDetails,
        } = orderWeWorkOn

        if (isInvoice) invoiceDataCheck(invoiceDetails)

        const frontendPrices = {
          deliveryCost,
          productPrices: orderWeWorkOn.orderDetails[0].productsInCart.map(
            ({ prodId: { _id, price } }) => ({ _id, price })
          ),
        }

        handleSendOrderToRest(
          {
            orderId,
            consumptionDetails: {
              consumptionDate,
              consumptionTime,
              consumptionType,
              consumptionPersons,

              deliveryAddress:
                consumptionType === consumptionTypeTypes.delivery
                  ? deliveryAddress
                  : undefined,
              deliveryDetails,
              note,

              isInvoice,
              invoiceDetails: isInvoice ? invoiceDetails : undefined,
            },
            frontendPrices,
            refetch: () => {
              setGeolocation({ ...geolocation })
              refetch()
            },
          },
          () => {
            setIsLoading(false)
            onDeleteCartOrderFromLocalStorage(orderId)
          },
          () => setIsLoading(false)
        )
      } catch (err) {
        err.array.forEach((mssg) => toast.error(mssg))
        setIsLoading(false)
      }
    },
  }
}
