/** @jsx jsx */
import React, { useState, useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import MoneyEz from '../../../helpers/MoneyEz'
import { PRODUCT_STATUSES } from '../../../constants/statuses'
import getStatusName from '../../../helpers/get-status-name'


export default ({ products }) => {
  if (!(products?.length)) return null

  return (
    <List css={{
      maxHeight: '75vh',
      overflowX: 'scroll',
      width: '100%'
    }}>
      {products.map(product => <ProductListItem key={product._id} product={product} />)}
    </List>
  )
}


const ProductListItem = ({ product }) => (
  <ListItem css={{ paddingLeft: 0 }}>
    <p css={{ width: '50%' }}>
      <span css={{ fontWeight: 700 }}>{product.amount} x {product.name}</span>
      <br />
      <span css={{ opacity: '.6', fontSize: 14 }}>{PRODUCT_STATUSES[getStatusName(product.status)]}</span>
    </p>

    <ListItemSecondaryAction>
      {MoneyEz(product.price)
        .multiply(product.amount)
        .getAmountWithFullPrecisionAndZl()}
    </ListItemSecondaryAction>
  </ListItem>
)