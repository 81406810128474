import { gql } from '@apollo/client'

export const GET_ORDER = gql`
  query ezcOrder($orderId: ID!) {
    ezcOrder(orderId: $orderId) {
      messages {
        fromRestId
        fromUserId
        isRead
      }
      _id
      consumptionTime
      consumptionDate
      consumptionType
      consumptionPersons
      deliveryAddress
      deliveryDetails
      note
      deliveryCost
      priceSum
      paidSum
      isPaid
      shortId
      isInvoice
      invoiceDetails {
        address
        city
        firmName
        nip
        zipCode
      }
      restId {
        _id
        name
        address
        photoUrl
        avatarUrl
        categories {
          type
          name
        }
      }
      status {
        _id
        name
        createdAt
      }
      orderDetails {
        userId
        productsToOrder {
          _id
          prodId {
            _id
            photoUrl
            description
            ingredients {
              name
            }
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsInProcess {
          _id
          prodId {
            _id
            photoUrl
            description
            ingredients {
              name
            }
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsDeclined {
          _id
          prodId {
            _id
            photoUrl
            description
            ingredients {
              name
            }
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsFinishedGood {
          _id
          prodId {
            _id
            photoUrl
            description
            ingredients {
              name
            }
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsFinishedBad {
          _id
          prodId {
            _id
            photoUrl
            description
            ingredients {
              name
            }
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
      }
    }

    user {
      _id
      coinsBalance
      coinsBlocked
    }
  }
`

export const GET_DECLINED_ORDERS = gql`
  query ezcOrdersDeclined {
    ezcOrdersDeclined {
      _id
      consumptionTime
      consumptionDate
      consumptionType
      consumptionPersons
      deliveryAddress
      deliveryDetails
      note
      priceSum
      paidSum
      deliveryCost
      shortId
      isPaid
      messages {
        fromUserId
        isRead
      }
      restId {
        _id
        name
        address
        avatarUrl
      }
      status {
        _id
        name
        createdAt
      }
      orderDetails {
        userId
        productsToOrder {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsInProcess {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsDeclined {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsFinishedGood {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsFinishedBad {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
      }
    }
  }
`

export const GET_FINISHED_ORDERS = gql`
  query ezcOrdersFinished {
    ezcOrdersFinished {
      _id
      consumptionTime
      consumptionDate
      consumptionType
      consumptionPersons
      deliveryAddress
      deliveryDetails
      note
      priceSum
      paidSum
      deliveryCost
      shortId
      isPaid
      messages {
        fromUserId
        isRead
      }
      restId {
        _id
        name
        address
        avatarUrl
      }
      status {
        _id
        name
        createdAt
      }
      orderDetails {
        userId
        productsToOrder {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsInProcess {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsDeclined {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsFinishedGood {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsFinishedBad {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
      }
    }
  }
`

export const GET_ACTIVE_ORDERS = gql`
  query ezcOrdersActive {
    ezcOrdersActive {
      messages {
        fromUserId
        isRead
      }
      _id
      consumptionTime
      consumptionDate
      consumptionType
      consumptionPersons
      deliveryAddress
      note
      priceSum
      paidSum
      deliveryCost
      isPaid
      shortId
      restId {
        _id
        name
        address
        avatarUrl
      }
      status {
        _id
        name
        createdAt
      }
      orderDetails {
        userId
        productsToOrder {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsInProcess {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsDeclined {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsFinishedGood {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsFinishedBad {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
      }
    }

    user {
      coinsBalance
      coinsBlocked
    }
  }
`

export const GET_ACTIVE_ORDER = gql`
  query ezcOrderActive($orderId: ID!) {
    ezcOrderActive(orderId: $orderId) {
      messages {
        fromRestId
        fromUserId
        isRead
      }
      _id
      consumptionTime
      consumptionDate
      consumptionType
      consumptionPersons
      deliveryAddress
      deliveryDetails
      note
      deliveryCost
      priceSum
      paidSum
      isPaid
      shortId
      isInvoice
      invoiceDetails {
        address
        city
        firmName
        nip
        zipCode
      }
      restId {
        _id
        name
        address
        photoUrl
        avatarUrl
        categories {
          type
          name
        }
      }
      status {
        _id
        name
        createdAt
      }
      orderDetails {
        userId
        productsToOrder {
          _id
          prodId {
            _id
            photoUrl
            description
            ingredients {
              name
            }
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsInProcess {
          _id
          prodId {
            _id
            photoUrl
            description
            ingredients {
              name
            }
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsDeclined {
          _id
          prodId {
            _id
            photoUrl
            description
            ingredients {
              name
            }
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsFinishedGood {
          _id
          prodId {
            _id
            photoUrl
            description
            ingredients {
              name
            }
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsFinishedBad {
          _id
          prodId {
            _id
            photoUrl
            description
            ingredients {
              name
            }
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
      }
    }

    user {
      coinsBalance
      coinsBlocked
    }
  }
`

export const GET_ACTIVE_ORDER_CART = gql`
  query ezcOrderActive($orderId: ID!) {
    ezcOrderActive(orderId: $orderId) {
      restId {
        _id
        name
        address
        photoUrl
        # categories {
        #   type
        #   name
        # }
      }
      orderDetails {
        userId
        productsInCart {
          _id
          prodId {
            _id
            name
            price
            photoUrl
          }
          amount
        }
      }
    }
  }
`

export const GET_ORDERS_IN_CART = gql`
  query ezcOrdersInCart {
    ezcOrdersInCart {
      _id
      restId {
        _id
        name
        avatarUrl
        address
        consumptionTypes
      }
      orderDetails {
        productsInCart {
          _id
          prodId {
            _id
            name
            price
            photoUrl
          }
          amount
        }
      }
      isFinished
      consumptionTime
      consumptionDate
      consumptionType
      consumptionPersons
      deliveryAddress
      note
    }
  }
`

export const GET_ORDER_BY_REST_ID = gql`
  query ezcOrdersInCartByRest($restId: ID!) {
    ezcOrdersInCartByRest(restId: $restId) {
      _id
      orderDetails {
        productsInCart {
          _id
          prodId {
            _id
            name
            price
            photoUrl
          }
          amount
        }
      }
    }
  }
`

export const GET_DELIVERY_INFO = gql`
  query ezcOrderDeliveryCost($geoInput: GeolocationInput!, $restId: ID!) {
    ezcOrderDeliveryCost(geoInput: $geoInput, restId: $restId) {
      deliveryRadius
      freeDeliveryAmount
      minAmount
      deliveryCost
      distanceToUser
      availableHours {
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
        sun {
          open
          close
        }
      }
    }
  }
`

export const GET_ACTIVE_ORDER_SHORT = gql`
  query ezcOrdersActive {
    ezcOrdersActive {
      _id
      messages {
        fromUserId
        isRead
      }
      isPaid
      status {
        _id
        name
      }
    }
  }
`

export const DELETE_ORDER = gql`
  mutation orderDeleteOrder($orderId: ID!) {
    orderDeleteOrder(orderId: $orderId) {
      success
    }
  }
`

export const DELETE_PRODUCT = gql`
  mutation orderDeleteProduct(
    $orderId: ID!
    $prodId: ID!
    $isDeleteAllProds: Boolean
  ) {
    orderDeleteProduct(
      orderId: $orderId
      prodId: $prodId
      isDeleteAllProds: $isDeleteAllProds
    ) {
      success
    }
  }
`

export const SEND_ORDER_TO_REST = gql`
  mutation orderSendOrderToRest(
    $orderId: ID!
    $input: ConsumptionDetailsInput
    $frontendPrices: FrontendPricesInput!
  ) {
    orderSendOrderToRest(
      orderId: $orderId
      input: $input
      frontendPrices: $frontendPrices
    ) {
      _id
      success
      message
    }
  }
`

export const ADD_PRODUCT_TO_ORDER = gql`
  mutation orderAddProduct($orderId: ID!, $prodId: ID!) {
    orderAddProduct(orderId: $orderId, prodId: $prodId) {
      success
      message
    }
  }
`

export const CREATE_ORDER_ADD_PROD = gql`
  mutation orderCreateAddProd($input: OrderCreateInput!, $prodId: ID!) {
    orderCreateAddProd(input: $input, prodId: $prodId) {
      success
      message
      _id
    }
  }
`

export const ADD_ORDERS_TO_USER = gql`
  mutation orderAddToUser(
    $userId: ID!
    $password: String!
    $input: [OrderCreateInput]
  ) {
    orderAddToUser(input: $input, userId: $userId, password: $password) {
      success
      message
    }
  }
`

// Active order Graphs

export const ACTIVE_ORDER_ADD_PROD = gql`
  mutation orderAddProductToActiveOrder($orderId: ID!, $prodId: ID!) {
    orderAddProductToActiveOrder(orderId: $orderId, prodId: $prodId) {
      success
      message
    }
  }
`

export const ACTIVE_ORDER_DELETE_PROD = gql`
  mutation orderDeleteProductFromActiveOrder(
    $orderId: ID!
    $prodId: ID!
    $isDeleteAllProds: Boolean
  ) {
    orderDeleteProductFromActiveOrder(
      orderId: $orderId
      prodId: $prodId
      isDeleteAllProds: $isDeleteAllProds
    ) {
      success
    }
  }
`

export const ACTIVE_ORDER_SEND_TO_REST = gql`
  mutation orderSendActiveOrderToRest($orderId: ID!) {
    orderSendActiveOrderToRest(orderId: $orderId) {
      _id
      success
    }
  }
`

export const UPDATE_ORDER_STATUS = gql`
  mutation orderUpdateStatus(
    $orderId: ID!
    $status: String!
    $productStatuses: [ProductStatusesInput]
  ) {
    orderUpdateStatus(
      orderId: $orderId
      status: $status
      productStatuses: $productStatuses
    ) {
      success
    }
  }
`

export const PAY_FOR_ORDER_FROM_WALLET = gql`
  mutation orderPayFromWallet($orderId: ID!) {
    orderPayFromWallet(orderId: $orderId) {
      success
    }
  }
`

export const FINISH_THE_ORDER = gql`
  mutation orderFinishTheOrder($orderId: ID!) {
    orderFinishTheOrder(orderId: $orderId) {
      success
    }
  }
`

export const ANNUL_ORDER_BY_CLIENT = gql`
  mutation orderAnnulByClient($orderId: ID!) {
    orderAnnulByClient(orderId: $orderId) {
      success
    }
  }
`
