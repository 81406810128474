/** @jsx jsx */
import React, { useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Grid, TextField } from '@material-ui/core'
import { Button, SectionHeadline } from '../../../../components/Elements'
import { sDescription, sGrid } from '../../../../style/style'
import { useRestaurantContext } from '../context/restaurant.provider'
import { sInput } from '../../../style/input.style'
import { s } from '../../../../style'

const DeliveryCosts = () => {
  const {
    deliverySettings: {
      deliveryCost,
      deliveryRadius,
      minAmount,
      availableHours,
      freeDeliveryAmount,
    },
    handleDeliverySettings,
    onDeleteDeliveryCost,
    onAddDeliveryCost,
    handleDeliveryCost,
  } = useRestaurantContext()

  return (
    <Grid container css={sGrid}>
      <SectionHeadline noPaddingLeft>Koszt Dostaw</SectionHeadline>
      <Grid item xs={12}>
        <p css={[sDescription, { marginBottom: '0.5rem' }]}>
          Zdefiniuj obszary dostaw, określając promień oraz koszt każdego z
          nich.
        </p>
      </Grid>
      {deliveryCost.map(([distance, amount], id) => (
        <React.Fragment key={id}>
          <Grid
            item
            xs={12}
            sm={5}
            css={{
              [s.xs]: { marginTop: '1rem' },
              [s.sm]: { paddingRight: '1.5rem' },
            }}>
            <TextField
              label='Promień (metry)'
              value={distance}
              onChange={handleDeliveryCost('distance', id)}
              fullWidth
              css={sInput}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            css={{ [s.sm]: { paddingRight: '1.5rem' } }}>
            <TextField
              label='Koszt dostawy (ZŁ)'
              value={amount}
              onChange={handleDeliveryCost('amount', id)}
              fullWidth
              css={sInput}
            />
          </Grid>
          <Grid item xs={12} sm={2} css={{ [s.xs]: { marginBottom: '2rem' } }}>
            <Button w100 dimmed action={() => onDeleteDeliveryCost(id)}>
              Usuń
            </Button>
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12} sm={5}>
        <Button w100 w100Desktop action={onAddDeliveryCost}>
          Dodaj obszar
        </Button>
      </Grid>
    </Grid>
  )
}

export default DeliveryCosts
