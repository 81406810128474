import MoneyEz from '../../helpers/MoneyEz'
import removeDuplicateProducts from '../../helpers/remove-duplicate-products'

function createOrderSum({ orderDetails, paidSum, deliveryCost }) {
  if (!(orderDetails && orderDetails[0])) {
    return {
      sum: '0,00zł',
      paid: '0,00zł',

      sumToPay: '0,00zł',
      sumToPayAmount: 0,
    }
  }

  let amountSum = 0
  let productsSum = 0
  let allProducts = [
    ...orderDetails[0]?.productsInProcess,
    ...orderDetails[0]?.productsFinishedGood,
  ]

  allProducts = removeDuplicateProducts(allProducts) // heres a fix

  productsSum = MoneyEz(0).sumProductArray(allProducts).getAmount()

  amountSum = MoneyEz(amountSum)
    .add(productsSum)
    .add(deliveryCost || 0)
    .getAmount()

  const deliveryAmount = MoneyEz(
    deliveryCost || 0
  ).getAmountWithFullPrecisionAndZl()

  const annulProducts = [...orderDetails[0]?.productsFinishedBad]
  const annulAmount = MoneyEz(0).sumProductArray(annulProducts).getAmount()

  const sum = MoneyEz(amountSum).getAmountWithFullPrecisionAndZl()

  const paid = MoneyEz(paidSum).getAmountWithFullPrecisionAndZl()

  const annulProductsSum =
    MoneyEz(annulAmount).getAmountWithFullPrecisionAndZl()

  const q = MoneyEz(amountSum).subsctract(paidSum)
  const sumToPay = q.getAmountWithFullPrecisionAndZl()
  const sumToPayAmount = q.getAmount()

  return {
    sum,
    paid,

    annulAmount,
    annulProductsSum,

    sumToPay,
    sumToPayAmount,

    deliveryCost,
    deliveryAmount,
    productsSum: MoneyEz(productsSum).getAmountWithFullPrecisionAndZl(),
  }
}

export default createOrderSum
