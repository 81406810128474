/** @jsx jsx */
import React, { useEffect, useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import Skeleton from '@material-ui/lab/Skeleton'
import { useError } from '../../../../../hooks/errors.hook'

import Carousel from '../../../../components/Carousel/Carousel.component'
import { CarouselItemList } from '../../../../components/CarouselItem'
import { ProdItemMini } from '../../../../components/ProdItem'
import {
  useGetProdsByCategoryId,
  useGetCategoriesByType,
} from '../../../../hooks/useProds.queries'
import { useGeolocationContext } from '../../../../context/Geolocation/Geolocation.provider'
import { s, globals, colors } from '../../../../../style'

const ProductsCarousel = ({ categoryType }) => {
  const { errorHandling } = useError()
  const [categoryId, setCategoryId] = useState(null)
  const { data, error, loading } = useGetCategoriesByType(categoryType)

  useEffect(() => {
    if (data) {
      const categoryValue =
        data.categoriesByType &&
        data.categoriesByType[0] &&
        data.categoriesByType[0]._id
          ? data.categoriesByType[0]._id
          : null
      setCategoryId(categoryValue)
    }
  }, [data])

  if (error) {
    errorHandling(error)
    return null
  }

  if (loading)
    return (
      <div>
        {/* <div css={{ display: 'flex', justifyContent: 'space-between' }}>
          <Skeleton variant='text' width={90} height={20} />
          <Skeleton variant='text' width={90} height={20} />
          <Skeleton variant='text' width={90} height={20} />
        </div> */}
      </div>
    )

  if (categoryId === null) return null

  const categoryData = data.categoriesByType.map((el) => ({
    ...el,
    name: el.name.charAt(0).toUpperCase() + el.name.slice(1),
  }))

  return (
    <div css={styles}>
      <div
        css={{
          maxWidth: globals.maxWidthMedium,
          width: '100%',
          margin: '0 auto',
        }}>
        <Carousel
          data={categoryData}
          CarouselItem={CarouselItemList}
          onClickHandler={setCategoryId}
          categoryId={categoryId}
        />
      </div>

      <div css={{ maxWidth: globals.maxWidthMediumCarousel, margin: '0 auto' }}>
        <ProductsByCategoryId
          categoryId={categoryId}
          loadingCategories={loading}
        />
      </div>
    </div>
  )
}

const ProductsByCategoryId = ({ categoryId, loadingCategories }) => {
  const { errorHandling } = useError()
  const {
    geolocation: { lat, lng },
  } = useGeolocationContext()
  const { data, loading, error } = useGetProdsByCategoryId(categoryId, {
    lng,
    lat,
  })

  // || categoryId === '5f8d46ef407ef5b981970d5a'
  if (loading || loadingCategories)
    return (
      <div
        css={{
          display: 'flex',
          overflowX: 'scroll',
          width: '100%',
          span: { marginLeft: 12, borderRadius: 12, flexShrink: 0 },
          paddingBottom: '1.5rem',
          [s.md]: { paddingBottom: '2rem' },
        }}>
        {new Array(10).fill(0).map((e, id) => (
          <Skeleton
            variant='rect'
            key={id}
            css={[
              id === 0 && { [s.sm_down]: { marginLeft: '1.5rem !important' } },
              { width: 170, height: 196, backgroundColor: colors.gray200 },
            ]}
          />
        ))}
      </div>
    )
  if (error) {
    errorHandling(error)
    return null
  }

  if (data.ezcProductsByCategoryId.length === 0)
    return (
      <p css={{ opacity: '.3', margin: '24px 8px', fontWeight: 800 }}>
        Wybrana kategoria nie posiada jeszcze produktów.
      </p>
    )

  // if (categoryId === '5f8d46ef407ef5b981970d5a')
  //   return (
  //     <div
  //       css={{
  //         display: 'flex',
  //         overflowX: 'scroll',
  //         width: '100%',
  //         span: { marginRight: 16 },
  //       }}>
  //       <Skeleton variant='rect' width={190} height={160} />
  //       <Skeleton variant='rect' width={190} height={160} />
  //     </div>
  //   )

  return (
    <Carousel
      scrollButtons={182 * 3}
      data={data.ezcProductsByCategoryId}
      CarouselItem={ProdItemMini}
      link='/product/'
    />
  )
}

export default ProductsCarousel

const styles = {
  margin: '1rem 0',

  [s.md]: {
    margin: '3rem 0',
  },
}
