/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core'
import { s, colors, alpha, globals } from '../../../style'
import { krakenUrls } from '../../../constants/s3-files'
import { dateFc } from '../../../helpers/date-format'
import PersonIcon from '@material-ui/icons/Person'
import { Bullet } from '../../../components/Elements'
import {
  consumptionTypeNames,
  consumptionTypeIcons,
} from '../../../constants/types'
import { styleIsPaid } from '../../../EZC/views/ProductPage/components/RestContainer'

const OrderUser = ({
  personal,
  consumptionDate,
  consumptionTime,
  consumptionPersons,
  consumptionType,
  priceSum,
  paidSum,
  isPaid,
}) => {
  const isToday = consumptionDate === new Date().toISOString().slice(0, 10)

  return (
    <Grid item xs={12}>
      <div
        css={[
          {
            marginBottom: '1.5rem',
            position: 'relative',
            overflow: 'hidden',
            [s.md]: { borderRadius: '1.5rem 1.5rem 0 0' },
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '1rem 1rem 0 0',
            alignItems: 'start',
            width: '100%',
            textAlign: 'left',
            // background: `linear-gradient(to right, ${colors.gray100}, ${colors.gray200})`,
          },
        ]}>
        <div
          css={[
            {
              display: 'flex',
              alignItems: 'center',
              background: `linear-gradient(to right, ${colors.gray200}, ${colors.gray400})`,
              borderRadius: '1rem 1rem 0 0',
              padding: '1rem',
              width: '100%',
              [s.md]: {
                padding: '2rem',
                borderRadius: '1.5rem 1.5rem 0 0',
                // width: 'calc(100vw - 232px + 2rem)',
              },
            },
          ]}>
          {personal.avatarUrl && (
            <img
              src={krakenUrls.small + personal.avatarUrl}
              alt={personal.name}
              css={{
                width: 64,
                height: 64,
                [s.md]: { width: 96, height: 96 },
                borderRadius: 48,
              }}
              loading='lazy'
            />
          )}
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '1rem',
              [s.md]: { marginLeft: '2rem' },
            }}>
            <h4
              css={{
                fontWeight: 800,
                letterSpacing: '-0.025em',
                fontSize: '1.25rem',
                [s.md]: { fontSize: '2rem' },
              }}>
              {personal.name} {personal.surname}
            </h4>
            <div
              css={{
                display: 'flex',
              }}></div>
            <p
              css={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '0.875rem',
                fontWeight: 800,
                marginTop: '0.5rem',
                [s.md]: { fontSize: '1rem', marginTop: '1rem' },
                color: colors.gray600,
                span: {
                  opacity: 0.5,
                },
                flexWrap: 'wrap',
              }}>
              <div css={globals.center}>
                {consumptionTypeIcons[consumptionType]}
                &nbsp;
              </div>
              <div
                css={[
                  { [s.ss]: { display: 'none' } },
                  !isToday && { [s.xs]: { display: 'none' } },
                ]}>
                {consumptionTypeNames[consumptionType]}
              </div>
              <Bullet />
              {!isToday && (
                <React.Fragment>
                  {dateFc(consumptionDate)} <Bullet />
                </React.Fragment>
              )}

              {consumptionTime}
              <Bullet />
              {consumptionPersons}
              <PersonIcon fontSize='inherit' />
            </p>
          </div>
          <div
            css={[
              {
                [s.xs]: { display: 'none' },
                position: 'absolute',
                top: 0,
                right: 0,
                // width: 232,
                height: '100%',
                [s.md]: { transform: 'scale(2)' },
                background: `linear-gradient(to right, ${
                  colors.gray200
                }, ${colors.gray200.concat(alpha[0])})`,
              },
            ]}>
            <img
              src={krakenUrls.small + personal.avatarUrl}
              alt={personal.name + ' ' + personal.surname}
              css={{
                position: 'absolute',
                top: '50%',
                right: 0,
                width: 200,
                height: 200,
                transform: 'translateY(-50%)',
                filter: 'blur(32px)',
              }}
              loading='lazy'
            />
          </div>
        </div>
        {isPaid !== undefined && (
          <div css={[styleIsPaid(isPaid), { justifyContent: 'space-between' }]}>
            <div>{isPaid ? 'OPŁACONE' : 'NIEOPŁACONE'}</div>
            &nbsp;
            {paidSum > 0 && paidSum !== priceSum ? (
              <div css={{ opacity: 0.5 }}>
                {priceSum - paidSum} / {priceSum} PLN
              </div>
            ) : (
              <div css={{ opacity: 0.5 }}>{priceSum} PLN</div>
            )}
          </div>
        )}
      </div>
    </Grid>
  )
}

export default OrderUser
