/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import {
  isOrderNew,
  hasOrderNewProducts,
  wasOrderAnnuledByClient
} from '../../../helpers/order-checks'
import { useOrderAttentionContext } from '../context/order-attention.context'



export default () => {
  const { orderWeWorkOn } = useOrderAttentionContext()

  if (wasOrderAnnuledByClient(orderWeWorkOn)) {
    return <Header text="Zamówienie zostało anulowane" color="red" />
  }

  if (isOrderNew(orderWeWorkOn)) {
    return <Header text="Nowe zamówienie" color="green" />
  }

  if (hasOrderNewProducts(orderWeWorkOn)) {
    return <Header text="Domówienie do zamówienia" color="orange" />
  }

  return <Header text="Zamówienie zostało opłacone" color="#e47f00" />
}


const Header = ({ text, color }) => (
  <h3 css={{
    color,
    marginBottom: 16,
    fontSize: 24
  }}>
    {text}
  </h3>
)