/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button'

import {
  InvoiceDetails,
  Header,
  OrderListSummary,
  OrderSum,
  ProductList,
} from '../../../components'
import createOrderSummary from '../../../helpers/create-order-summary'
import createOrderSum from '../../../helpers/create-order-sum'
import { ezwRoutePrefix } from '../../settings'



export default ({
  isDeclined,
  _id,
  orderDetails,

  status,
  shortId,
  ownerId,
  consumptionPersons,
  consumptionType,
  consumptionDate,
  consumptionTime,
  priceSum,
  isPaid,
  paidSum,
  deliveryCost,
  messages,

  deliveryAddress,
  deliveryDetails,
  note,
  waiterNote,
  isInvoice,
  invoiceDetails,
}) => {

  const [isOpen, setIsOpen] = useState(false)
  const onOpen = () => setIsOpen(!isOpen)

  const summaryProps = createOrderSummary({
    status,
    shortId,
    ownerId,
    consumptionPersons,
    consumptionType,
    consumptionDate,
    consumptionTime,
    priceSum,
    isPaid,
  })

  const sumProps = createOrderSum({
    orderDetails,
    paidSum,
    deliveryCost
  })

  let productsList = []
  let declinedProducts = []
  if (orderDetails && orderDetails[0]) {
    const {
      productsToOrder,
      productsInProcess,
      productsFinishedGood,
      productsFinishedBad,
      productsDeclined
    } = orderDetails[0]
    productsList = [
      ...productsToOrder,
      ...productsInProcess,
      ...productsFinishedGood,
    ]
    declinedProducts = [
      ...productsFinishedBad,
      ...productsDeclined
    ]
  }

  return (
    <MuiAccordion
      expanded={isOpen}
      onChange={onOpen}
    >
      <MuiAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div css={{ width: '100%', padding: '12px 0' }}>
          <OrderListSummary {...summaryProps} />
        </div>
      </MuiAccordionSummary>

      <MuiAccordionDetails css={{ width: '100%', display: 'block' }}>
        {deliveryAddress && <p css={{ marginBottom: 16 }}><b>Adres dostawy:</b> {deliveryAddress}</p>}
        {deliveryDetails && <p css={{ marginBottom: 16 }}><b>Szczegóły dostawy:</b> {deliveryDetails}</p>}
        {note && (
          <React.Fragment>
            <h4>Notatka do zamówienia</h4>
            <p css={{ marginBottom: 16 }}>{note}</p>
          </React.Fragment>
        )}
        {waiterNote && (
          <React.Fragment>
            <h4>Notatka kelnera:</h4>
            <p css={{ marginBottom: 16 }}>{waiterNote}</p>
          </React.Fragment>
        )}
        {isInvoice && <InvoiceDetails {...invoiceDetails} textHeader="Dane do faktury" />}

        {
          (deliveryAddress || note || isInvoice || waiterNote)
          && <hr css={{ margin: '16px 0' }} />
        }

        <Button
          variant="contained"
          component={Link}
          to={
            isDeclined
              ? `${ezwRoutePrefix}/orders-declined-chat/${_id}?name=${ownerId.personal.name
              + ' ' + ownerId.personal.surname}&avatarUrl=${ownerId.personal.avatarUrl}`
              : `${ezwRoutePrefix}/orders-finished-chat/${_id}?name=${ownerId.personal.name
              + ' ' + ownerId.personal.surname}&avatarUrl=${ownerId.personal.avatarUrl}`
          }
          css={{ fontWeight: 700 }}
        >
          Czat - {messages.length} wiad.
        </Button>

        <Header>Szczegóły zamówienia</Header>
        <ProductList products={productsList} />

        {!!declinedProducts.length && <Header>Odrzucone lub anulowane produkty</Header>}
        <ProductList products={declinedProducts} />

        <OrderSum {...sumProps} consumptionType={consumptionType} />
      </MuiAccordionDetails>
    </MuiAccordion>
  )
}