import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { useGetRestaurant } from '../../../hooks/useQueries'
import useRestaurantMutations from '../../../hooks/useRestaurant.mutations'

export default () => {
  const { data, loading, error } = useGetRestaurant()
  const { handleRestUpdate } = useRestaurantMutations()
  const [isLoading, setIsLoading] = useState(false)
  const [menuSections, setMenuSections] = useState([])

  const [wasChanges, setWasChanges] = useState(false)
  const [activeSectionId, setActiveSectionId] = useState('')
  const [isAddProductsOpen, setIsAddProductsOpen] = useState(false)
  const [singleSectionProducts, setSingleSectionProducts] = useState([])

  useEffect(() => {
    if (data?.ezrRestaurant) setMenuSections(data.ezrRestaurant.menuSections)
  }, [data])

  const handleAddProductsOpen = (sectionId) => {
    setActiveSectionId(sectionId)
    setIsAddProductsOpen(true)
  }

  const handleAddProductsClose = () => setIsAddProductsOpen(false)

  const onAddSection = () => {
    if (!wasChanges) setWasChanges(true)

    const newSection = {
      _id: '' + menuSections.length + Math.random(),
      name: '',
      products: [],
    }

    setMenuSections([newSection, ...menuSections])

    toast.info('Sekcja została dodana. Nadaj nazwę sekcji oraz dodaj produkty')
  }

  const onDeleteSection = (_id) => {
    if (!wasChanges) setWasChanges(true)
    setMenuSections(menuSections.filter((section) => section._id !== _id))
  }

  const onDeleteProduct = (sectionId) => (prodId) => {
    if (!wasChanges) setWasChanges(true)
    setMenuSections(
      menuSections.map((section) =>
        section._id === sectionId
          ? {
              ...section,
              products: section.products.filter(
                (product) => product._id !== prodId
              ),
            }
          : section
      )
    )
  }

  const onNameChange = (sectionId) => (e) => {
    if (!wasChanges) setWasChanges(true)
    setMenuSections(
      menuSections.map((section) =>
        section._id === sectionId
          ? { ...section, name: e.target.value }
          : section
      )
    )
  }

  const onSaveAddedProducts = () => {
    if (!wasChanges) setWasChanges(true)
    setMenuSections(
      menuSections.map((section) =>
        section._id === activeSectionId
          ? { ...section, products: [...singleSectionProducts] }
          : section
      )
    )
    setSingleSectionProducts([])
    handleAddProductsClose()
  }

  const onSave = () => {
    try {
      setIsLoading(true)
      const data = {
        inputMenu: menuSections.map(({ _id, name, products, __typename }) => {
          if (!name.length) throw new Error('Nazwa sekcji jest wymagana')

          return __typename
            ? { name, products: products.map((i) => i._id), _id }
            : { name, products: products.map((i) => i._id) }
        }),
      }

      handleRestUpdate(
        data,
        () => {
          toast.dark('Dane restauracji zostały zaktualizowane')
          setWasChanges(false)
          setIsLoading(false)
        },
        () => {
          setIsLoading(false)
        }
      )
    } catch (err) {
      toast.warning(err.message)
    }
  }

  return {
    loading,
    error,
    isLoading,

    menuSections,
    wasChanges,
    setWasChanges,

    activeSectionId,
    isAddProductsOpen,
    singleSectionProducts,

    setMenuSections,
    setSingleSectionProducts,

    handleAddProductsOpen,
    handleAddProductsClose,
    onAddSection,
    onDeleteSection,
    onDeleteProduct,
    onNameChange,
    onSaveAddedProducts,
    onSave,
  }
}
