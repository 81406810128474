export const ORDER_STATUSES = {
  ORDER_IN_CART: 'W koszyku',
  WAITING_FOR_ACCEPT: 'Oczekujące',
  LACK_OF_FUNDS: 'Brak środków',
  DECLINED_BY_REST: 'Odrzucone',
  DECLINED_BY_CLIENT: 'Odrzucone',
  PARTIALLY_ACCEPTED: 'Zaakceptowane ⚖️',
  ACCEPTED: 'Zaakceptowane',
  CONFIRMED: 'Potwierdzone',
  PARTIALLY_CONFIRMED: 'Potwierdzone ⚖️',
  FINISHED_BY_USER: 'Zakończone 👨‍💼',
  FINISHED: 'Zakończone 🍴 ',
  AUTO_FINISHED: 'Zakończone ⚙️',
  DECLINED_FINISH_BY_REST: 'Zwrócone', // Zwrot? Anulowane ?
  ANNUL_BY_CLIENT: 'Anulowane 👨‍💼',
  ANNUL_BY_REST: 'Anulowane 🍴 ',
  AUTO_ANNULLED_BECAUSE_OF_PAYMENT: 'Anulowane ⚙️',
}

export const ORDER_STATUS_COLOR = {
  ORDER_IN_CART: '#D3D7E0', // W koszyku
  WAITING_FOR_ACCEPT: '#B9BCC4', // Oczekujące
  LACK_OF_FUNDS: '#FF0000', // Brak środków
  DECLINED_BY_REST: '#FF0000', // Odrzucone
  DECLINED_BY_CLIENT: '#FF0000', // Odrzucone - Klient'
  PARTIALLY_ACCEPTED: '#FFAA00', // Zaakceptowane Częściowo
  ACCEPTED: '#FFD500', // Zaakceptowane
  CONFIRMED: '#00B30F', // Potwierdzone
  PARTIALLY_CONFIRMED: '#006609', // Potwierdzone Częściowo
  FINISHED_BY_USER: '#343340', // Zakończone - Klient - o tutaj można wrzucić napiwek i recenzję
  FINISHED: '#343340', // Zakończone - Restauracja
  AUTO_FINISHED: '#343340', // Zakończone - System
  DECLINED_FINISH_BY_REST: '#800000', // Zwrócone ? Anulowane ?
  ANNUL_BY_CLIENT: '#800000', // Anulowane - Klient,
  ANNUL_BY_REST: '#800000', // Anulowane - Restauracja,
  AUTO_ANNULLED_BECAUSE_OF_PAYMENT: '#800000', // Anulowane - System,
}

export const PRODUCT_STATUSES = {
  WAITING_FOR_ACCEPT: 'Oczekujący',
  WAITING_FOR_PAYMENT: 'Do Opłaty',
  WAITING_FOR_ACCEPT_BY_USER: 'Do Akceptacji',
  DECLINED_BY_REST: 'Odrzucony',
  DECLINED_BY_CLIENT: 'Niezgodny',
  ACCEPTED: 'Zaakceptowany',
  CONFIRMED: 'Potwierdzony',
  PREPARING: 'Realizowany',
  DONE: 'Gotowy',
  FINISHED: 'Dostarczony',
  ANNULED: 'Anulowany',
  AUTO_ANNULED_BECAUSE_OF_NO_PAYMENT: 'Anulowany ⚙️',
}

export const PRODUCT_STATUS_COLOR = {
  WAITING_FOR_ACCEPT: '#B9BCC4', // Oczekujący
  WAITING_FOR_PAYMENT: '#FF0000', // Do Opłaty
  WAITING_FOR_ACCEPT_BY_USER: '#B9BCC4', // Do Akceptacji
  DECLINED_BY_REST: '#FF0000', // Odrzucony
  DECLINED_BY_CLIENT: '#FF0000', // Niezgodny
  ACCEPTED: '#FFD500', // Zaakceptowany
  CONFIRMED: '#FFD500', // Potwierdzony
  PREPARING: '#FF8000', // Realizowany
  DONE: '#00B30F', // Gotowy
  FINISHED: '#343340', // Dostarczony
  ANNULED: '#800000', // Anulowany
  AUTO_ANNULED_BECAUSE_OF_NO_PAYMENT: '#800000', // Anulowany - System
}

// 🍴🏃⌛⚙️⚖️

export const PRODUCT_END_STATUSES = [
  'FINISHED',
  'DECLINED_BY_REST',
  'DECLINED_BY_CLIENT',
  'ANNULED',
]

export const prodColorFn = (status) => {
  let color = '#fff'

  const colors = {
    ...PRODUCT_STATUS_COLOR,
  }

  return colors[status[status.length - 1].name]
    ? colors[status[status.length - 1].name]
    : color
}

export const orderColorFn = (status) => {
  let color = '#fff'
  let backgroundColor = '#B9BCC4'

  const backgroundColors = {
    ...ORDER_STATUS_COLOR,
  }

  return {
    color,
    backgroundColor: backgroundColors[status[status.length - 1].name]
      ? backgroundColors[status[status.length - 1].name]
      : backgroundColor,
  }
}
