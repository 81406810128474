/** @jsx jsx */
import React, { useContext } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { NavLink } from 'react-router-dom'

import HomeIcon from '@material-ui/icons/Home'
import RestaurantIcon from '@material-ui/icons/Restaurant'
import FastfoodIcon from '@material-ui/icons/Fastfood'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'

import { ButtonBase, Hidden } from '@material-ui/core'
import { colors, s, alpha } from '../../style'

export default function LabelBottomNavigation() {
  return (
    <React.Fragment>
      <ButtonBase css={sNav}>
        <div css={sContainer}>
          <NavLink to='/rest/restaurant' activeStyle={sActiveNavLink}>
            <RestaurantIcon fontSize='inherit' color='inherit' />
          </NavLink>
          <NavLink to='/rest/products' activeStyle={sActiveNavLink}>
            <FastfoodIcon fontSize='inherit' color='inherit' />
          </NavLink>
          <NavLink to='/rest/menu' activeStyle={sActiveNavLink}>
            <MenuBookIcon fontSize='inherit' color='inherit' />
          </NavLink>
          <NavLink to='/rest/payments' activeStyle={sActiveNavLink}>
            <AccountBalanceWalletIcon fontSize='inherit' color='inherit' />
          </NavLink>
          <Hidden smDown>
            <NavLink to='/rest/access' activeStyle={sActiveNavLink}>
              <SupervisorAccountIcon fontSize='inherit' color='inherit' />
            </NavLink>
          </Hidden>
          <NavLink exact to='/rest' activeStyle={sActiveNavLink}>
            <HomeIcon fontSize='inherit' color='inherit' />
          </NavLink>
        </div>
      </ButtonBase>
    </React.Fragment>
  )
}

const sNav = {
  transition: 'transform 0.3s cubic-bezier(0.25, 1, 0.5, 1)',
  boxSizing: 'border-box',
  position: 'fixed',
  bottom: 0,
  left: 0,
  zIndex: 1100,
  width: '100vw',
  height: 72,
  backgroundColor: '#ffffff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '2rem 2rem 0 0',
  boxShadow: `0px -4px 16px ${colors.text.concat(alpha[8])}`,
  color: colors.orange,
  cursor: 'default',

  [s.sm]: {
    boxShadow: `0px -4px 64px ${colors.text.concat(alpha[8])}`,
  },

  '.active:hover': {
    [s.hover]: {
      filter: 'saturate(150%) contrast(120%)',
    },
  },

  '.MuiTouchRipple-rippleVisible': {
    animationDuration: '1.2s',
  },
}

const sLink = {
  transition:
    'color 0.2s ease-out, background-color 0.2s ease-out, filter 0.2s ease-out',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 50,
  height: 50,
  color: colors.text,
  fontSize: 32,
  borderRadius: '50%',
  position: 'relative',

  '&:hover': {
    backgroundColor: colors.gray200,
    color: colors.text.concat(alpha[60]),
  },

  svg: {
    maxWidth: 26,
    maxHeight: 26,
  },
}

const sContainer = {
  width: '100%',
  height: '100%',
  maxWidth: 960,
  display: 'flex',

  alignItems: 'center',
  // backgroundColor: '#fff',
  padding: '0 1.5rem',

  [s.xs]: { justifyContent: 'space-between' },
  [s.sm]: { justifyContent: 'space-evenly' },

  a: { ...sLink },
}

const sActiveNavLink = {
  backgroundColor: colors.orange150,
  color: colors.orange,

  '&:hover': {
    filter: 'brightness(120%) contrast(1.5)',
  },
}
