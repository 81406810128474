/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars


export default ({
  address,
  city,
  firmName,
  nip,
  zipCode,
  textHeader
}) => {

  const invoiceData = {
    "Nazwa firmy": firmName,
    "NIP": nip,
    "Adres": address,
    "Miasto": city,
    "Kod pocztowy": zipCode
  }


  return (
    <div css={{
      margin: '8px 0',
      border: '1px solid #777',
      borderRadius: 8,
      padding: 8
    }}>
      {textHeader && <h3>{textHeader}</h3>}

      {Object.entries(invoiceData).map(([key, value]) => (
        <DataContainer key={key}>
          <p css={{ width: 180 }}>{key}: </p>
          <p css={{ width: 'calc(100% - 180px)' }}>{value}</p>
        </DataContainer>
      ))}
    </div>
  )
}


const DataContainer = ({ children }) => (
  <div css={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '4px 0'
  }}>
    {children}
  </div>
)