import { gql } from '@apollo/client'

export const GET_RESTAURANT = gql`
  query ezrRestaurant($restId: ID!) {
    ezrRestaurant(restId: $restId) {
      _id
      name
      consumptionTypes
      description
      coins
      avatarUrl
      photoUrl
      categories {
        _id
        name
        type
      }
      address
      location {
        coordinates
      }
      menuSections {
        _id
        name
        products {
          _id
          name
          categories {
            name
          }
          description
          photoUrl
          price
          ingredients {
            _id
            name
            type
          }
        }
      }
      products {
        _id
        name
        price
        description
        cookTime
        cookMethod
        keywords
        flavors
        photoUrl
        categories {
          _id
          name
          type
        }
        ingredients {
          _id
          name
          type
        }
      }
      openningHours {
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
        sun {
          open
          close
        }
      }
      isActive
      deliverySettings {
        freeDeliveryAmount
        deliveryRadius
        minAmount
        deliveryCost
        availableHours {
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
          sun {
            open
            close
          }
        }
      }
    }
  }
`

export const GET_RESTAURANTS = gql`
  query ezrRestaurants {
    ezrRestaurants {
      _id
      name
      description
      address
      avatarUrl
      coins
      isActive
      photoUrl
      categories {
        _id
        name
        type
      }
    }
  }
`

export const GET_SALES_STATISTICS = gql`
  query ezrSalesStatistics($restId: ID!) {
    ezrSalesStatistics(restId: $restId) {
      label
      value
    }
  }
`

export const GET_ORDERS_AMOUNT_STATISTICS = gql`
  query ezrOrdersAmountStatistics($restId: ID!) {
    ezrOrdersAmountStatistics(restId: $restId) {
      label
      value
    }
  }
`

export const GET_WAITER_DASHBOARD_ACCESS = gql`
  query ezrWaiterDashboardAccess($restId: ID!) {
    ezrWaiterDashboardAccess(restId: $restId) {
      login
    }
  }
`
