import React, { createContext, useContext } from 'react'
import useModifierGroup from '../hooks/useModifierGroup'


function noop() { };
export const ModifierGroup = createContext({
  state: {},
  onChangeState: {},

  modifierItems: [],
  onAddModifierItem: noop,
  onDeleteModifierItem: noop,
  onChangeModifierItem: {},

  amountRules: {},
  onChangeAmountRules: {}
})

export const useModifierGroupContext = () => useContext(ModifierGroup)



export default ({ children }) =>
  <ModifierGroup.Provider value={{ ...useModifierGroup() }}>
    {children}
  </ModifierGroup.Provider>