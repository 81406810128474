import React, { createContext, useContext } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import useCartWidget from '../hooks/useCartWidget'
import useCartHandle from '../hooks/useCartHandle'
import useOrderMutations from '../../../hooks/useOrders.mutations'
import { ezwRoutePrefix } from '../../settings'

function noop() {}
export const AddProductsContext = createContext({
  orderId: '',
  // useCartWidget
  isCartOpen: false,
  toggleDrawer: noop,
  // useCartHandle
  amountPrice: '',
  cart: [],
  addProduct: noop,
  deleteProduct: noop,

  onSendProducts: noop,
})

export const useAddProductsContext = () => useContext(AddProductsContext)

export default ({ children, orderId }) => {
  const history = useHistory()
  const { handleAddProducts } = useOrderMutations()
  const cartWidget = useCartWidget()
  const cartHandle = useCartHandle()

  const onSendProducts = () => {
    if (!cartHandle?.cart?.length) {
      toast.warning('Dodaj produkty do zamówienia')
      return
    }
    const data = {
      orderId,
      productsArray: cartHandle.cart.map(({ _id, amount }) => ({
        prodId: _id,
        amount,
      })),
    }
    const cb = () => {
      toast.success('Produkty zostały dodane do zamówienia')
      history.push(`${ezwRoutePrefix}/orders-active`)
    }

    handleAddProducts(data, cb)
  }

  return (
    <AddProductsContext.Provider
      value={{
        orderId,
        ...cartWidget,
        ...cartHandle,
        onSendProducts,
      }}>
      {children}
    </AddProductsContext.Provider>
  )
}
