/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useActiveOrdersContext } from '../context/orders-active.context'
import useOrdersMutations from '../../../hooks/useOrders.mutations'
import { WaiterNote } from '../../../components'


export default () => {
  const { waiterNote, _id } = useActiveOrdersContext()
  const { handleChangeWaiterNote } = useOrdersMutations()

  const onSave = async (note) => {
    await handleChangeWaiterNote({ note, orderId: _id })
  }

  return (
    <WaiterNote
      note={waiterNote}
      onSave={onSave}
      size="small"
    />
  )
}