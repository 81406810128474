import { gql } from '@apollo/client'


export const SUBS_ADMIN_CHAT_CLIENT = gql`
  subscription adminChatClient($chatId: ID!) {
    adminChatClient(chatId: $chatId) {
      _id
      message
      fromUserId
    }
  }
`

export const SUBS_ADMIN_CHAT_ADMIN = gql`
  subscription adminChatAdmin {
    adminChatAdmin {
      _id
      message
      fromUserId
    }
  }
`

export const GET_ADMIN_ALL_ACTIVE_CHATS = gql`
  query adminAllActiveChats {
    adminAllActiveChats {
      _id
      userId {
        _id
        personal {
          name
          surname
        }
      }
      messages {
        _id
        message
        fromUserId
        createdAt
        isRead
      }
    }
  }
`

export const GET_EZC_ADMIN_CHAT = gql`
  query ezcAdminChat {
    ezcAdminChat {
      _id
      messages {
        _id
        message
        fromUserId
        createdAt
        isRead
      }
    }
  }
`

export const ADMIN_CHAT_ADD_MSSG = gql`
  mutation adminChatAddMssg($chatId: ID, $message: String!) {
    adminChatAddMssg(chatId: $chatId, message: $message) {
      _id
      success
    }
  }
`

export const ADMIN_CHAT_READ_MSSGS = gql`
  mutation adminChatReadMssgs($chatId: ID!) {
    adminChatReadMssgs(chatId: $chatId) {
      success
    }
  }
`

export const ADMIN_CHAT_FINISH = gql`
  mutation adminChatFinish($chatId: ID!) {
    adminChatFinish(chatId: $chatId) {
      success
    }
  }
`