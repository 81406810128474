/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'

import useUserMutations from '../../hooks/useUser.mutations'
import Headline from '../../../components/Headline/Headline'
import {
  sGrid,
  sOuter,
  sDescription,
  sContainerOuter,
  sPaddingTop,
} from '../../../style/style'
import { Button } from '../../../components/Elements'
import { s } from '../../../style'
import { Input } from './components'
import { Grid } from '@material-ui/core'
import Spinner from '../../../components/Spinner'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'

export default function () {
  const [isLoading, setIsLoading] = useState(false)
  const [inputValues, setInputValues] = useState({ first: '' })
  const { handleNewPassword } = useUserMutations()
  const history = useHistory()
  const { token } = useParams()

  const onChange = (e) =>
    setInputValues({ ...inputValues, [e.target.name]: e.target.value })
  const onSubmit = () => {
    setIsLoading(true)
    handleNewPassword({ token, password: inputValues.first }, () => {
      setIsLoading(false)
      toast.success('Hasło zostało zmienione! Zaloguj się do swojego konta')
      history.push('/login')
    })
  }

  return (
    <React.Fragment>
      <TopNav title={'Nowe Hasło'} />
      <div css={sOuter}>
        <Headline>Nowe Hasło</Headline>
        <Grid container css={sGrid}>
          <p css={sDescription}>Ustaw nowe hasło do twojego konta Eatzon</p>
        </Grid>
      </div>

      <Grid container css={[sContainerOuter, sGrid, sPaddingTop]}>
        <div css={[sFormContainer]}>
          <Input
            onChange={onChange}
            name='first'
            value={inputValues.first}
            type='password'
            placeholder='Podaj nowe hasło'
          />

          <Button w100 action={isLoading ? () => {} : onSubmit}>
            {isLoading ? (
              <Spinner size={1.5} addCss={{ color: '#fff' }} />
            ) : (
              'Zapisz hasło'
            )}
          </Button>
        </div>
      </Grid>
    </React.Fragment>
  )
}

const sFormContainer = {
  [s.sm_down]: { paddingBottom: '2rem' },
  paddingBottom: '1.5rem',
}
