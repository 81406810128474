/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import {
  sBackground,
  sContainer,
} from '../../../components/Widget/Widget.component'
import { s, colors, alpha } from '../../../style'
import { Button } from '../../../components/Elements'
import { sButtonFixed } from '../../pages/orders/order-active.page'
import { cookMethods, flavors } from '../../../constants/types'
import FilterGroup from './FilterGroup'
import FiltersChosen from './FiltersChosen'
import FilterSliders from './FilterSliders'

const SearchFilter = ({
  getCategories,
  categories,
  addKeyToFilter,
  prodFilter,
  ingredientsList,
  setProdFilter,
  onSubmit,
  setFocus,
  onFloatChange,
}) => {
  const [open, setOpenState] = useState(false)

  const setOpen = (value) => {
    !open && setFocus(false)
    setOpenState(value)
  }

  const flavorsTypesKeys = Object.keys(flavors)
  const cookMethodsTypes = Object.keys(cookMethods)

  const filtersGroups = [
    {
      name: 'Kuchnia',
      data: getCategories('cuisine'),
      onChoose: addKeyToFilter('categories'),
      filterData: prodFilter && prodFilter.categories,
    },
    {
      name: 'Danie',
      data: getCategories('popular').concat(getCategories('mealtype')),
      onChoose: addKeyToFilter('categories'),
      filterData: prodFilter && prodFilter.categories,
    },
    {
      name: 'Pora dnia',
      data: getCategories('daytime'),
      onChoose: addKeyToFilter('categories'),
      filterData: prodFilter && prodFilter.categories,
    },
    {
      name: 'Dieta',
      data: getCategories('diet'),
      onChoose: addKeyToFilter('categories'),
      filterData: prodFilter && prodFilter.categories,
    },
    {
      name: 'Smak',
      data: flavorsTypesKeys,
      onChoose: addKeyToFilter('flavors'),
      filterData: prodFilter && prodFilter.flavors,
      types: flavors,
    },
    {
      name: 'Metoda gotowania',
      data: cookMethodsTypes,
      onChoose: addKeyToFilter('cookMethod'),
      filterData: prodFilter && prodFilter.cookMethod,
      types: cookMethods,
    },
    {
      name: 'Składniki',
      data: ingredientsList,
      onChoose: addKeyToFilter('ingredients'),
      filterData: prodFilter && prodFilter.ingredients,
    },
  ]

  const isDisabled = prodFilter
    ? Object(prodFilter).values?.reduce((acc, e) =>
        e.must?.length === 0 && e.mustNot?.length === 0
          ? (acc = true)
          : (acc = false)
      )
    : true

  return (
    <React.Fragment>
      <div
        css={[sBackground, !open && sBackgroundHidden]}
        onClick={() => setOpen(!open)}
      />

      <div css={sButtonFixed}>
        <Button
          w100
          w100Desktop
          action={() => setOpen(!open)}
          buttonTransparent>
          FILTRUJ
        </Button>
      </div>
      <div css={[sContainer, sContainer2, open && sContainerShown]}>
        <FiltersChosen
          setOpen={setOpen}
          open={open}
          prodFilter={prodFilter}
          categories={categories}
          ingredientsList={ingredientsList}
          addKeyToFilter={addKeyToFilter}
          onSubmit={onSubmit}
        />

        <div css={sPadding}>
          <p css={sSubtitle}>
            Zaznacz kategorie i składniki, które musi mieć Twoje danie. Kliknij
            dwukrotnie, aby zaznaczyć niechciane elementy.
          </p>
          <div css={sFilterGroups}>
            {filtersGroups.map((filterGroup, id) => (
              <FilterGroup key={id} {...filterGroup} />
            ))}
            <FilterSliders
              prodFilter={prodFilter}
              onFloatChange={onFloatChange}
            />
          </div>
          <div
            css={{
              padding: '1rem 0 3rem',
              [s.md]: {
                display: 'flex',
                width: '100%',
                padding: '1rem 0 5rem',
              },
            }}>
            <Button dimmed w100 action={() => setProdFilter(null)}>
              Resetuj
            </Button>
            <div
              css={{ padding: '1rem 0 0', [s.md]: { paddingLeft: '2rem' } }}
            />
            {/* <Button w100 w100Desktop dimmed={isDisabled} action={onSubmit}>
              {isDisabled ? 'Wybierz filtry' : 'Pokaż Wyniki'}
            </Button> */}
          </div>
        </div>
      </div>
      <div
        css={[sButtonResults, (isDisabled || !open) && sButtonResultsDisabled]}>
        <Button w100 w100Desktop dimmed={isDisabled} action={onSubmit}>
          {isDisabled ? 'Wybierz filtry' : 'Pokaż Wyniki'}
        </Button>
      </div>
    </React.Fragment>
  )
}

export const sBackgroundHidden = { opacity: 0, pointerEvents: 'none' }

const sFilterGroups = {
  display: 'flex',
  flexDirection: 'column',
}

const sButtonResults = {
  width: '100%',
  maxWidth: 960,
  padding: '0 1.5rem',
  transition: 'transform 0.5s cubic-bezier(0.22, 1, 0.36, 1)',
  position: 'fixed',
  bottom: 72,
  left: '50%',
  transform: 'translate(-50%, 0%)',
  zIndex: 1002,
  [s.md]: {
    padding: '0 2rem',
  },
}

const sButtonResultsDisabled = {
  transform: 'translate(-50%, 100%)',
}

const sSubtitle = {
  color: colors.text.concat(alpha[30]),
  fontSize: '0.75rem',
  lineHeight: '1.618em',
  margin: '2rem 0.5rem 1rem',
  [s.md]: {
    fontSize: '0.875rem',
  },
}

const sContainer2 = {
  width: 'calc(100% + 3px)',
  zIndex: 1001,
  transform: 'translate(calc(-50% - 1px), calc(100% - 138px))',
  // padding: '3rem 2rem 4.5rem',
  // [s.sm_down]: {
  //   padding: '2rem 1.5rem 4.5rem',
  // },
  padding: 0,
  [s.sm_down]: { padding: 0 },
  boxShadow: `0px -4px 16px ${colors.text.concat(alpha[8])}`,
  [s.md]: {
    transform: 'translate(calc(-50% - 1px), calc(100% - 158px))',
  },
}

const sPadding = {
  padding: '0 2rem 4.5rem',
  [s.sm_down]: {
    padding: '0 1.5rem 4.5rem',
  },
  // position: 'relative',
  // top: 0,
  // left: 0,
  // zIndex: 3,
}

const sContainerShown = {
  transform: 'translate(calc(-50% - 1px), 0%)',
  [s.md]: {
    transform: 'translate(calc(-50% - 1px), 0%)',
  },
}

export default SearchFilter
