/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import Headline from '../../../components/Headline/Headline'
import { Grid } from '@material-ui/core'
import { sBottomLinks, sOuter, sGrid } from '../../../style/style'
import { ButtonBorder } from '../../../components/Elements'
import { s } from '../../../style'
import { Link } from 'react-router-dom'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'

const Terms = () => {
  return (
    <>
      <TopNav title={'Regulamin Platformy Eatzon'} />
      <div css={sOuter}>
        <Headline>Regulamin</Headline>
        <Grid container css={sGrid}>
          <Doc />
          <Grid item xs={12} css={sBottomLinks}>
            <ButtonBorder link={'/privacy'}>Polityka Prywatności</ButtonBorder>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export const sDoc = {
  fontSize: 14,
  // 'h2, h3, p, ol, li': {
  //   fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
  //   'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif`,
  // },
  'li::marker': {
    padding: '1rem',
  },
  'p, ol': {
    fontWeight: 400,
  },
  li: {
    marginLeft: '4rem',
    [s.sm_down]: { marginLeft: '2rem' },
    marginBottom: '0.5rem',
    listStyleType: 'decimal',
    ol: {
      marginTop: '0.5rem',
    },
    li: {
      listStyleType: 'lower-latin',
      li: {
        listStyleType: 'lower-roman',
      },
    },
  },
  h2: {
    marginTop: '4rem',
    marginBottom: '1rem',
    [s.sm_down]: { marginTop: '2rem' },
  },
  h3: {
    marginLeft: '3rem',
    [s.sm_down]: { marginLeft: '1rem' },
    marginTop: '2rem',
    marginBottom: '1rem',
  },
  p: {
    marginBottom: '1rem',
  },
}

const Doc = () => {
  return (
    <div css={sDoc}>
      <h2>REGULAMIN UŻYTKOWANIA PLATFORMY EATZON</h2>
      <p>
        Niniejszy Regulamin świadczenia usług drogą elektroniczną (dalej jako: „
        <strong>Regulamin</strong>”) określa warunki świadczenia usług za
        pośrednictwem serwisu internetowego Eatzon(dalej jako: „
        <strong>Platforma</strong>”).
      </p>
      <h2>I. DEFINICJE</h2>
      <p>
        Na potrzeby Regulaminu, przyjmuje się następujące znaczenie poniższych
        pojęć
      </p>
      <ol>
        <li>
          Usługodawca - Eatzon Sp. z o.o. z siedzibą w Lublinie ul. Mełgiewska
          7-9, 20-952 która stworzyła, rozwija i zarządza Platformą
          Technologiczną, oraz dodatkowo w stosownych przypadkach i na żądanie
          Użytkowników i klientów korzystających z usług gastronomicznych i
          cateringowych Restauratora pełni rolę pośrednika w dostawie dań i
          napojów
        </li>
        <li>
          Platforma Technologiczna - Aplikacja webowa i mobilna Eatzon, strona
          www, i inne narzędzia i urządzenia internetowe dostępne na stronie
          Usługodawcy Eatzon.pl umożliwiająca zawieranie Umów drogą
          elektroniczną pomiędzy Restauratorem a Użytkownikiem, zarządzanie
          zawartymi Umowami, założenie i korzystanie z funkcjonalności Konta
          Użytkownika.
        </li>
        <li>
          Restaurator - Podmiot prowadzący działalność gospodarczą w zakresie
          usług gastronomicznych i cateringowych oraz handlowych związanych
          bezpośrednio z żywnością, publikujący swoją Ofertę na Platformie w
          celu umożliwienia Użytkownikom złożenia Zamówienia w celu zawarcia
          umowy
        </li>
        <li>
          Użytkownik – konsument w rozumieniu art. 22 38 ustawy z dnia 26
          kwietnia 1964 r. Kodeks cywilny (tj. Dz.U. z 2020 r. poz. 1740 ze zm.)
          lub inny podmiot, który korzysta z Platformy, a także przy użyciu
          Platformy złożył Zamówienie w celu zawarcia umowy z Restauratorem
        </li>
        <li>
          Konto Użytkownika - indywidualny dla każdego Użytkownika panel,
          uruchomiony na jego rzecz i żądanie z chwilą dokonania Rejestracji na
          Platformie; W Koncie Użytkownika gromadzone są informacje na jego
          temat oraz informacje o jego działaniach w ramach Platformy;
        </li>
        <li>
          Portfel - funkcjonalność Konta Użytkownika umożliwiająca dokonywanie
          płatności na Platformie.{' '}
        </li>
        <li>
          Rejestracja – czynność faktyczna dokonana przez Użytkownika w sposób
          określony w Regulaminie w celu założenia Konta Użytkownika i złożenia
          Zamówienia.
        </li>
        <li>
          Oferta - Wybór Produktów i usług, w tym opcji Odbioru, prezentowanych
          przez poszczególnych Restauratorów, które Użytkownik może zamówić
          korzystając z Platformy
        </li>
        <li>
          Produkt - posiłek w postaci produktu, dania lub napoju znajdujący się
          w Ofercie Restauracji.{' '}
        </li>
        <li>
          Odbiór - możliwy do wyboru przez Użytkownika sposób dostarczenia
          przedmiotu Umowy określony w Ofercie Restauratora.{' '}
        </li>
        <li>
          Zamówienie – oświadczenie woli Użytkownika złożone za pośrednictwem
          Platformy i wyrażające bezpośrednią wolę zawarcia Umowy pomiędzy
          Użytkownikiem a Restauratorem na wybraną przez niego Ofertę
          Restauratora
        </li>
        <li>
          Preautoryzacja - transakcja płatnicza polegająca na czasowym
          zablokowaniu na zlecenie posiadacza karty środków na rachunku
          prowadzonym dla karty, aby zabezpieczyć dostępne środki na pokrycie
          zobowiązania.
        </li>
        <li>
          Umowa – oznacza stosunek prawny zawarty pomiędzy Użytkownikiem a
          Restauratorem na podstawie złożonego przez Użytkownika Zamówienia
        </li>
        <li>
          Koszyk – usługa polegająca na wyświetlaniu listy wybranych przez
          Użytkownika składowych Oferty oraz szczegółów na temat warunków zakupu
          takich jak cena jednostkowa i łączna
        </li>
        <li>
          Konsument – Użytkownik będący osobą fizyczną, zawierający z
          Restauratorem umowę niezwiązaną bezpośrednio z jego działalnością
          gospodarczą lub zawodową; postanowienia dotyczące Konsumenta mają
          zastosowanie odpowiednio także do osoby fizycznej zawierającej z
          Restauratorem umowę bezpośrednio związaną z jej działalnością
          gospodarczą, gdy z treści tej umowy wynika, że nie posiada ona dla tej
          osoby charakteru zawodowego, wynikającego w szczególności z przedmiotu
          wykonywanej przez nią działalności gospodarczej, udostępnionego na
          podstawie przepisów o Centralnej Ewidencji i Informacji o Działalności
          Gospodarczej.
        </li>
      </ol>
      <h2>II. POSTANOWIENIA OGÓLNE</h2>
      <ol>
        <li>
          Niniejszy Regulamin określa zasady dostępu i korzystania z Platformy.
          Warunkiem korzystania z Platformy jest uprzednie zapoznanie się z
          postanowieniami Regulaminu i ich akceptacja.
        </li>
        <li>
          Usługodawca udostępnia Platformę za pośrednictwem której Użytkownik ma
          możliwość zamówienia produktów wchodzących w skład Oferty Restauracji.
        </li>
        <li>
          Minimalne warunki umożliwiające korzystanie z Platformy to:
          <ol>
            <li>Dostęp do urządzenia podłączonego do sieci Internet, </li>
            <li>Zainstalowany i aktualny system operacyjny, </li>
            <li>
              Przeglądarka internetowa umożliwiająca wyświetlanie dokumentów
              HTML,
            </li>
            <li>
              Włączona opcja akceptowania plików cookies w przypadku wybranej
              usługi,
            </li>
            <li>Możliwość instalowania aplikacji zewnętrznych,</li>
            <li>
              Posiadanie aktywnego konta poczty elektronicznej (adres e-mail).
            </li>
          </ol>
        </li>
        <li>
          Dla prawidłowego działania Platforma wykorzystuje dane i
          funkcjonalności urządzenia mobilnego, na którym jest zainstalowana.
          Przy instalacji Platformy lub przy jej pierwszym użyciu Użytkownik
          zostanie poproszony o wyrażenie zgody na niezbędny do prawidłowego
          działania Platformy dostęp do:
          <ol>
            <li>dane o lokalizacji</li>
          </ol>
        </li>
        <li>
          Fakultatywnie, w celu korzystania z dodatkowych funkcjonalności
          Platformy, Użytkownik może wyrazić także zgodę na dostęp Platformy do
          nieobligatoryjnych danych takich jak:{' '}
          <ol>
            <li>dostęp do galerii,</li>
            <li>dostęp do aparatu,</li>
            <li>dostęp do mikrofonu,</li>
            <li>dostęp do kontaktów,</li>
            <li>dostęp do preferencji przeglądarki użytkownika</li>
          </ol>
        </li>
        <li>
          Użytkownik jest obowiązany do korzystania z Platformy w sposób zgodny
          z prawem i dobrymi obyczajami, mając na uwadze poszanowanie dóbr
          osobistych oraz praw autorskich i praw własności intelektualnej
          Usługodawcy i osób trzecich.{' '}
        </li>
        <li>
          Usługodawca oświadcza, że korzystanie z usług świadczonych drogą
          elektroniczną może się wiązać z zagrożeniem utraty poufności,
          dostępności i integralności danych Użytkownika w związku z
          nieuprawnionym działaniem osób trzecich. Z tego względu Użytkownicy
          powinni stosować właściwe środki techniczne i organizacyjne mające na
          celu minimalizację zagrożeń, w szczególności programy antywirusowe
          oraz zasadę nieujawniania osobom postronnym hasła do Konta
          Użytkownika.
        </li>
      </ol>
      <h2>III. ZAKRES ŚWIADCZONYCH USŁUG</h2>
      <ol>
        <li>
          Usługodawca pełni rolę pośrednika w zawarciu Umowy pomiędzy
          Użytkownikiem a Restauratorem z wykorzystaniem Platformy.{' '}
        </li>
        <li>
          Usługodawca udostępnia Platformę, za pośrednictwem której prezentuje w
          imieniu i na rzecz Restauratora jej Ofertę w celu umożliwienia
          Użytkownikom jej przeglądania, złożenia Zamówienia i zawarcia Umowy.{' '}
        </li>
        <li>
          Restaurator jest zobowiązany do umieszczenia w Ofercie wszelkich
          wymaganych właściwymi przepisami prawa informacji o składnikach i
          alergenach produktów wchodzących w skład jego Oferty. Restaurator
          ponosi wyłączną odpowiedzialność za informacje umieszczone w Ofercie,
          w szczególności za ich zgodność ze stanem faktycznym.{' '}
        </li>
        <li>
          Platforma umożliwia Użytkownikowi wybór opcji Odbioru złożonego
          Zamówienia zgodnie z Ofertą Restauratora.{' '}
        </li>
        <li>
          Usługodawca udostępnia Użytkownikom usługę prowadzenia Konta
          Użytkownika na zasadach określonych w punkcie IV Regulaminu.
        </li>
      </ol>
      <h2>IV. KONTO UŻYTKOWNIKA</h2>
      <ol>
        <li>Przeglądanie Oferty nie wymaga założenia Konta Użytkownika.</li>
        <li>
          Konto Użytkownika jest niezbędne do korzystania z Usług takich jak:
          <ol>
            <li>złożenie Zamówienia,</li>
            <li>dostęp do historii Zamówień,</li>
            <li>Portfel,</li>
            <li>wyrażanie opinii na temat Restauracji i Produktów, </li>
            <li>statystyki, </li>
            <li>czat.</li>
          </ol>
        </li>
        <li>
          W celu Rejestracji należy wypełnić formularz rejestracyjny
          udostępniony na Platformie oraz wskazać dane do uwierzytelnienia.
          Użytkownik zobowiązany jest do należytej ochrony danych
          uwierzytelniających przed nieuprawnionym dostępem, w szczególności
          zobowiązany jest do zachowania ich w tajemnicy i nieujawniania osobom
          trzecim.{' '}
        </li>
        <li>
          Użytkownik może dokonać Rejestracji także za pośrednictwem swojego
          konta w serwisach zewnętrznych, z którymi integrację umożliwia
          Platforma. W takiej sytuacji Użytkownik wyraża zgodę i poleca
          serwisowi zewnętrznemu, by udostępnił określone dane Eatzon w celu
          założenia i autoryzacji dostępu do Konta Użytkownika na Platformie. Po
          dokonaniu autoryzacji na stronie serwisu zewnętrznego, użytkownik
          zwrotnie jest przekierowany na Platformę, w której zakładane jest
          Konto Użytkownika z odnośnikiem do konta w serwisie zewnętrznym.{' '}
        </li>
        <li>
          Po przesłaniu wypełnionego formularza rejestracyjnego Użytkownik
          otrzyma niezwłocznie potwierdzenie dokonania rejestracji na podany w
          formularzu adres e-mail. Z chwilą wysłania wiadomości, o której mowa w
          zdaniu poprzedzającym, między Użytkownikiem a Usługodawcą zostaje
          zawarta umowa o świadczenie drogą elektroniczną usługi prowadzenia
          Konta Użytkownika na zasadach określonych w niniejszym Regulaminie. W
          przypadku Rejestracji za pośrednictwem konta w serwisie zewnętrznym, o
          którym mowa w punkcie 4, umowa o świadczenie drogą elektroniczną
          usługi prowadzenia Konta Użytkownika zostanie zawarta z chwilą
          akceptacji Regulaminu przez Użytkownika.
        </li>
        <li>
          Osoby do 16 roku życia nie mogą samodzielnie korzystać z Platformy ani
          dokonać Rejestracji - w takim przypadku Rejestrację musi zatwierdzić
          rodzic lub opiekun prawny. Jeżeli zatwierdzenie nie zostanie dokonane
          w terminie 30 dni, Rejestracja nie dojdzie do skutku.{' '}
        </li>
        <li>
          Użytkownik jest zobowiązany do tego, by dane dotyczące jego tożsamości
          i prawnej zdolności do działania podane w formularzu rejestracyjnym
          były prawdziwe, dokładne, aktualne i kompletne. Użytkownik ponosi
          odpowiedzialność za podanie danych fałszywych bądź niekompletnych. W
          przypadku stwierdzenia podania przez Użytkownika fałszywych danych,
          Usługodawcy przysługuje prawo odmowy Rejestracji lub - w przypadku
          ustalenia, że dane są nieprawdziwe po dokonaniu Rejestracji – prawo
          zablokowania Konta Użytkownika. O fakcie oraz przyczynach odmowy
          Rejestracji lub blokady konta Użytkownik zostanie poinformowany drogą
          elektroniczną na adres podany w formularzu rejestracyjnym.
        </li>
        <li>
          Z chwilą dokonania Rejestracji tworzony jest profil publiczny
          Użytkownika. Użytkownik za pośrednictwem Konta Użytkownika decyduje o
          zakresie danych dostępnych publicznie.{' '}
        </li>
        <li>
          Za pośrednictwem Konta Użytkownika Użytkownik może zarządzać własnymi
          danymi wskazanymi podczas Rejestracji, w tym dokonywać ich
          aktualizacji.{' '}
        </li>
        <li>
          {' '}
          Usługa Konto Użytkownika świadczona jest nieodpłatnie przez czas
          nieoznaczony. Użytkownik może w każdej chwili i bez podania przyczyny
          żądać usunięcia Konta Użytkownika poprzez wysłanie wiadomości do
          Usługodawcy na adres korespondencyjny lub elektroniczny.{' '}
        </li>
        <li>
          Usługodawca zastrzega sobie prawo do usunięcia Konta Użytkownika w
          przypadku jego nieaktywności w określonym czasie. W takiej sytuacji
          Usługodawca powiadomi Użytkownika na adres e-mail podany przy
          Rejestracji o planowanym usunięciu Konta Użytkownika i umożliwi
          Użytkownikowi wyrażenie sprzeciwu wobec usunięcia. Brak wniesienia
          sprzeciwu przez Użytkownika w oznaczonym czasie oznacza zgodę na
          usunięcie jego Konta Użytkownika.{' '}
        </li>
      </ol>
      <h2>V. OPINIE UŻYTKOWNIKÓW</h2>
      <ol>
        <li>
          Użytkownicy mają możliwość wyrażenia swojej opinii na temat wykonania
          Umowy przez Restauratora w drodze oceny lub wystawienia komentarza.
          Wyrażenie opinii jest dobrowolne.{' '}
        </li>
        <li>
          Ocena może dotyczyć Restauratora lub konkretnego Produktu i polega na
          przyznaniu punktów w skali od 1 do 5.
        </li>
        <li>
          Użytkownik może wyrazić swoją opinię na temat Restauratora lub
          Produktu jednokrotnie i dopiero po zrealizowaniu złożonego Zamówienia.
          Użytkownik ma możliwość modyfikacji swojej opinii.{' '}
        </li>
        <li>
          W przypadku odstąpienia od Umowy przed jej zrealizowaniem, wyrażona
          opinia nie zostanie opublikowana.{' '}
        </li>
        <li>
          Opinie powinny być zgodne z rzeczywistą realizacją Umowy i nie mogą:
          <ol>
            <li>
              naruszać obowiązujących przepisów prawa, w tym praw podmiotów
              trzecich oraz zasad współżycia społecznego
            </li>
            <li>
              mieć charakteru zniesławiającego, naruszać dóbr osobistych lub
              stanowić czynu nieuczciwej konkurencji,{' '}
            </li>
            <li>
              zawierać treści niedozwolonych i niezgodnych z prawem w tym
              wulgaryzmów,{' '}
            </li>
            <li>treści o charakterze reklamowym. </li>
          </ol>
        </li>
        <li>
          Użytkownik ponosi wyłączną odpowiedzialność za treści umieszczane w
          komentarzach.{' '}
        </li>
        <li>
          Usługodawca nie ingeruje w treść opinii Użytkownika, jednak zastrzega
          sobie prawo do ich usunięcia w uzasadnionych przypadkach takich jak:
          <ol>
            <li>naruszenie zasad określonych w punkcie 5 powyżej, </li>
            <li>oczywisty błąd odnoszący się do przedmiotu opinii, </li>
            <li>nieczytelność. </li>
          </ol>
        </li>
        <li>
          Poprzez wyrażenie opinii Użytkownik wyraża zgodę na jej
          wykorzystywanie przez Usługodawcę, w tym publiczne jej prezentowanie
          innym Użytkownikom i osobom przeglądającym treści na Platformie.{' '}
        </li>
      </ol>

      <h2>VI. CZAT</h2>
      <ol>
        <li>
          Czat jest nieodpłatną usługą świadczoną w ramach Konta Użytkownika,
          która, umożliwia nawiązanie kontaktu w czasie rzeczywistym pomiędzy
          Użytkownikiem a Restauracją za pośrednictwem komunikatora
          zamieszczonego na Platformie.
        </li>
        <li>Czat służy do komunikacji w sprawie złożonego Zamówienia.</li>
        <li>
          Aby rozpocząć korzystanie z Czatu należy wysłać wiadomość za
          pośrednictwem okna Czatu. Zaprzestanie korzystania z usługi jest
          możliwe w każdym czasie poprzez zamknięcie okna konwersacji.{' '}
        </li>
      </ol>
      <h2>VII. ZAWARCIE I WYKONANIE UMOWY</h2>
      <ol>
        <li>
          Zamówienia za pośrednictwem Platformy można składać przez 7 dni w
          tygodniu, 24 godziny na dobę, przy czym termin ich realizacji wynika
          ze szczegółów Oferty Restauratora.
        </li>
        <li>
          Złożenie Zamówienia możliwe jest wyłącznie za pośrednictwem Konta
          Użytkownika.{' '}
        </li>
        <li>
          W celu złożenia Zamówienia należy wybrać Produkty z Oferty danego
          Restauratora, wskazać dostępną opcję Odbioru oraz zaznaczyć pole
          Zamawiam.
        </li>
        <li>
          Platforma niezwłocznie przekazuje Zamówienie do Restauratora, który
          zobowiązany jest je potwierdzić w całości lub w części bądź odmówić
          przyjęcia zamówienia. W przypadku odmowy przyjęcia Zamówienia
          Restaurator zawiadomi Użytkownika o tym fakcie, informując
          jednocześnie o dostępności zamówionych produktów i pozostawiając mu
          decyzję o sposobie dalszej realizacji Zamówienia. W przypadku
          częściowej odmowy Zamawiający może potwierdzić realizację lub anulować
          zamówienie.
        </li>
        <li>
          Do zawarcia Umowy dochodzi w momencie potwierdzenia przez Restauratora
          złożonego Zamówienia. Z chwilą, w której Użytkownik złożył Zamówienie
          uznaje się, że świadomie zaakceptował warunki jego realizacji
          określone niniejszym Regulaminem, w tym cenę i obowiązek jej zapłaty.{' '}
        </li>
        <li>
          Z chwilą potwierdzenia przez Restaurację Zamówienia, uzyskuje ono
          status “Aktywne”, a Użytkownik może je uzupełnić o dodatkowe Produkty,
          co każdorazowo wymaga potwierdzenia Restauratora zgodnie z punktem 4 i
          5 powyżej.{' '}
        </li>
        <li>
          Użytkownik za pośrednictwem Platformy może sprawdzać status realizacji
          swojego Zamówienia oraz skontaktować się z Restauratorem korzystając z
          funkcji czat.{' '}
        </li>
        <li>
          W momencie potwierdzenia przez Restauratora Zamówienia następuje
          przelew ze środków na rachunku Użytkownika. Płatność zostanie pobrana
          przez Usługodawcę z chwilą wykonania umowy przez Restauratora.
        </li>
        <li>
          Termin wykonania Umowy określa treść oferty Restauratora. Miejscem
          spełniania świadczenia związanego z Zamówieniem jest miejsce Odbioru
          wskazane przez Użytkownika w Zamówieniu.{' '}
        </li>
        <li>
          Umowa może być prawidłowo wykonana przez Restauratora wyłącznie pod
          warunkiem wskazania przez Użytkownika w Koncie Użytkownika i podczas
          Zamówienia prawidłowych danych.{' '}
        </li>
        <li>
          Akceptując postanowienia Regulaminu i zawierając Umowę, Użytkownik
          wyraża zgodę na wystawianie i udostępnianie faktur przez Restauratora
          w formie elektronicznej i udostępnienie jej w Koncie Użytkownika przez
          Usługodawcę oraz na adres e-mail wskazany przez Użytkownika w Koncie
          Użytkownika zgodnie z art. 106n ust. 1 ustawy z dnia 11 marca 2004 r.
          o podatku od towarów i usług (Dz. U. z 2004 r. nr 54 poz. 535 ze zm.).
        </li>
        <li>
          Klient ma prawo do odwołania zgody wyrażonej na udostępnianie faktur w
          formie elektronicznej, w tym celu Użytkownik powinien wnieść pisemny
          sprzeciw na adres korespondencyjny lub elektroniczny Usługodawcy. W
          takim wypadku Usługodawca przekaże Użytkownikowi fakturę w wersji
          papierowej na adres korespondencyjny wskazany w Koncie Użytkownika lub
          wniosku.
        </li>
        <li>
          Użytkownik zobowiązany jest w szczególności do:
          <ol>
            <li>
              Podawania na Platformie wyłącznie prawdziwych i aktualnych danych,{' '}
            </li>
            <li>
              Niezwłocznego aktualizowania danych wskazanych na Platformie, w
              szczególności w takim zakresie, w jakim jest to niezbędne dla
              prawidłowej realizacji Zamówienia,{' '}
            </li>
            <li>Terminowej zapłaty ceny,</li>
            <li>Terminowego odbioru Produktów.</li>
          </ol>
        </li>
      </ol>
      <h2>VIII. PRAWO ODSTĄPIENIA OD UMOWY</h2>
      <ol>
        <li>Użytkownik ma prawo odstąpienia od Umowy.</li>
        <li>
          Prawo do odstąpienia od umowy może nastąpić nie później niż na 180
          minut przed upływem terminu wykonania Umowy.
        </li>
      </ol>
      <h2>IX. LICENCJA</h2>
      <ol>
        <li>
          Usługodawca udziela Użytkownikowi niewyłącznej, niezbywalnej i
          ograniczonej do terytorium Rzeczypospolitej Polskiej licencji na
          korzystania z Platformy.
        </li>
        <li>
          Licencja uprawnia Użytkownika do nieodpłatnego pobrania Platformy oraz
          do korzystania z niej zgodnie z przeznaczeniem i zasadami Regulaminu
        </li>
        <li>
          Użytkownik przyjmuje do wiadomości, iż Usługodawcy przysługują prawa
          własności intelektualnej, w tym prawa autorskie i prawa pokrewne do
          Platformy i wszystkich jej elementów mających charakter Utworu w
          rozumieniu Ustawy o prawie autorskim.
        </li>
        <li>
          Kopiowanie, rozpowszechnianie, dokonywanie opracowań elementów
          Platformy, a także wszelkie inne formy korzystania z Platformy
          wykraczające poza dozwolony użytek osobisty bez wyraźnej pisemnej
          zgody Usługodawcy stanowią naruszenie majątkowych praw autorskich.
        </li>
        <li>
          Bazy danych udostępnione w Platformie są przedmiotem ochrony zgodnie z
          Ustawą o ochronie baz danych. Zabronione jest pobieranie przez
          Użytkowników zawartości baz danych udostępnianych na Platformie i ich
          wtórne wykorzystywanie w całości lub części.
        </li>
        <li>
          Niniejszy Regulamin nie przyznaje Użytkownikowi żadnych praw własności
          intelektualnej do Platformy i jej części. Wszelkie prawa, które nie
          zostały wyraźnie przyznane są zastrzeżone na rzecz Usługodawcy i jego
          licencjodawców.
        </li>
      </ol>
      <h2>X. OCHRONA DANYCH OSOBOWYCHY</h2>
      <ol>
        <li>
          Administratorem danych osobowych Użytkowników Platformy jest
          Usługodawca.{' '}
        </li>
        <li>
          Usługodawca udostępnia dane osobowe Użytkowników Restauratorowi, w
          której złożyli Zamówienie. Restaurator staje się wówczas odrębnym i
          samodzielnym administratorem tych danych w zakresie niezbędnym do
          realizacji Umowy.
        </li>
        <li>
          Cele oraz zasady przetwarzania danych osobowych szczegółowo reguluje{' '}
          <Link to='/privacy'>
            <strong>Polityka Prywatności</strong>
          </Link>{' '}
          stanowiąca integralną część niniejszego Regulaminu.
        </li>
      </ol>
      <h2>XI. POSTANOWIENIA KOŃCOWE</h2>
      <ol>
        <li>
          Regulamin i jego zmiany dostępne są w Sklepie w formacie, który
          umożliwia pozyskanie, odtwarzanie i utrwalanie treści Regulaminu.
        </li>
        <li>
          Sprzedawca zastrzega sobie prawo do wprowadzania oraz odwoływania
          ofert, promocji oraz do zmiany cen Produktów w Sklepie bez uszczerbku
          dla praw nabytych przez Klienta, w tym w szczególności warunków umów
          zawartych przed dokonaniem zmiany.
        </li>
        <li>
          Sprzedawca zastrzega sobie możliwość wprowadzania zmian w Regulaminie.
          Do Umów sprzedaży zawartych przed zmianą Regulaminu stosuje się
          Regulamin obowiązujący w dacie zawarcia umowy. W odniesieniu do umów o
          charakterze ciągłym (np. o prowadzenie Konta Klienta), zmieniony
          Regulamin wiąże Klienta, jeżeli zostały zachowane wymagania określone
          w art. 384 i 384[1] Kodeksu cywilnego, tj. Klient został prawidłowo
          powiadomiony o zmianach i nie wypowiedział umowy w terminie 14 dni
          kalendarzowych od dnia powiadomienia.{' '}
        </li>
        <li>
          Niniejszy Regulamin obowiązuje od dnia publikacji na stronie
          internetowej Sklepu.
        </li>
        <li>
          W sprawach nieuregulowanych w niniejszym Regulaminie mają zastosowanie
          przepisy Kodeksu cywilnego, przepisy Ustawy o świadczeniu usług drogą
          elektroniczną oraz inne właściwe przepisy prawa polskiego.
        </li>
      </ol>
    </div>
  )
}

export default Terms
