/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import validateEmail from '../../../helpers/validateEmail'
import { InputEmail, Button } from '../'

const FindUserByEmail = ({ getUser, loading }) => {
  const [email, setEmail] = useState('')
  const handleEmail = (e) => setEmail(e.target.value)

  const onSubmit = () => {
    if (validateEmail(email)) getUser({ variables: { email } })
    else toast.warning('Email jest niepoprawny')
  }
  const onEnterSubmit = (e) => e.key === 'Enter' && onSubmit()

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'flex-end',
      }}>
      <InputEmail
        label='Podaj email użytkownika'
        value={email}
        onChange={handleEmail}
        onKeyUp={onEnterSubmit}
      />
      <Button css={{ marginLeft: 8 }} onClick={onSubmit} disabled={loading}>
        Szukaj
      </Button>
    </div>
  )
}

FindUserByEmail.propTypes = {
  getUser: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default FindUserByEmail
