/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import {
  consumptionTypeTypes as types,
  consumptionTypeNames,
  consumptionTypeIcons
} from '../../../../constants/types'
import { useRestaurantContext } from '../context/restaurant.provider'


const ConsumptionTypes = () => {
  const {
    restaurantInfo,
    setRestaurantInfo
  } = useRestaurantContext()
  const { consumptionTypes } = restaurantInfo

  const onChange = type => {
    const value = consumptionTypes.includes(type)
      ? consumptionTypes.filter(el => el !== type)
      : [...consumptionTypes, type]

    setRestaurantInfo({ ...restaurantInfo, consumptionTypes: value })
  }


  return (
    <React.Fragment>
      <h3 css={{
        marginBottom: 16
      }}>
        Zaznacz typy zamówień, które obsługuje Twoja restauracja
      </h3>
      <div css={{
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%'
      }}>
        <TypeDiv
          value={types.in}
          isActive={consumptionTypes?.includes(types.in)}
          onClick={() => onChange(types.in)}
        />
        <TypeDiv
          value={types.out}
          isActive={consumptionTypes?.includes(types.out)}
          onClick={() => onChange(types.out)}
        />
        <TypeDiv
          value={types.delivery}
          isActive={consumptionTypes?.includes(types.delivery)}
          onClick={() => onChange(types.delivery)}
        />
      </div>
    </React.Fragment>
  )
}

export default ConsumptionTypes


const TypeDiv = ({
  onClick,
  value,
  isActive
}) => (
  <div
    onClick={onClick}
    css={[
      {
        padding: 8,
        margin: '0 4px',
        minWidth: 80,
        maxWidth: 128,
        width: '100%',
        borderRadius: 8,
        fontWeight: 800,
        fontSize: 14,
        border: 'none',
        marginBottom: 8,

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px',
        transition: 'box-shadow .1s linear, transform .1s linear'
      },

      isActive
        ? {
          boxShadow: 'rgb(0 0 0 / 16%) 0px 4px 16px',
          backgroundColor: '#00B30F',
          color: '#FFFFFF',
          transform: 'translateY(-2px)'
        }
        : {
          backgroundColor: '#FFFFFF29',
          color: '#B9BCC4'
        }
    ]}
  >
    {consumptionTypeIcons[value]}
    <p>{consumptionTypeNames[value]}</p>
  </div>
)