/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { useError } from '../../../../hooks/errors.hook'
import { GET_USER } from '../../../graphql/user.graphql'
import MoneyEz from '../../../../helpers/MoneyEz'
import Headline from '../../../../components/Headline/Headline'
import { sOuter, sGrid, sBottomLinks } from '../../../../style/style'
import { colors, s, alpha } from '../../../../style'
import { ButtonBorder } from '../../../../components/Elements'
import { Grid } from '@material-ui/core'
import BlockIcon from '@material-ui/icons/Block'
import AccountBalanceIcon from '@material-ui/icons/DoneAll'
import WalletCharge from './WalletCharge'
import WalletTransactions from './WalletTransactions'
import mastercardLogo from '../../../../style/assets/mastercardlogo'
import visaLogo from '../../../../style/assets/visalogo'
import { TopNav } from '../../../../components/TopNavFixed/TopNavFixed'

const Wallet = () => {
  const { errorHandling } = useError()
  const { data, loading, error } = useQuery(GET_USER)

  if (loading) return <div />
  if (error) {
    errorHandling(error)
    return null
  }

  const { user } = data
  const { personal } = user
  const coinsFree = MoneyEz(user.coinsBalance)
    .subsctract(user.coinsBlocked)
    .getAmountWithFullPrecision()
    .split('.')

  const coinsBlocked = MoneyEz(user.coinsBlocked)
    .getAmountWithFullPrecision()
    .split('.')

  return (
    <React.Fragment>
      <TopNav
        title={'Portfel'}
        subtitle={personal && `- ${personal.name} ${personal.surname}`}
      />
      <div css={sOuter}>
        <Headline
          itemChildren={
            <ButtonBorder link={`/account/transactions`}>
              Transakcje
            </ButtonBorder>
          }>
          Portfel
        </Headline>
        <Grid container css={[sGrid]} justify='space-between'>
          <Tile
            subtitle={'Dostępne Środki'}
            version='BALANCE'
            coins={coinsFree}
            linkDisabled
          />
          <Tile
            subtitle={'Blokady'}
            version='BLOCK'
            coins={coinsBlocked}
            linkDisabled
          />
          <WalletCharge />
          <WalletTransactions />
          <Grid item xs={12} css={sBottomLinks}>
            <ButtonBorder link={'/terms'}>Regulamin</ButtonBorder>
            <ButtonBorder link={'/privacy'}>Polityka Prywatności</ButtonBorder>
          </Grid>
          <Grid item xs={12} css={sLogos}>
            <div>{mastercardLogo}</div>
            <div>{visaLogo}</div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}

export const Tile = ({ coins, subtitle, version, linkDisabled }) => {
  return (
    <Grid item xs={12} md={6}>
      <Link
        to={`/account/wallet`}
        css={[
          sTileContainer,
          version === 'BALANCE' && sTileBalance,
          version === 'BLOCK' && sTileBlock,
          { display: 'flex', flexDirection: 'column' },
          linkDisabled && { pointerEvents: 'none' },
        ]}>
        <div className='balance-title'>
          {coins[0]}.<span>{coins[1]}</span>&nbsp;
          <span css={{ fontSize: '1.5rem', fontWeight: 900 }}>zł</span>
        </div>
        <div className='balance-subtitle'>{subtitle}</div>
        <div className='balance-icon'>
          {version === 'BALANCE' && (
            <AccountBalanceIcon
              className='balance-icon-free'
              fontSize='inherit'
            />
          )}
          {version === 'BLOCK' && (
            <BlockIcon className='balance-icon-block' fontSize='inherit' />
          )}
        </div>
      </Link>
    </Grid>
  )
}

const sTileContainer = {
  position: 'relative',
  overflow: 'hidden',
  fontWeight: 800,
  backgroundColor: colors.gray200,
  padding: '1rem',
  borderRadius: '1rem',
  marginBottom: '1rem',
  [s.md]: {
    padding: '2rem',
    marginBottom: '1.5rem',
  },
  '.balance-title': {
    fontSize: '2rem',
    marginBottom: '1rem',
    [s.md]: { marginBottom: '1.5rem' },
    span: {
      opacity: 0.3,
    },
  },
  '.balance-subtitle': {
    fontSize: '0.875rem',
  },
  '.balance-icon': {
    opacity: 0.3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translate(-25%, -50%)',
    fontSize: '4rem',
    [s.md]: { transform: 'translate(-40%, -50%)' },
  },
  '.balance-icon-free': {},
  '.balance-icon-block': {},
}

const sTileBalance = {
  background: `linear-gradient(to right, ${colors.green.concat(
    alpha[8]
  )}, ${colors.green.concat(alpha[30])})`,
  color: colors.green,
  [s.md]: { marginRight: '0.75rem' },
}

const sTileBlock = {
  background: `linear-gradient(to right, ${colors.red.concat(
    alpha[8]
  )}, ${colors.red.concat(alpha[30])})`,
  color: colors.red,
  [s.md]: { marginLeft: '0.75rem' },
}

const sLogos = {
  margin: '1rem 0 0',
  paddingTop: '2rem',
  borderTop: `1px solid ${colors.text.concat(alpha[16])}`,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 0,
  [s.md]: { margin: '1.5rem 0 0', paddingTop: '2.5rem' },
  svg: {
    height: 48,
    width: 'auto',
  },
}

export default Wallet
