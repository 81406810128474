import React, { createContext, useContext } from 'react'

import useProductImage from '../hooks/useProductImage'
import useProductInfo, { initProductInfo } from '../hooks/useProductInfo'
import useAvailableDays, { initAvailableHours } from '../hooks/useAvailableDays'
import useCategories from '../hooks/useCategories'
import useIngredients from '../hooks/useIngredients'
import useCookDetails from '../hooks/useCookDetails'


function noop() { };
export const ProductContext = createContext({
  // useProductImage
  imageFile: null,
  imagePreview: null,
  onAddImage: noop,
  avatarUrl: '',
  setAvatarUrl: noop,

  // useAvailableDays
  availableHours: initAvailableHours,
  setAvailableHours: noop,
  handleAvailableHours: noop,

  // useProductInfo
  productInfo: initProductInfo,
  setProductInfo: noop,
  onChangeInfo: noop,

  // useCategories
  categories: [],
  asyncCategories: { data: undefined, loading: undefined, error: undefined },
  handleCategories: noop,

  // useIngredients
  ingredients: [],
  asyncIngridients: { data: undefined, loading: undefined, error: undefined },
  handleIngridients: noop,

  // useCookDetails
  flavors: [],
  cookMethod: [],
  handleFlavors: noop,
  handleCookMethod: noop
})

export const useProductContext = () => useContext(ProductContext)



export default ({ children }) => {

  return (
    <ProductContext.Provider value={{
      ...useProductImage(),
      ...useAvailableDays(),

      ...useProductInfo(),
      ...useCategories(),

      ...useIngredients(),
      ...useCookDetails()
    }}>
      {children}
    </ProductContext.Provider>
  )
}