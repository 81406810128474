import { useMutation } from '@apollo/client'
import { useError } from '../../hooks/errors.hook'
import {
  ADMIN_CHAT_ADD_MSSG,
  ADMIN_CHAT_READ_MSSGS,
  ADMIN_CHAT_FINISH,
  GET_ADMIN_ALL_ACTIVE_CHATS,
  GET_EZC_ADMIN_CHAT
} from '../graphql/chat.graphql'


export const useAdminChatMutations = () => {
  const { errorHandling } = useError()
  const [adminChatAddMssg] = useMutation(ADMIN_CHAT_ADD_MSSG)
  const [adminChatReadMssgs] = useMutation(ADMIN_CHAT_READ_MSSGS)
  const [adminChatFinish] = useMutation(ADMIN_CHAT_FINISH)

  async function onAddMssgAdminChat(data, cb, errCb) {
    try {
      const { chatId, message } = data
      const res = await adminChatAddMssg({ variables: { chatId, message } })

      if (typeof cb === 'function') cb(res)

    } catch (err) {
      errorHandling(err)
      if (typeof errCb === 'function') errCb(err)
    }
  }

  async function onReadMssgsAdminChat(data, cb, errCb) {
    try {
      const { chatId } = data
      const res = await adminChatReadMssgs({
        variables: { chatId },
        refetchQueries: [{ query: GET_ADMIN_ALL_ACTIVE_CHATS }]
      })

      if (typeof cb === 'function') cb(res)

    } catch (err) {
      errorHandling(err)
      if (typeof errCb === 'function') errCb(err)
    }
  }

  async function onReadMssgsChat(data, cb, errCb) {
    try {
      const { chatId } = data
      const res = await adminChatReadMssgs({
        variables: { chatId },
        refetchQueries: [{ query: GET_EZC_ADMIN_CHAT }]
      })

      if (typeof cb === 'function') cb(res)

    } catch (err) {
      errorHandling(err)
      if (typeof errCb === 'function') errCb(err)
    }
  }

  async function onFinishAdminChat(data, cb, errCb) {
    try {
      const { chatId } = data
      const res = await adminChatFinish({ variables: { chatId }, refetchQueries: [{ query: GET_ADMIN_ALL_ACTIVE_CHATS }] })

      if (typeof cb === 'function') cb(res)

    } catch (err) {
      errorHandling(err)
      if (typeof errCb === 'function') errCb(err)
    }
  }

  return {
    onAddMssgAdminChat,
    onReadMssgsChat,
    onReadMssgsAdminChat,
    onFinishAdminChat
  }
}