/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { ProductList, Button } from '../../../components'
import useOrderMutations from '../../../hooks/useOrders.mutations'

import { useOrderAttentionContext } from '../context/order-attention.context'
import HeaderCondition from './HeaderCondition'


export default () => {
  const { handleUpdateOrderStatus } = useOrderMutations()
  const {
    orderWeWorkOn,
    alreadyOrderedProducts
  } = useOrderAttentionContext()

  const onSubmitPaidOrder = () => handleUpdateOrderStatus({ orderId: orderWeWorkOn._id, status: 'CONFIRMED' })
  const { justPaidProducts, acceptedProducts } = segregateProducts(alreadyOrderedProducts)


  return (
    <React.Fragment>
      <HeaderCondition condition={justPaidProducts?.length}>
        Poniższe produkty zostały opłacone
      </HeaderCondition>
      <ProductList products={justPaidProducts} />
      <Button
        onClick={onSubmitPaidOrder}
        variant="contained"
        color="primary"
        css={{ fontWeight: 700 }}
      >
        Potwierdzam realizację
      </Button>

      <br />
      <br />

      <HeaderCondition condition={acceptedProducts?.length}>Zaakceptowane wcześniej produkty</HeaderCondition>
      <ProductList products={acceptedProducts} />
    </React.Fragment>
  )
}



function segregateProducts(products) {
  const _s = p => [...p.status].pop().name
  return {
    justPaidProducts: products.filter(p => _s(p) === 'ACCEPTED'),
    acceptedProducts: products.filter(p => _s(p) !== 'ACCEPTED')
  }
}