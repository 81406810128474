/** @jsx jsx */
import React, { useContext, createContext } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import OrderActiveItem from './OrderActiveItem'
import OrdersActiveProvider from '../context/orders-active.context'



export default (propsOrder) => (
  <OrdersActiveProvider order={propsOrder}>
    <OrderActiveItem />
  </OrdersActiveProvider>
)