/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { Grid } from '@material-ui/core'
import { Collapsable, Badge, Bullet } from '../../../../components/Elements'
import ForumIcon from '@material-ui/icons/Forum'

import { colors } from '../../../../style'

const OrderChat = ({
  orderId,
  name,
  avatarUrl,
  messages,
  isFinished,
  waiter,
  link,
}) => {
  // console.log(messages)

  const unreadMessages = waiter
    ? messages.filter((message) => message.fromUserId && !message.isRead).length
    : messages.filter((message) => message.fromRestId && !message.isRead).length

  const msgQuantity = messages?.length

  return (
    <Grid item xs={12} md={6}>
      <Collapsable
        left={!waiter}
        noPadding
        oneLiner
        title={isFinished ? 'Czat Archiwum' : 'Czat'}
        badge={
          <Badge
            collapse
            left={!waiter}
            color={colors.orange}
            show={unreadMessages > 0}
            showAnimation>
            {unreadMessages > 0 && unreadMessages}
          </Badge>
        }
        icon={<ForumIcon />}
        link={
          link
            ? link
            : !isFinished &&
              `/orders-chat/${orderId}?name=${name}&avatarUrl=${avatarUrl}`
        } // bug prevent
        description={
          isFinished ? (
            <>
              {msgQuantity || ''}
              {!msgQuantity && 'Brak wiadomość'}
              &nbsp;{msgQuantity === 1 && 'wiadomość'}
              {msgQuantity > 1 && 'wiadomości'}
            </>
          ) : messages.length > 0 ? (
            unreadMessages > 0 ? (
              <>
                <span css={{ color: colors.text, fontWeight: 500 }}>
                  {unreadMessages}
                </span>
                &nbsp;{unreadMessages === 1 && 'Nowa wiadomość'}
                {unreadMessages > 1 && unreadMessages < 5 && 'Nowe wiadomości'}
                {unreadMessages >= 5 && 'Nowych wiadomości'} od Restauracji
              </>
            ) : (
              <>
                Odczytane
                <Bullet />
                {messages.length} wiadomości
              </>
            )
          ) : (
            'Rozpocznij konwersację'
          )
        }></Collapsable>
    </Grid>
  )
}

export default OrderChat
