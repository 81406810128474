import { useQuery } from '@apollo/client'

import {
  GET_ORDERS_IN_CART,
  GET_FINISHED_ORDERS,
  GET_DECLINED_ORDERS,
  GET_ACTIVE_ORDERS,
  GET_ACTIVE_ORDER,
  GET_ACTIVE_ORDER_CART,
  GET_DELIVERY_INFO,
  GET_ACTIVE_ORDER_SHORT,
  GET_ORDER,
} from '../graphql/orders.graphql'

export const useGetOrdersInCart = () => useQuery(GET_ORDERS_IN_CART)

export const useGetActiveOrders = () =>
  useQuery(GET_ACTIVE_ORDERS, { pollInterval: 2000 })

export const useGetActiveOrdersShort = () => useQuery(GET_ACTIVE_ORDER_SHORT)

export const useGetActiveOrder = (orderId) =>
  useQuery(GET_ACTIVE_ORDER, { pollInterval: 2000, variables: { orderId } })

export const useGetActiveOrderCart = (orderId) =>
  useQuery(GET_ACTIVE_ORDER_CART, { variables: { orderId } })

export const useGetFinishedOrders = () => useQuery(GET_FINISHED_ORDERS)

export const useGetDeclinedOrders = () => useQuery(GET_DECLINED_ORDERS)

export const useGetOrder = (orderId) =>
  useQuery(GET_ORDER, { variables: { orderId } })

export const useGetDeliveryInfo = (geoInput, restId) =>
  useQuery(GET_DELIVERY_INFO, { variables: { geoInput, restId } })
