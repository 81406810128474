/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Line } from 'react-chartjs-2'

import { dataParse } from '../helpers/data-parse'
import { useGetStatisticSales } from '../../../hooks/useQueries'
import { useActiveRestaurantContext } from '../../../context/active-restaurant.provider'


export default () => {
  const { activeRestaurantId } = useActiveRestaurantContext()
  const { data, loading, error } = useGetStatisticSales(activeRestaurantId)

  if (loading) return null
  if (error) return null

  const { labels, values } = dataParse(data.ezrSalesStatistics)

  return <Line data={dataLine(labels, values)} />
}

const dataLine = (labels, data) => ({
  labels,
  datasets: [
    {
      label: 'Sprzedaż, zł',
      data,

      fill: false,
      lineTension: 0.2,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 3,
      pointRadius: 1,
      pointHitRadius: 20,
    }
  ]
})