import EzAdminContainer from './_container/ezadmin-container'
import EzAdminChangeRestaurantOwner from './change-restaurant-owner/ezadmin-change-restaurant-owner.page'
import EzAdminChargeRestaurant from './charge-restaurant/ezadmin-charge-restaurant.page'
import EzAdminChat from './chat/ezadmin-chat.page'
import EzAdminMakeRestaurateur from './make-restaurateur/ezadmin-make-restaurateur.page'
import EzAdminTransactionsByRestaurants from './transactions-by-restaurants/ezadmin-transactions-by-restaurants.page'

import { ezAdminRoutePrefix } from './settings'


const q = (route) => ezAdminRoutePrefix.concat(route)

const ezAdminRoutes = [
  {
    path: q('/change-restaurant-owner'),
    component: EzAdminChangeRestaurantOwner
  },
  {
    path: q('/charge-restaurant'),
    component: EzAdminChargeRestaurant
  },
  {
    path: q('/chat'),
    component: EzAdminChat
  },
  {
    path: q('/make-restaurateur'),
    component: EzAdminMakeRestaurateur
  },
  {
    path: q('/transactions-by-restaurants'),
    component: EzAdminTransactionsByRestaurants
  },
]


export {
  ezAdminRoutePrefix,
  ezAdminRoutes,
  EzAdminContainer
}