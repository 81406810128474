import { useQuery } from '@apollo/client'

import {
  GET_PRODUCT,
  GET_PRODUCTS,
  GET_PRODUCTS_BY_CATEGORY_ID,
  GET_CATEGORIES_BY_TYPE,
  GET_INGREDIENTS,
  GET_INGREDIENTS_BY_IDS
} from '../graphql/prods.graphql'


export const useGetAllProds = () => useQuery(GET_PRODUCTS)

export const useGetProdsByCategoryId = (categoryId, geoInput) => useQuery(GET_PRODUCTS_BY_CATEGORY_ID, {
  variables: { categoryId, geoInput }
})

export const useGetCategoriesByType = (categoryType) => useQuery(GET_CATEGORIES_BY_TYPE, {
  variables: { categoryType }
})

export const useGetProduct = (prodId) => useQuery(GET_PRODUCT, {
  variables: { prodId }
})

export const useGetIngredients = (page, limit) => useQuery(GET_INGREDIENTS, {
  variables: { page, limit }
})

export const useGetIngredientsByIDs = (_ids) => useQuery(GET_INGREDIENTS_BY_IDS, {
  variables: { _ids }
})