import { gql } from '@apollo/client'


export const CHANGE_RESTAURANT_OWNER = gql`
  mutation adminChangeRestaurantOwner($restId: ID!, $toUserId: ID!) {
    adminChangeRestaurantOwner(restId: $restId, toUserId: $toUserId) {
      success
    }
  }
`

export const CHARGE_RESTAURANT = gql`
  mutation adminChargeRestaurant($restId: ID!, $coinsAmount: Float!) {
    adminChargeRestaurant(restId: $restId, coinsAmount: $coinsAmount) {
      success
      commissionAmount
      payoutAmount
    }
  }
`

export const GENERATE_TRANSACTIONS_CSV = gql`
  mutation adminGenerateCsvOfTransactionsByRestaurant($restId: ID!, $startDate: Date!, $endDate: Date!) {
    adminGenerateCsvOfTransactionsByRestaurant(restId: $restId, startDate: $startDate, endDate: $endDate)
  }
`

export const GET_RESTAURANTS_BY_NAME = gql`
  query adminGetRestaurantByName($query: String!) {
    adminGetRestaurantByName(query: $query) {
      _id
      name
      owner
      address
      avatarUrl
      coins
      isActive
    }
  }
`