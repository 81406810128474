/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';



const DialogTitle = (props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          css={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}


export default function CustomizedDialogs({ children, btnName, btnHandle, title, isOpen, handleClose }) {

  return (
    <Dialog onClose={handleClose} open={isOpen} css={{ ".MuiDialog-paperWidthSm": { maxWidth: 'calc(100% - 64px)' } }}>
      <DialogTitle onClose={handleClose}>
        {title}
      </DialogTitle>

      <MuiDialogContent dividers>
        {children}
      </MuiDialogContent>

      <MuiDialogActions>
        <Button
          autoFocus
          onClick={btnHandle}
          color="secondary"
          variant="outlined"
        >
          {btnName}
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
}