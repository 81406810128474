import { useState } from 'react'


export default () => {
  const [openningHours, setOpenningHours] = useState(initOpenningHours)

  const handleOpenningHours = (e, type) => {
    setOpenningHours({
      ...openningHours,
      [e.target.name]: {
        ...openningHours[e.target.name],
        [type]: parseInt(e.target.value, 10)
      }
    })
  }

  return {
    openningHours,
    setOpenningHours,
    handleOpenningHours
  }
}

export const initOpenningHours = {
  mon: {
    open: 600,
    close: 1440
  },
  tue: {
    open: 600,
    close: 1440
  },
  wed: {
    open: 600,
    close: 1440
  },
  thu: {
    open: 600,
    close: 1440
  },
  fri: {
    open: 600,
    close: 1440
  },
  sat: {
    open: 600,
    close: 1440
  },
  sun: {
    open: 600,
    close: 1440
  },
}