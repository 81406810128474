import { gql } from '@apollo/client'


export const CHANGE_IS_RESTAURATEUR = gql`
  mutation adminChangeIsRestaurateur($userId: ID!, $isRestaurateur: Boolean!) {
    adminChangeIsRestaurateur(userId: $userId, isRestaurateur: $isRestaurateur) {
      success
    }
  }
`

export const GET_USER_BY_EMAIL = gql`
  query adminGetUserByEmail($email: String!) {
    adminGetUserByEmail(email: $email) {
      _id
      email
      isRestaurateur
      personal {
        name
        surname
      }
    }
  }
`

export const CHECK_PERMISSION = gql`
  query adminCheckPermission {
    adminCheckPermission
  }
`