/** @jsx jsx */
import React, { useState, useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useLocation } from 'react-router-dom'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import { sOuter } from '../../../style/style'
import Headline from '../../../components/Headline/Headline'
import SearchInput from './SearchInput'
import SearchFilter from './SearchFilter'
import { useSearch, useFetchInputFilters } from './hooks'
import { useError } from '../../../hooks/errors.hook'
import { isError, isLoading } from './helpers/searchInput.helper'
import queryCreator from './helpers/queryCreator.search'
import { useGetIngredientsByIDs } from '../../hooks/useProds.queries'

const SearchWrapper = ({ headline, children }) => {
  const [focus, setFocus] = useState(false)
  const { errorHandling } = useError()

  const { getCategories, categories, first30Ingredients } =
    useFetchInputFilters()

  const {
    states: {
      query,
      setQuery,
      prodFilter,
      setProdFilter,
      ingredientsList,
      setIngredientsList,

      isAutocompleteOpen,
      setIsAutocompleteOpen,
      isFilterPopupOpen,
      setIsFilterPopupOpen,
    },

    handlers: {
      addKeyToFilter,
      onFloatChange,
      onSubmit,
      addNewIngredient,
      onQueryStringChange,
    },
  } = useSearch()

  const searchConfig = useLocation().search
  const searchQuery = queryCreator(searchConfig)

  useEffect(() => {
    if (searchQuery) setSearchQueryData()

    function setSearchQueryData() {
      setQuery(searchQuery.query || '')
      setProdFilter(searchQuery.prodFilter || null)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (first30Ingredients.data) {
      setIngredientsList(first30Ingredients.data.ingredients)
    }
  }, [first30Ingredients]) // eslint-disable-line

  const [ingredientIdsToFetch, setIngredientIdsToFetch] = useState([])

  useEffect(() => {
    if (areIngrListAndSearchQuery() && noFurtherIngrWereFetch()) {
      const idsArray = getIngrIdsToFetch()
      setIngredientIdsToFetch(idsArray)
    }

    // Helper functions
    function noFurtherIngrWereFetch() {
      return ingredientIdsToFetch.length === 0
    }
    function areIngrListAndSearchQuery() {
      return (
        ingredientsList.length &&
        searchQuery &&
        searchQuery.prodFilter &&
        searchQuery.prodFilter.ingredients
      )
    }

    function getIngrIdsToFetch() {
      const { must, mustNot } = searchQuery.prodFilter.ingredients
      const mustIds = must ? must : []
      const mustNotIds = mustNot ? mustNot : []
      const ids = [...mustIds, ...mustNotIds]
      const isNewIngrId = (_id) => !ingredientsList.find((el) => el._id === _id)

      const idsToFetch = ids.filter(isNewIngrId)
      return idsToFetch
    }
  }, [searchConfig, ingredientsList.length]) // eslint-disable-line

  const furtherFetchedIngredients = useGetIngredientsByIDs(ingredientIdsToFetch) // Apollo query

  useEffect(() => {
    if (furtherFetchedIngredients.data) addFetchedIngredientsToList()

    function addFetchedIngredientsToList() {
      const i = first30Ingredients.data
        ? first30Ingredients.data.ingredients
        : []
      setIngredientsList([
        ...furtherFetchedIngredients.data.getIngredientsByIDs,
        ...i,
      ])
    }
  }, [furtherFetchedIngredients, first30Ingredients]) // eslint-disable-line

  const isReady =
    !isLoading({ categories, first30Ingredients }) &&
    !isError({ categories, first30Ingredients }, errorHandling)

  return (
    <React.Fragment>
      <TopNav title={''} extraCssOuter={{ boxShadow: 'none' }} />
      <div
        css={[
          sOuter,
          { transition: 'color 0.15s ease-out', pointerEvents: 'none' },
          focus && { color: 'white', zIndex: 1002 },
        ]}>
        <Headline>{headline}</Headline>
      </div>
      <SearchInput
        query={query}
        prodFilter={prodFilter}
        isAutocompleteOpen={isAutocompleteOpen}
        isFilterPopupOpen={isFilterPopupOpen}
        addNewIngredient={addNewIngredient}
        onQueryStringChange={onQueryStringChange}
        setIsAutocompleteOpen={setIsAutocompleteOpen}
        setIsFilterPopupOpen={setIsFilterPopupOpen}
        onSubmit={onSubmit}
        focus={focus}
        setFocus={setFocus}
      />
      {isReady && (
        <SearchFilter
          addKeyToFilter={addKeyToFilter}
          prodFilter={prodFilter}
          getCategories={getCategories}
          ingredientsList={ingredientsList}
          categories={categories}
          setProdFilter={setProdFilter}
          onSubmit={onSubmit}
          query={query}
          setFocus={setFocus}
          onFloatChange={onFloatChange}
        />
      )}
      {children}
    </React.Fragment>
  )
}

export default SearchWrapper
