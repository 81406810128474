/** @jsx jsx */
import React, { useContext, useEffect, useState, useCallback } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useParams, useHistory, useLocation } from 'react-router-dom'

import { useError } from '../../../hooks/errors.hook'
import { useGeolocationContext } from '../../context/Geolocation/Geolocation.provider'

// Components
import PageLoader from '../../../components/PageLoader/PageLoader'
// import Layout from '../../components/Layout'
// import { ProdRestContainer } from '../../../components/Containers'
import OrderInfo from '../../components/BottomOrderInfo/BottomOrderInfo.component'

// import { krakenUrls } from '../../../constants/s3-files'
import { s, globals } from '../../../style'

// Views
import { RestaurantMenuView } from '../../views/RestaurantPage'
import { AuthContext } from '../../../context/auth.context'
import { useGetRestaurant } from '../../hooks/useRests.queries'
import { useOrderInCartByRestaurant } from '../../context/OrderInCartByRestaurant/OrderInCartByRestaurant.provider'
import { Grid } from '@material-ui/core'
import MoneyEz from '../../../helpers/MoneyEz'
import BackgroundBlur from '../../views/ProductPage/components/BackgroundBlur'
import Intro from '../../views/ProductPage/components/Intro'
import Location from '../../views/ProductPage/components/Location'
import ConsumptionTypes from '../../views/ProductPage/components/ConsumptionTypes'
import Availability from '../../views/ProductPage/components/Availability'
import Delivery from '../../views/ProductPage/components/Delivery'
import InActivity from '../../views/ProductPage/components/InActivity'
import NavRestMenu from '../../views/RestaurantPage/components/NavRestMenu'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'

const Restaurant = () => {
  const restId = useParams().id
  const history = useHistory()
  const searchParams = useLocation().search
  const { geolocation } = useGeolocationContext()
  const { data, loading, error } = useGetRestaurant(restId, geolocation)
  const { errorHandling } = useError()

  const { isAuth } = useContext(AuthContext)
  const { setRestId, loadingOrders, errorOrders, order } =
    useOrderInCartByRestaurant()

  const [menuDiv, setMenuDiv] = useState(null)
  const measuredRef = useCallback((node) => {
    if (node !== null) setMenuDiv(node)
  }, [])

  useEffect(() => {
    setRestId(restId)
  }, [restId])

  const isMenuSections = Boolean(data?.ezcRestaurant?.menuSections.length)
  useEffect(() => {
    if (isMenuSections && searchParams && menuDiv) {
      const isScrollToMenu =
        searchParams
          .slice(1)
          .split('&')
          .find((el) => el.includes('menu'))
          .split('=')[1] === 'true'

      if (isScrollToMenu) {
        setTimeout(() => {
          menuDiv.scrollIntoView()
        }, 10)
      }
    }
  }, [isMenuSections, searchParams, menuDiv])

  if (loading || loadingOrders) return <PageLoader />
  if (error || errorOrders) {
    errorHandling(error || errorOrders)
    return null
  }

  const { ezcRestaurant } = data
  if (!ezcRestaurant) {
    history.push('/404')
    return null
  }

  const {
    menuSections,
    name,
    photoUrl,
    avatarUrl,
    categories,
    description,
    openningHours,
    consumptionTypes,
    ...otherRestProps
  } = ezcRestaurant

  let noAuthRestData
  if (!isAuth)
    noAuthRestData = {
      _id: restId,
      name: ezcRestaurant.name,
      avatarUrl: ezcRestaurant.avatarUrl,
      address: ezcRestaurant.address,
    }

  const products = order?.orderDetails?.[0].productsInCart
  let sum = '0,00zł'
  if (products) {
    sum = MoneyEz(0)
      .sumProductArray(
        products.map(({ amount, prodId: { price } }) => ({ amount, price }))
      )
      .getAmountWithFullPrecisionAndZl()
  }

  // console.log(ezcRestaurant)

  const categoriesOrder = [
    'POPULAR',
    'MEALTYPE',
    'CUISINE',
    'DIET',
    'DAYTIME',
    'OTHER',
  ]
  const categoriesShow = categoriesOrder
    .map((order) => categories.filter(({ type }) => type === order))
    .flat()
    .map((e) => e.name || e)

  // console.log(menuSections)
  // console.log(order)

  return (
    // <Layout darknav navNoBRadius>
    <React.Fragment>
      <TopNav
        title={name}
        photo={avatarUrl}
        textCss={{ maxWidth: `calc(100vw - ${48 + 32 + 30 + 36}px)` }}
        extraCssOuter={{ boxShadow: 'none' }}
      />
      <NavRestMenu items={menuSections.map((section) => section.name)} />
      <BackgroundBlur title={name} photo={photoUrl} />
      <Intro
        title={name}
        photo={photoUrl}
        photoAvatar={avatarUrl}
        categories={categoriesShow}
        description={description}
        // consumptionTypes={consumptionTypes}
      >
        {!ezcRestaurant.isActive && (
          <InActivity>Restauracja tymczasowo nieaktywna</InActivity>
        )}
      </Intro>
      <Grid container css={sTabs}>
        <Grid item xs={12} md={6}>
          <Availability
            hours={openningHours}
            type='restaurant'
            isRestActive={ezcRestaurant.isActive}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Delivery
            restId={restId}
            isDelivery={consumptionTypes.includes('delivery')}
            isRestActive={ezcRestaurant.isActive}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <ConsumptionTypes consumptionTypes={consumptionTypes} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Location
            id={restId}
            address={ezcRestaurant.address}
            location={ezcRestaurant.location}
          />
        </Grid>
      </Grid>
      <RestaurantMenuView
        measuredRef={measuredRef}
        menuSections={menuSections}
      />
      <OrderInfo
        mainText={sum}
        btnOnClick={() => history.push(`/cart-checkout/${order?._id}`)}
        isShow={order}
      />
    </React.Fragment>
    // </Layout>
  )
}

const sTabs = {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '1.5rem',
  maxWidth: globals.maxWidthMedium,
  margin: '0 auto',
  paddingBottom: 0,
  [s.md]: { flexDirection: 'row', paddingTop: '2.5rem' },
}

const sTopNavContent = {
  boxShadow: `none`,
}

export default Restaurant
