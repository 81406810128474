/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import Button from '@material-ui/core/Button';

import { DateRange } from 'react-date-range';
import { subDays, lightFormat, startOfDay } from 'date-fns';
import { pl } from 'date-fns/locale'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import useCsv from '../../../hooks/useCsv.mutations'


export default () => {
  const { getTransactionsCsv } = useCsv()
  const d = startOfDay(new Date())

  const [state, setState] = useState([
    {
      startDate: subDays(d, 7),
      endDate: d,
      key: 'selection'
    }
  ]);

  console.log(state)

  const onDownload = () => {
    const { startDate, endDate } = state[0]
    getTransactionsCsv({
      startDate,
      endDate
    })
  }

  return (
    <React.Fragment>
      <DateRange
        locale={pl}
        onChange={item => setState([item.selection])}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={state}
        direction="horizontal"
        maxDate={new Date()}
      />
      <Button
        variant="contained"
        onClick={onDownload}
        css={{
          display: 'block',
          margin: '0 0.833em',
        }}
      >
        Pobierz plik csv od {lightFormat(state[0].startDate, 'dd-MM-yyyy')} do {lightFormat(state[0].endDate, 'dd-MM-yyyy')}
      </Button>
    </React.Fragment>
  )
}