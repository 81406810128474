/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { useProductContext } from '../context/product.provider'
import useProdMutations from '../../../hooks/useProd.mutations'
import { sButtonFixed } from '../../../../EZW/pages/Order/buttons/FixedButtons'
import { Button } from '../../../../components/Elements'

const SaveButton = ({ prodId }) => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)

  const { handleUpdateProd } = useProdMutations({ prodId })
  const {
    categories,
    ingredients,
    availableHours: availableHoursFront,
    imageFile,
    flavors,
    cookMethod,
    productInfo: { name, description, price, cookTime, vat, consumptionTypes },
  } = useProductContext()

  const onSubmit = () => {
    setIsLoading(true)
    // transforming categories and ingridients to send to server,
    // segregating new ones and existing ones
    const categoryIds = []
    const newCategories = []
    categories.forEach((el) => {
      if (typeof el === 'string') categoryIds.push(el)
      else newCategories.push(el.inputValue)
    })

    const ingredientIds = []
    const newIngredients = []
    ingredients.forEach((el) => {
      if (typeof el === 'string') ingredientIds.push(el)
      else newIngredients.push(el.inputValue)
    })

    // to delete __typename from OpenningHoursInput, graphql purposes
    const { __typename, ...a } = availableHoursFront
    const i = Object.entries(a).map((el) => ({
      day: el[0],
      close: el[1].close,
      open: el[1].open,
    }))
    const availableHours = {}
    i.forEach(({ day, open, close }) => {
      availableHours[day] = { open, close }
    })

    // creating input object
    const input = {
      name,
      description,
      price: parseFloat(price),
      vat: parseFloat(vat),
      cookTime: parseInt(cookTime, 10),
      categories: categoryIds,
      ingredients: ingredientIds,
      cookMethod,
      flavors,
      availableHours,
      consumptionTypes,
    }

    const errors = []
    if (!name) errors.push('Podaj nazwę')
    if (!price && price !== 0) errors.push('Podaj cenę')
    if (!vat && vat !== 0 && price !== 0) errors.push('Podaj VAT')
    if (errors.length) {
      errors.forEach((el) => toast.error(el))
      setIsLoading(false)
      return
    }

    handleUpdateProd(
      {
        prodId,
        input,
        categories: newCategories,
        ingredients: newIngredients,
        imgFile: imageFile,
      },
      () => {
        toast.success('Produkt został zaktualizowany')
        setIsLoading(false)
        // history.push(ezrmRoutePrefix + '/products')
      },
      () => setIsLoading(false)
    )
  }

  return (
    <div css={sButtonFixed}>
      <Button
        w100
        w100Desktop
        buttonTransparent={true}
        action={onSubmit}
        disabled={isLoading}
        loadingSpinner={isLoading}
        loading={isLoading}>
        Zapisz zmiany
      </Button>
    </div>
  )
}

export default SaveButton
