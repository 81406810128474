import { useState } from 'react'

import { useGetProducts } from '../../../hooks/useQueries'



export default () => {
  const [searchString, setSearchString] = useState('')
  const { data, loading, error } = useGetProducts()

  const onSearchString = e => setSearchString(e.target.value)

  const products = data?.ezrProducts || []

  return {
    products,
    loading,
    error,
    searchString,
    onSearchString
  }
}