import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import useOrderMutations from '../../../hooks/useOrders.mutations'
import getStatusName from '../../../../helpers/get-status-name'

export default ({ _id, orderDetails }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [products, setProducts] = useState([])
  const [finishedProducts, setFinishedProducts] = useState([])
  const {
    handleUpdateOrderStatus,
    handleUpdateProdStatus,
    handleAnnulDoneProd,
    handleFinishOrder,
    handleDangerAnnulOrder,
  } = useOrderMutations()

  useEffect(() => {
    const prodInProcess =
      (orderDetails && orderDetails[0]?.productsInProcess) || []
    setProducts(prodInProcess)
  }, [orderDetails])

  useEffect(() => {
    if (orderDetails && orderDetails[0]) {
      setFinishedProducts([
        ...orderDetails[0].productsFinishedGood.map((q) => ({
          ...q,
          isStatusChange: true,
        })),
      ])
    }
  }, [orderDetails])

  const onSetFinishedProducts = (value) => {
    setFinishedProducts(value)
  }

  const mapCb = (product, _id) =>
    product._id !== _id
      ? product
      : { ...product, isStatusChange: !product.isStatusChange }
  const onToggleProduct = (_id) =>
    setFinishedProducts(finishedProducts.map((product) => mapCb(product, _id)))

  const handleActiveProdStatus = (status) => {
    const prodStatuses = {
      productsToOrder: [],
      productsInProcess: [],
      productsFinishedGood: [],
    }

    try {
      prodStatuses.productsFinishedGood = finishedProducts
        .filter((product) => {
          if (
            product.isStatusChange &&
            getStatusName(product.status) === 'WAITING_FOR_PAYMENT'
          )
            throw new Error()
          return product.isStatusChange
        })
        .map(({ _id }) => ({ _id, status }))

      setIsLoading(true)
      handleUpdateProdStatus({ orderId: _id, prodStatuses }, () =>
        setIsLoading(false)
      )
    } catch (err) {
      toast.warning('Zmiana statusu nieopłaconego produktu jest niemożliwa')
    }
  }

  const handleAnnulFinishedProd = (cb) => {
    setIsLoading(true)
    const idToAnnul = finishedProducts
      .filter((product) => product.isStatusChange)
      .map(({ _id }) => _id)

    if (idToAnnul.length === 0) {
      toast.warning('Nie zaznaczono żadnego produktu')
      setIsLoading(false)
      typeof cb === 'function' && cb()
      return
    }

    handleAnnulDoneProd(
      { orderId: _id, idToAnnul },
      () => {
        typeof cb === 'function' && cb()
        setIsLoading(false)
      },
      () => {
        typeof cb === 'function' && cb()
        setIsLoading(false)
      }
    )
  }

  const handleDecline = () => {
    setIsLoading(true)
    handleUpdateOrderStatus(
      { orderId: _id, status: 'DECLINED_BY_REST' },
      () => setIsLoading(false),
      () => setIsLoading(false)
    )
  }

  const handleFinishTheOrder = () => {
    setIsLoading(true)
    handleFinishOrder(
      { orderId: _id },
      () => setIsLoading(false),
      () => setIsLoading(false)
    )
  }

  const handleAnnulOrder = () => {
    setIsLoading(true)
    const cb = () => setIsLoading(false)
    handleDangerAnnulOrder(
      {
        orderId: _id,
        reason: 'Przyczyna',
      },
      cb,
      cb
    )
  }

  return {
    isLoading,
    isOpen,
    setIsOpen,
    products,
    finishedProducts,
    setFinishedProducts: onSetFinishedProducts,
    onToggleProduct,
    handleActiveProdStatus,
    handleDecline,
    handleFinishTheOrder,
    handleAnnulFinishedProd,
    handleAnnulOrder,
  }
}
