import { gql } from '@apollo/client'

export const FINISH_ORDER = gql`
  mutation orderFinishTheOrder($orderId: ID!) {
    orderFinishTheOrder(orderId: $orderId) {
      success
    }
  }
`

export const UPDATE_ORDER_STATUS = gql`
  mutation orderUpdateStatus($orderId: ID!, $status: String!, $productStatuses: [ProductStatusesInput] ) {
    orderUpdateStatus(orderId: $orderId, status: $status, productStatuses: $productStatuses) {
      success
    }
  }
`

export const UPDATE_PROD_STATUS = gql`
  mutation orderUpdateProdStatus($orderId: ID!, $prodStatuses: ActiveProdStatusesInput) {
    orderUpdateProdStatus(orderId: $orderId, prodStatuses: $prodStatuses) {
      success
    }
  }
`

export const ANNUL_DONE_PROD = gql`
  mutation orderAnnulDoneProd($orderId: ID!, $idToAnnul: [ID]!) {
    orderAnnulDoneProd(orderId: $orderId, idToAnnul: $idToAnnul) {
      success
    }
  }
`

export const ADD_PRODUCTS_TO_ORDER = gql`
  mutation orderRestAddProducts($orderId: ID!, $productsArray: [ProductsToOrderInput]!) {
    orderRestAddProducts(orderId: $orderId, productsArray: $productsArray) {
      success
    }
  }
`

export const DANGER_ANNUL_ORDER = gql`
  mutation orderDangerAnnulOrder($orderId: ID!, $reason: String!) {
    orderDangerAnnulOrder(orderId: $orderId, reason: $reason) {
      success
    }
  }
`

export const CONFIRM_ANNUL_ORDER = gql`
  mutation orderConfirmAnnulByClient($orderId: ID!) {
    orderConfirmAnnulByClient(orderId: $orderId) {
      success
    }
  }
`

export const CHANGE_WAITER_NOTE = gql`
  mutation orderAddWaiterNote($orderId: ID!, $note: String) {
    orderAddWaiterNote(orderId: $orderId, note: $note) {
      success
    }
  }
`