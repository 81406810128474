/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useParams, useHistory } from 'react-router-dom'
import { useGetRestMenu } from '../../hooks/useQueries'
import isCorrectId from '../../../helpers/check-correct-id'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import { sDescription, sGrid, sOuter } from '../../../style/style'
import Headline from '../../../components/Headline/Headline'
import { Grid } from '@material-ui/core'
import SkeletonOrder from '../../../components/SkeletonOrder/SkeletonOrder'
import useOrderMutations from '../../hooks/useOrders.mutations'
import { toast } from 'react-toastify'
import { useOrdersContext } from '../../context/orders.context'
import { Button, ButtonBorder } from '../../../components/Elements'
import { useTopNavContext } from '../../../context/topnav.context'
import NavRestMenu from '../../../EZC/views/RestaurantPage/components/NavRestMenu'
import { Element as ElementScroll } from 'react-scroll'
import { s } from '../../../style'
import { ProdItemMenuList } from '../../../EZC/components/ProdItem/'
import { sButtonFixed } from '../../../EZC/pages/orders/order-active.page'
// import MoneyEz from '../../../helpers/MoneyEz'
// import useCartWidget from './hooks/useCartWidget'
// import useCartHandle from './hooks/useCartHandle'

const OrderAdd = () => {
  const { data, loading, error } = useGetRestMenu()
  const { setAnimBack } = useTopNavContext()
  const history = useHistory()
  const orderId = useParams().id

  const { activeOrders, attentionOrders } = useOrdersContext()

  const order = activeOrders
    .concat(attentionOrders)
    .find((order) => order._id === orderId)

  const [cart, setCart] = useState([])
  // const amountPrice = MoneyEz(0)
  //   .sumProductArray(cart)
  //   .getAmountWithFullPrecisionAndZl()

  const { handleAddProducts } = useOrderMutations()

  if (loading) {
    return (
      <React.Fragment>
        <div css={sOuter}>
          <Headline>Menu</Headline>
          <Grid container css={sGrid}>
            <SkeletonOrder />
            <SkeletonOrder />
          </Grid>
        </div>
      </React.Fragment>
    )
  }

  if (!isCorrectId(orderId) || error || !data) {
    return (
      <React.Fragment>
        <div css={sOuter}>
          <Headline>Menu</Headline>
          <p css={sDescription}>Nie odnaleziono menu restauracji</p>
        </div>
      </React.Fragment>
    )
  }

  const menuSections = data.waiterMenuSections

  // console.log('data', data)
  // console.log('order', order)
  // console.log('cart', cart)

  const onSendProducts = () => {
    if (!cart?.length) {
      toast.warning('Dodaj produkty do zamówienia')
      return
    }
    const data = {
      orderId,
      productsArray: cart.map(({ _id, amount }) => ({
        prodId: _id,
        amount,
      })),
    }
    const cb = () => {
      toast.success('Produkty zostały dodane do zamówienia')
      history.push(`/waiter/order/${orderId}`)
    }

    handleAddProducts(data, cb)
  }

  const addProduct = (product) => {
    const { _id, name, price, photoUrl } = product
    let isProductExist = cart.find((product) => product._id === _id)
    if (!isProductExist) {
      setCart([...cart, { _id, name, price, photoUrl, amount: 1 }])
      return
    }

    setCart(
      cart.map((product) =>
        product._id !== _id
          ? product
          : { ...product, amount: product.amount + 1 }
      )
    )
  }

  const deleteProduct = ({ _id }) => {
    let product = cart.find((product) => product._id === _id)

    if (product.amount === 1) {
      setCart(cart.filter((prod) => prod._id !== _id))
    } else {
      setCart(
        cart.map((product) =>
          product._id !== _id
            ? product
            : { ...product, amount: product.amount - 1 }
        )
      )
    }
  }

  return (
    <React.Fragment>
      <TopNav
        isScrolledAlready
        title={
          order?.ownerId?.personal.name + ' ' + order?.ownerId?.personal.surname
        }
        photo={order?.ownerId?.personal.avatarUrl}
        textCss={{ maxWidth: `calc(100vw - ${48 + 32 + 30 + 36}px)` }}
        extraCssOuter={{ boxShadow: 'none' }}>
        <ButtonBorder
          extraCss={{ backgroundColor: 'white' }}
          action={() => {
            setAnimBack(true)
            history.push(`/order-active/${orderId}`)
          }}>
          Wróć
        </ButtonBorder>
      </TopNav>
      <NavRestMenu items={menuSections.map((section) => section.name)} />

      <div css={[sOuter, { marginTop: '6rem', padding: '0 1.5rem' }]}>
        {menuSections.map((section, sectionId) => (
          <ElementScroll
            name={encodeURIComponent(section.name)}
            key={sectionId}>
            <div
              key={sectionId}
              css={{ paddingTop: '3rem', [s.md]: { paddingTop: '4rem' } }}>
              <p
                css={{
                  fontSize: '1.5rem',
                  fontWeight: 800,
                  whiteSpace: 'nowrap',
                  letterSpacing: '-0.05em',
                  paddingLeft: '1rem',
                  marginBottom: '1.5rem',
                  [s.xs]: { fontSize: '1.5rem' },
                  [s.md]: { paddingLeft: '2rem' },
                }}>
                {section.name}
              </p>
              <Grid container>
                {section.products.map((product, productId) => {
                  const onAddProduct = () => {
                    addProduct(product)
                  }
                  const onDeleteProduct = () => {
                    deleteProduct({
                      _id: product._id,
                    })
                  }
                  return (
                    <ProdItemMenuList
                      id={productId}
                      key={productId}
                      data={product}
                      handleAddProduct={onAddProduct}
                      handleDeleteProduct={onDeleteProduct}
                      productInOrder={cart?.find(
                        (prod) => prod._id === product._id
                      )}
                      orderI={orderId}
                      noLink
                    />
                  )
                })}
              </Grid>
            </div>
          </ElementScroll>
        ))}

        <div css={sButtonFixed}>
          <Button w100 w100Desktop action={onSendProducts} buttonTransparent>
            Domów{' '}
            {cart.length > 0 && (
              <span>
                &nbsp; +{' '}
                {cart.reduce((sum, curr) => curr.price * curr.amount + sum, 0)}{' '}
                PLN
              </span>
            )}
          </Button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default OrderAdd
