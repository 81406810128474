/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

// Styles
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import { Chip, TextField } from '@material-ui/core'

const filter = createFilterOptions()

const AutocompleteInput = ({
  options,
  freeSolo = false,
  label,
  placeholder,
  stateArr,
  setStateArr,
  language,
}) => {
  const [inputValue, setInputValue] = useState('')

  return (
    <div css={styleContainer}>
      {/* <div>values: {stateArr.map((value) => ` ${value} | `)}</div> */}
      <Autocomplete
        value={stateArr}
        onChange={(event, newValue) => {
          setStateArr(newValue)
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        multiple
        css={[{ marginBottom: 24, textTrasform: 'capitalize' }]}
        options={typeof options === 'object' ? Object.keys(options) : options}
        freeSolo={freeSolo}
        getOptionLabel={(option) => {
          if (typeof option === 'string') return option
          if (option.inputValue) return option.inputValue
          return option[language]
        }}
        renderOption={(option) => options[option][language]}
        filterOptions={(optionsUI, params) => {
          const optionsNames = Object.values(options).map(
            (option) => option[language]
          )
          const filtered = filter(optionsNames, params)
          const newFiltered = filtered.map(
            (item) =>
              Object.keys(options).filter(
                (option) => options[option][language] === item
              )[0]
          )
          return newFiltered
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              // variant={mui.chipForm.variant}
              // size={mui.chipForm.size}
              css={global.chipForm}
              label={options[option][language]}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            // size={mui.textField.size}
            // variant={mui.textField.variant}
            label={label}
            placeholder={placeholder}
          />
        )}
      />
    </div>
  )
}

const styleContainer = {
  width: '100%',
}

export default AutocompleteInput
