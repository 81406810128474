/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Button } from '../../../../components/Elements'
import { useOrderAttentionContext } from '../context/order-attention.context'
import useOrderMutations from '../../../hooks/useOrders.mutations'
import { segregateProducts } from '../OrderProducts'
import { colors, s, alpha } from '../../../../style'
import { useHistory } from 'react-router-dom'

const FixedButtons = ({ zIndex, orderId, isPaid, isCanceled }) => {
  const {
    orderWeWorkOn,
    alreadyOrderedProducts,
    productsToAccept,
    handleAcceptProducts,
    handleAccept,
  } = useOrderAttentionContext()

  const history = useHistory()

  const { handleUpdateOrderStatus, handleConfirmAnnulOrder } =
    useOrderMutations()

  const onSubmitPaidOrder = () =>
    handleUpdateOrderStatus({ orderId: orderWeWorkOn._id, status: 'CONFIRMED' })

  const { justPaidProducts } = segregateProducts(alreadyOrderedProducts)
  const { status } = orderWeWorkOn
  const isNewOrder = status[status.length - 1].name === 'WAITING_FOR_ACCEPT'

  return (
    <div
      css={[
        sButtonFixed,
        {
          zIndex,
        },
      ]}>
      {!isCanceled && !productsToAccept.length && !justPaidProducts.length && (
        <Button
          w100
          w100Desktop
          link={`/waiter/add/${orderId}`}
          buttonTransparent={true}>
          Domów
        </Button>
      )}
      {!isCanceled &&
        justPaidProducts?.length > 0 &&
        !productsToAccept?.length > 0 && (
          <Button
            w100
            w100Desktop
            action={onSubmitPaidOrder}
            buttonTransparent={true}>
            Potwierdź Produkty
          </Button>
        )}
      {!isCanceled && productsToAccept?.length > 0 && (
        <Button
          w100
          w100Desktop
          action={isNewOrder ? handleAccept : handleAcceptProducts}
          buttonTransparent={true}>
          Akceptuj Zamówienie
        </Button>
      )}
      {isCanceled && (
        <Button
          w100
          w100Desktop
          action={() => {
            handleConfirmAnnulOrder({ orderId: orderWeWorkOn._id })
            history.push('/waiter/history')
          }}
          buttonTransparent={true}>
          Potwierdź anulowanie
        </Button>
      )}
    </div>
  )
}

export const sButtonFixed = {
  background: `linear-gradient(to right, ${colors.orange200}, ${colors.orange})`,
  width: '100%',
  maxWidth: 720,
  // padding: '0 2rem',
  [s.sm_down]: {
    maxWidth: 'calc(100vw - 3rem)',
    padding: '0 1.5rem',
  },
  position: 'fixed',
  height: 48,
  left: '50%',
  transform: 'translateX(-50%)',
  borderRadius: '1.5rem',
  transition:
    'bottom 0.6s ease-in-out, filter 0.3s ease-out, transform 0.3s ease-out, opacity 0.3s ease-out',
  boxShadow: `0px 8px 24px ${colors.orange.concat(alpha[60])}`,
  zIndex: 200,
  bottom: 72,
  '&:hover': {
    [s.hover]: {
      filter: 'brightness(1.1)',
    },
  },
}

export default FixedButtons
