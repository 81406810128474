/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Slider } from '@material-ui/core'
import { sTitle, sFilterGroup } from './FilterGroup'
import { colors } from '../../../style'

const FilterSliders = ({ onFloatChange, prodFilter }) => {
  const filterSliders = [
    {
      name: 'Cena',
      unit: 'ZŁ',
      onChange: onFloatChange('price'),
      max: 150,
      value: [
        (prodFilter && prodFilter.price && prodFilter.price.min) || 0,
        (prodFilter && prodFilter.price && prodFilter.price.max) || 200,
      ],
      marks: [
        { value: 20, label: '$' },
        { value: 40, label: '$$' },
        { value: 80, label: '$$$' },
        { value: 120, label: '$$$$' },
      ],
      extraCss: !prodFilter?.price && {
        color: colors.text,
        span: { color: colors.text },
      },
    },
    {
      name: 'Przygotowanie',
      unit: 'w minutach',
      onChange: onFloatChange('cookTime'),
      max: 90,
      value: [
        (prodFilter && prodFilter.cookTime && prodFilter.cookTime.min) || 0,
        (prodFilter && prodFilter.cookTime && prodFilter.cookTime.max) || 180,
      ],
      marks: [
        { value: 10, label: 'Fast Food' },
        { value: 35, label: 'Standard' },
        { value: 60, label: 'Slow Food' },
      ],
      extraCss: !prodFilter?.cookTime && {
        color: colors.text,
        span: { color: colors.text },
      },
    },
  ]

  return (
    <div>
      {filterSliders.map((filter, id) => (
        <FilterSlider {...filter} key={id} prodFilter={prodFilter} />
      ))}
    </div>
  )
}

const FilterSlider = ({
  onChange,
  max,
  value,
  name,
  marks,
  unit,
  prodFilter,
  extraCss,
}) => {
  return (
    <div css={sFilterGroup}>
      <h2 css={sTitle}>
        {name} <span css={sUnit}>{unit}</span>
      </h2>
      <div css={[sSlider]}>
        <Slider
          valueLabelDisplay='on'
          value={value}
          onChange={onChange}
          max={max}
          step={5}
          marks={marks}
          css={[
            {
              color: colors.green, // line color

              marginTop: 28,
              span: { color: colors.green, transition: 'color 0.3s ease-out' }, // circle color

              'span>span>span>span': { color: colors.white, fontWeight: 800 }, // popup text
            },
            extraCss,
          ]}
        />
      </div>
    </div>
  )
}

const sSlider = {
  padding: '1.5rem',
  '.MuiSlider-markLabel': {
    fontWeight: 800,
    color: colors.text,
    fontSize: '0.75rem',
    letterSpacing: '-0.05em',
  },
  '.MuiSlider-rail': {
    height: 8,
    borderRadius: '2rem',
    color: colors.gray500,
  },
  '.MuiSlider-track': {
    height: 8,
  },
  '.MuiSlider-mark': {
    height: 8,
    color: colors.gray600,
  },
  '.MuiSlider-thumb': {
    width: 16,
    height: 16,
  },
  '.MuiSlider-mark .MuiSlider-markActive': {
    color: colors.white,
  },
  '.MuiSlider-valueLabel': {
    left: 'calc(-50%)',
    zIndex: '0 !important',
  },
}

const sUnit = {
  fontSize: '0.875rem',
  color: colors.gray400,
  letterSpacing: 0,
  marginLeft: '0.25rem',
}

export default FilterSliders
