import React from 'react'
import { ProductList } from '../../../components'
import HeaderCheck from './HeaderCheck'
import { useActiveOrdersContext } from '../context/orders-active.context'



const ActiveProducts = () => {
  const { products } = useActiveOrdersContext()

  return (
    <React.Fragment>
      <HeaderCheck condition={products?.length}>Produkty zaakceptowane</HeaderCheck>
      <ProductList products={products} />
    </React.Fragment>
  )
}

export default ActiveProducts