import EzwIndex from './index/ezw-index.page'

import EzwOrdersChat from './orders-chat/ezw-orders-chat.page'
import EzwOrdersFinishedChat from './orders-chat/ezw-orders-finished-chat.page'
import EzwOrdersDeclinedChat from './orders-chat/ezw-orders-declined-chat.page'

import EzwOrdersActive from './orders-active/ezw-orders-active.page'
import EzwOrdersAddProduct from './orders-add-product/ezw-orders-add-product'
import EzwOrdersAttention from './orders-attention/ezw-orders-attention.page'
import EzwOrdersFinished from './orders-finished/ezw-orders-finished.page'

import { ezwRoutePrefix } from './settings'

const q = (route) => ezwRoutePrefix.concat(route)


const ezwRoutes = [
  {
    path: q('/orders-chat/:id'),
    component: EzwOrdersChat
  },
  {
    path: q('/orders-finished-chat/:id'),
    component: EzwOrdersFinishedChat
  },
  {
    path: q('/orders-declined-chat/:id'),
    component: EzwOrdersDeclinedChat
  },

  {
    path: q('/orders-attention'),
    component: EzwOrdersAttention
  },
  {
    path: q('/orders-active'),
    component: EzwOrdersActive
  },
  {
    path: q('/orders-add-product/:id'),
    component: EzwOrdersAddProduct
  },
  {
    path: q('/orders-finished'),
    component: EzwOrdersFinished
  },
]


export {
  EzwIndex,
  ezwRoutes,
  ezwRoutePrefix
}