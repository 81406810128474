/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'
import Badge from '@material-ui/core/Badge';
import { default as AvatarMaterialUI } from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { deepOrange } from '@material-ui/core/colors';

import CategoryList from '../CategoryList'
import { PRODUCT_STATUSES } from '../../../constants/statuses'
import { krakenUrls } from '../../../constants/s3-files'


const ProdItemOrderSmall = ({ data, blackTheme }) => {
  const activeStatus = data.status[data.status.length - 1].name
  const subheadData = [
    {
      name: PRODUCT_STATUSES[activeStatus],
      style: {
        color: activeStatus === 'DECLINED_BY_REST' ? '#B9BCC4' : '#00B30F',
        textTransform: 'uppercase'
      }
    },
    { Component: <PriceComponent data={data} /> }
  ]

  return (
    <Link to={`/product/${data.prodId._id}`} css={styles.main}>
      <Avatar data={data} activeStatus={activeStatus} />
      <RightDiv data={data} subheadData={subheadData} blackTheme={blackTheme} />
    </Link>
  )
}


const styles = {
  main: {
    display: 'flex',
    alignItems: 'center',
    padding: 16,
  },

  rightDiv: {
    color: '#292929',
    fontWeight: 800,
    fontSize: 14,
    flexGrow: 1,
  },

  rightDivBlacktheme: {
    color: '#fff',
    opacity: '.6',
    span: {
      color: '#fff',
      opacity: '.45'
    }
  }
}

// Components
const RightDiv = ({ blackTheme, data, subheadData }) => (
  <div css={[
    styles.rightDiv,
    blackTheme ? styles.rightDivBlacktheme : {}
  ]}>
    <p>{data.name}</p>
    <CategoryList small opacity="1" categories={subheadData} />
  </div>
)

const Avatar = ({ data, activeStatus }) => (
  <div css={{ marginRight: 12 }}>
    <StyledBadge
      overlap="circle"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      variant="dot"
      css={{
        span: {
          color: activeStatus === 'DECLINED_BY_REST' ? '#B9BCC4' : '#00B30F',
          backgroundColor: activeStatus === 'DECLINED_BY_REST' ? '#B9BCC4' : '#00B30F'
        }
      }}
    >
      <AvatarMaterialUI alt={data.name} src={krakenUrls.small + data.prodId.photoUrl} className={useStyles().orange}>
        {data.name[0]}
      </AvatarMaterialUI>
    </StyledBadge>
  </div>
)

// Helper Components
const PriceComponent = ({ data }) => (
  <p>
    {data.amount}
    <small css={{ color: '#2929294D', padding: '0 4px' }}>x</small>
    {data.price}
    <small css={{ color: '#2929294D' }}>zł</small>
  </p>
)

const StyledBadge = withStyles((theme) => ({
  badge: {
    // backgroundColor: '#44b700',
    // color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));



export default ProdItemOrderSmall