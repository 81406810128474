/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'
import Badge from '@material-ui/core/Badge';

import { Button } from '../../../components'
import getUnreadMessagesAmount from '../../../helpers/unread-messages-amount'
import { useActiveOrdersContext } from '../context/orders-active.context'
import { ezwRoutePrefix } from '../../settings'
import WaiterNote from './WaiterNote'


const ButtonsHandlers = () => {

  const {
    _id,
    handleFinishTheOrder,

    isLoading,
    orderSummary,
    ownerId,
    messages
  } = useActiveOrdersContext()

  const chatLink = `${ezwRoutePrefix}/orders-chat/${_id}?name=${orderSummary?.personal}&avatarUrl=${ownerId?.personal?.avatarUrl}`
  const unreadMssgsAmount = getUnreadMessagesAmount(messages)

  return (
    <React.Fragment>

      <SummaryButton
        variant="contained"
        component={Link}
        to={`${ezwRoutePrefix}/orders-add-product/${_id}`}
      >
        Domów produkt
      </SummaryButton>

      <ChatButton
        chatLink={chatLink}
        unreadMssgsAmount={unreadMssgsAmount}
      />

      <SummaryButton
        onClick={handleFinishTheOrder}
        disabled={isLoading}
      >
        Zakończ
      </SummaryButton>

      <div
        css={{ marginTop: 8 }}
        onClick={e => e.stopPropagation()}
      >
        <WaiterNote />
      </div>
    </React.Fragment>
  )
}

const SummaryButton = ({ children, onClick, ...otherProps }) => (
  <Button
    size="small"
    onClick={e => {
      e.stopPropagation();
      if (typeof onClick === 'function') onClick()
    }}
    css={{ fontWeight: 700, marginRight: 8 }}
    {...otherProps}
  >
    {children}
  </Button>
)

const ChatButton = ({
  chatLink,
  unreadMssgsAmount
}) => (

  <SummaryButton
    color={unreadMssgsAmount > 0 ? "secondary" : undefined}
    variant={unreadMssgsAmount > 0 ? "contained" : 'outlined'}
    component={Link}
    to={chatLink}
  >
    <Badge
      badgeContent={unreadMssgsAmount}
      color="secondary"
      css={{
        span: {
          right: '-14px',
          top: 4,
          border: '2px solid #fff',
          padding: '0 4px',
        }
      }}
    >
      Czat
    </Badge>
  </SummaryButton>
)


export default ButtonsHandlers