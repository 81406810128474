/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import TextInput from '../../../components/TextInput/TextInput'

import { useProductContext } from '../context/product.provider'


export default () => {
  const {
    productInfo,
    onChangeInfo
  } = useProductContext()

  return (
    <TextInput
      label="Opis"
      name="description"
      value={productInfo.description}
      onChange={onChangeInfo}
      multiline
      rowsMax={10}
      rows={2}
      css={{ width: '100%', marginBottom: 16 }}
    />
  )
}