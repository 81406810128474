/** @jsx jsx */
import React, { useState, useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import AddIcon from '@material-ui/icons/Add';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ProductsTable from '../components/ProductsTable'



export default ({
  menuSections,
  setMenuSections,
  handleAddProductsOpen,
  onDeleteSection,
  onDeleteProduct,
  onNameChange,
  wasChanges,
  setWasChanges
}) => {

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (!wasChanges) setWasChanges(true)
    setMenuSections(arrayMove(menuSections, oldIndex, newIndex))
  };

  return (
    <SortableContainer onSortEnd={onSortEnd} useDragHandle>
      {menuSections.map((section, index) =>
        <SortableItem
          key={section._id}
          index={index}
          section={section}
          handleAddProductsOpen={handleAddProductsOpen}
          onDeleteSection={onDeleteSection}
          onDeleteProduct={onDeleteProduct}
          onNameChange={onNameChange}
        />)}
    </SortableContainer>
  )
}


// Drag'n'drop
const DragHandle = sortableHandle(() => <div css={{ paddingRight: 8, cursor: 'row-resize' }}><DragHandleIcon /></div>);

const SortableContainer = sortableContainer(({ children }) => <div>{children}</div>)

const SortableItem = sortableElement(({ section, handleAddProductsOpen, onDeleteSection, onDeleteProduct, onNameChange }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
      >
        <div css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <DragHandle />

          <div css={{
            flexGrow: 1,
            paddingLeft: 8
          }}>
            <input
              defaultValue={section.name}
              onChange={onNameChange(section._id)}
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              placeholder="Nazwa sekcji..."
              css={{
                padding: '8px 0',
                border: 'none',
                fontSize: 20,
                width: '100%',
                height: '100%',
                maxWidth: 320,
                borderBottom: '1px solid transparent',
                borderColor: 'transparent',
                transition: 'border-color .2s ease-out',

                "&:hover": {
                  borderColor: '#000',
                }
              }}
            />
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <div css={{ width: '100%' }}>
          <div css={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: 16
          }}>
            <ButtonGroup color="primary" aria-label="outlined primary button group">
              <Button
                variant="contained"
                onClick={() => handleAddProductsOpen(section._id)}
                startIcon={<AddIcon>Dodaj</AddIcon>}
              >
                Dodaj do sekcji produkty
                </Button>
              <Button variant="text" onClick={() => onDeleteSection(section._id)}>Usuń sekcję</Button>
            </ButtonGroup>
          </div>

          {!!section.products.length && (
            <ProductsTable
              products={section.products}
              onDeleteProduct={onDeleteProduct(section._id)}
            />
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  )
});