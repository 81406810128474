import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useGetActiveOrdersShort } from '../../../hooks/useOrders.queries'
import handleChanges from '../helpers/handleChanges'

export default ({ isAuth }) => {
  const [isShown, setIsShown] = useState(false)
  const [attentionOrderIds, setAttentionOrderIds] = useState([])

  const { data, startPolling, stopPolling } = useGetActiveOrdersShort()
  const history = useHistory()

  useEffect(() => {
    if (isAuth) startPolling(2000)
    return () => isAuth && stopPolling()
  }, [])

  useEffect(() => {
    handleChanges({
      data,
      setAttentionOrderIds,
      setIsShown,
      attentionOrderIds,
    })
  }, [data])

  const onPopupClick = () => {
    setIsShown(false)
    attentionOrderIds.length === 1
      ? history.push(`/order-active/${attentionOrderIds[0]}`)
      : history.push('/orders')
  }

  const onHide = () => {
    setIsShown(false)
  }

  return {
    isShown,
    onPopupClick,
    onHide,
    attentionOrderIds,
  }
}
