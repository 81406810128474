/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify'
import { useParams, useLocation, useHistory } from 'react-router-dom'

import OrderChatView from '../../../EZC/views/Chat/OrderChat.view'
import isCorrectId from '../../../helpers/check-correct-id'
import { ezwRoutePrefix } from '../settings'

const EzrOrdersChat = () => {
  const history = useHistory()
  const orderId = useParams().id
  const restParams = useLocation().search

  if (!isCorrectId(orderId)) {
    toast.error(
      'Nieprawidłowe ID chatu (3). Spróbuj wejść ponownie. Jeśli nadal widzisz ten błąd, skontaktuj się z nami, używając Chatu z Eatzon, który znajdziesz w widoku Konto lub na podstronie /account'
    )
    history.push(`${ezwRoutePrefix}/orders-active`)
    return null
  }

  let name = ''
  let avatarUrl = ''
  if (restParams) {
    const paramsArr = restParams.slice(1).split('&')
    name = paramsArr.find((el) => el.includes('name='))?.slice(5)
    avatarUrl = paramsArr.find((el) => el.includes('avatarUrl='))?.slice(10)
  }

  return (
    <OrderChatView
      orderId={orderId}
      name={decodeURI(name)}
      avatarUrl={decodeURI(avatarUrl)}
      onCloseChat={() => history.push(`${ezwRoutePrefix}/orders-active`)}
      isWaiter
    />
  )
}

export default EzrOrdersChat
