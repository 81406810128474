/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import Avatar from '@material-ui/core/Avatar'
import Badge from '@material-ui/core/Badge'
import ChatIcon from '@material-ui/icons/Chat'
import { krakenUrls } from '../../../constants/s3-files'

import useChat from './hooks/useChat'
import useAdminChatAdmin from './hooks/useAdminChatAdmin'

import {
  ChatContainer,
  InputArea,
  Messages,
  MessagesContainer,
  HeaderInfo,
} from './components'

const AdminChatAdmin = () => {
  const {
    newMssg,
    setNewMssg,
    measuredRef,
    setMssgDiv,
    scrollToBottom,
    errorHandling,
    userId,
  } = useChat()

  const {
    data,
    error,
    activeChat,
    setChat,

    onSend,
    onReadMssgs,
    onAdminFinishChat,
  } = useAdminChatAdmin({
    newMssg,
    setNewMssg,
    scrollToBottom,
    setMssgDiv,
    userId,
  })

  if (error) {
    errorHandling(error)
    return null
  }
  if (!data) return null

  if (data.adminAllActiveChats.length === 0) return <NoChatsView />

  const activeChatAsync = data.adminAllActiveChats.find(
    (el) => el._id === activeChat
  )
  const messages = activeChat ? activeChatAsync && activeChatAsync.messages : []
  const allChats = data.adminAllActiveChats

  const setUserName = (user) => {
    if (!user) return ''

    if (user?.personal?.name) {
      const { name, surname } = user.personal
      return surname ? name + ' ' + surname : name
    }
    return user._id
  }

  return (
    <ChatContainer>
      <AdminChatFlexContainer>
        <ChatPreviewsContainer>
          <ChatLogo />
          {allChats.map((el) => (
            <ChatPreviewIcon
              key={el._id}
              name={setUserName(el.userId)}
              setChat={() => setChat(el._id)}
              isActive={el._id === activeChat}
              unreadMssgAmount={
                el.messages.filter(
                  (mssg) => mssg.fromUserId !== userId && !mssg.isRead
                ).length
              }
            />
          ))}
        </ChatPreviewsContainer>

        <SingleChatWindow>
          <HeaderInfo
            name={setUserName(activeChatAsync?.userId)}
            avatarUrl={activeChatAsync?.userId.avatarUrl}
            onAdminFinishChat={onAdminFinishChat}
            userId={activeChatAsync?.userId._id}
          />

          <MessagesContainer measuredRef={measuredRef}>
            <Messages userId={userId} messages={messages} />
          </MessagesContainer>

          <InputArea
            newMssg={newMssg}
            setNewMssg={setNewMssg}
            onSend={onSend}
            onReadMssgs={onReadMssgs}
            isAdmin
          />
        </SingleChatWindow>
      </AdminChatFlexContainer>
    </ChatContainer>
  )
}

const NoChatsView = () => (
  <ChatContainer>
    <HeaderInfo name='Brak czatów' />
  </ChatContainer>
)

const AdminChatFlexContainer = ({ children }) => (
  <div css={{ display: 'flex', height: '100%' }}>{children}</div>
)

const ChatPreviewsContainer = ({ children }) => (
  <div css={{ marginRight: 8 }}>{children}</div>
)

const SingleChatWindow = ({ children }) => (
  <div
    css={{
      flexGrow: 1,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}>
    {children}
  </div>
)

const ChatLogo = () => (
  <div
    css={{
      height: 48,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '4px 0',

      svg: {
        height: 28,
        width: 28,
      },
    }}>
    <ChatIcon css={{ color: '#3389f9' }} />
  </div>
)

const ChatPreviewIcon = ({
  isActive,
  name,
  setChat,
  unreadMssgAmount,
  avatarUrl,
}) => (
  <div
    css={{
      minHeight: 56,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
    <Badge
      color='secondary'
      badgeContent={unreadMssgAmount}
      css={{ span: { transform: 'none' } }}>
      <Avatar
        alt={name}
        src={krakenUrls.small + avatarUrl}
        onClick={setChat}
        css={{
          margin: 4,
          cursor: 'pointer',
          backgroundColor: isActive ? '#3389f9' : undefined,
        }}
      />
    </Badge>
  </div>
)

export default AdminChatAdmin
