/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { ButtonBase } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'

import { s, colors, alpha, globals } from '../../../style'

const BottomOrderInfo = ({ isShow, mainText, btnOnClick }) => (
  <ButtonBase
    onClick={btnOnClick}
    css={{
      width: '100%',
      maxWidth: 720,
      padding: '0 2rem',
      [s.sm_down]: {
        maxWidth: 'calc(100vw - 3rem)',
        padding: '0 1.5rem',
      },
      position: 'fixed',
      height: 48,
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: isShow ? 72 : -72,
      background: `linear-gradient(to right, ${colors.green400}, ${colors.green800})`,
      color: '#ffffff',
      borderRadius: '1.5rem',
      transition: 'bottom 0.6s ease-in-out, filter 0.3s ease-out',
      boxShadow: `0px 8px 24px ${colors.green.concat(alpha[60])}`,
      zIndex: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontWeight: 800,
      letterSpacing: '0.05em',
      '&:hover': {
        [s.hover]: {
          filter: 'brightness(1.1)',
        },
      },
    }}>
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}>
      <ShoppingCartIcon
        css={{
          width: 18,
          marginRight: 8,
        }}
      />
      <p
        css={{
          lineHeight: 1,
          fontSize: '1rem',
          flexGrow: 1,
        }}>
        {mainText}
      </p>
    </div>
    <div
      css={[
        {
          color: '#ffffff',
          fontWeight: 800,
          borderRadius: 16,
          height: 32,
          padding: '0 1rem',
          paddingLeft: '1.5rem',
          backgroundColor: colors.white.concat(alpha[30]),
          fontSize: '0.875rem',
          textTransform: 'uppercase',
        },
        { ...globals.center },
      ]}>
      dalej
      <ArrowForwardIosIcon css={{ marginLeft: '0.5rem' }} fontSize='inherit' />
    </div>
  </ButtonBase>
)

export default BottomOrderInfo
