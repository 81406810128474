/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import {
  FindRestaurant,
  Button,
  PopupContainer,
  SearchResultsRestaurants
} from '../../components'
import FindUser from './views/FindUser'
import useChangeRestaurantOwner from './useChangeRestaurantOwner'



const EzAdminChangeRestaurantOwner = () => {
  const {
    getRestaurant,
    data,
    loading,
    onRestaurantChoose,
    isPopupOpen,
    onPopupClose,
    onChangeOwner,
    onChoseOwner,
    onResetOwner
  } = useChangeRestaurantOwner()

  const ItemHelperComponent = ({ restId }) => (
    <Button onClick={() => onRestaurantChoose(restId)}>
      Wybierz
    </Button>
  )

  return (
    <React.Fragment>
      <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <FindRestaurant getRestaurant={getRestaurant} loading={loading} />
        <SearchResultsRestaurants
          restaurants={data?.adminGetRestaurantByName}
          ItemHelperComponent={ItemHelperComponent}
        />
      </div>

      <PopupContainer
        isOpen={isPopupOpen}
        onClose={onPopupClose}
        headerText="Wybierz nowego właściciela"
        btnText="Zapisz"
        btnOnClick={onChangeOwner}
      >
        <FindUser onChoseOwner={onChoseOwner} onResetOwner={onResetOwner} />
      </PopupContainer>
    </React.Fragment>
  )
}


export default EzAdminChangeRestaurantOwner