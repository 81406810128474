/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Bullet, StatusLarge, Badge } from '../../../components/Elements'
// import {
//   transactionTypeIcons,
//   transactionTypeNames,
// } from '../../../constants/types'
import { colors, s } from '../../../style'
import { Grid, Hidden } from '@material-ui/core'
import {
  sImage,
  sText,
} from '../../../components/Elements/Collapsable/Collapsable'
import {
  sLeft,
  sContainerInner,
  sTopContainer,
  sLimitText,
} from '../../../EZC/pages/orders/components/ActiveOrderItem'
import { dateFc } from '../../../helpers/date-format'
import eatzonIcon from '../../../style/assets/eatzonIcon.svg'
import { krakenUrls } from '../../../constants/s3-files'

const transactionTypeNames = {
  COMMISSION: 'PROWIZJA',
  PAYOUT: 'WYPŁATA',
  USER_REST: 'SPRZEDAŻ',
}

const Transaction = ({ id, data }) => {
  const {
    type,
    fromUser,
    fromFirm,
    fromRest,
    coinsAmount,
    toUser,
    toRest,
    toFirm,
    createdAt,
  } = data

  const isCommision = type === 'COMMISSION' // orange
  const isPayout = type === 'PAYOUT' // red
  const isPurchase = type === 'USER_REST' // green
  const isEatzon = Boolean(isPayout || isCommision)

  const q = createdAt.split(' ')
  const date = q[0]
  const time = q[1].split(':').slice(0, 2).join(':')

  return (
    <Grid
      item
      xs={12}
      md={6}
      css={{
        animation: `fadeIn 0.3s ease-in-out`,
      }}>
      <div
        css={[
          id % 2 === 1 && { [s.md]: { marginLeft: '0.75rem' } },
          id % 2 === 0 && { [s.md]: { marginRight: '0.75rem' } },
          {
            display: 'flex',
            marginBottom: '1.5rem',
            [s.sm_down]: { marginBottom: '1rem' },
          },
        ]}>
        <div css={[sContainerInner]}>
          <div css={sTopContainer}>
            <div css={sLeft}>
              <div css={[sImage, { position: 'relative' }]}>
                {isEatzon || (!isEatzon && fromUser?.personal?.avatarUrl) ? (
                  <img
                    src={
                      isEatzon
                        ? eatzonIcon
                        : krakenUrls.small + fromUser.personal.avatarUrl
                    }
                    alt={isEatzon ? 'Eatzon' : fromUser.personal.avatarUrl}
                    loading='lazy'
                  />
                ) : (
                  <div
                    css={{
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      backgroundColor: colors.gray400,
                    }}
                  />
                )}

                <Badge
                  small
                  left
                  color={colors.orange}
                  extraCss={{
                    boxShadow: `0px 0px 0px 3px ${colors.gray200}`,
                  }}
                  // show={paymentState === 'PENDING'}
                  showAnimation
                />
              </div>
              <div css={sText}>
                <p
                  css={[
                    sText.title,
                    sText.oneLiner,
                    sLimitText,
                    // paymentState !== 'CONFIRMED' && { opacity: 0.3 },
                  ]}>
                  {isEatzon ? 'Eatzon' : fromUser.personal.name}
                </p>
                <p
                  css={[
                    sText.subtitle,
                    sText.oneLiner,
                    {
                      display: 'flex',
                      [s.xs]: {
                        maxWidth: 'calc(100vw - 48px - 32px - 40px - 100px)',
                      },
                    },
                  ]}>
                  {/* <Hidden smUp>{dateFc(date)}</Hidden>
                  <Hidden xsDown>{dateFc(date, time)}</Hidden> */}
                  {dateFc(date, time)}
                  {/* <Hidden mdDown> */}
                  {/* {' '} */}
                  <Bullet />
                  {transactionTypeNames[type]}
                  {/* </Hidden> */}
                  {/* <Bullet /> */}
                </p>
              </div>
            </div>
            <StatusLarge
              color={
                isPurchase
                  ? colors.green
                  : isPayout
                  ? colors.red
                  : isCommision
                  ? colors.orange
                  : colors.gray400
              }>
              {isEatzon ? '-' : '+'} {coinsAmount.toFixed(2)} zł
            </StatusLarge>
          </div>
        </div>
      </div>
    </Grid>
  )
}

export default Transaction
