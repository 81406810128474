/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import ProductList from '../views/ProductList'


export default ({ name, products }) => {
  if (!products.length) return null
  return (
    <div css={{ marginBottom: 32 }}>
      <h4>{name}</h4>
      <ProductList products={products} />
    </div>
  )
}