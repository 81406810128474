/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { RestaurantItem } from '../'


const SearchResultsRestaurants = ({ restaurants, ItemHelperComponent }) =>
  restaurants?.length === 0
    ? <NoRestaurantsFound />
    : restaurants
      ? restaurants.map(restaurant => (
        <RestaurantItem
          key={restaurant._id}
          helperComponent={ItemHelperComponent
            && <ItemHelperComponent restId={restaurant._id} />}
          {...restaurant}
        />
      ))
      : null


const NoRestaurantsFound = () => (
  <div css={{ margin: '16px 0' }}>
    <h4>Brak wyników.</h4>
    <p>Spróbuj doprecyzować nazwę Restauracji.</p>
  </div>
)

export default SearchResultsRestaurants