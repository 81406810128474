/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useError } from '../../../../hooks/errors.hook'
import { useGetUserByEmail } from '../../../hooks/useUsers.queries'
import {
  Button,
  UserItem,
  FindUserByEmail,
} from '../../../components'


const FindUser = ({ onChoseOwner, onResetOwner }) => {
  const [isSelect, setIsSelect] = useState(false)
  const { errorHandling } = useError()
  const [getUser, { data, loading, error }] = useGetUserByEmail()

  if (error) errorHandling(error)

  return (
    <div>
      <FindUserByEmail
        loading={loading}
        getUser={(...args) => { getUser(...args); onResetOwner(); setIsSelect(false) }}
      />
      {data?.adminGetUserByEmail
        && (
          <UserItem
            {...data.adminGetUserByEmail}
            helperComponent={
              <Button
                color={isSelect ? "secondary" : undefined}
                onClick={() => { setIsSelect(true); onChoseOwner(data.adminGetUserByEmail._id) }}
              >
                Wybierz właściciela
              </Button>
            }
          />
        )}
    </div>
  )
}

export default FindUser