import { useState, useCallback } from 'react'
import { useAuthContext } from '../../../../context/auth.context'
import { useError } from '../../../../hooks/errors.hook'


const useChat = () => {
  const [newMssg, setNewMssg] = useState('')
  const [mssgDiv, setMssgDiv] = useState(null)
  const { errorHandling } = useError()
  const { userId } = useAuthContext()

  const measuredRef = useCallback(node => {
    if (node !== null) {
      setMssgDiv(node)
      node.scrollIntoView({ behavior: "smooth" })
    }
  }, []);

  const scrollToBottom = () => {
    if (mssgDiv) mssgDiv.scrollIntoView({ behavior: "smooth" })
  }

  return {
    newMssg,
    setNewMssg,
    measuredRef,
    setMssgDiv,
    scrollToBottom,
    errorHandling,
    userId
  }
}

export default useChat