/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import RestCarousel from './views/RestCarousel/RestCarousel.view'
import ProdCarousel from './views/ProdCarousel/ProdCarousel.view'
import RestNearYou from '../../views/RestNearYou/RestNearYou.view'
import Greeting from './views/Greeting/Greeting.view'
import { sOuter, sGrid, sBottomLinks } from '../../../style/style'
import { ButtonBorder } from '../../../components/Elements'
import { Grid } from '@material-ui/core'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'

const EzcIndex = () => {
  return (
    <>
      <TopNav disable />
      <Greeting />
      <RestNearYou />

      {process.env.REACT_APP_NODE_ENV === 'UAT' ||
      process.env.REACT_APP_NODE_ENV === 'PROD' ? (
        <React.Fragment>
          {/* <ProdCarousel categoryType="DAYTIME" /> */}
          {/* <ProdCarousel categoryType="MEALTYPE" /> */}
          <ProdCarousel categoryType='POPULAR' />
          {/* <ProdCarousel categoryType="DIET" /> */}
          <ProdCarousel categoryType='CUISINE' />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ProdCarousel categoryType='DAYTIME' />
          <ProdCarousel categoryType='MEALTYPE' />
          <ProdCarousel categoryType='POPULAR' />
          <ProdCarousel categoryType='DIET' />
          <ProdCarousel categoryType='CUISINE' />
        </React.Fragment>
      )}
      <RestCarousel />
      <div css={sOuter}>
        <Grid container css={sGrid}>
          <Grid item xs={12} css={sBottomLinks}>
            <ButtonBorder link={'/terms'}>Regulamin</ButtonBorder>
            <ButtonBorder link={'/privacy'}>Polityka Prywatności</ButtonBorder>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default EzcIndex
