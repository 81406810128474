import { useState } from 'react'
import { useGetCategories } from '../../../hooks/useQueries'


export default () => {
  const [categories, setCategories] = useState([])
  const asyncCategories = useGetCategories()

  const handleCategories = (i) => setCategories(i)

  return {
    categories,
    asyncCategories,
    handleCategories
  }
}