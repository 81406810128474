/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'

import ArchiveIcon from '@material-ui/icons/Archive'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import MoneyEz from '../../../../helpers/MoneyEz'
import { krakenUrls } from '../../../../constants/s3-files'

import {
  EnhancedTableHead,
  stableSort,
  getComparator,
} from '../helpers/products-table'
import { ezrmRoutePrefix } from '../../settings'
import useProductsTable from '../hooks/useProductsTable'
import { useProductsContext } from '../context/products.provider'
import useProdMutations from '../../../hooks/useProd.mutations'

export default function EnhancedTable() {
  const [isLoading, setIsLoading] = useState(false)
  const { handleArchiveProd } = useProdMutations()

  const { searchString, products } = useProductsContext()
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    emptyRows,
    filteredData,

    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useProductsTable({ searchString, products })

  const onArchive = (prodId, isArchived) => {
    setIsLoading(true)
    handleArchiveProd(
      { prodId, isArchived: !isArchived },
      () => {
        setIsLoading(false)
        toast.info(
          isArchived
            ? 'Produkt został dearchiwizowany'
            : 'Produkt został zarchiwizowany'
        )
      },
      () => setIsLoading(false)
    )
  }

  return (
    <React.Fragment>
      <TableContainer>
        <Table aria-labelledby='tableTitle' aria-label='enhanced table'>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />

          <TableBody>
            {stableSort(filteredData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((product) => {
                return (
                  <TableRow
                    hover
                    role='checkbox'
                    tabIndex={-1}
                    key={product._id}
                    component={Link}
                    to={ezrmRoutePrefix + `/product/${product._id}`}
                    css={
                      product.isArchived
                        ? {
                            backgroundColor: '#CCC',
                            opacity: '.3',
                            '&&:hover': { backgroundColor: '#CCC' },
                          }
                        : {}
                    }>
                    <TableCell component='th' scope='row'>
                      {product.photoUrl && (
                        <img
                          src={krakenUrls.small + product.photoUrl}
                          alt={product.name}
                          css={{ width: 50, borderRadius: 4 }}
                        />
                      )}
                    </TableCell>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>
                      {MoneyEz(product.price).getAmountWithFullPrecisionAndZl()}
                    </TableCell>
                    <TableCell>{product.categories}</TableCell>
                    <TableCell>
                      <Tooltip
                        title={
                          product.isArchived
                            ? 'Włącz produkt'
                            : 'Wyłącz produkt'
                        }>
                        <IconButton
                          disabled={isLoading}
                          onClick={(e) => {
                            e.preventDefault()
                            onArchive(product._id, product.isArchived)
                          }}>
                          {product.isArchived ? (
                            <UnarchiveIcon />
                          ) : (
                            <ArchiveIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component='div'
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </React.Fragment>
  )
}
