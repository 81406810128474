/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'
import useProductsTable from '../hooks/useProductsTable'
import { useProductsContext } from '../context/products.provider'
import useProdMutations from '../../../hooks/useProd.mutations'
import { sGrid, sNoMarginTop, sOuter } from '../../../../style/style'
import { Grid, Select } from '@material-ui/core'

import { stableSort, getComparator } from '../helpers/products-table'
import { colors, s } from '../../../../style'
import { krakenUrls } from '../../../../constants/s3-files'
import {
  sContainer,
  sTextContainer,
  sImageWrapper,
  sPrice,
} from '../../../../EZC/components/ProdItem/ProdItemMenuList.component'
import { Bullet, StatusLarge } from '../../../../components/Elements'

const ProductsList = () => {
  const { handleArchiveProd } = useProdMutations()

  const { searchString, products } = useProductsContext()
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    emptyRows,
    filteredData,

    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useProductsTable({ searchString, products })

  return (
    <div css={[sOuter, sNoMarginTop]}>
      <Grid container css={sGrid}>
        {stableSort(filteredData, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((product, id) => (
            <Grid item xs={12} md={6} key={id}>
              <ProductItem data={product} id={id} />
            </Grid>
          ))}
      </Grid>
    </div>
  )
}

export const ProductItem = ({ data, id }) => {
  const { name, price, photoUrl, isArchived, categories, _id } = data

  return (
    <div
      css={[
        sContainer,
        id % 2 === 1 && { [s.md]: { marginLeft: '0.75rem' } },
        id % 2 === 0 && { [s.md]: { marginRight: '0.75rem' } },
        isArchived && {
          background: colors.gray500,
          color: 'white',
          '&:hover': {
            background: colors.gray600,
          },
        },
      ]}>
      <Link to={`/rest/products/${_id}`} css={[sTextContainer]}>
        <div>
          {isArchived && (
            <div css={{ marginBottom: '0.5rem' }}>
              <StatusLarge color={colors.red800}>Archiwum</StatusLarge>
            </div>
          )}
          <h5 className='prod-item-title'>{name.toLowerCase()}</h5>
          <p className='prod-item-ingredients' css={{ marginBottom: '1.5rem' }}>
            {categories}
          </p>
          <p css={sPrice}>
            {price.toFixed(2)} <span>&nbsp;zł</span>
          </p>
        </div>
        <div
          css={[
            sImageWrapper,
            !photoUrl && {
              backgroundColor: colors.gray200,
              [s.sm_down]: { boxShadow: 'none' },
              [s.md]: { boxShadow: 'none' },
            },
            isArchived && { opacity: 0.3 },
          ]}>
          {photoUrl && (
            <img
              className='prod-item-image'
              src={krakenUrls.small + photoUrl}
              alt={''}
              loading='lazy'
            />
          )}
        </div>
      </Link>
    </div>
  )
}

export default ProductsList
