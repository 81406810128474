/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Input } from '../Auth/components'
import { sOuter, sGrid } from '../../../style/style'
import { useTopNavContext } from '../../../context/topnav.context'
import { s, globals, colors } from '../../../style'
import SearchIcon from '@material-ui/icons/Search'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { ButtonBase } from '@material-ui/core'
import SearchAutoComplete from './SearchAutoComplete'
import CloseIcon from '@material-ui/icons/Close'

const SearchInput = ({
  query,
  prodFilter,
  isAutocompleteOpen,
  isFilterPopupOpen,

  addNewIngredient,
  onQueryStringChange,
  setIsAutocompleteOpen,
  setIsFilterPopupOpen,
  onSubmit,
  focus,
  setFocus,

  isFiltersPopup,
}) => {
  const { scrolled } = useTopNavContext()

  const onEnterSubmit = (e) => e.key === 'Enter' && onSubmit()

  return (
    <div css={[sOuter, sOuter2]}>
      <div
        css={[
          sGrid,
          sGrid2,
          scrolled && { width: 'calc(100% - 3.5rem)' },
          focus && { width: '100%' },
        ]}>
        <div
          css={{ position: 'relative', top: 0, left: 0, width: '100%' }}
          // onMouseLeave={() => setFocus(false)}
        >
          <Input
            onKeyUp={onEnterSubmit}
            onSubmit={onSubmit}
            onFocus={() => setFocus(true)}
            // onMouseLeave={() => setFocus(false)}
            // onBlur={() => setFocus(false)}
            onChange={onQueryStringChange}
            type='text'
            value={query}
            placeholder='Na co masz ochotę?'
            sBaseExtra={{ margin: 0, zIndex: 1002 }}
            sInputExtra={[
              sInput,
              // scrolled && { padding: '0.75rem 1.5rem' }
            ]}
          />
          <SearchAutoComplete
            query={query}
            open={focus}
            setOpen={setFocus}
            ingredientsFilterData={prodFilter && prodFilter.ingredients}
            addNewIngredient={addNewIngredient}
          />
          <ButtonBase
            css={[
              sButtonSearch,

              sButtonReset,
              !query && { opacity: 0, pointerEvents: 'none' },
            ]}
            onClick={() => onQueryStringChange({ target: { value: '' } })}>
            <CloseIcon fontSize='inherit' />
          </ButtonBase>
          <ButtonBase css={sButtonSearch} onClick={onSubmit}>
            <p>wyszukaj</p>
            <SearchIcon fontSize='inherit' />
          </ButtonBase>
        </div>
      </div>
    </div>
  )
}

// const sButtons = {
//   flexShrink: 0,
//   position: 'absolute',
//   right: 0,
//   top: 0,
//   height: '100%',
//   display: 'flex',
//   padding: '0 1.5rem',
//   [s.md]: {
//     padding: '0 2rem',
//   },
// }

export const sButtonReset = {
  opacity: 0.3,
  '&:hover': {
    opacity: 1,
  },
  right: 32,
  [s.sm]: { right: 52 },
  [s.md]: { right: 160, width: 52, maxWidth: 52 },
}

export const sButtonSmall = {
  flexShrink: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'max-content',
  height: 50,
  minWidth: 50,
  minHeight: 50,
  [s.md]: {
    minWidth: 52,
    minHeight: 52,
  },
  fontSize: '1.5rem',
  ...globals.center,
  backgroundColor: 'transparent',
  color: colors.text,
  transition:
    'color 0.3s ease-out, background-color 0.3s ease-out, opacity 0.3s ease-out',
  borderRadius: '1.5rem',
  '&:hover': {
    [s.hover]: {
      backgroundColor: colors.gray300,
    },
  },
}

export const sButtonSearch = {
  ...sButtonSmall,
  [s.md]: {
    minWidth: 52,
    minHeight: 52,
    padding: '0 1.5rem',
  },
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1002,
  p: {
    [s.sm_down]: { display: 'none' },
    textTransform: 'uppercase',
    letterSpacing: '0.25em',
    fontSize: '0.75rem',
    fontWeight: 800,
    marginRight: '1rem',
  },
}

export const sOuter2 = {
  marginTop: 0,
  position: 'sticky',
  top: 9,
  left: 0,
  zIndex: 1000,
  [s.md]: { marginTop: 0, top: 14 },
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
}

export const sGrid2 = {
  position: 'relative',
  top: 0,
  left: 0,
  transition: 'width 0.3s cubic-bezier(0.22, 1, 0.36, 1)',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '0 1.5rem 0 1.5rem',
  marginBottom: '2rem',
  [s.md]: {
    padding: '0 1.5rem',
    marginBottom: '3rem',
  },
}

export const sInput = {
  fontSize: '0.875rem',
  [s.md]: { fontSize: '1rem' },
}

export default SearchInput
