import { useQuery } from '@apollo/client'
import {
  GET_FINISHED_ORDERS,
  GET_ACTIVE_ORDERS,
  GET_DECLINED_ORDERS
} from '../graphql/orders.graphql'
import { GET_REST_MENU } from '../graphql/restaurant-menu.graphql'
import { useWaiterAuthContext } from '../context/auth-waiter.context'


export const useGetFinishedOrders = (paginationInput) => {
  const { restaurantId } = useWaiterAuthContext()
  const restId = restaurantId
  return useQuery(GET_FINISHED_ORDERS, { variables: { restId, paginationInput } })
}

export const useGetDeclinedOrders = (paginationInput) => {
  const { restaurantId } = useWaiterAuthContext()
  const restId = restaurantId
  return useQuery(GET_DECLINED_ORDERS, { variables: { restId, paginationInput } })
}

export const useGetActiveOrders = () => {
  const { restaurantId } = useWaiterAuthContext()
  const restId = restaurantId
  return useQuery(GET_ACTIVE_ORDERS, {
    variables: { restId },
    pollInterval: 2000
  })
}

export const useGetRestMenu = () => {
  const { restaurantId } = useWaiterAuthContext()
  const restId = restaurantId
  return useQuery(GET_REST_MENU, { variables: { restId } })
}