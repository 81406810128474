import { useState } from 'react' // eslint-disable-line no-unused-vars
import { useGetActiveOrders } from './useQueries'

const useOrdersHandle = () => {
  const [isAttentionDisable, setIsAttentionDisable] = useState(true)
  const { data, loading, error } = useGetActiveOrders()

  function onClickAttention() {
    setIsAttentionDisable(true)
    // history.push(`${ezwRoutePrefix}/orders-attention`)
  }

  function onCloseAttention(e) {
    e.stopPropagation()
    setIsAttentionDisable(true)
  }

  return {
    data,
    loading,
    error,
    isAttentionDisable,
    setIsAttentionDisable,
    onClickAttention,
    onCloseAttention,
  }
}

export default useOrdersHandle
