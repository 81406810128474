import { useState } from 'react'
import { imagePreview as imgPreviewUtil } from '../../../../helpers/image-preview'


export default () => {
  const [logoUrl, setLogoUrl] = useState('')
  const [logoFile, setLogoFile] = useState(null)
  const [logoPreview, setLogoPreview] = useState(null)

  const [backgroundUrl, setBackgroundUrl] = useState('')
  const [backgroundFile, setBackgroundFile] = useState(null)
  const [backgroundPreview, setBackgroundPreview] = useState(null)

  const handleAddLogo = event => {
    setLogoFile(event.target.files[0])
    imgPreviewUtil(event.target.files[0]).then(file => setLogoPreview(file)).catch(e => setLogoPreview(null));
    event.target.value = ''
  }

  const handleAddBackground = event => {
    setBackgroundFile(event.target.files[0])
    imgPreviewUtil(event.target.files[0]).then(file => setBackgroundPreview(file)).catch(e => setBackgroundPreview(null));
    event.target.value = ''
  }


  return {
    logoUrl,
    setLogoUrl,

    logoFile,
    logoPreview,
    handleAddLogo,

    backgroundUrl,
    setBackgroundUrl,

    backgroundFile,
    backgroundPreview,
    handleAddBackground
  }
}