/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { Collapse } from '@material-ui/core'
import Headline from '../Headline/Headline'
import WidgetContainer from '../Widget/Widget.component'
import { sGrid, sDescription } from '../../style/style'
import {
  sFormContainer,
  sGridOverride,
  sHeadline,
} from '../../EZW/pages/Popups/styles'
import StatusLarge from '../Elements/StatusLarge/StatusLarge'
import {
  ORDER_STATUSES,
  ORDER_STATUS_COLOR,
  PRODUCT_STATUSES,
  PRODUCT_STATUS_COLOR,
} from '../../constants/statuses'
import { SectionHeadline } from '../Elements'

const orderStatusesGuide = [
  {
    name: 'ORDER_IN_CART',
    desc: 'Zamówienie znajduje się w koszyku i może zostać przesłane do restauracji przez widok podsumowania. Maksymalnie 1 zamówienie w koszyku w tej samej restauracji',
  },
  {
    name: 'WAITING_FOR_ACCEPT',
    desc: 'Zamówienie zostało przesłane do restauracji i czeka na akceptację. Obsługa restauracji może zaakceptować całe zamówienie, zaakceptować tylko część produktów lub odrzucić całe zamówienie.',
  },
  {
    name: 'ACCEPTED',
    desc: 'Zamówienie i jego produkty zostały w pełni zaakceptowane. Oczekują na płatność z Twojej strony. Jeżeli zamówienie jest opłacone, restaurator potwierdzi realizację tego zamówienia',
  },
  {
    name: 'PARTIALLY_ACCEPTED',
    desc: 'Zamówienie częściowo zaakceptowane, czyli niektóre z zamówionych produktów zostały odrzucone. W takiej sytuacji możesz zdecydować, czy pomimo akceptacji części produktów chcesz dalej kontynuować zamówienie. Wybierz "Potwierdź i opłać" lub odrzuć zamówienie.',
  },
  {
    name: 'CONFIRMED',
    desc: 'Zamówienie zostało potwierdzone przez obsługę restaracji. Oczekuje na rozpoczęcie realizacji składowych produktów. W tym statusie jest możliwa rezygnacja z zamówienia, o ile system nie stwierdzi, że do realizacji zostało niewiele czasu',
  },
  {
    name: 'PARTIALLY_CONFIRMED',
    desc: 'Zamówienie częściowo potwierdzone. Oznacza to, że niektóre z zamówionych produktów nie zostały zaakceptowane, opłacone lub potwierdzone. Status pojawia się głównie w przypadku domówienia produktów do realizowanego już zamówienia.',
  },
  {
    name: 'FINISHED_BY_USER',
    desc: 'Zamówienie zakończone przez Klienta. Możliwe tylko w przypadku opłaconego zamówienia.',
  },
  {
    name: 'FINISHED',
    desc: 'Zamówienie zakończone przez Restaurację',
  },
  {
    name: 'AUTO_FINISHED',
    desc: 'Zamówienie zakończone automatycznie przez System na skutek braku zakończenia przez Klienta lub Restaurację',
  },
  {
    name: 'ANNUL_BY_CLIENT',
    desc: 'Zamówienie anulowane przez Klienta. Obsługa restauracji może potwierdzić anulowanie zamówienia, zwracając środki za opłacone produkty na konto Klienta',
  },
  {
    name: 'ANNUL_BY_REST',
    desc: 'Zamówienie anulowane przez Restaurację. W przypadku opłaconych produktów, wszystkie środki zostają zwrócone na konto Klienta',
  },
  {
    name: 'AUTO_ANNULLED_BECAUSE_OF_PAYMENT',
    desc: 'Zamówienie anulowane przez System z powodu braku płatności w ciągu 20 minut od zamówienia.',
  },
]

const productStatusesGuide = [
  {
    name: 'WAITING_FOR_ACCEPT',
    desc: 'Produkt oczekuje na akceptację przez Restaurację',
  },
  {
    name: 'WAITING_FOR_PAYMENT',
    desc: 'Produkt został zaakceptowany przez Restaurację, ale nie został opłacony przez Klienta',
  },
  {
    name: 'ACCEPTED',
    desc: 'Produkt został zaakceptowany przez Restaurację i opłacony przez Klienta. Oczekuje na potwierdzenie ze strony Restraucji',
  },
  {
    name: 'CONFIRMED',
    desc: 'Produkt został potwierdzony przez Restaurację i oczekuje na rozpoczęcie realizacji',
  },
  {
    name: 'PREPARING',
    desc: 'Rządanie realizacji produktu zostało przekazane na kuchnię',
  },
  {
    name: 'DONE',
    desc: 'Produkt został przygotowany i wkrótce zostanie dostarczony do Klienta',
  },
  {
    name: 'FINISHED',
    desc: 'Produkt został odebrany/dostarczony Klientowi',
  },
  {
    name: 'DECLINED_BY_REST',
    desc: 'Produkt został odrzucony przez Restaurację w procesie akceptacji',
  },
  {
    name: 'ANNULED',
    desc: 'Produkt został anulowany przez Restaurację',
  },
  {
    name: 'AUTO_ANNULED_BECAUSE_OF_NO_PAYMENT',
    desc: 'Produkt został anulowany przez System z powodu braku płatności',
  },
]

const StatusGuide = ({ open, setOpen }) => {
  return (
    <React.Fragment>
      <WidgetContainer isWidgetOpen={open} setIsWidgetOpen={setOpen}>
        <Collapse in={open}>
          <div>
            <Headline extraCss={sHeadline}>Statusy</Headline>
            <div css={[sGrid, sGridOverride]}>
              <p css={[sDescription, { fontSize: '0.875rem' }]}>
                Sprawdź co oznaczają poszczególne statusy zamówień oraz
                produktów i poznaj jak działa system Eatzon
              </p>
            </div>
            <div css={[sGrid, sGridOverride]}>
              <SectionHeadline
                noPaddingLeft
                noPaddingTop
                sOuter={{ margin: '1rem 0' }}>
                Zamówienia
              </SectionHeadline>
              {orderStatusesGuide.map((status, id) => (
                <div key={id}>
                  <div>
                    <StatusLarge color={ORDER_STATUS_COLOR[status.name]}>
                      {ORDER_STATUSES[status.name]}
                    </StatusLarge>
                  </div>
                  <p css={[sDescription, sDescription2]}>{status.desc}</p>
                </div>
              ))}
            </div>
            <div css={[sGrid, sGridOverride]}>
              <SectionHeadline
                noPaddingLeft
                noPaddingTop
                sOuter={{ margin: '1rem 0' }}>
                Produkty
              </SectionHeadline>
              {productStatusesGuide.map((status, id) => (
                <div key={id}>
                  <div>
                    <StatusLarge color={PRODUCT_STATUS_COLOR[status.name]}>
                      {PRODUCT_STATUSES[status.name]}
                    </StatusLarge>
                  </div>
                  <p css={[sDescription, sDescription2]}>{status.desc}</p>
                </div>
              ))}
            </div>
          </div>
        </Collapse>
      </WidgetContainer>
    </React.Fragment>
  )
}

const sDescription2 = { fontSize: '0.75rem', margin: '1rem 0 1rem' }

export default StatusGuide
