/** @jsx jsx */
import React, { useEffect, useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { sGrid, sNoMarginTop, sOuter } from '../../../style/style'
import Headline from '../../../components/Headline/Headline'
import { SectionHeadline, StatusOrder } from '../../../components/Elements'
import { ORDER_STATUSES, ORDER_STATUS_COLOR } from '../../../constants/statuses'
import { s } from '../../../style'
import { Grid } from '@material-ui/core'
import SkeletonOrder from '../../../components/SkeletonOrder/SkeletonOrder'

import removeDuplicateProducts from '../../../helpers/remove-duplicate-products'
import sortProductsByStatus from '../../../helpers/sort-products-by-status'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import StatusGuide from '../../../components/StatusGuide/StatusGuide'
import OrderUser from '../Order/OrderUser'
import OrderStatuses from '../../../EZC/pages/orders/components/OrderStatuses'
import OrderType from '../../../EZC/pages/orders/components/OrderType'
import OrderAddress from '../../../EZC/pages/orders/components/OrderAddress'
import OrderDate from '../../../EZC/pages/orders/components/OrderDate'
import OrderNote from '../../../EZC/pages/orders/components/OrderNote'
import OrderInvoice from '../../../EZC/pages/orders/components/OrderInvoice'
import OrderID from '../../../EZC/pages/orders/components/OrderID'
import OrderPaymentLarge from '../../../EZC/pages/orders/components/OrderPaymentLarge'
import OrderChatHistory from '../../../EZC/pages/orders/components/OrderChatHistory'
import OrderProduct from '../Order/OrderProduct'

const OrderView = ({ order, loading }) => {
  const [isStatusGuideOpen, setIsStatusGuideOpen] = useState(false)

  if (loading)
    return (
      <React.Fragment>
        <div css={sOuter}>
          <Headline
            itemChildren={
              <StatusOrder color={ORDER_STATUS_COLOR['WAITING_FOR_ACCEPT']}>
                Pobieranie
              </StatusOrder>
            }
            extraCss={sHeadline}>
            Zamówienie
          </Headline>
          <Grid container css={sGrid}>
            <SkeletonOrder />
            <SkeletonOrder />
          </Grid>
        </div>
      </React.Fragment>
    )

  let allProducts,
    allProductsSorted,
    newAllProducts = []
  if (order) {
    allProducts = [
      ...order.orderDetails[0].productsToOrder,
      ...order.orderDetails[0].productsInProcess,
      ...order.orderDetails[0].productsFinishedGood,
      ...order.orderDetails[0].productsFinishedBad,
      ...order.orderDetails[0].productsDeclined,
    ]

    newAllProducts = removeDuplicateProducts(allProducts)
    allProductsSorted = sortProductsByStatus(newAllProducts)
  }

  if (!order) return null

  const {
    _id: orderId,
    shortId,
    ownerId,
    isPaid,
    restId: { name, avatarUrl, _id },
    status: statuses,
    consumptionType,
    deliveryAddress,
    deliveryDetails,
    consumptionDate,
    consumptionTime,
    consumptionPersons, // IKR
    note,
    isInvoice,
    invoiceDetails,
    deliveryCost,
    paidSum,
    priceSum,
    messages,
  } = order

  // console.log(order)

  const status = [...statuses].pop().name

  return (
    <React.Fragment>
      <TopNav
        title={shortId}
        subtitle={` - ${ownerId.personal.name} ${ownerId.personal.surname}`}
        photo={ownerId.personal.avatarUrl}
        textCss={{
          [s.xs]: { maxWidth: `calc(100vw - 9rem)` },
        }}
      />

      {/* HEADLINE */}
      <div css={sOuter}>
        <Headline
          itemChildren={
            <div
              css={{ cursor: 'pointer' }}
              onClick={() => setIsStatusGuideOpen(!isStatusGuideOpen)}>
              <StatusOrder color={ORDER_STATUS_COLOR[status]}>
                {ORDER_STATUSES[status]}
              </StatusOrder>
            </div>
          }
          extraCss={sHeadline}>
          {shortId}
        </Headline>
        <Grid container css={sGrid}>
          <OrderUser
            personal={ownerId.personal}
            consumptionDate={consumptionDate}
            consumptionTime={consumptionTime}
            consumptionPersons={consumptionPersons}
            consumptionType={consumptionType}
            priceSum={priceSum}
            paidSum={paidSum}
            isPaid={isPaid}
          />
        </Grid>
      </div>

      <div css={[sOuter, sNoMarginTop]}>
        <Grid
          container
          css={[sGrid, sGridSpacing]}
          spacing={3}
          justify='space-between'>
          <SectionHeadline>Produkty</SectionHeadline>
          {allProductsSorted.map((product, productIndex) => (
            <OrderProduct key={productIndex} data={product} />
          ))}
        </Grid>
      </div>

      {/* ORDER DETAILS */}
      <div css={[sOuter, sNoMarginTop, sGridSpacingParent]}>
        <Grid
          container
          css={[sGrid, sGridSpacing]}
          spacing={3}
          justify='space-between'>
          <SectionHeadline>Szczegóły</SectionHeadline>

          <OrderChatHistory
            orderId={orderId}
            userId={null}
            waiter
            noPaddingBottom
          />

          <OrderStatuses
            statuses={statuses}
            waiter
            open={isStatusGuideOpen}
            setOpen={setIsStatusGuideOpen}
          />

          {/* ORDER TYPE */}
          <OrderType consumptionType={consumptionType} />

          {/* ORDER ADDRESS */}
          {consumptionType === 'delivery' && (
            <OrderAddress
              deliveryAddress={deliveryAddress}
              deliveryDetails={deliveryDetails}
            />
          )}

          {/* ORDER DATE */}
          <OrderDate
            consumptionDate={consumptionDate}
            consumptionTime={consumptionTime}
            consumptionPersons={consumptionPersons}
          />

          {/* ORDER NOTE */}
          {note && <OrderNote note={note} />}

          {/* ORDER INVOICE */}
          {isInvoice && <OrderInvoice invoiceDetails={invoiceDetails} />}

          {/* ORDER ID */}
          <OrderID shortId={shortId} orderId={_id} />

          {/* ORDER PAYMENT */}
          <OrderPaymentLarge
            priceSum={priceSum}
            paidSum={paidSum}
            deliveryCost={deliveryCost}
            isDelivery={consumptionType === 'delivery'}
          />
        </Grid>
      </div>
      <StatusGuide open={isStatusGuideOpen} setOpen={setIsStatusGuideOpen} />
    </React.Fragment>
  )
}

const sHeadline = {
  alignItems: 'center',
  [s.xs]: {
    padding: '2rem 1.5rem 1.5rem',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}
const sGridSpacingParent = {
  [s.xs]: {
    '.MuiGrid-spacing-xs-3': {
      width: '100%',
      margin: 0,

      '& > .MuiGrid-item': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
}

const sGridSpacing = {
  '.MuiGrid-item': {
    [s.xs]: { paddingBottom: '4px !important' },
  },
}

export default OrderView
