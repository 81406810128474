/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import eatzonLogo from '../../../style/assets/eatzon'
import Lava from '../../../style/assets/lava'
import { toast } from 'react-toastify'
import { s, colors, alpha } from '../../../style'
import packageJson from '../../../../package.json'
import { ButtonBase } from '@material-ui/core'
import { sha256 } from 'js-sha256'
import ArrowForward from '@material-ui/icons/ArrowForwardIos'
import { Button } from '../../../components/Elements'

const PasswordProtected = ({ isAccess, setIsAccess }) => {
  const [passwordInput, setPasswordInput] = useState('')
  const [isError, setIsError] = useState(false)

  const hashedPassword =
    '887826774cb51854ea1f82c15332f36b89d5779bfbc35e2f4ebb7b3e10d0518b'

  const _handleSubmit = (event) => {
    event.preventDefault()
    const hashed = sha256(passwordInput)
    if (hashed === hashedPassword) {
      toast.success(
        'Poznaj Eatzon! Odkrywaj nowe, przepyszne dania i ciekawe lokale gastronomiczne w Twojej okolicy ' +
          ' 🍕 😋',
        { autoClose: false }
      )
      toast.info(
        'Restauratorze! Eatzon to platforma do zarządzania procesami w lokalach gastronomicznych. Dzięki niej możesz pozyskać nowych klientów i zwiększysz wydajność Twojej restauracji 🔥',
        { autoClose: false }
      )
      setIsAccess(true)
    } else setIsError(true)
  }

  return (
    <>
      <div css={sLava}>
        <span className='lava'>{Lava}</span>
      </div>
      <div css={sOuter}>
        <div css={sLogo}>
          {eatzonLogo}
          <span css={sVersion}>{packageJson.version}</span>
        </div>
        <div
          css={{
            marginBottom: '2rem',
            [s.md]: {
              marginBottom: '4rem',
            },
          }}
        />
        <ButtonBase
          css={{
            borderRadius: 24,
            [s.sm_down]: {
              width: 'calc(100vw - 4rem)',
            },
          }}>
          <form onSubmit={_handleSubmit}>
            <input
              type='password'
              required={true}
              placeholder='Hasło dostępu'
              value={passwordInput}
              onChange={(e) => {
                // if (sha256(e.target.value) === hashedPassword) _handleSubmit(e)
                if (isError) setIsError(false)
                setPasswordInput(e.target.value)
              }}
              css={[
                sTextInput,
                !passwordInput && {
                  backgroundColor: colors.text.concat(alpha[8]) + ' !important',
                  '&:hover': {
                    [s.hover]: {
                      backgroundColor:
                        colors.text.concat(alpha[16]) + ' !important',
                    },
                  },
                },
                passwordInput &&
                  isError && {
                    color: colors.red.concat(alpha[90]) + ' !important',
                    backgroundColor:
                      colors.red.concat(alpha[16]) + ' !important',
                  },
              ]}
            />
          </form>
        </ButtonBase>
        <Button dimmed action={_handleSubmit} extraCss={sArrowForward}>
          <ArrowForward />
        </Button>
      </div>
    </>
  )
}

const sOuter = {
  width: '100vw',
  height: '90vh',
  maxWidth: 960,
  margin: '0 auto',
  padding: '1.5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',

  // '@keyframes fadeIn': {
  //   from: { opacity: 0 },
  //   to: { opacity: 1 },
  // },
}

const sLogo = {
  // svg: {
  //   width: 300,
  //   height: 'auto',
  //   maxWidth: '80%',
  // },
  animation: 'fadeIn 0.6s 1 ease-in alternate both 0s',
  position: 'relative',
  width: 300,
  maxWidth: '80%',
  display: 'flex',
}

const sVersion = {
  animation: 'fadeIn 0.3s 1 ease-in alternate both 0.3s',
  position: 'absolute',
  right: 0,
  bottom: -24,
  fontWeight: 800,
  letterSpacing: '0.1em',
  fontSize: '0.875rem',
  [s.xs]: {
    bottom: -16,
  },
}

const sLava = {
  '.lava': {
    animation: 'fadeIn 1.2s 1 ease-in alternate both 0s',
    svg: {
      pointerEvents: 'none',
      width: '100vw',
      height: 'auto',
      position: 'fixed',
      right: 0,
      bottom: 0,
      [s.xs]: {
        width: '150vw',
        transform: 'scale(1.3) translate(20%, -10%)',
        right: '50%',
      },
      [s.md]: {
        transform: 'translateY(4%)',
      },
      [s.xl]: {
        transform: 'translateY(16%)',
      },
    },
  },
}

export const sTextInput = {
  animation: 'fadeIn 0.6s 1 ease-in alternate both 0.6s',
  border: 'none',
  backgroundImage: 'none',
  transition:
    'opacity 0.25s ease-out, background-color 0.25s ease-out, color 0.25s ease-out',
  color: colors.text.concat(alpha[30]),
  backgroundColor: colors.text.concat(alpha[4]),
  [s.touch]: {
    color: colors.text.concat(alpha[60]),
    backgroundColor: colors.text.concat(alpha[8]),
  },
  '&:hover': {
    [s.hover]: {
      color: colors.text.concat(alpha[100]),
      backgroundColor: colors.text.concat(alpha[16]),
    },
  },
  ':focus': {
    '::placeholder': {
      opacity: 0,
    },
    outline: 'none',
    [s.touch]: {
      color: colors.text.concat(alpha[100]),
      backgroundColor: colors.text.concat(alpha[16]),
    },
  },
  width: '50%',
  borderRadius: 24,
  padding: '0 1.5rem',
  textAlign: 'center',
  fontWeight: 500,
  fontFamily: 'Gilroy',
  boxSizing: 'border-box',
  letterSpacing: '0.2em',
  '::placeholder': {
    transition: 'opacity 0.25s ease-out',
    fontWeight: 400,
    opacity: 0.6,
    letterSpacing: 0,
    [s.lg]: {
      opacity: 0.3,
    },
  },
  backdropFilter: 'blur(16px)',
  [s.sm_down]: {
    width: 'calc(100vw - 4rem)',
    height: 64,
    fontSize: 32,
  },
  [s.sss]: {
    fontSize: 24,
  },
  [s.md]: {
    height: 96,
    width: '100%',
    fontSize: 48,
  },
}

const sArrowForward = {
  [s.lg]: { display: 'none' },
  marginTop: '2rem',
  color: colors.text.concat(alpha[60]),
  padding: 0,
  width: 64,
  height: 64,
  borderRadius: 64,
  backgroundColor: colors.text.concat(alpha[8]),
  backdropFilter: 'blur(16px)',
  animation: 'fadeIn 0.6s 1 ease-in alternate both 1s',
  '&:hover': {
    [s.hover]: {
      backgroundColor: colors.text.concat(alpha[16]),
      color: colors.text,
    },
  },
}

export default PasswordProtected
