import React, { useState, useEffect } from "react";
import packageJson from "../package.json";



function withClearCache(Component) {

  const buildDateGreaterThan = (latestDate, currentDate) => latestDate > currentDate

  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    useEffect(() => {
      fetch("/meta.json")
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = packageJson.buildDate;

          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate
          );

          if (shouldForceRefresh) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        })
        .catch(err => {
          console.log(err);
          setIsLatestBuildDate(true);
        })
    }, []);

    function refreshCacheAndReload() {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      // delete browser cache and hard reload
      window.location.reload()
    };

    return isLatestBuildDate ? <Component {...props} /> : null
  }

  return ClearCacheComponent;
}

export default withClearCache;