/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { ButtonBorder } from '../../../components/Elements'
import Headline from '../../../components/Headline/Headline'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import { sOuter } from '../../../style/style'
import RestaurantProvider, {
  useRestaurantContext,
} from './context/restaurant.provider'
import Details from './components/Details'
import ConsumptionTypes from './components/ConsumptionTypes'
import OpenHours from './components/OpenHours'
import { Collapse } from '@material-ui/core'
import DeliverySettings from './components/DeliverySettings'
import DeliveryCosts from './components/DeliveryCosts'
import DeliveryHours from './components/DeliveryHours'
import ButtonAdd from './components/ButtonAdd'

const RestaurantNewPage = () => {
  const { restaurantInfo } = useRestaurantContext()

  return (
    <React.Fragment>
      <TopNav title={'Nowa Restauracja'} offset={60} />
      <div css={sOuter}>
        <Headline
        // itemChildren={<ButtonBorder>Przycisk</ButtonBorder>}
        >
          Nowa Restauracja
        </Headline>
        <Details />
        <ConsumptionTypes />
        <OpenHours />
        <Collapse in={restaurantInfo.consumptionTypes.includes('delivery')}>
          <DeliverySettings />
          <DeliveryCosts />
          <DeliveryHours />
        </Collapse>
      </div>
      <ButtonAdd />
    </React.Fragment>
  )
}

export default () => (
  <RestaurantProvider>
    <RestaurantNewPage />
  </RestaurantProvider>
)
