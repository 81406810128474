/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Line } from 'react-chartjs-2'

import { useGetStatisticSales } from '../../hooks/useQueries'
import { useActiveRestaurantContext } from '../../context/active-restaurant.provider'

import { Grid } from '@material-ui/core'

export function dataParse(data) {
  const labels = data.map((el) => el.label.slice(8))
  const values = data.map((el) => el.value)

  return {
    labels,
    values,
  }
}

const ChartSales = () => {
  const { activeRestaurantId } = useActiveRestaurantContext()
  const { data, loading, error } = useGetStatisticSales(activeRestaurantId)

  if (loading) return null
  if (error) return null

  const { labels, values } = dataParse(data.ezrSalesStatistics)

  return (
    <Grid item xs={12} lg={6}>
      <Line data={dataLine(labels, values)} options={options} height={300} />
    </Grid>
  )
}

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
}

const dataLine = (labels, data) => ({
  labels,
  datasets: [
    {
      legend: { display: false },
      label: 'Sprzedaż',
      data,
      fill: true,
      lineTension: 0.25,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 3,
      pointRadius: 1,
      pointHitRadius: 20,
    },
  ],
})

export default ChartSales
