/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core'
import { sOuter, sGrid, sBottomLinks } from '../../../style/style'
import Headline from '../../../components/Headline/Headline'
import { ButtonBorder } from '../../../components/Elements'
import {
  OrdersHistoryFinished,
  OrdersHistoryDeclined,
} from './OrdersHistoryFinishedAndDeclined'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'

const OrdersHistory = () => (
  <React.Fragment>
    <TopNav title={'Historia Zamówień'} />
    <div css={sOuter}>
      <Headline>Historia Zamówień</Headline>
      <Grid container css={sGrid}>
        <OrdersHistoryFinished />
        <OrdersHistoryDeclined />
      </Grid>
      <Grid container css={sGrid}>
        <Grid
          item
          xs={12}
          css={[
            sBottomLinks,
            // { marginTop: '3rem', [s.md]: { marginTop: '4rem' } },
          ]}>
          <ButtonBorder link={'/orders'}>Zamówienia</ButtonBorder>
          {/* <ButtonBorder link={'/orders-finished'}>
            Legacy Historia Zamówień
          </ButtonBorder> */}
        </Grid>
      </Grid>
    </div>
  </React.Fragment>
)

export default OrdersHistory
