import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useGetEzcAdminChat } from '../../../hooks/useChat.queries'
import { useAdminChatMutations } from '../../../hooks/useChat.mutations'


const useAdminChatClient = ({
  scrollToBottom,
  newMssg,
  setNewMssg,
  userId
}) => {

  const history = useHistory()
  const [chatId, setChatId] = useState(undefined)
  const { data, error, loading, subscribeToMore, refetch } = useGetEzcAdminChat()
  const { onAddMssgAdminChat, onReadMssgsChat } = useAdminChatMutations()

  useEffect(() => { refetch() }, [])
  useEffect(() => { scrollToBottom() }, [data])
  useEffect(() => {
    const isActiveChatOnServer = data && data.ezcAdminChat
    if (isActiveChatOnServer) setChatId(data.ezcAdminChat._id)
  }, [data])

  const onCloseChat = () => history.goBack()

  const onSend = () => {
    if (newMssg.length === 0) return

    onAddMssgAdminChat(
      {
        chatId,
        message: newMssg
      },
      (res) => {
        setNewMssg('')
        scrollToBottom()
        if (!chatId) {
          refetch()
          setChatId(res.data.adminChatAddMssg._id)
        }
      }
    )
  }

  const onReadMssgs = () => {
    if (!data) return

    const isUnreadMssgs = data.ezcAdminChat.messages.find(mssg => (mssg.fromUserId !== userId && !mssg.isRead))
    if (!isUnreadMssgs || !chatId) return

    onReadMssgsChat({ chatId })
  }

  return {
    chatId,
    setChatId,
    data,
    error,
    loading,
    subscribeToMore,
    onCloseChat,
    onSend,
    onReadMssgs
  }

}

export default useAdminChatClient