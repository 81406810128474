import { useMutation } from '@apollo/client'
import { useError } from '../../hooks/errors.hook'
import {
  GENERATE_TRANSACTIONS_CSV,
  CHANGE_RESTAURANT_OWNER,
  CHARGE_RESTAURANT
} from '../graphql/restaurants.graphql'


const useRestaurantsMutations = () => {
  const { errorHandling } = useError()
  const [adminGenerateCsvOfTransactionsByRestaurant] = useMutation(GENERATE_TRANSACTIONS_CSV)
  const [adminChangeRestaurantOwner] = useMutation(CHANGE_RESTAURANT_OWNER)
  const [adminChargeRestaurant] = useMutation(CHARGE_RESTAURANT)


  /**
   * 
   * @param {String} restId Restaurant's _id
   * @param {Number} coinsAmount Coin's amount to charge the restaurant.
   * @returns {CommonResponse|undefined} if error returns undefined. 
   */
  const handleChargeRestaurant = async (restId, coinsAmount) => {
    try {
      const variables = { restId, coinsAmount }
      const res = await adminChargeRestaurant({ variables })
      return res
    } catch (err) { errorHandling(err) }
  }

  /**
   * 
   * @param {String} restId Restanrt's _id
   * @param {String} toUserId Future's owner _id
   * @returns {CommonResponse|undefined} if error returns undefined. 
   */
  const handleChangeRestaurantOwner = async (restId, toUserId) => {
    try {
      const variables = { restId, toUserId }
      const res = await adminChangeRestaurantOwner({ variables })
      return res
    } catch (err) { errorHandling(err) }
  }

  /**
   * 
   * @param {String} restId Restaurant's _id
   * @param {Date} startDate 
   * @param {Date} endDate 
   * @returns {String|undefined} if error returns undefined. Otherwise, returns csv file's path.
   */
  const handleGenerateTransactionsCsv = async (restId, startDate, endDate) => {
    try {
      const variables = { restId, startDate, endDate }
      const res = await adminGenerateCsvOfTransactionsByRestaurant({ variables })
      return res
    } catch (err) { errorHandling(err) }
  }

  return {
    handleChargeRestaurant,
    handleChangeRestaurantOwner,
    handleGenerateTransactionsCsv
  }
}

export default useRestaurantsMutations