/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import MainContainer from '../../components/MainContainer/MainContainer'
import ChooseRestaurant from './views/ChooseRestaurant'
import ChartOrdersAmount from './views/ChartOrdersAmount'
import ChartSales from './views/ChartSales'


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
}

export default () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => setValue(newValue)

  return (
    <MainContainer header="Pulpit">

      <ChooseRestaurant />

      <Divider />

      <FormControl css={{ margin: '8px 0' }}>
        <InputLabel>Okres</InputLabel>
        <Select defaultValue={14}>
          <MenuItem value={14}>14 dni</MenuItem>
          {/* <MenuItem value={30}>1 miesiąc</MenuItem>
          <MenuItem value={90}>3 miesiące</MenuItem>
          <MenuItem value={180}>6 miesiący</MenuItem>
          <MenuItem value={365}>rok</MenuItem> */}
        </Select>
      </FormControl>

      <div css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexWrap: 'wrap'
      }}>
        <div css={{ position: 'relative', width: '66%', marginBottom: 20 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Sprzedaż" />
            <Tab label="Ilość zamówień" />
          </Tabs>

          <TabPanel value={value} index={0}>
            <br />
            <ChartSales />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <br />
            <ChartOrdersAmount />
          </TabPanel>
        </div>
      </div>

    </MainContainer>
  )
}