/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import NoteIcon from '@material-ui/icons/PostAdd'
import OrderNote from './OrderNote.component'
import { Collapsable } from '../../../../components/Elements'
import { Grid } from '@material-ui/core'

const CheckoutNote = ({ note, onSaveNote }) => {
  const [parentIsOpen, parentSetIsOpen] = useState(false)
  return (
    <Grid item xs={12} md={6}>
      <Collapsable
        parentIsOpen={parentIsOpen}
        parentSetIsOpen={parentSetIsOpen}
        left
        noPadding
        oneLiner
        title={'Notatka'}
        icon={<NoteIcon />}
        description={note || 'Informacje dla restauracji'}>
        <OrderNote
          note={note}
          onSaveNote={onSaveNote}
          parentSetIsOpen={parentSetIsOpen}
        />
      </Collapsable>
    </Grid>
  )
}

export default CheckoutNote
