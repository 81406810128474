import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { sendToPaynow } from '../../../../helpers/paynow.helper'
import useOrdersMutations from '../../../hooks/useOrders.mutations'
import MoneyEz from '../../../../helpers/MoneyEz'

const usePayment = ({ defaultValue, orderId }) => {
  const [amount, setAmount] = useState(defaultValue || '')
  const [isLoading, setIsLoading] = useState(false)
  const { handlePayForOrderFromWallet } = useOrdersMutations()

  useEffect(() => {
    onValueChange(defaultValue)
  }, [defaultValue])

  function onValueChange(e) {
    const val = e && e.target ? e.target.value : e // use this function with different args
    setAmount(val)
  }

  async function onSubmit() {
    if (countDecimals(amount) > 2)
      return toast.error('Nieprawidłowa liczba. Za dużo cyfr po przycinku.')
    const money = MoneyEz(parseFloat(amount))

    if (defaultValue && defaultValue > money.getAmount())
      return toast.error(
        'Kwota nie może być mniejsza, niż brakująca kwota w zamówieniu.'
      )

    setIsLoading(true)
    const res = await sendToPaynow(money.getGroshi(), orderId)
    if (!res) setIsLoading(false)
  }

  function onPayFromWallet() {
    setIsLoading(true)
    handlePayForOrderFromWallet({ orderId }, () => setIsLoading(false))
  }

  function countDecimals(value) {
    if (typeof value === 'undefined') return
    if (Math.floor(value) === parseFloat(value)) return 0
    return value.toString().split('.')[1].length || 0
  }

  return {
    amount,
    isLoading,
    onValueChange,
    onSubmit,
    onPayFromWallet,
  }
}

export default usePayment
