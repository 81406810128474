/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useHistory } from 'react-router-dom'
import arrayMove from 'array-move';

import ChipInput from 'material-ui-chip-input';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import ModifierGroupProvider, { useModifierGroupContext } from './context/modifier-group.context'
import {
  SortableContainer,
  SortableItem
} from './views/ModifierItems'
import Quantities from './views/Quantities'
import ConditionalQuantities from './views/ConditionalQuantities'


const ModifierGrouPage = () => {
  const {
    state,
    onChangeState,
    modifierItems,
    setModifierItems,
    onAddModifierItems,

    amountRules,
    onChangeAmountRules
  } = useModifierGroupContext()
  const [modifiersToAdd, setModifiersToAdd] = useState([])

  const history = useHistory()

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setModifierItems(arrayMove(modifierItems, oldIndex, newIndex))
  };


  return (
    <div>

      <div css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16
      }}>
        <IconButton onClick={history.goBack}>
          <ArrowBackIcon />
        </IconButton>

        <div css={{
          button: {
            margin: '2px 6px'
          }
        }}>
          <Button
            variant="contained"
          >
            Usuń
          </Button>
          <Button
            variant="contained"
            disabled
          >
            Duplikuj
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled
          >
            Zapisz
          </Button>
        </div>
      </div>

      <input
        value={state.name}
        onChange={onChangeState.name}
        placeholder="Podaj nazwę grupy modyfikatorów"
        css={{
          marginBottom: 8,
          border: 'none',
          fontSize: 24,
          width: '100%',
          maxWidth: 420,
          borderBottom: '1px solid transparent',
          borderColor: 'transparent',
          transition: 'border-color .2s ease-out',

          "&:hover": {
            borderColor: '#000',
          }
        }}
      />
      <hr />

      <div css={{
        padding: '12px 0',
        display: 'flex',
        alignItems: 'flex-start'
      }}>
        <div css={{
          maxWidth: 720,
          marginRight: 8
        }}>
          <ChipInput
            value={modifiersToAdd}
            fullWidth
            placeholder="Dodaj modyfikatory"
            onAdd={(chip) => setModifiersToAdd([...modifiersToAdd, chip])}
            onKeyDown={e => {
              if ((e.metaKey && e.key === 'Enter') || (e.ctrlKey && e.key === 'Enter')) {
                onAddModifierItems(modifiersToAdd)
                setModifiersToAdd([])
              }
            }}
            onDelete={(chip, index) =>
              setModifiersToAdd([...modifiersToAdd.slice(0, index), ...modifiersToAdd.slice(index + 1)])}
          />
          <p css={{
            width: '100%',
            maxWidth: 400,
            opacity: '.6',
            fontSize: 14,
            marginTop: 8
          }}>
            Wpisz nazwę modyfikatora i wciśnij Enter. Jak skończysz, wciśnij przycisk Dodaj
          </p>
        </div>
        <Button
          variant="contained"
          color="primary"
          disabled={!modifiersToAdd.length}
          css={{
            marginLeft: 12
          }}
          onClick={() => {
            onAddModifierItems(modifiersToAdd)
            setModifiersToAdd([])
          }}
        >
          Dodaj
        </Button>
      </div>

      <br />

      <SortableContainer onSortEnd={onSortEnd} useDragHandle>
        {modifierItems.map((el, index) => <SortableItem key={el._id} index={index} value={el} />)}
      </SortableContainer>

      <br />
      <br />

      <h3 css={{ marginBottom: 4 }}>Reguły</h3>
      <p css={{ fontSize: 14, opacity: '.6' }}>Ustaw reguły, aby kontrolować jak klienci wybierają modyfikatory produktu.</p>
      <br />

      <p onClick={onChangeAmountRules.isRequired} css={{
        display: 'inline-block',
        padding: '4px 8px',
        borderRadius: 4,
        cursor: 'pointer',
        userSelect: 'none',
        boxShadow: amountRules.isRequired ? 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px' : undefined,
        transition: 'box-shadow ease-out .1s',
      }}>
        <Checkbox
          checked={amountRules.isRequired}
          color="default"
        />
        Czy jest wymagany wybór modyfikatora?
      </p>

      <br />
      <br />

      <div css={{
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
        padding: 24,
        borderRadius: 8
      }}>
        {amountRules.isConditionalQuantities
          ? <ConditionalQuantities />
          : <Quantities />}
      </div>
      <div css={{ margin: '8px 0' }}>
        <span
          onClick={onChangeAmountRules.isConditionalQuantities}
          css={{
            cursor: 'pointer',
            borderBottom: '1px solid #444',
            paddingBottom: 2
          }}
        >
          {amountRules.isConditionalQuantities
            ? "Wróć do zwykłych ustawień"
            : "Ustaw warunkowe ilości"}
        </span>
      </div>

    </div>
  )
}


export default () => (
  <ModifierGroupProvider>
    <ModifierGrouPage />
  </ModifierGroupProvider>
)