export function isLoading({ categories, first30Ingredients }) {
  return (
    categories.cuisine.loading
    || categories.mealtype.loading
    || categories.popular.loading
    || categories.daytime.loading
    || categories.diet.loading
    || first30Ingredients.loading
  )
}


export function isError({ categories, first30Ingredients }, errorHandling) {

  if (categories.cuisine.error) errorHandling(categories.cuisine.error)
  if (categories.mealtype.error) errorHandling(categories.mealtype.error)
  if (categories.popular.error) errorHandling(categories.popular.error)
  if (categories.daytime.error) errorHandling(categories.daytime.error)
  if (categories.diet.error) errorHandling(categories.diet.error)
  if (first30Ingredients.error) errorHandling(first30Ingredients.error)

  return (
    categories.cuisine.error
    || categories.mealtype.error
    || categories.popular.error
    || categories.daytime.error
    || categories.diet.error
    || first30Ingredients.error
  )
}