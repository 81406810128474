import { useState } from 'react'

export default () => {
  const [productInfo, setProductInfo] = useState(initProductInfo)

  const onChangeInfo = (e) =>
    setProductInfo({ ...productInfo, [e.target.name]: e.target.value })

  return {
    productInfo,
    setProductInfo,
    onChangeInfo,
  }
}

export const initProductInfo = {
  name: '',
  description: '',
  price: '',
  vat: '',
  cookTime: '',
  consumptionTypes: ['in', 'out', 'delivery'],
  _id: '',
  isArchived: false,
}
