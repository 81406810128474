/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import MainContainer from '../../components/MainContainer/MainContainer'
import ProductProvider from './context/product.provider'

import AvailableDays from './views/AvailableDays'
import Categories from './views/Categories'
import CookMethods from './views/CookMethods'
import Description from './views/Description'
import Flavors from './views/Flavors'
import Ingredients from './views/Ingredients'
import Name from './views/Name'
import NumInputs from './views/NumInputs'
import ProductImage from './views/ProductImage'
import AddButton from './views/AddButton'
import ConsumptionTypes from './views/ConsumptionTypes'


/*
 * TODO: Optimize context / state handling.
 * Details: now we update WHOLE page with WHOLE data after EVERY change on input etc.
*/

const ProductPage = () => {
  return (
    <MainContainer header="Nowy produkt">

      <Name />
      <Description />
      <br />
      <ConsumptionTypes />
      <br />

      <AvailableDays />
      <Flavors />

      <CookMethods />
      <Categories />

      <Ingredients />
      <NumInputs />

      <ProductImage />
      <AddButton />

    </MainContainer>
  )
}



export default () => (
  <ProductProvider>
    <ProductPage />
  </ProductProvider>
)