/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify'
import { useParams, useLocation, useHistory } from 'react-router-dom'

// import Layout from '../../components/Layout'
import OrderChatView from '../../views/Chat/OrderChat.view'
import isCorrectId from '../../../helpers/check-correct-id'

const OrdersChat = () => {
  const history = useHistory()
  const orderId = useParams().id
  const restParams = useLocation().search

  if (!isCorrectId(orderId)) {
    toast.error(
      'Nieprawidłowe ID chatu (1). Spróbuj wejść ponownie. Jeśli nadal widzisz ten błąd, skontaktuj się z nami, używając Chatu z Eatzon, który znajdziesz w widoku Konto lub na podstronie /account',
      { autoClose: false }
    )
    history.push('/ezr/orders')
    return null
  }

  let name = ''
  let avatarUrl = ''
  if (restParams) {
    const paramsArr = restParams.slice(1).split('&')
    name = paramsArr.find((el) => el.includes('name='))?.slice(5) || ''
    avatarUrl =
      paramsArr.find((el) => el.includes('avatarUrl='))?.slice(10) || ''
  }

  return (
    // <Layout>
    <OrderChatView
      orderId={orderId}
      name={decodeURI(name)}
      avatarUrl={decodeURI(avatarUrl)}
      onCloseChat={() => history.push('/order-active/' + orderId)}
    />
    // </Layout>
  )
}

export default OrdersChat
