/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { krakenUrls } from '../../../constants/s3-files'
import MoneyEz from '../../../helpers/MoneyEz'


const RestaurantItem = ({ avatarUrl, name, address, coins, helperComponent }) => (
  <div css={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 12px',
    border: '1px solid #777',
    borderRadius: 8,
    marginBottom: 16,
    width: '100%',
    maxWidth: 720
  }}>
    {avatarUrl && (
      <div css={{
        borderRadius: '50%',
        width: 40,
        height: 40,
        marginRight: 12,
        backgroundImage: `url(${krakenUrls.small + avatarUrl})`,
        backgroundPosition: '50%',
        backgroundSize: 'cover',
      }} />
    )}
    <div css={{ flexGrow: 1 }}>
      <h4>{name}</h4>
      {address && <p>{address}</p>}
      <p>Saldo: {MoneyEz(coins).getAmountWithFullPrecisionAndZl()}</p>
    </div>
    {helperComponent && <div css={{ marginLeft: 12 }}>{helperComponent}</div>}
  </div>
)

export default RestaurantItem