import React, { createContext, useContext } from 'react'
import useProducts from '../hooks/useProducts'

function noop() { };
export const ProductsContext = createContext({
  products: [],
  loading: undefined,
  error: undefined,
  searchString: '',
  onSearchString: noop

})

export const useProductsContext = () => useContext(ProductsContext)



export default ({ children }) => {

  return (
    <ProductsContext.Provider value={{
      ...useProducts()
    }}>
      {children}
    </ProductsContext.Provider>
  )
}