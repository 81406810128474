/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core'
import { Collapsable, Bullet, Row } from '../../../../components/Elements'
import { krakenUrls } from '../../../../constants/s3-files'
import UpdateIcon from '@material-ui/icons/Update'
import FastfoodIcon from '@material-ui/icons/Fastfood'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import { colors } from '../../../../style'
import CancelIcon from '@material-ui/icons/Cancel'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

const icons = {
  NEW_ORDER_STATUS: <UpdateIcon fontSize='inherit' />,
  PROD_STATUS: <FastfoodIcon fontSize='inherit' />,
  FINISH_ORDER: <AssignmentTurnedInIcon fontSize='inherit' />,
  ANNUL_ORDER_BY_CLIENT: <CancelIcon fontSize='inherit' />,
  ANNUL_ORDER_BY_REST: <CancelIcon fontSize='inherit' />,
  ANNUL_PROD: <RemoveCircleOutlineIcon fontSize='inherit' />,
  NEW_PRODS: <AddCircleOutlineIcon fontSize='inherit' />,
  NEW_ORDER: <AddCircleIcon fontSize='inherit' />,
}

const Notification = ({ data, id, onRead }) => {
  const { newest, notifications } = data
  const notisL = notifications.length

  // console.log(data)

  return (
    <Grid item xs={12} md={6}>
      <div onClick={() => (!newest.isRead ? onRead() : null)}>
        <Collapsable
          extraCss={newest.isRead && { opacity: 0.3 }}
          noPadding
          oneLiner
          left={id % 2 === 0}
          right={id % 2 !== 0}
          title={newest.header}
          image={
            newest.fromRest
              ? krakenUrls.small + newest.fromRest.avatarUrl
              : false
          }
          imageLink={
            newest.type === 'NEW_ORDER_STATUS' ||
            newest.type === 'ANNUL_PROD' ||
            newest.type === 'PROD_STATUS'
              ? `/order-active/${newest.orderId}`
              : `/orders-history/${newest.orderId}`
          }
          description={
            <div
              css={{
                overflowX: 'scroll',
                display: 'flex',
                alignItems: 'center',
              }}>
              <span>{newest.message}</span>
              <Bullet />
              {newest.howManyTimeAgo}
              <Bullet />
              {notisL}{' '}
              {notisL === 1
                ? 'powiadomienie'
                : notisL < 5
                ? 'powiadomienia'
                : 'powiadomień'}
            </div>
          }>
          {notifications.map((notifi, id) => (
            <Row
              extraCss={{
                '.row-children': {
                  flexShrink: 0,
                  color: colors.gray600,
                  fontSize: '0.75rem',
                  paddingLeft: '0.5rem',
                },
              }}
              key={id}
              desc={
                <div>
                  <p css={{ fontWeight: 'bold', color: colors.text }}>
                    {notifi.header}
                  </p>
                  <p css={{ fontSize: '0.75rem' }}>
                    {notifi.message}
                    {/* {notifi.type} */}
                  </p>
                </div>
              }
              icon={icons[notifi.type]}>
              {`${new Date(notifi.createdAt).toISOString()}`
                .slice(0, 16)
                .split('T')
                .map((e, id) => (
                  <p key={id}>{e}</p>
                ))}
            </Row>
          ))}
        </Collapsable>
      </div>
    </Grid>
  )
}

export default Notification
