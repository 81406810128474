/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import useMakeRestaurateur from './useMakeRestaurateur'
import {
  Button,
  FindUserByEmail,
  UserItem
} from '../../components'



const EzAdminMakeRestaurateur = () => {
  const {
    getUser,
    data,
    loading,
    isLoading,
    onChangeRole
  } = useMakeRestaurateur()

  return (
    <div>
      <FindUserByEmail getUser={getUser} loading={loading} />
      {data?.adminGetUserByEmail
        && (
          <UserItem
            {...data.adminGetUserByEmail}
            helperComponent={
              <Button
                onClick={onChangeRole}
                disabled={isLoading}
              >Zmień rolę</Button>
            }
          />
        )}
    </div>
  )
}

export default EzAdminMakeRestaurateur