import { useState } from 'react' // eslint-disable-line no-unused-vars
import { useLocation, useHistory } from 'react-router-dom'

import { useGetActiveOrders } from './useQueries'
import { navbarActiveLinkStyles } from '../components/Navbar/Navbar.ezw'
import { ezwRoutePrefix } from '../pages/settings'


const useOrdersHandle = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const [isAttentionDisable, setIsAttentionDisable] = useState(true)
  const { data, loading, error } = useGetActiveOrders()

  function addStyles(param) {
    const url = ezwRoutePrefix + '/' + pathname.split('/')[2]
    if (url === param) return navbarActiveLinkStyles
    return {}
  }

  function onClickAttention() {
    setIsAttentionDisable(true)
    history.push(`${ezwRoutePrefix}/orders-attention`)
  }

  function onCloseAttention(e) {
    e.stopPropagation()
    setIsAttentionDisable(true)
  }

  return {
    data,
    loading,
    error,
    isAttentionDisable,
    setIsAttentionDisable,

    addStyles,
    onClickAttention,
    onCloseAttention
  }
}

export default useOrdersHandle