import { InMemoryCache, makeVar, gql } from '@apollo/client'

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    me: String!
    activeRestaurant: String
  }
`

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn() {
          return isLoggedInVar()
        },
        me() {
          return userVar()
        },
        activeRestaurant() {
          return activeRestaurantVar()
        },
      },
    },
  },
})

export const activeRestaurantVar = makeVar(
  localStorage.getItem('activeRestaurant')
)
export const userVar = makeVar(localStorage.getItem('userId'))
export const isLoggedInVar = makeVar(!!localStorage.getItem('token'))
