/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { lightFormat } from 'date-fns';

import {
  FindRestaurant,
  Button,
  PopupContainer,
  SearchResultsRestaurants
} from '../../components'
import useTransactionsByRestaurant from './useTransactionsByRestaurant'
import CsvCalendar from './views/CsvCalendar'


const EzAdminTransactionsByRestaurants = () => {
  const {
    // fetching
    getRestaurant,
    data,
    loading,
    // state data
    state,
    setState,
    onRestaurantChoose,
    // popup
    isPopupOpen,
    onPopupClose,
    // async fn
    onGenerateCsv
  } = useTransactionsByRestaurant()

  const ItemHelperComponent = ({ restId }) => (
    <Button onClick={() => onRestaurantChoose(restId)}>
      Wybierz
    </Button>
  )

  return (
    <React.Fragment>
      <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <FindRestaurant getRestaurant={getRestaurant} loading={loading} />
        <SearchResultsRestaurants
          restaurants={data?.adminGetRestaurantByName}
          ItemHelperComponent={ItemHelperComponent}
        />
      </div>

      <PopupContainer
        isOpen={isPopupOpen}
        onClose={onPopupClose}
        headerText="Podaj zakres dat i pobierz plik"
        btnText={`Pobierz plik csv 
          od ${lightFormat(state[0].startDate, 'dd-MM-yyyy')} 
          do ${lightFormat(state[0].endDate, 'dd-MM-yyyy')}`}
        btnOnClick={onGenerateCsv}
        css={{ "div.MuiDialog-paperWidthSm": { maxWidth: 700 } }}
      >
        <CsvCalendar
          state={state}
          setState={setState}
        />
      </PopupContainer>
    </React.Fragment>
  )
}


export default EzAdminTransactionsByRestaurants