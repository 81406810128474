/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useError } from '../../../hooks/errors.hook'

import {
  Button,
  FindUserByEmail,
  UserItem
} from '../../components'

import {
  useChangeAdminPermission,
  useGetUserByEmail
} from '../../hooks/useSuperAdminGql'



const EzAdminMakeRestaurateur = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { errorHandling } = useError()
  const [getUser, { data, loading, error, refetch }] = useGetUserByEmail()
  const { handleAdminPermission } = useChangeAdminPermission()

  if (error) errorHandling(error)

  console.log(data?.superAdminGetUserByEmail)

  const onChangeRole = async () => {
    try {
      setIsLoading(true)
      await handleAdminPermission({ userId: data?.superAdminGetUserByEmail._id })

      await refetch()
      setIsLoading(false)

    } catch (err) {
      errorHandling(err)
      setIsLoading(false)
    }
  }

  return (
    <div>
      <FindUserByEmail getUser={getUser} loading={loading} />
      {data?.superAdminGetUserByEmail
        && (
          <UserItem
            {...data.superAdminGetUserByEmail}
            helperComponent={
              <Button
                onClick={onChangeRole}
                disabled={isLoading}
              >Zmień rolę</Button>
            }
          />
        )}
    </div>
  )
}

export default EzAdminMakeRestaurateur