/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useRoutesEzc } from '../../../routes'
import { useAuthContext } from '../../../context/auth.context'
import { OrderInCartByRestaurantIdProvider } from '../../context/OrderInCartByRestaurant/OrderInCartByRestaurant.provider'
import NoAuthOrdersContextProvider from '../../context/NoAuthOrders/NoAuthOrders.provider'
import { ActiveOrdersListenerProvider } from '../../context/ActiveOrdersListener/ActiveOrdersListener.context'

import Layout from '../../components/Layout/Layout.component'

export default () => {
  const { isAuth } = useAuthContext()
  const routes = useRoutesEzc(isAuth)

  return (
    <NoAuthOrdersContextProvider>
      <OrderInCartByRestaurantIdProvider>
        <ActiveOrdersListenerProvider isAuth={isAuth}>
          <Layout>{routes}</Layout>
        </ActiveOrdersListenerProvider>
      </OrderInCartByRestaurantIdProvider>
    </NoAuthOrdersContextProvider>
  )
}
