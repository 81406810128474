/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useOrderAttentionContext } from '../context/order-attention.context'
import { consumptionTypeTypes } from '../../../../constants/types'



export default () => {
  const { orderWeWorkOn: {
    deliveryAddress,
    deliveryDetails,
    consumptionType
  } } = useOrderAttentionContext()

  return consumptionType === consumptionTypeTypes.delivery && (
    <div css={{
      margin: '8px 0',
      border: '1px solid #777',
      borderRadius: 8,
      padding: 8
    }}>
      <h3 css={{ marginBottom: 8 }}>Szczegóły dostawy</h3>
      <p>Adres: {deliveryAddress}</p>
      {deliveryDetails && <p>Szczegóły: {deliveryDetails}</p>}
    </div>
  )
}