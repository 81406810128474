import { useState } from 'react'


export default () => {
  const [isCartOpen, setIsCartOpen] = useState(false)

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsCartOpen(open);
  };

  return {
    isCartOpen,
    toggleDrawer
  }
}