import { useMutation } from '@apollo/client'
// import { toast } from 'react-toastify'
import { useError } from '../../hooks/errors.hook'

import { LOGIN } from '../graphql/auth.graphql'


const useAuth = () => {
  const { errorHandling } = useError()
  const [waiterLogin] = useMutation(LOGIN)

  const handleLogin = async ({ login, password }, cb, errCb) => {
    try {
      const response = await waiterLogin({ variables: { login, password } })
      if (cb) cb()
      return response

    } catch (err) {
      errorHandling(err)
      if (errCb) errCb()
    }
  }

  return {
    handleLogin
  }
}

export default useAuth