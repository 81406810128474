import React from 'react'

import AddImgHandler from '../../../components/AddImgHandler/AddImgHandler'
import { useRestaurantContext } from '../context/restaurant.provider'


export default () => {
  const {
    backgroundPreview,
    backgroundUrl,
    handleAddBackground
  } = useRestaurantContext()

  return (
    <AddImgHandler
      onChange={handleAddBackground}
      preview={backgroundPreview}
      imgUrl={backgroundUrl}
      header="Tło"
      subheader="Wybierz tło widoku restauracji"
      name="photofile"
    />
  )
}