/** @jsx jsx */
import React, { useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import LinearProgress from '@material-ui/core/LinearProgress';

import { useError } from '../../../hooks/errors.hook'
import { useGetRestaurant } from '../../hooks/useQueries'
import MainContainer from '../../components/MainContainer/MainContainer'

import Name from './views/Name'
import Description from './views/Description'
import Address from './views/Address'
import OpenningHours from './views/OpenningHours'
import ActiveStatus from './views/ActiveStatus'
import Categories from './views/Categories'
import ImgBackground from './views/ImgBackground'
import ImgLogo from './views/ImgLogo'
import SaveButton from './views/SaveButton'
import ConsumptionTypes from './views/ConsumptionTypes'
import DeliverySettings from './views/DeliverySettings'

import RestaurantProvider, { useRestaurantContext } from './context/restaurant.provider'



const RestaurantPage = () => {
  const { errorHandling } = useError()
  const { data, loading, error } = useGetRestaurant()
  const {
    setBackgroundUrl,
    setLogoUrl,
    setOpenningHours,
    setRestaurantInfo,
    handleCategories,
    setDeliverySettings,
    restaurantInfo
  } = useRestaurantContext()

  useEffect(() => {
    if (data?.ezrRestaurant) {
      const restaurant = data.ezrRestaurant
      setLogoUrl(restaurant.avatarUrl)
      setBackgroundUrl(restaurant.photoUrl)
      setOpenningHours(restaurant.openningHours)

      const {
        name,
        description,
        address,
        location: { coordinates },
        isActive,
        consumptionTypes,
        deliverySettings
      } = restaurant

      setDeliverySettings({ ...deliverySettings })

      setRestaurantInfo({
        name,
        description,
        address,
        location: { coordinates },
        isActive,
        consumptionTypes
      })

      handleCategories(restaurant.categories.map(el => el._id))
    }
  }, [data])

  if (loading) return <LinearProgress />
  if (error) {
    errorHandling(error)
    return null
  }

  return (
    <MainContainer header="Restauracja" >

      <Name />
      <Description />

      <Address />
      <Categories />
      <br />
      <ConsumptionTypes />

      {restaurantInfo.consumptionTypes.includes('delivery')
        && <DeliverySettings />}

      <br />
      <OpenningHours />
      <br />

      <ActiveStatus />

      <ImgBackground />
      <ImgLogo />

      <SaveButton />

    </MainContainer>
  )
}


export default () => (
  <RestaurantProvider>
    <RestaurantPage />
  </RestaurantProvider>
)