import { useQuery } from '@apollo/client'

import {
  GET_PRODUCT,
  GET_PRODUCTS,
  GET_CATEGORIES,
  GET_INGREDIENTS
} from '../graphql/products.graphql'

import {
  GET_TRANSACTIONS_AND_BALANCE
} from '../graphql/payments.graphql'

import {
  GET_RESTAURANT,
  GET_RESTAURANTS,
  GET_SALES_STATISTICS,
  GET_ORDERS_AMOUNT_STATISTICS,
  GET_WAITER_DASHBOARD_ACCESS
} from '../graphql/restaurants.graphql'



export const useGetCategories = () => useQuery(GET_CATEGORIES)

export const useGetIngredients = () => useQuery(GET_INGREDIENTS)

export const useGetProduct = (prodId) => useQuery(GET_PRODUCT, { variables: { prodId } })

export const useGetProducts = () => {
  const restId = localStorage.getItem('activeRestaurant')
  return useQuery(GET_PRODUCTS, { variables: { restId } })
}

export const useGetPaymentsDetails = () => {
  const restId = localStorage.getItem('activeRestaurant')
  return useQuery(GET_TRANSACTIONS_AND_BALANCE, { variables: { restId } })
}

export const useGetRestaurant = () => {
  const restId = localStorage.getItem('activeRestaurant')
  return useQuery(GET_RESTAURANT, { variables: { restId } })
}

export const useGetRestaurants = () => useQuery(GET_RESTAURANTS)

// Graphs & statistics
export const useGetStatisticSales = () => {
  const restId = localStorage.getItem('activeRestaurant')
  return useQuery(GET_SALES_STATISTICS, { variables: { restId } })
}

export const useGetStatisticOrdersAmount = () => {
  const restId = localStorage.getItem('activeRestaurant')
  return useQuery(GET_ORDERS_AMOUNT_STATISTICS, { variables: { restId } })
}

export const useGetWaiterDashoboardAccess = () => {
  const restId = localStorage.getItem('activeRestaurant')
  return useQuery(GET_WAITER_DASHBOARD_ACCESS, { variables: { restId } })
}