import { useEffect } from 'react'

import { useGeolocationContext } from '../../../context/Geolocation/Geolocation.provider'
import { usePlacesAutocomplete } from '../../../../components/GooglePlacesAutocomplete'
import { useNoAuthOrdersContext } from '../../../context/NoAuthOrders/NoAuthOrders.provider'
import {
  _getDate,
  // invoiceDataCheck
} from './utils'



export default ({ orderId }) => {
  const {
    ordersNoAuth,
    onUpdateOrderNoAuth
  } = useNoAuthOrdersContext()

  const { onSelectAddress } = usePlacesAutocomplete()
  const { geolocation, setGeolocation } = useGeolocationContext()

  const orderWeWorkOn = ordersNoAuth.find(order => order._id === orderId)

  useEffect(() => {
    if (orderWeWorkOn) {
      const { d, h } = _getDate()
      onUpdateOrderNoAuth({
        invoiceDetails: {},
        ...orderWeWorkOn,
        deliveryAddress: orderWeWorkOn.deliveryAddress || geolocation?.address,
        consumptionDate: orderWeWorkOn.consumptionDate || d,
        consumptionTime: orderWeWorkOn.consumptionTime || h,
      })
    }
  }, [])


  return {
    orderWeWorkOn,

    onChange: e => onUpdateOrderNoAuth({ ...orderWeWorkOn, [e.target.name]: e.target.value }),

    handleSelectAddress(address) {
      onSelectAddress(address)
        .then(({ lng, lat }) => {
          setGeolocation({ address, lat, lng })
          onUpdateOrderNoAuth({ ...orderWeWorkOn, deliveryAddress: address })
        })
    },

    onChangeAddres(address) {
      onUpdateOrderNoAuth({ ...orderWeWorkOn, deliveryAddress: address })
    },

    onSaveNote(note) {
      onUpdateOrderNoAuth({ ...orderWeWorkOn, note: note })
    },

    onIsInvoiceChange() {
      onUpdateOrderNoAuth({ ...orderWeWorkOn, isInvoice: !orderWeWorkOn.isInvoice })
    },

    onInvoiceDetailsChange(e) {
      onUpdateOrderNoAuth({
        ...orderWeWorkOn,
        invoiceDetails: {
          ...orderWeWorkOn.invoiceDetails,
          [e.target.name]: e.target.value
        }
      })
    }
  }
}