/** @jsx jsx */
import React, { useState, useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

import { useOrdersContext } from '../../context/orders.context'
import OrderActiveSummary from './views/OrderActiveSummary'
import OrderActive from './views/OrderActive.view'


const EzwOrdersActive = () => {
  const { activeOrders } = useOrdersContext()

  if (activeOrders.length === 0) return (
    <div css={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    }}>
      <h1 css={{ marginBottom: '10vh' }}>Już poszukujemy Klientów :)</h1>
    </div>
  )

  return (
    <div css={{ padding: '0 24px' }}>
      <h1 css={{ opacity: .3 }}>
        Aktywne zamówienia
      </h1>


      <MuiAccordionSummary expandIcon={<span css={{ width: 24 }} />}>
        <OrderActiveSummary info />
      </MuiAccordionSummary>

      {activeOrders
        .filter(order => order.isPaid)
        .map(order => <OrderActive key={order._id} {...order} />)}

      {!!activeOrders.find(q => q.isPaid) && <hr css={{ border: 'none', height: 50 }} />}

      {activeOrders
        .filter(order => !order.isPaid)
        .map(order => <OrderActive key={order._id} {...order} />)}
    </div>
  )
}

export default EzwOrdersActive