/** @jsx jsx */
import React, { useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useRoutesEZR } from '../../../routes'
import Layout from '../../components/Layout'
import { useAuthContext } from '../../../context/auth.context'
import ActiveRestaurantProvider from '../../context/active-restaurant.provider'
import { useActiveRestaurantContext } from '../../context/active-restaurant.provider'
import { useGetRestaurants } from '../../hooks/useQueries'
import { useQuery } from '@apollo/client'
import { GET_RESTAURANT } from '../../graphql/restaurants.graphql'

const IndexPage = () => {
  const { isAuth } = useAuthContext()
  const routes = useRoutesEZR(isAuth)

  const {
    data: dataRestaurants,
    loading: loadingRestaurants,
    error: errorRestaurants,
  } = useGetRestaurants()
  const { activeRestaurantId, handleActiveRestaurantId } =
    useActiveRestaurantContext()
  const {
    data: dataRestaurant,
    loading: loadingRestaurant,
    error: errorRestaurant,
  } = useQuery(GET_RESTAURANT, {
    variables: { restId: activeRestaurantId },
    skip: !activeRestaurantId,
  })

  useEffect(() => {
    if (dataRestaurants?.ezrRestaurants?.length && !activeRestaurantId) {
      handleActiveRestaurantId(dataRestaurants.ezrRestaurants[0]._id)
    }
  }, [dataRestaurants])

  if (!activeRestaurantId) return null

  return <Layout>{routes}</Layout>
}

export default () => (
  <ActiveRestaurantProvider>
    <IndexPage />
  </ActiveRestaurantProvider>
)
