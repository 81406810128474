/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { s, colors } from '../../../style'

const CarouselItemList = ({ data, categoryId, id, last, ...otherProps }) => (
  <div
    {...otherProps}
    css={{
      paddingLeft: '1.5rem',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      transition: 'all 0.3s ease-out',
      scrollSnapAlign: 'start',
    }}>
    <span
      css={[
        sStyle,
        categoryId === data._id && sActiveStyle,
        id === 0 && { paddingLeft: '0.5rem' },
        last && { paddingRight: '1.5rem' },
      ]}>
      {data.name}
    </span>
  </div>
)

const sStyle = {
  transition: 'color 0.3s ease-out',
  fontSize: '1rem',
  letterSpacing: '-0.05em',
  [s.md]: { fontSize: '1.5rem' },
  fontWeight: 500,
  width: 'max-content',
  color: colors.gray400,
  ':hover': {
    color: colors.gray600,
  },
}

const sActiveStyle = {
  fontWeight: 800,
  color: colors.text,
  ':hover': {
    color: colors.text,
  },
  // textShadow: `-1px -1px 0 ${colors.text}, 1px -1px 0 ${colors.text}, -1px 1px 0 ${colors.text}, 1px 1px 0 ${colors.text}`,
}

export default CarouselItemList
