/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { Grid } from '@material-ui/core'
import { SectionHeadline } from '../../../../components/Elements'
import { sDescription, sGrid } from '../../../../style/style'
import { useRestaurantContext } from '../context/restaurant.provider'

import {
  consumptionTypeTypes as types,
  consumptionTypeNames,
  consumptionTypeIcons,
} from '../../../../constants/types'
import { colors, s, alpha } from '../../../../style'

const ConsumptionTypes = () => {
  const { restaurantInfo, setRestaurantInfo } = useRestaurantContext()
  const { consumptionTypes } = restaurantInfo

  const onChange = (type) => {
    const value = consumptionTypes.includes(type)
      ? consumptionTypes.filter((el) => el !== type)
      : [...consumptionTypes, type]

    setRestaurantInfo({ ...restaurantInfo, consumptionTypes: value })
  }

  return (
    <Grid container css={[sGrid]}>
      <SectionHeadline noPaddingLeft>Typy Zamówień</SectionHeadline>
      <p css={sDescription}>
        Zaznacz, które sposoby zamówień są aktualnie dostępne w Twojej
        restauracji.
      </p>
      <Grid item xs={12} css={sContainer}>
        <div
          css={[
            sTypeButton,
            consumptionTypes?.includes(types.in) && sTypeButtonActive,
          ]}
          onClick={() => onChange(types.in)}>
          {consumptionTypeIcons[types.in]}
          <p>{consumptionTypeNames[types.in]}</p>
        </div>
        <div
          css={[
            sTypeButton,
            consumptionTypes?.includes(types.out) && sTypeButtonActive,
            { [s.md]: { margin: '0 1.5rem' } },
          ]}
          onClick={() => onChange(types.out)}>
          {consumptionTypeIcons[types.out]}
          <p>{consumptionTypeNames[types.out]}</p>
        </div>

        <div
          css={[
            sTypeButton,
            consumptionTypes?.includes(types.delivery) && sTypeButtonActive,
          ]}
          onClick={() => onChange(types.delivery)}>
          {consumptionTypeIcons[types.delivery]}
          <p>{consumptionTypeNames[types.delivery]}</p>
        </div>
      </Grid>
    </Grid>
  )
}

const sContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '2rem',
  [s.md]: {
    justifyContent: 'flex-start',
  },
}

const sTypeButton = {
  cursor: 'pointer',
  transition: 'background-color 0.3s ease-out, color 0.3s ease-out',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontSize: '2rem',
  backgroundColor: colors.gray200,
  color: colors.gray500,
  padding: '1rem',
  borderRadius: '1rem',
  width: 160,
  [s.xs]: {
    width: '30%',
    maxWidth: '30%',
    flexBasis: '30%',
  },
  p: {
    textAlign: 'center',
    marginTop: '0.5rem',
    fontSize: '0.675rem',
    textTransform: 'uppercase',
    fontWeight: 800,
    [s.md]: {
      fontSize: '0.75rem',
    },
  },
}

const sTypeButtonActive = {
  backgroundColor: colors.orange.concat(alpha[16]),
  color: colors.orange,
}

export default ConsumptionTypes
