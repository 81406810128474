import EzrmIndex from './index/ezrm-index.page'
import EzrmDashboard from './dashboard/ezrm-dashboard.page'
import EzrmMenuEdit from './menu-edit/ezrm-menu-edit.page'
import EzrmModifierGroup from './modifier-group/ezrm-modifier-group.page'
import EzrmModifierGroups from './modifier-groups/ezrm-modifier-groups.page'
import EzrmPayments from './payments/ezrm-payments.page'
import EzrmPermissions from './permissions/ezrm-permissions.page'
import EzrmProduct from './product/ezrm-product.page'
import EzrmNewProduct from './product/ezrm-new-product.page'
import EzrmProducts from './products/ezrm-products.page'
import EzrmRestaurant from './restaurant/ezrm-restaurant.page'
import EzrmNewRestaurant from './restaurant/ezrm-new-restaurant.page'
// import EzrmStatistics from './statistics/'
import { ezrmRoutePrefix } from './settings'


const q = (route) => ezrmRoutePrefix.concat(route)

const ezrmRoutes = [
  { path: q('/dashboard'), component: EzrmDashboard },
  { path: q('/menu-edit'), component: EzrmMenuEdit },
  { path: q('/modifier-group/:id'), component: EzrmModifierGroup },
  { path: q('/modifier-groups'), component: EzrmModifierGroups },
  { path: q('/payments'), component: EzrmPayments },
  { path: q('/permissions'), component: EzrmPermissions },
  { path: q('/new-product'), component: EzrmNewProduct },
  { path: q('/product/:id'), component: EzrmProduct },
  { path: q('/products'), component: EzrmProducts },
  { path: q('/restaurant'), component: EzrmRestaurant },
  { path: q('/new-restaurant'), component: EzrmNewRestaurant }
]

export {
  EzrmIndex,
  ezrmRoutes,
  ezrmRoutePrefix
}