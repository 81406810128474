/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';

import MoneyEz from '../../../../helpers/MoneyEz'

import CsvCalendar from './CsvCalendar'


export default ({ coinsAmount }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <h3>Stan konta Eatzon</h3>

      <div css={{
        margin: '4px 0 8px',
        fontSize: 14
      }}>
        <p>
          Obecny stan konta: <b>{MoneyEz(coinsAmount || 0).getAmountWithFullPrecisionAndZl()}</b>
        </p>
      </div>

      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Pobierz plik csv
      </Button>

      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div>
          <CsvCalendar />
        </div>
      </Menu>
    </React.Fragment >
  )
}