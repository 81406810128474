/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { SectionHeadline } from '../../../../components/Elements'
import { sDescription, sGrid } from '../../../../style/style'
import { useProductContext } from '../context/product.provider'
import { colors, s } from '../../../../style'
import { sInput } from '../../../style/input.style'

const Price = () => {
  const { productInfo, onChangeInfo } = useProductContext()

  return (
    <Grid container css={sGrid}>
      <SectionHeadline noPaddingLeft>Cena</SectionHeadline>
      <Grid item xs={12}>
        <p css={[sDescription, { marginBottom: '0rem' }]}>
          Ustal wartość produktu oraz stawkę podatku VAT.
        </p>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        css={{ marginTop: '1rem', [s.md]: { paddingRight: '0.75rem' } }}>
        <TextField
          required
          label='Cena brutto (zł)'
          name='price'
          value={productInfo.price}
          onChange={onChangeInfo}
          fullWidth
          css={[sInput]}
        />
      </Grid>
      <Grid item xs={12} md={6} css={{ [s.md]: { paddingLeft: '0.75rem' } }}>
        <FormControl
          css={{
            width: '100%',
            position: 'relative',
            'label + .MuiInput-formControl': { marginTop: 0 },
          }}>
          <InputLabel
            css={{
              zIndex: 6,
              position: 'absolute',
              left: 24,
              top: 10,
              fontSize: '0.875rem',
              fontWeight: 800,
              color: colors.gray600,
              textTransform: 'uppercase',
              letterSpacing: '0.1em',
              transform: 'translate(0,1.5px) scale(0.75)',
              marginTop: '0 !important',
            }}>
            Stawka VAT
          </InputLabel>
          <Select
            fullWidth
            name='vat'
            value={typeof productInfo.vat === 'number' ? productInfo.vat : 0}
            onChange={onChangeInfo}
            css={[
              sInput,
              { '::before, ::after': { display: 'none !important' } },
            ]}
            required>
            <MenuItem value={0}>0 (Zwolniony)</MenuItem>
            <MenuItem value={0.05}>5%</MenuItem>
            <MenuItem value={0.08}>8%</MenuItem>
            <MenuItem value={0.23}>23%</MenuItem>
          </Select>
          {/* {productInfo.vat === 0 && (
            <FormHelperText>
              {'Wybieraj stawkę 0 tylko jeśli jesteś zwolniony z podatku VAT!'}
            </FormHelperText>
          )} */}
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default Price
