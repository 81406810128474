/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import useOrdersMutations from '../../../hooks/useOrders.mutations'
import { Button } from '../../../../components/Elements'
import { sendToPaynow } from '../../../../helpers/paynow.helper'

const ButtonAccept = ({ orderId, amountToPay, buttonTransparent }) => {
  const [isLoading, setIsLoading] = useState(false)

  const { handleUpdateOrderStatus } = useOrdersMutations()

  const onAccept = () => {
    setIsLoading(true)
    handleUpdateOrderStatus({ orderId, status: 'ACCEPTED' }, () => {
      if (amountToPay > 0) sendToPaynow(amountToPay * 10 * 10, orderId)
      else setIsLoading(false)
    })
  }

  return (
    <Button
      w100
      w100Desktop
      action={onAccept}
      loading={isLoading}
      buttonTransparent={buttonTransparent}>
      Potwierdź i Opłać
    </Button>
  )
}

export default ButtonAccept
