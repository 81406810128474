/** @jsx jsx */
import React, { useState, useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddIcon from '@material-ui/icons/Add';

import MainContainer from '../../components/MainContainer/MainContainer'

import AddProducts from './views/AddProducts'
import SectionsList from './views/SectionsList'
import PopupDialog from './components/PopupDialog'
import useMenuEdit from './hooks/useMenuEdit'



export default () => {
  const {
    loading,
    error,
    isLoading,
    menuSections,
    wasChanges,
    setWasChanges,
    activeSectionId,
    isAddProductsOpen,
    singleSectionProducts,

    setMenuSections,
    setSingleSectionProducts,

    handleAddProductsOpen,
    handleAddProductsClose,
    onAddSection,
    onDeleteSection,
    onDeleteProduct,
    onNameChange,
    onSaveAddedProducts,
    onSave

  } = useMenuEdit()

  if (loading) return <LinearProgress />
  if (error) return null

  const helperComponent = (
    <ButtonGroup size="large" variant="contained">
      <Button
        color="primary"
        startIcon={<AddIcon>Dodaj</AddIcon>}
        onClick={onAddSection}
      >
        Dodaj sekcję
      </Button>

      <Button
        onClick={onSave}
        color="secondary"
        disabled={!wasChanges || isLoading}
      >
        Zapisz zmiany
      </Button>
    </ButtonGroup>
  )

  return (
    <MainContainer
      header="Menu"
      helperComponent={helperComponent}
    >
      <SectionsList
        menuSections={menuSections}
        setMenuSections={setMenuSections}
        handleAddProductsOpen={handleAddProductsOpen}
        onDeleteSection={onDeleteSection}
        onDeleteProduct={onDeleteProduct}
        onNameChange={onNameChange}
        wasChanges={wasChanges}
        setWasChanges={setWasChanges}
      />
      <PopupDialog
        btnName="OK"
        btnHandle={onSaveAddedProducts}
        title="Dodaj produkty"
        isOpen={isAddProductsOpen}
        handleClose={handleAddProductsClose}
      >
        <AddProducts
          menuSections={menuSections}
          sectionId={activeSectionId}
          right={singleSectionProducts}
          setRight={setSingleSectionProducts}
        />
      </PopupDialog>
    </MainContainer>
  )
}