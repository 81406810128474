import React from 'react'
export default (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xlink='http://www.w3.org/1999/xlink'
    width='1920'
    height='622.999'
    viewBox='0 0 1920 622.999'>
    <defs>
      <linearGradient
        id='linear-gradient'
        x1='0'
        y1='0'
        x2='0.5'
        y2='1'
        gradientUnits='objectBoundingBox'>
        <stop offset='0' stopColor='#ffd500' stopOpacity='0.161' />
        <stop offset='0.382' stopColor='#fa0' stopOpacity='0.16' />
        <stop offset='1' stopColor='#ff8000' stopOpacity='0.6' />
      </linearGradient>
      <linearGradient
        id='linear-gradient-2'
        x1='0'
        y1='0'
        x2='1'
        y2='1'
        gradientUnits='objectBoundingBox'>
        <stop offset='0' stopColor='#ffd500' stopOpacity='0.6' />
        <stop offset='0.375' stopColor='#fa0' stopOpacity='0.302' />
        <stop offset='0.597' stopColor='#ff8000' stopOpacity='0.302' />
        <stop offset='1' stopColor='red' stopOpacity='0.6' />
      </linearGradient>
    </defs>
    <g id='Lava' transform='translate(2 -560.862)'>
      <path
        id='Path_97'
        data-name='Path 97'
        d='M-9878-4472.139v-166.5s51.646-172.289,201.911-173.325c54.348-.377,108.4,24.877,158.565,50.131,88.529,44.568,164.966,89.137,209.608-7.157,41.626-89.789,102.818-23.122,194.441,43.545,62.318,45.344,138.716,90.688,232.609,86.806,231.94-9.6,299.908-405.3,392.048-318.367s86.57,323.013,215.5,152.261S-8228.3-5144.039-7958-5082v609.859Z'
        transform='translate(9876 5656)'
        fill='url(#linear-gradient)'
      />
      <path
        id='Path_96'
        data-name='Path 96'
        d='M-9878-4472.139v-58.109s51.646-236.683,201.911-237.719c58.34-.4,116.337,38.436,169.555,77.272,83.847,61.19,155.833,122.383,198.619,30.1,41.626-89.789,102.818-23.122,194.441,43.545,62.318,45.342,138.716,90.686,232.609,86.8,231.94-9.594,299.908-405.295,392.048-318.362s86.57,323.008,215.5,152.258S-8202.3-5116.762-7958-5044v571.86Z'
        transform='translate(9876 5656)'
        fill='url(#linear-gradient-2)'
      />
    </g>
  </svg>
)
