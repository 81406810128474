/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import BottomLine from './components/BottomLine'
import MenuList from './components/MenuList'
import TopLogo from './components/TopLogo'




export default ({ showMenu, menuToggleDrawer }) => {

  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      anchor="left"
      open={showMenu}
      onClose={menuToggleDrawer(false)}
      onOpen={menuToggleDrawer(true)}
    >
      <Container menuToggleDrawer={menuToggleDrawer}>
        <TopLogo />
        <MenuList />

        <div css={{ flexGrow: 1 }} />

        <BottomLine />
      </Container>
    </SwipeableDrawer>
  )
}


// Components
const Container = ({ children, menuToggleDrawer }) => (
  <div
    role="presentation"
    onClick={menuToggleDrawer(false)}
    onKeyDown={menuToggleDrawer(false)}
    css={{
      width: 300,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}
  >{children}</div>
)