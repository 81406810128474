/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useChosenFilterItems } from './hooks'
import { cookMethods, flavors } from '../../../constants/types'
import { FilterOption } from './FilterGroup'
import { s, colors, alpha } from '../../../style'
import ScrollContainer from 'react-indiana-drag-scroll'

const FiltersChosen = ({
  prodFilter,
  categories,
  ingredientsList,
  addKeyToFilter,
  setOpen,
  open,
  onSubmit,
  clickDisabled,
}) => {
  const { data } = useChosenFilterItems({
    prodFilter,
    categories,
    ingredientsList,
    flavors,
    cookMethods,
  })

  return (
    <div css={sOuter}>
      <div
        css={[
          sBackground,
          open && { backgroundColor: colors.white, height: '100%', opacity: 0 },
        ]}
        onClick={() => setOpen(!open)}
      />
      <span
        css={[sTitle, !open && { color: colors.white }]}
        onClick={() => setOpen(!open)}>
        FILTRUJ
      </span>
      {prodFilter && data && (
        <ScrollContainer
          css={sScroller}
          onClick={() => !open && setOpen(!open)}>
          {[]
            .concat(data.cookMethods.must, data.cookMethods.mustNot)
            .map((id) => (
              <FilterOption
                key={id}
                id={id}
                name={cookMethods[id].pl}
                filterData={prodFilter && prodFilter.cookMethod}
                onChoose={() => addKeyToFilter('cookMethod')(id)}
                // onSubmit={onSubmit}
                open={open}
                setOpen={setOpen}
                noMargin
              />
            ))}
          {[].concat(data.flavors.must, data.flavors.mustNot).map((id) => (
            <FilterOption
              key={id}
              id={id}
              name={flavors[id].pl}
              filterData={prodFilter && prodFilter.flavors}
              onChoose={() => addKeyToFilter('flavors')(id)}
              // onSubmit={onSubmit}
              // clickDisabled={!open}
              open={open}
              setOpen={setOpen}
              noMargin
            />
          ))}
          {[]
            .concat(data.categories.must, data.categories.mustNot)
            .map((el) => (
              <FilterOption
                key={el._id}
                id={el._id}
                name={el.name}
                filterData={prodFilter && prodFilter.categories}
                onChoose={() => addKeyToFilter('categories')(el._id)}
                // onSubmit={onSubmit}
                // clickDisabled={!open}
                open={open}
                setOpen={setOpen}
                noMargin
              />
            ))}
          {[].concat(data.ingr.must, data.ingr.mustNot).map((el) => (
            <FilterOption
              key={el._id}
              id={el._id}
              name={el.name}
              filterData={prodFilter && prodFilter.ingredients}
              onChoose={() => addKeyToFilter('ingredients')(el._id)}
              // onSubmit={onSubmit}
              // clickDisabled={!open}
              open={open}
              setOpen={setOpen}
              noMargin
            />
          ))}
        </ScrollContainer>
      )}
    </div>
  )
}

const sOuter = {
  display: 'flex',
  width: '100%',
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 1,
  alignItems: 'center',

  padding: '1.5rem 0rem 1.5rem 2.5rem',
  [s.sm_down]: {
    padding: '1rem 0rem 1rem 2rem',
  },
  borderBottom: `2px solid ${colors.text.concat(alpha[8])}`,
  backgroundColor: colors.white,
}

const sTitle = {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  top: 0,
  left: 0,
  fontSize: '1.125rem',
  paddingRight: '0.5rem',
  fontWeight: 800,
  borderRadius: '2rem',
  minHeight: '2rem',
  [s.md]: { minHeight: 36, paddingRight: '2rem' },
  transition: 'color 0.3s ease-out',
  cursor: 'pointer',
}

const sScroller = {
  overflow: 'auto',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  top: 0,
  left: 0,
  // cursor: 'grab',
}

const sBackground = {
  transition:
    'background-color 0.15s ease-out, height 0.3s ease-out, opacity 0.3s ease-out',
  position: 'absolute',
  top: -2,
  left: 0,
  width: '100%',
  height: '300%',
  // borderRadius: '1.5rem',
  backgroundColor: colors.text,
  cursor: 'pointer',
}

export default FiltersChosen
