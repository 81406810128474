/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { Button } from '../../../components/Elements'
import useOrdersMutations from '../../hooks/useOrders.mutations'
import { useHistory } from 'react-router'

const ButtonFinish = ({ orderId }) => {
  const { handleFinishOrder } = useOrdersMutations()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = () => {
    setIsLoading(true)
    const cb = () => {
      setIsLoading(false)
      history.push(`/waiter/history/${orderId}`)
    }
    handleFinishOrder({ orderId }, cb)
  }

  return (
    <Button w100Mobile action={onFinish} loading={isLoading}>
      Zakończ Zamówienie
    </Button>
  )
}

export default ButtonFinish
