/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { useModifierGroupContext } from '../context/modifier-group.context'



const DragHandle = sortableHandle(() => <div css={{ paddingRight: 8, cursor: 'row-resize' }}><DragHandleIcon /></div>);

export const SortableItem = sortableElement(({ value }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null);

  const {
    onChangeModifierItem,
    onDeleteModifierItem
  } = useModifierGroupContext()

  return (
    <div css={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '12px 16px',
      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
      backgroundColor: '#fff'
    }}>
      <DragHandle />

      <div css={{
        flexGrow: 1,
        paddingLeft: 8
      }}>
        <input
          value={value.name}
          onChange={onChangeModifierItem.name(value._id)}
          placeholder="Podaj nazwę modyfikatora"
          css={{
            padding: '8px 0',
            border: 'none',
            fontSize: 20,
            width: '100%',
            height: '100%',
            maxWidth: 320,
            borderBottom: '1px solid transparent',
            borderColor: 'transparent',
            transition: 'border-color .2s ease-out',

            "&:hover": {
              borderColor: '#000',
            }
          }}
        />
      </div>

      <div css={{
        display: 'flex',
        alignItems: 'center'
      }}>
        <Input
          value={value.price}
          onChange={onChangeModifierItem.price(value._id)}
          endAdornment={<InputAdornment position="end">zł</InputAdornment>}
          css={{
            width: '100%',
            maxWidth: 120,
            input: {
              textAlign: 'right'
            }
          }}
        />
        <div css={{ marginLeft: 8 }}>
          <IconButton
            size="small"
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => {
              handleClose();
              onDeleteModifierItem(value._id)
            }}>Usuń</MenuItem>
          </Menu>
        </div>
      </div>

    </div>
  )
});

export const SortableContainer = sortableContainer(({ children }) => <div>{children}</div>)