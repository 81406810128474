import { useGetCategoriesByType, useGetIngredients } from '../../../hooks/useProds.queries';


const useFetchInputFilters = () => {

  const categories = {
    cuisine: useGetCategoriesByType('CUISINE'),
    mealtype: useGetCategoriesByType('MEALTYPE'),
    popular: useGetCategoriesByType('POPULAR'),
    daytime: useGetCategoriesByType('DAYTIME'),
    diet: useGetCategoriesByType('DIET')
  }
  const first30Ingredients = useGetIngredients(1, 30)

  const getCategories = (type) => categories[type].data.categoriesByType

  return {
    categories,
    first30Ingredients,

    getCategories
  }
}

export default useFetchInputFilters