import { gql } from '@apollo/client'

export const SUBS_ORDER_MESSAGES = gql`
  subscription addMssgToOrder($orderId: ID!) {
    addMssgToOrder(orderId: $orderId) {
      _id
      message
      author
      fromUserId
      fromRestId
    }
  }
`