function searchStringCreator(query, prodFilter) {
  let str = '?'
  if (query) str = str.concat('query=' + query.replace(/[^a-zA-ZąĄćĆęĘłŁńŃóÓśŚżŻźŹ\s]+/g, ''))
  if (!prodFilter) return str

  const floatKeys = [
    'price',
    'cookTime'
  ]

  floatKeys.forEach(key => {
    if (prodFilter[key]) {
      const { min, max } = prodFilter[key]
      if (min) str = str.concat(`&${key}Min=` + min)
      if (max) str = str.concat(`&${key}Max=` + max)
    }
  })

  const idKeys = [
    'categories',
    'ingredients',
    'flavors',
    'cookMethod'
  ]

  idKeys.forEach(key => {
    if (prodFilter[key]) {
      const { must, mustNot } = prodFilter[key]
      if (must) str = str.concat(`&${key}Must=` + must.join(','))
      if (mustNot) str = str.concat(`&${key}MustNot=` + mustNot.join(','))
    }
  })

  return str.charAt(1) === '&' ? '?' + str.slice(2) : str
}


export default searchStringCreator