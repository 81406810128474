import getStatusName from '../../helpers/get-status-name'


export function wasOrderJustPaid(order) {
  if (getStatusName(order.status) === 'ACCEPTED' && order.isPaid) return true

  return order.orderDetails[0]?.productsFinishedGood
    .find((product) => getStatusName(product.status) === 'ACCEPTED')
  // && order.isPaid
}

export function isOrderNew(order) {
  return getStatusName(order.status) === 'WAITING_FOR_ACCEPT'
}

export function hasOrderNewProducts(order) {
  return Boolean(order.orderDetails[0]?.productsToOrder.length)
}

export function wasOrderAnnuledByClient(order) {
  return getStatusName(order.status) === 'ANNUL_BY_CLIENT'
}