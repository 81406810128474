import { createContext, useContext } from 'react'

function noop() { };

export const WaiterAuthContext = createContext({
  waiterToken: null,
  restaurantId: null,
  waiterLogin: noop,
  waiterLogout: noop,
  waiterIsAuth: false
})

export const useWaiterAuthContext = () => useContext(WaiterAuthContext)