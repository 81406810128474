/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { s, colors, globals } from '../../../../style'

const ChatContainer = ({ children }) => (
  <div
    css={{
      width: '100%',
      height: `calc(100vh - 72px - 72px - 12px)`,
      margin: '0 auto',
      backgroundColor: colors.white,
      position: 'relative',
      maxWidth: globals.maxWidthMedium,
      padding: '2rem',
      paddingBottom: 0,
      [s.sm_down]: {
        height: `calc(100vh - 72px - 74px)`,
      },
    }}>
    {children}
  </div>
)

export default ChatContainer
