/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { s } from '../../../style'

const StatusOrder = ({ color, children, frontColor, noPadding }) => {
  return (
    <div
      css={[
        sStatusOrder,
        {
          background: `linear-gradient(to right, ${color}, ${color})`,
        },
        frontColor && { color: frontColor },
        noPadding && {
          paddingTop: 0,
          paddingBottom: 0,
          fontWeight: 500,
        },
      ]}>
      {children}
    </div>
  )
}

const sStatusOrder = {
  display: 'flex',
  flexWrap: 'wrap',
  whiteSpace: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  lineHeight: '1em',
  padding: '0 1rem',
  height: 32,
  textAlign: 'center',
  fontWeight: 800,
  letterSpacing: '0.025em',
  color: '#ffffff',
  fontSize: '0.875rem',
  borderRadius: 16,
  // whiteSpace: 'nowrap',
  // [s.ss]: { wordBreak: 'break-word' },
  // [s.sss]: {
  //   fontSize: 10 / 16 + 'rem',
  // },
  [s.sss]: {
    marginTop: '0.75rem',
  },
  [s.xs]: {
    marginTop: '0.5rem',
    minWidth: 200,
    letterSpacing: '0.025em',
  },
  [s.md]: {
    marginTop: 0,
    fontSize: '1rem',
    height: 40,
    padding: '0 1.5rem',
    borderRadius: 24,
  },
}

export default StatusOrder
