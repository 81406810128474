import React from 'react'
import { ProductList } from '../../../components'
import { useOrderAttentionContext } from '../context/order-attention.context'
import HeaderCondition from './HeaderCondition'


export default () => {
  const {
    alreadyOrderedProducts
  } = useOrderAttentionContext()

  return (
    <>
      <HeaderCondition condition={alreadyOrderedProducts?.length}>Zaakceptowane wcześniej produkty</HeaderCondition>
      <ProductList products={alreadyOrderedProducts} />
    </>
  )
}