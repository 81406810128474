/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { Grid } from '@material-ui/core'
import { Collapsable } from '../../../../components/Elements'
import { CollapsableText } from '../../../../components/Elements/Collapsable/Collapsable'
import SmsIcon from '@material-ui/icons/Sms'
import { useGetAnyOrderMssgs } from '../../../../hooks/useGraphql.hook'
import Messages from '../../../views/Chat/components/Messages.chat'

import { s } from '../../../../style'

const OrderChat = ({ userId, orderId, waiter, noPaddingBottom }) => {
  const { data, error, loading } = useGetAnyOrderMssgs(orderId)

  // console.log(data)

  // if (data) {
  //   console.log(data.anyOrderMessages)
  //   console.log(data.anyOrderMessages.length)
  // }

  return (
    <Grid item xs={12} md={6}>
      <Collapsable
        noPaddingBottom={noPaddingBottom}
        left
        noPadding
        oneLiner
        title={'Czat Archiwum'}
        icon={<SmsIcon />}
        description={
          loading ? (
            <>Wiadomości</>
          ) : error ? (
            <>Błąd odczytu czatu</>
          ) : data.anyOrderMessages?.length > 0 ? (
            `${data.anyOrderMessages?.length} wiadomości`
          ) : (
            'Brak wiadomości'
          )
        }>
        {data?.anyOrderMessages?.length > 0 ? (
          <div
            css={{
              padding: '1.5rem',
              [s.md]: { padding: '2rem' },
            }}>
            {data && (
              <Messages
                messages={data.anyOrderMessages}
                userId={userId}
                chatHistory
                isWaiter={waiter}
              />
            )}
          </div>
        ) : (
          <CollapsableText>
            Nie prowadzono rozmów z {waiter ? 'Klientem' : 'Restauracją'}
          </CollapsableText>
        )}
      </Collapsable>
    </Grid>
  )
}

export default OrderChat
