/** @jsx jsx */
import React, { useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import useChat from './hooks/useChat'
import useOrderChat from './hooks/useOrderChat'
import { useGetActiveOrderMssgs } from '../../../hooks/useGraphql.hook'
import { SUBS_ORDER_MESSAGES } from '../../../graphql/subscriptions.graphql'
import TopNavContent from '../../views/ProductPage/components/TopNavContent'

import {
  ChatContainer,
  ChatWindow,
  InputArea,
  Messages,
  MessagesContainer,
  HeaderInfo,
} from './components'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'

const OrderChat = ({ orderId, name, avatarUrl, onCloseChat, isWaiter }) => {
  const { data, error, loading, subscribeToMore, refetch } =
    useGetActiveOrderMssgs(orderId)
  const {
    newMssg,
    setNewMssg,
    measuredRef,
    scrollToBottom,
    errorHandling,
    userId,
  } = useChat()

  const { onSend } = useOrderChat(orderId, {
    newMssg,
    setNewMssg,
    scrollToBottom,
  })

  useEffect(() => {
    refetch()
  }, [])
  useEffect(() => {
    if (!data && !loading) {
      onCloseChat()
      return null
    }
    scrollToBottom()
  }, [data])

  if (error) {
    errorHandling(error)
    onCloseChat()
    return null
  }
  if (loading) return null

  return (
    <ChatContainer>
      <ChatWindow>
        <TopNav
          title={name}
          subtitle={` - Czat`}
          photo={avatarUrl !== 'undefined' ? avatarUrl : false}
          isScrolledAlready
        />
        {/* 
        <HeaderInfo
          name={name}
          avatarUrl={avatarUrl}
          onCloseChat={onCloseChat}
        /> */}

        <MessagesContainer measuredRef={measuredRef}>
          <Messages
            isWaiter={isWaiter}
            userId={isWaiter ? null : userId}
            messages={data.orderMessages}
            subscribeToNewMssgs={() =>
              subscribeToMore({
                document: SUBS_ORDER_MESSAGES,
                variables: { orderId },
                updateQuery: (prev, { subscriptionData }) => {
                  if (!subscriptionData.data) return prev
                  const newMssg = subscriptionData.data.addMssgToOrder
                  return Object.assign({}, prev, {
                    orderMessages: [...prev.orderMessages, newMssg],
                  })
                },
              })
            }
          />
        </MessagesContainer>

        <InputArea newMssg={newMssg} setNewMssg={setNewMssg} onSend={onSend} />
      </ChatWindow>
    </ChatContainer>
  )
}

export default OrderChat
