// import axios from 'axios'
import { useContext } from 'react'
import { useError } from '../../hooks/errors.hook'
import { AuthContext } from '../../context/auth.context'

import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import {
  USER_UPDATE_SETTINGS,
  USER_UPDATE,
  USER_RESET_PASSWORD,
  USER_NEW_PASSWORD,
  USER_CONFIRM_EMAIL,
  USER_SIGNUP,
  USER_LOGIN,
  GET_NOTIFICATIONS,
  READ_NOTIFICATION,
  GET_USER,
} from '../graphql/user.graphql'

import { useUploadImg } from '../../hooks/useUploadImg.hook'

export default function useUserMutations() {
  const { errorHandling } = useError()
  const { uploadImgAndGetURL } = useUploadImg()

  const auth = useContext(AuthContext)
  const history = useHistory()

  const [userChangeSettings] = useMutation(USER_UPDATE_SETTINGS)
  const [userUpdate] = useMutation(USER_UPDATE)
  const [userResetPassword] = useMutation(USER_RESET_PASSWORD)
  const [userConfirmEmail] = useMutation(USER_CONFIRM_EMAIL)
  const [userNewPassword] = useMutation(USER_NEW_PASSWORD)
  const [userCreate] = useMutation(USER_SIGNUP)
  const [userLogin] = useMutation(USER_LOGIN)

  const handleChangeUserSettings = (data, cb) => {
    const { isSendingClientPushes, isSendingRestPushes } = data
    userChangeSettings({
      variables: { input: { isSendingClientPushes, isSendingRestPushes } },
      refetchQueries: [{ query: GET_USER }],
    })
      .then((res) => {
        toast.dark('Ustawienia zostały zaktualizowane')
        if (cb) cb(res)
      })
      .catch((err) => {
        errorHandling(err)
      })
  }

  const handleUpdateAvatar = async (data, cb, errCb) => {
    const { imgFile } = data
    try {
      const s3url = await uploadImgAndGetURL(imgFile, 'USER_AVATAR')

      await userUpdate({
        variables: { input: { avatarUrl: s3url.data.getS3ImgUrlLink.imgName } },
        refetchQueries: [{ query: GET_USER }],
      })

      if (cb && typeof cb === 'function') cb()
    } catch (err) {
      errorHandling(err)
      if (typeof errCb === 'function') errCb()
    }
  }

  const handleUserUpdate = (data, cb) => {
    // const { email, name, surname } = data
    userUpdate({
      variables: { input: data },
      refetchQueries: [{ query: GET_USER }],
    })
      .then((res) => {
        toast.dark('Dane użytkownika zostały zaktualizowane')
        if (cb) cb(res)
      })
      .catch((err) => {
        errorHandling(err)
      })
  }

  const handleResetPassword = (data, cb) => {
    const { email } = data
    userResetPassword({
      variables: { email },
    })
      .then((res) => {
        if (cb && typeof cb === 'function') cb(res)
      })
      .catch((err) => {
        errorHandling(err)
      })
  }

  const handleNewPassword = (data, cb) => {
    const { token, password } = data
    userNewPassword({
      variables: { token, password },
    })
      .then((res) => {
        if (cb && typeof cb === 'function') cb(res)
      })
      .catch((err) => {
        errorHandling(err)
      })
  }

  const handleConfirmEmail = (data, cb) => {
    const { token } = data
    userConfirmEmail({
      variables: { token },
    })
      .then((res) => {
        if (cb && typeof cb === 'function') cb(res)
      })
      .catch((err) => {
        errorHandling(err)
        history.push('/')
      })
  }

  const handleLogin = (data, cb) => {
    const { email, password } = data

    userLogin({
      variables: {
        email: email.trim(),
        password: password.trim(),
      },
    })
      .then(({ data }) => {
        if (typeof cb === 'function') cb(data)

        auth.login(data.userLogin.token, data.userLogin.userId)

        toast.dark('Witamy ponownie! 🖐 ')
        // setTimeout(() => {
        //   window.location.reload() // refresh the cache
        // }, 2500)
      })
      .catch((err) => {
        if (typeof cb === 'function') cb({ userLogin: false })
        errorHandling(err)
      })
  }

  const handleSignup = async (data, cb) => {
    const { email, password, name, surname } = data

    try {
      const userDb = await userCreate({
        variables: {
          input: { email, password, name, surname },
        },
      })

      if (typeof cb === 'function') cb(userDb.data)

      auth.login(userDb.data.userCreate.token, userDb.data.userCreate.userId)
      toast.success(
        `Witamy w Eatzon${(name || surname) && ', '}${name && name + ' '}${
          surname && surname + ' '
        }! Na podany adres email wysłaliśmy link do potwierdzenia konta 😎`,
        {
          autoClose: false,
        }
      )

      // setTimeout(() => {
      //   window.location.reload() // refresh the cache
      // }, 2500)

      return userDb
    } catch (err) {
      if (typeof cb === 'function') cb({ userCreate: false })
      errorHandling(err)
    }
  }

  return {
    handleChangeUserSettings,
    handleUserUpdate,
    handleUpdateAvatar,

    handleResetPassword,
    handleConfirmEmail,
    handleNewPassword,
    handleSignup,
    handleLogin,

    userLogin,
    userCreate,
  }
}

export const useReadNotification = () => {
  const [userReadNotification] = useMutation(READ_NOTIFICATION)

  async function onReadNotification(data, cb) {
    const { notifId } = data
    const restId = localStorage.getItem('activeRestaurant')

    try {
      await userReadNotification({
        variables: { notifId, restId },
        refetchQueries: [{ query: GET_NOTIFICATIONS }],
      })
      if (typeof cb === 'function') cb()
    } catch (err) {
      console.log(err)
    }
  }

  return { onReadNotification }
}
