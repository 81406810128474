/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import { useModifierGroupContext } from '../context/modifier-group.context'


export default () => {
  // const {
  //   amountRules,
  //   onChangeAmountRules
  // } = useModifierGroupContext()

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <p>Dostosuj ilość na podstawie innej grupy modyfikatorów</p>
      <br />

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Grupa modyfikatorów</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          defaultValue={0}
        // value={age}
        // onChange={handleChange}
        >
          <MenuItem value={10}>Rozmiar</MenuItem>
          <MenuItem value={20}>Rodzaj bułki</MenuItem>
        </Select>
      </FormControl>

      <br />
      <br />

      <div css={{
        flexGrow: 1,
        width: '100%',
        maxWidth: 720
      }}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Item One" />
            <Tab label="Item Two" />
            <Tab label="Item Three" />
            <Tab label="Item Four" />
            <Tab label="Item Five" />
            <Tab label="Item Six" />
            <Tab label="Item Seven" />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          Item One
          <QuantitiesInputs />
        </TabPanel>
        <TabPanel value={value} index={1}>
          Item Two
          <QuantitiesInputs />
        </TabPanel>
        <TabPanel value={value} index={2}>
          Item Three
          <QuantitiesInputs />
        </TabPanel>
        <TabPanel value={value} index={3}>
          Item Four
          <QuantitiesInputs />
        </TabPanel>
        <TabPanel value={value} index={4}>
          Item Five
          <QuantitiesInputs />
        </TabPanel>
        <TabPanel value={value} index={5}>
          Item Six
          <QuantitiesInputs />
        </TabPanel>
        <TabPanel value={value} index={6}>
          Item Seven
          <QuantitiesInputs />
        </TabPanel>
      </div>

    </React.Fragment>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


const QuantitiesInputs = () => (
  <React.Fragment>
    <p>Ile maksymalnie modyfikatorów może wybrać klient?</p>
    <TextField
      type="number"
      // value={amountRules.maxSelectAmount}
      // onChange={onChangeAmountRules.maxSelectAmount}
      size="small"
    />

    <br />
    <br />
    <br />

    <p>Ile maksymalnie <u>pojedyńczych</u> modyfikatorów może wybrać klient?</p>
    <TextField
      type="number"
      // value={amountRules.maxSingleItemSelectAmount}
      // onChange={onChangeAmountRules.maxSingleItemSelectAmount}
      size="small"
    />
  </React.Fragment>
)