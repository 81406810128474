/** @jsx jsx */
import React, { useState, useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useParams, useHistory } from 'react-router-dom'
import Skeleton from '@material-ui/lab/Skeleton'
import { useError } from '../../../../hooks/errors.hook'
import { useGetRestaurantSkip } from '../../../hooks/useRests.queries'
import { Link } from 'react-router-dom'
import GooglePlacesAutocomplete from '../../../../components/GooglePlacesAutocomplete'
import {
  consumptionTypeTypes,
  consumptionTypeNames,
  consumptionTypeIcons,
} from '../../../../constants/types'
import MoneyEz from '../../../../helpers/MoneyEz'

import GetDeliveryInfo from '../../../views/GetDeliveryInfo/GetDeliveryInfo.view'

import ChoseType from '../components/ChoseType.component'
import InputFields from '../components/InputFields.component'
import InvoiceDetails from '../components/InvoiceDetails.component'

import useCheckoutLogic from '../hooks/useCheckoutLogic'
import RestContainer, {
  sContainer as sRestContainerExported,
} from '../../../views/ProductPage/components/RestContainer'
import Headline from '../../../../components/Headline/Headline'
import { globals, s, colors, alpha } from '../../../../style'
import {
  sOuter,
  sGrid,
  sNoMarginTop,
  sDescription,
  sBottomLinks,
} from '../../../../style/style'
import { Grid, Hidden, Collapse, ButtonBase } from '@material-ui/core'
import {
  Collapsable,
  Bullet,
  Button,
  ButtonBorder,
} from '../../../../components/Elements'
import EventIcon from '@material-ui/icons/Event'
import PersonIcon from '@material-ui/icons/Person'
import { dateFc } from '../../../../helpers/date-format'
import PersonPinIcon from '@material-ui/icons/PersonPinCircle'
import FastfoodIcon from '@material-ui/icons/Fastfood'
import DocumentIcon from '@material-ui/icons/InsertDriveFile'

import Input from '../../../views/Auth/components/Input.component'
import { ProductItem } from '../../../pages/cart/components/OrderItem.component'
import PageLoader from '../../../../components/PageLoader/PageLoader'
import OrderPayment from '../../orders/components/OrderPayment'
import CheckoutNote from '../components/CheckoutNote'
import { TopNav } from '../../../../components/TopNavFixed/TopNavFixed'

function later(delay) {
  return new Promise(function (resolve) {
    setTimeout(resolve, delay)
  })
}

export default () => {
  const history = useHistory()
  const orderId = useParams().id
  const { errorHandling } = useError()
  const [keepTimer, setKeepTimer] = useState(true)
  const [countNoData, setCountNoData] = useState(0)

  const {
    isLoading,
    loading,
    error,
    orderWeWorkOn,
    handleSelectAddress,
    onChange,
    onChangeAddres,
    onInvoiceDetailsChange,
    onIsInvoiceChange,
    onSaveNote,
    onSendOrder,
    setDeliveryCost,
    deliveryCost,
  } = useCheckoutLogic({ orderId })

  useEffect(() => {
    if (countNoData >= 1) {
      // console.log(
      //   'redirect to cart and force to redirect there to lastest checkout'
      // )
      history.push('/cart?checkout=1')
      // ok this and the interval effect below is for the bug: when user orders while being noauth and signs in, then the checkout id is changed cuz its being saved in database instead of localstorage (yup it shouldnt be like this wtf right?)
    }
  }, [countNoData])

  useEffect(() => {
    const interval = setTimeout(() => {
      if (!orderWeWorkOn) setCountNoData(countNoData + 1)
    }, 200)

    return () => clearInterval(interval)
  }, [orderWeWorkOn])

  if (error) {
    errorHandling(error)
    return null
  }

  if (loading || isLoading) return <PageLoader />

  if (!orderWeWorkOn) return <PageLoader />
  // return (
  //   <div css={sOuter}>
  //     <Headline>Podsumowanie</Headline>
  //     <div css={sGrid}>
  //       <p css={[sDescription, { animation: 'fadeIn 1s ease-out 2s' }]}>
  //         Ładowanie danych...
  //       </p>
  //     </div>
  //   </div>
  // )

  const {
    consumptionDate,
    consumptionPersons,
    consumptionTime,
    consumptionType,
    deliveryAddress,
    deliveryDetails,
    note,
    isInvoice,
    invoiceDetails,
    orderDetails,
    restId,
  } = orderWeWorkOn

  // console.log(isInvoice)
  // console.log(invoiceDetails)
  // console.log(orderWeWorkOn)

  const productsCost = MoneyEz(0)
    .sumProductArray(
      orderDetails[0].productsInCart.map(({ amount, prodId: { price } }) => ({
        amount,
        price,
      }))
    )
    .getAmount()

  const isDelivery = consumptionType === consumptionTypeTypes.delivery

  // console.log('deliveryError', deliveryError)

  return (
    // <Layout noNavbar>
    <React.Fragment>
      <TopNav
        title={orderWeWorkOn?.restId?.name}
        subtitle={`- Podsumowanie`}
        photo={orderWeWorkOn?.restId?.avatarUrl}
        textCss={{
          [s.xs]: { maxWidth: `calc(100vw - 9rem)` },
        }}
      />
      <div css={sOuter}>
        <Headline>Podsumowanie</Headline>
      </div>
      <Hidden mdUp>
        {restId ? (
          <GetRestaurantImage restId={restId._id} data={restId}>
            <RestContainer />
          </GetRestaurantImage>
        ) : (
          <Skeleton css={sRestContainerExported} />
        )}
      </Hidden>

      <div css={[sOuter, sNoMarginTop]}>
        {orderWeWorkOn && (
          <Grid container css={[sGrid]}>
            {/* SPOSÓB DOSTAWY */}
            <Grid item xs={12} md={6}>
              <Collapsable
                // openAlready
                left
                noPadding
                oneLiner
                title={consumptionTypeNames[consumptionType]}
                icon={consumptionTypeIcons[consumptionType]}
                description={
                  <div css={{ overflowX: 'scroll' }}>
                    Wybierz Sposób Zamówienia
                  </div>
                }
                extraCss={{ backgroundColor: colors.gray100 }}>
                <ChoseType
                  consumptionType={consumptionType}
                  onChange={onChange}
                  availableTypes={orderWeWorkOn?.restId?.consumptionTypes || []}
                />
              </Collapsable>
            </Grid>

            {/* DOSTAWA */}
            <Grid item xs={12} md={6}>
              <Collapse in={consumptionType === consumptionTypeTypes.delivery}>
                <Collapsable
                  // openAlready
                  wasOpenAlready
                  right
                  noPadding
                  oneLiner
                  title={'Adres'}
                  icon={<PersonPinIcon />}
                  description={
                    <div
                      css={{
                        overflowX: 'scroll',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      {deliveryAddress}
                      {deliveryDetails && (
                        <>
                          <Bullet />
                          {deliveryDetails}
                        </>
                      )}
                    </div>
                  }
                  extraCss={{
                    backgroundColor: colors.gray100,
                    overflow: 'hidden',
                  }}>
                  <div
                    css={{
                      [s.sm_down]: { padding: '1.5rem 1rem 0' },
                      [s.md]: { padding: '2rem 2rem 0' },
                    }}>
                    <GooglePlacesAutocomplete
                      value={deliveryAddress || ''}
                      onChange={onChangeAddres}
                      onSelect={handleSelectAddress}
                    />
                    <Input
                      darker
                      placeholder='Numer mieszkania, piętro...'
                      name='deliveryDetails'
                      onChange={onChange}
                      value={deliveryDetails}
                    />
                  </div>
                  <GetDeliveryInfo
                    restId={restId._id}
                    orderAmount={MoneyEz(0)
                      .sumProductArray(
                        orderDetails[0].productsInCart.map(
                          ({ amount, prodId: { price } }) => ({
                            amount,
                            price,
                          })
                        )
                      )
                      .getAmount()}
                    setDeliveryCost={setDeliveryCost}
                    // setDeliveryError={setDeliveryError}
                  />
                </Collapsable>
              </Collapse>
            </Grid>

            {/* TERMIN */}
            <Grid item xs={12} md={6}>
              <Collapsable
                // openAlready
                left
                noPadding
                oneLiner
                title={'Termin'}
                icon={<EventIcon />}
                description={
                  <div
                    css={{
                      overflowX: 'scroll',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    {dateFc(consumptionDate)}
                    <Bullet />
                    {consumptionTime}
                    <Bullet />
                    {consumptionPersons === 10 ? '10+' : consumptionPersons}
                    <PersonIcon
                      fontSize='inherit'
                      css={{ marginLeft: '0.25rem' }}
                    />
                  </div>
                }
                extraCss={{ backgroundColor: colors.gray100 }}>
                <InputFields
                  onChange={onChange}
                  consumptionDetails={{
                    consumptionDate,
                    consumptionPersons,
                    consumptionTime,
                  }}
                />
              </Collapsable>
            </Grid>
            {/* PRODUKTY */}
            <Grid item xs={12} md={6}>
              <Collapsable
                // openAlready={orderDetails[0].productsInCart.length <= 3}
                right
                noPadding
                oneLiner
                title={'Produkty'}
                icon={<FastfoodIcon />}
                description={
                  <div
                    css={{
                      overflowX: 'scroll',
                      fontWeight: 500,
                    }}>
                    <span css={{ color: colors.text }}>
                      {productsCost.toFixed(2)}
                    </span>{' '}
                    zł
                    <Bullet />
                    <>
                      {orderDetails[0].productsInCart.reduce(
                        (sum, curr) => curr.amount + sum,
                        0
                      )}
                    </>
                    <FastfoodIcon
                      fontSize='inherit'
                      css={{
                        marginLeft: '0.25rem',
                        [s.md]: { marginLeft: '0.5rem' },
                      }}
                    />
                  </div>
                }>
                <>
                  {orderDetails[0].productsInCart.map((product, productId) => (
                    <ProductItem
                      id={product.prodId._id}
                      key={productId}
                      amount={product.amount}
                      name={product.prodId.name}
                      price={product.prodId.price}
                      photoUrl={product.prodId.photoUrl}
                    />
                  ))}
                  <div
                    css={{
                      [s.sm_down]: { padding: '1.5rem 1rem' },
                      [s.md]: { padding: '2rem' },
                    }}>
                    <Button
                      dimmed
                      w100
                      link={`/restaurant/${orderWeWorkOn.restId._id}?menu=true`}>
                      Edytuj produkty
                    </Button>
                  </div>
                </>
              </Collapsable>
            </Grid>

            {/* NOTATKA */}
            <CheckoutNote note={note} onSaveNote={onSaveNote} />

            {/* FAKTURA */}
            <Grid item xs={12} md={6}>
              <Collapsable
                right
                noPadding
                oneLiner
                title={'Faktura'}
                icon={<DocumentIcon />}
                description={
                  isInvoice ? (
                    <div css={{ overflow: 'scroll' }}>
                      {invoiceDetails.firmName ? (
                        <>
                          {invoiceDetails.firmName}
                          <Bullet />
                        </>
                      ) : (
                        'Uzupełnij dane firmy '
                      )}
                      {invoiceDetails.nip && (
                        <>
                          {invoiceDetails.nip}
                          <Bullet />
                        </>
                      )}
                      {invoiceDetails.address && (
                        <>
                          {invoiceDetails.address}
                          <Bullet />
                        </>
                      )}
                      {invoiceDetails.zipCode && (
                        <>
                          {invoiceDetails.zipCode}
                          <Bullet />
                        </>
                      )}
                      {invoiceDetails.city && (
                        <>
                          {invoiceDetails.city}
                          <Bullet />
                        </>
                      )}
                    </div>
                  ) : (
                    'Utwórz fakturę do zamówienia'
                  )
                }>
                <InvoiceDetails
                  isInvoice={isInvoice}
                  onIsInvoiceChange={onIsInvoiceChange}
                  invoiceDetails={invoiceDetails}
                  onInvoiceDetailsChange={onInvoiceDetailsChange}
                />
              </Collapsable>
            </Grid>

            {/* PŁATNOŚĆ */}
            <OrderPayment
              isDelivery={isDelivery}
              deliveryCost={isDelivery && deliveryCost}
              productsCost={productsCost.toFixed(2)}
              totalCost={
                isDelivery
                  ? (productsCost + deliveryCost).toFixed(2)
                  : productsCost.toFixed(2)
              }
            />
            <Grid item xs={12}>
              <p
                css={{
                  padding: '1.5rem 1rem 0rem',
                  fontSize: '0.75rem',
                  color: colors.text.concat(alpha[60]),
                  a: {
                    color: colors.text,
                    fontWeight: 800,
                    textDecoaration: 'underline',
                  },
                }}>
                Przesyłając zamówienie, potwierdzasz zapoznanie się z{' '}
                <Link to='/terms'>Regulaminem</Link> oraz{' '}
                <Link to='/privacy'>Politką Prywatności</Link>
              </p>
            </Grid>
          </Grid>
        )}
      </div>

      <Hidden smDown>
        <div css={sRestContainer}>
          {restId && (
            <GetRestaurantImage restId={restId._id} data={restId}>
              <RestContainer />
            </GetRestaurantImage>
          )}
        </div>
      </Hidden>

      <SendOrderBtn
        isLoading={isLoading}
        onSendOrder={onSendOrder}
        // deliveryError={deliveryError}
      />
    </React.Fragment>
  )
}

export const SendOrderBtn = ({
  isLoading,
  onSendOrder,
  // deliveryError
}) => (
  <ButtonBase
    onClick={onSendOrder}
    css={[
      {
        width: '100%',
        maxWidth: 720,
        padding: '0 2rem',
        [s.sm_down]: {
          maxWidth: 'calc(100vw - 3rem)',
          padding: '0 1.5rem',
        },
        position: 'fixed',
        height: 48,
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: 72, // !isLoading ? 72 : -72,
        background: `linear-gradient(to right, ${colors.green400}, ${colors.green800})`,
        color: '#ffffff',
        borderRadius: '1.5rem',
        transition: 'bottom 0.6s ease-in-out, filter 0.3s ease-out',
        boxShadow: `0px 8px 24px ${colors.green.concat(alpha[60])}`,
        zIndex: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontWeight: 800,
        letterSpacing: '0.05em',
        textAlign: 'center',
        fontSize: '0.875rem',
        [s.md]: { fontSize: '1rem' },
        '&:hover': {
          [s.hover]: {
            filter: 'brightness(1.1)',
          },
        },
      },
      // deliveryError && {
      //   background: `linear-gradient(to right, ${colors.red600}, ${colors.green800})`,
      // },
    ]}>
    <div css={{ width: '100%' }}>
      {/* {deliveryError ? `DOSTAWA NIEMOŻLIWA` : `PRZEŚLIJ ZAMÓWIENIE`} */}
      PRZEŚLIJ ZAMÓWIENIE
    </div>
  </ButtonBase>
)

export const GetRestaurantImage = ({ restId, children, data }) => {
  const { data: queryData, loading, error } = useGetRestaurantSkip(restId, true)

  if (loading || error)
    return (
      <div
        css={[sRestContainerExported, { backgroundColor: colors.gray200 }]}
      />
    )

  if (queryData.ezcRestaurant)
    return (
      <>
        {React.cloneElement(children, {
          data: {
            ...data,
            photoUrl: queryData.ezcRestaurant.photoUrl,
            categories: queryData.ezcRestaurant.categories,
          },
        })}
      </>
    )

  return (
    <div css={[sRestContainerExported, { backgroundColor: colors.gray200 }]} />
  )
}

export const sRestContainer = {
  [s.sm_down]: {
    marginTop: globals.topnavHeight.mobile,
    padding: '1.5rem 0',
  },
  [s.md]: { marginTop: globals.topnavHeight.desktop + 64 - 24 },
}
