import React, { createContext, useContext } from 'react'

import useOrderActive from '../hooks/useOrderActive.hook'
import createOrderSummary from '../../../helpers/create-order-summary'
import getStatusName from '../../../../helpers/get-status-name'


function noop() { };

export const ActiveOrdersContext = createContext({
  // Accordion Tab Data
  orderSummary: {},
  isOpen: false,
  onOpen: noop,

  // product
  _id: '',
  products: [],
  finishedProducts: [],
  orderDetails: [],
  messages: [],
  isPaid: false,
  paidSum: 0,
  deliveryCost: 0,
  status: '',

  consumptionType: '',
  deliveryAddress: '',
  note: '',
  isInvoice: false,
  invoiceDetails: {},

  // handlers
  isLoading: false,
  onToggleProduct: noop,
  handleActiveProdStatus: noop,
  handleDecline: noop,
  handleFinishTheOrder: noop,
  handleAnnulFinishedProd: noop,
  handleAnnulOrder: noop
})

export const useActiveOrdersContext = () => useContext(ActiveOrdersContext)


export default ({
  children,
  order: {
    _id,
    orderDetails,

    status,
    shortId,
    ownerId,
    consumptionPersons,
    consumptionType,
    consumptionDate,
    consumptionTime,
    priceSum,
    isPaid,
    paidSum,
    deliveryCost,
    messages,

    deliveryAddress,
    deliveryDetails,
    note,
    waiterNote,
    isInvoice,
    invoiceDetails,
  }
}) => {

  const {
    isLoading,
    isOpen,
    setIsOpen,
    products,
    finishedProducts,

    onToggleProduct,
    handleActiveProdStatus,
    handleDecline,
    handleFinishTheOrder,
    handleAnnulFinishedProd,
    handleAnnulOrder

  } = useOrderActive({ _id, orderDetails })

  const onOpen = () => setIsOpen(!isOpen)


  return (
    <ActiveOrdersContext.Provider value={{
      orderSummary: createOrderSummary({
        status,
        shortId,
        ownerId,
        consumptionPersons,
        consumptionType,
        consumptionDate,
        consumptionTime,
        priceSum,
        isPaid,
      }),
      isOpen,
      onOpen,

      _id,
      products,
      finishedProducts,
      orderDetails,
      messages,
      isPaid,
      paidSum,
      deliveryCost,
      status: getStatusName(status),

      consumptionType,
      deliveryAddress,
      deliveryDetails,
      note,
      waiterNote,
      isInvoice,
      invoiceDetails,

      isLoading,
      onToggleProduct,
      handleActiveProdStatus,
      handleDecline,
      handleFinishTheOrder,
      handleAnnulFinishedProd,
      handleAnnulOrder

    }}>
      {children}
    </ActiveOrdersContext.Provider>
  )
}