/** @jsx jsx */
import React, { useEffect, useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useHistory } from 'react-router'
import { useOrdersContext } from '../context/orders.context'
import {
  sTitle,
  sSubtitle,
  sContainer2,
  sBackgroundHidden,
  sContainerShown,
} from '../../EZC/context/ActiveOrdersListener/components/Popup'
import {
  sBackground,
  sContainer,
} from '../../components/Widget/Widget.component'
import ActiveOrderItem from '../../EZC/pages/orders/components/ActiveOrderItem'
import { Collapse, Grid } from '@material-ui/core'
import { Button } from '../../components/Elements'

const PopupWaiter = () => {
  const history = useHistory()
  const [isShown, setIsShown] = useState(false)
  const { attentionOrders } = useOrdersContext()
  const ordersL = attentionOrders?.length

  const onHide = () => {
    setIsShown(false)
  }

  useEffect(() => {
    ordersL >= 1 && setIsShown(true)
  }, [ordersL])

  return (
    <React.Fragment>
      <div
        css={[sBackground, !isShown && sBackgroundHidden]}
        onClick={onHide}></div>
      <div css={[sContainer, sContainer2, isShown && sContainerShown]}>
        <h1 css={sTitle}>Nowe Zamówienia</h1>
        <p css={sSubtitle}>
          {ordersL} zamówie{ordersL > 1 ? (ordersL > 5 ? 'ń' : 'nia') : 'nie'}{' '}
          został{ordersL > 1 ? (ordersL > 5 ? 'o' : 'y') : 'o'} zaktualizowa
          {ordersL > 1 ? (ordersL > 5 ? 'nych' : 'ne') : 'ne'} i wymaga
          {ordersL > 1 ? (ordersL > 5 ? 'ją' : 'ją') : ''} Twojej uwagi
        </p>
        <Collapse in={Boolean(ordersL)}>
          <div css={{ padding: '1rem 0 0' }}>
            {attentionOrders.map((order, id) => (
              <Grid
                container
                key={order._id}
                onClick={() => {
                  history.push(`/waiter/order/${order._id}`)
                  setIsShown(false)
                }}>
                <ActiveOrderItem
                  orderId={order._id}
                  date={order.consumptionDate}
                  time={order.consumptionTime}
                  consumptionType={order.consumptionType}
                  restaurantAvatar={order.restId.avatarUrl}
                  restaurantName={order.restId.name}
                  priceSum={order.priceSum}
                  isPaid={order.isPaid}
                  status={[...order.status].pop().name}
                  w100
                />
              </Grid>
            ))}
          </div>
        </Collapse>
        <div css={{ padding: '1rem 0 2rem' }}>
          <Button
            w100
            w100Desktop
            action={() => {
              history.push(`/waiter/notifications`)
              setIsShown(false)
            }}>
            ZOBACZ WSZYSTKIE&nbsp;<span css={{ opacity: 0.5 }}>(</span>
            {ordersL}
            <span css={{ opacity: 0.5 }}>)</span>
          </Button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PopupWaiter
