import { gql } from '@apollo/client'


export const GET_TRANSACTIONS_AND_BALANCE = gql`
  query ezrTransactions($restId: ID!) {
    ezrRestaurant(restId: $restId) {
      coins
    }

    ezrTransactions(restId: $restId) {
      _id
      type
      fromUser {
        _id
        personal {
          name
          avatarUrl
        }
      }
      fromRest {
        _id
        name
        avatarUrl
      }
      fromFirm
      fromOther
      toUser {
        _id
        personal {
          name
          avatarUrl
        }
      }
      toRest {
        _id
        name
        avatarUrl
      }
      toFirm
      coinsAmount
      createdAt
    }
  }
`