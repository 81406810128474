
const useChosenFilterItems = ({ prodFilter, categories, ingredientsList, flavors, cookMethods }) => {
  if (!prodFilter) return { data: null }

  const objectEntriesFilter = Object.entries(prodFilter)

  const data = {
    ingr: {
      must: [],
      mustNot: []
    },
    categories: {
      must: [],
      mustNot: []
    },
    flavors: {
      must: [],
      mustNot: []
    },
    cookMethods: {
      must: [],
      mustNot: []
    },
  }

  const a = el => el
  const b = el => el._id
  const dataToHandle = [
    {
      name: 'ingredients',
      list: ingredientsList,
      callbackId: b,
      dataObj: data.ingr
    },
    {
      name: 'categories',
      list: Object.entries(categories),
      callbackId: b,
      dataObj: data.categories
    },
    {
      name: 'flavors',
      list: Object.keys(flavors),
      callbackId: a,
      dataObj: data.flavors
    },
    {
      name: 'cookMethod',
      list: Object.keys(cookMethods),
      callbackId: a,
      dataObj: data.cookMethods
    },
  ]

  dataToHandle.forEach(obj => {
    const filter = objectEntriesFilter.find(el => el[0] === obj.name)
    const mustFilter = filter ? filter[1].must : undefined
    const mustNotFilter = filter ? filter[1].mustNot : undefined

    if (obj.name === 'categories') {

      if (mustFilter) {
        mustFilter.forEach(id => {

          obj.list.forEach(q => {
            const i = q[1].data.categoriesByType.find(el => obj.callbackId(el) === id)
            if (i) obj.dataObj.must.push(i)
          })
        })
      }

      if (mustNotFilter) {
        mustNotFilter.forEach(id => {

          obj.list.forEach(q => {
            const i = q[1].data.categoriesByType.find(el => obj.callbackId(el) === id)
            if (i) obj.dataObj.mustNot.push(i)
          })
        })
      }

    } else {

      if (mustFilter) {
        mustFilter.forEach(id => {
          const i = obj.list.find(el => obj.callbackId(el) === id)
          if (i) obj.dataObj.must.push(i)
        })
      }

      if (mustNotFilter) {
        mustNotFilter.forEach(id => {
          const i = obj.list.find(el => obj.callbackId(el) === id)
          if (i) obj.dataObj.mustNot.push(i)
        })
      }
    }
  })

  return {
    data
  }
}

export default useChosenFilterItems