/** @jsx jsx */
import React, { useState, useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { daysOfWeek } from '../../../constants/daysOfWeek'


const openningHoursValues = {
  "0": '0:00',
  // ... openningHoursValuesFc()
  "1440": '24:00'
}
const openningHoursValuesFc = () => {
  let i = '0:00'
  for (let index = 0; index < 1441; index += 30) {
    openningHoursValues[index] = i
    let b = i.split(':')
    if (parseInt(b[1], 10) === 30) {
      b[0]++
      b[1] = '00'
    } else {
      b[1] = '30'
    }
    i = b.join(':')
  }
}
openningHoursValuesFc()
const daysOpenInit = {}
Object.keys(daysOfWeek).forEach(day => daysOpenInit[day] = { open: false, close: false })



const OpenningHours = ({ handleDayOnChange, openningHours, header, paragraphs }) => {

  const [daysOpen, setDaysOpen] = useState(daysOpenInit)

  const handleDayOpen = (day, type) => {
    setDaysOpen({ ...daysOpen, [day]: { ...daysOpen[day], [type]: true } })
  }

  const handleDayClose = (day, type) => {
    setDaysOpen({ ...daysOpen, [day]: { ...daysOpen[day], [type]: false } })
  }


  return (
    <div css={{ margin: '24px 0' }}>
      <h3>{header}</h3>

      {paragraphs?.length > 0 && (
        <div css={{ fontSize: 14, opacity: '.6', marginBottom: 8 }}>
          {paragraphs.map((text, index) => <p key={index}>{text}</p>)}
        </div>)}

      {Object.keys(daysOfWeek).map(day => (
        <div key={day} css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p>{daysOfWeek[day]}</p>
          <div>
            <Select
              labelId={`open ${day}`}
              open={daysOpen[day].open}
              onClose={() => handleDayClose(day, 'open')}
              onOpen={() => handleDayOpen(day, 'open')}
              value={openningHours[day].open}
              onChange={e => handleDayOnChange(e, 'open')}
              name={day}
              css={{ marginRight: 16, minWidth: 80 }}
            >
              {Object.keys(openningHoursValues).map(value => (
                <MenuItem key={value} value={value}>{openningHoursValues[value]}</MenuItem>
              ))}
            </Select>
            <Select
              labelId={`close ${day}`}
              open={daysOpen[day].close}
              onClose={() => handleDayClose(day, 'close')}
              onOpen={() => handleDayOpen(day, 'close')}
              value={openningHours[day].close}
              onChange={e => handleDayOnChange(e, 'close')}
              name={day}
              css={{ minWidth: 80 }}
            >
              {Object.keys(openningHoursValues).map(value => (
                <MenuItem key={value} value={value}>{openningHoursValues[value]}</MenuItem>
              ))}
            </Select>
          </div>
        </div>
      ))}
    </div>
  )
}

export default OpenningHours