/** @jsx jsx */
import React, { useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { ButtonBorder } from '../../../components/Elements'
import Headline from '../../../components/Headline/Headline'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import {
  sGrid,
  sNoMarginTop,
  sOuter,
  sOuterPadding,
} from '../../../style/style'
import { useAuthContext } from '../../../context/auth.context'
import { useActiveRestaurantContext } from '../../context/active-restaurant.provider'
import { useGetRestaurants } from '../../hooks/useQueries'
import { useQuery } from '@apollo/client'
import { GET_RESTAURANT } from '../../graphql/restaurants.graphql'
import {
  sRestSkeleton,
  sRestSkeletonInner,
} from '../../../EZW/pages/Home/HomePage'
import RestContainer from '../../../EZC/views/ProductPage/components/RestContainer'
import { colors, s } from '../../../style'
import HomeIcon from '@material-ui/icons/Home'
import RestaurantIcon from '@material-ui/icons/Restaurant'
import FastfoodIcon from '@material-ui/icons/Fastfood'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import AddLocationIcon from '@material-ui/icons/AddLocation'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { MenuItem } from '../../../EZC/pages/account/Account.page'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import { Grid } from '@material-ui/core'
import RoomServiceIcon from '@material-ui/icons/RoomService'
import PersonIcon from '@material-ui/icons/Person'
import { RestItemLarge } from '../../../EZC/components/RestItem'
import VisibilityIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit'

const itemsData = [
  {
    to: '/rest/restaurant',
    name: 'Restauracja',
    description: 'Zarządzaj aktywnym lokalem',
    AvatarIcon: RestaurantIcon,
  },
  {
    to: '/rest/restaurant/new',
    name: 'Nowa Restauracja',
    description: 'Dodaj kolejny lokal',
    AvatarIcon: AddLocationIcon,
  },
  {
    to: '/rest/products',
    name: 'Produkty',
    description: 'Modyfikuj istniejące produkty',
    AvatarIcon: FastfoodIcon,
  },
  {
    to: '/rest/product/new',
    name: 'Nowy Produkty',
    description: 'Dodaj kolejny produkt',
    AvatarIcon: AddBoxIcon,
  },
  {
    to: '/rest/menu',
    name: 'Menu',
    description: 'Zarządzaj kartą menu',
    AvatarIcon: MenuBookIcon,
  },
  {
    to: '/rest/payments',
    name: 'Transakcje',
    description: 'Sprawdź ostatnie płatności',
    AvatarIcon: AccountBalanceWalletIcon,
  },
  {
    to: '/rest/access',
    name: 'Dostęp',
    description: 'Zarzadzaj kontem kelnera',
    AvatarIcon: SupervisorAccountIcon,
  },
  {
    to: '/waiter',
    name: 'Panel Kelnera',
    AvatarIcon: RoomServiceIcon,
  },
  {
    to: '/',
    name: 'Panel Klient',
    AvatarIcon: PersonIcon,
  },
]

const HomePage = () => {
  const { logout } = useAuthContext()
  const {
    data: dataRestaurants,
    loading: loadingRestaurants,
    error: errorRestaurants,
  } = useGetRestaurants()
  const { activeRestaurantId, handleActiveRestaurantId } =
    useActiveRestaurantContext()
  const {
    data: dataRestaurant,
    loading: loadingRestaurant,
    error: errorRestaurant,
  } = useQuery(GET_RESTAURANT, {
    variables: { restId: activeRestaurantId },
    skip: !activeRestaurantId,
  })

  useEffect(() => {
    if (dataRestaurants?.ezrRestaurants?.length && !activeRestaurantId) {
      handleActiveRestaurantId(dataRestaurants.ezrRestaurants[0]._id)
    }
  }, [dataRestaurants])

  const scrollTop = () => {
    if (typeof document !== 'undefined') {
      document
        .getElementById('scroller')
        .childNodes[0].scrollIntoView({ behavior: 'smooth' })
    }
  }

  const restaurants = dataRestaurants?.ezrRestaurants || []

  const restaurantsSorted = restaurants
    .filter((rest) => rest.isActive)
    .concat(restaurants.filter((rest) => !rest.isActive))

  return (
    <React.Fragment>
      <TopNav title={'Restaurator'} offset={60} />
      <div css={sOuterPadding}>
        <Headline
          itemChildren={<ButtonBorder action={logout}>Wyloguj</ButtonBorder>}>
          Restaurator
        </Headline>
      </div>
      {dataRestaurant ? (
        <RestContainerWithSaldo dataRestaurant={dataRestaurant} />
      ) : (
        <div css={sRestSkeleton}>
          <div css={sRestSkeletonInner} />
        </div>
      )}
      <div css={[sOuter, sOuter2]}>
        <Grid container css={sGrid}>
          {itemsData.map((item, id) => (
            <MenuItem key={id} id={id} {...item} />
          ))}
          <MenuItem
            name='Wyloguj'
            AvatarIcon={MeetingRoomIcon}
            onClick={() => logout()}
          />
        </Grid>
      </div>
      <div css={[sOuter, sOuter2]}>
        <Headline
          itemChildren={
            <ButtonBorder link={'/rest/restaurant/new'}>
              Nowa Restauracja
            </ButtonBorder>
          }>
          Restauracje
        </Headline>
        <Grid container css={sGrid}>
          {restaurantsSorted.map((restaurant, id) => (
            <Grid
              onClick={() => {
                handleActiveRestaurantId(restaurant._id)
                scrollTop()
              }}
              key={id}
              item
              xs={12}
              md={6}
              css={[
                { paddingBottom: '1.5rem', cursor: 'pointer' },
                !restaurant.isActive && { opacity: 0.3 },
              ]}>
              <RestItemLarge
                id={id + 2}
                data={restaurant}
                restView
                restViewActive={
                  dataRestaurant?.ezrRestaurant?._id === restaurant._id
                }
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </React.Fragment>
  )
}

export const RestContainerWithSaldo = ({ dataRestaurant, saldoChildren }) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}>
      <RestContainer
        data={dataRestaurant.ezrRestaurant}
        extraCss={{ [s.md]: { marginBottom: 0 } }}>
        <div
          css={[
            sBalance,
            {
              position: 'absolute',
              top: '3rem',
              right: '2rem',
              width: 'max-content',
              zIndex: 300,
              fontSize: '1.5rem',
              padding: '1.5rem 2rem',
              [s.sm_down]: { display: 'none' },
              flexDirection: 'column',
              alignItems: 'flex-start',
            },
          ]}>
          <span>Saldo</span>
          {dataRestaurant.ezrRestaurant.coins.toFixed(2)} ZŁ
        </div>
        <div
          css={[
            {
              position: 'absolute',
              bottom: '3rem',
              right: '3.5rem',
              zIndex: 300,
              display: 'flex',
              [s.sm_down]: { display: 'none' },
            },
          ]}>
          <ButtonBorder
            extraCss={sButtonBorder}
            link={`/restaurant/${dataRestaurant.ezrRestaurant._id}`}>
            <VisibilityIcon fontSize='inherit' />
            &nbsp;&nbsp;Wyświetl
          </ButtonBorder>
          <div css={{ paddingLeft: '1.5rem' }} />
          <ButtonBorder extraCss={sButtonBorder} link={`/rest/restaurant/`}>
            <EditIcon fontSize='inherit' />
            &nbsp;&nbsp;Edytuj
          </ButtonBorder>
        </div>
      </RestContainer>
      <div
        css={[
          sBalance,
          {
            [s.md]: {
              display: 'none',
            },
          },
        ]}>
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          <span>Saldo</span>
          {dataRestaurant.ezrRestaurant.coins.toFixed(2)} ZŁ
        </div>
        {saldoChildren}
      </div>
    </div>
  )
}

const sButtonBorder = {
  backgroundColor: colors.gray200,
  color: colors.text,
  boxShadow: '0px 0px 0px black',
}

const sBalance = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: 'calc(100vw - 3rem)',
  backgroundColor: colors.gray200,
  padding: '1.5rem',
  fontSize: '1.25rem',
  fontWeight: 800,
  margin: '0 1.5rem',
  borderRadius: '1rem',
  letterSpacing: '0.1em',
  span: {
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    color: colors.gray600,
    letterSpacing: '0.2em',
    marginBottom: '0.25rem',
  },
}

const sOuter2 = { marginTop: '2rem', [s.sm]: { marginTop: '2rem' } }

export default HomePage
