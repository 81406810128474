/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import Button from '@material-ui/core/Button'

import Dialog from '../../../components/Dialog'
import useOrderMutations from '../../../hooks/useOrders.mutations'
import { useActiveOrdersContext } from '../context/orders-active.context'


export default () => {
  const { handleDangerAnnulOrder } = useOrderMutations()
  const { _id } = useActiveOrdersContext()

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const onDialogOpen = () => setIsDialogOpen(true)
  const onDialogClose = () => setIsDialogOpen(false)

  const onAnnul = () => {
    const data = {
      orderId: _id,
      reason: ' '
    }
    handleDangerAnnulOrder(data, onDialogClose)
  }


  return (
    <React.Fragment>
      <Button
        onClick={onDialogOpen}
        variant="outlined"
      >
        Anuluj zamówienie
      </Button>

      <Dialog
        isOpen={isDialogOpen}
        onClose={onDialogClose}
        textMainBtn="Zamknij"
        onMainBtn={onDialogClose}
        textSecondaryBtn="Anuluj zamówienie"
        onSecondaryBtn={onAnnul}

        textHeader="Anulować zamówienie?"
        textContent="Cała kwota za zamówienie zostanie zwrócona Klientowi, a zamówienie trafi do Anulowanych."
      />
    </React.Fragment>
  )
}