import {
  sInput as sInputAuth,
  sInputDarker as sInputDarkerAuth,
  sBase,
} from '../../EZC/views/Auth/components/Input.component'

import { colors, s } from '../../style'

export const sInput = {
  ...sBase,
  ...sInputAuth,
  // ...sInputDarker,
  borderRadius: 16,
  fontSize: '1rem',
  [s.md]: { fontSize: '1rem', padding: '1.75rem 1.5rem 0.75rem' },
  letterSpacing: '0em',
  padding: '1.75rem 1.5rem 0.75rem',
  'label + .MuiInput-formControl': {
    marginTop: 0,
  },
  '.MuiInputLabel-formControl': {
    top: 10,
    left: 24,
    transform: 'translate(0, 14px) scale(1)',
    fontWeight: 800,
    color: colors.gray500,
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    letterSpacing: '0.1em',
  },
  '.MuiInputLabel-shrink': {
    transform: 'translate(0, 1.5px) scale(0.75)',
  },
}

export const sInputDarker = { ...sInputDarkerAuth }
