const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_PUBLIC_VAPID_KEY)

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - base64String.length % 4) % 4)
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

function sendSubscription(subscription, url) {
  const userData = JSON.parse(localStorage.getItem('userData'))
  const token = userData?.token

  const waiterData = JSON.parse(localStorage.getItem('waiterData'))
  const waiterToken = waiterData?.waiterToken

  return fetch(
    url,
    {
      method: 'POST',
      body: JSON.stringify(subscription),
      headers: {
        'Content-Type': 'application/json',
        authorization: token ? `Bearer ${token}` : "",
        waiterauth: waiterToken ? `Bearer ${waiterToken}` : ""
      }
    }
  )
}

export function subscribeUser() {
  const subscribeUrl = `${process.env['REACT_APP_SERVER_URI_' + process.env.REACT_APP_NODE_ENV]}/notifications/subscribe`

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(function (registration) {
      if (!registration.pushManager) {
        console.log('Push manager unavailable.')
        return
      }

      registration.pushManager.getSubscription()
        .then(function (existedSubscription) {
          if (existedSubscription === null) {
            console.log('No subscription detected, make a request.')
            registration.pushManager.subscribe({
              applicationServerKey: convertedVapidKey,
              userVisibleOnly: true,
            })
              .then(function (newSubscription) {
                console.log('New subscription added.')
                sendSubscription(newSubscription, subscribeUrl)
              })
              .catch(function (e) {
                if (Notification.permission !== 'granted') {
                  console.log('Permission was not granted.')
                } else {
                  console.error('An error ocurred during the subscription process.', e)
                }
              })
          } else {
            console.log('Existed subscription detected.')
            sendSubscription(existedSubscription, subscribeUrl)
          }
        })
    })
      .catch(function (e) {
        console.error('An error ocurred during Service Worker registration.', e)
      })
  }
}



export function subscribeWaiter() {
  const subscribeUrl = `${process.env['REACT_APP_SERVER_URI_' + process.env.REACT_APP_NODE_ENV]}/notifications/subscribe-waiter`

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(function (registration) {
      if (!registration.pushManager) {
        return
      }

      registration.pushManager.getSubscription()
        .then(function (existedSubscription) {
          if (existedSubscription === null) {
            registration.pushManager.subscribe({
              applicationServerKey: convertedVapidKey,
              userVisibleOnly: true,
            })
              .then(function (newSubscription) {
                sendSubscription(newSubscription, subscribeUrl)
              })
              .catch((e) => { })
          } else {
            sendSubscription(existedSubscription, subscribeUrl)
          }
        })
    })
      .catch((e) => { })
  }
}