/** @jsx jsx */
import React, { useContext } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { NavLink } from 'react-router-dom'

import HomeIcon from '@material-ui/icons/Home'
import SearchIcon from '@material-ui/icons/Search'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import LocalDiningIcon from '@material-ui/icons/LocalDining'
import PersonIcon from '@material-ui/icons/Person'
import ButtonBase from '@material-ui/core/ButtonBase'
import { Badge } from '../../../components/Elements'
import { colors, s, alpha } from '../../../style'

import { useGetOrdersInCart } from '../../hooks/useOrders.queries'
// import { useGetActiveOrdersShort } from '../../hooks/useOrders.queries'
import { useNoAuthOrdersContext } from '../../context/NoAuthOrders/NoAuthOrders.provider'
import { AuthContext } from '../../../context/auth.context'
import { ActiveOrdersListenerContext } from '../../context/ActiveOrdersListener/ActiveOrdersListener.context'

export default function LabelBottomNavigation() {
  // const { pathname } = useLocation()
  // const url = '/' + pathname.split('/')[1]

  const { data: cartData } = useGetOrdersInCart()
  // const { data: orderData } = useGetActiveOrdersShort()
  const { ordersNoAuth } = useNoAuthOrdersContext()
  const { isAuth } = useContext(AuthContext)
  const { attentionOrderIds } = useContext(ActiveOrdersListenerContext)

  const totalItemsAuth =
    cartData &&
    cartData?.ezcOrdersInCart
      .map((order) =>
        order.orderDetails[0].productsInCart.map((product) => product.amount)
      )
      .flat()
      .reduce((sum, curr) => sum + curr, 0)

  const totalItemsNoAuth = ordersNoAuth
    .map((order) =>
      order.orderDetails[0].productsInCart.map((product) => product.amount)
    )
    .flat()
    .reduce((sum, curr) => sum + curr, 0)

  const totalItems = isAuth ? totalItemsAuth : totalItemsNoAuth

  return (
    <React.Fragment>
      <ButtonBase css={sNav}>
        <div css={sContainer}>
          <NavLink exact to='/' activeStyle={sActiveNavLink}>
            <HomeIcon fontSize='inherit' color='inherit' />
          </NavLink>

          <NavLink to='/search' activeStyle={sActiveNavLink}>
            <SearchIcon fontSize='inherit' color='inherit' />
          </NavLink>
          <NavLink to='/cart' activeStyle={sActiveNavLink}>
            <ShoppingCartIcon fontSize='inherit' color='inherit' />
            <Badge color={colors.green} show={totalItems > 0} showAnimation>
              {totalItems > 0 && totalItems}
            </Badge>
          </NavLink>
          <NavLink to='/orders' activeStyle={sActiveNavLink}>
            <LocalDiningIcon fontSize='inherit' color='inherit' />
            {isAuth && (
              <Badge
                color={colors.orange}
                show={attentionOrderIds?.length > 0}
                showAnimation>
                {attentionOrderIds?.length > 0 && attentionOrderIds.length}
              </Badge>
            )}
          </NavLink>
          <NavLink to='/account' activeStyle={sActiveNavLink}>
            <PersonIcon fontSize='inherit' color='inherit' />
          </NavLink>
        </div>
      </ButtonBase>
    </React.Fragment>
  )
}

const sNav = {
  boxSizing: 'border-box',
  position: 'fixed',
  bottom: 0,
  left: 0,
  zIndex: 1100,
  width: '100vw',
  height: 72,
  backgroundColor: '#ffffff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '2rem 2rem 0 0',
  boxShadow: `0px -4px 16px ${colors.text.concat(alpha[8])}`,
  color: colors.green,
  cursor: 'default',

  [s.sm]: {
    boxShadow: `0px -4px 64px ${colors.text.concat(alpha[8])}`,
  },

  '.active:hover': {
    [s.hover]: {
      filter: 'saturate(150%) contrast(120%)',
    },
  },

  '.MuiTouchRipple-rippleVisible': {
    animationDuration: '1.2s',
  },
}

const sLink = {
  transition:
    'color 0.2s ease-out, background-color 0.2s ease-out, filter 0.2s ease-out',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 50,
  height: 50,
  color: colors.text,
  fontSize: 32,
  borderRadius: '50%',
  position: 'relative',

  '&:hover': {
    backgroundColor: colors.gray200,
    color: colors.text.concat(alpha[60]),
  },
}

const sContainer = {
  width: '100%',
  height: '100%',
  maxWidth: 960,
  display: 'flex',

  alignItems: 'center',
  // backgroundColor: '#fff',
  padding: '0 1.5rem',

  [s.xs]: { justifyContent: 'space-between' },
  [s.sm]: { justifyContent: 'space-evenly' },

  a: { ...sLink },
}

const sActiveNavLink = {
  backgroundColor: colors.green200,
  color: colors.green,

  '&:hover': {
    backgroundColor: 'red',
    filter: 'brightness(120%) contrast(1.5)',
  },
}
