/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'
import { krakenUrls } from '../../../constants/s3-files'
import { colors, alpha, s } from '../../../style'
import { Bullets } from '../../../components/Elements'

const ProdItemMini = ({ data, categoryId, to, id, last }) => {
  const { name, photoUrl, categories, price, restAvatarUrl } = data
  const catePopular = categories.filter(({ type }) => type === 'POPULAR')
  const cateMealtype = categories.filter(({ type }) => type === 'MEALTYPE')
  const cateCustine = categories.filter(({ type }) => type === 'CUISINE')
  const cateDiet = categories.filter(
    ({ name, type }) => type === 'DIET' && name !== 'mięsne'
  )
  const cateOther = categories.filter(({ type }) => type === 'OTHER')
  const cateNoType = categories.filter((category) => !category.type)
  // const cateDayTime = categories.filter(({ type }) => type === 'DAYTIME')

  const categoriesShow = []
    .concat(
      catePopular,
      cateMealtype,
      cateCustine,
      cateDiet,
      cateOther,
      cateNoType
    )
    .map((e) => (e.name ? e.name : e))

  // console.log(last)

  return (
    // <div
    //   css={{
    //     width: 170,
    //     height: 196,
    //     backgroundColor: colors.gray200,
    //     borderRadius: 12,
    //     flexShrink: 0,
    //     marginRight: 12,
    //   }}>

    <React.Fragment>
      <div css={[{ scrollSnapAlign: 'start' }]}>
        <Link
          to={`/product/${data._id}`}
          css={[
            sContainer,
            id === 0 && { [s.sm_down]: { marginLeft: '1.5rem' } },
            {
              animation: `fadeIn 0.3s ease-in-out ${
                id * 0.15 * 0 - id * 0.05 * 0
              }s alternate both`,
              // '@keyframes fadeIn': {
              //   from: { opacity: 0 },
              //   to: { opacity: 1 },
              // },
            },
          ]}>
          {/* <div
        css={{
          position: 'relative',
          borderRadius: 'inherit',
          height: '100%',
          animation: `fadein 0.3s ease-in ${
            id * 0.15 - id * 0.05
          }s alternate both`,
          // '@keyframes fadein': {
          //   from: { opacity: 0 },
          //   to: { opacity: 1 },
          // },
        }}> */}
          <div
            css={[
              sImageContainer,

              !photoUrl && { backgroundColor: colors.gray300 },
              !photoUrl &&
                restAvatarUrl && {
                  height: 128,
                  img: { objectPosition: 'center center' },
                },
            ]}>
            {photoUrl && (
              <img
                src={photoUrl && krakenUrls.small + photoUrl}
                alt={name}
                loading='lazy'
              />
            )}
            {!photoUrl && restAvatarUrl && (
              <img
                src={krakenUrls.medium + restAvatarUrl}
                alt={name}
                loading='lazy'
              />
            )}
          </div>
          <div css={sTextContainer}>
            <div css={{ padding: '0.5rem' }}>
              <h3 css={sTextContainer.title}>{name}</h3>
              <p css={sTextContainer.categories}>
                <Bullets array={categoriesShow} />
              </p>
            </div>
            <div css={sDetails}>
              <span css={sDetails.price}>
                {price.toFixed(2)}
                <span> zł</span>
              </span>
            </div>
          </div>
          {/* </div> */}
        </Link>
      </div>
      {last === 1 && <div css={sLast} />}
    </React.Fragment>
  )
}

const sContainer = {
  width: 170,
  height: 196,
  backgroundColor: colors.gray200,
  borderRadius: 12,
  flexShrink: 0,
  marginLeft: 12,
  transition: 'background-color 0.3s ease-out, box-shadow 0.3s ease-out',
  '&:hover': {
    backgroundColor: colors.gray300,
    // boxShadow: '0px 0px 8px rgba(0,0,0,0.08)',
    img: {
      transform: 'scale(1.1)',
    },
  },

  // width: '100%',
  // height: '100%',
  // borderRadius: 'inherit',

  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}

const sImageContainer = {
  width: '100%',
  height: 144, // height: 128?
  position: 'relative',
  borderRadius: 'inherit',
  overflow: 'hidden',

  img: {
    transition: 'transform 0.3s ease-out',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center bottom',
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
  },
}

const sTextContainer = {
  borderBottomLeftRadius: 'inherit',
  borderBottomRightRadius: 'inherit',
  position: 'absolute',

  left: 0,
  bottom: 0,
  zIndex: 2,
  // background: `linear-gradient(to bottom, ${colors.white.concat(
  //   alpha[60]
  // )}, ${colors.gray200.concat(alpha[60])})`,

  background: `linear-gradient(to right, ${colors.gray100.concat(
    alpha[90]
  )}, ${colors.white.concat(alpha[0])})`,

  backdropFilter: 'blur(12px)',
  width: '100%',
  height: 77,
  color: colors.text,

  title: {
    overflow: 'hidden',
    // display: '-webkit-box',
    // WebkitLineClamp: 1,
    // WebkitBoxOrient: 'vertical',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 170 - 16,
    fontWeight: 800,
    fontSize: '0.875rem',
    letterSpacing: '-0.025em',
    // textShadow: `1px 1px 1px rgba(0,0,0,0.3)`,
  },

  categories: {
    marginTop: 2,
    opacity: 0.6,
    fontWeight: 500,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 170 - 16,
    fontSize: '0.75rem',
    textTransform: 'capitalize',
  },
}

const sDetails = {
  display: 'flex',
  alignItems: 'center',
  padding: '0.375rem 0.5rem 0.25rem',
  width: '100%',
  borderTop: `1px solid ${colors.text.concat(alpha[8])}`,
  price: {
    fontWeight: 800,
    fontSize: 12,

    span: {
      opacity: 0.3,
    },
  },
}

const sLast = {
  display: 'flex',
  flexShrink: 0,
  width: 194 - 12,
  height: 196,
  [s.xs]: {
    width: 209, // was 209
  },
}

export default ProdItemMini
