/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Bar } from 'react-chartjs-2'

import { useGetStatisticOrdersAmount } from '../../hooks/useQueries'
import { useActiveRestaurantContext } from '../../context/active-restaurant.provider'

import { Grid } from '@material-ui/core'

export function dataParse(data) {
  const labels = data.map((el) => el.label.slice(8))
  const values = data.map((el) => el.value)

  return {
    labels,
    values,
  }
}

export default () => {
  const { activeRestaurantId } = useActiveRestaurantContext()
  const { data, loading, error } =
    useGetStatisticOrdersAmount(activeRestaurantId)

  if (loading) return null
  if (error) return null

  const { labels, values } = dataParse(data.ezrOrdersAmountStatistics)

  return (
    <Grid item xs={12} lg={6}>
      <Bar data={dataBar(labels, values)} height={300} />
    </Grid>
  )
}

const dataBar = (labels, data) => ({
  labels,
  datasets: [
    {
      label: 'Zamówienia',
      data,

      backgroundColor: 'rgba(255,99,132,0.2)',
      borderColor: 'rgba(255,99,132,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
      hoverBorderColor: 'rgba(255,99,132,1)',
    },
  ],
})
