/** @jsx jsx */
import React, { useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { ButtonBorder, SectionHeadline } from '../../../components/Elements'
import Headline from '../../../components/Headline/Headline'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import { sOuter, sGrid, sDescription, sPaddingTop } from '../../../style/style'
import { Grid, Collapse } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'

import { useError } from '../../../hooks/errors.hook'
import { useGetRestaurant } from '../../hooks/useQueries'
import RestaurantProvider, {
  useRestaurantContext,
} from './context/restaurant.provider'

import Details from './components/Details'
import ConsumptionTypes from './components/ConsumptionTypes'
import OpenHours from './components/OpenHours'
import DeliverySettings from './components/DeliverySettings'
import DeliveryHours from './components/DeliveryHours'
import DeliveryCosts from './components/DeliveryCosts'
import SkeletonOrder from '../../../components/SkeletonOrder/SkeletonOrder'
import ButtonSave from './components/ButtonSave'

const RestaurantPage = () => {
  const { errorHandling } = useError()
  const { data, loading, error } = useGetRestaurant()
  const {
    setBackgroundUrl,
    setLogoUrl,
    setOpenningHours,
    setRestaurantInfo,
    handleCategories,
    setDeliverySettings,
    restaurantInfo,
  } = useRestaurantContext()

  useEffect(() => {
    if (data?.ezrRestaurant) {
      const restaurant = data.ezrRestaurant
      setLogoUrl(restaurant.avatarUrl)
      setBackgroundUrl(restaurant.photoUrl)
      setOpenningHours(restaurant.openningHours)

      const {
        name,
        description,
        address,
        location: { coordinates },
        isActive,
        consumptionTypes,
        deliverySettings,
      } = restaurant

      setDeliverySettings({ ...deliverySettings })

      setRestaurantInfo({
        name,
        description,
        address,
        location: { coordinates },
        isActive,
        consumptionTypes,
      })

      handleCategories(restaurant.categories.map((el) => el._id))
    }
  }, [data])

  if (loading)
    return (
      <React.Fragment>
        <div css={sOuter}>
          <Headline>Restauracja</Headline>
          <Grid container css={sGrid}>
            <p css={sDescription}>
              Modyfikuj szczegóły restauracji, aktywuj kolejne typy zamówień,
              zarządzaj godzinami otwarcia oraz ustawieniami dostaw.
            </p>
          </Grid>
          <Grid container css={[sGrid, sPaddingTop]}>
            <SkeletonOrder />
            <SkeletonOrder />
          </Grid>
        </div>
      </React.Fragment>
    )

  return (
    <React.Fragment>
      <TopNav title={'Restauracja'} offset={60} />
      <div css={sOuter}>
        <Headline
          textWrap
          itemChildren={
            <ButtonBorder link={`/restaurant/${data.ezrRestaurant._id}`}>
              <VisibilityIcon fontSize='inherit' />
              &nbsp;&nbsp;Wyświetl
            </ButtonBorder>
          }>
          {restaurantInfo.name}
        </Headline>
        <Details />
        <ConsumptionTypes />
        <OpenHours />
        <Collapse in={restaurantInfo.consumptionTypes.includes('delivery')}>
          <DeliverySettings />
          <DeliveryCosts />
          <DeliveryHours />
        </Collapse>
      </div>
      <ButtonSave />
    </React.Fragment>
  )
}

export default () => (
  <RestaurantProvider>
    <RestaurantPage />
  </RestaurantProvider>
)
