/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useActiveOrdersContext } from '../context/orders-active.context'
import { OrderListSummary } from '../../../components'



export default ({ info }) => {
  const { orderSummary } = useActiveOrdersContext()

  return <OrderListSummary info={info} {...orderSummary} />
}