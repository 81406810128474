// prettier-ignore
export const backendErrors = {
  // auth
  NOT_ADMIN: 'Brak Autoryzacji - Administrator',
  NOT_AUTH: 'Brak Autentykacji',
  WRONG_TOKEN: 'Token niepoprawny. Proszę, zaloguj się ponownie',
  WRONG_PASSWORD: 'Hasło niepoprawne',
  NOT_RESTAURATEUR: 'Brak Autoryzacji - Restaurator',
  NOT_OWNER: 'Brak Autoryzacji - Właściciel',
  NOT_ORDERS_CLIENT_REST: 'Powiązanie użytkownika oraz restauracji w zamówieniu zakończone niepowodzeniem',

  // inputValidation
  INVALID_INPUT: 'Wprowadzone dane są nieprawidłowe',
  INVALID_EMAIL: 'Email niepoprawny',
  SHORT_PASSWORD: 'Hasło powinno zawierać minimum 8 znaków',

  INVALID_REST_NAME: 'Nazwa restauracji wymagana',
  INVALID_REST_ADDRESS: 'Adres restauracji wymagany',
  INVALID_COORDS: 'Niepoprawne współrzędne geograficzne',

  // rest
  CANT_INACTIVE_REST_BECAUSE_OF_ACTIVE_ORDERS: 'Wyłączenie restauracji jest możliwe po zakończeniu wszystkich aktywnych zamówień',
  CANT_DELETE_EVER_ORDERED_PROD: 'Produkt, który występuje w istniejącym zamówieniu, nie może zostać usunięty',

  // other
  USER_NOT_FOUND: 'Użytkownik nie został odnaleziony',
  USER_EXISTS: 'Użytkownik o podanym adresie email już istnieje',
  REST_NOT_FOUND: 'Restauracja nie została odnaleziona',
  PROD_NOT_FOUND: 'Produkt nie został odnaleziony',
  ORDER_NOT_FOUND: 'Zamówienie zostało przeniesione do Historii Zamówień',
  WAITER_NOT_FOUND: 'Login jest nieprawidłowy',

  CANT_ADD_PROD_TO_FINISHED_ORDER: 'Dodanie produktów do zakończonego zamówienia jest niemożliwe',
  BAD_REST_ID: 'Błędne ID Restauracji',
  NO_CATEGORY_TYPE: 'Kategoria o wskazanym typie nie została odnaleziona',
  USERS_ORDER_NOT_FOUND: 'Zamówienia użytkownika nie zostały odnalezione',

  // orders
  THERE_ARE_UNPAID_PRODS: 'Domówienie możliwe po opłaceniu wcześniejszych produktów',
  THERE_ARE_UNACCEPTED_PRODS: 'Domówienie możliwe po akceptacji wcześniejszych produktów przez restaurację',
  A_LOT_ORDERS_WAITING_FOR_ACCEPT: 'Aktualnie posiadasz kilka nieopłaconych zamówień. Rozpoczęcie kolejnego jest niemożliwe, dopóki wcześniejsze nie zostaną opłacone, zakończone lub anulowane',
  CANT_DECLINE_PAID_ORDER: 'Opłacone zamówienie nie może zostać odrzucone',
  REST_IS_INACTIVE: 'Restauracja tymczasowo nieaktywna',
  NOT_AVAILABLE_PROD_TIME: 'Jeden z produktów w koszyku nie jest dostępny w wybranym terminie zamówienia. Wybierz inny termin lub usuń produkt',

  TOO_LATE_TO_ADD_PRODUCTS: 'Domówienie produktów do zamówień typu Dostawa oraz Odbiór możliwe maksymalnie do 3 godzin przed terminem realizacji',
  VISIT_DATE_IS_TOO_SOON: 'Zamówienie nie może zostać zrealizowane w czasie przeszłym',
    // 'Nie możesz anulować zamówienie, ponieważ zostało mniej niż 3 godziny do jego początku.',
  CANT_ANNUL_BECAUSE_OF_TIME: 'Zamówienie zostało realizowane i nie może być anulowane. Skontaktuj się z Restauracją w celu wyjaśnienia', 
  CANT_ANNUL_UNACCEPTED_ORDER: 'Zamówienie może zostać anulowane po akceptacji przez restaurację',
  CANT_FINISH_UNPAID_ORDER: 'Zakończenie nieopłaconego zamówienia jest niemożliwe. Anuluj zamówienie w celu usunięcia',

  // system
  STATUS_NOT_EXISTS: 'Status nie został odnaleziony',
  USER_NOT_EXISTS: 'Użytkownik nie został odnaleziony',
  REST_NOT_EXISTS: 'Restauracja nie została odnaleziona',
  PROD_NOT_EXISTS: 'Produkt nie został odnaleziony',

  // is not used
  LACK_OF_FUNDS: 'Środki są niewystarczające. Doładuj konto',

  //chat
  NO_ACTIVE_ADMIN_CHAT: 'Czat z administratorem nie został odnaleziony',
  NO_ADMIN_CHAT_WITH_PROVIDED_ID: 'Czat o podanym ID nie został odnaleziony'
}

// prettier-ignore
export const clientErrors = {
  INCORRECT_IMAGE_TYPE: 'Nieprawidłowy format pliku. Obsługiwane formaty to .jpg, .jpeg oraz .png',
  NO_S3_LINK_RESPONSE: 'Błąd odpowiedzi serwera S3'
}
