/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { useRestaurantContext } from '../context/restaurant.provider'



export default () => {
  const {
    restaurantInfo,
    setRestaurantInfo
  } = useRestaurantContext()

  if (restaurantInfo.isActive === undefined) return null

  return (
    <FormControlLabel
      control={
        <Switch
          checked={restaurantInfo.isActive}
          onChange={() => setRestaurantInfo({ ...restaurantInfo, isActive: !restaurantInfo.isActive })}
          name="isActive"
          color="primary"
        />
      }
      label="Czy restauracja aktywna"
    />
  )
}