// Dependencies
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import withClearCache from './ClearCache'
import * as serviceWorker from './serviceWorker'
import { subscribeUser, subscribeWaiter } from './subscription'
import { GeolocationProvider } from './EZC/context/Geolocation/Geolocation.provider'
import './style/index.css'

// Apollo Client
import { ApolloClient, ApolloProvider, split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { WebSocketLink } from '@apollo/client/link/ws'
import { createUploadLink } from 'apollo-upload-client'
import { cache, typeDefs } from './graphql/cache'

// Material UI
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { plPL } from '@material-ui/core/locale'
import { colors } from './style'

console.log(`NODE_ENV:`, process.env.REACT_APP_NODE_ENV)

const graphqlServerUrl =
  process.env['REACT_APP_SERVER_URI_' + process.env.REACT_APP_NODE_ENV]
const ws = process.env.REACT_APP_NODE_ENV === 'LOCAL_DEV' ? 'ws' : 'wss'

function authorizationWsLink() {
  const d = JSON.parse(localStorage.getItem('userData'))
  const token = d?.token
  return token ? `Bearer ${token}` : ''
}

function waiterAuthWsLink() {
  const d = JSON.parse(localStorage.getItem('waiterData'))
  const token = d?.waiterToken
  return token ? `Bearer ${token}` : ''
}

const wsLink = new WebSocketLink({
  // https://dev.eatzon.pl/graphql => ws://dev.eatzon.pl/graphql
  uri: [ws].concat(graphqlServerUrl.split(':').slice(1)).join(':'),
  options: {
    reconnect: true,
    connectionParams: {
      authorization: authorizationWsLink(),
      waiterauth: waiterAuthWsLink(),
    },
  },
})

const httpLink = createUploadLink({
  uri: graphqlServerUrl,
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: authorizationWsLink(),
      waiterauth: waiterAuthWsLink(),
    },
  }
})

const client = new ApolloClient({
  cache,
  typeDefs,
  link: authLink.concat(splitLink),
  connectToDevTools: true,
})

const muiTheme = createMuiTheme(
  {
    palette: {
      primary: {
        main: colors.text,
      },
      secondary: {
        main: colors.green,
      },
    },
    typography: {
      fontFamily: ['Gilroy'].join(','),
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 844,
        lg: 1280,
        xl: 1920,
      },
    },
  },
  plPL
)

const IndexApp = () => (
  <ApolloProvider client={client}>
    <ThemeProvider theme={muiTheme}>
      <Router>
        <GeolocationProvider>
          <App />
        </GeolocationProvider>
      </Router>
    </ThemeProvider>
  </ApolloProvider>
)

const ClearCacheComponent = withClearCache(IndexApp)

ReactDOM.render(<ClearCacheComponent />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()

if (localStorage.getItem('userData')) subscribeUser()
if (localStorage.getItem('waiterData')) subscribeWaiter()
