/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { ProductList, Button } from '../../../components'
import useOrderMutations from '../../../hooks/useOrders.mutations'

import { useOrderAttentionContext } from '../context/order-attention.context'
import HeaderCondition from './HeaderCondition'


export default () => {
  const { handleConfirmAnnulOrder } = useOrderMutations()
  const {
    orderWeWorkOn,
    alreadyOrderedProducts
  } = useOrderAttentionContext()

  const onSubmit = () =>
    handleConfirmAnnulOrder({ orderId: orderWeWorkOn._id })


  return (
    <React.Fragment>
      <HeaderCondition condition={alreadyOrderedProducts?.length}>
        Produkty w zamówieniu
      </HeaderCondition>
      <ProductList products={alreadyOrderedProducts} />

      <Button
        onClick={onSubmit}
        variant="contained"
        color="primary"
        css={{ fontWeight: 700 }}
      >
        Potwierdzam anulowanie zamówienia
      </Button>
    </React.Fragment>
  )
}