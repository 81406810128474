/** @jsx jsx */
import React, { useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useHistory } from 'react-router-dom'

import { useOrdersContext } from '../../context/orders.context'

import OrderAttentionProvider from './context/order-attention.context'
import OrderAttention from './views/OrderAttention.view'
import NextOrderAttention from './views/NextOrderAttention'
import { ezwRoutePrefix } from '../settings'


const EzwOrdersAttention = () => {
  const history = useHistory()
  const { attentionOrders, loading } = useOrdersContext()
  const orderWeWorkOn = attentionOrders[0]

  useEffect(() => {
    if (!loading && !attentionOrders.length) {
      history.push(`${ezwRoutePrefix}/orders-active`)
    }
  }, [loading, attentionOrders])

  if (!attentionOrders || !attentionOrders.length) return null

  return (
    <OrderAttentionProvider orderWeWorkOn={orderWeWorkOn}>
      <h1 css={{ opacity: .3 }}>
        Wymagają uwagi
      </h1>
      <div css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100% - 40px)', // subtract h1 height
        flexDirection: 'column'
      }}>
        <OrderAttention />
        <NextOrderAttention />
      </div>
    </OrderAttentionProvider>
  )
}

export default EzwOrdersAttention