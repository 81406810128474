/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars


const UserItem = ({ personal, isRestaurateur, helperComponent }) => (
  <div css={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 32
  }}>
    <div>
      {personal?.name && <p>Imię: <b>{personal?.name}</b></p>}
      {personal?.surname && <p>Nazwisko: <b>{personal?.surname}</b></p>}
      {
        isRestaurateur
          ? <p css={{ color: 'green', fontWeight: 700 }}>Jest restauratorem</p>
          : <p css={{ color: 'red', fontWeight: 700 }}>Nie jest restauratorem</p>
      }
    </div>
    <div>{helperComponent}</div>
  </div>
)

export default UserItem