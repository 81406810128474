import orderNeedToPay from '../../../helpers/order-need-to-pay'
import getStatusName from '../../../../helpers/get-status-name'


export default ({
  data,
  setAttentionOrderIds,
  setIsShown,
  attentionOrderIds
}) => {

  if (!data?.ezcOrdersActive) return

  const ids = []

  for (const order of data.ezcOrdersActive) {
    if (orderNeedToPay(order)) {
      ids.push(order._id)
      continue
    }

    if (getStatusName(order.status) === 'PARTIALLY_ACCEPTED') {
      ids.push(order._id)
      continue
    }

    if (order.messages.find(mssg => (!mssg?.fromUserId && !mssg.isRead))) {
      ids.push(order._id)
      continue
    }
  }

  setAttentionOrderIds(ids)

  ids.length > attentionOrderIds.length
    && ids.length > 0
    && setIsShown(true)
}