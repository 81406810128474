

function deliveryParseFloatAmount(deliverySettings) {
  const {
    deliveryCost,
    minAmount,
    freeDeliveryAmount
  } = { ...deliverySettings }

  if (freeDeliveryAmount !== null) {
    deliverySettings.freeDeliveryAmount = parseFloat(freeDeliveryAmount)
  }

  deliverySettings.minAmount = parseFloat(minAmount)

  deliverySettings.deliveryCost = deliveryCost
    .map(([distance, amount]) => ([distance, parseFloat(amount)]))
}

export default deliveryParseFloatAmount