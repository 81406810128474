import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { ezrmRoutePrefix } from '../../pages/settings'


export default () => {
  const { pathname } = useLocation()
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onValueChange = () => pathname === ezrmRoutePrefix + "/products" ? setValue(0) : setValue(1)

  useEffect(() => { onValueChange() }, [pathname])

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
    >
      <Tab label="Produkty" component={Link} to={ezrmRoutePrefix + "/products"} />
      <Tab label="Grupy modyfikatorów" component={Link} to={ezrmRoutePrefix + "/modifier-groups"} />
    </Tabs>
  )
}