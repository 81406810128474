/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useError } from '../../../../hooks/errors.hook'
import MoneyEz from '../../../../helpers/MoneyEz'
import { useQuery } from '@apollo/client'
import { GET_USER_TRANSACTIONS, GET_USER } from '../../../graphql/user.graphql'
import Headline from '../../../../components/Headline/Headline'
import { sOuter, sGrid } from '../../../../style/style'
import { ButtonBorder } from '../../../../components/Elements'
import { Grid } from '@material-ui/core'
import { Tile } from '../wallet/Wallet.page'
import Transaction from './Transaction'
import SkeletonOrder from '../../../../components/SkeletonOrder/SkeletonOrder'
import { useHistory } from 'react-router-dom'

// import Transaction from './components/Transaction.component'
import { TopNav } from '../../../../components/TopNavFixed/TopNavFixed'

const Transactions = () => {
  return (
    <React.Fragment>
      <TopNav title={'Transakcje'} />

      <div css={sOuter}>
        <Headline
          itemChildren={
            <ButtonBorder link={`/account/wallet`}>Portfel</ButtonBorder>
          }>
          Transakcje
        </Headline>
        <AccountBalance />
        <TransactionsGrid />
      </div>
    </React.Fragment>
  )
}

const AccountBalance = () => {
  const { errorHandling } = useError()
  const history = useHistory()
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery(GET_USER)

  if (userLoading) return <div />

  if (userError) {
    errorHandling(userError)
    return null
  }

  if (!userData?.user) {
    history.push('/account/transactions')
  }

  const { user } = userData
  const { personal } = user

  const coinsFree = MoneyEz(user.coinsBalance)
    .subsctract(user.coinsBlocked)
    .getAmountWithFullPrecision()
    .split('.')

  const coinsBlocked = MoneyEz(user.coinsBlocked)
    .getAmountWithFullPrecision()
    .split('.')

  return (
    <React.Fragment>
      <TopNav
        title={'Transakcje'}
        subtitle={personal && `- ${personal.name} ${personal.surname}`}
      />
      <Grid container css={[sGrid]} justify='space-between'>
        <Tile
          subtitle={'Dostępne Środki'}
          version='BALANCE'
          coins={coinsFree}
        />
        <Tile subtitle={'Blokady'} version='BLOCK' coins={coinsBlocked} />
      </Grid>
    </React.Fragment>
  )
}

const TransactionsGrid = () => {
  const { errorHandling } = useError()
  const { data, loading, error } = useQuery(GET_USER_TRANSACTIONS)

  if (error) {
    errorHandling(error)
    return null
  }

  if (loading)
    return (
      <Grid container css={sGrid} justify='space-between'>
        <SkeletonOrder />
        <SkeletonOrder />
        <SkeletonOrder />
        <SkeletonOrder />
      </Grid>
    )

  const { ezcTransactions } = data

  let transactions = ezcTransactions

  return (
    <Grid container css={sGrid} justify='space-between'>
      {transactions.map((data, id) => (
        <Transaction id={id} data={data} key={id} />
      ))}
    </Grid>
  )
}

export default Transactions
