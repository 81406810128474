/** @jsx jsx */
import React, { useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Hidden } from '@material-ui/core'
import { useError } from '../../../../hooks/errors.hook'
import { useGetOrdersInCart } from '../../../hooks/useOrders.queries'
import useOrdersMutation from '../../../hooks/useOrders.mutations'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import OrderItem from '../components/OrderItem.component'
import useCheckoutLogic from '../hooks/useCheckoutLogic'

import { sDescription } from '../../../../style/style'
import SkeletonOrder from '../../../../components/SkeletonOrder/SkeletonOrder'

export default () => {
  const { errorHandling } = useError()
  const { data, loading, error } = useGetOrdersInCart()
  const { handleDeleteOrder } = useOrdersMutation()
  const { onDeleteCartOrderFromLocalStorage } = useCheckoutLogic({})
  const history = useHistory()
  const location = useLocation()
  const query = queryString.parse(location.search)

  useEffect(() => {
    if (query.checkout && data?.ezcOrdersInCart?.length > 0) {
      const checkouts = data.ezcOrdersInCart
      const checkoutId = checkouts[checkouts.length - 1]._id
      // console.log(`redirect to checkout ${checkoutId}`)
      history.push(`/cart-checkout/${checkoutId}`)
    }
  }, [data])

  // return <div>Auth Koszyk</div>

  if (loading)
    return (
      <>
        <SkeletonOrder />
        <Hidden mdUp>
          <SkeletonOrder />
          <SkeletonOrder />
          <SkeletonOrder />
        </Hidden>
      </>
    )
  if (error) {
    errorHandling(error)
    return null
  }

  const onDeleteOrder = (orderId) => {
    handleDeleteOrder({ orderId }, onDeleteCartOrderFromLocalStorage(orderId))
  }

  const { ezcOrdersInCart } = data

  if (!data || !ezcOrdersInCart || ezcOrdersInCart.length === 0)
    return <div css={sDescription}>Twój koszyk jest pusty</div>

  return ezcOrdersInCart.map((order, id) => (
    <OrderItem
      onlyOne={ezcOrdersInCart.length === 1}
      id={id}
      key={id}
      onDelete={() => onDeleteOrder(order._id)}
      {...makeOrderProps(order)}
    />
  ))
}

function makeOrderProps(order) {
  const { _id, restId, orderDetails } = order

  return {
    orderId: _id,
    restaurantId: restId._id,
    restaurantName: restId.name,
    restaurantAddress: restId.address,
    restaurantLogoSrc: restId.avatarUrl,
    orderedProducts: orderDetails[0].productsInCart,
  }
}
