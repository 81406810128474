import React from 'react'

import AddImgHandler from '../../../components/AddImgHandler/AddImgHandler'
import { useRestaurantContext } from '../context/restaurant.provider'


export default () => {
  const {
    logoPreview,
    handleAddLogo,
    logoUrl
  } = useRestaurantContext()

  return (
    <AddImgHandler
      onChange={handleAddLogo}
      preview={logoPreview}
      imgUrl={logoUrl}
      header="Logo"
      subheader="Wybierz logo restauracji"
      name="photofileLogo"
    />
  )
}