import { useState, useEffect } from 'react'

export default ({ products, searchString }) => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('isArchived')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(999)

  const filteredData = products
    .map((product) => ({
      ...product,
      categories: product.categories.map((q) => q.name).join(', '),
    }))
    .filter((product) =>
      product.name.toLowerCase().includes(searchString.toLowerCase())
    )

  useEffect(() => {
    if (page !== 0) setPage(0)
  }, [searchString])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredData.length - page * rowsPerPage)

  return {
    order,
    orderBy,
    page,
    rowsPerPage,
    emptyRows,
    filteredData,

    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  }
}
