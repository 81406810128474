/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify'
import Spinner from '../../../components/Spinner'
import { useHistory } from 'react-router-dom'
import useUserMutations from '../../hooks/useUser.mutations'
import Headline from '../../../components/Headline/Headline'
import {
  sGrid,
  sOuter,
  sDescription,
  sContainerOuter,
  sPaddingTop,
} from '../../../style/style'
import { ButtonBorder, Button } from '../../../components/Elements'
import { s } from '../../../style'
import { Input } from './components'
import { Grid } from '@material-ui/core'
import validateEmail from '../../../helpers/validateEmail'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'

export default function () {
  const [isLoading, setIsLoading] = useState(false)
  const [inputValues, setInputValues] = useState({ email: '' })
  const { handleResetPassword } = useUserMutations()
  const history = useHistory()

  const onChange = (e) =>
    setInputValues({ ...inputValues, [e.target.name]: e.target.value })
  const onSubmit = () => {
    setIsLoading(true)
    if (!validateEmail(inputValues.email)) {
      toast.error('Email nieprawidłowy')
      return setIsLoading(false)
    }
    handleResetPassword({ email: inputValues.email }, () => {
      toast.info(
        'Link do zresetowania hasła został wysłany na podany adres email'
      )
      setIsLoading(false)
      history.push('/')
    })
  }

  return (
    <React.Fragment>
      <TopNav title={'Resetowanie Hasła'} />
      <div css={sOuter}>
        <Headline
          itemChildren={<ButtonBorder link={`/login`}>Zaloguj</ButtonBorder>}>
          Resetowanie
        </Headline>
        <Grid container css={sGrid}>
          <p css={sDescription}>
            Zresetuj hasło do twojego konta Eatzon, podając powiązany z nim
            adres email. Na skrzynkę zostanie przesłana dalsza instrukcja
          </p>
        </Grid>
      </div>

      <Grid container css={[sContainerOuter, sGrid, sPaddingTop]}>
        <div css={[sFormContainer]}>
          <Input
            onChange={onChange}
            name='email'
            type='email'
            value={inputValues.email}
            placeholder='Email'
          />

          <Button w100 action={isLoading ? () => {} : onSubmit}>
            {isLoading ? (
              <Spinner size={1.5} addCss={{ color: '#fff' }} />
            ) : (
              'Resetuj hasło'
            )}
          </Button>
        </div>
      </Grid>
    </React.Fragment>
  )
}

const sFormContainer = {
  [s.sm_down]: { paddingBottom: '2rem' },
  paddingBottom: '1.5rem',
}
