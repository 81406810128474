import { useCallback, useContext } from 'react'
import { toast } from 'react-toastify'
import { AuthContext } from '../context/auth.context'
import { backendErrors } from '../constants/errors'
import { useHistory } from 'react-router-dom'

const errorsNotToHandle = [
  'NO_ACTIVE_ADMIN_CHAT',
  'NO_CATEGORY_TYPE',
  'Połączenie z siecią',
]

const urls = {
  login: '/login',
  404: '/404',
}

export const useError = () => {
  const { logout } = useContext(AuthContext)
  const history = useHistory()

  const errorHandling = useCallback((err) => {
    try {
      errorsNotToHandle.forEach((errorMssg) => {
        if (err.message.includes(errorMssg)) throw new Error()
      })

      const errorHandle = errorHandleInit(err.message, history)

      errorHandle(
        'NOT_AUTH',
        {
          redirectUrl: urls.login,
          toastMssg:
            'Autentykacja zakończona niepowodzeniem. Proszę, zaloguj się ponownie',
        },
        logout
      )

      errorHandle(
        'USER_NOT_EXISTS',
        {
          redirectUrl: urls.login,
          toastMssg:
            'Zapisane informacje o użytkownik na Twoim urządzeniu są niepoprawne. Proszę, zaloguj się ponownie',
        },
        logout
      )

      errorHandle('REST_NOT_FOUND', {
        redirectUrl: urls[404],
        toastMssg: 'Restauracja nie została odnaleziona',
      })

      errorHandle('PROD_NOT_FOUND', {
        redirectUrl: urls[404],
        toastMssg: 'Produkt nie został odnaleziony',
      })

      errorHandle('NOT_ADMIN', {
        redirectUrl: urls[404],
        toastMssg:
          'Autoryzacja zakończona niepowodzeniem. Proszę, zaloguj się, używając odpowiedniego konta',
      })

      errorHandle(
        [
          'Argument passed in must be a single String of 12 bytes or a string of 24 hex characters',
          'Cast to ObjectId failed for value',
        ],
        {
          redirectUrl: urls[404],
          toastMssg:
            'Nieprawidłowy ID. Jeśli próbowałeś wejść na stronę restauracji lub produktu, sprawdź poprawność linku',
        }
      )

      if (!backendErrors[err.message]) console.log(err)

      const errMssg = backendErrors[err.message] || err.message
      toast.error(errMssg)
    } catch (error) {}
  }, [])

  return { errorHandling }
}

function errorHandleInit(errMssg, history) {
  return function (
    expectedErrMssg,
    { redirectUrl, toastMssg, autoClose = 6000 },
    callback
  ) {
    if (typeof expectedErrMssg === 'object' && Array.isArray(expectedErrMssg)) {
      for (const expectedMssg of expectedErrMssg) {
        if (errMssg.includes(expectedMssg)) execFn()
      }
    }

    if (errMssg.includes(expectedErrMssg)) execFn()

    function execFn() {
      if (toastMssg) toast.error(toastMssg)
      if (typeof callback === 'function') callback()
      if (redirectUrl) history.replace(redirectUrl)
      throw new Error()
    }
  }
}
