import getStatusName from '../../helpers/get-status-name'


/**
 * @param {Object} order order.status[] && order.isPaid Boolean
 * @returns {Boolean}
 */
export default (order) => {

  const paidStatuses = [
    'ACCEPTED',
    'CONFIRMED',
    'PARTIALLY_CONFIRMED'
  ]

  const status = getStatusName(order.status)

  return !order.isPaid
    && paidStatuses.includes(status)
}