/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { s, colors, alpha } from '../../../../style'
import { krakenUrls } from '../../../../constants/s3-files'

const BackgroundBlur = ({ title, photo }) => {
  return (
    <div css={sOuter}>
      <div css={sImageBackground}>
        {photo && (
          <img src={krakenUrls.small + photo} alt={title} loading='lazy' />
        )}
        <div css={sBlurBackground} />
        <div css={sGradientBackground} />
      </div>
    </div>
  )
}

const sOuter = {
  width: '100vw',
}

const sImageBackground = {
  width: '100vw',
  height: 260,
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,

  [s.md]: {
    height: 400,
  },

  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
  },
}

const sBlurBackground = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backdropFilter: 'blur(30px)',
}

const sGradientBackground = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: `linear-gradient(to bottom, 
    ${colors.white.concat(alpha[30])} 0%,
    ${colors.white.concat(alpha[30])} 20%,
    ${colors.white.concat(alpha[60])} 60%,
    ${colors.white.concat(alpha[95])} 90%,
    ${colors.white.concat(alpha[100])} 100%
    )`,
}

export default BackgroundBlur
