/** @jsx jsx */
import React, { useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
// import Spinner from '../../../components/Spinner'
import { useError } from '../../../hooks/errors.hook'
import MoneyEz from '../../../helpers/MoneyEz'

import useDeliveryInfo from '../../hooks/useDeliveryInfo'
import ConditionParagraph from './components/ConditionParagraph'

const GetDeliveryInfo = ({
  restId,
  orderAmount = 0,
  setDeliveryCost,
  // setDeliveryError,
}) => {
  const { errorHandling } = useError()
  const {
    data,
    loading,
    error,

    // availableHours,
    // deliveryCost,
    deliveryRadius,
    distanceToUser,
    freeDeliveryAmount,
    minAmount,
    _deliveryCostAmount,
  } = useDeliveryInfo({ restId })

  useEffect(() => {
    if (data?.ezcOrderDeliveryCost) {
      setDeliveryCost(
        typeof data?.ezcOrderDeliveryCost.freeDeliveryAmount === 'number' &&
          data?.ezcOrderDeliveryCost.freeDeliveryAmount <= orderAmount
          ? 0
          : _deliveryCostAmount
      )
    }
  }, [orderAmount, data])

  // useEffect(() => {
  //   if (distanceToUser > deliveryRadius) {
  //     console.log('DISTANCE TO USER TOO FAR')
  //     setDeliveryError(true)
  //   } else setDeliveryError(false)

  //   if (minAmount > orderAmount) {
  //     console.log('MIN AMOUNT !')
  //     setDeliveryError(true)
  //   } else setDeliveryError(false)
  // }, [setDeliveryError, distanceToUser, deliveryRadius, minAmount, orderAmount])

  if (loading) return <div />
  if (error) {
    errorHandling(error)
    return <p>Spróbuj załadować ponownie</p>
  }
  if (!data?.ezcOrderDeliveryCost) {
    return <p>Spróbuj załadować ponownie</p>
  }

  if (distanceToUser > deliveryRadius)
    return (
      <ConditionParagraph
        condition={distanceToUser > deliveryRadius}
        text='Wybrany adres jest po za obszarem dostaw restauracji'
        theme='error'
      />
    )

  return (
    <>
      <ConditionParagraph
        condition={freeDeliveryAmount === null}
        text={'Koszt dostawy: '}
        text2={MoneyEz(_deliveryCostAmount).getAmountWithFullPrecisionAndZl()}
      />

      <ConditionParagraph
        condition={freeDeliveryAmount && freeDeliveryAmount > orderAmount}
        text={'Koszt dostawy: '}
        text2={MoneyEz(_deliveryCostAmount).getAmountWithFullPrecisionAndZl()}
      />
      <ConditionParagraph
        condition={minAmount > orderAmount}
        text={'Minimalna kwota dostawy nie została osiągnięta. Pozostało: '}
        text2={MoneyEz(minAmount)
          .subsctract(orderAmount)
          .getAmountWithFullPrecisionAndZl()}
        theme='error'
      />
      <ConditionParagraph
        condition={freeDeliveryAmount && freeDeliveryAmount > orderAmount}
        text={'Darmowa dostawa od '}
        text2={MoneyEz(
          freeDeliveryAmount || 0
        ).getAmountWithFullPrecisionAndZl()}
        theme='info'
      />

      <ConditionParagraph
        condition={freeDeliveryAmount && freeDeliveryAmount <= orderAmount}
        text='Darmowa dostawa aktywowana'
        theme='success'
      />
    </>
  )
}

export default GetDeliveryInfo
