/** @jsx jsx */
import React, { useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
// import Layout from '../../components/Layout'
import Spinner from '../../../components/Spinner'
import useUserMutations from '../../hooks/useUser.mutations'

export default function () {
  const { handleConfirmEmail } = useUserMutations()
  const { token } = useParams()
  const history = useHistory()

  useEffect(() => {
    handleConfirmEmail({ token }, () => {
      toast.success('Adres email został potwierdzony!')
      history.push('/')
    })
  }, [token])

  return (
    // <Layout>
    <Spinner size={2} />
    // </Layout>
  )
}
