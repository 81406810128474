import { usePostMssgToOrder } from '../../../../hooks/useGraphql.hook'


const useOrderChat = (
  orderId,
  { newMssg, setNewMssg, scrollToBottom }
) => {
  const { onPostMssgToOrder } = usePostMssgToOrder()

  const onSend = () => {
    if (newMssg.length === 0) return

    onPostMssgToOrder(
      { orderId, message: newMssg },
      () => {
        setNewMssg('')
        scrollToBottom()
      }
    )
  }


  return { onSend }
}

export default useOrderChat