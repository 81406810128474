/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Bullet, Collapsable } from '../../../../components/Elements'
import SettingsIcon from '@material-ui/icons/Settings'
import { s, colors } from '../../../../style'
import {
  consumptionTypeNames,
  consumptionTypeIcons,
} from '../../../../constants/types'

const ConsumptionTypes = ({ consumptionTypes }) => {
  return (
    <Collapsable
      left
      noPadding
      oneLiner
      title={'Realizacja'}
      description={
        <div css={{ overflowX: 'scroll' }}>
          {consumptionTypes?.map((type, id) => (
            <>
              {id !== 0 && <Bullet />}
              {consumptionTypeNames[type]}
            </>
          ))}
        </div>
      }
      icon={<SettingsIcon />}>
      <>
        <div css={{ padding: '1rem 1rem 2rem', [s.md]: { padding: '2rem' } }}>
          <p
            css={{
              textAlign: 'center',
              fontWeight: 800,
              fontSize: '1.25rem',
              letterSpacing: '-0.03em',
              [s.xs]: {
                fontSize: '1.125rem',
                paddingTop: '1rem',
              },
            }}>
            Dostępne opcje obsługi
          </p>
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '2rem',
              width: '100%',
            }}>
            {consumptionTypes.map((type, id) => (
              <div
                key={id}
                css={[
                  {
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                  id !== 0 &&
                    id !== consumptionTypes.length - 1 && {
                      margin: '0 2rem',
                      [s.md]: { margin: '0 3rem' },
                    },
                ]}>
                <span
                  css={{ fontSize: '1.5rem', [s.md]: { fontSize: '2rem' } }}>
                  {consumptionTypeIcons[type]}
                </span>
                <p
                  css={{
                    fontSize: '0.675rem',
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    [s.md]: { fontSize: '0.75rem' },
                  }}>
                  {consumptionTypeNames[type]}
                </p>
              </div>
            ))}
          </div>
        </div>
      </>
    </Collapsable>
  )
}

export default ConsumptionTypes
