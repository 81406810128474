/** @jsx jsx */
import React, { useState, useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import TextField from '@material-ui/core/TextField';
import {
  Button,
  Dialog
} from '../'


export default ({
  note,
  onSave,
  size
}) => {

  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState('')

  useEffect(() => { setValue(note) }, [])

  const onClose = () => setIsOpen(false)
  const onOpen = () => setIsOpen(true)
  const onChange = e => setValue(e.target.value)

  const onSubmit = async () => {
    await onSave(value)
    setIsOpen(false)
  }

  const btn = (children) => (
    <Button
      onClick={e => { e.preventDefault(); onOpen() }}
      variant="outlined"
      size={size}
      css={{ fontWeight: 700 }}
    >
      {children}
    </Button>
  )

  const txtField = (
    <TextField
      label="Twoja notatka"
      multiline
      rows={2}
      value={value}
      onChange={onChange}
      css={{ width: '100%' }}
    />
  )

  return (
    <React.Fragment>
      <NoteView
        note={note}
        btn={btn}
      />

      <Dialog
        isOpen={isOpen}
        onClose={onClose}

        textHeader="Notatka do zamówienia"
        textContent={txtField}

        onMainBtn={onSubmit}
        textMainBtn="Zapisz"

        onSecondaryBtn={onClose}
        textSecondaryBtn="Zamknij"
      />
    </React.Fragment>
  )
}


const NoteView = ({ note, btn }) => note
  ? (
    <div css={{
      margin: '8px 0',
      border: '1px solid #777',
      borderRadius: 8,
      padding: 8,
      display: 'inline-flex',
      alignItems: 'center'
    }}>
      <p css={{ flexGrow: 1, marginRight: 8 }}>{note}</p>
      {btn('Zmień notatkę')}
    </div>
  )
  : btn('Zostaw notatkę')