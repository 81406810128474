/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import Dialog from '../../../components/Dialog'

import Button from '../../../components/Button'


const activeProdStatuses = [
  { name: 'Realizowany', status: 'PREPARING' },
  { name: 'Gotowy', status: 'DONE' },
  { name: 'Dostarczony', status: 'FINISHED' },
]

const StatusButtons = ({
  isLoading,
  handleActiveProdStatus,
  handleAnnulFinishedProd
}) => {
  const [isDeclineOpen, setIsDeclineOpen] = useState(false)

  const handleOpenDecline = () => setIsDeclineOpen(true)
  const handleCloseDecline = () => setIsDeclineOpen(false)
  const onAnnulProducts = () => {
    if (isLoading) return
    handleAnnulFinishedProd(handleCloseDecline)
  }

  return (
    <React.Fragment>
      <p css={{ opacity: '.6', margin: '4px 0', fontSize: 14 }}>
        Kliknij poniższy przycisk, aby zmienić status zaznaczonych produktów:
    </p>
      <div css={{ display: 'flex' }}>
        {activeProdStatuses.map((el, index) => (
          <Button
            key={index}
            css={getStatusBtnStyle(el)}
            onClick={() => handleActiveProdStatus(el.status)}
            disabled={isLoading}
            variant="outlined"
            size="small"
          >
            {el.name}
          </Button>
        ))}
        <Button
          onClick={handleOpenDecline}
          size="small"
        >
          Anuluj zaznaczone produkty
        </Button>
      </div>

      <Dialog
        isOpen={isDeclineOpen}
        onClose={handleCloseDecline}
        textMainBtn="Zamknij"
        onMainBtn={handleCloseDecline}
        textSecondaryBtn="Anuluj zaznaczone produkty"
        onSecondaryBtn={onAnnulProducts}

        textHeader="Chcesz anulować zaznaczone produkty?"
        textContent="Pieniądze za anulowane produkty zostaną odliczone od kwoty zamówienia i zwrócone Klientowi."
      />

    </React.Fragment>
  )
}


export default StatusButtons


const getStatusBtnStyle = el => [
  {
    marginRight: 8,
    fontWeight: 700
  },
  el.status === 'PREPARING'
    ? { color: '#FF8000' }
    : {},
  el.status === 'DONE'
    ? { color: '#00B30F' }
    : {},
  el.status === 'FINISHED'
    ? { color: '#343340' }
    : {},
]