/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars


export default ({
  sum,
  paid,

  annulAmount,
  annulProductsSum,

  sumToPay,
  sumToPayAmount,

  consumptionType,
  deliveryCost,
  deliveryAmount,
  productsSum,
}) => (

  <div css={{ textAlign: 'right', margin: '20px 16px 0 0', p: { marginBottom: 2 } }}>
    <p>
      Produkty: {productsSum}
    </p>

    {
      consumptionType === 'delivery'
      && <p>Dostawa: {deliveryCost === 0 ? 'DARMOWA' : deliveryAmount}</p>
    }

    <p css={{ fontWeight: 700, "&&": { marginBottom: 6 } }}>
      Suma: {sum || '0,00zł'}
    </p>

    <p>
      Opłacono: {paid || '0,00zł'}
    </p>

    {!!annulProductsSum
      && !!annulAmount
      && annulAmount > 0
      && <p>Suma produktów anulowanych: {annulProductsSum}</p>
    }

    {!!sumToPay
      && (
        <p css={{
          color: sumToPayAmount > 0 ? '#FF0000' : undefined,
          fontWeight: sumToPayAmount > 0 ? 700 : undefined
        }}>
          Suma do zapłaty: {sumToPay}
        </p>
      )}
  </div>
)