export function invoiceDataCheck(invoiceDetails) {
  if (typeof invoiceDetails !== 'object')
    throw new Error('Dane do faktury nie mogą być puste')

  const errorsArray = []

  const { nip, firmName, address, city, zipCode } = invoiceDetails

  const maskNumberCheck = (value) =>
    value.split('-').filter((q) => isNaN(q)).length === 0

  if (!(nip && nip.length > 0 && maskNumberCheck(nip)))
    errorsArray.push('NIP wymagany')

  if (!firmName) errorsArray.push('Nazwa firmy wymagana')
  if (!address) errorsArray.push('Adres wymagany')
  if (!city) errorsArray.push('Miasto wymagane')
  if (!(zipCode && zipCode.length > 0 && maskNumberCheck(zipCode)))
    errorsArray.push('Kod pocztowy wymagany')

  if (errorsArray.length > 0) {
    const err = new Error()
    err.array = errorsArray
    throw err
  }
}

export function _getDate() {
  const date = new Date()
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  if (month < 10) month = '0' + month

  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()

  const hours = date.getHours()
  const minutes =
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()

  const d = [year, month, day].join('-')
  const h = [hours + 1, minutes].join(':')

  return { d, h }
}
