import { s } from '../../../style'

export const sHeadline = {
  [s.sm_down]: { padding: '2rem 2rem 1rem' },
  [s.md]: { padding: '2rem 4rem 2rem' },
}

export const sGridOverride = {
  [s.sm_down]: { padding: '0 2rem' },
  [s.md]: { padding: '0 4rem' },
}

export const sFormContainer = {
  display: 'flex',
  [s.sm_down]: {
    padding: '1rem 1.5rem 2rem',
    gap: '1rem',
    flexDirection: 'column',
  },
  [s.md]: { padding: '0.5rem 3rem 3rem', gap: '2rem' },
}
