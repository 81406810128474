/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import {
  Bullet,
  Button,
  ButtonBorder,
  Collapsable,
} from '../../../components/Elements'
import Headline from '../../../components/Headline/Headline'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import { sOuter, sGrid, sDescription, sBottomLinks } from '../../../style/style'
import { Grid, TextField } from '@material-ui/core'
import RoomServiceIcon from '@material-ui/icons/RoomService'
import { CollapsableText } from '../../../components/Elements/Collapsable/Collapsable'
import { toast } from 'react-toastify'
import { useGetWaiterDashoboardAccess } from '../../hooks/useQueries'
import useRestaurantMutations from '../../hooks/useRestaurant.mutations'
import { sInput, sInputDarker } from '../../style/input.style'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { s } from '../../../style'
import { GET_RESTAURANT } from '../../graphql/restaurants.graphql'
import { useQuery } from '@apollo/client'
import { useActiveRestaurantContext } from '../../context/active-restaurant.provider'

const AccessPage = () => {
  const [isOpen, setIsOpen] = useState(false)

  const [values, setValues] = useState({
    password: '',
    showPassword: false,
    isChangePassView: false,
    isLoading: false,
  })

  const { data, loading, error } = useGetWaiterDashoboardAccess()
  const { handleChangeWaiterPassword } = useRestaurantMutations()

  if (error) {
    toast.error(error.toString())
  }

  const login = data?.ezrWaiterDashboardAccess?.login

  const onSubmitPassword = async () => {
    setValues({ ...values, isLoading: true })
    await handleChangeWaiterPassword({ password: values.password })
    setValues({ ...values, isLoading: false })
    toast.dark('Hasło zostało zmienione')
    setIsOpen(false)
  }

  const handleChangePassword = (e) =>
    setValues({ ...values, password: e.target.value })

  // const showChangePassword = () => {
  //   setValues({ ...values, isChangePassView: !values.isChangePassView })
  // }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const { activeRestaurantId } = useActiveRestaurantContext()

  const { data: dataRestaurant } = useQuery(GET_RESTAURANT, {
    variables: { restId: activeRestaurantId },
    skip: !activeRestaurantId,
  })

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault()
  // }

  return (
    <React.Fragment>
      <TopNav title={'Dostępy'} offset={60} />
      <div css={sOuter}>
        <Headline>Dostępy</Headline>
        <Grid container css={[sGrid, { marginBottom: '1rem' }]}>
          <p css={sDescription}>
            Zarządzaj dostępem do konta powiązanych z Restauracją{' '}
            <strong>{dataRestaurant?.ezrRestaurant.name}</strong>
          </p>
        </Grid>
        <Grid container css={sGrid}>
          <Grid item xs={12} md={6}>
            <Collapsable
              left
              noPadding
              oneLiner
              title={'Kelner'}
              icon={<RoomServiceIcon />}
              description={
                <>
                  {dataRestaurant && (
                    <>
                      {dataRestaurant?.ezrRestaurant.name}
                      <Bullet />
                    </>
                  )}{' '}
                  Dostęp do panelu kelnera
                </>
              }
              parentIsOpen={isOpen}
              parentSetIsOpen={setIsOpen}>
              {!loading && !error && (
                <CollapsableText>
                  <TextField
                    disabled
                    label='Login'
                    value={login}
                    fullWidth
                    css={[sInput, sInputDarker]}
                  />
                  <TextField
                    autoComplete='off'
                    type={values.showPassword ? 'text' : 'password'}
                    label='Nowe Hasło'
                    onChange={handleChangePassword}
                    value={values.password}
                    fullWidth
                    css={[sInput, sInputDarker]}
                  />
                  <div
                    css={{
                      display: 'flex',
                      gap: '1rem',
                      [s.md]: { gap: '2rem' },
                    }}>
                    <Button size48 dimmed action={handleClickShowPassword}>
                      {values.showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </Button>
                    <Button w100 w100Desktop action={onSubmitPassword}>
                      Zmień Hasło
                    </Button>
                  </div>
                </CollapsableText>
              )}
            </Collapsable>
          </Grid>
          <Grid item xs={12} css={sBottomLinks}>
            <ButtonBorder link={'/waiter'}>Panel Kelnera</ButtonBorder>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}

export default AccessPage
