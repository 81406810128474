import React from 'react'

import OpenningHours from '../../../components/OpenningHours/OpenningHours.component'

import { useRestaurantContext } from '../context/restaurant.provider'


export default () => {
  const {
    openningHours,
    handleOpenningHours
  } = useRestaurantContext()

  return (
    <OpenningHours
      handleDayOnChange={handleOpenningHours}
      openningHours={openningHours}
      header="Wybierz godziny otwarcia restauracji"
      paragraphs={[
        'Jeśli restauracja któregoś dnia jest otwarta całodobowo, pozostaw od 0:00 do 24:00.',
        'Jeśli restauracja któregoś dnia jest zamknięta, ustaw od 0:00 do 0:00.'
      ]}
    />
  )
}