/** @jsx jsx */
import React, { useState, useContext } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
// import Layout from '../../components/Layout'
import NewPasswordView from '../../views/Auth/NewPassword.view'

export default function () {
  return (
    // <Layout>
    <NewPasswordView />
    // </Layout>
  )
}
