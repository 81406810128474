import { gql } from '@apollo/client'

export const GET_PRODUCTS = gql`
  query ezcProducts {
    ezcProducts {
      _id
      name
      restaurant {
        _id
        name
      }
      price
      photoUrl
      categories {
        _id
        name
        type
      }
    }
  }
`

export const GET_PRODUCTS_BY_CATEGORY_ID = gql`
  query ezcProductsByCategoryId($categoryId: ID!, $geoInput: GeolocationInput) {
    ezcProductsByCategoryId(categoryId: $categoryId, geoInput: $geoInput) {
      _id
      name
      restaurant {
        _id
        name
      }
      price
      photoUrl
      categories {
        _id
        name
        type
      }
    }
  }
`

export const GET_CATEGORIES_BY_TYPE = gql`
  query categoriesByType($categoryType: String!) {
    categoriesByType(categoryType: $categoryType) {
      _id
      name
      order
    }
  }
`

export const GET_PRODUCT = gql`
  query ezcProduct($prodId: ID!) {
    ezcProduct(prodId: $prodId) {
      _id
      name
      description
      categories {
        name
        type
      }
      ingredients {
        name
      }
      flavors
      cookTime
      cookMethod
      restaurant {
        _id
        name
        avatarUrl
        photoUrl
        address
        categories {
          type
          name
        }
        consumptionTypes
        location {
          coordinates
        }
        products {
          _id
          name
          photoUrl
          categories {
            name
          }
          price
        }
        isActive
      }
      price
      photoUrl
      isArchived
      consumptionTypes
      availableHours {
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
        sun {
          open
          close
        }
      }
    }
  }
`

export const GET_INGREDIENTS = gql`
  query ingredients($page: Int, $limit: Int) {
    ingredients(page: $page, limit: $limit) {
      _id
      name
    }
  }
`

export const GET_INGREDIENTS_BY_IDS = gql`
  query getIngredientsByIDs($_ids: [ID]) {
    getIngredientsByIDs(_ids: $_ids) {
      _id
      name
    }
  }
`
