import React from 'react'

import Creatable from '../../../components/autocompletes/Creatable'
import { useProductContext } from '../context/product.provider'
import { ingredientTypes } from '../../../../constants/types'



export default () => {
  const {
    ingredients,
    handleIngridients,
    asyncIngridients
  } = useProductContext()

  return (
    <Creatable
      label="Składniki"
      placeholder="Składniki"
      stateArr={ingredients}
      setStateArr={handleIngridients}
      data={asyncIngridients?.data?.ingredients || []}
      language="pl"
      types={ingredientTypes}
    />
  )
}