/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { ButtonBorder } from '../../../components/Elements'
import Headline from '../../../components/Headline/Headline'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import { sOuter, sGrid, sNoMarginTop } from '../../../style/style'
import ProductsProvider, {
  useProductsContext,
} from './context/products.provider'
import SearchProducts from './components/SearchProducts'
import ProductsList from './components/ProductsList'
import SkeletonOrder from '../../../components/SkeletonOrder/SkeletonOrder'
import { Grid } from '@material-ui/core'

const ProductsPage = () => {
  const { loading, error } = useProductsContext()

  return (
    <React.Fragment>
      <TopNav title={''} extraCssOuter={{ boxShadow: 'none' }} />
      <div css={sOuter}>
        <Headline
          itemChildren={
            <ButtonBorder link={'/rest/product/new'}>
              Nowy Produkty
            </ButtonBorder>
          }>
          Produkty
        </Headline>
      </div>
      <SearchProducts />
      {loading && (
        <div css={[sOuter, sNoMarginTop]}>
          <Grid container css={[sGrid]}>
            <SkeletonOrder />
            <SkeletonOrder />
          </Grid>
        </div>
      )}
      {!loading && !error && <ProductsList />}
    </React.Fragment>
  )
}

export default () => (
  <ProductsProvider>
    <ProductsPage />
  </ProductsProvider>
)
