const removeDuplicateProducts = (allProducts) => {
  let newAllProducts = []

  allProducts
    .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
    .sort((a, b) =>
      a.status[0].createdAt < b.status[0].createdAt
        ? -1
        : a.status[0].createdAt > b.status[0].createdAt
        ? 1
        : 0
    )

  allProducts.forEach((element, index, array) => {
    const nextElement = index + 1 < allProducts.length && allProducts[index + 1]
    if (nextElement) {
      if (element._id !== nextElement._id) {
        if (
          element.name !== nextElement.name ||
          element.status[0].createdAt !== nextElement.status[0].createdAt
        )
          newAllProducts.push(element)
        // else if (element.status[0].createdAt !== nextElement.status[0].createdAt)
      }
    } else newAllProducts.push(element)
  })

  return newAllProducts
}

export default removeDuplicateProducts
