/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core'
import Collapsable, {
  CollapsableText,
} from '../../../../components/Elements/Collapsable/Collapsable'
import {
  consumptionTypeNames,
  consumptionTypeIcons,
} from '../../../../constants/types'
import { colors } from '../../../../style'

const OrderType = ({ consumptionType }) => {
  return (
    <Grid item xs={12} md={6}>
      <Collapsable
        // left
        noPadding
        oneLiner
        title={consumptionTypeNames[consumptionType]}
        icon={consumptionTypeIcons[consumptionType]}
        description={'Sposób Zamówienia'}>
        <CollapsableText>
          Wybrany Sposób zamówienia:{' '}
          <strong css={{ color: colors.text }}>
            {consumptionTypeNames[consumptionType]}
          </strong>
        </CollapsableText>
      </Collapsable>
    </Grid>
  )
}

export default OrderType
