import { useState } from 'react'
import { subDays } from 'date-fns';
import { useError } from '../../../hooks/errors.hook'

import useRestaurantsMutations from '../../hooks/useRestaurants.mutations'
import { useGetRestaurantsByName } from '../../hooks/useRestaurants.queries'



const useTransactionsByRestaurant = () => {
  const { errorHandling } = useError()
  const { handleGenerateTransactionsCsv } = useRestaurantsMutations()
  const [getRestaurant, { data, loading, error }] = useGetRestaurantsByName()

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [restId, setRestId] = useState('')
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  if (error) errorHandling(error)

  const onRestaurantChoose = restId => {
    setIsPopupOpen(true)
    setRestId(restId)
  }

  const onPopupClose = () => {
    setIsPopupOpen(false)
    setRestId('')
  }


  const onGenerateCsv = async () => {
    try {
      const { startDate, endDate } = state[0]

      const response = await handleGenerateTransactionsCsv(restId, startDate, endDate)

      if (response) {
        setIsPopupOpen(false)
        openInNewTab(
          process.env[
            'REACT_APP_SERVER_URI_'
              .concat(process.env.REACT_APP_NODE_ENV)
          ].slice(0, -8)
          + response.data.adminGenerateCsvOfTransactionsByRestaurant
        )
      }

    } catch (err) {
      errorHandling(err)
    }
  }


  return {// fetching
    getRestaurant,
    data,
    loading,
    // state data
    state,
    setState,
    onRestaurantChoose,
    // popup
    isPopupOpen,
    onPopupClose,
    // async fn
    onGenerateCsv
  }
}

function openInNewTab(url) {
  var win = window.open(url, '_blank');
  win.focus();
}


export default useTransactionsByRestaurant