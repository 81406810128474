/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import ProductsModifiersNav from '../../components/ProductsModifiersNav/ProductsModifiersNav'
import SearchInput from '../../components/SearchInput/SearchInput'

import ModifiersTable from './views/ModifiersTable'



export default () => {
  const [searchString, setSearchString] = useState('')

  return (
    <React.Fragment>
      <ProductsModifiersNav />

      <div css={{ padding: 24 }}>

        <div css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <h1 css={{ marginBottom: 24 }}>Grupy modyfikatorów</h1>

          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon>Dodaj</AddIcon>}
            size="large"
          >
            Dodaj grupę
          </Button>
        </div>

        <SearchInput searchString={searchString} setSearchString={setSearchString} />
        <br />
        <ModifiersTable searchString={searchString} />

      </div>
    </React.Fragment>
  )
}