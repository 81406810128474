import { useState } from 'react'
import { imagePreview as imgPreviewUtil } from '../../../../helpers/image-preview'


export default () => {
  const [avatarUrl, setAvatarUrl] = useState('')
  const [imageFile, setImageFile] = useState(null)
  const [imagePreview, setImagePreview] = useState(null)

  const onAddImage = event => {
    setImageFile(event.target.files[0])

    imgPreviewUtil(event.target.files[0])
      .then(file => setImagePreview(file))
      .catch(e => setImagePreview(null))

    event.target.value = ''
  }

  return {
    imageFile,
    imagePreview,
    onAddImage,

    avatarUrl,
    setAvatarUrl
  }
}