import { breakpoint } from '../../../constants/style'


export const large = {
  outer: {
    padding: '8px',
    minWidth: 210,
    display: 'block',
    zIndex: 1,

    [breakpoint.md]: {
      minWidth: 250,
    }
  },

  inner: {
    borderRadius: 12,
    minHeight: 160,
    maxHeight: 160,
    maxWidth: 280,
    boxShadow: '0 2px 6px 0 #00000014',
    cursor: 'pointer',
    display: 'flex!important',
    flexDirection: 'column',
    overflow: 'hidden'
  },

  imgContainer: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    flexBasis: '160px',
    position: 'relative'
  },

  name: {
    position: 'absolute',
    bottom: 0,
    left: 8,
    zIndex: 10,
    color: 'rgba(41, 41, 41, 1)',
    fontSize: 16,
    fontWeight: 800
  },

  gradient: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 64,
    background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255, 255, 255, .9) 20%, rgba(255, 255, 255, 0.8) 40%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0.25) 80%, rgba(255,255,255,0) 100%)'
  },

  bottomSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // height: 40,
    padding: '4px 8px',
    backgroundColor: '#fff'
  },

  price: {
    fontSize: 20,
    color: '#9FA2A8',
    fontWeight: 800,

    span: {
      color: '#D3D7E0'
    }
  }
}