/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { useOrdersContext } from '../../../context/orders.context'
import { OrderAttentionSummaryComponent } from './OrderAttentionSummary'


export default () => {
  const {
    attentionOrders
  } = useOrdersContext()
  const nextOrder = attentionOrders[1]

  if (!nextOrder) return null

  return (
    <Container>
      <OrderAttentionSummaryComponent {...nextOrder} />
    </Container>
  )
}

// Components
const Container = ({ children }) => (
  <div css={{
    width: '100%',
    marginTop: 32,
    maxWidth: 520,
    backgroundColor: '#fff',
    padding: 16,
    borderRadius: '4px 4px 0 0',
    opacity: '.6',
    position: 'relative',

    "&::after": {
      content: '" "',
      background: 'linear-gradient(0deg, rgba(237,237,237,1) 0%, rgba(237,237,237,0.4416141456582633) 50%, rgba(0,0,0,0) 100%)',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      top: '30%'
    }
  }}>
    {children}
  </div>
)