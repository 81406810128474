/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { sDescription, sGrid } from '../../../../style/style'
import { Grid, TextField } from '@material-ui/core'
import { useRestaurantContext } from '../context/restaurant.provider'
import { sInput } from '../../../style/input.style'
import { categoryTypes } from '../../../../constants/types'
import GooglePlacesAutocomplete from '../../../../components/GooglePlacesAutocomplete'
import Creatable from '../../../components/autocompletes/Creatable'
import StatusActive from './StatusActive'
import ImageLogo from './ImageLogo'
import ImageBackground from './ImageBackground'
import { s } from '../../../../style'

const Details = () => {
  const {
    restaurantInfo,
    handleRestaurantInfo,
    handleChangeAddres,
    handleSelectAddress,
    categories,
    asyncCategories,
    handleCategories,
  } = useRestaurantContext()

  return (
    <Grid container css={sGrid}>
      <p css={sDescription}>
        Modyfikuj szczegóły restauracji, aktywuj kolejne typy zamówień,
        zarządzaj godzinami otwarcia oraz ustawieniami dostaw.
      </p>
      <Grid item xs={12} md={12} css={{ marginTop: '2rem' }}>
        <TextField
          required
          label='Nazwa'
          name='name'
          value={restaurantInfo.name}
          onChange={handleRestaurantInfo}
          fullWidth
          css={sInput}
        />
      </Grid>
      <Grid item xs={12}>
        <GooglePlacesAutocomplete
          required
          value={restaurantInfo.address}
          onChange={handleChangeAddres}
          onSelect={handleSelectAddress}
          label={'Adres'}
          placeholder={'ADRES'}
        />
      </Grid>
      <Grid item xs={12} css={{ [s.xs]: { marginTop: '0.5rem' } }}>
        <TextField
          label='Opis'
          name='description'
          value={restaurantInfo.description}
          onChange={handleRestaurantInfo}
          fullWidth
          css={sInput}
          multiline
          rowsMax={10}
          rows={2}
        />
      </Grid>
      <Grid item xs={12}>
        <Creatable
          label='Kategorie'
          placeholder='Kategorie'
          stateArr={categories}
          setStateArr={handleCategories}
          data={asyncCategories?.data?.categories || []}
          language='pl'
          types={categoryTypes}
        />
      </Grid>
      <ImageLogo />
      <ImageBackground />
      <StatusActive />
    </Grid>
  )
}

export default Details
