/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useLocation, useHistory } from 'react-router-dom'

import MoneyEz from '../../../helpers/MoneyEz'
import checkCorrectId from '../../../helpers/check-correct-id'
import { useError } from '../../../hooks/errors.hook'

import { useGetRestaurantMenu } from '../../hooks/useRests.queries'
import { useGetActiveOrderCart } from '../../hooks/useOrders.queries'
import useOrdersMutation from '../../hooks/useOrders.mutations'
import { ProdItemMenuList } from '../../components/ProdItem'

import { sButtonFixed } from './order-active.page'
import { Button, ButtonBorder } from '../../../components/Elements'
import { sOuter } from '../../views/RestaurantPage/RestaurantMenuSection.view'
import { Element as ElementScroll } from 'react-scroll'
import { s } from '../../../style'
import { Grid } from '@material-ui/core'
import NavRestMenu from '../../views/RestaurantPage/components/NavRestMenu'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import PageLoader from '../../../components/PageLoader/PageLoader'
import { useTopNavContext } from '../../../context/topnav.context'

export default () => {
  const { setAnimBack } = useTopNavContext()
  const menuParams = useLocation().search
  const { restId, orderId } = getData(menuParams)
  const history = useHistory()
  const { errorHandling } = useError()

  const { data, loading, error } = useGetRestaurantMenu(restId)
  const orderFetch = useGetActiveOrderCart(orderId)
  const {
    handleAddProductToActive,
    handleDeleteProductFromActive,
    handleSendActiveOrderToRest,
  } = useOrdersMutation()

  if (!(checkCorrectId(restId) && checkCorrectId(orderId))) {
    history.push('/orders')
    return null
  }
  if (error || orderFetch.error) {
    errorHandling(error || orderFetch.error)
    return null
  }
  if (loading || orderFetch.loading) {
    return <PageLoader />
  }

  const menuSections = data?.ezcRestaurant?.menuSections
  if (!menuSections) return null
  const order = orderFetch.data?.ezcOrderActive

  const onSend = () => handleSendActiveOrderToRest({ orderId })

  return (
    <React.Fragment>
      <TopNav
        isScrolledAlready
        title={data?.ezcRestaurant?.name}
        photo={data?.ezcRestaurant?.avatarUrl}
        textCss={{ maxWidth: `calc(100vw - ${48 + 32 + 30 + 36}px)` }}
        extraCssOuter={{ boxShadow: 'none' }}>
        <ButtonBorder
          extraCss={{ backgroundColor: 'white' }}
          action={() => {
            setAnimBack(true)
            history.push(`/order-active/${orderId}`)
          }}>
          Wróć
        </ButtonBorder>
      </TopNav>
      <NavRestMenu items={menuSections.map((section) => section.name)} />

      <div css={[sOuter, { marginTop: '4rem' }]}>
        {menuSections.map((section, sectionId) => (
          <ElementScroll
            name={encodeURIComponent(section.name)}
            key={sectionId}>
            <div
              key={sectionId}
              css={{ paddingTop: '3rem', [s.md]: { paddingTop: '4rem' } }}>
              <p
                css={{
                  fontSize: '1.5rem',
                  fontWeight: 800,
                  whiteSpace: 'nowrap',
                  letterSpacing: '-0.05em',
                  paddingLeft: '1rem',
                  marginBottom: '1.5rem',
                  [s.xs]: { fontSize: '1.5rem' },
                  [s.md]: { paddingLeft: '2rem' },
                }}>
                {section.name}
              </p>
              <Grid container>
                {section.products.map((product, productId) => {
                  const onAddProduct = () => {
                    handleAddProductToActive({ orderId, prodId: product._id })
                  }
                  const onDeleteProduct = () => {
                    handleDeleteProductFromActive({
                      orderId,
                      prodId: product._id,
                    })
                  }
                  return (
                    <ProdItemMenuList
                      id={productId}
                      key={productId}
                      data={product}
                      handleAddProduct={onAddProduct}
                      handleDeleteProduct={onDeleteProduct}
                      productInOrder={order?.orderDetails[0].productsInCart.find(
                        (orderProd) => orderProd.prodId._id === product._id
                      )}
                      orderI={orderId}
                      noLink
                    />
                  )
                })}
              </Grid>
            </div>
          </ElementScroll>
        ))}

        <div css={sButtonFixed}>
          <Button w100 w100Desktop action={onSend} buttonTransparent>
            Domów + {getAddedProductsSum(order)}
          </Button>
        </div>
      </div>
    </React.Fragment>
  )
}

function getData(params) {
  const arr = params.slice(1).split('&')

  const q = 'restId='
  const r = arr.find((el) => el.includes(q))
  const restId = r && r.slice(q.length)

  const w = 'orderId='
  const o = arr.find((el) => el.includes(w))
  const orderId = o && o.slice(w.length)

  return {
    restId,
    orderId,
  }
}

function getAddedProductsSum(order) {
  const productSumNumber = order.orderDetails[0].productsInCart.reduce(
    (a, b) => MoneyEz(b.prodId.price).multiply(b.amount).add(a).getAmount(),
    0
  )

  const q = MoneyEz(productSumNumber).getAmountWithFullPrecisionAndZl()

  return q
}
