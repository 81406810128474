/** @jsx jsx */
import React, { useState, useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars


export default ({ children, fontSize }) => (
  <h4 css={{
    fontSize: fontSize || 18,
    marginTop: 16
  }}>
    {children}
  </h4>
)