/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'
import { useError } from '../../../../hooks/errors.hook'

import { useQuery } from '@apollo/client'
import { GET_USER } from '../../../graphql/user.graphql'

import { Grid, ButtonBase } from '@material-ui/core'
import { sOuter, sGrid, sBottomLinks } from '../../../../style/style'
import { ButtonBorder, Badge } from '../../../../components/Elements'
import Headline from '../../../../components/Headline/Headline'
import { Profile } from '../Account.page'

import isAdminHelper from '../../../../helpers/isAdmin'
import MoneyEz from '../../../../helpers/MoneyEz'
import { Tile } from '../wallet/Wallet.page'

import { colors, s, alpha } from '../../../../style'
import { TopNav } from '../../../../components/TopNavFixed/TopNavFixed'

const Info = () => {
  const { errorHandling } = useError()
  const { data, loading, error } = useQuery(GET_USER)

  if (loading) return <div />
  if (error) {
    errorHandling(error)
    return null
  }
  const {
    user: { email, personal, isRestaurateur, _id, ...user },
  } = data

  const coinsFree = MoneyEz(user.coinsBalance)
    .subsctract(user.coinsBlocked)
    .getAmountWithFullPrecision()
    .split('.')

  const coinsBlocked = MoneyEz(user.coinsBlocked)
    .getAmountWithFullPrecision()
    .split('.')

  const isAdmin = _id ? isAdminHelper(_id) : false

  return (
    <React.Fragment>
      <TopNav
        title={'Informacje'}
        subtitle={`- ${personal.name} ${personal.surname}`}
      />
      <div css={sOuter}>
        <Headline
        // itemChildren={<ButtonBorder link={`/account`}>Konto</ButtonBorder>}
        >
          Informacje
        </Headline>
        <Grid container css={[sGrid]}>
          <Profile
            actualBalance={coinsFree}
            personal={personal || {}}
            isRestaurateur={isRestaurateur}
            isAdmin={isAdmin}
            linkDisabled
          />
          <Tile
            subtitle={'Dostępne Środki'}
            version='BALANCE'
            coins={coinsFree}
          />
          <Tile subtitle={'Blokady'} version='BLOCK' coins={coinsBlocked} />
          <InfoContainer
            left
            extraCss={{ [s.sm_down]: { marginTop: '0.5rem' } }}>
            <div css={{ marginBottom: 16 }}>
              <Header>Numer ID klienta</Header>
              <Paragraph>{_id}</Paragraph>
            </div>
            <div>
              <Header>Imię i nazwisko</Header>
              <Paragraph>
                {personal.name} {personal.surname}
              </Paragraph>
            </div>
          </InfoContainer>

          <InfoContainer right>
            <Header>Adres email</Header>
            <Paragraph>{email}</Paragraph>
          </InfoContainer>

          <Grid item xs={12} css={sBottomLinks}>
            <ButtonBorder link={'/account/settings'}>Ustawienia</ButtonBorder>
            {/* <ButtonBorder link={'/account/wallet'}>Portfel</ButtonBorder>
            <ButtonBorder link={'/account/notifications'}>
              Powiadomienia
            </ButtonBorder> */}
            <ButtonBorder link={'/privacy'}>Polityka Prywatności</ButtonBorder>
            <ButtonBorder link={'/terms'}>Regulamin</ButtonBorder>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}

const Header = ({ children }) => (
  <h6
    css={{
      opacity: 0.6,
      fontSize: 12,
      fontWeight: 500,
      marginBottom: '0.5rem',
    }}>
    {children}
  </h6>
)

const Paragraph = ({ children }) => <p css={{ fontWeight: 800 }}>{children}</p>

const InfoContainer = ({ children, left, right, extraCss }) => (
  <Grid item xs={12} md={6}>
    <div
      css={[
        {
          borderRadius: 16,
          padding: '1rem',
          marginBottom: '1rem',
          [s.sm]: { padding: '2rem', marginBottom: '1.5rem' },
          background: `linear-gradient(to right, ${colors.gray100}, ${colors.gray200})`,
        },
        left && { [s.md]: { marginRight: '0.75rem' } },
        right && { [s.md]: { marginLeft: '0.75rem' } },
        extraCss,
      ]}>
      {children}
    </div>
  </Grid>
)

export default Info
