import { useQuery } from '@apollo/client'

import {
  GET_EZC_ADMIN_CHAT,
  GET_ADMIN_ALL_ACTIVE_CHATS
} from '../graphql/chat.graphql'


export const useGetAdminAllActiveChats = () => useQuery(GET_ADMIN_ALL_ACTIVE_CHATS)

export const useGetEzcAdminChat = () => useQuery(GET_EZC_ADMIN_CHAT)
