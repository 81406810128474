/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import {
  FindRestaurant,
  Button,
  InputAmount,
  PopupContainer,
  SearchResultsRestaurants
} from '../../components'
import useChargeRestaurant from './useChargeRestaurant'


const EzAdminChargeRestaurant = () => {
  const {
    // fetching
    getRestaurant,
    data,
    loading,
    // state data
    chargeData,
    onRestaurantChoose,
    onChangeAmount,
    // popup
    isPopupOpen,
    onPopupClose,
    // async fn
    onChargeRestaurant
  } = useChargeRestaurant()

  const ItemHelperComponent = ({ restId }) => (
    <Button onClick={() => onRestaurantChoose(restId)}>
      Wybierz
    </Button>
  )

  return (
    <React.Fragment>
      <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <FindRestaurant getRestaurant={getRestaurant} loading={loading} />
        <SearchResultsRestaurants
          restaurants={data?.adminGetRestaurantByName}
          ItemHelperComponent={ItemHelperComponent}
        />
      </div>

      <PopupContainer
        isOpen={isPopupOpen}
        onClose={onPopupClose}
        headerText="Podaj kwotę "
        btnText="Obciążyć konto restauracji"
        btnOnClick={onChargeRestaurant}
      >
        <div css={{ minWidth: 320, maxWidth: 560, width: '100%' }}>
          <InputAmount
            value={chargeData.amount}
            onChange={onChangeAmount}
            label="Podaj kwotę"
          />
        </div>
      </PopupContainer>
    </React.Fragment>
  )
}


export default EzAdminChargeRestaurant