/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'

import {
  consumptionTypeIcons,
  consumptionTypeNames,
} from '../../../../constants/types'
import {
  ORDER_STATUSES,
  ORDER_STATUS_COLOR,
} from '../../../../constants/statuses'
import { krakenUrls } from '../../../../constants/s3-files'
import { dateFc } from '../../../../helpers/date-format'

import { Grid, ButtonBase, Hidden } from '@material-ui/core'
import { s, colors, alpha } from '../../../../style'
import {
  sImage,
  sText,
} from '../../../../components/Elements/Collapsable/Collapsable'
import { Bullet, StatusLarge, Badge } from '../../../../components/Elements'

export default ({
  id,
  orderId,
  date,
  time,
  consumptionType,
  restaurantAvatar,
  restaurantName,
  priceSum,
  paidSum,
  isPaid,
  status,
  needAttention,
  isOrderHistory,
  isInvoice,
  w100,
  waiter,
  shortId,
  link,
  waiterHistory,
}) => {
  return (
    <Grid
      item
      xs={12}
      md={waiter ? 12 : w100 ? 12 : 6}
      lg={waiter ? 6 : w100 ? 12 : 6}
      css={{
        animation: `fadeIn 0.3s ease-in-out`,
      }}>
      <Link
        to={
          link
            ? link
            : waiter
            ? `/waiter/order/${orderId}`
            : isOrderHistory
            ? `/orders-history/${orderId}`
            : `/order-active/${orderId}`
        }
        css={[
          sContainerOuter,
          id % 2 === 1 && !waiter && { [s.md]: { marginLeft: '0.75rem' } },
          id % 2 === 0 && !waiter && { [s.md]: { marginRight: '0.75rem' } },
          id % 2 === 1 && waiter && { [s.lg]: { marginLeft: '0.75rem' } },
          id % 2 === 0 && waiter && { [s.lg]: { marginRight: '0.75rem' } },
          { marginBottom: '1.5rem', [s.sm_down]: { marginBottom: '1rem' } },
        ]}>
        <ButtonBase
          css={[
            sContainerInner,
            !isPaid && { [s.md]: { borderRadius: '1rem 1rem 0 0' } },
          ]}>
          <div
            css={[
              sTopContainer,
              waiter && !waiterHistory && sTopContainerWaiter,
            ]}>
            <div css={[sLeft, waiter && !waiterHistory && sLeftWaiter]}>
              <div
                css={[
                  sImage,
                  { position: 'relative' },
                  !restaurantAvatar && { backgroundColor: colors.gray300 },
                ]}>
                {restaurantAvatar && (
                  <img
                    src={krakenUrls.small + restaurantAvatar}
                    alt={restaurantName}
                    loading='lazy'
                  />
                )}
                <Badge
                  small
                  left
                  color={colors.orange}
                  extraCss={{
                    boxShadow: `0px 0px 0px 3px ${colors.gray200}`,
                  }}
                  show={needAttention}
                  showAnimation
                />
              </div>
              <div css={sText}>
                <p css={[sText.title, sText.oneLiner, !waiter && sLimitText]}>
                  {waiter ? shortId : restaurantName}
                  {waiter && (
                    <span
                      css={{
                        // color: colors.gray600,
                        // fontWeight: 500,
                        opacity: 0.25,
                      }}>
                      &nbsp;-&nbsp;{restaurantName}
                    </span>
                  )}
                </p>
                <p css={[sText.subtitle, sText.oneLiner]}>
                  {!waiter && (
                    <React.Fragment>
                      <Hidden smUp>{dateFc(date)}</Hidden>
                      <Hidden xsDown>{dateFc(date, time)}</Hidden>
                    </React.Fragment>
                  )}
                  {waiter && dateFc(date, time)}
                  <Bullet />
                  <>
                    {`${priceSum.toFixed(2)}`.slice(-2) === '00'
                      ? priceSum.toFixed(0)
                      : priceSum.toFixed(2)}{' '}
                    zł
                  </>
                  <Bullet />
                  <Hidden lgUp>{consumptionTypeIcons[consumptionType]}</Hidden>
                  <Hidden mdDown>
                    {consumptionTypeNames[consumptionType]}
                  </Hidden>
                </p>
              </div>
            </div>
            <div
              css={[
                {
                  display: 'flex',
                },
                waiter &&
                  !waiterHistory && {
                    [s.xs]: { justifyContent: 'flex-end', width: '100%' },
                  },
              ]}>
              {waiter && isInvoice && !waiterHistory && (
                <StatusLarge
                  color={colors.gray600}
                  extraCss={{ marginRight: '0.5rem' }}>
                  Faktura
                </StatusLarge>
              )}
              <StatusLarge color={ORDER_STATUS_COLOR[status]}>
                {ORDER_STATUSES[status]}
              </StatusLarge>
            </div>
          </div>
          {needAttention && (
            <div
              css={[
                sIsNotPaid,
                {
                  background: `linear-gradient(to right, ${colors.orange}, ${colors.orange800})`,
                },
                !isPaid && {
                  borderRadius: 0,
                  [s.md]: {
                    padding: '0.75rem 2rem 1.5rem',
                    paddingLeft: '6rem',
                    left: 0,
                    bottom: 0,
                    position: 'relative',
                  },
                },
              ]}>
              Wymaga uwagi
            </div>
          )}
          {!isPaid && (
            <div css={sIsNotPaid}>
              <div>Nieopłacone</div>
              {/* // paidSum here doesnt work */}
              {/* {paidSum > 0 && paidSum !== priceSum ? (
                <div css={{ opacity: 0.5 }}>
                  {priceSum - paidSum} / {priceSum} PLN
                </div>
              ) : (
                <div css={{ opacity: 0.5 }}>{priceSum} PLN</div>
              )} */}
            </div>
          )}
        </ButtonBase>
      </Link>
    </Grid>
  )
}

const sContainerOuter = {
  display: 'flex',
}

export const sContainerInner = {
  position: 'relative',
  transition: 'background-color 0.3s ease-out',
  backgroundColor: colors.gray200,
  borderRadius: '1rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: '100%',
  '&:hover': {
    [s.hover]: {
      backgroundColor: colors.gray300.concat(alpha[100]),
      img: {
        transform: 'scale(1.1)',
        filter: 'brightness(1.1)',
      },
    },
  },
}

export const sLeft = {
  display: 'flex',
  marginRight: '1rem',
  [s.sss]: {
    marginRight: 0,
  },
}

export const sLeftWaiter = {
  [s.xs]: {
    marginBottom: '1rem',
  },
}

export const sTopContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '1rem',
  [s.md]: {
    padding: '2rem',
  },
}

export const sTopContainerWaiter = {
  [s.xs]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}

const sIsNotPaid = {
  display: 'flex',
  justifyContent: 'space-between',
  color: '#ffffff',
  alignItems: 'center',
  background: `linear-gradient(to right, ${colors.red600}, ${colors.red800})`,
  width: '100%',
  borderRadius: '0 0 16px 16px',
  padding: '0 1rem',
  textTransform: 'uppercase',
  fontWeight: 800,
  letterSpacing: '0.2em',
  height: 24,
  fontSize: '0.75rem',
  [s.md]: {
    padding: '0 2rem',
    paddingLeft: '6rem',
    left: 0,
    bottom: -12,
    position: 'absolute',
  },
  [s.sm_down]: {
    paddingLeft: 16 + 12 + 40,
  },
}

export const sLimitText = {
  [s.xs]: {
    maxWidth: 'calc(100vw - 243px)',
  },
  [s.sss]: {
    maxWidth: 'calc(100vw - 212px)',
  },
}
