import React from 'react'
import { useProductContext } from '../context/product.provider'
import OpenningHours from '../../../components/OpenningHours/OpenningHours.component'



export default () => {
  const {
    availableHours,
    handleAvailableHours
  } = useProductContext()

  return (
    <OpenningHours
      openningHours={availableHours}
      handleDayOnChange={handleAvailableHours}
      header="Kiedy produkt będzie dostępny?"
      paragraphs={[
        'Jeśli produkt nie ma ograniczeń czasowych, pozostaw od 0:00 do 24:00.',
        'Jeśli produkt któregoś dnia nie jest dostępny, ustaw od 0:00 do 0:00.'
      ]}
    />
  )
}