/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars


const NameHeader = ({ name }) => <h2 css={stylesHeader}>{name}</h2>
const stylesHeader = {
  color: '#292929',
  fontWeight: 800,
  fontSize: 30,
  letterSpacing: '-.15px'
}

export default NameHeader