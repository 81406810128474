import { useMutation } from '@apollo/client'
import { useError } from '../../hooks/errors.hook'
import {
  CHANGE_IS_RESTAURATEUR
} from '../graphql/users.graphql'


const useUsersMutations = () => {
  const { errorHandling } = useError()
  const [adminChangeIsRestaurateur] = useMutation(CHANGE_IS_RESTAURATEUR)

  /**
   * 
   * @param {String} userId 
   * @param {Boolean} isRestaurateur 
   * @returns {CommonResponse|undefined} if error returns undefined. 
   */
  const handleChangeIsRestaurateur = async (userId, isRestaurateur) => {
    try {
      const variables = { userId, isRestaurateur }
      const res = await adminChangeIsRestaurateur({ variables })
      return res
    } catch (err) { errorHandling(err) }
  }

  return {
    handleChangeIsRestaurateur
  }
}

export default useUsersMutations