/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import LocationOnIcon from '@material-ui/icons/LocationOn'
import useDeliveryInfo from '../../../hooks/useDeliveryInfo'
import Map from '../../../components/Map/Map'
import { Button, Bullet, Collapsable } from '../../../../components/Elements'
import Spinner from '@material-ui/core/CircularProgress'
import { s } from '../../../../style'

const Location = ({ id, address, location }) => {
  return (
    <Collapsable
      right
      noPadding
      oneLiner
      title={'Lokalizacja'}
      description={
        <div css={{ overflowX: 'scroll' }}>
          {id && (
            <>
              <DistanceToUser restId={id} />
              {` km`}
              <Bullet />
            </>
          )}
          {address &&
            address
              .split(',')
              .filter((_, id) => id === 1)
              .reverse()}
          <Bullet />
          {address &&
            address
              .split(',')
              .filter((_, id) => id === 0)
              .reverse()}
        </div>
      }
      icon={<LocationOnIcon />}>
      <>
        {location && <Map location={location} />}
        <div css={{ padding: '1rem', [s.md]: { padding: '2rem' } }}>
          <p
            css={{
              textAlign: 'center',
              fontWeight: 800,
              fontSize: '1.25rem',
              letterSpacing: '-0.03em',
              [s.xs]: {
                fontSize: '1.125rem',
                paddingTop: '1rem',
              },
            }}>
            {address}
          </p>
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-evenly',
              padding: '2rem 0',
              '.stats-location-item': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              },
              '.stats-location-title': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.5rem',
                fontWeight: 800,
              },
              '.stats-location-title-unit': {
                fontSize: '0.875rem',
                padding: '6px 0px 0px 4px',
              },
              '.stats-location-subtitle': {
                fontSize: '0.75rem',
                opacity: 0.6,
              },
            }}>
            <div className='stats-location-item'>
              <div className='stats-location-title'>
                <DistanceToUser restId={id} />
                <span className='stats-location-title-unit'>km</span>
              </div>
              <div className='stats-location-subtitle'>dystans</div>
            </div>
            <div className='stats-location-item'>
              <div className='stats-location-title'>
                <DistanceToUser byCar restId={id} />
                <span className='stats-location-title-unit'>min</span>
              </div>
              <div className='stats-location-subtitle'>samochodem</div>
            </div>
            <div className='stats-location-item'>
              <div className='stats-location-title'>
                <DistanceToUser onFoot restId={id} />
                <span className='stats-location-title-unit'>min</span>
              </div>
              <div className='stats-location-subtitle'>na piechotę</div>
            </div>
          </div>
          <Button
            w100
            external
            link={`https://www.google.com/maps/dir//${address}`}>
            NAWIGUJ
          </Button>
        </div>
      </>
    </Collapsable>
  )
}

export const DistanceToUser = ({ restId, onFoot, byCar }) => {
  const { distanceToUser, loading } = useDeliveryInfo({ restId })
  if (loading) return <Spinner size={'0.75rem'} thickness={8} />
  if (onFoot) return <>{(distanceToUser / 1.4 / 60).toFixed(0)}</>
  if (byCar) return <>{(distanceToUser / 10 / 60).toFixed(0)}</>
  return <>{(distanceToUser / 1000).toFixed(2)}</>
}

export default Location
