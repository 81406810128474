import { gql } from '@apollo/client'

export const GET_CATEGORIES = gql`
  query categories {
    categories {
      _id
      name
      order
      type
      photo
      icon
    }
  }
`

export const GET_INGREDIENTS = gql`
  query ingredients {
    ingredients {
      _id
      name
      order
      type
      photo
      icon
    }
  }
`

export const GET_PRODUCT = gql`
  query ezcProduct($prodId: ID!) {
    ezcProduct(prodId: $prodId) {
      _id
      name
      description
      consumptionTypes
      categories {
        _id
        name
      }
      ingredients {
        _id
        name
      }
      flavors
      cookTime
      cookMethod
      restaurant {
        _id
        name
        avatarUrl
        address
        products {
          _id
          name
          photoUrl
          categories {
            name
          }
          price
        }
      }
      price
      vat
      photoUrl
      isArchived
      availableHours {
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
        sun {
          open
          close
        }
      }
    }
  }
`

export const GET_PRODUCTS = gql`
  query ezrProducts($restId: ID!) {
    ezrProducts(restId: $restId) {
      _id
      name 
      price
      photoUrl
      categories {
        _id
        name
      }
      isArchived
    }
  }
`