/** @jsx jsx */
import React, { useState, useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory } from 'react-router-dom'
import { colors, s, alpha, globals } from '../../style'
import { ButtonBase } from '@material-ui/core'
import { useTopNavContext } from '../../context/topnav.context'
import { krakenUrls } from '../../constants/s3-files'

export const TopNav = ({
  title,
  subtitle,
  children,
  image,
  icon,
  photo,
  textCss,
  isScrolledAlready,
  extraCssOuter,
  disable,
}) => {
  const { setData } = useTopNavContext()
  useEffect(() => {
    setData({
      title,
      subtitle,
      content: children || null,
      image,
      photo,
      textCss,
      icon,
      isScrolledAlready,
      extraCssOuter,
      disable,
    })
  }, [])

  return null
}

const TopNavFixed = () => {
  const history = useHistory()
  const {
    scrolled: isScrolled,
    data,
    setScrolled,
    setAnimBack,
  } = useTopNavContext()

  const {
    title,
    subtitle,
    image,
    content,
    photo,
    textCss,
    icon,
    isScrolledAlready,
    extraCssOuter,
    disable,
  } = data

  useEffect(() => {
    if (isScrolledAlready) setScrolled(true)
  })

  return (
    <div>
      <nav
        css={[
          sContainer,
          isScrolled && sContainerScrolled,
          extraCssOuter,
          disable && { opacity: 0, pointerEvents: 'none' },
        ]}>
        <div css={sInner}>
          <div
            css={[
              {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
              },
              // extraCssInner,
            ]}>
            <ButtonBase css={globals.sIcon}>
              <ArrowBackIcon
                onClick={() => {
                  setAnimBack(true)
                  history.goBack()
                }}
                fontSize='inherit'
              />
            </ButtonBase>
            <div
              css={[sChildren, isScrolled ? { opacity: 1 } : { opacity: 0 }]}>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}>
                <div css={{ display: 'flex', alignItems: 'center' }}>
                  {(image || photo) && (
                    <div
                      css={{
                        width: 32,
                        height: 32,
                        borderRadius: 16,
                        boxShadow: '0px 4px 4px rgba(0,0,0,0.08)',
                      }}>
                      <img
                        loading='lazy'
                        src={photo ? krakenUrls.small + photo : image}
                        alt={title}
                        css={{
                          borderRadius: 16,
                          objectFit: 'cover',
                          objectPosition: 'center center',
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    </div>
                  )}
                  {icon && (
                    <span
                      css={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 32,
                        height: 32,
                      }}>
                      {icon}
                    </span>
                  )}
                  <span
                    css={[
                      {
                        margin: '0 12px',
                        overflow: 'hidden',
                        overflowX: 'scroll',
                        whiteSpace: 'nowrap',
                        // textOverflow: 'ellipsis',
                        maxWidth: `calc(100vw - 210px)`,
                        [s.xs]: { fontSize: '0.875rem' },
                      },
                      { maxWidth: 'calc(100vw - 7rem)' },
                      image && { maxWidth: `calc(100vw - 240px)` },
                      textCss,
                    ]}>
                    {title} <span css={{ opacity: 0.3 }}>{subtitle}</span>
                  </span>
                </div>
              </div>
            </div>
            {content && (
              <div className={isScrolled ? `scrolled` : ``}>{content}</div>
            )}
          </div>
        </div>
      </nav>
    </div>
  )
}

const sContainer = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  zIndex: 1000,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [s.sm_down]: {
    height: globals.topnavHeight.mobile,
  },
  height: globals.topnavHeight.desktop,
  backgroundColor: 'transparent',
  boxShadow: `0px 4px 24px ${colors.text.concat(alpha[0])}`,
  transition:
    'background-color 0.3s ease-out, box-shadow 0.3s ease-out, opacity 0.3s ease-out',
}

const sContainerScrolled = {
  backgroundColor: '#ffffff',
  boxShadow: `0px 4px 24px ${colors.text.concat(alpha[8])}`,
}

const sChildren = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: 12,
  transition: 'opacity 0.3s ease-out',
  fontSize: '1rem',
  fontWeight: 800,
  width: '100%',
}

const sInner = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxWidth: globals.maxWidthMedium,
  width: '100vw',
  height: '100%',
  padding: '0 1.5rem',
  boxSizing: 'border-box',
}

export default TopNavFixed
