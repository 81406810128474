const allProductsOrder = [
  'WAITING_FOR_ACCEPT',
  'WAITING_FOR_PAYMENT',
  'WAITING_FOR_ACCEPT_BY_USER',
  'ACCEPTED',
  'CONFIRMED',
  'PREPARING',
  'DONE',
  'FINISHED',
  'DECLINED_BY_REST',
  'DECLINED_BY_CLIENT',
  'ANNULED',
  'AUTO_ANNULED_BECAUSE_OF_NO_PAYMENT',
]

const sortProductsByStatus = (products) =>
  allProductsOrder
    .map((order) =>
      products.filter((prod) => [...prod.status].pop().name === order)
    )
    .flat()

export default sortProductsByStatus
