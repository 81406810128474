import React from 'react'
import { Button as ButtonMUI } from '@material-ui/core'


const Button = ({ children, ...otherProps }) =>
  <ButtonMUI
    variant="outlined"
    {...otherProps}
  >
    {children}
  </ButtonMUI>

export default Button