import { useMutation } from '@apollo/client'
import { useError } from '../../hooks/errors.hook'

import { UPDATE_REST, CREATE_REST, CHANGE_WAITER_PASSWORD } from '../graphql/mutations.graphql'
import { GET_RESTAURANT, GET_RESTAURANTS } from '../graphql/restaurants.graphql'
import { GET_CATEGORIES } from '../graphql/products.graphql'

import { useUploadImg } from '../../hooks/useUploadImg.hook'


const useRestaurantMutations = () => {

  const { errorHandling } = useError()
  const { uploadImgAndGetURL } = useUploadImg()

  const [restUpdate] = useMutation(
    UPDATE_REST,
    {
      refetchQueries: [
        { query: GET_RESTAURANTS },
        { query: GET_CATEGORIES }
      ]
    }
  )

  const [restCreate] = useMutation(
    CREATE_REST,
    {
      refetchQueries: [
        { query: GET_RESTAURANTS },
        { query: GET_CATEGORIES }
      ]
    }
  )

  const [restNewPasswordWaiterDashboard] = useMutation(CHANGE_WAITER_PASSWORD)

  async function handleRestUpdate(data, cb, errCb) {
    const restId = localStorage.getItem('activeRestaurant')
    const { input, newCategories, inputMenu, logoFile, backgroundFile } = data

    try {
      const s3LogoUrl = await uploadImgAndGetURL(logoFile, 'REST_AVATAR')
      const s3BackgroundUrl = await uploadImgAndGetURL(backgroundFile, 'REST_BACKGROUND')

      await restUpdate({
        variables: {
          restId,
          input,
          newCategories,
          inputMenu,
          avatarUrl: s3LogoUrl ? s3LogoUrl.data.getS3ImgUrlLink.imgName : null,
          backgroundImgUrl: s3BackgroundUrl ? s3BackgroundUrl.data.getS3ImgUrlLink.imgName : null
        },
        refetchQueries: [{ query: GET_RESTAURANT, variables: { restId } }]
      })

      if (cb && typeof cb === 'function') cb()

    } catch (err) {
      errorHandling(err)
      if (typeof errCb === 'function') errCb()
    }
  }

  async function handleRestCreate(data, cb, errCb) {
    const { input, newCategories, logoFile, backgroundFile } = data

    try {
      const s3LogoUrl = await uploadImgAndGetURL(logoFile, 'REST_AVATAR')
      const s3BackgroundUrl = await uploadImgAndGetURL(backgroundFile, 'REST_BACKGROUND')

      const res = await restCreate({
        variables: {
          input,
          newCategories,
          avatarUrl: s3LogoUrl ? s3LogoUrl.data.getS3ImgUrlLink.imgName : null,
          backgroundImgUrl: s3BackgroundUrl ? s3BackgroundUrl.data.getS3ImgUrlLink.imgName : null
        }
      })

      if (cb && typeof cb === 'function') cb()

      return res

    } catch (err) {
      errorHandling(err)
      if (typeof errCb === 'function') errCb()
    }
  }

  async function handleChangeWaiterPassword(data, cb, errCb) {
    const restId = localStorage.getItem('activeRestaurant')
    const { password } = data
    try {
      await restNewPasswordWaiterDashboard({ variables: { restId, password } })

      if (cb && typeof cb === 'function') cb()

    } catch (err) {
      errorHandling(err)
      if (typeof errCb === 'function') errCb()
    }
  }


  return {
    handleRestUpdate,
    handleRestCreate,
    handleChangeWaiterPassword
  }
}

export default useRestaurantMutations