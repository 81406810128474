/** @jsx jsx */
import React, { useEffect, useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { s, colors } from '../../../../style'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import { Collapsable, Bullet } from '../../../../components/Elements'
import { numberOfDayJS } from '../../../../constants/daysOfWeek'
import { DayRow, minToHour, hourOrMin } from './Availability'
import useDeliveryInfo from '../../../hooks/useDeliveryInfo'
import Spinner from '@material-ui/core/CircularProgress'

const Delivery = ({ restId, isDelivery, isRestActive }) => {
  const now = new Date()
  const day = now.getDay()
  const todaysDay = numberOfDayJS[day]
  const tomorrowsDay = numberOfDayJS[(day + 1) % 7]
  const nowTime = now.getHours() * 60 + now.getMinutes()

  // const {
  //   availableHours,
  //   deliveryCost,
  //   deliveryRadius,
  //   distanceToUser,
  //   freeDeliveryAmount,
  //   minAmount,
  //   loading
  // } = useDeliveryInfo({ restId })

  const deliveryData = useDeliveryInfo({ restId })

  return (
    <Collapsable
      right
      noPadding
      oneLiner
      title={'Dostawa'}
      description={
        deliveryData.loading ? (
          <Spinner size={'0.75rem'} thickness={8} />
        ) : (
          <RenderDeliveryDescription
            data={deliveryData}
            now={nowTime}
            todaysDay={todaysDay}
            tomorrowsDay={tomorrowsDay}
            isDelivery={isDelivery}
            isRestActive={isRestActive}
          />
        )
      }
      icon={<LocalShippingIcon />}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '1rem 0',
          [s.sm]: {
            padding: '0 0 1.5rem',
          },
        }}>
        {isRestActive ? (
          isDelivery ? (
            <>
              {!deliveryData.loading && (
                <>
                  <RenderDeliveryDetails data={deliveryData} />
                  {Object.entries(deliveryData.availableHours)
                    .filter((key, id) => id > 0)
                    .map(([day, hour], id) => (
                      <DayRow
                        key={id}
                        day={day}
                        hour={hour}
                        todaysDay={todaysDay}
                        type={'delivery'}
                      />
                    ))}
                </>
              )}
            </>
          ) : (
            <span
              css={{
                fontSize: '0.75rem',
                fontWeight: 800,
                textAlign: 'center',
                padding: '1rem 1rem 0rem',
                [s.xs]: { padding: '0 1rem' },
              }}>
              Zamawianie z dostawą zostało wyłączone
            </span>
          )
        ) : (
          <span
            css={{
              fontSize: '0.75rem',
              fontWeight: 800,
              textAlign: 'center',
              padding: '1rem 1rem 0rem',
              [s.xs]: { padding: '0 1rem' },
            }}>
            Restauracja została tymczasowo wyłączona
          </span>
        )}
      </div>
    </Collapsable>
  )
}

const RenderDeliveryDescription = ({
  data,
  now,
  todaysDay,
  tomorrowsDay,
  isDelivery,
  isRestActive,
}) => {
  const { availableHours, deliveryCost, deliveryRadius, distanceToUser } = data

  // console.log(data)

  // const availableHours = {
  //   mon: { open: 0, close: 1440 },
  //   tue: { open: 0, close: 1440 },
  //   wed: { open: 200, close: 600 },
  //   thu: { open: 200, close: 1400 },
  //   fri: { open: 0, close: 1440 },
  //   sat: { open: 0, close: 1440 },
  //   sun: { open: 0, close: 1440 },
  // }

  // const deliveryCost = [
  //   [1000, 5],
  //   [3000, 15],
  //   [5000, 30],
  // ]
  // const distanceToUser = 0
  // const deliveryRadius = 5000

  const [deliveryState, setDeliveryState] = useState(null) // SOON, YES, NO
  const SOON = 'SOON'
  const YES = 'YES'
  const NO = 'NO'
  const TOOFAR = 'TOOFAR'
  const INACTIVE = 'INACTIVE'

  const hours = availableHours[todaysDay]
  const tomorrow = availableHours[tomorrowsDay]

  const countCost = () => {
    if (Math.round(deliveryRadius) >= Math.round(distanceToUser))
      return deliveryCost.filter((cost) => cost[0] >= distanceToUser)[0][1]
    return null
  }

  useEffect(() => {
    if (now < hours.open) setDeliveryState(SOON)
    if (now >= hours.open && now < hours.close && hours.close > 0)
      setDeliveryState(YES)
    if (
      now < hours.close &&
      hours.close > 0 &&
      Math.round(deliveryRadius) <= Math.round(distanceToUser)
    )
      setDeliveryState(TOOFAR)
    if (now >= hours.close || hours.close === 0) setDeliveryState(NO)
    if (!isRestActive) setDeliveryState(INACTIVE)
  }, [hours, now, distanceToUser, deliveryRadius])

  if (deliveryState === INACTIVE)
    return <span css={{ color: 'red' }}>Restauracja nieaktywna</span>

  if (!availableHours || !isDelivery)
    return <span css={{ color: 'red' }}>Wyłączona</span>

  if (deliveryState === TOOFAR)
    return (
      <div
        css={{
          // maxWidth: `calc(100vw - 175px)`,
          overflowX: 'scroll',
          // [s.md_only]: { maxWidth: 282 },
        }}>
        <span css={{ color: 'red' }}>Za daleko</span>
        <Bullet />
        <span>dostawa do {deliveryRadius.toFixed(0)}m</span>
        <Bullet />
        <span>twój dystans {distanceToUser.toFixed(0)}m</span>
      </div>
    )

  return (
    <div css={{ overflowX: 'scroll' }}>
      {deliveryState === SOON && <span css={{ color: 'orange' }}>Wkrótce</span>}
      {deliveryState === YES && <span css={{ color: 'green' }}>Możliwa</span>}
      {deliveryState === NO && <span css={{ color: 'red' }}>Niemożliwa</span>}
      {(deliveryState === SOON || deliveryState === YES) && (
        <>
          <Bullet />
          <>{countCost()} zł</>
        </>
      )}
      {tomorrow.close > 0 && <Bullet />}
      {deliveryState === SOON && <span>od {minToHour(hours.open)}</span>}
      {deliveryState === YES && <span>do {minToHour(hours.close)}</span>}
      {deliveryState === NO && tomorrow.close > 0 && (
        <span>do {minToHour(tomorrow.open)}</span>
      )}

      {tomorrow.close > 0 && <Bullet />}
      {/* <Bullet /> */}
      {deliveryState === SOON && <span>za {hourOrMin(hours.open - now)}</span>}
      {deliveryState === YES && (
        <span>jeszcze {hourOrMin(hours.close - now)}</span>
      )}
      {deliveryState === NO && tomorrow.close > 0 && (
        <span>za {hourOrMin(tomorrow.open + 24 * 60 - now)}</span>
      )}
    </div>
  )
}

const RenderDeliveryDetails = ({ data }) => {
  const {
    deliveryCost,
    deliveryRadius,
    distanceToUser,
    freeDeliveryAmount,
    minAmount,
  } = data

  const sRow = {
    transition: 'opacity 0.3s ease-out, background-color 0.3s ease-out',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 1.5rem',
    fontWeight: 800,
    [s.sm]: {
      padding: '1rem 2rem',
    },
    color: colors.text,
    fontSize: '0.875rem',
    opacity: 1,
    '&:hover': {
      opacity: 1,
      // backgroundColor: colors.gray300,
    },
  }

  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      {minAmount && (
        <div css={sRow}>
          <div>Dostawa możliwa</div>
          <div>od {minAmount} zł</div>
        </div>
      )}
      {freeDeliveryAmount && (
        <div css={sRow}>
          <div>Darmowa dostawa</div>
          <div>od {freeDeliveryAmount} zł</div>
        </div>
      )}
      {deliveryRadius && (
        <div css={sRow}>
          <div>Odległość dostawy</div>
          <div>do {deliveryRadius.toFixed(0)} m</div>
        </div>
      )}
      {distanceToUser && (
        <div css={sRow}>
          <div>Twoja odległość</div>
          <div>{distanceToUser.toFixed(0)} m</div>
        </div>
      )}
      {deliveryCost.map((cost, id) => (
        <div css={sRow} key={id}>
          <div>Do {cost[0]} metrów</div>
          <div>{cost[1]} zł</div>
        </div>
      ))}
    </div>
  )
}

export default Delivery
