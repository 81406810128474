/** @jsx jsx */
import React, { useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
// import Spinner from '../../../../components/Spinner'
import { useError } from '../../../../hooks/errors.hook'
import { useAuthContext } from '../../../../context/auth.context'
// import { ORDER_STATUSES } from '../../../../constants/statuses'

import { useGetActiveOrders } from '../../../hooks/useOrders.queries'
import { useActiveOrdersListener } from '../../../context/ActiveOrdersListener/ActiveOrdersListener.context'

import SkeletonOrder from '../../../../components/SkeletonOrder/SkeletonOrder'
import ActiveOrderItem from '../components/ActiveOrderItem'

import { Grid, Hidden } from '@material-ui/core'
import { ButtonBorder } from '../../../../components/Elements'
import { s } from '../../../../style'
import { sDescription, sBottomLinks } from '../../../../style/style'

const AllActiveOrdersAuth = () => {
  const { errorHandling } = useError()
  const { data, error, loading, refetch } = useGetActiveOrders()
  const { attentionOrderIds } = useActiveOrdersListener()

  useEffect(() => {
    refetch()
  }, [])

  if (error) {
    errorHandling(error)
    return null
  }

  // console.log(data)

  if (loading)
    return (
      <>
        <SkeletonOrder />
        <SkeletonOrder />
        <Hidden mdUp>
          <SkeletonOrder />
          <SkeletonOrder />
        </Hidden>
      </>
    )
  if (!data || data?.ezcOrdersActive.length < 1)
    return (
      <div css={sDescription}>
        Na ten moment nie posiadasz żadnych aktywnych Zamówień
      </div>
    )

  return (
    data.ezcOrdersActive
      .slice()
      // .sort(sortOrdersByStatus)
      .sort((a, b) => {
        const dateA = new Date(a.consumptionDate + ' ' + a.consumptionTime)
        const dateB = new Date(b.consumptionDate + ' ' + b.consumptionTime)
        return dateA - dateB
      })
      .sort((a, b) =>
        a.isPaid && !b.isPaid ? 1 : !a.isPaid && b.isPaid ? -1 : 0
      )
      .map((order, id) => (
        <ActiveOrderItem
          id={id}
          key={order._id}
          orderId={order._id}
          date={order.consumptionDate}
          time={order.consumptionTime}
          consumptionType={order.consumptionType}
          restaurantAvatar={order.restId.avatarUrl}
          restaurantName={order.restId.name}
          priceSum={order.priceSum}
          isPaid={order.isPaid}
          status={[...order.status].pop().name}
          needAttention={attentionOrderIds.includes(order._id)}
        />
      ))
  )
}

const AllActiveOrdersNoAuth = () => (
  <>
    <p css={sDescription}>
      Panel Zamówień jest widoczny tylko dla zalogowanych użytkowników
    </p>
    <Grid item xs={12} css={sBottomLinks}>
      <ButtonBorder link={'/login'}>Zaloguj</ButtonBorder>
      <ButtonBorder link={'/signup'}>Zarejestruj</ButtonBorder>
    </Grid>
  </>
)

const AllActiveOrders = () => {
  const { isAuth } = useAuthContext()
  if (isAuth)
    return (
      <>
        <AllActiveOrdersAuth />
        <Grid
          item
          xs={12}
          css={{
            display: 'flex',
            [s.sm_down]: {
              paddingTop: '0.5rem',
            },
            [s.md]: {
              paddingTop: '1.5rem',
            },
          }}>
          <ButtonBorder link={'/orders-history'}>
            Historia Zamówień
          </ButtonBorder>
          <span css={{ marginLeft: '1rem' }} />
          <ButtonBorder link={'/account/notifications'}>
            Powiadomienia
          </ButtonBorder>
        </Grid>
      </>
    )
  return <AllActiveOrdersNoAuth />
}

export default AllActiveOrders
