/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import Spinner from '../../../components/Spinner'
import { useApolloClient } from '@apollo/client'
import { Grid, Hidden } from '@material-ui/core'
import { Input } from '../../../EZC/views/Auth/components'
import { s } from '../../../style'
import {
  sBottomLinks,
  sContainerOuter,
  sDescription,
  sGrid,
  sOuter,
  sPaddingTop,
} from '../../../style/style'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import Headline from '../../../components/Headline/Headline'
import { ButtonBorder, Button } from '../../../components/Elements'
import { useWaiterAuthContext } from '../../context/auth-waiter.context'
import useAuth from '../../hooks/useAuth.mutations'
import { toast } from 'react-toastify'

const initValues = {
  login: '',
  password: '',
}

const LoginPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [inputValues, setInputValues] = useState(initValues)
  const client = useApolloClient()
  const { waiterLogin } = useWaiterAuthContext()
  const { handleLogin } = useAuth()

  const onChange = (e) =>
    setInputValues({ ...inputValues, [e.target.name]: e.target.value })

  const onSubmit = async () => {
    setIsLoading(true)
    const cb = () => setIsLoading(false)
    const response = await handleLogin(inputValues, cb, cb)
    if (response) {
      const {
        data: {
          waiterLogin: { token, restaurantId },
        },
      } = response
      // console.log('Apollo Client Reset Login!')
      client.cache.reset()
      client.clearStore()
      client.resetStore()
      waiterLogin(token, restaurantId)
      toast.dark('Witaj w Panelu Kelnera Eatzon! ✋')
    }
  }

  const onEnterSubmit = (e) => e.key === 'Enter' && onSubmit()

  return (
    <React.Fragment>
      <TopNav title={'Logowanie'} subtitle=' - Kelner' offset={60} />
      <div css={sOuter}>
        <Headline
        // itemChildren={<ButtonBorder link={`/account`}>Klient</ButtonBorder>}
        >
          Kelner
        </Headline>
        <Grid container css={sGrid}>
          <p css={sDescription}>
            Zaloguj się do konta kelnera Eatzon i obsługuj klientów restauracji
            z łatwością
          </p>
        </Grid>
      </div>

      <Grid container css={[sContainerOuter, sGrid, sPaddingTop]}>
        <div css={[sFormContainer]}>
          <Input
            onChange={onChange}
            name='login'
            type='text'
            value={inputValues.login}
            placeholder='Login'
            onKeyUp={onEnterSubmit}
          />

          <Input
            onChange={onChange}
            name='password'
            value={inputValues.password}
            type='password'
            placeholder='Hasło'
            onKeyUp={onEnterSubmit}
          />

          <Button w100 action={isLoading ? () => {} : onSubmit}>
            {isLoading ? (
              <Spinner size={1.5} addCss={{ color: '#fff' }} />
            ) : (
              'Zaloguj'
            )}
          </Button>
        </div>
        <Grid
          item
          xs={12}
          css={[sBottomLinks, { [s.sm_down]: { justifyContent: 'flex-end' } }]}>
          {/* <ButtonBorder link='/kelner'>Waiter Legacy</ButtonBorder> */}
          <ButtonBorder link='/rest'>Panel Restauratora</ButtonBorder>
          <ButtonBorder link='/account'>Panel Klienta</ButtonBorder>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const sFormContainer = {
  [s.sm_down]: { paddingBottom: '2rem' },
  paddingBottom: '1.5rem',
}

export default LoginPage
