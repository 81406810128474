/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

const MessagesContainer = ({ children, measuredRef }) => (
  <div
    css={{
      // height: 'calc(100vh - 60px - 58px - 50px)',
      // paddingBottom: '1.5rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      overflowY: 'scroll',
      flexGrow: 1,
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
      scrollbarWidth: 'none',
      // [s.md]: { paddingBottom: '2rem' },
    }}>
    <div
      css={{
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
          width: 0,
          height: 0,
        },
        scrollbarWidth: 'none',
      }}>
      {children}
      <div ref={measuredRef} />
    </div>
  </div>
)

export default MessagesContainer
