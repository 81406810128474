import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import useOrderMutations from '../../../hooks/useOrders.mutations'

export default ({ orderWeWorkOn }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [productsToAccept, setProductsToAccept] = useState([])

  useEffect(() => {
    const prodToOrder =
      orderWeWorkOn?.orderDetails[0]?.productsToOrder?.map((el) => ({
        ...el,
        isStatusChange: true,
      })) || []
    setProductsToAccept(prodToOrder)
  }, [orderWeWorkOn])

  const mapHelperFc = (el, _id) =>
    el._id !== _id ? el : { ...el, isStatusChange: !el.isStatusChange }

  const handleToggleProduct = (_id) => {
    setProductsToAccept(productsToAccept.map((el) => mapHelperFc(el, _id)))
  }

  let alreadyOrderedProducts = []
  if (orderWeWorkOn?.orderDetails && orderWeWorkOn.orderDetails[0]) {
    alreadyOrderedProducts = [
      ...orderWeWorkOn.orderDetails[0].productsInProcess,
      ...orderWeWorkOn.orderDetails[0].productsFinishedGood,
      ...orderWeWorkOn.orderDetails[0].productsFinishedBad,
    ]
  }

  // Async Product Handlers
  const { handleUpdateOrderStatus, handleUpdateProdStatus } =
    useOrderMutations()

  const handleAccept = () => {
    const isDeclinedProduct = !!productsToAccept.find(
      (el) => !el.isStatusChange
    )

    if (isDeclinedProduct) {
      const productStatuses = productsToAccept.map(
        ({ _id, prodId, amount, isStatusChange }) => ({
          _id,
          prodId: prodId._id,
          amount,
          isStatusChange,
        })
      )

      if (!productStatuses.find((el) => el.isStatusChange)) {
        toast.warning(
          'Należy zaakceptować conajmniej 1 produkt lub odrzucić zamówienie'
        )
        return
      }

      setIsLoading(true)
      handleUpdateOrderStatus(
        {
          orderId: orderWeWorkOn._id,
          status: 'PARTIALLY_ACCEPTED',
          productStatuses,
        },
        () => setIsLoading(false)
      )
    } else {
      setIsLoading(true)
      handleUpdateOrderStatus(
        { orderId: orderWeWorkOn._id, status: 'ACCEPTED' },
        () => setIsLoading(false)
      )
    }
  }

  const handleDecline = () => {
    setIsLoading(true)
    handleUpdateOrderStatus(
      { orderId: orderWeWorkOn._id, status: 'DECLINED_BY_REST' },
      () => setIsLoading(false)
    )
  }

  const handleAcceptProducts = () => {
    setIsLoading(true)
    const prodStatuses = {
      productsToOrder: [],
      productsInProcess: [],
    }

    let acceptedProds = []
    let declinedProds = []
    productsToAccept.forEach(({ isStatusChange, _id }) => {
      if (isStatusChange) {
        acceptedProds.push({ _id, status: 'WAITING_FOR_PAYMENT' })
      } else {
        declinedProds.push({ _id, status: 'DECLINED_BY_REST' })
      }
    })
    prodStatuses.productsToOrder = [...acceptedProds, ...declinedProds]

    handleUpdateProdStatus({ orderId: orderWeWorkOn._id, prodStatuses }, () =>
      setIsLoading(false)
    )
  }

  const handleDeclineAllProducts = () => {
    setIsLoading(true)
    let declinedProds = []
    productsToAccept.forEach(({ _id }) => {
      declinedProds.push({ _id, status: 'DECLINED_BY_REST' })
    })

    const prodStatuses = {
      productsToOrder: declinedProds,
      productsInProcess: [],
    }

    handleUpdateProdStatus({ orderId: orderWeWorkOn._id, prodStatuses }, () =>
      setIsLoading(false)
    )
  }

  return {
    isLoading,
    // order data
    orderWeWorkOn,
    alreadyOrderedProducts,
    productsToAccept,
    // sync handlers
    handleToggleProduct,
    // async handlers
    handleAccept,
    handleDecline,
    handleAcceptProducts,
    handleDeclineAllProducts,
  }
}
