import { useEffect } from 'react'
import { useGeolocationContext } from '../context/Geolocation/Geolocation.provider'
import { useGetDeliveryInfo } from './useOrders.queries'


const useDeliveryInfo = ({ restId }) => {
  if (!restId) throw new Error('useDeliveryInfo wymaga restId')

  const { geolocation } = useGeolocationContext()
  const { lat, lng } = geolocation
  const { data, loading, error, refetch } = useGetDeliveryInfo({ lat, lng }, restId)

  useEffect(() => { refetch() }, [geolocation])

  const {
    availableHours,
    deliveryCost,
    deliveryRadius,
    distanceToUser,
    freeDeliveryAmount,
    minAmount
  } = data?.ezcOrderDeliveryCost || {}

  const _deliveryCostAmount = (
    distanceToUser <= deliveryRadius
    && data?.ezcOrderDeliveryCost
    && [...deliveryCost]
      .sort(([a], [b]) => a - b)
      .find(([distance, cost]) => distance > distanceToUser)[1]
  ) || null

  return {
    data,
    loading,
    error,

    availableHours,
    deliveryCost,
    deliveryRadius,
    distanceToUser,
    freeDeliveryAmount,
    minAmount,
    _deliveryCostAmount
  }
}

export default useDeliveryInfo