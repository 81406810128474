/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core'
import { Collapsable, Row } from '../../../../components/Elements'
import FingerprintIcon from '@material-ui/icons/Fingerprint'
import StorageIcon from '@material-ui/icons/Storage'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu'
import { s } from '../../../../style'

const OrderID = ({ shortId, orderId }) => {
  return (
    <Grid item xs={12} md={6}>
      <Collapsable
        noPadding
        oneLiner
        title={'Identyfikator'}
        icon={<FingerprintIcon />}
        description={
          <>
            {shortId}
            {/* <Bullet />
            {orderId} */}
          </>
        }>
        <Row desc={'Skrót'} icon={<RestaurantMenuIcon fontSize='inherit' />}>
          {shortId}
        </Row>
        <Row desc={'Baza'} icon={<StorageIcon fontSize='inherit' />}>
          <span css={{ [s.sss]: { fontSize: '0.75rem' } }}>{orderId}</span>
        </Row>
      </Collapsable>
    </Grid>
  )
}

export default OrderID
