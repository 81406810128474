/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle'
import { Collapsable, Button } from '../../../../components/Elements'
import { CollapsableText } from '../../../../components/Elements/Collapsable/Collapsable'
import { colors, s } from '../../../../style'
import Input from '../../../views/Auth/components/Input.component'
import usePayment from '../../../views/Payment/hooks/usePayment'
import { ButtonBase, Collapse } from '@material-ui/core'

const exampleAmounts = [20, 50, 100, 200, 500]

const WalletCharge = ({ defaultValue }) => {
  const { amount, isLoading, onValueChange, onSubmit } = usePayment({
    defaultValue,
  })

  return (
    <Grid item xs={12} md={6} css={{ [s.sm_down]: { paddingTop: '1rem' } }}>
      <Collapsable
        openAlready
        left
        noPadding
        oneLiner
        title={'Doładuj Eatcoin'}
        icon={<SwapVerticalCircleIcon />}
        description={`Przelej środki na konto Eatzon`}>
        <CollapsableText>
          <div css={{ position: 'relative' }}>
            <Input
              darker
              id='amount'
              type='number'
              placeholder='Kwota doładowania'
              onChange={onValueChange}
              value={amount}
              sBaseExtra={{
                marginBottom: 0,
                'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
                  {
                    appearance: 'none',
                    margin: 0,
                  },
                'input[type=number]': {
                  MozAppearance: 'textfield',
                },
              }}
            />
            <span css={[sCurrency, !amount && { opacity: 0 }]}>PLN</span>
          </div>
          <ExampleAmounts
            items={exampleAmounts}
            onValueChange={onValueChange}
            amount={amount}
          />
          <Button
            dimmed={!amount}
            w100
            w100Mobile
            w100Desktop
            action={onSubmit}
            loading={isLoading}>
            Zapłać
          </Button>
          <Collapse in={amount}>
            <p css={{ paddingTop: '1.5rem', fontSize: '0.75rem' }}>
              Przechodząc do bramki płatniczej, potwierdzasz zapoznanie się z{' '}
              <Link
                to='/terms'
                css={{
                  color: colors.text,
                  fontWeight: 800,
                  textDecoration: 'underline',
                }}>
                Regulaminem
              </Link>
            </p>
          </Collapse>
        </CollapsableText>
      </Collapsable>
    </Grid>
  )
}

const ExampleAmounts = ({ amount, items, onValueChange }) => {
  return (
    <div css={sExampleAmount}>
      {items.map((item, index) => (
        <ButtonBase
          key={index}
          onClick={() => onValueChange(item)}
          css={[
            sExampleAmount.item,
            amount == item && {
              color: colors.white,
              backgroundColor: colors.text,
            },
          ]}>
          {item}
        </ButtonBase>
      ))}
    </div>
  )
}

const sExampleAmount = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '1rem',
  margin: '1.5rem 0',
  [s.md]: { margin: '2rem 0' },
  flexWrap: 'wrap',

  item: {
    transition: 'color 0.3s ease-out, background-color 0.3s ease-out',
    color: colors.text,
    height: 32,
    borderRadius: '1rem',
    backgroundColor: colors.gray400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.875rem',
    padding: '0 0.5rem',
    fontWeight: 800,
  },
}

const sCurrency = {
  transition: 'opacity 0.3s ease-out',
  position: 'absolute',
  top: '50%',
  right: '1.5rem',
  transform: 'translateY(-50%)',
  fontWeight: 800,
  fontSize: '0.875rem',
  color: colors.text,
  opacity: 1,
}

export default WalletCharge
