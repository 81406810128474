import React from 'react'
import TextInput from '../../../components/TextInput/TextInput'

import { useRestaurantContext } from '../context/restaurant.provider'


export default () => {
  const {
    restaurantInfo,
    handleRestaurantInfo
  } = useRestaurantContext()

  return (
    <TextInput
      label="Opis"
      name="description"
      value={restaurantInfo.description}
      onChange={handleRestaurantInfo}
      multiline
      rowsMax={10}
      rows={2}
      css={{ width: '100%', marginBottom: 16 }}
    />
  )
}