/** @jsx jsx */
import React, { useState, useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import MaskedInput from 'react-text-mask'

import { Input, TextField, ButtonBase, Collapse } from '@material-ui/core'

import {
  sBase,
  sInput,
  sInputDarker,
} from '../../../views/Auth/components/Input.component'
import { Button } from '../../../../components/Elements'
import { s } from '../../../../style'

export default ({
  isInvoice,
  onIsInvoiceChange,
  invoiceDetails,
  onInvoiceDetailsChange,
}) => {
  return (
    <div
      css={{
        [s.sm_down]: { padding: '1.5rem 1rem' },
        [s.md]: { padding: '2rem' },
      }}>
      <Button
        w100
        dimmed={isInvoice}
        action={() => onIsInvoiceChange(!isInvoice)}
        extraCss={{ fontSize: '0.875rem' }}>
        {isInvoice ? 'Zrezygnuj z Faktury' : 'Dodaj dane do Faktury'}
      </Button>

      <Collapse in={!!isInvoice}>
        {!!isInvoice && (
          <InvoiceDetails
            invoiceDetails={invoiceDetails}
            onChange={onInvoiceDetailsChange}
          />
        )}
      </Collapse>
    </div>
  )
}

const InvoiceDetails = ({ invoiceDetails, onChange }) => (
  <div css={{ marginTop: '1.5rem' }}>
    <ButtonBase css={sBase}>
      <TextField
        placeholder='Nazwa Firmy'
        value={invoiceDetails.firmName || ''}
        onChange={onChange}
        name='firmName'
        fullWidth
        required
        css={[sInput, sInputDarker]}
      />
    </ButtonBase>
    <ButtonBase css={sBase}>
      <Input
        placeholder='NIP'
        value={invoiceDetails.nip}
        onChange={onChange}
        name='nip'
        id='nip'
        inputComponent={TextMaskNip}
        css={[sInput, sInputDarker]}
      />
    </ButtonBase>
    <ButtonBase css={sBase}>
      <TextField
        placeholder='Adres'
        value={invoiceDetails.address || ''}
        onChange={onChange}
        name='address'
        fullWidth
        required
        css={[sInput, sInputDarker]}
      />
    </ButtonBase>
    <ButtonBase css={sBase}>
      <TextField
        placeholder='Miasto'
        value={invoiceDetails.city || ''}
        onChange={onChange}
        name='city'
        fullWidth
        required
        css={[sInput, sInputDarker]}
      />
    </ButtonBase>
    <ButtonBase css={[sBase, { marginBottom: 0 }]}>
      <Input
        placeholder='Kod Pocztowy'
        value={invoiceDetails.zipCode}
        onChange={onChange}
        name='zipCode'
        id='zip-code'
        inputComponent={TextMaskZipCode}
        css={[sInput, sInputDarker]}
      />
    </ButtonBase>
  </div>
)

function TextMaskNip(props) {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        /[1-9]/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
    />
  )
}

function TextMaskZipCode(props) {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/[1-9]/, /\d/, '-', /\d/, /\d/, /\d/]}
    />
  )
}
