/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { krakenUrls } from '../../../../constants/s3-files'
import { s, colors, alpha } from '../../../../style'
import { StatusLarge } from '../../../../components/Elements'
import X from '@material-ui/icons/Clear'
import Arrow from '@material-ui/icons/ArrowRightAlt'
import {
  PRODUCT_STATUSES,
  PRODUCT_STATUS_COLOR,
} from '../../../../constants/statuses'
import { useHistory } from 'react-router-dom'

const OrderProduct = ({ data, waiter }) => {
  const {
    name,
    price,
    amount,
    prodId: { _id, photoUrl, ingredients, description },
    status,
  } = data

  const history = useHistory()

  const statusCurrent = status?.length > 0 && [...status].pop().name

  let ingrString = ''
  if (ingredients) {
    ingredients.forEach(({ name }, index) => {
      if (index + 1 === ingredients.length) ingrString = ingrString.concat(name)
      else ingrString = ingrString.concat(name + ', ')
    })
  }

  return (
    <Grid item xs={12} md={6}>
      <div css={[sContainer]}>
        <div
          onClick={() => (waiter ? null : history.push(`/product/${_id}`))}
          css={sTextContainer}>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}>
            <div>
              <h5 className='prod-item-title'>{name}</h5>
              <p className='prod-item-ingredients'>
                {photoUrl
                  ? ingrString || description
                  : description || ingrString}
              </p>
            </div>
            <p css={sPrice}>
              {amount > 1 && (
                <>
                  {amount}{' '}
                  <span css={sIcon}>
                    <X />
                  </span>
                </>
              )}
              {price.toFixed(2)} <span>&nbsp;zł</span>
              {amount > 1 && (
                <>
                  <span css={sIcon}>
                    <Arrow />
                  </span>
                  {(amount * price).toFixed(2)} <span>&nbsp;zł</span>
                </>
              )}
            </p>
          </div>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
              gap: '1rem',
              [s.md]: { gap: '2rem' },
            }}>
            {statusCurrent && (
              <StatusLarge color={PRODUCT_STATUS_COLOR[statusCurrent]}>
                {PRODUCT_STATUSES[statusCurrent]}
              </StatusLarge>
            )}
            {photoUrl && (
              <div
                css={[
                  sImageWrapper,
                  !photoUrl && {
                    backgroundColor: colors.gray200,
                    [s.sm_down]: { boxShadow: 'none' },
                    [s.md]: { boxShadow: 'none' },
                  },
                ]}>
                <img
                  className='prod-item-image'
                  src={krakenUrls.small + photoUrl}
                  alt={name}
                  loading='lazy'
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Grid>
  )
}

export const sContainer = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: 16,
  background: `linear-gradient(to right, ${colors.gray100}, ${colors.gray200})`,
  transition:
    'background-color 0.3s ease-out, border-radius 0.15s ease-out, box-shadow 0.3s ease-out',
  // marginBottom: '1.5rem',
  padding: '2rem',
  [s.sm_down]: { marginBottom: 0, padding: '1rem' },
  [s.md]: { height: '100%', backgroundColor: colors.gray200 },

  '&:hover': {
    [s.hover]: {
      backgroundColor: colors.gray200,
      '.prod-item-image': {
        transform: 'scale(1.1)',
        filter: 'brightness(1.1)',
      },
      '.prod-item-button': {
        backgroundColor: colors.gray400,
      },
    },
  },
}

// const sProdInOrder = {
//   opacity: 1,
//   position: 'absolute',
//   top: 0,
//   left: -12,
//   width: 12,
//   transition: 'opacity 0.3s ease-out',
//   height: '100%',
//   backgroundColor: colors.green,
//   // boxShadow: `-12px 0px 0px 0px ${colors.green}`,
//   borderRadius: '8px 0px 0px 8px',
//   // [s.md]: { boxShadow: `inset 12px 0px 0px 0px ${colors.green}` },
// }

const sImageWrapper = {
  flexShrink: 0,
  position: 'relative',
  borderRadius: '50%',
  width: 48,
  height: 48,
  [s.md]: {
    width: 64,
    height: 64,
    boxShadow: `-2px 4px 16px ${colors.black.concat(alpha[16])}`,
  },
  [s.sm_down]: {
    boxShadow: `-1px 2px 8px ${colors.black.concat(alpha[16])}`,
  },

  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
    borderRadius: 'inherit',
    transition: 'transform 0.3s ease-in-out, filter 0.3s ease-in-out',
  },
}

const sTextContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',

  '.prod-item-title': {
    fontWeight: 800,
    fontSize: '0.8125rem',
    letterSpacing: '-0.025em',
    textTransform: 'capitalize',
    marginBottom: '0.25rem',
    marginRight: '1rem',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    [s.md]: {
      marginRight: '2rem',
      fontSize: '0.9375rem',
      marginBottom: '0.5rem',
    },
    [s.xl]: { maxWidth: '90%' },
  },

  // '.prod-item-desc': { opacity: '.6', fontSize: 14 },

  '.prod-item-ingredients': {
    opacity: 0.6,
    fontSize: '0.75rem',
    marginRight: '1rem',
    marginBottom: '1rem',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    [s.md]: {
      marginRight: '2rem',
      fontSize: '0.875rem',
      // marginBottom: '0.5rem',
    },
    [s.xl]: { maxWidth: '90%' },
  },
}

const sPrice = {
  // fontWeight: 800,
  // fontSize: '0.75rem',
  // [s.md]: { fontSize: '0.875rem' },
  // span: {
  //   opacity: 0.3,
  // },
  // display: 'flex',
  // alignItems: 'center',

  fontWeight: 500,
  fontSize: '0.75rem',
  [s.md]: { fontSize: '0.875rem' },
  opacity: 0.6,
  span: {
    opacity: 0.5,
  },
  display: 'flex',
  alignItems: 'center',
}

const sIcon = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  margin: '0 0.25rem',
  svg: { fontSize: '0.875rem' },
  [s.md]: { svg: { marginTop: 1 } },
}

export default OrderProduct
