import { useMutation, useLazyQuery } from '@apollo/client'
import {
  GET_USER_BY_EMAIL,
  CHANGE_ADMIN_PERMISSION
} from '../graphql/graphql'


export const useGetUserByEmail = () => useLazyQuery(GET_USER_BY_EMAIL)

export const useChangeAdminPermission = () => {
  const [superAdminChangeAdminPermission] = useMutation(CHANGE_ADMIN_PERMISSION)

  return ({
    handleAdminPermission: async ({ userId }) => {
      await superAdminChangeAdminPermission({ variables: { userId } })
    }
  })
}