/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

const AttentionOrder = ({ isDisable, orderAmount, onClick, onClose }) => {
  if (isDisable) return null

  return (
    <Container onClick={onClick}>
      <OrdersAmount orderAmount={orderAmount} />
      <Header>
        Zamówie
        {orderAmount < 4 && orderAmount !== 0
          ? orderAmount === 1
            ? 'nie'
            : 'nia'
          : 'ń'}{' '}
        wymaga{orderAmount > 1 && orderAmount < 5 && 'ją'}
      </Header>
      <Subheader>Dotknij w dowolnym miejscu, aby zobaczyć</Subheader>
    </Container>
  )
}

const OrdersAmount = ({ orderAmount }) => (
  <span
    css={{
      width: '50vh',
      maxWidth: '50vw',
      height: '50vh',
      color: '#ffffff',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '25vh',
      fontWeight: 700,
    }}>
    {orderAmount}
  </span>
)

const Container = ({ children, ...otherProps }) => (
  <div
    {...otherProps}
    css={{
      zIndex: 99,
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: '#ff8000',

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
    {children}
  </div>
)

const Header = ({ children }) => (
  <p
    css={{
      color: '#ffffff',
      fontSize: 48,
      marginBottom: 16,
    }}>
    {children}
  </p>
)

const Subheader = ({ children }) => (
  <span
    css={{
      color: '#ffaf5e',
      fontSize: 24,
      fontWeight: 700,
    }}>
    {children}
  </span>
)

export default AttentionOrder
