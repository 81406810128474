/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'
import { colors, alpha, s } from '../../../style'
import { ButtonBase } from '@material-ui/core'
import Spinner from '../../Spinner'

const Button = ({
  small,
  link,
  children,
  action,
  dimmed,
  loading,
  extraCss,
  w100,
  w100Mobile,
  w100Desktop,
  external,
  size48,
  buttonTransparent,
  loadingSpinner,
}) => {
  if (action)
    return (
      <div
        onClick={action}
        css={[
          (w100 || w100Mobile) && { [s.sm_down]: { width: '100%' } },
          w100Desktop && { [s.md]: { width: '100%' } },
          loading && { pointerEvents: 'none' },
        ]}>
        <ButtonBase
          css={[
            sButton,
            w100 && { width: '100%' },
            w100Mobile && { [s.sm_down]: { width: '100%' } },
            dimmed && {
              backgroundColor: colors.gray400,
              '&:hover': { backgroundColor: colors.gray600 },
            },
            extraCss,
            small && { sSmall },
            size48 && { width: 48, maxWidth: 48, flexGrow: 0, padding: 0 },
            buttonTransparent && {
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          ]}>
          {loadingSpinner && loading ? (
            <Spinner size={1.5} addCss={{ color: '#fff' }} />
          ) : (
            children
          )}
        </ButtonBase>
      </div>
    )
  if (link)
    return !external ? (
      <Link
        to={link}
        css={(w100 || w100Mobile) && { [s.sm_down]: { width: '100%' } }}>
        <ButtonBase
          css={[
            sButton,
            w100 && { width: '100%' },
            w100Mobile && { [s.sm_down]: { width: '100%' } },
            dimmed && {
              backgroundColor: colors.gray400,
              '&:hover': { backgroundColor: colors.gray600 },
            },
            extraCss,
            small && { sSmall },
            size48 && { width: 48, maxWidth: 48, flexGrow: 0, padding: 0 },
            buttonTransparent && {
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          ]}>
          {loadingSpinner && loading ? (
            <Spinner size={1.5} addCss={{ color: '#fff' }} />
          ) : (
            children
          )}
        </ButtonBase>
      </Link>
    ) : (
      <a
        href={link}
        target='_blank'
        rel='noopener noreferrer'
        css={(w100 || w100Mobile) && { [s.sm_down]: { width: '100%' } }}>
        <ButtonBase
          css={[
            sButton,
            w100 && { width: '100%' },
            w100Mobile && { [s.sm_down]: { width: '100%' } },
            dimmed && {
              backgroundColor: colors.gray400,
              '&:hover': { backgroundColor: colors.gray600 },
            },
            extraCss,
            small && { sSmall },
            size48 && { width: 48, maxWidth: 48, flexGrow: 0, padding: 0 },
            buttonTransparent && {
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          ]}>
          {loadingSpinner && loading ? (
            <Spinner size={1.5} addCss={{ color: '#fff' }} />
          ) : (
            children
          )}
        </ButtonBase>
      </a>
    )
  return (
    <div css={(w100 || w100Mobile) && { [s.sm_down]: { width: '100%' } }}>
      <ButtonBase
        css={[
          sButton,
          w100 && { width: '100%' },
          w100Mobile && { [s.sm_down]: { width: '100%' } },
          dimmed && {
            backgroundColor: colors.gray400,
            '&:hover': { backgroundColor: colors.gray600 },
          },
          extraCss,
          small && { sSmall },
          size48 && { width: 48, maxWidth: 48, flexGrow: 0, padding: 0 },
          buttonTransparent && {
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        ]}>
        {loadingSpinner && loading ? (
          <Spinner size={1.5} addCss={{ color: '#fff' }} />
        ) : (
          children
        )}
      </ButtonBase>
    </div>
  )
}

const sButton = {
  display: 'flex',
  jsutifyContent: 'center',
  alignItems: 'center',
  transition: 'color 0.3s ease-out, background-color 0.3s ease-out',
  fontSize: '0.875rem',
  fontWeight: 800,
  textAlign: 'center',
  padding: '0px 48px',
  minWidth: 48,
  height: 48,
  borderRadius: 24,
  color: '#ffffff',
  letterSpacing: '0.05em',
  backgroundColor: colors.text,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',

  '&:hover': {
    backgroundColor: colors.text.concat(alpha[80]),
  },
}

const sSmall = {
  padding: 0,
}

export default Button
