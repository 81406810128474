import { useMutation, useQuery } from '@apollo/client'
import { useError } from '../hooks/errors.hook'
import {
  GET_IMG_URL,
  POST_MSSG_TO_ORDER,
  GET_ACTIVE_ORDER_MESSAGES,
  GET_FINISHED_ORDER_MESSAGES,
  GET_DECLINED_ORDER_MESSAGES,
  GET_ANY_ORDER_MESSAGES,
} from '../graphql/mutations.graphql'

export const useGetS3ImgUrl = () => {
  const { errorHandling } = useError()
  const [getS3ImgUrlLink] = useMutation(GET_IMG_URL)

  try {
    async function onGetS3UrlLink(data, cb) {
      const { type } = data
      return getS3ImgUrlLink({ variables: { type } })
    }
    return { onGetS3UrlLink }
  } catch (err) {
    errorHandling(err)
  }
}

export const usePostMssgToOrder = () => {
  const { errorHandling } = useError()
  const [orderCreateNewMessage] = useMutation(POST_MSSG_TO_ORDER)

  try {
    async function onPostMssgToOrder(data, cb) {
      const { orderId, message } = data

      await orderCreateNewMessage({
        variables: { orderId, message },
        refetchQueries: [
          { query: GET_ACTIVE_ORDER_MESSAGES, variables: { orderId } },
        ],
      })

      if (typeof cb === 'function') cb()
    }

    return { onPostMssgToOrder }
  } catch (err) {
    errorHandling(err)
  }
}

export const useGetActiveOrderMssgs = (orderId) =>
  useQuery(GET_ACTIVE_ORDER_MESSAGES, { variables: { orderId } })

export const useGetFinishedOrderMssgs = (orderId) =>
  useQuery(GET_FINISHED_ORDER_MESSAGES, { variables: { orderId } })

export const useGetDeclinedOrderMssgs = (orderId) =>
  useQuery(GET_DECLINED_ORDER_MESSAGES, { variables: { orderId } })

export const useGetAnyOrderMssgs = (orderId) =>
  useQuery(GET_ANY_ORDER_MESSAGES, { variables: { orderId } })
