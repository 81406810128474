/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import useOrdersMutations from '../../../hooks/useOrders.mutations'
import { Button } from '../../../../components/Elements'

const ButtonDecline = ({ orderId }) => {
  const [isLoading, setIsLoading] = useState(false)

  const { handleUpdateOrderStatus } = useOrdersMutations()

  const onDecline = () => {
    setIsLoading(true)
    handleUpdateOrderStatus({ orderId, status: 'DECLINED_BY_CLIENT' }, () =>
      setIsLoading(false)
    )
  }

  return (
    <Button dimmed w100Mobile action={onDecline} loading={isLoading}>
      Odrzuć
    </Button>
  )
}

export default ButtonDecline
