/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import MoneyEz from '../../../helpers/MoneyEz'
import { PRODUCT_STATUSES } from '../../../constants/statuses'
import getStatusName from '../../../helpers/get-status-name'


export default ({ products, onToggleProduct }) => {
  if (!(products?.length)) return null

  return (
    <List css={{
      maxHeight: '75vh',
      overflowX: 'scroll',
      width: '100%'
    }}>
      {products.map(product => (
        <ProductListHandleItem
          key={product._id}
          product={product}
          onToggleProduct={onToggleProduct}
        />
      ))}
    </List>
  )
}


const ProductListHandleItem = ({
  product,
  onToggleProduct
}) => (

  <ListItem
    dense
    button
    onClick={() => onToggleProduct(product._id)}
  >
    <ListItemIcon>
      <Checkbox
        edge="start"
        checked={product.isStatusChange}
        disableRipple
      />
    </ListItemIcon>

    <p css={{ width: '50%' }}>
      <span css={{ fontWeight: 700 }}>{product.amount} x {product.name}</span>
      <br />
      <ProductStatus product={product} />
    </p>

    <ListItemSecondaryAction>
      {MoneyEz(product.price)
        .multiply(product.amount)
        .getAmountWithFullPrecisionAndZl()}
    </ListItemSecondaryAction>
  </ListItem>
)


const ProductStatus = ({ product }) => (
  <span css={[{
    fontSize: 14
  },
  getStatusName(product.status) === 'PREPARING'
    ? { color: '#FF8000' }
    : {},
  getStatusName(product.status) === 'DONE'
    ? { color: '#00B30F' }
    : {},
  getStatusName(product.status) === 'FINISHED'
    ? { color: '#343340' }
    : {},
  ]}>
    {PRODUCT_STATUSES[getStatusName(product.status)]}
  </span>
)
