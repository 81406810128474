/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { Collapse } from '@material-ui/core'
import { Button } from '../../../components/Elements'
import Headline from '../../../components/Headline/Headline'
import WidgetContainer from '../../../components/Widget/Widget.component'
import { sGrid, sDescription } from '../../../style/style'
import { s } from '../../../style'
import { sFormContainer, sGridOverride, sHeadline } from './styles'
import useOrdersMutations from '../../hooks/useOrders.mutations'
import { useHistory } from 'react-router'

const OrderCancel = ({
  orderId,
  isCancelWidgetOpen,
  setIsCancelWidgetOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  // const [isDialog, setIsDialog] = useState(false)
  const onClickOpen = () => setIsCancelWidgetOpen(true)
  const onClickClose = () => setIsCancelWidgetOpen(false)

  const { handleDangerAnnulOrder } = useOrdersMutations()

  const onAnnul = () => {
    setIsLoading(true)
    const cb = () => {
      setIsLoading(false)
      onClickClose()
      history.push(`/waiter/history/${orderId}`)
    }
    handleDangerAnnulOrder(
      { orderId, reason: 'order canceled by restaurant' },
      cb
    )
  }

  return (
    <React.Fragment>
      <Button dimmed w100Mobile action={onClickOpen}>
        Anuluj Zamówienie
      </Button>
      <WidgetContainer
        isWidgetOpen={isCancelWidgetOpen}
        setIsWidgetOpen={setIsCancelWidgetOpen}>
        <Collapse in={isCancelWidgetOpen}>
          <div>
            <Headline extraCss={sHeadline}>Anulowanie</Headline>
            <div css={[sGrid, sGridOverride]}>
              <p css={[sDescription, { fontSize: '0.875rem' }]}>
                Anulowanie zamówienia jest bezpowrotne. Klient zostanie
                poinformowany o Twojej decyzji. W przypadku zamówienia
                opłaconego, wszytskie środki zostaną zwrócone na konto klienta,
                a zamówienie trafi do Anulowanych
              </p>
            </div>
            <div css={[sFormContainer]}>
              <Button
                loadingSpinner
                w100
                w100Desktop
                dimmed
                action={onAnnul}
                loading={isLoading}>
                Tak, Anuluj Zamówienie
              </Button>
              <Button
                loadingSpinner
                w100
                w100Desktop
                action={onClickClose}
                loading={isLoading}>
                Nie, Zamknij
              </Button>
            </div>
          </div>
        </Collapse>
      </WidgetContainer>
    </React.Fragment>
  )
}

export default OrderCancel
