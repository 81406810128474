/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add';

import { useError } from '../../../hooks/errors.hook'
// import ProductsModifiersNav from '../../components/ProductsModifiersNav/ProductsModifiersNav'
import LoadingBar from '../../components/LoadingBar/LoadingBar'

import ProductsTable from './views/ProductsTable'
import ProductSearchInput from './views/ProductSearchInput'
import ProductsProvider, { useProductsContext } from './context/products.provider'
import { ezrmRoutePrefix } from '../settings'



const ProductsPage = () => {
  const { errorHandling } = useError()
  const {
    loading,
    error
  } = useProductsContext()

  if (loading) return <LoadingBar />
  if (error) {
    errorHandling(error)
    return null
  }

  return (
    <PaddingContainer>
      <ProductsHeader />
      <ProductsTable />
    </PaddingContainer>
  )
}


// Components
const PaddingContainer = ({ children }) => <div css={{ padding: 24 }}>{children}</div>

const ProductsHeader = () => (
  <React.Fragment>
    <h1 css={{ marginBottom: 24 }}>Produkty</h1>

    <div css={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 12
    }}>
      <ProductSearchInput />

      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon>Dodaj</AddIcon>}
        size="large"
        component={Link}
        to={ezrmRoutePrefix + "/new-product"}
      >
        Dodaj produkt
      </Button>
    </div>
  </React.Fragment>
)


export default () => (
  <ProductsProvider>
    <ProductsPage />
  </ProductsProvider>
)