/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useOrderAttentionContext } from '../context/order-attention.context'



export default () => {
  const { orderWeWorkOn: { note } } = useOrderAttentionContext()

  return note && (
    <div css={{
      margin: '8px 0',
      border: '1px solid #777',
      borderRadius: 8,
      padding: 8
    }}>
      <h3 css={{ marginBottom: 8 }}>Klient zostawił notatkę.</h3>
      <p>{note}</p>
    </div>
  )
}