import React from 'react'

import GooglePlacesAutocomplete from '../../../../components/GooglePlacesAutocomplete'
import { useRestaurantContext } from '../context/restaurant.provider'


export default () => {
  const {
    restaurantInfo,
    handleChangeAddres,
    handleSelectAddress,
  } = useRestaurantContext()


  return (
    <GooglePlacesAutocomplete
      required
      value={restaurantInfo.address}
      onChange={handleChangeAddres}
      onSelect={handleSelectAddress}
    />
  )
}