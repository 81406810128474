/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import InvoiceDetails from '../../../components/InvoiceDetails'
import { useActiveOrdersContext } from '../context/orders-active.context'



export default () => {
  const {
    isInvoice,
    invoiceDetails
  } = useActiveOrdersContext()

  return isInvoice && <InvoiceDetails {...invoiceDetails} textHeader="Dane do faktury" />
}