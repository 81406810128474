/** @jsx jsx */
import React, { useContext, useEffect, useRef, useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { useError } from '../../../hooks/errors.hook'
import MoneyEz from '../../../helpers/MoneyEz'

import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import DashboardIcon from '@material-ui/icons/Dashboard'
import LocalDiningIcon from '@material-ui/icons/LocalDining'
import PersonIcon from '@material-ui/icons/Person'
import SettingsIcon from '@material-ui/icons/Settings'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import NotificationsIcon from '@material-ui/icons/Notifications'
import AnnouncementIcon from '@material-ui/icons/Announcement'

import { GET_USER } from '../../graphql/user.graphql'
import { AuthContext } from '../../../context/auth.context'
import { krakenUrls } from '../../../constants/s3-files'
import { useGetEzcAdminChat } from '../../hooks/useChat.queries'

import { Grid, ButtonBase } from '@material-ui/core'
import { sOuter, sGrid, sBottomLinks } from '../../../style/style'
import { ButtonBorder, Badge } from '../../../components/Elements'
import TopNavContent from '../../views/ProductPage/components/TopNavContent'
import Headline from '../../../components/Headline/Headline'

import { colors, s, alpha } from '../../../style'
import ArrowForward from '@material-ui/icons/ArrowForwardIos'
import isAdminHelper from '../../../helpers/isAdmin'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import RoomServiceIcon from '@material-ui/icons/RoomService'
import RestaurantIcon from '@material-ui/icons/Restaurant'

const itemsData = [
  {
    to: '/account/info',
    name: 'Informacje',
    subtitle: '',
    AvatarIcon: PersonIcon,
  },
  {
    to: '/account/notifications',
    name: 'Powiadomienia',

    subtitle: '',
    AvatarIcon: NotificationsIcon,
  },
  {
    to: '/account/wallet',
    name: 'Portfel',
    subtitle: '',
    AvatarIcon: AccountBalanceWalletIcon,
  },
  {
    to: '/account/transactions',
    name: 'Transakcje',
    subtitle: '',
    AvatarIcon: SyncAltIcon,
  },
  {
    to: '/orders-history',
    name: 'Historia Zamówień',

    subtitle: '',
    AvatarIcon: LocalDiningIcon,
  },
  {
    to: '/account/settings',
    name: 'Ustawienia',
    subtitle: '',
    AvatarIcon: SettingsIcon,
  },
]

const Account = () => {
  const { logout } = useContext(AuthContext)
  const { errorHandling } = useError()
  const { data, loading, error, refetch } = useQuery(GET_USER)

  const chat = useGetEzcAdminChat()

  useEffect(() => {
    refetch()
  }, [])

  if (loading || chat.loading) return <div />
  if (error) {
    errorHandling(error)
    return null
  }

  const { user } = data
  const unreadAdminMssgs = chat.data
    ? chat.data.ezcAdminChat.messages.filter(
        (mssg) => mssg.fromUserId !== user._id && !mssg.isRead
      ).length
    : 0

  const { personal, coinsBalance, coinsBlocked, isRestaurateur, _id } = user
  const actualBalance = MoneyEz(coinsBalance)
    .subsctract(coinsBlocked)
    .getAmountWithFullPrecision()
    .split('.')

  const isAdmin = _id ? isAdminHelper(_id) : false

  // console.log(isAdminHelper(_id))

  const handleLogout = () => {
    logout()
    toast.dark('Do zobaczenia wkrótce ✌')
  }
  const handleEzrClick = (e) => {
    if (!isRestaurateur) {
      e.preventDefault()
      toast.info(
        'Zostań Restauratorem Eatzon! Skontaktuj się z nami mailowo na 📧 info@eatzon.pl',
        { autoClose: false }
      )
    }
  }

  return (
    <React.Fragment>
      <TopNav
        title={`Konto`}
        subtitle={personal && `- ${personal.name} ${personal.surname}`}
        offset={60}
      />
      <div css={sOuter}>
        <Headline
          itemChildren={
            <ButtonBorder action={handleLogout}>Wyloguj</ButtonBorder>
          }>
          Konto
        </Headline>

        <Grid container css={sGrid}>
          <Profile
            actualBalance={actualBalance}
            personal={personal || {}}
            isRestaurateur={isRestaurateur}
            isAdmin={isAdmin}
          />

          {itemsData.map((item, id) => (
            <MenuItem key={id} id={id} {...item} />
          ))}

          <MenuItem
            id={6}
            to={isAdmin ? `/admin` : `/account/ez-chat`}
            name={isAdmin ? 'Panel Administratora' : 'Chat z Eatzon'}
            description={isAdmin ? '' : 'Napisz do administracji Eatzon'}
            badgeContent={unreadAdminMssgs}
            AvatarIcon={AnnouncementIcon}
          />

          <MenuItem
            id={7}
            to={isRestaurateur ? '/rest' : '/account'}
            name={isRestaurateur ? 'Panel Restauratora' : 'Restaurator Eatzon'}
            description={
              isRestaurateur ? '' : 'Dodaj swoją restaurację do Eatzon'
            }
            AvatarIcon={RestaurantIcon}
            onClick={handleEzrClick}
          />
          {isRestaurateur && (
            <MenuItem
              id={8}
              to={'/waiter'}
              name={'Panel Kelnera'}
              AvatarIcon={RoomServiceIcon}
            />
          )}
          {isRestaurateur && (
            <MenuItem
              id={9}
              to={'/ez-manager'}
              name={'(Legacy) Restaurator'}
              AvatarIcon={RestaurantIcon}
              css={{ opacity: 0.3 }}
            />
          )}

          {isRestaurateur && (
            <MenuItem
              id={10}
              to={'/kelner'}
              name={'(Legacy) Kelner'}
              AvatarIcon={RoomServiceIcon}
              css={{ opacity: 0.3 }}
            />
          )}
          <Grid item xs={12} css={sBottomLinks}>
            <ButtonBorder link={'/terms'}>Regulamin</ButtonBorder>
            <ButtonBorder link={'/privacy'}>Polityka Prywatności</ButtonBorder>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}

export const Profile = ({
  actualBalance,
  personal,
  isRestaurateur,
  isAdmin,
  linkDisabled,
}) => {
  // useEffect(() => {}, [personal])

  return (
    <Grid item xs={12}>
      <ButtonBase
        css={[
          sButtonBase,
          {
            marginBottom: '1.5rem',
            position: 'relative',
            overflow: 'hidden',
            [s.md]: { borderRadius: '1.5rem' },
            background: 'transparent',
          },
        ]}>
        <Link
          to={`/account/info`}
          css={[
            {
              display: 'flex',
              alignItems: 'center',
              background: `linear-gradient(to right, ${colors.gray200}, ${colors.gray400})`,
              borderRadius: '1rem',
              padding: '1rem',
              width: '100%',
              [s.md]: {
                padding: '2rem',
                borderRadius: '1.5rem',
                // width: 'calc(100vw - 232px + 2rem)',
              },
            },
            isRestaurateur && {
              background: `linear-gradient(to right, ${colors.orange.concat(
                alpha[8]
              )}, ${colors.orange.concat(alpha[30])})`,
            },
            isAdmin && {
              background: `linear-gradient(to right, ${colors.blue.concat(
                alpha[8]
              )}, ${colors.blue.concat(alpha[30])})`,
            },
            linkDisabled && { pointerEvents: 'none' },
          ]}>
          {personal.avatarUrl && (
            <img
              src={krakenUrls.small + personal.avatarUrl}
              alt={personal.name}
              css={{
                width: 64,
                height: 64,
                [s.md]: { width: 96, height: 96 },
                borderRadius: 48,
              }}
              loading='lazy'
            />
          )}
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '1rem',
              [s.md]: { marginLeft: '2rem' },
            }}>
            <h4
              css={{
                fontWeight: 800,
                letterSpacing: '-0.025em',
                fontSize: '1.25rem',
                [s.md]: { fontSize: '2rem' },
              }}>
              {personal.name} {personal.surname}
            </h4>
            <div
              css={{
                display: 'flex',
              }}>
              {isRestaurateur && (
                <p css={[sRole, { color: colors.orange }]}>
                  <RestaurantMenuIcon fontSize='inherit' />
                  &nbsp;Restaurator&nbsp;&nbsp;
                </p>
              )}
              {isAdmin && (
                <p css={[sRole, { color: colors.blue }]}>
                  <VerifiedUserIcon fontSize='inherit' />
                  &nbsp;Administrator
                </p>
              )}
            </div>
            <p
              css={{
                fontSize: '1.25rem',
                fontWeight: 800,
                marginTop: '0.5rem',
                [s.md]: { fontSize: '1.5rem', marginTop: '1rem' },
              }}>
              {actualBalance[0]}
              <span css={{ color: '#3433404D' }}>.{actualBalance[1]} zł</span>
            </p>
          </div>
          <div
            css={[
              {
                [s.xs]: { display: 'none' },
                position: 'absolute',
                top: 0,
                right: 0,
                // width: 232,
                height: '100%',
                [s.md]: { transform: 'scale(2)' },
                background: `linear-gradient(to right, ${
                  colors.gray200
                }, ${colors.gray200.concat(alpha[0])})`,
              },
              isRestaurateur && {
                background: `linear-gradient(to right, ${colors.orange.concat(
                  alpha[30]
                )}, ${colors.orange.concat(alpha[0])})`,
              },
              isAdmin && {
                background: `linear-gradient(to right, ${colors.blue.concat(
                  alpha[30]
                )}, ${colors.blue.concat(alpha[0])})`,
              },
            ]}>
            <img
              src={krakenUrls.small + personal.avatarUrl}
              alt={personal.name}
              css={{
                position: 'absolute',
                top: '50%',
                right: 0,
                width: 200,
                height: 200,
                transform: 'translateY(-50%)',
                filter: 'blur(32px)',
              }}
              loading='lazy'
            />
          </div>
        </Link>
      </ButtonBase>
    </Grid>
  )
}

export const MenuItem = ({
  id,
  to,
  AvatarIcon,
  name,
  description,
  badgeContent,
  action,
  ...otherProps
}) => (
  <Grid item xs={12} md={6}>
    <div css={sOuterId(id)}>
      <ButtonBase css={sButtonBase}>
        <Link to={to} {...otherProps} css={sContainer}>
          <MenuItemInner
            AvatarIcon={AvatarIcon}
            badgeContent={badgeContent}
            name={name}
            description={description}
          />
          <ArrowForward fontSize='inherit' />
        </Link>
      </ButtonBase>
    </div>
  </Grid>
)
const MenuItemInner = ({ AvatarIcon, badgeContent, name, description }) => {
  return (
    <div css={sFlexCenter}>
      <div css={sIcon}>
        {AvatarIcon && <AvatarIcon />}
        <Badge left color={colors.green} show={badgeContent > 0} showAnimation>
          {badgeContent > 0 && badgeContent}
        </Badge>
      </div>

      <div css={[sText]}>
        <span css={sText.title}>{name}</span>
        {description && <span css={[sText.subtitle]}>{description}</span>}
      </div>
    </div>
  )
}

const sButtonBase = {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '1rem',
  cursor: 'pointer',
  alignItems: 'start',
  width: '100%',
  textAlign: 'left',
  background: `linear-gradient(to right, ${colors.gray100}, ${colors.gray200})`,
}

const sIcon = {
  position: 'relative',
  fontSize: 24,
  width: 40,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  backgroundColor: colors.gray400,
  color: colors.text,
  flexShrink: 0,

  [s.md]: {
    width: 48,
    height: 48,
  },
}

const sText = {
  display: 'flex',
  textAlign: 'left',
  flexDirection: 'column',
  marginLeft: '0.75rem',
  [s.sm]: {
    marginLeft: '1rem',
  },
  width: '100%',
  title: {
    fontSize: '1rem',
    fontWeight: 800,
    [s.sm_down]: {
      letterSpacing: '-0.025em',
    },
    [s.ss]: {
      fontSize: '0.875rem',
    },
  },
  subtitle: {
    marginTop: '0.25rem',
    fontWeight: 500,
    fontSize: '0.875rem',
    color: colors.gray600,
    display: 'flex',
    alignItems: 'center',
    [s.ss]: {
      fontSize: '0.75rem',
    },
  },
  oneLiner: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 'calc(100vw - 175px)',
    [s.md_only]: { maxWidth: 'calc(50vw - 196px)' },
  },
}

const sContainer = {
  transition: 'background-color 0.3s ease-out, border-radius 0.1s ease-out',
  display: 'flex',
  justifyContent: 'space-between',
  paddingRight: '0.5rem',
  alignItems: 'center',
  cursor: 'pointer',
  width: '100%',
  padding: '1rem',
  borderRadius: '1rem',
  [s.md]: {
    padding: '2rem',
  },
  '&:hover': {
    [s.hover]: {
      backgroundColor: colors.gray300.concat(alpha[100]),
    },
  },
}

const sFlexCenter = { display: 'flex', alignItems: 'center' }

const sOuterId = (id) => ({
  paddingBottom: '1rem',
  [s.md]: {
    paddingBottom: '1.5rem',
    paddingRight: id % 2 === 0 ? '0.75rem' : 0,
    paddingLeft: id % 2 === 0 ? 0 : '0.75rem',
  },
})

const sRole = {
  fontSize: '0.75rem',
  fontWeight: 800,
  display: 'flex',
  alignItems: 'center',
}

export default Account
