import axios from 'axios'
import { useGetS3ImgUrl } from './useGraphql.hook'
import { clientErrors } from '../constants/errors'


export const useUploadImg = () => {
  const { onGetS3UrlLink } = useGetS3ImgUrl()

  // function begin
  async function uploadImgAndGetURL(imgFile, type) {
    if (!imgFile) return null

    isCorrectImgType(imgFile)

    const awsS3url = await getImgLink(onGetS3UrlLink, type)

    await putImgToS3(awsS3url, imgFile)

    return awsS3url
  }

  return {
    uploadImgAndGetURL
  }
}



function isCorrectImgType(imgFile) {
  const acceptedImgTypes = ['image/png', 'image/jpeg']
  const isInvalidType = !acceptedImgTypes.includes(imgFile.type)

  if (isInvalidType) throw new Error(clientErrors.INCORRECT_IMAGE_TYPE)
}

async function getImgLink(onGetS3UrlLink, type) {
  const awsS3url = await onGetS3UrlLink({ type }) // 'PROD_AVATAR'
  const isNoLink = !awsS3url.data.getS3ImgUrlLink
  if (isNoLink) throw new Error(clientErrors.NO_S3_LINK_RESPONSE)

  return awsS3url
}

async function putImgToS3(awsS3url, imgFile) {
  await axios.put(
    awsS3url.data.getS3ImgUrlLink.url,
    imgFile,
    { headers: { 'Content-Type': imgFile.type } }
  )
}