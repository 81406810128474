/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import DashboardIcon from '@material-ui/icons/Dashboard';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import ListIcon from '@material-ui/icons/List';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import RoomServiceIcon from '@material-ui/icons/RoomService';

import { useRoutesEzrm } from '../../../routes'
import { useAuthContext } from '../../../context/auth.context'
import ActiveRestaurantProvider from '../../context/active-restaurant.provider'
import Logo from '../../components/Logo'

import { ezrmRoutePrefix } from '../../pages/settings'


const q = (str) => ezrmRoutePrefix.concat(str)
const navLinks = [
  { name: 'Pulpit', href: q('/dashboard'), Ico: DashboardIcon },
  { name: 'Restauracja', href: q('/restaurant'), Ico: RestaurantIcon },
  { name: 'Produkty', href: q('/products'), Ico: FastfoodIcon },
  { name: 'Menu', href: q('/menu-edit'), Ico: ListIcon },
  { name: 'Transakcje', href: q('/payments'), Ico: AccountBalanceWalletIcon },
  { name: 'Dostępy', href: q('/permissions'), Ico: PeopleIcon },
]


export default () => {
  const { logout } = useAuthContext()
  const routes = useRoutesEzrm()

  return (
    <ActiveRestaurantProvider>
      <main css={{
        display: 'flex',
        width: '100%',
        minHeight: '100vh',
      }}>
        <div css={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          maxWidth: 220
        }}>
          <div css={{
            svg: {
              width: '80%',
              padding: '0 16px'
            }
          }}>
            <Logo />
          </div>
          <List css={{ flexGrow: 1, width: 220 }}>
            {navLinks.map(({ name, href, Ico }) => (
              <ListItem button key={href} component={Link} to={href}>
                <ListItemIcon><Ico /></ListItemIcon>
                <ListItemText primary={name} />
              </ListItem>
            ))}
          </List>

          <List css={{ width: 220 }}>
            <ListItem button component={Link} to="/kelner">
              <ListItemIcon><RoomServiceIcon /></ListItemIcon>
              <ListItemText primary="Pulpit Kelnera" />
            </ListItem>
            <ListItem button component={Link} to="/account">
              <ListItemIcon><PersonIcon /></ListItemIcon>
              <ListItemText primary="Aplikacja Klienta" />
            </ListItem>
            <ListItem button onClick={logout}>
              <ListItemIcon><PowerSettingsNewIcon /></ListItemIcon>
              <ListItemText primary="Wyloguj się" />
            </ListItem>
          </List>
        </div>

        <div css={{
          flexGrow: 1,
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
          padding: 16,
          backgroundColor: '#fff',
          overflow: 'scroll',
          maxHeight: '100vh'
        }}>
          {routes}
        </div>
      </main>
    </ActiveRestaurantProvider>
  )
}