/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'
import Badge from '@material-ui/core/Badge';

import { Button } from '../../../components'


const ChatButton = ({
  chatLink,
  unreadMssgsAmount
}) => (

  <Button
    color={unreadMssgsAmount > 0 ? "secondary" : undefined}
    variant={unreadMssgsAmount > 0 ? "contained" : 'outlined'}
    component={Link}
    to={chatLink}
    css={{ marginLeft: 8, fontWeight: 700 }}
  >
    <Badge
      badgeContent={unreadMssgsAmount}
      color="secondary"
      css={{
        span: {
          right: '-14px',
          top: 4,
          border: '2px solid #fff',
          padding: '0 4px',
        }
      }}
    >
      Czat
    </Badge>
  </Button>
)

export default ChatButton