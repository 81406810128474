/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { OrderSum } from '../../../components'
import createOrderSum from '../../../helpers/create-order-sum'
import { useActiveOrdersContext } from '../context/orders-active.context'



export default () => {
  const {
    orderDetails,
    paidSum,
    deliveryCost,
    consumptionType
  } = useActiveOrdersContext()

  const sumProps = createOrderSum({
    orderDetails,
    paidSum,
    deliveryCost
  })

  return <OrderSum {...sumProps} consumptionType={consumptionType} />
}