/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { Grid } from '@material-ui/core'
import { SectionHeadline } from '../../../../components/Elements'
import { sDescription, sGrid } from '../../../../style/style'
import { useRestaurantContext } from '../context/restaurant.provider'
import OpenHoursTable from '../../../components/OpenHoursTable'

const OpenHours = () => {
  const { openningHours, handleOpenningHours } = useRestaurantContext()
  return (
    <Grid container css={sGrid}>
      <SectionHeadline noPaddingLeft>Godziny Otwarcia</SectionHeadline>
      <p css={[sDescription, { marginBottom: '1rem' }]}>
        Określ godziny otwarcia Restauracji. Zakres 0:00 - 24:00 oznacza, że
        restauracja jest otwarta całodobowo. Zakres 0:00 - 0:00 oznacza, że tego
        dnia restauracja jest zamknięta.
      </p>
      <OpenHoursTable setter={handleOpenningHours} getter={openningHours} />
    </Grid>
  )
}

export default OpenHours
