/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import SearchWrapper from './SearchWrapper'
import { sGrid, sDescription } from '../../../style/style'
import { s, globals } from '../../../style'

const Search = () => (
  <SearchWrapper headline={'Szukaj'}>
    <div css={[sGrid, sGrid2]}>
      <p css={[sDescription, { [s.xs]: { fontSize: '0.875rem' } }]}>
        Wpisz rodzaj kuchni, danie, składniki, albo nazwę produktu lub
        restauracji. Skorzystaj z Filtracji i znajdź idealne zestawienie!
      </p>
    </div>
  </SearchWrapper>
)

const sGrid2 = {
  width: '100vw',
  maxWidth: globals.maxWidthMedium,
  margin: '0 auto',
}

export default Search
