import React, { createContext, useContext } from 'react'
import useOrderAttention from '../hooks/useOrderAttention'


function noop() { };

export const OrderAttentionContext = createContext({
  isLoading: false,

  orderWeWorkOn: {},
  alreadyOrderedProducts: [],
  productsToAccept: [],

  // sync handlers
  handleToggleProduct: noop,

  // async handlers
  handleAccept: noop,
  handleDecline: noop,
  handleAcceptProducts: noop,
  handleDeclineAllProducts: noop

})

export const useOrderAttentionContext = () => useContext(OrderAttentionContext)



export default ({ children, orderWeWorkOn }) => {
  return (
    <OrderAttentionContext.Provider value={{
      ...useOrderAttention({ orderWeWorkOn })
    }}>
      {children}
    </OrderAttentionContext.Provider>
  )
}