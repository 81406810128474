/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const MenuContainer = ({ children, onClose }) => (
  <div css={{
    margin: '0 24px',
    padding: '60px 12px 0',
    backgroundColor: '#fff',
    borderRadius: 4,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',

    height: '100%',
    overflow: 'scroll',
    position: 'relative',
  }}>
    <div css={{
      position: 'absolute',
      top: 6,
      right: 16,
      color: '#444',
      borderRadius: '50%',
      zIndex: 4,
      "svg": {
        transform: 'translateX(-1px)'
      }
    }}>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </div>

    {children}
  </div>
)

export default MenuContainer