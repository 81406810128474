export default function (messages) {
  if (!messages) return -1

  let unreadMssgsAmount = 0
  for (const mssg of messages) {
    if (mssg.fromUserId && !mssg.isRead) {
      unreadMssgsAmount++
    }
  }
  return unreadMssgsAmount
}