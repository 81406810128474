/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useOrderAttentionContext } from '../context/order-attention.context'
import MoneyEz from '../../../../helpers/MoneyEz'
import removeDuplicateProducts from '../../../../helpers/remove-duplicate-products'
import sortProductsByStatus from '../../../../helpers/sort-products-by-status'

export default () => {
  const {
    orderWeWorkOn: { orderDetails, paidSum, deliveryCost, consumptionType },
    productsToAccept,
  } = useOrderAttentionContext()

  let allProducts,
    allProductsSorted,
    newAllProducts = []
  if (orderDetails && orderDetails[0]) {
    allProducts = [
      ...orderDetails[0].productsToOrder,
      ...orderDetails[0].productsInProcess,
      ...orderDetails[0].productsFinishedGood,
    ]

    newAllProducts = removeDuplicateProducts(allProducts)
    allProductsSorted = sortProductsByStatus(newAllProducts)
  }

  // console.log(allProductsSorted)

  let amountSum = 0
  let productsSum = 0
  for (const product of allProductsSorted) {
    productsSum = MoneyEz(product?.price)
      .multiply(product?.amount)
      .add(productsSum)
      .getAmount()
  }

  // console.log('productsSum', productsSum)

  amountSum = MoneyEz(amountSum)
    .add(productsSum)
    .add(deliveryCost || 0)
    .getAmount()

  const annulProducts =
    orderDetails && orderDetails[0]
      ? [...orderDetails[0].productsFinishedBad]
      : []
  let annulAmount = 0
  for (const product of annulProducts) {
    annulAmount = MoneyEz(product?.price)
      .multiply(product?.amount)
      .add(annulAmount)
      .getAmount()
  }

  const paid = MoneyEz(paidSum).getAmountWithFullPrecisionAndZl()
  const uncheckedProductsSum = MoneyEz(0)
    .sumProductArray(productsToAccept.filter((p) => !p.isStatusChange))
    .getAmount()

  const deliveryAmount = MoneyEz(
    deliveryCost || 0
  ).getAmountWithFullPrecisionAndZl()

  const sum = MoneyEz(amountSum)
    .subsctract(uncheckedProductsSum)
    .getAmountWithFullPrecisionAndZl()

  const sumToPay = MoneyEz(amountSum)
    .subsctract(paidSum)
    .subsctract(uncheckedProductsSum)
    .getAmountWithFullPrecisionAndZl()

  return (
    <div
      css={{
        textAlign: 'right',
        margin: '20px 16px 0 0',
        p: { marginBottom: 2 },
      }}>
      <p>Produkty: {MoneyEz(productsSum).getAmountWithFullPrecisionAndZl()}</p>
      {consumptionType === 'delivery' && (
        <p>Dostawa: {deliveryCost === 0 ? 'DARMOWA' : deliveryAmount}</p>
      )}
      <p css={{ fontWeight: 700, '&&': { marginBottom: 8 } }}>Razem: {sum}</p>
      <p>Opłacono: {paid}</p>
      <p>Suma do zapłaty: {sumToPay}</p>
    </div>
  )
}
