/** @jsx jsx */
import React, { useState, useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory } from 'react-router-dom'
import { colors, s, alpha, globals } from '../../style'
import { ButtonBase } from '@material-ui/core'

const TopNav = ({
  children,
  offset = 100,
  childrenVisible,
  extraCssInner,
  extraCssOuter,
  isScrolledAlready,
}) => {
  return <div />
  /*
  const history = useHistory()
  const [isScrolled, setScrolled] = useState(isScrolledAlready ? true : false)

  // useEffect(() => {
  //   if (typeof window !== 'undefined')
  //     window.onscroll = function () {
  //       if (!isScrolledAlready && window.pageYOffset <= offset)
  //         setScrolled(false)
  //       else setScrolled(true)
  //     }
  //   return () => (window.onscroll = null)
  // }, [isScrolled, setScrolled, offset, isScrolledAlready])

  const handleScroll = () => {
    console.log(window.scrollY)
    if (!isScrolledAlready && window.scrollY <= offset) setScrolled(false)
    else setScrolled(true)
  }

  useEffect(() => {
    window.addEventListener('scroll', (e) => handleScroll(e))

    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener('scroll', (e) => handleScroll(e))
    }
  }, [])

  return (
    <nav
      css={[
        sContainer,
        isScrolled && {
          backgroundColor: '#ffffff',
          boxShadow: `0px 4px 24px ${colors.text.concat(alpha[8])}`,
        },
        extraCssOuter,
      ]}>
      <div css={sInner}>
        <div
          css={[
            {
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
            },
            extraCssInner,
          ]}>
          <ButtonBase css={globals.sIcon}>
            <ArrowBackIcon onClick={history.goBack} fontSize='inherit' />
          </ButtonBase>

          <button onClick={() => setScrolled(!isScrolled)}>
            {' '}
            {isScrolled ? 'scrolled' : 'not scrolled'}
          </button>
          <div css={[sChildren, isScrolled ? { opacity: 1 } : { opacity: 0 }]}>
            {children}
          </div>
          <div className={isScrolled ? `scrolled` : ``}>{childrenVisible}</div>
        </div>
      </div>
    </nav>
  )
  */
}

const sContainer = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  zIndex: 1000,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [s.sm_down]: {
    height: globals.topnavHeight.mobile,
  },
  height: globals.topnavHeight.desktop,
  backgroundColor: 'transparent',
  boxShadow: `0px 4px 24px ${colors.text.concat(alpha[0])}`,
  transition: 'background-color 0.3s ease-out, box-shadow 0.3s ease-out',
}

const sInner = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxWidth: globals.maxWidthMedium,
  width: '100vw',
  height: '100%',
  padding: '0 1.5rem',
  boxSizing: 'border-box',
}

const sChildren = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: 12,
  transition: 'opacity 0.3s ease-out',
  fontSize: '1rem',
  fontWeight: 800,
  width: '100%',
}

export default TopNav
