/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { sDescription, sGrid } from '../../../../style/style'
import { Grid, TextField } from '@material-ui/core'
import { sInput } from '../../../style/input.style'
import Creatable from '../../../components/autocompletes/Creatable'
import { useProductContext } from '../context/product.provider'
import AutocompleteInput from '../../../components/autocompletes/AutocompleteInput'
import {
  flavors as flavorTypes,
  cookMethods as cookMethodTypes,
  categoryTypes,
  ingredientTypes,
} from '../../../../constants/types'
import ImageProduct from './ImageProduct'

const Details = ({ children }) => {
  const {
    productInfo,
    onChangeInfo,
    flavors,
    handleFlavors,
    cookMethod,
    handleCookMethod,
    categories,
    handleCategories,
    asyncCategories,
    ingredients,
    handleIngridients,
    asyncIngridients,
  } = useProductContext()

  return (
    <Grid container css={sGrid}>
      <p css={sDescription}>
        Modyfikuj szczegóły produktu, zarządzaj godzinami dostępności oraz
        parametrami wyszukiwania.
      </p>
      <Grid item xs={12} md={12} css={{ marginTop: '2rem' }}>
        <TextField
          required
          label='Nazwa'
          name='name'
          value={productInfo.name}
          onChange={onChangeInfo}
          fullWidth
          css={sInput}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='Opis'
          name='description'
          value={productInfo.description}
          onChange={onChangeInfo}
          fullWidth
          css={sInput}
          multiline
          rowsMax={10}
          rows={2}
        />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteInput
          language='pl'
          options={flavorTypes}
          label='Smaki'
          // placeholder='Smaki'
          freeSolo={false}
          stateArr={flavors}
          setStateArr={handleFlavors}
        />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteInput
          language='pl'
          options={cookMethodTypes}
          label='Przyrządzenie'
          // placeholder='Przyrządzenie'
          freeSolo={false}
          stateArr={cookMethod}
          setStateArr={handleCookMethod}
        />
      </Grid>
      <Grid item xs={12}>
        <Creatable
          label='Kategorie'
          stateArr={categories}
          setStateArr={handleCategories}
          data={asyncCategories?.data?.categories || []}
          language='pl'
          types={categoryTypes}
        />
      </Grid>
      <Grid item xs={12}>
        <Creatable
          label='Składniki'
          placeholder='Składniki'
          stateArr={ingredients}
          setStateArr={handleIngridients}
          data={asyncIngridients?.data?.ingredients || []}
          language='pl'
          types={ingredientTypes}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          type='number'
          label='Czas przygotowania, min'
          name='cookTime'
          value={productInfo.cookTime}
          onChange={onChangeInfo}
          fullWidth
          css={sInput}
        />
      </Grid>
      <ImageProduct />
      {children}
    </Grid>
  )
}

export default Details
