import React from 'react'
import TextInput from '../../../components/TextInput/TextInput'

import { useRestaurantContext } from '../context/restaurant.provider'


export default () => {
  const {
    restaurantInfo,
    handleRestaurantInfo
  } = useRestaurantContext()

  return (
    <TextInput
      required
      label="Nazwa"
      name="name"
      value={restaurantInfo.name}
      onChange={handleRestaurantInfo}
      css={{
        width: '100%',
        marginBottom: 16,
        "input": { fontSize: 24, fontWeight: 700 }
      }}
    />
  )
}