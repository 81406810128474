/** @jsx jsx */
import React, { useState, useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Button } from '../../../../components/Elements'

export default ({
  restId,
  orderId,
  // needToPay,
}) => (
  <Button
    // isLoading={needToPay}
    w100
    w100Desktop
    link={`/order-active-menu?restId=${restId}&orderId=${orderId}`}
    buttonTransparent={true}>
    Domów
  </Button>
)
