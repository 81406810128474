/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars


export default ({ condition, children }) => !!condition && (
  <h4 css={{
    margin: '20px 0 8px',
    fontSize: 16
  }}>
    {children}
  </h4>
)