/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import useOrdersMutations from '../../../hooks/useOrders.mutations'
import { Collapse } from '@material-ui/core'
import { Button } from '../../../../components/Elements'
import Headline from '../../../../components/Headline/Headline'
import WidgetContainer from '../../../../components/Widget/Widget.component'
import { sGrid, sDescription } from '../../../../style/style'
import { s } from '../../../../style'

const ButtonCancel = ({
  orderId,
  isCancelWidgetOpen,
  setIsCancelWidgetOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  // const [isDialog, setIsDialog] = useState(false)
  const onClickOpen = () => setIsCancelWidgetOpen(true)
  const onClickClose = () => setIsCancelWidgetOpen(false)

  const { handleUpdateOrderStatus } = useOrdersMutations()

  const onAnnul = () => {
    setIsLoading(true)
    const cb = () => {
      setIsLoading(false)
      onClickClose()
    }
    handleUpdateOrderStatus({ orderId, status: 'ANNUL_BY_CLIENT' }, cb, cb)
  }

  return (
    <React.Fragment>
      <Button dimmed w100Mobile action={onClickOpen}>
        Anuluj Zamówienie
      </Button>
      <WidgetContainer
        isWidgetOpen={isCancelWidgetOpen}
        setIsWidgetOpen={setIsCancelWidgetOpen}>
        <Collapse in={isCancelWidgetOpen}>
          <div>
            <Headline extraCss={sHeadline}>Anulowanie</Headline>
            <div css={[sGrid, sGridOverride]}>
              <p css={[sDescription, { fontSize: '0.875rem' }]}>
                Anulowanie zamówienia jest bezpowrotne. Restauracja zostanie
                poinformowana o Twojej decyzji. W przypadku zamówienia
                opłaconego, środki zostaną zwrócone na Twoje konto Eatzon
              </p>
            </div>
            <div css={[sFormContainer]}>
              <Button
                loadingSpinner
                w100
                w100Desktop
                dimmed
                action={onAnnul}
                loading={isLoading}>
                Tak, Anuluj Zamówienie
              </Button>
              <Button
                loadingSpinner
                w100
                w100Desktop
                action={onClickClose}
                loading={isLoading}>
                Nie, Zamknij
              </Button>
            </div>
          </div>
        </Collapse>
      </WidgetContainer>
    </React.Fragment>
  )
}

const sHeadline = {
  [s.sm_down]: { padding: '2rem 2rem 1rem' },
  [s.md]: { padding: '2rem 4rem 2rem' },
}

const sGridOverride = {
  [s.sm_down]: { padding: '0 2rem' },
  [s.md]: { padding: '0 4rem' },
}

const sFormContainer = {
  display: 'flex',
  [s.sm_down]: {
    padding: '0 1.5rem 2rem',
    gap: '1rem',
    flexDirection: 'column',
  },
  [s.md]: { padding: '0.5rem 3rem 3rem', gap: '2rem' },
}

export default ButtonCancel
