/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core'
import { Collapsable, Bullet, Row } from '../../../../components/Elements'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import FastfoodIcon from '@material-ui/icons/Fastfood'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle'
import CancelIcon from '@material-ui/icons/Cancel'

import { colors, alpha, s } from '../../../../style'

const OrderPaymentLarge = ({ deliveryCost, priceSum, paidSum, isDelivery }) => {
  const productsCost = (priceSum - deliveryCost).toFixed(
    priceSum - deliveryCost === 0 ? 0 : 2
  )
  const paymentLeft = (priceSum - paidSum).toFixed(
    priceSum - paidSum === 0 ? 0 : 2
  )

  const money = (price) =>
    `${price.toFixed(2)}`.slice(-2) === '00'
      ? price.toFixed(0)
      : price.toFixed(2)

  return (
    <Grid item xs={12} md={6}>
      <Collapsable
        noPadding
        oneLiner
        title={'Płatność'}
        icon={<MonetizationOnIcon />}
        description={
          <div
            css={{
              overflowX: 'scroll',
            }}>
            <>
              Opłacono&nbsp;
              <span
                css={{
                  color: paidSum === priceSum ? colors.green : colors.red,
                  fontWeight: 800,
                }}>
                {money(paidSum)}
              </span>
              &nbsp;zł
            </>
            <Bullet />
            z&nbsp;
            <span css={{ color: colors.text, fontWeight: 800 }}>
              {money(priceSum)}&nbsp;
            </span>
            zł
          </div>
        }>
        <Row desc={'Produkty'} icon={<FastfoodIcon fontSize='inherit' />}>
          {productsCost} zł
        </Row>
        {isDelivery && (
          <Row desc={'Dostawa'} icon={<LocalShippingIcon fontSize='inherit' />}>
            {deliveryCost} zł
          </Row>
        )}
        <Row
          desc={'Opłacono'}
          icon={
            paidSum === priceSum ? (
              <CheckCircleIcon fontSize='inherit' />
            ) : paidSum > 0 ? (
              <SwapHorizontalCircleIcon fontSize='inherit' />
            ) : (
              <CancelIcon fontSize='inherit' />
            )
          }
          extraCss={
            paidSum === priceSum
              ? stylePaid
              : paidSum > 0
              ? stylePaidPartially
              : styleNotPaid
          }>
          {paidSum.toFixed(paidSum === 0 ? 0 : 2)} zł
        </Row>
        <Row
          desc={'Pozostało'}
          icon={<MoneyOffIcon fontSize='inherit' />}
          extraCss={{ border: 'none' }}>
          {paymentLeft} zł
        </Row>
        <Row
          desc={'Łącznie'}
          icon={<AttachMoneyIcon fontSize='inherit' />}
          focus>
          {priceSum.toFixed(2)} zł
        </Row>
      </Collapsable>
    </Grid>
  )
}

const stylePaid = {
  backgroundColor: colors.green.concat(alpha[16]),
  color: colors.green,
  '.row-desc': {
    color: colors.green,
  },
  boxShadow: `0px 0px 0px 1px ${colors.green.concat(alpha[30])}`,
  border: 'none',
  '&:hover': {
    [s.hover]: { backgroundColor: colors.green.concat(alpha[24]) },
  },
}

const styleNotPaid = {
  backgroundColor: colors.red.concat(alpha[16]),
  color: colors.red,
  '.row-desc': {
    color: colors.red,
  },
  boxShadow: `0px 0px 0px 1px ${colors.red.concat(alpha[30])}`,
  border: 'none',
  '&:hover': {
    [s.hover]: { backgroundColor: colors.red.concat(alpha[24]) },
  },
}

const stylePaidPartially = {
  backgroundColor: colors.orange.concat(alpha[16]),
  color: colors.orange,
  '.row-desc': {
    color: colors.orange,
  },
  boxShadow: `0px 0px 0px 1px ${colors.orange.concat(alpha[30])}`,
  border: 'none',
  '&:hover': {
    [s.hover]: { backgroundColor: colors.orange.concat(alpha[24]) },
  },
}

export default OrderPaymentLarge
