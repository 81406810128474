/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';

import MoneyEz from '../../../../helpers/MoneyEz'
import { krakenUrls } from '../../../../constants/s3-files'
import { useAddProductsContext } from '../context/orders-add-product.context'


export default ({
  _id,
  name,
  price,
  photoUrl,
}) => {
  const {
    addProduct,
    deleteProduct,
    cart
  } = useAddProductsContext()
  const product = cart.find(prod => prod._id === _id)

  const productInfo = {
    _id,
    price,
    name,
    photoUrl
  }

  const handlers = {
    onAddProduct: () => addProduct({ ...productInfo }),
    onDeleteProduct: () => deleteProduct({ ...productInfo })
  }

  return (
    <Container>
      <ProductImage photoUrl={photoUrl} />
      <InfoContainer>
        <ProductName name={name} />
        <ProductPrice price={price} />
        <AddRemoveProduct amount={product?.amount || 0} handlers={handlers} />
      </InfoContainer>
    </Container>
  )
}

// Helper Components
const Container = ({ children }) => (
  <div css={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 8,
    width: '100%',
    maxWidth: 160,
    minHeight: 120,
    backgroundColor: '#f3f2f2',
    borderRadius: 8,
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
  }}>
    {children}
  </div>
)

const ProductImage = ({ photoUrl }) => photoUrl && (
  <img
    src={krakenUrls.medium + photoUrl}
    alt=""
    css={{
      width: '100%',
      borderRadius: '4px 4px 0 0',
      margin: '0 auto'
    }}
  />
)

const InfoContainer = ({ children }) => (
  <div css={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: 8,
    position: 'relative'
  }}>
    {children}
  </div>
)

const ProductName = ({ name }) => <h5 css={{ opacity: '.7', marginBottom: 4 }}>{name}</h5>

const ProductPrice = ({ price }) => (
  <p css={{ fontSize: 14, lineHeight: '22px' }}>
    {MoneyEz(price).getAmountWithFullPrecisionAndZl()}
  </p>
)


const AddRemoveProduct = ({ amount, handlers }) => (
  <AddRemoveProductContainer amount={amount}>
    {!!amount && (
      <IconButton size="small" onClick={handlers.onDeleteProduct}>
        <RemoveIcon css={{ fontSize: 16, margin: 4, color: '#000' }} />
      </IconButton>
    )}
    {!!amount && <span css={{ fontWeight: 700 }}>{amount}</span>}
    <IconButton size="small" onClick={handlers.onAddProduct}>
      <AddIcon css={{ fontSize: 16, margin: 4, color: '#000' }} />
    </IconButton>
  </AddRemoveProductContainer>
)

const AddRemoveProductContainer = ({ children, amount }) => (
  <div css={{
    backgroundColor: '#FFD600',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    bottom: 4,
    right: 4,
    width: amount === 0 ? 30 : 'calc(100% - 8px)',
    transition: 'width .1s linear'
  }}>
    {children}
  </div>
)