import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { useError } from '../../hooks/errors.hook'

import {
  SEND_ORDER_TO_REST,
  ADD_PRODUCT_TO_ORDER,
  DELETE_PRODUCT,
  CREATE_ORDER_ADD_PROD,
  DELETE_ORDER,
  ADD_ORDERS_TO_USER,
  GET_ORDERS_IN_CART,
  GET_ACTIVE_ORDERS,
  GET_ACTIVE_ORDER,
  ACTIVE_ORDER_ADD_PROD,
  ACTIVE_ORDER_DELETE_PROD,
  ACTIVE_ORDER_SEND_TO_REST,
  UPDATE_ORDER_STATUS,
  PAY_FOR_ORDER_FROM_WALLET,
  GET_ACTIVE_ORDER_CART,
  FINISH_THE_ORDER,
  ANNUL_ORDER_BY_CLIENT,
} from '../graphql/orders.graphql'

import { useAuthContext } from '../../context/auth.context'
import { useNoAuthOrdersContext } from '../context/NoAuthOrders/NoAuthOrders.provider'
import { consumptionTypeTypes } from '../../constants/types'

export default function useOrdersMutations() {
  const { errorHandling } = useError()
  const { isAuth } = useAuthContext()
  const {
    onCreateOrderAddProdNoAuth,
    onAddProdNoAuth,
    onDeleteProdNoAuth,
    onDeleteOrderNoAuth,
  } = useNoAuthOrdersContext()

  const [orderDeleteOrder] = useMutation(DELETE_ORDER)

  const [orderAddProduct] = useMutation(ADD_PRODUCT_TO_ORDER)
  const [orderDeleteProduct] = useMutation(DELETE_PRODUCT)
  const [orderSendOrderToRest] = useMutation(SEND_ORDER_TO_REST)

  const [orderCreateAddProd] = useMutation(CREATE_ORDER_ADD_PROD)
  const [orderAddToUser] = useMutation(ADD_ORDERS_TO_USER)

  // ACTIVE ORDER
  const [orderAddProductToActiveOrder] = useMutation(ACTIVE_ORDER_ADD_PROD)
  const [orderDeleteProductFromActiveOrder] = useMutation(
    ACTIVE_ORDER_DELETE_PROD
  )
  const [orderSendActiveOrderToRest] = useMutation(ACTIVE_ORDER_SEND_TO_REST)

  const [orderUpdateStatus] = useMutation(UPDATE_ORDER_STATUS)
  const [orderPayFromWallet] = useMutation(PAY_FOR_ORDER_FROM_WALLET)
  const [orderFinishTheOrder] = useMutation(FINISH_THE_ORDER)
  const [orderAnnulByClient] = useMutation(ANNUL_ORDER_BY_CLIENT)

  const [errors, setErrors] = useState([])
  const history = useHistory()

  // Order handlers
  const handleDeleteOrder = (data, cb, errCb) => {
    const { orderId } = data

    if (!isAuth) {
      onDeleteOrderNoAuth(data, cb)
      toast.warning('Zamówienie zostało usunięte', { autoClose: 6000 })
      return
    }

    orderDeleteOrder({
      variables: { orderId },
      refetchQueries: [{ query: GET_ORDERS_IN_CART }],
    })
      .then((res) => {
        toast.warning('Zamówienie zostało usunięte', { autoClose: 6000 })
        if (typeof cb === 'function') cb(res)
      })
      .catch((err) => {
        errorHandling(err)
        if (typeof errCb === 'function') errCb(err)
      })
  }

  const handleCreateOrderAddProd = (
    data,
    cb,
    noauthProdData,
    noauthRestData
  ) => {
    const { consumptionDetails, prodId, restId } = data
    const input = consumptionDetails
      ? { ...consumptionDetails, restId }
      : { restId }

    if (!isAuth) {
      onCreateOrderAddProdNoAuth(data, cb, noauthProdData, noauthRestData)
      toast.success('🛍️ Dodano produkt do koszyka', { autoClose: 5000 })
      return
    }

    orderCreateAddProd({
      variables: { input, prodId },
      refetchQueries: [{ query: GET_ORDERS_IN_CART }],
    })
      .then(() => {
        if (typeof cb === 'function') cb()
      })
      .catch((err) => {
        errorHandling(err)
      })
  }

  const handleSendOrderToRest = (data, cb, errCb) => {
    if (!isAuth) return

    const {
      consumptionDetails,
      orderId,
      frontendPrices,
      refetch: refetchOrdersInCart,
    } = data

    if (
      !consumptionDetails.consumptionDate ||
      !consumptionDetails.consumptionTime
    ) {
      toast.warning('📆 Data rezerwacji jest wymagana')
      return errors.find((el) => el.type === 'NO_DATE')
        ? null
        : setErrors([...errors, { type: 'NO_DATE' }])
    }

    if (
      consumptionDetails.consumptionType === consumptionTypeTypes.delivery &&
      !consumptionDetails.deliveryAddress
    ) {
      toast.warning('📍 Adres dostawy jest wymagany')
      return errors.find((el) => el.type === 'NO_DELIVERY_ADDRESS')
        ? null
        : setErrors([...errors, { type: 'NO_DELIVERY_ADDRESS' }])
    }

    const { restId, ...otherConsumptionDetails } = consumptionDetails
    orderSendOrderToRest({
      variables: { orderId, input: otherConsumptionDetails, frontendPrices },
      refetchQueries: [{ query: GET_ORDERS_IN_CART }],
    })
      .then(({ data }) => {
        setErrors([])
        toast.info(data.orderSendOrderToRest.message, { autoClose: false })
        if (cb && typeof cb === 'function') cb()
        history.push('/order-active/'.concat(data.orderSendOrderToRest._id))
      })
      .catch((err) => {
        const x = 'CHANGED_PRICE'
        if (err.message.includes(x)) {
          toast.error(err.message.slice(x.length))
          refetchOrdersInCart()
        } else {
          errorHandling(err)
        }
        if (errCb && typeof errCb === 'function') errCb()
      })
  }

  // Product handlers
  const handleAddProduct = (data, cb, noAuthProdData) => {
    const { orderId, prodId } = data

    if (!isAuth) {
      onAddProdNoAuth(data, cb, noAuthProdData)
      return
    }

    orderAddProduct({
      variables: { orderId, prodId },
      refetchQueries: [{ query: GET_ORDERS_IN_CART }],
    })
      .then(() => cb && cb())
      .catch((err) => {
        errorHandling(err)
      })
  }

  const handleDeleteProduct = (data, cb) => {
    const { orderId, prodId, isDeleteAllProds } = data

    if (!isAuth) {
      onDeleteProdNoAuth(data, cb)
      return
    }

    orderDeleteProduct({
      variables: { orderId, prodId, isDeleteAllProds },
      refetchQueries: [{ query: GET_ORDERS_IN_CART }],
    })
      .then(() => cb && cb())
      .catch((err) => {
        errorHandling(err)
      })
  }

  // Active orders
  const handleAddProductToActive = (data, cb) => {
    const { orderId, prodId } = data
    if (!isAuth) return

    orderAddProductToActiveOrder({
      variables: { orderId, prodId },
      refetchQueries: [
        { query: GET_ACTIVE_ORDER_CART, variables: { orderId } },
      ],
    })
      .then(() => cb && cb())
      .catch((err) => {
        errorHandling(err)
      })
  }

  const handleDeleteProductFromActive = (data, cb) => {
    const { orderId, prodId, isDeleteAllProds } = data
    if (!isAuth) return

    orderDeleteProductFromActiveOrder({
      variables: { orderId, prodId, isDeleteAllProds },
      refetchQueries: [
        { query: GET_ACTIVE_ORDER_CART, variables: { orderId } },
      ],
    })
      .then(() => cb && cb())
      .catch((err) => {
        errorHandling(err)
      })
  }

  const handleSendActiveOrderToRest = (data, cb) => {
    const { orderId } = data

    if (!isAuth) return

    orderSendActiveOrderToRest({
      variables: { orderId },
      refetchQueries: [{ query: GET_ORDERS_IN_CART }],
    })
      .then(() => {
        setErrors([])
        history.push(`/order-active/${orderId}`)
        if (cb) cb()
      })
      .catch((err) => {
        errorHandling(err)
      })
  }

  const handleUpdateOrderStatus = (data, cb, errCb) => {
    const { orderId, status } = data
    if (!isAuth) return

    orderUpdateStatus({
      variables: { orderId, status },
      refetchQueries: [{ query: GET_ACTIVE_ORDERS }],
    })
      .then((res) => {
        if (typeof cb === 'function') cb(res)
      })
      .catch((err) => {
        if (typeof errCb === 'function') errCb(err)
        errorHandling(err)
      })
  }

  const handlePayForOrderFromWallet = (data, cb) => {
    const { orderId } = data

    orderPayFromWallet({
      variables: { orderId },
      refetchQueries: [{ query: GET_ACTIVE_ORDER, variables: { orderId } }],
    })
      .then((res) => {
        if (typeof cb === 'function') cb(res)
      })
      .catch((err) => {
        errorHandling(err)
        if (typeof cb === 'function') cb()
      })
  }

  const handleFinishTheOrder = (data, cb) => {
    const { orderId } = data
    orderFinishTheOrder({
      variables: { orderId },
      refetchQueries: [{ query: GET_ACTIVE_ORDERS }],
    })
      .then((res) => {
        if (typeof cb === 'function') cb(res)
        toast.info('Zamówienie zostało zakończone')
        history.push('/orders')
      })
      .catch((err) => {
        errorHandling(err)
        if (typeof cb === 'function') cb()
      })
  }

  const handleAnnulOrderByClient = (data, cb) => {
    const { orderId } = data
    orderAnnulByClient({
      variables: { orderId },
      refetchQueries: [{ query: GET_ACTIVE_ORDERS }],
    })
      .then((res) => {
        if (typeof cb === 'function') cb(res)
        toast.info('Zamówienie zostało anulowane')
        history.push('/orders')
      })
      .catch((err) => {
        errorHandling(err)
        if (typeof cb === 'function') cb()
      })
  }

  return {
    handleDeleteOrder,
    handleCreateOrderAddProd,
    handleSendOrderToRest,
    handleAddProduct,
    handleDeleteProduct,
    errors,

    handleAddProductToActive,
    handleDeleteProductFromActive,
    handleSendActiveOrderToRest,
    handleFinishTheOrder,
    handleAnnulOrderByClient,

    handleUpdateOrderStatus,

    orderAddToUser,
    handlePayForOrderFromWallet,
  }
}
