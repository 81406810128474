/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { consumptionTypeNames } from '../../../../constants/types'
import { dateFc } from '../../../../helpers/date-format'
import endWordHelper from '../../../../helpers/endWord.helper'

import { useOrderAttentionContext } from '../context/order-attention.context'



export default () => {
  const { orderWeWorkOn } = useOrderAttentionContext()

  return <OrderAttentionSummaryComponent {...orderWeWorkOn} />
}


export const OrderAttentionSummaryComponent = ({
  shortId,
  ownerId,
  consumptionType,
  consumptionDate,
  consumptionTime,
  consumptionPersons
}) => (

  <div css={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderBottom: '1px solid #444'
  }}>
    <div>
      <p css={{ fontWeight: 700, marginBottom: 4 }}>{ownerId?.personal?.name} {ownerId?.personal?.surname}</p>
      <span>ID zamówienia: {shortId}</span>
    </div>
    <div css={{ textAlign: 'right' }}>
      <p css={{ fontWeight: 700 }}>{consumptionTypeNames[consumptionType]}</p>
      <p css={{ margin: '4px 0' }}>{dateFc(consumptionDate, consumptionTime)}</p>
      <p>{endWordHelper('osoba', consumptionPersons)}</p>
    </div>
  </div>
)