/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { daysOfWeek } from '../../../../constants/daysOfWeek'
import useRestaurantMutations from '../../../hooks/useRestaurant.mutations'
import { useRestaurantContext } from '../context/restaurant.provider'

import deliverySettingsCheck from '../helpers/deliverySettingsCheck'
import deliveryParseFloatAmount from '../helpers/deliveryParseFloatAmounts'
import { sButtonFixed } from '../../../../EZW/pages/Order/buttons/FixedButtons'
import { Button } from '../../../../components/Elements'
import { Collapse } from '@material-ui/core'
import Headline from '../../../../components/Headline/Headline'
import { sGridOverride, sHeadline } from '../../../../EZW/pages/Popups/styles'
import WidgetContainer from '../../../../components/Widget/Widget.component'
import { sDescription, sGrid } from '../../../../style/style'
import { colors } from '../../../../style'

export default () => {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [ezwLoginData, setEzwLoginData] = useState(null)
  const history = useHistory()
  const { handleRestCreate } = useRestaurantMutations()
  const {
    openningHours,
    categories,
    restaurantInfo,
    logoFile,
    backgroundFile,
    deliverySettings,
  } = useRestaurantContext()

  const onSubmit = async () => {
    try {
      setIsLoading(true)
      for (const day in openningHours) {
        if (openningHours[day].open > openningHours[day].close) {
          toast.error(
            `${daysOfWeek[day]} - godzina otwarcia nie może być późniejsza od godziny zamknięcia`
          )
          return
        }
      }

      const categoryIds = []
      const newCategories = []
      categories.forEach((el) => {
        if (typeof el === 'string') categoryIds.push(el)
        else newCategories.push(el.inputValue)
      })

      if (restaurantInfo?.consumptionTypes.includes('delivery')) {
        const deliverySettingsErrors = deliverySettingsCheck(deliverySettings)
        if (deliverySettingsErrors.length > 0) {
          const errors = new Error()
          errors.array = deliverySettingsErrors
          throw errors
        }
        deliveryParseFloatAmount(deliverySettings)
      }

      const input = {
        name: restaurantInfo.name,
        description: restaurantInfo.description,
        categories: categoryIds,
        address: restaurantInfo.address,
        lng: restaurantInfo?.location?.coordinates[0],
        lat: restaurantInfo?.location?.coordinates[1],
        consumptionTypes: restaurantInfo?.consumptionTypes,
        openningHours,
        deliverySettings,
      }

      const errors = []
      if (!restaurantInfo.name) errors.push('Podaj nazwę')
      if (!restaurantInfo.address) errors.push('Podaj adres')
      if (errors.length) {
        errors.forEach((el) => toast.error(el))
        setIsLoading(false)
        return
      }

      const res = await handleRestCreate({
        input,
        newCategories,
        logoFile,
        backgroundFile,
      })

      if (res) setEzwLoginData(res.data?.restCreate)
      setIsLoading(false)
      setOpen(true)
    } catch (err) {
      if (err.array && err.array.length > 0) {
        err.array.forEach((e) => toast.warn(e))
      } else {
        toast.warn(err.message)
      }
      setIsLoading(false)
    }
  }

  const onClose = () => {
    setOpen(false)
    history.push('/waiter')
  }

  return (
    <>
      <div css={sButtonFixed}>
        <Button
          w100
          w100Desktop
          buttonTransparent={true}
          action={onSubmit}
          disabled={isLoading}
          loadingSpinner={isLoading}
          loading={isLoading}>
          Dodaj restaurację
        </Button>
      </div>

      <WidgetContainer isWidgetOpen={open} setIsWidgetOpen={setOpen}>
        <Collapse in={open}>
          <div>
            <Headline extraCss={sHeadline}>Dodano Restaurację</Headline>
            <div css={[sGrid, sGridOverride]}>
              <p css={[sDescription, { fontSize: '0.875rem' }]}>
                Twoja nowa Restauracja została dodana! Poniżej wyświetlono dane
                dostępowe do <strong>Panelu Kelnera</strong>.
              </p>
              <p css={[sDescription, { fontSize: '0.875rem' }]}>
                Login:{' '}
                <strong css={{ color: colors.text }}>
                  {ezwLoginData?.login}
                </strong>
              </p>
              <p css={[sDescription, { fontSize: '0.875rem' }]}>
                Hasło:{' '}
                <strong css={{ color: colors.text }}>
                  {ezwLoginData?.password}
                </strong>
              </p>
              <p css={[sDescription, { fontSize: '0.875rem' }]}>
                Poniższe dane są wyświetlone tylko raz. Zapisz je lub zmień w
                widoku <em>Dostępy</em>
              </p>
              <Button w100 w100Desktop action={onClose}>
                Panel Kelnera
              </Button>
            </div>
          </div>
        </Collapse>
      </WidgetContainer>
    </>
  )
}
