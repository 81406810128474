/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import { jsx, css } from "@emotion/core" // eslint-disable-line no-unused-vars
import { Link } from "react-router-dom"

// import MoneyEz from '../../../helpers/MoneyEz'
import { krakenUrls } from "../../../constants/s3-files"
// import AddRemoveProduct from '../../components/AddRemove/AddRemove.component'
import AddRemoveProductNew from "../../components/AddRemove/AddRemoveNew.component"
import { colors, s, alpha } from "../../../style"
import { Grid } from "@material-ui/core"
import X from "@material-ui/icons/Clear"
import Arrow from "@material-ui/icons/ArrowRightAlt"

const ProdItemMenuList = ({
  data,
  handleAddProduct,
  handleDeleteProduct,
  loading,
  productInOrder,
  noLink,
  id,
  searchView,
}) => {
  const {
    name,
    price,
    photoUrl,
    _id,
    ingredients,
    description,
    restAvatarUrl,
    categories,
  } = data

  console.log(ingredients)

  let ingrString = ""
  if (ingredients) {
    ingredients.forEach(({ name }, index) => {
      if (index + 1 === ingredients.length) ingrString = ingrString.concat(name)
      else ingrString = ingrString.concat(name + ", ")
    })
  }

  // const isDescGreaterThanIngredients = description.length > ingrString.length
  // const descOrIngredients = () =>
  //   isDescGreaterThanIngredients ? description : ingrString

  // console.log(productInOrder)

  const photo = photoUrl ? photoUrl : restAvatarUrl ? restAvatarUrl : false
  const categoriesString = categories?.map(
    (cat, id) => (id === 0 ? "" : ", ") + cat.name
  )

  return (
    <Grid item xs={12} md={6}>
      <div
        css={[
          sContainer,
          id % 2 === 1 && { [s.md]: { marginLeft: "0.75rem" } },
          id % 2 === 0 && { [s.md]: { marginRight: "0.75rem" } },
          productInOrder && { borderRadius: "0px 16px 16px 0px" },
        ]}
      >
        <div css={[sProdInOrder, productInOrder && { opacity: 1 }]} />
        <Link
          to={`/product/${_id}`}
          css={[sTextContainer, noLink && { pointerEvents: "none" }]}
        >
          <div>
            <h5 className='prod-item-title'>{name.toLowerCase()}</h5>
            <p className='prod-item-ingredients'>
              {/* {photoUrl ? ingrString || description : descOrIngredients ? description : ingrString} */}
              {searchView
                ? ingrString || description || categoriesString
                : photoUrl
                ? ingrString || description
                : description || ingrString}
            </p>
          </div>
          <div
            css={[
              sImageWrapper,
              !photo && {
                backgroundColor: colors.gray200,
                [s.sm_down]: { boxShadow: "none" },
                [s.md]: { boxShadow: "none" },
              },
            ]}
          >
            {photo && (
              <img
                className='prod-item-image'
                src={krakenUrls.small + photo}
                alt={""}
                loading='lazy'
              />
            )}
          </div>
        </Link>
        <div
          css={[
            {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              position: "relative",
              marginTop: "1rem",
              [s.md]: { marginTop: "2rem" },
            },
            !photoUrl && { marginTop: 0, [s.md]: { marginTop: 0 } },
          ]}
        >
          <p css={sPrice}>
            {productInOrder?.amount > 0 && (
              <>
                {productInOrder?.amount}{" "}
                <span css={sIcon}>
                  <X />
                </span>
              </>
            )}
            {price.toFixed(2)} <span>&nbsp;zł</span>
            {productInOrder?.amount > 1 && (
              <>
                <span css={sIcon}>
                  <Arrow />
                </span>
                {(productInOrder.amount * price).toFixed(2)}{" "}
                <span>&nbsp;zł</span>
              </>
            )}
          </p>
          {handleAddProduct && (
            <AddRemoveProductNew
              amount={productInOrder?.amount || 0}
              onAdd={handleAddProduct}
              onDelete={(e) => {
                e.preventDefault()
                handleDeleteProduct(e)
              }}
            />
          )}
        </div>
      </div>
    </Grid>
  )
}

export const sContainer = {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: 16,
  // backgroundColor: colors.gray100,
  background: `linear-gradient(to right, ${
    colors.gray100
  }, ${colors.gray100.concat(alpha[50])})`,
  transition:
    "background-color 0.3s ease-out, border-radius 0.15s ease-out, box-shadow 0.3s ease-out",
  marginBottom: "1.5rem",
  padding: "2rem",
  [s.sm_down]: { marginBottom: "1rem", padding: "1rem" },
  [s.md]: { height: "calc(100% - 1.5rem)" },

  "&:hover": {
    [s.hover]: {
      backgroundColor: colors.gray200,
      ".prod-item-image": {
        transform: "scale(1.1)",
        filter: "brightness(1.1)",
      },
      ".prod-item-button": {
        backgroundColor: colors.gray400,
      },
    },
  },
}

const sProdInOrder = {
  opacity: 0,
  position: "absolute",
  top: 0,
  left: -12,
  width: 12,
  transition: "opacity 0.3s ease-out",
  height: "100%",
  backgroundColor: colors.green,
  // boxShadow: `-12px 0px 0px 0px ${colors.green}`,
  borderRadius: "8px 0px 0px 8px",
  // [s.md]: { boxShadow: `inset 12px 0px 0px 0px ${colors.green}` },
}

export const sImageWrapper = {
  flexShrink: 0,
  position: "relative",
  borderRadius: "50%",
  width: 72,
  height: 72,
  [s.md]: {
    width: 96,
    height: 96,
    boxShadow: `-2px 4px 24px ${colors.text.concat(alpha[8])}`,
  },
  [s.sm_down]: {
    boxShadow: `-1px 2px 24px ${colors.text.concat(alpha[8])}`,
  },

  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center center",
    borderRadius: "inherit",
    transition: "transform 0.3s ease-in-out, filter 0.3s ease-in-out",
  },
}

export const sTextContainer = {
  display: "flex",
  justifyContent: "space-between",
  height: "100%",

  ".prod-item-title": {
    fontWeight: 800,
    fontSize: "0.8125rem",
    letterSpacing: "-0.025em",
    textTransform: "capitalize",
    marginBottom: "0.25rem",
    marginRight: "1rem",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    [s.md]: {
      marginRight: "2rem",
      fontSize: "0.9375rem",
      marginBottom: "0.5rem",
    },
    [s.xl]: { maxWidth: "90%" },
  },

  // '.prod-item-desc': { opacity: '.6', fontSize: 14 },

  ".prod-item-ingredients": {
    opacity: 0.6,
    fontSize: "0.75rem",
    marginRight: "1rem",
    [s.md]: { marginRight: "2rem", fontSize: "0.875rem" },
    [s.xl]: { maxWidth: "90%" },
  },
}

export const sPrice = {
  fontWeight: 800,
  fontSize: "0.75rem",
  [s.md]: { fontSize: "0.875rem" },
  span: {
    opacity: 0.3,
  },
  display: "flex",
  alignItems: "center",
}

const sIcon = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  margin: "0 0.25rem",
  svg: { fontSize: "0.875rem" },
  [s.md]: { svg: { marginTop: 1 } },
}

export default ProdItemMenuList
