/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { s, globals, colors } from '../../../../style'
import { Bullets } from '../../../../components/Elements'
import { krakenUrls } from '../../../../constants/s3-files'
import {
  consumptionTypeNames,
  consumptionTypeIcons,
} from '../../../../constants/types'

const Intro = ({
  photo,
  photoAvatar,
  title,
  categories,
  description,
  children,
  restAvatar,
  consumptionTypes,
}) => {
  return (
    <div
      css={{
        position: 'relative',
        zIndex: 1,
        width: '100vw',
        maxWidth: globals.maxWidthMedium,
        margin: '0 auto',
        marginTop: globals.topnavHeight.mobile,
        [s.md]: { marginTop: globals.topnavHeight.desktop + 64 },
      }}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          [s.sm_down]: { flexDirection: 'column' },
        }}>
        <div css={{ [s.md]: { width: '60%' } }}>
          <div
            css={{
              // backgroundColor: 'red',
              padding: '1.5rem 1.5rem 2rem',
              display: 'flex',
              [s.md]: {
                alignItems: 'center',
              },
              [s.md_only]: {
                padding: '1.5rem 1.5rem 1rem',
              },
            }}>
            <div
              css={{
                borderRadius: '50%',
                width: 64,
                height: 64,
                flexShrink: 0,
                boxShadow: '0px 4px 8px rgba(0,0,0,0.08)',
                [s.md]: {
                  width: 96,
                  height: 96,
                },
                img: {
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center center',
                  // borderRadius: 32,
                  borderRadius: '50%',
                },
              }}>
              {(photoAvatar || restAvatar) && (
                <img
                  src={
                    photoAvatar
                      ? krakenUrls.small + photoAvatar
                      : krakenUrls.small + restAvatar
                  }
                  alt={title}
                  loading='lazy'
                />
              )}
            </div>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '1.5rem',
                [s.md]: {
                  marginLeft: '2rem',
                },
              }}>
              <h1
                css={[
                  {
                    fontSize: '2rem',
                    fontWeight: 800,
                    letterSpacing: '-0.05em',
                    lineHeight: '1em',
                    paddingBottom: '0.5rem',
                    [s.ss]: {
                      fontSize: '1.5rem',
                    },
                    [s.lg]: {
                      paddingBottom: '1rem',
                      fontSize: '2.5rem',
                    },
                  },
                  title.length >= 20 && {
                    [s.xs]: {
                      fontSize: '1.5rem',
                    },
                    [s.ss]: {
                      fontSize: '1.25rem',
                    },
                  },
                  title.length >= 40 && {
                    [s.xs]: {
                      fontSize: '1.25rem',
                    },
                    [s.ss]: {
                      fontSize: '1.25rem',
                    },
                    [s.sm]: {
                      fontSize: '1.5rem',
                    },
                    [s.lg]: {
                      fontSize: '2rem',
                    },
                  },
                ]}>
                {title}
              </h1>
              <div
                css={[
                  {
                    display: 'flex',
                    flexWrap: 'wrap',
                    textTransform: 'capitalize',
                    lineHeight: '1.618em',
                    fontSize: '1rem',
                    [s.lg]: {
                      fontSize: '1.25rem',
                    },
                  },
                  categories.length > 2 && { fontSize: '0.875rem' },
                ]}>
                <Bullets array={categories} />
              </div>
            </div>
          </div>
          <p
            css={{
              padding: '0 1.5rem',
              opacity: 0.6,
              [s.sm_down]: { textAlign: 'center' },
              fontSize: '0.875rem',
              lineHeight: '1.618em',
              [s.md]: {
                marginLeft: '8rem',
                paddingRight: '3rem',
              },
              [s.lg]: {
                fontSize: '1rem',
                paddingRight: '6rem',
              },
              [s.xl]: {
                maxWidth: '75%',
              },
            }}>
            {description}
          </p>
          <div
            css={{
              padding: '1.5rem 1.5rem 0',
              [s.sm_down]: { textAlign: 'center' },
              fontSize: '0.875rem',
              lineHeight: '1.618em',
              [s.md]: {
                marginLeft: '8rem',
                paddingTop: '2.5rem',
              },
              [s.lg]: {
                fontSize: '1rem',
              },
              [s.xl]: {
                maxWidth: '75%',
              },
            }}>
            {children}
            {consumptionTypes && consumptionTypes.length > 0 && (
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  [s.md]: {
                    justifyContent: 'flex-start',
                  },
                  color: colors.gray600,
                }}>
                {consumptionTypes.map((type, id) => (
                  <div
                    key={id}
                    css={[
                      {
                        flexDirection: 'column',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      },
                      id !== 0 &&
                        id !== consumptionTypes.length - 1 && {
                          margin: '0 2rem',
                        },
                    ]}>
                    <span css={{ fontSize: '1.5rem' }}>
                      {consumptionTypeIcons[type]}
                    </span>
                    <p
                      css={{
                        fontSize: '0.675rem',
                        fontWeight: 700,
                        textTransform: 'uppercase',
                      }}>
                      {consumptionTypeNames[type]}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {photo && (
          <div
            css={{
              padding: '1.5rem',
              [s.sm_down]: { paddingTop: '2rem', paddingBottom: 0 },
              [s.md]: { width: '40%' },
            }}>
            <div
              css={{
                width: '100%',
                height: '100%',
                paddingTop: '56.25%',
                position: 'relative',
                borderRadius: 24,
                boxShadow: '-4px 8px 32px rgba(0,0,0,0.16)',
                overflow: 'hidden',
                '&:hover': {
                  img: {
                    transform: 'scale(1.1)',
                    filter: 'brightness(1.1)',
                  },
                },
              }}>
              <img
                loading='lazy'
                src={krakenUrls.medium + photo}
                alt={title}
                css={{
                  transition:
                    'transform 0.3s ease-in-out, filter 0.3s ease-in-out',
                  borderRadius: 24,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center center',
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Intro
