/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core'
import { Collapsable, Button } from '../../../../../components/Elements'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { CollapsableText } from '../../../../../components/Elements/Collapsable/Collapsable'
import Input from '../../../../views/Auth/components/Input.component'
import CloseIcon from '@material-ui/icons/Close'
import { s } from '../../../../../style'

const SettingsPersonal = ({ data, handleUserUpdate }) => {
  const [personalData, setPersonalData] = useState(data)
  const [isOpen, setIsOpen] = useState(false)
  const onChange = (e) =>
    setPersonalData({ ...personalData, [e.target.name]: e.target.value })
  const onReset = () => {
    setPersonalData(data)
    setIsOpen(false)
  }
  const onSave = () => handleUserUpdate(personalData, () => setIsOpen(false))
  return (
    <Grid item xs={12} md={6}>
      <Collapsable
        left
        noPadding
        oneLiner
        title={'Użytkownik'}
        icon={<AccountCircleIcon />}
        description={`Informacje personalne`}
        parentIsOpen={isOpen}
        parentSetIsOpen={setIsOpen}>
        <CollapsableText>
          <Input
            name='name'
            placeholder='Imię'
            onChange={onChange}
            value={personalData.name}
            darker
          />
          <Input
            name='surname'
            placeholder='Nazwisko'
            onChange={onChange}
            value={personalData.surname}
            darker
          />
          <Input
            name='email'
            placeholder='Email'
            onChange={onChange}
            value={personalData.email}
            darker
          />
          <div css={{ display: 'flex', gap: '1rem', [s.md]: { gap: '2rem' } }}>
            <Button size48 dimmed action={onReset}>
              <CloseIcon />
            </Button>
            <Button w100 w100Desktop action={onSave}>
              Zapisz
            </Button>
          </div>
        </CollapsableText>
      </Collapsable>
    </Grid>
  )
}

export default SettingsPersonal
