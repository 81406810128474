/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

import { Header } from '../../../components'
import { useActiveOrdersContext } from '../context/orders-active.context'

import OrderActiveSummary from './OrderActiveSummary'
import DeliveryDetails from './DeliveryDetails'
import InvoiceDetails from './InvoiceDetails'
import OrderNote from './OrderNote'

import ActiveProducts from './ActiveProducts'
import HandleProducts from './HandleProducts'
import FinishedProducts from './FinishedProducts'
import ButtonsHandlers from './ButtonsHandlers'

import OrderSum from './OrderSum'
import AnnulOrderButton from './AnnulOrderButton'



export default () => {
  const {
    isOpen,
    onOpen,
    products,
  } = useActiveOrdersContext()

  return (
    <MuiAccordion
      expanded={isOpen}
      onChange={onOpen}
    >
      <MuiAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div css={{ width: '100%' }}>
          <OrderActiveSummary />
          <ButtonsHandlers />
        </div>
      </MuiAccordionSummary>

      <MuiAccordionDetails css={{ width: '100%', display: 'block' }}>
        <DeliveryDetails />
        <InvoiceDetails />
        <OrderNote />

        <Header>Szczegóły zamówienia</Header>
        <ActiveProducts />
        <HrCondition condition={products.length} />

        <HandleProducts />
        <FinishedProducts />
        <HrCondition condition />

        <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <AnnulOrderButton />
          <OrderSum />
        </div>
      </MuiAccordionDetails>
    </MuiAccordion>
  )
}


const HrCondition = ({ condition }) => !!condition && <hr css={{ marginTop: 20, width: 'calc(100% - 16px)' }} />