/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useRoutesEzw } from '../../../routes'
import {
  useWaiterAuthContext,
  WaiterAuthContext,
} from '../../context/auth-waiter.context'
import { useWaiterAuth } from '../../hooks/auth-waiter.hook'

import { Navbar, Menu, AttentionOrder } from '../../components'

import { useError } from '../../../hooks/errors.hook'
import OrdersProvider, { useOrdersContext } from '../../context/orders.context'
import EzwLogin from './views/ezw-login'

/*
  What is going on here:
  1. Check if is auth
  2a. If is auth => show OrdersContainer
  2b. Otherwise, show LoginPage
  3. OrdersContainer just rolling up Orders with OrdersProvider
  4. Orders is main component
*/

export default () => {
  const {
    waiterLogin,
    waiterLogout,
    waiterToken,
    restaurantId,
    ready,
  } = useWaiterAuth()
  const waiterIsAuth = !!waiterToken

  if (!ready) return null

  return (
    <WaiterAuthContext.Provider
      value={{
        waiterLogin,
        waiterLogout,
        waiterToken,
        restaurantId,
        waiterIsAuth,
      }}>
      <EzwIndexInner />
    </WaiterAuthContext.Provider>
  )
}

const EzwIndexInner = () => {
  const { waiterIsAuth } = useWaiterAuthContext()
  return waiterIsAuth ? <OrdersContainer /> : <EzwLogin />
}

const OrdersContainer = () => (
  <OrdersProvider>
    <Orders />
  </OrdersProvider>
)

const Orders = () => {
  const {
    data,
    loading,
    error,
    isAttentionDisable,
    addStyles,
    onClickAttention,
    onCloseAttention,
    showMenu,
    menuToggleDrawer,
    attentionOrders,
  } = useOrdersContext()

  const routes = useRoutesEzw()
  const { errorHandling } = useError()

  if (error) {
    errorHandling(error)
    return null
  }
  if (loading || !data) return <div />

  return (
    <main
      css={{
        display: 'flex',
        maxWidth: 1366,
        width: '100%',
        margin: '0 auto',
      }}>
      <AttentionOrder
        isDisable={isAttentionDisable}
        orderAmount={attentionOrders.length}
        onClick={onClickAttention}
        onClose={onCloseAttention}
      />

      <Menu showMenu={showMenu} menuToggleDrawer={menuToggleDrawer} />

      <Navbar
        addStyles={addStyles}
        attentionOrdersAmount={attentionOrders.length}
        onShowMenu={menuToggleDrawer(true)}
      />

      <div
        css={{
          flexGrow: 1,
          padding: '16px 0',
          height: '100vh',
          overflow: 'scroll',
        }}>
        {routes}
      </div>
    </main>
  )
}
