/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify'
import { useParams, useHistory } from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress'

import isCorrectId from '../../../helpers/check-correct-id'
import { useError } from '../../../hooks/errors.hook'
import { useGetRestMenu } from '../../hooks/useQueries'
import { ezwRoutePrefix } from '../settings'

import OrdersAddProductProvider from './context/orders-add-product.context'
import Cart from './views/Cart'
import CartButton from './views/CartButton'
import MenuSections from './components/MenuSections.component'
import MenuContainer from './components/MenuContainer.component'

const EzwOrdersAddProduct = () => {
  const { data, loading, error } = useGetRestMenu()
  const history = useHistory()
  const orderId = useParams().id
  const { errorHandling } = useError()

  const goOrdersActive = () => history.push(`${ezwRoutePrefix}/orders-active`)

  if (!isCorrectId(orderId) || loading || error || !data)
    return checkHandle({
      orderId,
      goOrdersActive,
      loading,
      error,
      errorHandling,
      data,
    })

  return (
    <OrdersAddProductProvider orderId={orderId}>
      <MenuContainer onClose={goOrdersActive}>
        <MenuSections menuSections={data.waiterMenuSections} />
        <CartButton />

        <Cart />
      </MenuContainer>
    </OrdersAddProductProvider>
  )
}

export default EzwOrdersAddProduct

function checkHandle({
  orderId,
  goOrdersActive,
  loading,
  error,
  errorHandling,
  data,
}) {
  if (!isCorrectId(orderId)) {
    toast.error(
      'Nieprawidłowe ID chatu (2). Spróbuj wejść ponownie. Jeśli nadal widzisz ten błąd, skontaktuj się z nami, używając Chatu z Eatzon, który znajdziesz w widoku Konto lub na podstronie /account'
    )
    goOrdersActive()
    return null
  }

  if (loading) return <LinearProgress />

  if (error) {
    errorHandling(error)
    goOrdersActive()
    return null
  }

  if (!data) {
    toast.warning('😕 Coś poszło nie tak...')
    goOrdersActive()
    return null
  }
}
