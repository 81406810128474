import { useState } from 'react'


export default () => {
  const [state, setState] = useState(initState)

  const onChangeState = {
    name: e => setState({ ...state, name: e.target.value }),

    isConditionalPrice: e => setState({ ...state, isConditionalPrice: !state.isConditionalPrice }),

    conditionalPriceModifierGroupId: _id => setState({ ...state, conditionalPriceModifierGroupId: _id }),

    isPreselectedModifiers: e => setState({ ...state, isPreselectedModifiers: !state.isPreselectedModifiers }),
  }


  const [modifierItems, setModifierItems] = useState(initModifierItems)

  const onAddModifierItems = (arr) => {
    const timestamp = new Date().getUTCMilliseconds()
    const itemsToAdd = arr.map((name, index) => ({
      ...initModifierItems[0],
      _id: timestamp + index,
      name: name.charAt(0).toUpperCase() + name.slice(1),
    }))
    setModifierItems([...modifierItems, ...itemsToAdd])
  }

  const onDeleteModifierItem = _id => setModifierItems(modifierItems.filter(el => el._id !== _id))

  const onChangeModifierItem = {
    name: _id => e => setModifierItems(arrayChangeById(
      modifierItems,
      _id,
      { name: e.target.value }
    )),

    price: _id => e => setModifierItems(arrayChangeById(
      modifierItems,
      _id,
      { price: e.target.value }
    )),

    conditionalPrices: (_id, modifierItemId) => (e) => setModifierItems(modifierItems.map(item =>
      item._id === _id
        ? {
          ...item,
          conditionalPrices: arrayChangeById(
            item.conditionalPrices,
            modifierItemId,
            { conditionalPrice: e.target.value }
          )
        }
        : item
    )),

    isPreselected: _id => e => setModifierItems(modifierItems.map(item =>
      item._id === _id
        ? { ...item, isPreselected: !item.isPreselected }
        : item
    )),
  }


  const [amountRules, setAmountRules] = useState(initAmountRules)

  const onChangeAmountRules = {
    isRequired: () => setAmountRules({
      ...amountRules,
      isRequired: !amountRules.isRequired
    }),

    maxSelectAmount: e => setAmountRules({ ...amountRules, maxSelectAmount: e.target.value }),

    maxSingleItemSelectAmount: e => setAmountRules({ ...amountRules, maxSingleItemSelectAmount: e.target.value }),

    isConditionalQuantities: () => setAmountRules({
      ...amountRules,
      isConditionalQuantities: !amountRules.isConditionalQuantities
    }),

    conditionalModifierGroupId: e => setAmountRules({
      ...amountRules,
      conditionalModifierGroupId: e.target.value
    }),

    conditionalAmounts_maxSelectAmount: e => modifierItemId => setAmountRules({
      ...amountRules,
      conditionalAmounts: arrayChangeById(
        amountRules.conditionalAmounts,
        modifierItemId,
        { maxSelectAmount: e.target.value }
      )
    }),

    conditionalAmounts_maxSingleItemSelectAmount: e => modifierItemId => setAmountRules({
      ...amountRules,
      conditionalAmounts: arrayChangeById(
        amountRules.conditionalAmounts,
        modifierItemId,
        { maxSingleItemSelectAmount: e.target.value }
      )
    })
  }


  return {
    state,
    onChangeState,

    modifierItems,
    setModifierItems,
    onAddModifierItems,
    onDeleteModifierItem,
    onChangeModifierItem,

    amountRules,
    onChangeAmountRules
  }
}


const initState = {
  name: 'Rozmiar',
  isConditionalPrice: false,
  conditionalPriceModifierGroupId: '',
  isPreselectedModifiers: false,
}

const initModifierItems = [{
  _id: '123',
  name: 'Duży',
  price: 19,
  VAT: 0,
  isPreselected: false,
  conditionalPrices: [{
    conditionalPriceModifierItemId: '',
    conditionalPrice: 0
  }]
},
{
  _id: '124',
  name: 'Mały',
  price: 9,
  VAT: 0,
  isPreselected: false,
  conditionalPrices: [{
    conditionalPriceModifierItemId: '',
    conditionalPrice: 0
  }]
}]

const initAmountRules = {
  isRequired: false,
  maxSelectAmount: 1,
  maxSingleItemSelectAmount: 1,
  isConditionalQuantities: false,
  conditionalModifierGroupId: '',
  conditionalAmounts: [{
    conditionalModifierItemId: '',
    maxSelectAmount: 1,
    maxSingleItemSelectAmount: 1
  }]
}


function arrayChangeById(arr, _id, objToAdd) {
  return arr.map(item =>
    item._id === _id
      ? { ...item, ...objToAdd }
      : item
  )
}