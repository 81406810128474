/** @jsx jsx */
import React, { useState, useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useHistory, useLocation } from 'react-router-dom'
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ChatIcon from '@material-ui/icons/Chat';

import { ezAdminRoutePrefix } from '../../pages/settings'


const Layout = ({ children }) => (
  <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <span css={{ display: 'block', height: 16 }} />
    <Navbar />
    <span css={{ display: 'block', height: 16 }} />
    {children}
  </div>
)


const Navbar = () => {
  const createUrl = (url) => ezAdminRoutePrefix.concat(url)
  const { pathname } = useLocation()
  const [value, setValue] = useState(pathname);
  const history = useHistory()

  useEffect(() => { setValue(pathname) }, [pathname])

  return (
    <BottomNavigation
      value={value}
      onChange={(e, newValue) => setValue(newValue)}
      showLabels
      css={{
        margin: '0 auto',
        width: '100%',
        maxWidth: 700,
        height: 72,
        borderRadius: 8
      }}
    >
      <BottomNavigationAction
        onClick={() => history.push(createUrl('/make-restaurateur'))}
        value={createUrl('/make-restaurateur')}
        label="Nadaj restauratora"
        icon={<FavoriteIcon />}
      />
      <BottomNavigationAction
        onClick={() => history.push(createUrl('/transactions-by-restaurants'))}
        value={createUrl('/transactions-by-restaurants')}
        label="Raporty transakcji restauracji"
        icon={<LocationOnIcon />}
      />
      <BottomNavigationAction
        onClick={() => history.push(createUrl('/charge-restaurant'))}
        value={createUrl('/charge-restaurant')}
        label="Obciąż restaurację"
        icon={<RestoreIcon />}
      />
      <BottomNavigationAction
        onClick={() => history.push(createUrl('/change-restaurant-owner'))}
        value={createUrl('/change-restaurant-owner')}
        label="Zmień właściciela restauracji"
        icon={<LocationOnIcon />}
      />
      <BottomNavigationAction
        onClick={() => history.push(createUrl('/chat'))}
        value={createUrl('/chat')}
        label="Czat"
        icon={<ChatIcon />}
      />
    </BottomNavigation>
  );
}



export default Layout