import { useQuery } from '@apollo/client'

import {
  GET_RESTAURANT_AND_CART_ORDER,
  GET_RESTAURANTS,
  GET_RESTAURANT_MENU,
  GET_RESTAURANT_CONSUMPTION_TYPES,
} from '../graphql/rests.graphql'

export const useGetRestaurants = (geoInput) =>
  useQuery(GET_RESTAURANTS, { variables: { geoInput } })

export const useGetRestaurant = (restId, geolocation) =>
  useQuery(GET_RESTAURANT_AND_CART_ORDER, {
    variables: {
      restId,
      geoInput: { lat: geolocation.lat, lng: geolocation.lng },
    },
  })

export const useGetRestaurantSkip = (restId, skip) =>
  useQuery(GET_RESTAURANT_AND_CART_ORDER, {
    variables: {
      restId,
      // geoInput: { lat: 0, lng: 0 },
    },
    // skip: skip,
  })

export const useGetRestaurantMenu = (restId) =>
  useQuery(GET_RESTAURANT_MENU, { variables: { restId } })

export const useGetRestaurantConsumptionTypes = (restId) =>
  useQuery(GET_RESTAURANT_CONSUMPTION_TYPES, { variables: { restId } })
