/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { s } from '../../../style'

const StatusLarge = ({ color, children, frontColor, noPadding, extraCss }) => {
  return (
    <div
      css={[
        sStatusLarge,
        { backgroundColor: color },
        frontColor && { color: frontColor },
        noPadding && {
          paddingTop: 0,
          paddingBottom: 0,
          fontWeight: 500,
        },
        extraCss,
      ]}>
      {children}
    </div>
  )
}

const sStatusLarge = {
  height: 'max-content',
  width: 'min-content',
  lineHeight: '1em',
  padding: '5px 8px 4px',
  textAlign: 'center',
  fontWeight: 800,
  letterSpacing: '-0.025em',
  color: '#ffffff',
  fontSize: '0.75rem',
  borderRadius: '0.75rem',
  whiteSpace: 'nowrap',
  [s.ss]: { wordBreak: 'break-word' },
  [s.sss]: {
    fontSize: 10 / 16 + 'rem',
  },
  [s.lg]: {
    fontSize: '0.875rem',
  },
}

export default StatusLarge
