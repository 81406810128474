/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

// Styles
import { Chip, TextField } from '@material-ui/core'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import { sInput } from '../../style/input.style'
import { colors, alpha } from '../../../style'

const filter = createFilterOptions()
// Config & Utils
const caps = (string) =>
  typeof string === 'string'
    ? string.charAt(0).toUpperCase() + string.slice(1)
    : ''

const Creatable = ({
  stateArr,
  setStateArr,
  placeholder,
  label,
  data,
  language,
  types,
}) => {
  const [inputValue, setInputValue] = useState('')
  let elements = [...data]
  return (
    <div css={styleContainer}>
      {/* <div>values: {stateArr.map((value) => `${value} | `)}</div>
      <div>{`inputValue: '${inputValue}'`}</div> */}
      <Autocomplete
        multiple
        value={stateArr}
        onChange={(event, newValue) => setStateArr(newValue)}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        css={[{ marginBottom: 24, textTrasform: 'capitalize' }]}
        groupBy={(option) => {
          const foundElement = elements.filter((el) => el._id === option)
          return option.type || types[foundElement[0].type][language]
        }}
        // size={mui.textField.size}
        options={Object.values(elements).map((element) => element._id)}
        getOptionLabel={(option) => {
          if (typeof option === 'string') return caps(option)
          if (option.inputValue) return caps(option.inputValue)
          return caps(option.name)
        }}
        filterOptions={(options, params) => {
          const optionsNames = Object.values(elements).map(
            (element) => element.name
          )
          const filtered = filter(optionsNames, params)
          const newFiltered = filtered
            .filter((item) => typeof item === 'string')
            .map((item) => elements.find((e) => e.name === item)._id)

          const findMatched = filtered.filter(
            (item) => item.toLowerCase() === params.inputValue.toLowerCase()
          )
          if (params.inputValue !== '' && findMatched.length === 0) {
            newFiltered.push({
              inputValue: params.inputValue.toLocaleLowerCase(),
              name: `Dodaj "${caps(params.inputValue)}"`,
              type: 'CUSTOM',
            })
          }
          return newFiltered
        }}
        // getOptionSelected={(option) => option.name} /// ????
        renderOption={(option) => {
          const definedElement =
            elements
              .filter((element) => element._id === option)
              .map((found) => found.name) || option.name

          return option.name || definedElement
        }}
        filterSelectedOptions
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              // variant={mui.chipForm.variant}
              // size={mui.chipForm.size}
              // css={[global.chipForm, { textTransform: 'capitalize' }]}
              css={sChip}
              label={
                caps(option.inputValue) ||
                elements
                  .filter((element) => element._id === option)
                  .map((found) => found.name)
              }
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            // size={mui.textField.size}
            // variant={mui.textField.variant}
            label={label}
            // placeholder={placeholder}
            css={sInput}
          />
        )}
      />
    </div>
  )
}

const styleContainer = {
  width: '100%',
}

export const sChip = {
  color: 'white',
  backgroundColor: colors.text,
  textTransform: 'capitalize',
  fontWeight: 800,
  '.MuiChip-deleteIcon': {
    color: colors.white.concat(alpha[30]),
  },
}

export default Creatable
