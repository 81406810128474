/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import Headline from '../../../components/Headline/Headline'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import { sOuter } from '../../../style/style'
import ProductProvider from './context/product.provider'
import Details from './components/Details'
import Price from './components/Price'
import ConsumptionTypes from './components/ConsumptionTypes'
import OpenHours from './components/OpenHours'
import ButtonAdd from './components/ButtonAdd'

const ProductNewPage = () => {
  return (
    <div>
      <TopNav title={'Nowy Produkt'} offset={60} />
      <div css={sOuter}>
        <Headline>Nowy Produkt</Headline>
        <Details />
        <Price />
        <ConsumptionTypes />
        <OpenHours />
      </div>
      <ButtonAdd />
    </div>
  )
}

export default () => (
  <ProductProvider>
    <ProductNewPage />
  </ProductProvider>
)
