/** @jsx jsx */
import React, { useContext } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { ButtonBorder } from '../../../../../components/Elements'
import { s, globals } from '../../../../../style'
import { AuthContext } from '../../../../../context/auth.context'
import { GET_USER } from '../../../../graphql/user.graphql'
import { useQuery } from '@apollo/client'
import eatzonLogo from '../../../../../style/assets/eatzon'
import { toast } from 'react-toastify'

const Greeting = () => {
  const { logout, isAuth } = useContext(AuthContext)
  const { data, loading } = useQuery(GET_USER)

  // console.log(isAuth)

  const greetText = () => {
    const hour = new Date().getHours()
    if (hour >= 0 && hour < 6) return 'Dobrej Nocy'
    else if (hour >= 6 && hour < 18) return 'Dzień Dobry'
    else if (hour >= 18) return 'Dobry Wieczór'
    else return 'Dzień Dobry'
  }

  const handleLogout = () => {
    logout()
    toast.dark('Do zobaczenia wkrótce ✌')
  }

  return (
    <div css={sContainer}>
      <div>
        <h1 css={[sTitle, sAnimate]}>
          <div
            css={{
              position: 'relative',
              span: { transition: 'opacity 0.6s ease-in-out' },
            }}>
            <span css={isAuth ? { opacity: 1 } : { opacity: 0 }}>
              {greetText()}
            </span>
            <span
              css={[
                sLogo,
                { position: 'absolute', top: 0, left: 0 },
                isAuth ? { opacity: 0 } : { opacity: 1 },
              ]}>
              {eatzonLogo}
            </span>
          </div>
          {isAuth && !loading && data?.user && (
            <React.Fragment>
              <div css={sAnimate}>
                <span css={{ [s.xs]: { display: 'none' } }}>,&nbsp;</span>
                <span
                  css={{
                    [s.xs]: {
                      fontSize: '1rem',
                      letterSpacing: '-0.01em',
                      display: 'flex',
                    },
                  }}>
                  {data?.user?.personal?.name}
                </span>
              </div>
            </React.Fragment>
          )}
        </h1>
      </div>
      <ButtonBorder action={isAuth && handleLogout} link={!isAuth && '/login'}>
        {isAuth ? 'Wyloguj' : 'Zaloguj'}
      </ButtonBorder>
    </div>
  )
}

const sTitle = {
  width: 'max-content',
  display: 'flex',
  letterSpacing: '-0.05em',
  fontWeight: 800,
  fontSize: '2rem',
  [s.xs]: {
    flexDirection: 'column',
  },
  [s.sss]: {
    fontSize: '1.5rem',
  },
  [s.md]: {
    fontSize: '2rem',
  },
}

const sContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '2rem 2rem 0',
  maxWidth: globals.maxWidthMedium,
  margin: '0 auto',

  [s.md]: {
    padding: '4rem 2rem 0',
  },
}

const sLogo = {
  display: 'flex',
  height: 40,
  alignItems: 'center',
  svg: { height: 32, width: 'auto' },
}
const sAnimate = {
  animation: 'fadeIn 0.6s ease-in-out both alternate',
  // '@keyframes fadeIn': {
  //   from: { opacity: 0 },
  //   to: { opacity: 1 },
  // },
}

export default Greeting
