/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core'
import { Collapsable, Bullet, Row } from '../../../../components/Elements'
import PeopleIcon from '@material-ui/icons/People'
import TimeIcon from '@material-ui/icons/QueryBuilder'
import EventIcon from '@material-ui/icons/Event'
import { dateFc, getFullDate } from '../../../../helpers/date-format'
import PersonIcon from '@material-ui/icons/Person'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'

const OrderDate = ({
  consumptionDate,
  consumptionPersons,
  consumptionTime,
}) => {
  return (
    <Grid item xs={12} md={6}>
      <Collapsable
        noPadding
        oneLiner
        title={'Termin'}
        icon={<EventIcon />}
        description={
          <div
            css={{
              overflowX: 'scroll',
              display: 'flex',
              alignItems: 'center',
            }}>
            {dateFc(consumptionDate)}
            <Bullet />
            {consumptionTime}
            <Bullet />
            {consumptionPersons === 10 ? '10+' : consumptionPersons}
            <PersonIcon fontSize='inherit' css={{ marginLeft: '0.25rem' }} />
          </div>
        }>
        <Row desc={'Data'} icon={<CalendarTodayIcon fontSize='inherit' />}>
          {getFullDate(consumptionDate)}
        </Row>
        <Row desc={'Czas'} icon={<TimeIcon fontSize='inherit' />}>
          {consumptionTime}
        </Row>
        <Row desc={'Osoby'} icon={<PeopleIcon fontSize='inherit' />}>
          {consumptionPersons === 10 ? '10+' : consumptionPersons}
        </Row>
      </Collapsable>
    </Grid>
  )
}

export default OrderDate
