/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import Navbar from '../Navbar'
import TopNavFixed from '../../../components/TopNavFixed/TopNavFixed'

const Layout = ({ children }) => {
  // const isAuth = !!localStorage.getItem('token')

  return (
    <React.Fragment>
      <TopNavFixed />
      <main css={sMain}>{children}</main>
      <Navbar />
    </React.Fragment>
  )
}

export const sMain = {
  width: '100vw',
  overflowX: 'hidden',
  margin: '0 auto',
  paddingBottom: '8rem',
  height: '100vh',
  maxHeight: '100vh',
  position: 'relative',
}

export default Layout
