import { daysOfWeek } from '../../../../constants/daysOfWeek'

export default ({
  availableHours,
  deliveryCost,
  minAmount,
  deliveryRadius,
  freeDeliveryAmount,
}) => {
  const errors = []

  const availableHoursArray = Object.entries(availableHours)
  for (const [day, { open, close }] of availableHoursArray) {
    if (open > close) {
      errors.push(
        `W ${daysOfWeek[day]} godzina otwarcia jest późniejsza, niż godzina zamknięcia.`
      )
    }
  }

  const noDeliveryRadiusInDeliveryCost =
    deliveryCost.findIndex(([distance]) => distance === deliveryRadius) === -1

  if (noDeliveryRadiusInDeliveryCost) {
    errors.push(
      'Jedno z pól Kosztu Dostawy musi zawierać wskazany Promień Dostawy.'
      // + 'Jeden z obszarów powinien wynosić maksymaly Promień Dostawy np. 6000 metrów.'
    )
  }

  const isOneOfDeliveryCostRadiusMoreThenDeliveryRadius =
    deliveryCost.findIndex(([distance]) => distance > deliveryRadius) > -1

  if (isOneOfDeliveryCostRadiusMoreThenDeliveryRadius) {
    errors.push(
      'Jeden z pól Kosztu Dostawy jest wyższy, niż wskazany Promień Dostawy.'
    )
  }

  const deliveryCostRadiusesAmounts = {}
  for (const [distance, amount] of deliveryCost) {
    if (amount < 0) {
      errors.push('Kwota "Kosztu Dostawy" nie może być ujemna.')
    }

    if (deliveryCostRadiusesAmounts[distance]) {
      errors.push(
        'Zaznaczono ten sam "Promień Dostawy" dla wielu "Kosztów Dostawy".'
      )
    } else {
      deliveryCostRadiusesAmounts[distance] = 1
    }
  }

  if (deliveryRadius < 500) {
    errors.push('Minimalny Promień Dostawy wynosi 500 metrów.')
  }

  if (typeof freeDeliveryAmount === 'number' && freeDeliveryAmount < 0) {
    errors.push('Kwota Darmowej Dostawy nie moż być ujemna.')
  }

  if (deliveryCost < 0) {
    errors.push('Kwota Dostawy nie może być ujemna.')
  }

  return errors
}
