/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import TextInput from '../../../components/TextInput/TextInput'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { useProductContext } from '../context/product.provider'


export default () => {
  const {
    productInfo,
    onChangeInfo
  } = useProductContext()

  return (
    <div css={{ maxWidth: 256, margin: '16px 0' }}>
      <div css={{
        margin: '16px 0 8px',
        display: 'flex',
        // justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}>
        <TextInput
          label="Cena brutto, zł"
          type="number"
          name="price"
          value={productInfo.price}
          onChange={onChangeInfo}
          css={{ width: 120 }}
          required
        />

        <FormControl css={{ width: 120, marginLeft: 16 }}>
          <InputLabel>Stawka VAT</InputLabel>
          <Select
            name="vat"
            value={typeof productInfo.vat === 'number' ? productInfo.vat : ''}
            onChange={onChangeInfo}
            required
          >
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={0.05}>5%</MenuItem>
            <MenuItem value={0.08}>8%</MenuItem>
            <MenuItem value={0.23}>23%</MenuItem>
          </Select>
          {productInfo.vat === 0 && <FormHelperText>
            {'Wybieraj stawkę 0 tylko jeśli jesteś zwolniony z podatku VAT!'}
          </FormHelperText>}
        </FormControl>
      </div>

      <TextInput
        label="Czas przygotowania, min"
        type="number"
        name="cookTime"
        value={productInfo.cookTime}
        onChange={onChangeInfo}
        css={{ width: '100%' }}
      />

    </div>
  )
}