/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useHistory, useParams } from 'react-router-dom'

// Components-Out
// import Layout from '../../../components/Layout'
import GooglePlacesAutocomplete from '../../../../components/GooglePlacesAutocomplete'
// Views
import AuthWidget from '../../../views/AuthWidget/AuthWidget.view'
import GetDeliveryInfo from '../../../views/GetDeliveryInfo/GetDeliveryInfo.view'

// Hooks
import { useError } from '../../../../hooks/errors.hook'
import { useGetRestaurantConsumptionTypes } from '../../../hooks/useRests.queries'
import useCheckoutNoAuth from '../hooks/useCheckoutNoAuth'

// Helpers
import MoneyEz from '../../../../helpers/MoneyEz'

import {
  SendOrderBtn,
  GetRestaurantImage,
  sRestContainer,
} from './SingleCartOrder'
import { Link } from 'react-router-dom'

import Skeleton from '@material-ui/lab/Skeleton'

import {
  consumptionTypeTypes,
  consumptionTypeNames,
  consumptionTypeIcons,
} from '../../../../constants/types'

import ChoseType from '../components/ChoseType.component'
import InputFields from '../components/InputFields.component'
import InvoiceDetails from '../components/InvoiceDetails.component'

// import useCheckoutLogic from '../hooks/useCheckoutLogic'
import TopNavContent from '../../../views/ProductPage/components/TopNavContent'
import RestContainer, {
  sContainer as sRestContainerExported,
} from '../../../views/ProductPage/components/RestContainer'
import Headline from '../../../../components/Headline/Headline'
import { s, colors, alpha } from '../../../../style'
import { sOuter, sGrid, sNoMarginTop } from '../../../../style/style'
import { Grid, Hidden, Collapse } from '@material-ui/core'
import { Collapsable, Bullet, Button } from '../../../../components/Elements'
import EventIcon from '@material-ui/icons/Event'
import PersonIcon from '@material-ui/icons/Person'
import { dateFc } from '../../../../helpers/date-format'
import PersonPinIcon from '@material-ui/icons/PersonPinCircle'
import FastfoodIcon from '@material-ui/icons/Fastfood'
import DocumentIcon from '@material-ui/icons/InsertDriveFile'

import Input from '../../../views/Auth/components/Input.component'
import { ProductItem } from '../../../pages/cart/components/OrderItem.component'
import PageLoader from '../../../../components/PageLoader/PageLoader'
import OrderPayment from '../../orders/components/OrderPayment'
import CheckoutNote from '../components/CheckoutNote'
import { TopNav } from '../../../../components/TopNavFixed/TopNavFixed'
import { useAuthContext } from '../../../../context/auth.context'

export default () => {
  const { isAuth } = useAuthContext()
  const { errorHandling } = useError()
  const [deliveryCost, setDeliveryCost] = useState(0)
  // const [deliveryError, setDeliveryError] = useState(false)
  const [isWidgetOpen, setIsWidgetOpen] = useState(false)
  const setIsWidgetOpenTrue = () => setIsWidgetOpen(true)

  // console.log('isAuth', isAuth)

  const orderId = useParams().id
  const history = useHistory()
  const {
    // isLoading,
    loading,
    orderWeWorkOn,
    onChange,
    handleSelectAddress,
    onChangeAddres,
    onInvoiceDetailsChange,
    onIsInvoiceChange,
    onSaveNote,
  } = useCheckoutNoAuth({ orderId })

  const { data, error } = useGetRestaurantConsumptionTypes(
    orderWeWorkOn?.restId?._id
  )

  if (loading || !orderWeWorkOn?._id) return <PageLoader />

  if (error) errorHandling(error)
  if (!orderWeWorkOn) {
    history.push('/cart') // redirect here
    return null
  }

  const {
    consumptionDate,
    consumptionPersons,
    consumptionTime,
    consumptionType,
    deliveryAddress,
    deliveryDetails,
    note,
    isInvoice,
    invoiceDetails,
    orderDetails,
    restId,
  } = orderWeWorkOn

  // console.log(invoiceDetails)
  // console.log(orderWeWorkOn)

  const productsCost = MoneyEz(0)
    .sumProductArray(
      orderDetails[0].productsInCart.map(({ amount, prodId: { price } }) => ({
        amount,
        price,
      }))
    )
    .getAmount()

  const isDelivery = consumptionType === consumptionTypeTypes.delivery

  return (
    <React.Fragment>
      <TopNav
        title={orderWeWorkOn?.restId?.name}
        subtitle={`- Podsumowanie`}
        photo={orderWeWorkOn?.restId?.avatarUrl}
        textCss={{
          [s.xs]: { maxWidth: `calc(100vw - 9rem)` },
        }}
      />
      <div css={sOuter}>
        <Headline>Podsumowanie</Headline>
      </div>
      <Hidden mdUp>
        {restId ? (
          <GetRestaurantImage restId={restId._id} data={restId}>
            <RestContainer />
          </GetRestaurantImage>
        ) : (
          <Skeleton css={sRestContainerExported} />
        )}
      </Hidden>

      <div css={[sOuter, sNoMarginTop]}>
        {orderWeWorkOn && (
          <Grid container css={[sGrid]}>
            {/* SPOSÓB DOSTAWY */}
            <Grid item xs={12} md={6}>
              <Collapsable
                // openAlready
                left
                noPadding
                oneLiner
                title={consumptionTypeNames[consumptionType]}
                icon={consumptionTypeIcons[consumptionType]}
                description={
                  <div css={{ overflowX: 'scroll' }}>
                    Wybierz Sposób Zamówienia
                  </div>
                }
                extraCss={{ backgroundColor: colors.gray100 }}>
                <ChoseType
                  consumptionType={consumptionType}
                  onChange={onChange}
                  availableTypes={data?.ezcRestaurant?.consumptionTypes || []}
                />
              </Collapsable>
            </Grid>

            {/* DOSTAWA */}
            <Grid item xs={12} md={6}>
              <Collapse in={consumptionType === consumptionTypeTypes.delivery}>
                <Collapsable
                  // openAlready
                  right
                  noPadding
                  oneLiner
                  title={'Adres'}
                  icon={<PersonPinIcon />}
                  description={
                    <div
                      css={{
                        overflowX: 'scroll',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      {deliveryAddress}
                      {deliveryDetails && (
                        <>
                          <Bullet />
                          {deliveryDetails}
                        </>
                      )}
                    </div>
                  }
                  extraCss={{
                    backgroundColor: colors.gray100,
                    overflow: 'hidden',
                  }}>
                  <div
                    css={{
                      [s.sm_down]: { padding: '1.5rem 1rem 0' },
                      [s.md]: { padding: '2rem 2rem 0' },
                    }}>
                    <GooglePlacesAutocomplete
                      value={deliveryAddress || ''}
                      onChange={onChangeAddres}
                      onSelect={handleSelectAddress}
                    />
                    <Input
                      darker
                      placeholder='Numer mieszkania, piętro...'
                      name='deliveryDetails'
                      onChange={onChange}
                      value={deliveryDetails}
                    />
                  </div>
                  <GetDeliveryInfo
                    restId={restId._id}
                    orderAmount={MoneyEz(0)
                      .sumProductArray(
                        orderDetails[0].productsInCart.map(
                          ({ amount, prodId: { price } }) => ({
                            amount,
                            price,
                          })
                        )
                      )
                      .getAmount()}
                    setDeliveryCost={setDeliveryCost}
                    // setDeliveryError={setDeliveryError}
                  />
                </Collapsable>
              </Collapse>
            </Grid>

            {/* TERMIN */}
            <Grid item xs={12} md={6}>
              <Collapsable
                // openAlready
                left
                noPadding
                oneLiner
                title={'Termin'}
                icon={<EventIcon />}
                description={
                  <div
                    css={{
                      overflowX: 'scroll',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    {dateFc(consumptionDate)}
                    <Bullet />
                    {consumptionTime}
                    <Bullet />
                    {consumptionPersons}
                    <PersonIcon
                      fontSize='inherit'
                      css={{ marginLeft: '0.25rem' }}
                    />
                  </div>
                }
                extraCss={{ backgroundColor: colors.gray100 }}>
                <InputFields
                  onChange={onChange}
                  consumptionDetails={{
                    consumptionDate,
                    consumptionPersons,
                    consumptionTime,
                  }}
                />
              </Collapsable>
            </Grid>

            {/* PRODUKTY */}
            <Grid item xs={12} md={6}>
              <Collapsable
                // openAlready={orderDetails[0].productsInCart.length <= 3}
                right
                noPadding
                oneLiner
                title={'Produkty'}
                icon={<FastfoodIcon />}
                description={
                  <div
                    css={{
                      overflowX: 'scroll',
                      fontWeight: 500,
                    }}>
                    <span css={{ color: colors.text }}>
                      {productsCost.toFixed(2)}
                    </span>{' '}
                    zł
                    <Bullet />
                    <>
                      {orderDetails[0].productsInCart.reduce(
                        (sum, curr) => curr.amount + sum,
                        0
                      )}
                    </>
                    <FastfoodIcon
                      fontSize='inherit'
                      css={{
                        marginLeft: '0.25rem',
                        [s.md]: { marginLeft: '0.5rem' },
                      }}
                    />
                  </div>
                }>
                <>
                  {orderDetails[0].productsInCart.map((product, productId) => (
                    <ProductItem
                      id={product.prodId._id}
                      key={productId}
                      amount={product.amount}
                      name={product.prodId.name}
                      price={product.prodId.price}
                      photoUrl={product.prodId.photoUrl}
                    />
                  ))}
                  <div
                    css={{
                      [s.sm_down]: { padding: '1.5rem 1rem' },
                      [s.md]: { padding: '2rem' },
                    }}>
                    <Button
                      dimmed
                      w100
                      link={`/restaurant/${orderWeWorkOn.restId._id}?menu=true`}>
                      Edytuj produkty
                    </Button>
                  </div>
                </>
              </Collapsable>
            </Grid>

            {/* NOTATKA */}
            <CheckoutNote note={note} onSaveNote={onSaveNote} />

            {/* FAKTURA */}
            <Grid item xs={12} md={6}>
              <Collapsable
                right
                noPadding
                oneLiner
                title={'Faktura'}
                icon={<DocumentIcon />}
                description={
                  isInvoice ? (
                    <div css={{ overflow: 'scroll' }}>
                      {invoiceDetails.firmName ? (
                        <>
                          {invoiceDetails.firmName}
                          <Bullet />
                        </>
                      ) : (
                        'Uzupełnij dane firmy '
                      )}
                      {invoiceDetails.nip && (
                        <>
                          {invoiceDetails.nip}
                          <Bullet />
                        </>
                      )}
                      {invoiceDetails.address && (
                        <>
                          {invoiceDetails.address}
                          <Bullet />
                        </>
                      )}
                      {invoiceDetails.zipCode && (
                        <>
                          {invoiceDetails.zipCode}
                          <Bullet />
                        </>
                      )}
                      {invoiceDetails.city && (
                        <>
                          {invoiceDetails.city}
                          <Bullet />
                        </>
                      )}
                    </div>
                  ) : (
                    'Utwórz fakturę do zamówienia'
                  )
                }>
                <InvoiceDetails
                  isInvoice={isInvoice}
                  onIsInvoiceChange={onIsInvoiceChange}
                  invoiceDetails={invoiceDetails}
                  onInvoiceDetailsChange={onInvoiceDetailsChange}
                />
              </Collapsable>
            </Grid>

            {/* PŁATNOŚĆ */}
            <OrderPayment
              isDelivery={isDelivery}
              deliveryCost={isDelivery && deliveryCost}
              productsCost={productsCost.toFixed(2)}
              totalCost={
                isDelivery
                  ? (productsCost + deliveryCost).toFixed(2)
                  : productsCost.toFixed(2)
              }
            />
            <Grid item xs={12}>
              <p
                css={{
                  padding: '1.5rem 1rem 0rem',
                  fontSize: '0.75rem',
                  color: colors.text.concat(alpha[60]),
                  a: {
                    color: colors.text,
                    fontWeight: 800,
                    textDecoaration: 'underline',
                  },
                }}>
                Przesyłając zamówienie, potwierdzasz zapoznanie się z{' '}
                <Link to='/terms'>Regulaminem</Link> oraz{' '}
                <Link to='/privacy'>Politką Prywatności</Link>
              </p>
            </Grid>
          </Grid>
        )}
      </div>

      <Hidden smDown>
        <div css={sRestContainer}>
          {restId && (
            <GetRestaurantImage restId={restId._id} data={restId}>
              <RestContainer />
            </GetRestaurantImage>
          )}
        </div>
      </Hidden>

      <SendOrderBtn
        onSendOrder={setIsWidgetOpenTrue}
        // deliveryError={deliveryError}
      />

      <AuthWidget
        isWidgetOpen={isWidgetOpen}
        setIsWidgetOpen={setIsWidgetOpen}
      />
    </React.Fragment>
  )
}
