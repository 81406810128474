/** @jsx jsx */
import React, { useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import MainContainer from '../../components/MainContainer/MainContainer'
import Name from './views/Name'
import Description from './views/Description'
import Address from './views/Address'
import OpenningHours from './views/OpenningHours'
import Categories from './views/Categories'
import ImgBackground from './views/ImgBackground'
import ImgLogo from './views/ImgLogo'
import AddButton from './views/AddButton'
import ConsumptionTypes from './views/ConsumptionTypes'
import DeliverySettings from './views/DeliverySettings'

import RestaurantProvider, { useRestaurantContext } from './context/restaurant.provider'



const NewRestaurantPage = () => {
  const { restaurantInfo } = useRestaurantContext()

  return (
    <MainContainer header="Stwórz nową restaurację">

      <Name />
      <Description />

      <Address />
      <Categories />
      <br />
      <ConsumptionTypes />

      {restaurantInfo.consumptionTypes.includes('delivery')
        && <DeliverySettings />}

      <br />

      <OpenningHours />

      <ImgBackground />
      <ImgLogo />

      <AddButton />
    </MainContainer>
  )
}


export default () => (
  <RestaurantProvider>
    <NewRestaurantPage />
  </RestaurantProvider>
)