/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'
import { colors, alpha } from '../../../style'
import { ButtonBase } from '@material-ui/core'

const ButtonBorder = ({ link, children, action, extraCss, color, disable }) => {
  if (action)
    return (
      <div
        onClick={action}
        css={[
          { transition: 'opacity 0.2s ease-out' },
          disable && { opacity: 0.3, pointerEvents: 'none' },
        ]}>
        <ButtonBase css={[sButtonBorder(color), extraCss]}>
          {children}
        </ButtonBase>
      </div>
    )
  return (
    <Link
      to={link}
      css={[
        { transition: 'opacity 0.2s ease-out' },
        disable && { opacity: 0.3, pointerEvents: 'none' },
      ]}>
      <ButtonBase css={[sButtonBorder(color), extraCss]}>{children}</ButtonBase>
    </Link>
  )
}

const sButtonBorder = (color) => ({
  transition:
    'color 0.3s ease-out, background-color 0.3s ease-out, box-shadow 0.3s ease-out',
  fontSize: '0.875rem',
  fontWeight: 800,
  textAlign: 'center',
  lineHeight: '1em',
  padding: '9px 12px 8px',
  borderRadius: 12,
  boxShadow: color
    ? `inset 0px 0px 0px 3.5px ${color}`
    : `inset 0px 0px 0px 3.5px ${colors.gray400}`,
  color: color ? color : colors.gray600,

  '&:hover': {
    boxShadow: color
      ? `inset 0px 0px 0px 3.5px ${color}`
      : `inset 0px 0px 0px 3.5px ${colors.gray300}`,
    backgroundColor: color ? color : colors.gray300,
    color: color ? colors.white : colors.text,
  },

  // fontWeight: 500,
  // boxShadow: 'none',
  // backgroundColor: colors.gray100,
})

export default ButtonBorder
