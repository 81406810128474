import { useState } from 'react'


export default () => {
  const [flavors, setFlavors] = useState([])
  const [cookMethod, setCookMethods] = useState([])

  const handleFlavors = (i) => setFlavors(i)
  const handleCookMethod = (i) => setCookMethods(i)

  return {
    flavors,
    cookMethod,
    handleFlavors,
    handleCookMethod
  }
}