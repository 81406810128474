/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';


export default ({ searchString, onChange }) => (
  <div css={{
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 400,
    backgroundColor: '#fafafa',
    borderRadius: 4
  }}>
    <InputBase
      placeholder="Szukaj..."
      inputProps={{ 'aria-label': 'search google maps' }}
      value={searchString}
      onChange={onChange}
      css={{
        width: 400 - 48
      }}
    />
    <IconButton>
      <SearchIcon />
    </IconButton>
  </div>
)