/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
// import TextField from '@material-ui/core/TextField'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'

import Input from '../../EZC/views/Auth/components/Input.component'
// import Spinner from '../Spinner'
import { useError } from '../../hooks/errors.hook'
import { colors, s } from '../../style'
import { sButtonSearch } from '../../EZC/views/Search/SearchInput'
import { ButtonBase } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

export const usePlacesAutocomplete = () => {
  const { errorHandling } = useError()

  const onSelectAddress = async (address) => {
    try {
      const results = await geocodeByAddress(address)
      const { lat, lng } = await getLatLng(results[0])
      return { lng, lat }
    } catch (err) {
      errorHandling(err)
    }
  }

  return {
    onSelectAddress,
  }
}

export default function GooglePlacesAutocomplete({
  value,
  onChange,
  onSelect,
  textFieldCss,
  required,
  placeholder,
  label,
}) {
  return (
    <PlacesAutocomplete value={value} onChange={onChange} onSelect={onSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <React.Fragment>
          <div css={{ position: 'relative' }}>
            <Input
              darker
              {...getInputProps()}
              id='address'
              label={label ? label : ''}
              placeholder={placeholder || 'Podaj Adres'}
              // variant='filled'
              css={[{ width: '100%', marginBottom: 0 }, textFieldCss]}
              value={value}
              required={required}
              sBaseExtra={{ marginBottom: 0 }}
              // sInputExtra={{
              //   backgroundColor: colors.gray300,
              //   [s.touch]: { backgroundColor: colors.gray400 },
              // }}
            />
            {/* <ButtonBase
              css={[
                sButtonSearch,
                (!value || value === '') && {
                  opacity: 0.3,
                  pointerEvents: 'none',
                },
              ]}
              onClick={() => onChange('')}>
              <p>resetuj</p>
              <CloseIcon fontSize='inherit' />
            </ButtonBase> */}
          </div>
          <div
            className='autocomplete-dropdown-container'
            css={{
              padding: '0 0',
              width: '100%',
              marginTop: '1rem',
              [s.sm]: { marginTop: '1.5rem' },
              [s.xs]: { marginBottom: '1rem' },
            }}>
            {/* {loading && <Spinner size={1.5} />} */}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item'
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: colors.gray300 }
                : { cursor: 'pointer' }

              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                  key={suggestion.index}
                  css={{
                    cursor: 'pointer',
                    padding: '1rem',
                    width: '100%',
                    fontSize: '0.75rem',
                    fontWeight: 500,
                    borderRadius: 32,
                  }}>
                  <span>{suggestion.description}</span>
                </div>
              )
            })}
          </div>
        </React.Fragment>
      )}
    </PlacesAutocomplete>
  )
}
