/** @jsx jsx */
import React, { useState, useEffect, useRef } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useHistory } from 'react-router-dom'
import { useSearch, useSearchAutocomplete } from '../../hooks/useUser.queries'
import { krakenUrls } from '../../../constants/s3-files'
import { colors, s, alpha } from '../../../style'
import { sBackground } from '../../../components/Widget/Widget.component'
import { sBackgroundHidden } from './SearchFilter'
import { Collapse, Avatar } from '@material-ui/core'
import { FilterOption } from './FilterGroup'
import { sDescription } from '../../../style/style'
import { Skeleton } from '@material-ui/lab'

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const SearchAutoComplete = ({
  query,
  open,
  addNewIngredient,
  ingredientsFilterData,
  setOpen,
}) => {
  const { data, loading } = useSearchAutocomplete(query)
  const history = useHistory()
  const [height, setHeight] = useState(0)
  const prevHeight = usePrevious(height)

  // const elementRef = useRef()

  // console.log(elementRef?.current)

  // console.log(height ? height : prevHeight)

  const prodL = data ? data.ezcSearchAutocomplete.products.length : 0
  const restL = data ? data.ezcSearchAutocomplete.restaurants.length : 0
  const ingrL = data ? data.ezcSearchAutocomplete.ingredients.length : 0

  useEffect(() => {
    let sum = 0

    sum += restL * 50
    sum += prodL * 50
    if (prodL > 0) sum += 83
    if (restL > 0) sum += 83
    if (ingrL > 0) sum += 63 + 42
    if (prodL > 0 || restL > 0 || ingrL > 0) sum += 74 + 20
    // if (prodL === 0 && prodL === 0 && prodL === 0) setHeight(0)

    return setHeight(sum)
  }, [data, setHeight])

  return (
    <React.Fragment>
      <div
        css={[sBackground, !open && sBackgroundHidden, { zIndex: 1001 }]}
        onClick={() => setOpen(!open)}
      />
      <div css={[sOuter]}>
        <Collapse in={open}>
          <div
            css={[
              sInner,
              {
                height: height ? height : prevHeight,
                animation: 'fadeIn 0.3s ease-out',
              },
            ]}>
            {ingrL > 0 && (
              <React.Fragment>
                <span css={sLabel}>Składniki</span>
                <div css={{ display: 'flex', flexWrap: 'wrap' }}>
                  {data.ezcSearchAutocomplete.ingredients.map(
                    (ingredient, id) => (
                      <FilterOption
                        key={id}
                        id={ingredient._id}
                        name={ingredient.name}
                        onChoose={() => addNewIngredient(ingredient)}
                        filterData={ingredientsFilterData}
                        darker
                      />
                    )
                  )}
                </div>
                <div css={sPaddingBottom} />
              </React.Fragment>
            )}
            {prodL > 0 && (
              <React.Fragment>
                <span css={sLabel}>Produkty</span>
                {data.ezcSearchAutocomplete.products.map((el) => (
                  <ProdItem key={el._id} data={el} history={history} />
                ))}
                <div css={sPaddingBottom} />
              </React.Fragment>
            )}
            {restL > 0 && (
              <React.Fragment>
                <span css={sLabel}>Restauracje</span>
                {data.ezcSearchAutocomplete.restaurants.map((el) => (
                  <RestItem key={el._id} data={el} history={history} />
                ))}
                <div css={sPaddingBottom} />
              </React.Fragment>
            )}
            {/* {(data?.ezcSearchAutocomplete.products.length !== 0 ||
              data?.ezcSearchAutocomplete.ingredients.length !== 0 ||
              data?.ezcSearchAutocomplete.restaurants.length !== 0) && (
              <div css={{ paddingBottom: '1.5rem' }} />
            )} */}
            {/* {data?.ezcSearchAutocomplete.products.length === 0 &&
              data?.ezcSearchAutocomplete.ingredients.length === 0 &&
              data?.ezcSearchAutocomplete.restaurants.length === 0 && (
                <div css={sDescription}>Brak wyników dla podanej frazy</div>
              )} */}
            {/* {loading && (
              <div
                css={{
                  paddingTop: '1.5rem',
                  animation: 'fadeIn 0.3s ease-out both',
                }}>
                <Skeleton variant='rect' css={sSkeleton} />
              </div>
            )} */}
          </div>
        </Collapse>
      </div>
    </React.Fragment>
  )
}

const ProdItem = ({ data, history }) => {
  const { _id, name, photoUrl, price, restAvatarUrl } = data

  const photo = photoUrl
    ? photoUrl
    : restAvatarUrl
    ? restAvatarUrl
    : 'undefined'

  // console.log(data)

  return (
    <div
      onMouseDown={(event) => {
        event.preventDefault()
        history.push('/product/' + _id)
      }}
      css={sItem}>
      <Avatar
        alt={name}
        src={photo}
        css={{ height: 32, width: 32, marginRight: 8 }}
      />

      <p>{name}</p>
      <span>
        {price.toFixed(2)} <span css={{ opacity: 0.3 }}>zł</span>{' '}
      </span>
    </div>
  )
}

const RestItem = ({ data: { _id, name, avatarUrl }, history }) => (
  <div
    onMouseDown={(event) => {
      event.preventDefault()
      history.push('/restaurant/' + _id)
    }}
    css={sItem}>
    <Avatar
      alt={name}
      src={krakenUrls.small + avatarUrl}
      css={{ height: 32, width: 32, marginRight: 8 }}
    />

    <p>{name}</p>
  </div>
)

const sItem = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0.5rem 1rem 0.5rem 0.5rem',
  margin: '0 0 1rem',
  cursor: 'pointer',
  borderRadius: '1rem',
  backgroundColor: colors.gray300,

  '&:hover': {
    backgroundColor: colors.gray400,
  },

  p: {
    flexGrow: 1,
    letterSpacing: '-0.025em',
  },

  'p, span': {
    fontSize: '0.75rem',
    fontWeight: 800,
  },

  span: {
    whiteSpace: 'nowrap',
  },
}

const sOuter = {
  transition: 'all 0.15s ease-out',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 1001,
  minHeight: 50,
  borderRadius: '1.5rem',
  // overflow: 'hidden',
}

const sInner = {
  // transition: 'height 0.5s cubic-bezier(0.22, 1, 0.36, 1)',
  transition: 'height 0.5s ease-in-out',
  width: '100%',
  backgroundColor: colors.gray200,
  padding: '3rem 1.5rem 0rem',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '1.5rem',
  overflowY: 'scroll',
  maxHeight: '55vh',
  height: 'auto',

  // boxShadow: `0px 0px 32px ${colors.black.concat(alpha[30])}`,
}
const sLabel = {
  textTransform: 'uppercase',
  fontSize: '0.75rem',
  letterSpacing: '0.1em',
  padding: '0 0.75rem 1.5rem',
  color: colors.gray500,
  fontWeight: 800,
  '&:first-of-type': {
    paddingTop: '1.5rem',
  },
}

const sPaddingBottom = {
  paddingBottom: '1.5rem',
}

const sSkeleton = {
  width: '100%',
  height: 50,
  borderRadius: '1rem',
  marginBottom: '1.5rem',
}

export default SearchAutoComplete
