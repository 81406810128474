/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'

import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import HistoryIcon from '@material-ui/icons/History';

import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { ezwRoutePrefix } from '../../pages/settings'


const linkData = [
  { href: `${ezwRoutePrefix}/orders-attention`, Icon: NotificationsActiveIcon },
  { href: `${ezwRoutePrefix}/orders-active`, Icon: AssignmentIcon },
  { href: `${ezwRoutePrefix}/orders-finished`, Icon: HistoryIcon },
]


const NavbarEzw = ({ addStyles, attentionOrdersAmount, onShowMenu }) => (
  <nav css={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    marginLeft: 16,
  }}>
    {linkData.map(({ href, Icon }) => (
      <Link
        key={href}
        to={href}
        css={[
          styles.link,
          addStyles(href),
        ]}
      >
        <BadgeComponent amount={href.includes('orders-attention') ? attentionOrdersAmount : 0}>
          <Icon />
        </BadgeComponent>
      </Link>
    ))}

    <span
      onClick={onShowMenu}
      css={[
        styles.link,
        { marginTop: 32, height: '8vh' }
      ]}
    >
      <MenuIcon />
    </span>
  </nav>
)

const styles = {
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: '15vh',
    cursor: 'pointer',
    backgroundColor: '#444444',
    color: '#fff',
    borderBottom: '2px solid rgb(0 0 0 / 14%)',

    "&:last-child": { borderBottom: 'none' }
  }
}

export const navbarActiveLinkStyles = {
  backgroundColor: '#bfbebe',
}

export default NavbarEzw


const BadgeComponent = ({ amount, children }) => (
  <Badge
    badgeContent={amount}
    color="secondary"
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
  >
    {children}
  </Badge>
)