/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useParams } from 'react-router-dom'

import SingleOrderMenuButton from '../../../EZC/pages/orders/views/SingleOrderMenuButton'
// import Payment from './views/PaymentOrder'

import { useGetActiveOrder } from '../../../EZC/hooks/useOrders.queries'
import MoneyEz from '../../../helpers/MoneyEz'
import orderNeedToPay from '../../../EZC/helpers/order-need-to-pay'
import removeDuplicateProducts from '../../../helpers/remove-duplicate-products'
import sortProductsByStatus from '../../../helpers/sort-products-by-status'
// import orderNeedToPay from '../../helpers/order-need-to-pay'

///////////////////////////////////////

import PageLoader from '../../../components/PageLoader/PageLoader'
import {
  sOuter,
  sNoMarginTop,
  sGrid,
  sPaddingTop,
  sBottomLinks,
} from '../../../style/style'
import { ButtonBorder, Button } from '../../../components/Elements'
import { colors, s, alpha } from '../../../style'
import Headline from '../../../components/Headline/Headline'
import { Grid, Hidden } from '@material-ui/core'
import RestContainer from '../../../EZC/views/ProductPage/components/RestContainer'
import { ORDER_STATUSES, ORDER_STATUS_COLOR } from '../../../constants/statuses'
import { StatusOrder, SectionHeadline } from '../../../components/Elements'
import OrderAddress from '../../../EZC/pages/orders/components/OrderAddress'
import OrderChat from '../../../EZC/pages/orders/components/OrderChat'
import OrderDate from '../../../EZC/pages/orders/components/OrderDate'
import OrderInvoice from '../../../EZC/pages/orders/components/OrderInvoice'
import OrderNote from '../../../EZC/pages/orders/components/OrderNote'
import OrderPaymentLarge from '../../../EZC/pages/orders/components/OrderPaymentLarge'
import OrderType from '../../../EZC/pages/orders/components/OrderType'
import OrderID from '../../../EZC/pages/orders/components/OrderID'
import OrderPartially from '../../../EZC/pages/orders/components/OrderPartially'
import OrderCanceled from '../../../EZC/pages/orders/components/OrderCanceled'
import ButtonAccept from '../../../EZC/pages/orders/components/ButtonAccept'
import OrderStatuses from '../../../EZC/pages/orders/components/OrderStatuses'

import ButtonCancel from '../../../EZC/pages/orders/components//ButtonCancel'
import ButtonFinish from '../../../EZC/pages/orders/components/ButtonFinish'
import ButtonNeedPayment from '../../../EZC/pages/orders/components/ButtonNeedPayment'
import OrderNeedPayment from '../../../EZC/pages/orders/components/OrderNeedPayment'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import { useOrdersContext } from '../../context/orders.context'
import SkeletonOrder from '../../../components/SkeletonOrder/SkeletonOrder'
import StatusGuide from '../../../components/StatusGuide/StatusGuide'
import OrderUser from './OrderUser'
import OrderCancel from '../Popups/OrderCancel'
import OrderFinish from '../Popups/OrderFinish'
import OrderAttentionProvider from './context/order-attention.context'
import OrderProduct from './OrderProduct'
import OrderProducts from './OrderProducts'
import OrdersActiveProvider from './context/orders-active.context'
import OrderProductsCancel from '../Popups/OrderProductsCancel'
import FixedButtons from './buttons/FixedButtons'

const OrderActive = () => {
  const [isCancelWidgetOpen, setIsCancelWidgetOpen] = useState(false)
  const [isCancelProductWidgetOpen, setIsCancelProductWidgetOpen] =
    useState(false)
  const [isStatusGuideOpen, setIsStatusGuideOpen] = useState(false)
  const orderId = useParams().id
  const { activeOrders, attentionOrders } = useOrdersContext()

  const order = activeOrders
    .concat(attentionOrders)
    .find((order) => order._id === orderId)

  // console.log(order)

  if (!order) {
    return (
      <React.Fragment>
        <div css={sOuter}>
          <Headline
            itemChildren={
              <StatusOrder color={ORDER_STATUS_COLOR['WAITING_FOR_ACCEPT']}>
                Pobieranie
              </StatusOrder>
            }
            extraCss={sHeadline}>
            Zamówienie
          </Headline>
          <Grid container css={sGrid}>
            <SkeletonOrder />
            <SkeletonOrder />
          </Grid>
        </div>
      </React.Fragment>
    )
  }

  let allProducts,
    allProductsSorted,
    newAllProducts = []
  if (order) {
    allProducts = [
      ...order.orderDetails[0].productsToOrder,
      ...order.orderDetails[0].productsInProcess,
      ...order.orderDetails[0].productsFinishedGood,
      ...order.orderDetails[0].productsFinishedBad,
      ...order.orderDetails[0].productsDeclined,
    ]

    newAllProducts = removeDuplicateProducts(allProducts)
    allProductsSorted = sortProductsByStatus(newAllProducts)
  }

  const isCanceled = _getActualStatusName(order) === 'ANNUL_BY_CLIENT'
  // const isCanceled = true

  const {
    shortId,
    ownerId,
    isPaid,
    restId: { name, avatarUrl, _id },
    status: statuses,
    consumptionType,
    deliveryAddress,
    deliveryDetails,
    consumptionDate,
    consumptionTime,
    consumptionPersons, // IKR
    note,
    isInvoice,
    invoiceDetails,
    deliveryCost,
    paidSum,
    priceSum,
    messages,
  } = order

  const status = [...statuses].pop().name

  return (
    <OrderAttentionProvider orderWeWorkOn={order}>
      <OrdersActiveProvider order={order}>
        {/* TOP NAV BAR */}
        <TopNav
          title={shortId}
          subtitle={` - ${ownerId.personal.name} ${ownerId.personal.surname}`}
          photo={ownerId.personal.avatarUrl}
          textCss={{
            [s.xs]: { maxWidth: `calc(100vw - 9rem)` },
          }}
        />

        {/* HEADLINE */}
        <div css={sOuter}>
          <Headline
            itemChildren={
              <div
                css={{ cursor: 'pointer' }}
                onClick={() => setIsStatusGuideOpen(!isStatusGuideOpen)}>
                <StatusOrder color={ORDER_STATUS_COLOR[status]}>
                  {ORDER_STATUSES[status]}
                </StatusOrder>
              </div>
            }
            extraCss={sHeadline}>
            {shortId}
          </Headline>
          <Grid container css={sGrid}>
            <OrderUser
              personal={ownerId.personal}
              consumptionDate={consumptionDate}
              consumptionTime={consumptionTime}
              consumptionPersons={consumptionPersons}
              consumptionType={consumptionType}
              priceSum={priceSum}
              paidSum={paidSum}
              isPaid={isPaid}
            />
          </Grid>
        </div>

        {/* RESTAURANT */}
        {/* <RestContainer data={order.restId} isPaid={isPaid} /> */}

        {/* {isCanceled && (
          <div css={[sOuter, sNoMarginTop]}>
            <Grid container css={sGrid}>
              <OrderCanceled name={name} shortId={shortId} />
            </Grid>
          </div>
        )} */}

        {/* ORDER NEED PAYMENT */}
        {/* {!isCanceled && (
        <>
          <div css={[sOuter, sNoMarginTop]}>
            <Grid container css={sGrid}>
              <OrderNeedPayment
                priceSum={priceSum}
                paidSum={paidSum}
                orderId={orderId}
                defaultValue={defaultPaymentValue} // amountToPay
                userCoinsBalance={userCoinsBalance}
              />
            </Grid>
          </div>
          <div css={sButtonFixed}>
            <ButtonNeedPayment
              orderId={orderId}
              defaultValue={defaultPaymentValue} // amountToPay
              buttonTransparent
            />
          </div>
        </>
      )} */}

        {/* ORDER PARTIALLY */}
        {/* {status === 'PARTIALLY_ACCEPTED' && (
        <div css={[sOuter, sNoMarginTop]}>
          <Grid container css={sGrid}>
            <OrderPartially
              orderId={orderId}
              amountToPay={defaultPaymentValue}
            />
          </Grid>
        </div>
      )} */}

        {/* ORDER PRODUCTS */}
        <OrderProducts
          isCanceled={isCanceled}
          allProductsSorted={allProductsSorted}
          setIsCancelProductWidgetOpen={setIsCancelProductWidgetOpen}
        />

        {/* ORDER DETAILS */}
        <div css={[sOuter, sNoMarginTop, sGridSpacingParent]}>
          <Grid
            container
            css={[sGrid, sGridSpacing]}
            spacing={3}
            justify='space-between'>
            <SectionHeadline>Szczegóły</SectionHeadline>

            <OrderChat
              orderId={orderId}
              name={name}
              avatarUrl={avatarUrl}
              messages={messages}
              link={`/waiter/chat/${orderId}?orderType=active&name=${
                ownerId.personal.name + ' ' + ownerId.personal.surname
              }&avatarUrl=${ownerId.personal.avatarUrl}`}
              waiter
            />

            {/* ORDER TYPE */}
            <OrderType consumptionType={consumptionType} />

            {/* ORDER ADDRESS */}
            {consumptionType === 'delivery' && (
              <OrderAddress
                deliveryAddress={deliveryAddress}
                deliveryDetails={deliveryDetails}
              />
            )}

            {/* ORDER DATE */}
            <OrderDate
              consumptionDate={consumptionDate}
              consumptionTime={consumptionTime}
              consumptionPersons={consumptionPersons}
            />

            {/* ORDER NOTE */}
            {note && <OrderNote note={note} />}

            {/* ORDER INVOICE */}
            {isInvoice && <OrderInvoice invoiceDetails={invoiceDetails} />}

            <OrderStatuses
              statuses={statuses}
              waiter
              open={isStatusGuideOpen}
              setOpen={setIsStatusGuideOpen}
            />

            {/* ORDER ID */}
            <OrderID shortId={shortId} orderId={_id} />

            {/* ORDER PAYMENT */}
            <OrderPaymentLarge
              priceSum={priceSum}
              paidSum={paidSum}
              deliveryCost={deliveryCost}
              isDelivery={consumptionType === 'delivery'}
            />
          </Grid>
        </div>
        {/* {status === 'PARTIALLY_ACCEPTED' && (
        <div css={sButtonFixed}>
          <ButtonAccept
            orderId={orderId}
            amountToPay={defaultPaymentValue}
            buttonTransparent
          />
        </div>
      )} */}

        {!isCanceled && (
          <div
            css={[
              sOuter,
              sNoMarginTop,
              sPaddingTop,
              {
                position: 'relative',
                zIndex: 1000,
              },
            ]}>
            <Grid
              container
              css={[
                sGrid,
                {
                  gap: '2rem',
                  marginTop: '2rem',
                  marginBottom: '3.5rem',
                  [s.sm_down]: {
                    flexDirection: 'column',
                    gap: '1rem',
                    marginBottom: '0.5rem',
                  },
                },
              ]}>
              <OrderFinish orderId={orderId} />
              <OrderCancel
                orderId={orderId}
                isCancelWidgetOpen={isCancelWidgetOpen}
                setIsCancelWidgetOpen={setIsCancelWidgetOpen}
              />
            </Grid>
          </div>
        )}

        <FixedButtons
          isPaid={isPaid}
          isCanceled={isCanceled}
          orderId={orderId}
          zIndex={isCancelWidgetOpen || isCancelProductWidgetOpen ? 999 : 1000}
        />

        <OrderProductsCancel
          isCancelProductWidgetOpen={isCancelProductWidgetOpen}
          setIsCancelProductWidgetOpen={setIsCancelProductWidgetOpen}
        />
        <StatusGuide open={isStatusGuideOpen} setOpen={setIsStatusGuideOpen} />
      </OrdersActiveProvider>
    </OrderAttentionProvider>
  )
}

const _getActualStatusName = (order) => [...order.status].pop().name

const sGridSpacing = {
  '.MuiGrid-item': {
    [s.xs]: { paddingBottom: '4px !important' },
  },
}

const sHeadline = {
  alignItems: 'center',
  [s.xs]: {
    padding: '2rem 1.5rem 1.5rem',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}

const sGridSpacingParent = {
  [s.xs]: {
    '.MuiGrid-spacing-xs-3': {
      width: '100%',
      margin: 0,

      '& > .MuiGrid-item': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
}

export default OrderActive
