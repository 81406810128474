/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { daysOfWeek } from '../../../../constants/daysOfWeek'
import useRestaurantMutations from '../../../hooks/useRestaurant.mutations'
import { useRestaurantContext } from '../context/restaurant.provider'

import deliverySettingsCheck from '../helpers/deliverySettingsCheck'
import deliveryParseFloatAmount from '../helpers/deliveryParseFloatAmounts'

import { sButtonFixed } from '../../../../EZW/pages/Order/buttons/FixedButtons'
import { Button } from '../../../../components/Elements'

export default () => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const { handleRestUpdate } = useRestaurantMutations()
  const {
    openningHours,
    categories,
    restaurantInfo,
    logoFile,
    backgroundFile,
    deliverySettings,
  } = useRestaurantContext()

  const onSubmit = () => {
    try {
      setIsLoading(true)
      const categoryIds = []
      const newCategories = []
      categories.forEach((el) => {
        if (typeof el === 'string') categoryIds.push(el)
        else newCategories.push(el.inputValue)
      })

      const a = deleteProperty__typename(openningHours)
      const i = Object.entries(a).map((el) => ({
        day: el[0],
        close: el[1].close,
        open: el[1].open,
      }))
      const openningHoursToSend = {}
      i.forEach(({ day, open, close }) => {
        if (open > close) {
          throw new Error(
            `${daysOfWeek[day]} - godzina otwarcia nie może być późniejsza od godziny zamknięcia`
          )
        }
        openningHoursToSend[day] = { open, close }
      })

      deliverySettings.availableHours = deleteProperty__typename(
        deliverySettings.availableHours
      )
      Object.keys(deliverySettings.availableHours).forEach((day) => {
        deliverySettings.availableHours[day] = deleteProperty__typename(
          deliverySettings.availableHours[day]
        )
      })

      if (restaurantInfo?.consumptionTypes.includes('delivery')) {
        const deliverySettingsErrors = deliverySettingsCheck(deliverySettings)
        if (deliverySettingsErrors.length > 0) {
          const errors = new Error()
          errors.array = deliverySettingsErrors
          throw errors
        }
        deliveryParseFloatAmount(deliverySettings)
      }

      const input = {
        name: restaurantInfo.name,
        description: restaurantInfo.description,
        categories: categoryIds,
        address: restaurantInfo.address,
        lng: restaurantInfo?.location?.coordinates[0],
        lat: restaurantInfo?.location?.coordinates[1],
        isActive: restaurantInfo?.isActive,
        consumptionTypes: restaurantInfo?.consumptionTypes,
        openningHours: openningHoursToSend,
        deliverySettings: deleteProperty__typename(deliverySettings),
      }

      const errors = []
      if (!restaurantInfo.name) errors.push('Podaj nazwę')
      if (!restaurantInfo.address) errors.push('Podaj adres')
      if (errors.length) {
        errors.forEach((el) => toast.error(el))
        setIsLoading(false)
        return
      }

      handleRestUpdate(
        {
          input,
          newCategories,
          logoFile,
          backgroundFile,
        },
        () => {
          setIsLoading(false)
          toast.dark('Dane restauracji zostały zaktualizowane')
          // history.push('/rest')
        },
        () => setIsLoading(false)
      )
    } catch (err) {
      if (err.array && err.array.length > 0) {
        err.array.forEach((e) => toast.warn(e))
      } else {
        toast.warn(err.message)
      }
      setIsLoading(false)
    }
  }

  return (
    <div css={sButtonFixed}>
      <Button
        w100
        w100Desktop
        buttonTransparent={true}
        action={onSubmit}
        disabled={isLoading}
        loadingSpinner={isLoading}
        loading={isLoading}>
        Zapisz zmiany
      </Button>
    </div>
  )
}

function deleteProperty__typename(obj) {
  const { __typename, ...a } = obj
  return a
}
