/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import CheckIcon from '@material-ui/icons/Check';
import { ORDER_STATUSES } from '../../../constants/statuses'



export default ({
  info,
  status,
  id,
  personal,
  personAmount,
  orderType,
  time,
  moneyAmount,
  paidStatus
}) => (

  <div css={{
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  }}>
    <StatusCell
      info={info}
      top={info ? "Status" : status}
      bottom={info ? "ID" : id}
    />
    <Cell
      info={info}
      top={info ? "Klient" : personal}
      bottom={info ? "Ilość osób" : personAmount}
    />
    <Cell
      info={info}
      top={info ? "Typ zamówienia" : orderType}
      bottom={info ? "Termin" : time}
    />
    <Cell
      info={info}
      top={info ? "Kwota paragonu" : moneyAmount}
      bottom={info ? "Status płatności" : paidStatus}
    />
  </div>
)


// Components
const StatusCell = (props) => (
  <div css={{ width: '25%', display: 'flex' }}>
    {props.top === ORDER_STATUSES.CONFIRMED
      && <div css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 12
      }}>
        <CheckIcon css={{ color: '#00B30F', stroke: '#00B30F', strokeWidth: 2 }} />
      </div>
    }
    <div>
      <CellInner {...props} statusCell />
    </div>
  </div>
)

const Cell = (props) => (
  <div css={{ width: '25%' }}>
    <CellInner {...props} />
  </div>
)

const CellInner = ({ info, top, bottom, statusCell }) => (
  <React.Fragment>
    <p css={{
      fontWeight: info ? undefined : 700,
      color: (statusCell && top === ORDER_STATUSES.CONFIRMED) ? '#007109' : undefined
    }}>
      {top}
    </p>
    <p css={{
      opacity: info ? '.6' : undefined
    }}>
      {
        (bottom === 'Opłacone' || bottom === 'Czeka na opłatę')
          ? (
            <React.Fragment>
              {bottom === 'Opłacone' && <span css={paidStatusStyles("#00b30f")}>Opłacone</span>}
              {bottom === 'Czeka na opłatę' && <span css={paidStatusStyles("#b30000")}>Czeka na opłatę</span>}
            </React.Fragment>
          )
          : bottom
      }
    </p>
  </React.Fragment>
)

const paidStatusStyles = (backgroundColor) => ({
  display: 'inline-block',
  padding: '4px 8px',
  backgroundColor,
  color: '#fff',
  fontWeight: 700,
  borderRadius: 4,
  marginTop: 4
})