/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { useRestaurantContext } from '../context/restaurant.provider'
import OpenningHours from '../../../components/OpenningHours/OpenningHours.component'



export default () => {
  const {
    deliverySettings: {
      deliveryCost,
      deliveryRadius,
      minAmount,
      availableHours,
      freeDeliveryAmount
    },
    handleDeliverySettings,
    onDeleteDeliveryCost,
    onAddDeliveryCost,
    handleDeliveryCost
  } = useRestaurantContext()


  return (
    <div css={{
      margin: '24px 0',
      padding: 8,
      borderRadius: 4,
      backgroundColor: '#ececef'
    }}>
      <h2 css={{ margin: 8 }}>Ustawienia dostawy</h2>
      <FormControl css={{ margin: 8 }}>
        <Input
          value={deliveryRadius}
          onChange={handleDeliverySettings('deliveryRadius')}
          endAdornment={<InputAdornment position="end">metry</InputAdornment>}
        />
        <FormHelperText>Promień dostawy</FormHelperText>
      </FormControl>

      <FormControl css={{ margin: 8 }}>
        <Input
          value={minAmount}
          onChange={handleDeliverySettings('minAmount')}
          endAdornment={<InputAdornment position="end">zł</InputAdornment>}
        />
        <FormHelperText>Minimalna wartość zamówienia</FormHelperText>
      </FormControl>

      <div css={{ margin: 8 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={freeDeliveryAmount !== null}
              onChange={handleDeliverySettings('freeDeliveryAmountCheckbox')}
              color="primary"
            />
          }
          label="Darmowa dostawa"
        />
        {freeDeliveryAmount !== null && (
          <FormControl css={{ display: 'block' }}>
            <Input
              value={freeDeliveryAmount || 0}
              onChange={handleDeliverySettings('freeDeliveryAmount')}
              endAdornment={<InputAdornment position="end">zł</InputAdornment>}
            />
            <FormHelperText>Wartość zamówienia dla darmowej dostawy</FormHelperText>
          </FormControl>
        )}
      </div>

      <div css={{ padding: 8 }}>
        <h3>Koszt dostawy</h3>
        <p css={{
          color: '#777',
          fontSize: 13,
          maxWidth: 420,
        }}>
          Podaj koszt dostawy. Możesz zaznaczyć wiele obszarów.
          <br />Na przykład, do 500 metrów będziesz miał 0 zł za dostawę, do 2000 - 5 zł, a do 10.000 metrów - 10zł.
        </p>
        {
          deliveryCost.map(([distance, amount], index) => (
            <div key={index}>
              <FormControl css={{ margin: '8px 8px 8px 0' }}>
                <Input
                  value={distance}
                  onChange={handleDeliveryCost('distance', index)}
                  endAdornment={<InputAdornment position="end">metry</InputAdornment>}
                />
                <FormHelperText>Promień</FormHelperText>
              </FormControl>

              <FormControl css={{ margin: '8px 8px 8px 8px' }}>
                <Input
                  value={amount}
                  onChange={handleDeliveryCost('amount', index)}
                  endAdornment={<InputAdornment position="end">zł</InputAdornment>}
                />
                <FormHelperText>Koszt dostawy</FormHelperText>
              </FormControl>

              {deliveryCost.length > 1
                && (
                  <button onClick={() => onDeleteDeliveryCost(index)}>
                    Usuń
                  </button>
                )}
            </div>
          ))
        }

        <button
          onClick={onAddDeliveryCost}
        >
          Dodaj
        </button>

      </div>

      <div css={{ padding: 8 }}>
        <OpenningHours
          handleDayOnChange={handleDeliverySettings('availableHours')}
          openningHours={availableHours}
          header="Godziny realizacji dostawy"
        />
      </div>

    </div>
  )
}