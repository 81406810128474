import { useState } from 'react'
import MoneyEz from '../../../../helpers/MoneyEz'


export default () => {
  const [cart, setCart] = useState([])
  const amountPrice = MoneyEz(0)
    .sumProductArray(cart)
    .getAmountWithFullPrecisionAndZl()

  const addProduct = (product) => {
    const { _id, name, price, photoUrl } = product
    let isProductExist = cart.find(product => product._id === _id)
    if (!isProductExist) {
      setCart([...cart, { _id, name, price, photoUrl, amount: 1 }])
      return
    }

    setCart(cart.map(product => product._id !== _id
      ? product
      : { ...product, amount: product.amount + 1 }
    ))
  }

  const deleteProduct = ({ _id }) => {
    let product = cart.find(product => product._id === _id)

    if (product.amount === 1) {
      setCart(cart.filter(prod => prod._id !== _id))

    } else {
      setCart(cart.map(product => product._id !== _id
        ? product
        : { ...product, amount: product.amount - 1 }
      ))
    }
  }

  return {
    amountPrice,
    cart,
    addProduct,
    deleteProduct
  }
}