/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import useAuth from '../../../hooks/useAuth.mutations'
import { useWaiterAuthContext } from '../../../context/auth-waiter.context'

const mrgn = { marginBottom: 16 }

export default function SignIn() {
  const [isLoading, setIsLoading] = useState(false)
  const [loginData, setLoginData] = useState({})
  const { waiterLogin } = useWaiterAuthContext()
  const { handleLogin } = useAuth()

  const onLogin = async () => {
    setIsLoading(true)
    const cb = () => setIsLoading(false)
    const response = await handleLogin(loginData, cb, cb)
    if (response) {
      const {
        data: {
          waiterLogin: { token, restaurantId },
        },
      } = response
      waiterLogin(token, restaurantId)
      toast.dark('Zalogowano pomyślnie')
    }
  }

  const onChange = (e) =>
    setLoginData({ ...loginData, [e.target.name.slice(1)]: e.target.value })

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        maxWidth: 480,
        width: '100%',
        margin: '10vh auto 0',
      }}>
      <h2 css={mrgn}>Eatzon | Pulpit Kelnera</h2>
      <TextField
        variant='outlined'
        required
        fullWidth
        label='Login'
        name='wlogin'
        autoComplete='wlogin'
        autoFocus
        css={mrgn}
        onChange={onChange}
      />
      <TextField
        variant='outlined'
        required
        fullWidth
        name='wpassword'
        label='Hasło'
        type='password'
        autoComplete='wpassword'
        css={mrgn}
        onChange={onChange}
      />

      <Button
        type='submit'
        fullWidth
        variant='contained'
        color='primary'
        css={mrgn}
        disable={isLoading ? true : undefined}
        onClick={onLogin}>
        Zaloguj się
      </Button>

      <div css={{ width: '100%' }}>
        <Link
          to='/'
          css={{
            display: 'block',
            color: '#343340',
            opacity: '.6',
            fontSize: 14,
            fontWeight: 800,
            lineHeight: '20px',
            marginBottom: 16,
            textAlign: 'right',
          }}>
          Panel Klienta
        </Link>
      </div>
    </div>
  )
}
