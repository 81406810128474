/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core'
import { Collapsable, Bullet, Row } from '../../../../components/Elements'
import DocumentIcon from '@material-ui/icons/InsertDriveFile'

const OrderInvoice = ({ invoiceDetails }) => {
  const { firmName, nip, address, zipCode, city } = invoiceDetails
  return (
    <Grid item xs={12} md={6}>
      <Collapsable
        noPadding
        oneLiner
        title={'Faktura'}
        icon={<DocumentIcon />}
        description={
          <div
            css={{
              overflowX: 'scroll',
            }}>
            {firmName}
            <Bullet />
            {nip}
            <Bullet />
            {address}
            <Bullet />
            {zipCode}
            <Bullet />
            {city}
          </div>
        }>
        <Row desc={'Nazwa'}>{firmName}</Row>
        <Row desc={'NIP'}>{nip}</Row>
        <Row desc={'Adres'}>{address}</Row>
        <Row desc={'Kod Pocztowy'}>{zipCode}</Row>
        <Row desc={'Miasto'}>{city}</Row>
      </Collapsable>
    </Grid>
  )
}

export default OrderInvoice
