/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useAuthContext } from '../../../context/auth.context'
import SingleCartOrder from './views/SingleCartOrder'
import SingleCartOrderNoAuth from './views/SingleCartOrderNoAuth'


export default () => {
  const { isAuth } = useAuthContext()
  if (isAuth) return <SingleCartOrder />
  else return <SingleCartOrderNoAuth />
}