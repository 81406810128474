/** @jsx jsx */
import React, { useEffect, useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { makeStyles } from '@material-ui/core/styles'
import { ToastContainer, Slide } from 'react-toastify'
import smoothscroll from 'smoothscroll-polyfill'
import 'react-toastify/dist/ReactToastify.css'

import { useRoutes } from './routes'
import { useAuth } from './hooks/auth.hook'
import { useTopNav } from './hooks/topnav.hook'
import { AuthContext } from './context/auth.context'
import { TopNavContext } from './context/topnav.context'
import PasswordProtected from './EZC/views/Auth/PasswordProtected.view'

import { useGeolocationContext } from './EZC/context/Geolocation/Geolocation.provider'
import isAdmin from './helpers/isAdmin'
import ScrollTop from './components/ScrollTop/ScrollTop'

smoothscroll.polyfill()

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

function App() {
  const { handleLocation } = useGeolocationContext()
  const { login, logout, token, userId, ready } = useAuth()
  const { scrolled, data, animBack, setScrolled, setData, setAnimBack } =
    useTopNav()
  const isAuth = !!token

  // SUPER HYPER IMPORTANT - withour this Material UI and Emotion doesn't work and whole UI breaks!!!!
  const classes = useStyles()
  const routes = useRoutes(isAuth, isAdmin(userId))

  const passwordLessEnvs = () =>
    process.env.REACT_APP_NODE_ENV === 'LOCAL_DEV' ||
    process.env.REACT_APP_NODE_ENV === 'TEST2' ||
    process.env.REACT_APP_NODE_ENV === 'TEST'

  const [isAccess, setIsAccess] = useState(passwordLessEnvs)

  useEffect(() => {
    handleLocation()
  }, [])

  if (!ready) return <div />

  if (isAccess || isAuth)
    return (
      <AuthContext.Provider value={{ login, logout, token, userId, isAuth }}>
        <TopNavContext.Provider
          value={{
            scrolled,
            setScrolled,
            data,
            setData,
            animBack,
            setAnimBack,
          }}>
          {/* <ScrollTop /> */}
          {routes}

          <ToastContainer
            transition={Slide}
            position='top-center'
            autoClose={6000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </TopNavContext.Provider>
      </AuthContext.Provider>
    )

  return <PasswordProtected isAccess={isAccess} setIsAccess={setIsAccess} />
}

export default App
