/** @jsx jsx */
import React, { useState, useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useParams, useHistory } from 'react-router-dom'
import SingleOrderMenuButton from './views/SingleOrderMenuButton'
// import Payment from './views/PaymentOrder'

import { useGetActiveOrder } from '../../hooks/useOrders.queries'
import MoneyEz from '../../../helpers/MoneyEz'
import orderNeedToPay from '../../helpers/order-need-to-pay'
import removeDuplicateProducts from '../../../helpers/remove-duplicate-products'
import sortProductsByStatus from '../../../helpers/sort-products-by-status'
// import orderNeedToPay from '../../helpers/order-need-to-pay'

///////////////////////////////////////

import PageLoader from '../../../components/PageLoader/PageLoader'
import {
  sOuter,
  sNoMarginTop,
  sGrid,
  sPaddingTop,
  sBottomLinks,
} from '../../../style/style'
import { ButtonBorder } from '../../../components/Elements'
import { colors, s, alpha } from '../../../style'
import Headline from '../../../components/Headline/Headline'
import { Grid, Hidden } from '@material-ui/core'
import RestContainer from '../../views/ProductPage/components/RestContainer'
import { ORDER_STATUSES, ORDER_STATUS_COLOR } from '../../../constants/statuses'
import { StatusOrder, SectionHeadline } from '../../../components/Elements'
import OrderAddress from './components/OrderAddress'
import OrderChat from './components/OrderChat'
import OrderDate from './components/OrderDate'
import OrderInvoice from './components/OrderInvoice'
import OrderNote from './components/OrderNote'
import OrderPaymentLarge from './components/OrderPaymentLarge'
import OrderType from './components/OrderType'
import OrderID from './components/OrderID'
import OrderPartially from './components/OrderPartially'
import OrderCanceled from './components/OrderCanceled'
import ButtonAccept from './components/ButtonAccept'
import OrderStatuses from './components/OrderStatuses'
import OrderProduct from './components/OrderProduct'
import ButtonCancel from './components/ButtonCancel'
import ButtonFinish from './components/ButtonFinish'
import ButtonNeedPayment from './components/ButtonNeedPayment'
import OrderNeedPayment from './components/OrderNeedPayment'
import StatusGuide from '../../../components/StatusGuide/StatusGuide'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'

const OrderActive = () => {
  const [isCancelWidgetOpen, setIsCancelWidgetOpen] = useState(false)
  const [isStatusGuideOpen, setIsStatusGuideOpen] = useState(false)
  const orderId = useParams().id
  const { data, error, loading } = useGetActiveOrder(orderId)
  const history = useHistory()

  useEffect(() => {
    if (error) history.push(`/orders-history/${orderId}`)
  }, [error, orderId])

  if (loading) return <PageLoader />
  if (error) {
    return (
      <React.Fragment>
        <TopNav title={'Zamówienie'} subtitle={` - nieodnalezione`} />
        <div css={sOuter}>
          <Headline>Nieodnaleziono</Headline>
          <Grid container css={sGrid}>
            <Grid item xs={12} css={sBottomLinks}>
              <ButtonBorder link={'/orders'}>Zamówienia</ButtonBorder>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    )
  }

  const { ezcOrderActive, user } = data

  let allProducts,
    allProductsSorted,
    newAllProducts = []
  if (ezcOrderActive?.orderDetails?.length) {
    allProducts = [
      ...ezcOrderActive.orderDetails[0].productsToOrder,
      ...ezcOrderActive.orderDetails[0].productsInProcess,
      ...ezcOrderActive.orderDetails[0].productsFinishedGood,
      ...ezcOrderActive.orderDetails[0].productsFinishedBad,
      ...ezcOrderActive.orderDetails[0].productsDeclined,
    ]

    newAllProducts = removeDuplicateProducts(allProducts)
    allProductsSorted = sortProductsByStatus(newAllProducts)
  }

  const { coinsBalance, coinsBlocked } = user
  let userCoinsBalance, defaultPaymentValue
  if (typeof coinsBalance === 'number' && typeof coinsBlocked === 'number') {
    userCoinsBalance = MoneyEz(coinsBalance)
      .subsctract(coinsBlocked)
      .getAmount()

    defaultPaymentValue = MoneyEz(ezcOrderActive.priceSum)
      .subsctract(ezcOrderActive.paidSum)
      .subsctract(userCoinsBalance)
      .getAmount()
  }

  const needToPay = orderNeedToPay(ezcOrderActive)

  const isCanceled = _getActualStatusName(ezcOrderActive) === 'ANNUL_BY_CLIENT'
  // const isCanceled = true

  const {
    shortId,
    isPaid,
    restId: { name, avatarUrl, _id },
    status: statuses,
    consumptionType,
    deliveryAddress,
    deliveryDetails,
    consumptionDate,
    consumptionTime,
    consumptionPersons, // IKR
    note,
    isInvoice,
    invoiceDetails,
    deliveryCost,
    paidSum,
    priceSum,
    messages,
  } = ezcOrderActive

  const status = [...statuses].pop().name

  return (
    <React.Fragment>
      {/* TOP NAV BAR */}
      <TopNav
        title={name}
        subtitle={` - ${shortId}`}
        photo={avatarUrl}
        textCss={{
          [s.xs]: { maxWidth: `calc(100vw - 9rem)` },
        }}
      />

      {/* {JSON.stringify(allProducts, null, 2)} */}

      {/* HEADLINE */}
      <div css={sOuter}>
        <Headline
          itemChildren={
            <div
              css={{ cursor: 'pointer' }}
              onClick={() => setIsStatusGuideOpen(!isStatusGuideOpen)}>
              <StatusOrder color={ORDER_STATUS_COLOR[status]}>
                {ORDER_STATUSES[status]}
              </StatusOrder>
            </div>
          }
          extraCss={{
            alignItems: 'center',
            [s.xs]: {
              padding: '2rem 1.5rem 1.5rem',
              justifyContent: 'center',
              flexDirection: 'column',
            },
          }}>
          {/* {isCanceled && `Anulowane `} */}
          Zamówienie
          <Hidden xsDown> {shortId}</Hidden>
        </Headline>
      </div>

      {/* RESTAURANT */}
      <RestContainer data={ezcOrderActive.restId} isPaid={isPaid} />

      {isCanceled && (
        <div css={[sOuter, sNoMarginTop]}>
          <Grid container css={sGrid}>
            <OrderCanceled name={name} shortId={shortId} />
          </Grid>
        </div>
      )}

      {/* {needToPay && (
        <Payment
          needToPay={needToPay}
          orderId={orderId}
          defaultValue={defaultPaymentValue}
          userCoinsBalance={userCoinsBalance}
        />
      )} */}

      {/* ORDER NEED PAYMENT */}
      {!isCanceled && needToPay && (
        <>
          <div css={[sOuter, sNoMarginTop]}>
            <Grid container css={sGrid}>
              <OrderNeedPayment
                priceSum={priceSum}
                paidSum={paidSum}
                orderId={orderId}
                defaultValue={defaultPaymentValue} // amountToPay
                userCoinsBalance={userCoinsBalance}
              />
            </Grid>
          </div>
          <div css={sButtonFixed}>
            <ButtonNeedPayment
              orderId={orderId}
              defaultValue={defaultPaymentValue} // amountToPay
              buttonTransparent
            />
          </div>
        </>
      )}

      {/* ORDER PARTIALLY */}
      {status === 'PARTIALLY_ACCEPTED' && (
        <div css={[sOuter, sNoMarginTop]}>
          <Grid container css={sGrid}>
            <OrderPartially
              orderId={orderId}
              amountToPay={defaultPaymentValue}
            />
          </Grid>
        </div>
      )}

      <div css={[sOuter, sNoMarginTop]}>
        <Grid container css={sGrid}>
          {/* ORDER CHAT */}
          <OrderChat
            orderId={orderId}
            name={name}
            avatarUrl={avatarUrl}
            messages={messages}
          />
          {/* ORDER STATUSES */}
          <OrderStatuses
            statuses={statuses}
            open={isStatusGuideOpen}
            setOpen={setIsStatusGuideOpen}
          />
        </Grid>
      </div>

      {/* ORDER PRODUCTS */}
      <div css={[sOuter, sNoMarginTop, sGridSpacingParent]}>
        <Grid
          container
          css={[sGrid, sGridSpacing]}
          spacing={3}
          justify='space-between'>
          <SectionHeadline>Produkty</SectionHeadline>
          {allProductsSorted.map((product, productIndex) => (
            <OrderProduct key={productIndex} data={product} />
          ))}
        </Grid>
      </div>

      {/* ORDER DETAILS */}
      <div css={[sOuter, sNoMarginTop, sGridSpacingParent]}>
        <Grid
          container
          css={[sGrid, sGridSpacing]}
          spacing={3}
          justify='space-between'>
          <SectionHeadline>Szczegóły</SectionHeadline>

          {/* ORDER TYPE */}
          <OrderType consumptionType={consumptionType} />

          {/* ORDER ADDRESS */}
          {consumptionType === 'delivery' && (
            <OrderAddress
              deliveryAddress={deliveryAddress}
              deliveryDetails={deliveryDetails}
            />
          )}

          {/* ORDER DATE */}
          <OrderDate
            consumptionDate={consumptionDate}
            consumptionTime={consumptionTime}
            consumptionPersons={consumptionPersons}
          />

          {/* ORDER NOTE */}
          {note && <OrderNote note={note} />}

          {/* ORDER INVOICE */}
          {isInvoice && <OrderInvoice invoiceDetails={invoiceDetails} />}

          {/* ORDER ID */}
          <OrderID shortId={shortId} orderId={_id} />

          {/* ORDER PAYMENT */}
          <OrderPaymentLarge
            priceSum={priceSum}
            paidSum={paidSum}
            deliveryCost={deliveryCost}
            isDelivery={consumptionType === 'delivery'}
          />
        </Grid>
      </div>
      {status === 'PARTIALLY_ACCEPTED' && (
        <div css={sButtonFixed}>
          <ButtonAccept
            orderId={orderId}
            amountToPay={defaultPaymentValue}
            buttonTransparent
          />
        </div>
      )}

      {!isCanceled && (
        <div
          css={[
            sOuter,
            sNoMarginTop,
            sPaddingTop,
            {
              position: 'relative',
              zIndex: 1000,
            },
          ]}>
          <Grid
            container
            css={[
              sGrid,
              {
                gap: '2rem',
                marginTop: '2rem',
                marginBottom: '3.5rem',
                [s.sm_down]: {
                  flexDirection: 'column',
                  gap: '1rem',
                  marginBottom: '0.5rem',
                },
              },
            ]}>
            <ButtonFinish orderId={orderId} />
            <ButtonCancel
              orderId={orderId}
              isCancelWidgetOpen={isCancelWidgetOpen}
              setIsCancelWidgetOpen={setIsCancelWidgetOpen}
            />
          </Grid>
        </div>
      )}

      {isPaid && !isCanceled && (
        <div css={[sButtonFixed, { zIndex: isCancelWidgetOpen ? 999 : 1000 }]}>
          <SingleOrderMenuButton
            // needToPay={needToPay}
            restId={ezcOrderActive.restId._id}
            orderId={orderId}
          />
        </div>
      )}
      <StatusGuide open={isStatusGuideOpen} setOpen={setIsStatusGuideOpen} />
    </React.Fragment>
  )
}

export const _getActualStatusName = (order) => [...order.status].pop().name

export const sGridSpacing = {
  '.MuiGrid-item': {
    [s.xs]: { paddingBottom: '4px !important' },
  },
}

export const sGridSpacingParent = {
  [s.xs]: {
    '.MuiGrid-spacing-xs-3': {
      width: '100%',
      margin: 0,

      '& > .MuiGrid-item': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
}

export const sButtonFixed = {
  background: `linear-gradient(to right, ${colors.green400}, ${colors.green800})`,
  width: '100%',
  maxWidth: 720,
  // padding: '0 2rem',
  [s.sm_down]: {
    maxWidth: 'calc(100vw - 3rem)',
    padding: '0 1.5rem',
  },
  position: 'fixed',
  height: 48,
  left: '50%',
  transform: 'translateX(-50%)',
  borderRadius: '1.5rem',
  transition: 'bottom 0.6s ease-in-out, filter 0.3s ease-out',
  boxShadow: `0px 8px 24px ${colors.green.concat(alpha[60])}`,
  zIndex: 200,
  bottom: 72,
  '&:hover': {
    [s.hover]: {
      filter: 'brightness(1.1)',
    },
  },
}

export default OrderActive
