/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import { useError } from '../../../hooks/errors.hook'

import MainContainer from '../../components/MainContainer/MainContainer'
import { useGetWaiterDashoboardAccess } from '../../hooks/useQueries'
import useRestaurantMutations from '../../hooks/useRestaurant.mutations'

export default () => {
  const [values, setValues] = useState({
    password: '',
    showPassword: false,
    isChangePassView: false,
    isLoading: false,
  })
  const { errorHandling } = useError()
  const { data, loading, error } = useGetWaiterDashoboardAccess()
  const { handleChangeWaiterPassword } = useRestaurantMutations()

  if (error) {
    errorHandling(error)
    return null
  }

  if (loading) {
    return <div />
  }

  const login = data?.ezrWaiterDashboardAccess?.login

  const onSubmitPassword = async () => {
    setValues({ ...values, isLoading: true })
    await handleChangeWaiterPassword({ password: values.password })
    setValues({ ...values, isLoading: false })
    toast.dark('Hasło zostało zmienione')
  }

  const handleChangePassword = (e) =>
    setValues({ ...values, password: e.target.value })

  const showChangePassword = () => {
    setValues({ ...values, isChangePassView: !values.isChangePassView })
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <MainContainer header='Dostępy'>
      <div css={{ paddingBottom: 24, borderBottom: '1px solid #777' }}>
        <h3 css={{ marginBottom: 12 }}>Dostęp do Pulpitu Kelnera</h3>
        <p css={{ marginBottom: 4 }}>
          Login: <b>{login}</b>
        </p>
        <span
          onClick={showChangePassword}
          css={{
            cursor: 'pointer',
            textDecoration: 'underline',
          }}>
          {values.isChangePassView ? 'Wróć' : 'Zmień hasło'}
        </span>
        {values.isChangePassView && (
          <div>
            <FormControl>
              <InputLabel>Nowe hasło</InputLabel>
              <Input
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChangePassword}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}>
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <Button
              variant='contained'
              color='primary'
              onClick={onSubmitPassword}
              disabled={values.isLoading}
              size='small'
              css={{
                display: 'block',
                margin: '4px 0 8px',
              }}>
              Zmień hasło
            </Button>
          </div>
        )}
      </div>
    </MainContainer>
  )
}
