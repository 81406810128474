/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Bar } from 'react-chartjs-2'

import { dataParse } from '../helpers/data-parse'
import { useGetStatisticOrdersAmount } from '../../../hooks/useQueries'
import { useActiveRestaurantContext } from '../../../context/active-restaurant.provider'


export default () => {
  const { activeRestaurantId } = useActiveRestaurantContext()
  const { data, loading, error } = useGetStatisticOrdersAmount(activeRestaurantId)

  if (loading) return null
  if (error) return null

  const { labels, values } = dataParse(data.ezrOrdersAmountStatistics)

  return <Bar data={dataBar(labels, values)} />
}

const dataBar = (labels, data) => ({
  labels,
  datasets: [
    {
      label: 'Ilość zrealizowanych zamówień',
      data,

      backgroundColor: 'rgba(255,99,132,0.2)',
      borderColor: 'rgba(255,99,132,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
      hoverBorderColor: 'rgba(255,99,132,1)',
    }
  ]
})