import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useAuthContext } from '../../../context/auth.context'
import { useError } from '../../../hooks/errors.hook'
import useUserMutations from '../../hooks/useUser.mutations'
import useOrdersMutations from '../../hooks/useOrders.mutations'

const storageName = 'ordersNoAuth'

export default () => {
  const [ordersNoAuth, setOrdersNoAuth] = useState([])
  const { errorHandling } = useError()
  const { login } = useAuthContext()

  const { userLogin, userCreate } = useUserMutations()
  const { orderAddToUser } = useOrdersMutations()

  const setOrdersToLocalStorage = (data) =>
    localStorage.setItem(storageName, JSON.stringify(data))
  const getOrdersFromLocalStorage = () =>
    JSON.parse(localStorage.getItem(storageName))
  const removeOrdersFromLocalStorage = () =>
    localStorage.removeItem(storageName)

  const setToStateAndLocalStorage = (data) => {
    setOrdersToLocalStorage(data)
    setOrdersNoAuth(data)
  }

  const onCreateOrderAddProdNoAuth = (_, __, prodData, restData) => {
    const q = () => Math.random().toString(36).substring(2, 15)
    const orderDummyId = '' + q() + q()

    const newOrder = {
      _id: orderDummyId,
      restId: restData,
      consumptionType: 'in',
      consumptionPersons: 1,
      orderDetails: [
        {
          productsInCart: [
            {
              prodId: prodData,
              amount: 1,
            },
          ],
        },
      ],
    }

    setToStateAndLocalStorage([...ordersNoAuth, newOrder])
  }

  const onUpdateOrderNoAuth = (data, __) => {
    const newOrders = ordersNoAuth.map((order) =>
      order._id === data._id ? data : order
    )
    setToStateAndLocalStorage(newOrders)
  }

  const onAddProdNoAuth = (data, __, prodData) => {
    const { orderId, prodId } = data

    const findOrder = ordersNoAuth.find((el) => el._id === orderId)
    if (!findOrder) return console.log("can't find order...")
    const userOrder = { ...findOrder }

    if (!userOrder.orderDetails.length) {
      const { price, name, photoUrl } = prodData
      userOrder.orderDetails.push({
        productsInCart: [
          {
            prodId: { _id: prodId, price, name, photoUrl },
            amount: 1,
          },
        ],
      })
    } else {
      const userOrderProduct = userOrder.orderDetails[0].productsInCart.find(
        (el) => el.prodId._id === prodId
      )
      if (userOrderProduct) {
        userOrderProduct.amount++
      } else {
        const { price, name, photoUrl } = prodData
        userOrder.orderDetails[0].productsInCart.push({
          prodId: { _id: prodId, price, name, photoUrl },
          amount: 1,
        })
      }
    }

    const newOrders = ordersNoAuth.map((order) =>
      order._id === orderId ? userOrder : order
    )
    setToStateAndLocalStorage(newOrders)
  }

  const onDeleteProdNoAuth = (data, __) => {
    const { orderId, prodId, isDeleteAllProds } = data

    const findOrder = ordersNoAuth.find((el) => el._id === orderId)
    if (!findOrder) return console.log("can't find order...")
    const userOrder = { ...findOrder }

    if (isDeleteAllProds) {
      userOrder.orderDetails[0].productsInCart = userOrder.orderDetails[0].productsInCart.filter(
        (el) => el.prodId._id !== prodId
      )
    } else {
      const userOrderProduct = userOrder.orderDetails[0].productsInCart.find(
        (el) => el.prodId._id === prodId
      )
      if (userOrderProduct.amount >= 2) {
        userOrderProduct.amount--
      } else if (userOrderProduct.amount === 1) {
        userOrder.orderDetails[0].productsInCart = userOrder.orderDetails[0].productsInCart.filter(
          (el) => el.prodId._id !== prodId
        )
      } else {
        console.log('error, wrong number')
      }
    }

    let newOrders

    if (userOrder.orderDetails[0].productsInCart.length === 0) {
      // delete order
      newOrders = ordersNoAuth.filter((order) => order._id !== orderId)
    } else {
      newOrders = ordersNoAuth.map((order) =>
        order._id === orderId ? userOrder : order
      )
    }

    setToStateAndLocalStorage(newOrders)
  }

  const onDeleteOrderNoAuth = (data, __) => {
    const { orderId } = data
    const newOrders = ordersNoAuth.filter((order) => order._id !== orderId)
    setToStateAndLocalStorage(newOrders)
  }

  const onSendOrdersNoAuthSignup = async (data, cb) => {
    const { email, password, name, surname } = data
    try {
      const { data } = await userCreate({
        variables: {
          input: { email, password, name, surname },
        },
      })

      const input = createOrderInput(ordersNoAuth)

      await orderAddToUser({
        variables: {
          userId: data.userCreate.userId,
          password,
          input,
        },
      })

      removeOrdersFromLocalStorage()

      login(data.userCreate.token, data.userCreate.userId)
      // toast.success(
      //   `Witamy w Eatzon ${(name || surname) && ', '}${name && name + ' '}${
      //     surname && surname + ' '
      //   }! 😎`,
      //   {
      //     autoClose: false,
      //   }
      // )
      setTimeout(() => window.location.reload(), 0)

      if (typeof cb === 'function') cb()
    } catch (err) {
      errorHandling(err)
      if (typeof cb === 'function') cb(err)
    }
  }

  const onSendOrdersNoAuthLogin = async (data, cb) => {
    const { email, password } = data
    try {
      const { data } = await userLogin({
        variables: {
          email: email.trim(),
          password: password.trim(),
        },
      })

      const input = createOrderInput(ordersNoAuth)

      await orderAddToUser({
        variables: {
          userId: data.userLogin.userId,
          password,
          input,
        },
      })

      removeOrdersFromLocalStorage()

      login(data.userLogin.token, data.userLogin.userId)
      // toast.info('Zalogowano pomyślnie')
      setTimeout(() => window.location.reload(), 0)

      if (typeof cb === 'function') cb()
    } catch (err) {
      errorHandling(err)
      if (typeof cb === 'function') cb(err)
    }
  }

  useEffect(() => {
    const data = getOrdersFromLocalStorage()
    if (data) setOrdersNoAuth(data)
  }, [])

  return {
    ordersNoAuth,
    onCreateOrderAddProdNoAuth,
    onUpdateOrderNoAuth,
    onAddProdNoAuth,
    onDeleteProdNoAuth,
    onDeleteOrderNoAuth,

    onSendOrdersNoAuthSignup,
    onSendOrdersNoAuthLogin,
  }
}

function createOrderInput(orders) {
  return orders.map(({ _id, restId, orderDetails, ...orderProps }) => ({
    ...orderProps,
    restId: restId._id,
    orderDetails: orderDetails.map(({ productsInCart }) => ({
      productsInCart: productsInCart.map(({ amount, prodId }) => ({
        amount,
        prodId: prodId._id,
      })),
    })),
  }))
}
