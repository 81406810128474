/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify'
import Headline from '../../../components/Headline/Headline'
import { Input } from '../Auth/components'
import Spinner from '../../../components/Spinner'
import { ButtonBorder, Button } from '../../../components/Elements'
import { sGrid, sDescription } from '../../../style/style'
import { s } from '../../../style'
import validateEmail from '../../../helpers/validateEmail'

const initValues = {
  email: '',
  password: '',
  repeatPassword: '',
  name: '',
  surname: '',
}

const SignupWidget = ({ onSubmit, setAuthType }) => {
  const [inputValues, setInputValues] = useState(initValues)
  const [isLoading, setIsLoading] = useState(false)
  const onChange = (e) =>
    setInputValues({ ...inputValues, [e.target.name]: e.target.value })
  const handleAction = () => setAuthType('LOGIN')
  const handleSubmit = () => {
    try {
      inputCheck(inputValues)
      setIsLoading(true)
      onSubmit(inputValues, () => setIsLoading(false))
    } catch (err) {
      if (err && err.errors) err.errors.forEach((el) => toast.warning(el))
    }
  }

  // const onEnterSubmit = (e) => e.key === 'Enter' && onSubmit()

  return (
    <div>
      <Headline
        extraCss={sHeadline}
        itemChildren={
          <ButtonBorder action={handleAction}>Zaloguj</ButtonBorder>
        }>
        Rejestracja
      </Headline>
      <div css={[sGrid, sGridOverride]}>
        <p css={[sDescription, { fontSize: '0.875rem' }]}>
          Eatzon to aplikacja do zamawiana dań oraz rezerwacji w restauracjach.
          Zarejestruj się i doświadczaj nowych doznań kulinarnych!
        </p>
      </div>
      <div css={[sFormContainer]}>
        <Input
          onChange={onChange}
          name='name'
          value={inputValues.name}
          placeholder='Imię'
        />

        <Input
          onChange={onChange}
          name='surname'
          value={inputValues.surname}
          placeholder='Nazwisko'
        />

        <Input
          onChange={onChange}
          name='email'
          type='email'
          value={inputValues.email}
          placeholder='Email'
        />

        <Input
          onChange={onChange}
          name='password'
          value={inputValues.password}
          type='password'
          placeholder='Hasło'
        />

        <Input
          onChange={onChange}
          name='repeatPassword'
          value={inputValues.repeatPassword}
          type='password'
          placeholder='Powtórz Hasło'
        />

        <Button w100 action={handleSubmit}>
          {isLoading ? (
            <Spinner size={1.5} addCss={{ color: '#fff' }} />
          ) : (
            'Zarejestruj'
          )}
        </Button>

        <p css={[sAgreement]}>
          Rejestrując się, akceptujesz
          <a
            href='https://eatzon.pl/warunki-uslugi'
            rel='noreferrer noopener'
            target='_blank'>
            <strong> Warunki Usługi</strong>
          </a>{' '}
          oraz{' '}
          <strong>
            <a
              href='https://eatzon.pl/polityka-prywatnosci'
              rel='noreferrer noopener'
              target='_blank'>
              Politykę Prywatności
            </a>
          </strong>
        </p>
      </div>
    </div>
  )
}

const sHeadline = {
  [s.sm_down]: { padding: '2rem 2rem 1rem' },
  [s.md]: { padding: '2rem 4rem 2rem' },
}

const sGridOverride = {
  [s.sm_down]: { padding: '0 2rem' },
  [s.md]: { padding: '0 4rem' },
}

const sFormContainer = {
  [s.sm_down]: { padding: '0 1.5rem 1.5rem' },
  [s.md]: { padding: '0.5rem 3rem 2rem' },
}

const sAgreement = {
  textAlign: 'center',
  lineHeight: '1.618em',
  maxWidth: '75%',
  margin: 'auto',
  fontSize: '0.75rem',
  paddingTop: '1.5rem',
  [s.md]: { paddingTop: '2rem' },
}

function inputCheck(inputValues) {
  const errors = []
  if (inputValues.password.length < 8) {
    errors.push('Hasło powinno zawierać min. 8 symboli')
  }

  if (inputValues.password !== inputValues.repeatPassword) {
    errors.push('Hasła nie są zgodne')
  }

  if (!inputValues.name) {
    errors.push('Imię jest wymagane')
  }

  if (!inputValues.surname) {
    errors.push('Nazwisko jest wymagane')
  }

  if (!validateEmail(inputValues.email)) {
    errors.push('Email jest nieprawidłowy')
  }

  if (errors.length) {
    const err = new Error()
    err.errors = errors
    throw err
  }
}

export default SignupWidget
