/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import { Collapsable } from '../../../../components/Elements'

const WalletCharge = () => {
  return (
    <Grid item xs={12} md={6}>
      <Collapsable
        right
        noPadding
        oneLiner
        title={'Transakcje'}
        link={`/account/transactions`}
        icon={<SyncAltIcon />}
        description={`Sprawdź historie transakcji`}></Collapsable>
    </Grid>
  )
}

export default WalletCharge
