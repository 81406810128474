/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { subDays, lightFormat } from 'date-fns';
import { dateFc } from '../../../helpers/date-format'

import OrdersFinished from './views/OrdersFinished'
import OrdersDeclined from './views/OrdersDeclined'
import TabPanel from './components/TabPanel'
import Calendar from './components/Calendar'
import PopupContainer from './components/PopupContainer'


const initCalendar = [{
  startDate: subDays(new Date(), 7),
  endDate: new Date(),
  key: 'selection'
}]

const EzwOrdersFinished = () => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const onCalendarOpen = () => setIsCalendarOpen(true)
  const onCalendarClose = () => setIsCalendarOpen(false)

  // When change something in calendar, we work on calendarState
  // When User accept dates on Calendar View, 
  // we write this data to state, 
  // and data from here is sending to the backend.
  const [state, setState] = useState(initCalendar);
  const [calendarState, setCalendarState] = useState(initCalendar);
  const onSubmitDate = () => setState(calendarState)
  const onResetDate = () => setCalendarState(state)

  const [value, setValue] = useState(0);
  const handleTabChange = (event, newValue) => setValue(newValue);

  const showDate = d => dateFc(lightFormat(d, 'yyyy-MM-dd'))

  const orderInner = (View, index) => (
    <TabPanel value={value} index={index} css={{ "&>div": { padding: '8px 0' } }}>
      <div css={{
        padding: '8px 0 16px',
        display: 'flex',
        alignItems: 'center'
      }}>
        <p css={{ marginRight: 8 }}>
          Zamówienia od {showDate(state[0].startDate) + ' '}
          do {showDate(state[0].endDate)}
        </p>
        <Button onClick={onCalendarOpen} variant="outlined" size="small">
          Zmienić zakres dat
        </Button>
      </div>

      <View
        startDate={state[0].startDate}
        endDate={state[0].endDate}
      />
    </TabPanel>
  )

  return (
    <div css={{ margin: '0 24px' }}>
      <h1 css={{ opacity: .3 }}>
        Historia zamówień
      </h1>

      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleTabChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Zakończone" icon={<DoneOutlineIcon />} />
          <Tab label="Odrzucone i anulowane" icon={<RemoveCircleOutlineIcon />} />
        </Tabs>
      </AppBar>

      {orderInner(OrdersFinished, 0)}
      {orderInner(OrdersDeclined, 1)}

      <PopupContainer
        isOpen={isCalendarOpen}
        onClose={() => {
          onResetDate()
          onCalendarClose()
        }}
        headerText="Podaj zakres dat"
        btnText="Zmień zakres dat"
        btnOnClick={() => {
          onSubmitDate()
          onCalendarClose()
        }}
        css={{ "div.MuiDialog-paperWidthSm": { maxWidth: 700 } }}
      >
        <Calendar
          state={calendarState}
          setState={setCalendarState}
        />
      </PopupContainer>
    </div>
  )
}


export default EzwOrdersFinished