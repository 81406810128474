import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import searchStringCreator from '../helpers/searchStringCreator.search'

const useSearch = () => {
  const history = useHistory()

  const [query, setQuery] = useState('')
  const [prodFilter, setProdFilter] = useState(null)
  const [ingredientsList, setIngredientsList] = useState([])

  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false)
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false)

  // Handlers
  const onQueryStringChange = (e) => setQuery(e.target.value)

  const onSubmit = () => {
    if (isFilterPopupOpen) setIsFilterPopupOpen(false)
    if (isAutocompleteOpen) setIsAutocompleteOpen(false)

    const searchStr = searchStringCreator(query, prodFilter)

    if (
      // if search string is empty
      !searchStr
        .slice(1)
        .split('&')
        .map((el) => el.split('='))
        .filter((el) => el && el[1] && el[1].length > 0).length > 0
    )
      return

    history.push({
      pathname: '/search/results',
      search: searchStr,
    })
  }

  const addNewIngredient = (ingredient) => {
    const isNewIngredient = !ingredientsList.find(
      (el) => el._id === ingredient._id
    )
    if (isNewIngredient) setIngredientsList([ingredient, ...ingredientsList])
    addKeyToFilter('ingredients')(ingredient._id)
  }

  const addKeyToFilter = (filterKey) => (id) => {
    if (!prodFilter) return setProdFilter({ [filterKey]: { must: [id] } })
    if (!prodFilter[filterKey])
      return setProdFilter({ ...prodFilter, [filterKey]: { must: [id] } })

    const { must, mustNot } = prodFilter[filterKey]

    const isCategoryChecked = must && must.find((checkedId) => checkedId === id)
    if (isCategoryChecked) {
      setProdFilter({
        ...prodFilter,
        [filterKey]: {
          must: must.filter((checkedId) => checkedId !== id),
          mustNot: mustNot ? [...mustNot, id] : [id],
        },
      })
      return
    }

    const isCategoryCheckedAsNot =
      mustNot && mustNot.find((checkedId) => checkedId === id)
    if (isCategoryCheckedAsNot) {
      setProdFilter({
        ...prodFilter,
        [filterKey]: {
          ...prodFilter[filterKey],
          mustNot: mustNot.filter((checkedId) => checkedId !== id),
        },
      })
      return
    }

    setProdFilter({
      ...prodFilter,
      [filterKey]: {
        ...prodFilter[filterKey],
        must: must ? [...must, id] : [id],
      },
    })
  }

  const onFloatChange = (filterKey) => (e, value) => {
    if (!prodFilter)
      return setProdFilter({ [filterKey]: { min: value[0], max: value[1] } })

    setProdFilter({
      ...prodFilter,
      [filterKey]: {
        min: value[0],
        max: value[1],
      },
    })
  }

  return {
    states: {
      query,
      setQuery,
      prodFilter,
      setProdFilter,
      ingredientsList,
      setIngredientsList,

      isAutocompleteOpen,
      setIsAutocompleteOpen,
      isFilterPopupOpen,
      setIsFilterPopupOpen,
    },

    handlers: {
      addKeyToFilter,
      onFloatChange,
      onSubmit,
      addNewIngredient,
      onQueryStringChange,
    },
  }
}

export default useSearch
