import { useState } from 'react'
import { toast } from 'react-toastify'
import { useError } from '../../../hooks/errors.hook'
import MoneyEz from '../../../helpers/MoneyEz'

import useRestaurantsMutations from '../../hooks/useRestaurants.mutations'
import { useGetRestaurantsByName } from '../../hooks/useRestaurants.queries'

const useChargeRestaurant = () => {
  const { errorHandling } = useError()
  const { handleChargeRestaurant } = useRestaurantsMutations()
  const [
    getRestaurant,
    { data, loading, error, refetch },
  ] = useGetRestaurantsByName()

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [chargeData, setChargeData] = useState({ restId: '', amount: '' })

  if (error) errorHandling(error)

  const onRestaurantChoose = (restId) => {
    setIsPopupOpen(true)
    setChargeData({ ...chargeData, restId })
  }

  const onPopupClose = () => {
    setIsPopupOpen(false)
    setChargeData({ restId: '', amount: 0 })
  }

  const onChangeAmount = (e) => {
    setChargeData({ ...chargeData, amount: e.target.value })
  }

  const onChargeRestaurant = async () => {
    try {
      const { restId, amount } = chargeData
      const money = MoneyEz(parseFloat(amount))

      if (money.getAmount() <= 0) {
        throw new Error('Kwota musi być dodatnia')
      }

      const success = await handleChargeRestaurant(restId, money.getAmount())

      if (success?.data) {
        const {
          payoutAmount,
          commissionAmount,
        } = success.data.adminChargeRestaurant

        toast.success(
          `Konto restauracji zostało obciążone na ${money.getAmountWithFullPrecisionAndZl()},
          w tym ${MoneyEz(
            payoutAmount
          ).getAmountWithFullPrecisionAndZl()} wypłaty i 
          ${MoneyEz(commissionAmount).getAmountWithFullPrecisionAndZl()} komisji
        `,
          { autoClose: false }
        )

        refetch()

        setIsPopupOpen(false)
      }
    } catch (err) {
      errorHandling(err)
    }
  }

  return {
    getRestaurant,
    data,
    loading,
    chargeData,
    onRestaurantChoose,
    isPopupOpen,
    onPopupClose,
    onChangeAmount,
    onChargeRestaurant,
  }
}

export default useChargeRestaurant
