/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import ImagePicker from '../../../components/ImagePicker'
import { useProductContext } from '../context/product.provider'

const ImageLogo = () => {
  const { avatarUrl, imagePreview, onAddImage } = useProductContext()

  return (
    <ImagePicker
      left
      onChange={onAddImage}
      image={imagePreview}
      imageUrl={avatarUrl}
      title='Zdjęcie'
      desc='Fotografia produktu'
      name='photofileLogo'
    />
  )
}

export default ImageLogo
