/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { StatusLarge } from '../../../components/Elements'
import {
  PRODUCT_STATUSES,
  PRODUCT_STATUS_COLOR,
} from '../../../constants/statuses'
import { alpha, colors, s } from '../../../style'
import X from '@material-ui/icons/Clear'
import Arrow from '@material-ui/icons/ArrowRightAlt'
import { Grid, Hidden } from '@material-ui/core'
import { krakenUrls } from '../../../constants/s3-files'
import { useOrderAttentionContext } from './context/order-attention.context'

const OrderProduct = ({ data, action, editable }) => {
  const {
    name,
    price,
    amount,
    prodId: { _id, photoUrl },
    status,
  } = data

  const statusCurrent = status?.length > 0 && [...status].pop().name

  const isCheck = data.isStatusChange

  return (
    <Grid item xs={12} md={6} css={sOuter}>
      {/* <div css={sCheck(product.isStatusChange)} onClick={action}> */}
      <div
        css={[
          sContainer,
          editable && !isCheck && { opacity: 0.3 },
          editable && { cursor: 'pointer' },
        ]}
        onClick={editable && action ? action : null}>
        <div
          css={[
            sProdInOrder(PRODUCT_STATUS_COLOR[statusCurrent]),
            isCheck && { opacity: 1 },
          ]}
        />
        <div
          css={[
            sImageWrapper,
            !photoUrl && {
              backgroundColor: colors.gray300,
              [s.sm_down]: { boxShadow: 'none' },
              [s.md]: { boxShadow: 'none' },
            },
            editable && { marginLeft: 12 },
          ]}>
          {photoUrl && (
            <img
              className='prod-item-image'
              src={krakenUrls.small + photoUrl}
              alt={name}
              loading='lazy'
            />
          )}
        </div>
        <div css={sTextContainer}>
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}>
            <h5 className='prod-item-title'>{name}</h5>
            <Hidden smDown>
              {statusCurrent && (
                <StatusLarge color={PRODUCT_STATUS_COLOR[statusCurrent]}>
                  {PRODUCT_STATUSES[statusCurrent]}
                </StatusLarge>
              )}
            </Hidden>
          </div>

          <p css={sPrice}>
            {amount > 1 && (
              <>
                {amount}{' '}
                <span css={sIcon}>
                  <X />
                </span>
              </>
            )}
            {price.toFixed(2)} <span>&nbsp;zł</span>
            {amount > 1 && (
              <>
                <span css={sIcon}>
                  <Arrow />
                </span>
                {(amount * price).toFixed(2)} <span>&nbsp;zł</span>
              </>
            )}
          </p>

          <Hidden mdUp>
            {statusCurrent && (
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  width: '100%',
                }}>
                <StatusLarge color={PRODUCT_STATUS_COLOR[statusCurrent]}>
                  {PRODUCT_STATUSES[statusCurrent]}
                </StatusLarge>
              </div>
            )}
          </Hidden>
        </div>
      </div>
      {editable && action && (
        <div css={sCheck(isCheck)} onClick={action}>
          <span />
        </div>
      )}
    </Grid>
  )
}

const sProdInOrder = (color) => ({
  opacity: 0,
  position: 'absolute',
  top: 0,
  left: 0,
  width: 12,
  transition: 'opacity 0.3s ease-out',
  height: '100%',
  backgroundColor: color,
  borderRadius: '8px 0px 0px 8px',
})

export const sCheck = (check) => ({
  cursor: 'pointer',

  flexShrink: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  marginLeft: '1rem',
  width: 20,
  height: 20,
  borderRadius: '50%',
  boxShadow: check
    ? `0px 0px 0px 4px ${colors.text}`
    : `0px 0px 0px 4px ${colors.gray400}`,
  transition: 'box-shadow 0.3s ease-out',
  '&:hover': {
    boxShadow: check
      ? `0px 0px 0px 4px ${colors.text}`
      : `0px 0px 0px 4px ${colors.gray700}`,
  },
  span: {
    borderRadius: '50%',
    transition: 'transform 0.3s',
    width: 14,
    height: 14,
    transform: check ? 'scale(1)' : 'scale(0)',
    backgroundColor: colors.text,
  },
})

const sOuter = {
  display: 'flex',
  alignItems: 'center',
}

export const sContainer = {
  width: '100%',
  position: 'relative',
  display: 'flex',
  borderRadius: 16,
  background: `linear-gradient(to right, ${colors.gray100}, ${colors.gray200})`,
  transition:
    'background-color 0.3s ease-out, border-radius 0.15s ease-out, box-shadow 0.3s ease-out, opacity 0.3s ease-out',
  padding: '2rem',
  [s.sm_down]: { padding: '1rem' },
  [s.md]: { backgroundColor: colors.gray200 },

  '&:hover': {
    [s.hover]: {
      backgroundColor: colors.gray200,
      '.prod-item-image': {
        transform: 'scale(1.1)',
        filter: 'brightness(1.1)',
      },
      '.prod-item-button': {
        backgroundColor: colors.gray400,
      },
    },
  },
}

const sImageWrapper = {
  flexShrink: 0,
  position: 'relative',
  borderRadius: '50%',
  width: 48,
  height: 48,
  [s.md]: {
    width: 64,
    height: 64,
    boxShadow: `-2px 4px 16px ${colors.black.concat(alpha[16])}`,
  },
  [s.sm_down]: {
    boxShadow: `-1px 2px 8px ${colors.black.concat(alpha[16])}`,
  },

  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
    borderRadius: 'inherit',
    transition: 'transform 0.3s ease-in-out, filter 0.3s ease-in-out',
  },
}

const sTextContainer = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '1rem',
  [s.sm]: {
    paddingLeft: '2rem',
  },

  '.prod-item-title': {
    fontWeight: 800,
    fontSize: '0.8125rem',
    letterSpacing: '-0.025em',
    textTransform: 'capitalize',
    marginBottom: '0.25rem',
    marginRight: '1rem',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    [s.md]: {
      marginRight: '2rem',
      fontSize: '0.9375rem',
      marginBottom: '0.5rem',
    },
    [s.xl]: { maxWidth: '90%' },
  },
}

const sPrice = {
  // fontWeight: 800,
  // fontSize: '0.75rem',
  // [s.md]: { fontSize: '0.875rem' },
  // span: {
  //   opacity: 0.3,
  // },
  // display: 'flex',
  // alignItems: 'center',
  paddingBottom: '0.5rem',
  fontWeight: 500,
  fontSize: '0.75rem',
  [s.md]: { fontSize: '0.875rem', paddingBottom: 0 },
  opacity: 0.6,
  span: {
    opacity: 0.5,
  },
  display: 'flex',
  alignItems: 'center',
}

const sIcon = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  margin: '0 0.25rem',
  svg: { fontSize: '0.875rem' },
  [s.md]: { svg: { marginTop: 1 } },
}

export default OrderProduct
