/** @jsx jsx */
import React, { useEffect, useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import { sDescription, sGrid, sOuter } from '../../../style/style'
import Headline from '../../../components/Headline/Headline'
import { ButtonBorder, SectionHeadline } from '../../../components/Elements'
import { Grid, Hidden } from '@material-ui/core'
import { s } from '../../../style'
import { useOrdersContext } from '../../context/orders.context'
import ActiveOrderItem from '../../../EZC/pages/orders/components/ActiveOrderItem'
import SkeletonOrder from '../../../components/SkeletonOrder/SkeletonOrder'
import {
  useGetDeclinedOrders,
  useGetFinishedOrders,
} from '../../hooks/useQueries'
import useOrdersPagination from '../../hooks/useOrdersPagination'
import { Input } from '../../../EZC/views/Auth/components'

const HistoryPage = () => {
  const date = new Date()
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 2)
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1)

  const [startDate, setStartDate] = useState(date.toISOString().slice(0, 10))
  const [endDate, setEndDate] = useState(date.toISOString().slice(0, 10))

  const {
    data: dataF,
    error: errorF,
    loading: loadingF,
    refetch: refetchF,
    fetchMore: fetchMoreF,
  } = useGetFinishedOrders({
    startDate,
    endDate,
  })

  const {
    data: dataD,
    error: errorD,
    loading: loadingD,
    refetch: refetchD,
    fetchMore: fetchMoreD,
  } = useGetDeclinedOrders({
    startDate,
    endDate,
  })

  const {
    orders: ordersF,
    onLoadMore: onLoadMoreF,
    isLoading: isLoadingF,
    noMoreToFetch: noMoreToFetchF,
  } = useOrdersPagination({
    dataOrders: dataF?.ezrOrdersFinished,
    fetchMore: fetchMoreF,
    startDate,
    endDate,
  })

  const {
    orders: ordersD,
    onLoadMore: onLoadMoreD,
    isLoading: isLoadingD,
    noMoreToFetch: noMoreToFetchD,
  } = useOrdersPagination({
    dataOrders: dataD?.ezrOrdersDeclined,
    fetchMore: fetchMoreD,
    startDate,
    endDate,
  })

  useEffect(() => {
    refetchF()
    refetchD()
  }, [])

  return (
    <React.Fragment>
      <TopNav title={'Historia'} offset={60} />
      <div css={sOuter}>
        <div
          css={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            [s.sm_down]: {
              flexDirection: 'column',
              justifyContent: 'flex-start',
            },
          }}>
          <Headline>Historia</Headline>
          <Grid
            container
            css={[
              sGrid,
              { width: '100%', [s.md]: { justifyContent: 'flex-end' } },
            ]}>
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              lg={2}
              css={{
                // [s.sm_down]: { marginBottom: '2rem' },
                paddingRight: '0.75rem',
              }}>
              <Input
                darker
                type='date'
                label='Od'
                pattern='\d{1,2}/\d{1,2}/\d{4}'
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              lg={2}
              css={{
                // [s.sm_down]: { marginBottom: '2rem' },
                paddingLeft: '0.75rem',
              }}>
              <Input
                darker
                type='date'
                label='Do'
                pattern='\d{1,2}/\d{1,2}/\d{4}'
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container css={sGrid}>
          {errorF && (
            <p
              css={[
                sDescription,
                { animation: 'fadeIn 1s ease-out 1s both alternate' },
              ]}>
              Nie odnaleziono zakończonych zamówień
            </p>
          )}
          {ordersF?.length === 0 && (
            <p css={sDescription}>
              Nie odnaleziono Zakończonych Zamówień w wybranym przedziale
              czasowym
            </p>
          )}
          {ordersF?.length > 0 &&
            ordersF.map((order, id) => (
              <ActiveOrderItem
                id={id}
                key={order._id}
                orderId={order._id}
                date={order.consumptionDate}
                time={order.consumptionTime}
                consumptionType={order.consumptionType}
                restaurantAvatar={order.ownerId.personal.avatarUrl}
                shortId={order.shortId}
                isInvoice={order.isInvoice}
                restaurantName={`${order.ownerId.personal.name} ${
                  order.ownerId.personal.surname
                    ? order.ownerId.personal.surname
                    : ''
                }
                `}
                priceSum={order.priceSum}
                paidSum={order.paidSum}
                isPaid={order.isPaid}
                status={[...order.status].pop().name}
                waiter
                // waiterHistory
                link={`/waiter/history/finish/${order._id}`}
              />
            ))}
          {(loadingF || isLoadingF) && (
            <>
              <SkeletonOrder />
              <SkeletonOrder />
            </>
          )}
          {ordersF && ordersF?.length > 0 && !noMoreToFetchF && (
            <Grid item xs={12} css={sButtons}>
              <ButtonBorder
                action={() => onLoadMoreF(ordersF[ordersF.length - 1]._id)}>
                Pobierz więcej
              </ButtonBorder>
            </Grid>
          )}
        </Grid>
        <Grid container css={sGrid}>
          <div css={{ paddingBottom: '1rem', width: '100%' }}>
            <SectionHeadline noPaddingLeft>Anulowane</SectionHeadline>
          </div>
        </Grid>
        <Grid container css={sGrid}>
          {errorD && (
            <p
              css={[
                sDescription,
                { animation: 'fadeIn 1s ease-out 1s both alternate' },
              ]}>
              Nie odnaleziono anulowanych zamówień
            </p>
          )}
          {ordersD?.length === 0 && (
            <p css={sDescription}>
              Nie odnaleziono Anulowanych Zamówień w wybranym przedziale
              czasowym
            </p>
          )}
          {ordersD?.length > 0 &&
            ordersD.map((order, id) => (
              <ActiveOrderItem
                id={id}
                key={order._id}
                orderId={order._id}
                date={order.consumptionDate}
                time={order.consumptionTime}
                consumptionType={order.consumptionType}
                restaurantAvatar={order.ownerId.personal.avatarUrl}
                shortId={order.shortId}
                isInvoice={order.isInvoice}
                restaurantName={`${order.ownerId.personal.name} ${
                  order.ownerId.personal.surname
                    ? order.ownerId.personal.surname
                    : ''
                }
                `}
                priceSum={order.priceSum}
                paidSum={order.paidSum}
                isPaid={order.isPaid}
                status={[...order.status].pop().name}
                waiter
                // waiterHistory
                link={`/waiter/history/cancel/${order._id}`}
              />
            ))}
          {(loadingD || isLoadingD) && (
            <>
              <SkeletonOrder />
              <SkeletonOrder />
            </>
          )}
          {ordersD && ordersD?.length > 0 && !noMoreToFetchD && (
            <Grid item xs={12} css={sButtons}>
              <ButtonBorder
                action={() => onLoadMoreD(ordersD[ordersD.length - 1]._id)}>
                Pobierz więcej
              </ButtonBorder>
            </Grid>
          )}
        </Grid>
      </div>
    </React.Fragment>
  )
}

const sButtons = {
  display: 'flex',
  [s.sm_down]: {
    paddingTop: '0.5rem',
  },
  [s.md]: {
    paddingTop: '1.5rem',
  },
}

export default HistoryPage
