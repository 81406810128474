/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { alpha, colors, s } from '../../../style'

const FilterGroup = ({ name, data, onChoose, filterData, types }) => {
  const [input, setInput] = useState('')

  if (!data) return <div />

  return (
    <div css={sFilterGroup}>
      <h2 css={sTitle}>{name}</h2>
      <div css={sFilterOptions}>
        {data.map((item, id) => (
          <FilterOption
            key={id}
            id={types ? item : item._id}
            name={types ? types[item]['pl'] : item.name}
            onChoose={() => onChoose(types ? item : item._id)}
            filterData={filterData}
          />
        ))}
      </div>
    </div>
  )
}

export const FilterOption = ({
  id,
  onChoose,
  name,
  filterData,
  noMargin,
  onSubmit,
  clickDisabled,
  setOpen,
  open,
  darker,
}) => {
  const isMust =
    filterData &&
    filterData.must &&
    filterData.must.find((categoryId) => categoryId === id)

  const isMusnt =
    filterData &&
    filterData.mustNot &&
    filterData.mustNot.find((categoryId) => categoryId === id)

  return (
    <div
      onClick={() => (open === false ? setOpen(true) : onChoose())}
      css={[
        sFilterOption,

        noMargin && { marginBottom: 0 },
        darker && sFilterOptionDarker,
        isMust && {
          color: colors.green,
          backgroundColor: colors.green.concat(alpha[16]),
        },
        isMusnt && {
          color: colors.red,
          backgroundColor: colors.red.concat(alpha[16]),
        },
      ]}>
      <div
        css={[
          sIcon,
          // !(isMusnt || isMust) && { width: 4 }
        ]}>
        <AddIcon
          fontSize='inherit'
          css={[sAbsolute, isMust && { opacity: 1 }]}
        />
        <RemoveIcon
          fontSize='inherit'
          css={[sAbsolute, isMusnt && { opacity: 1 }]}
        />
      </div>
      {name}
    </div>
  )
}

export const sTitle = {
  fontSize: '1.25rem',
  fontWeight: 800,
  letterSpacing: '-0.05em',
  margin: '0 0.5rem 1.5rem',
}

const sAbsolute = {
  opacity: 0,
  position: 'absolute',
  top: '50%',
  left: 0,
  transform: 'translate(0%, -50%)',
  transition: 'opacity 0.15s ease-out',
}

export const sFilterGroup = {
  margin: '1.5rem 0',
}

const sFilterOptions = {
  display: 'flex',
  flexWrap: 'wrap',
}

const sFilterOption = {
  transition: 'all 0.15s ease-out',
  display: 'flex',
  width: 'max-content',
  color: colors.gray600,
  backgroundColor: colors.gray200,
  padding: '0.5rem 0.75rem 0.5rem 0.5rem',
  borderRadius: '2rem',
  fontSize: '0.75rem',
  margin: '0 0.5rem 0.5rem 0',
  fontWeight: 800,
  cursor: 'pointer',
  height: 'max-content',
  whiteSpace: 'nowrap',
  [s.md]: {
    fontSize: '0.875rem',
  },
  animation: 'fadeIn 0.3s ease-out both',
}

const sFilterOptionDarker = {
  color: colors.text,
  backgroundColor: colors.gray300,
}

const sIcon = {
  position: 'relative',
  width: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1rem',
  transition: 'width 0.15s ease-out',
}

export default FilterGroup
