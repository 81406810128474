/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import AddImgHandler from '../../../components/AddImgHandler/AddImgHandler'
import { useProductContext } from '../context/product.provider'



export default () => {
  const {
    avatarUrl,
    imagePreview,
    onAddImage,
  } = useProductContext()

  return (
    <AddImgHandler
      onChange={onAddImage}
      preview={imagePreview}
      imgUrl={avatarUrl}
      header="Zdjęcie"
      subheader="Wybierz fotografię"
      name="photofile"
    />
  )
}