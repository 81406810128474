import React, { useState, useEffect, useContext, createContext } from 'react'
import { useGetOrdersInCart } from '../../hooks/useOrders.queries'
import useOrdersMutations from '../../hooks/useOrders.mutations'
import { useAuthContext } from '../../../context/auth.context'
import { useNoAuthOrdersContext } from '../NoAuthOrders/NoAuthOrders.provider'


export const OrderInCartByRestaurantContext = createContext({
  setRestId: () => { },
  handleAddProd: () => { },
  handleDeleteProduct: () => { },
  restaurantsOrderInCartId: '',
  loadingOrders: undefined,
  errorOrders: undefined,
  order: {}
})

export const useOrderInCartByRestaurant = () => useContext(OrderInCartByRestaurantContext)



export const OrderInCartByRestaurantIdProvider = ({ children }) => {
  const {
    handleAddProduct,
    handleCreateOrderAddProd,
    handleDeleteProduct
  } = useOrdersMutations()

  const {
    data: dataOrders,
    loading: loadingOrders,
    error: errorOrders
  } = useGetOrdersInCart()

  const { isAuth } = useAuthContext()
  const { ordersNoAuth } = useNoAuthOrdersContext()

  const [restaurantsOrderInCartId, setOrderInCartByRestaurantId] = useState('')
  const [restId, setRestId] = useState('')

  const findOrderCb = order => order.restId._id === restId
  const order = restId && (
    isAuth
      ? dataOrders?.ezcOrdersInCart.find(findOrderCb)
      : ordersNoAuth.find(findOrderCb)
  )

  useEffect(() => {
    if (order) {
      setOrderInCartByRestaurantId(order._id)
    } else {
      if (restaurantsOrderInCartId) {
        setOrderInCartByRestaurantId('')
      }
    }
  }, [order, restId])

  const handleAddProd = (e, prodId, noAuthProdData = {}, noAuthRestData = {}) => {
    e.preventDefault()
    const { price, name, photoUrl, _id } = noAuthProdData

    if (restaurantsOrderInCartId) {
      handleAddProduct({ orderId: restaurantsOrderInCartId, prodId }, null, { price, name, photoUrl })
    } else {
      handleCreateOrderAddProd({ prodId, restId }, null, { _id, price, name, photoUrl }, noAuthRestData)
    }
  }

  return (
    <OrderInCartByRestaurantContext.Provider value={{
      setRestId,
      handleAddProd,
      handleDeleteProduct,
      restaurantsOrderInCartId,
      loadingOrders,
      errorOrders,
      order
    }}>
      {children}
    </OrderInCartByRestaurantContext.Provider>
  )
}