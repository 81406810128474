/** @jsx jsx */
import React, { useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import {
  useGetFinishedOrders,
  useGetDeclinedOrders,
} from '../../../hooks/useOrders.queries'
import ActiveOrderItem from '../../orders/components/ActiveOrderItem'
import { SectionHeadline } from '../../../../components/Elements'
import { useError } from '../../../../hooks/errors.hook'
import { sDescription } from '../../../../style/style'
import SkeletonOrder from '../../../../components/SkeletonOrder/SkeletonOrder'

export const OrdersHistoryFinished = () => {
  const { data, loading, error, refetch } = useGetFinishedOrders()
  const { errorHandling } = useError()

  useEffect(() => {
    refetch()
  }, [])

  if (error) {
    errorHandling(error)
    return null
  }
  if (loading)
    return (
      <>
        <SkeletonOrder />
        <SkeletonOrder />
        <SkeletonOrder />
        <SkeletonOrder />
      </>
    )
  if (!data) return null

  if (data?.ezcOrdersFinished?.length === 0)
    return (
      <div css={[sDescription]}>
        Brak zrealizowanych zamówień. Wyszukaj to, na co masz ochotę i
        rozpocznij pierwsze zamówienie!
      </div>
    )

  return (
    <>
      {data.ezcOrdersFinished.map((order, id) => (
        <ActiveOrderItem
          isOrderHistory
          id={id}
          key={order._id}
          orderId={order._id}
          date={order.consumptionDate}
          time={order.consumptionTime}
          consumptionType={order.consumptionType}
          restaurantAvatar={order.restId.avatarUrl}
          restaurantName={order.restId.name}
          priceSum={order.priceSum}
          isPaid={order.isPaid}
          status={[...order.status].pop().name}
        />
      ))}
    </>
  )
}

export const OrdersHistoryDeclined = () => {
  const { data, loading, error, refetch } = useGetDeclinedOrders()
  const { errorHandling } = useError()
  useEffect(() => {
    refetch()
  }, [])

  if (error) {
    errorHandling(error)
    return null
  }
  if (loading) return null
  if (!data) return null

  if (data?.ezcOrdersDeclined?.length === 0) return null

  const { ezcOrdersDeclined } = data

  // console.log(ezcOrdersDeclined)

  return (
    <>
      <div css={{ paddingBottom: '1rem', width: '100%' }}>
        <SectionHeadline>Anulowane</SectionHeadline>
      </div>
      {ezcOrdersDeclined
        .slice()
        .sort((a, b) => {
          const dateA = new Date(a.consumptionDate + ' ' + a.consumptionTime)
          const dateB = new Date(b.consumptionDate + ' ' + b.consumptionTime)
          return dateB - dateA
        })
        .map((order, id) => (
          <ActiveOrderItem
            isOrderHistory
            id={id}
            key={order._id}
            orderId={order._id}
            date={order.consumptionDate}
            time={order.consumptionTime}
            consumptionType={order.consumptionType}
            restaurantAvatar={order.restId.avatarUrl}
            restaurantName={order.restId.name}
            priceSum={order.priceSum}
            isPaid={order.isPaid}
            status={[...order.status].pop().name}
          />
        ))}
    </>
  )
}
