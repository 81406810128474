/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import TextField from '@material-ui/core/TextField';

import { useModifierGroupContext } from '../context/modifier-group.context'



export default () => {
  const {
    amountRules,
    onChangeAmountRules
  } = useModifierGroupContext()

  return (
    <React.Fragment>
      <p>Ile maksymalnie modyfikatorów może wybrać klient?</p>
      <TextField
        type="number"
        value={amountRules.maxSelectAmount}
        onChange={onChangeAmountRules.maxSelectAmount}
        size="small"
      />

      <br />
      <br />
      <br />

      <p>Ile maksymalnie <u>pojedyńczych</u> modyfikatorów może wybrać klient?</p>
      <TextField
        type="number"
        value={amountRules.maxSingleItemSelectAmount}
        onChange={onChangeAmountRules.maxSingleItemSelectAmount}
        size="small"
      />
    </React.Fragment>
  )
}