/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify'

import { SUBS_ADMIN_CHAT_CLIENT } from '../../graphql/chat.graphql'

import {
  ChatContainer,
  InputArea,
  Messages,
  MessagesContainer,
  HeaderInfo,
} from './components'
import useChat from './hooks/useChat'
import useAdminChatClient from './hooks/useAdminChatClient'
import eatzonIcon from '../../../style/assets/eatzonIcon'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'

const AdminChatClient = () => {
  const {
    newMssg,
    setNewMssg,
    measuredRef,
    scrollToBottom,
    errorHandling,
    userId,
  } = useChat()

  const {
    chatId,
    setChatId,
    data,
    error,
    loading,
    subscribeToMore,
    onCloseChat,
    onSend,
    onReadMssgs,
  } = useAdminChatClient({
    newMssg,
    setNewMssg,
    userId,
    scrollToBottom,
  })

  if (error) {
    errorHandling(error)
    if (error.message === 'NO_ACTIVE_ADMIN_CHAT') {
      if (chatId !== undefined) setChatId(undefined)
    } else {
      return null
    }
  }
  if (loading) return null

  if (!chatId) {
    return (
      <ChatContainer>
        <ChatWindow>
          <TopNav
            isScrolledAlready
            icon={eatzonIcon}
            title={'Eatzon'}
            subtitle={` -  Czat`}
          />
          <MessagesContainer measuredRef={measuredRef}>
            <Messages userId={userId} />
          </MessagesContainer>

          <InputArea
            newMssg={newMssg}
            setNewMssg={setNewMssg}
            onSend={onSend}
            onReadMssgs={onReadMssgs}
          />
        </ChatWindow>
      </ChatContainer>
    )
  }

  return (
    <ChatContainer>
      <ChatWindow>
        <TopNav
          isScrolledAlready
          icon={eatzonIcon}
          title={'Eatzon'}
          subtitle={` -  Czat`}
        />

        <MessagesContainer measuredRef={measuredRef}>
          <Messages
            userId={userId}
            chatId={chatId}
            messages={data && data.ezcAdminChat.messages}
            subscribeToNewMssgs={() =>
              subscribeToMore({
                document: SUBS_ADMIN_CHAT_CLIENT,
                variables: { chatId },
                updateQuery: (prev, { subscriptionData }) => {
                  if (!subscriptionData.data) return prev
                  const newMssg = subscriptionData.data.adminChatClient
                  if (newMssg.message === 'CHAT_WAS_FINISHED') {
                    toast.warning(
                      'Czat został zamknięty. Jeśli masz dodatkowe pytania, daj nam znać w nowej wiadomości'
                    )
                    setChatId(undefined)
                    return {
                      ezcAdminChat: {},
                    }
                  }

                  return {
                    ezcAdminChat: {
                      ...prev.ezcAdminChat,
                      messages: [...prev.ezcAdminChat.messages, newMssg],
                    },
                  }
                },
              })
            }
          />
        </MessagesContainer>

        <InputArea
          newMssg={newMssg}
          setNewMssg={setNewMssg}
          onSend={onSend}
          onReadMssgs={onReadMssgs}
        />
      </ChatWindow>
    </ChatContainer>
  )
}

const ChatWindow = ({ children }) => (
  <div
    css={{
      flexGrow: 1,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}>
    {children}
  </div>
)

export default AdminChatClient
