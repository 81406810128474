/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars


export default ({ sum, onClick }) => (
  <div css={{
    position: 'sticky',
    width: '100%',
    maxWidth: 420,
    margin: '0 auto',
    bottom: 0,
    padding: 8,
  }}>
    <div onClick={onClick} css={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: 420,
      margin: '0 auto',
      backgroundColor: '#FFD600',
      borderRadius: 8,
      padding: 12,
      fontSize: 16,
      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    }}>
      <p>{sum}</p>
      <h4>Dalej</h4>
    </div>
  </div>
)