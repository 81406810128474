import { useQuery } from '@apollo/client'
import {
  SEARCH,
  GET_NOTIFICATIONS,
  GET_SEARCH_AUTOCOMPLETE
} from '../graphql/user.graphql'


export const useGetUserNotifications = () =>
  useQuery(GET_NOTIFICATIONS, { pollInterval: 2000 })

export const useSearch = ({ query, prodFilter }) =>
  useQuery(SEARCH, { variables: { query, prodFilter } })

export const useSearchAutocomplete = (query) =>
  useQuery(GET_SEARCH_AUTOCOMPLETE, { variables: { query } })