/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useParams } from 'react-router-dom'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';

import { krakenUrls } from '../../../../constants/s3-files'
import MoneyEz from '../../../../helpers/MoneyEz'

import { useAddProductsContext } from '../context/orders-add-product.context'



export default () => {
  const {
    isCartOpen,
    toggleDrawer,
    cart,
    amountPrice,
    addProduct,
    deleteProduct,
    onSendProducts
  } = useAddProductsContext()

  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      anchor="bottom"
      open={isCartOpen}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <Container>
        <h2 css={{ margin: '8px 0' }}>Koszyk</h2>
        <div css={{
          maxHeight: '50vh',
          overflow: 'scroll',
          padding: '12px 0'
        }}>
          {cart.map(prod => <ProductItem key={prod.name} {...prod} addProduct={addProduct} deleteProduct={deleteProduct} />)}

          <h2 css={{
            margin: '8px 0',
            textAlign: 'right'
          }}>Razem {amountPrice}</h2>

        </div>
        <div css={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
          <button
            onClick={onSendProducts}
            css={{
              fontWeight: 700,
              backgroundColor: '#FFD600',
              color: '#3a3a39',
              boxShadow: 'none',
              border: 'none',
              borderRadius: 8,
              padding: '10px 22px',
              margin: 8,
              fontSize: 16,
              textTransform: 'uppercase',
              cursor: 'pointer',
            }}
          >
            Dodaj do zamówienia - {amountPrice}
          </button>
        </div>
      </Container>
    </SwipeableDrawer>
  )
}


// Helper Components
const Container = ({ children }) => (
  <div css={{
    padding: 12,
    width: 540,
    margin: '0 auto',
  }}>
    {children}
  </div>
)

const ProductItem = (props) => {
  const { name, price, photoUrl } = props

  return (
    <div css={{
      display: 'flex',
      justifyContent: 'space-between',
      padding: 16,
      width: '100%'
    }}>
      <ProductImg photoUrl={photoUrl} />
      <ProductInfo name={name} price={price} />

      <AddRemove {...props} />
    </div>
  )
}

const ProductImg = ({ photoUrl }) => photoUrl && (
  <div css={{
    width: 120,
    marginRight: 32,
    borderRadius: 8,
    overflow: 'hidden'
  }}>
    <img src={krakenUrls.small + photoUrl} alt="" css={{ width: '100%' }} />
  </div>
)

const ProductInfo = ({ name, price }) => (
  <div css={{
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  }}>
    <h4>{name}</h4>
    <p>{MoneyEz(price).getAmountWithFullPrecisionAndZl()}</p>
  </div>
)

const AddRemove = ({ _id, name, price, photoUrl, amount, addProduct, deleteProduct }) => (
  <div css={{
    display: 'flex',
    alignItems: 'center'
  }}>
    <div css={{
      display: 'flex',
      width: 100,
      borderRadius: 16,
      height: 32,
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#f3f2eb',
    }}>
      <IconButton size="small" onClick={() => deleteProduct({ _id })}>
        <RemoveIcon />
      </IconButton>
      <span css={{ fontWeight: 700 }}>{amount}</span>
      <IconButton size="small" onClick={() => addProduct({ _id, name, price, photoUrl })}>
        <AddIcon />
      </IconButton>
    </div>
  </div>
)