/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { Collapse } from '@material-ui/core'
import { Button, ButtonBorder } from '../../../components/Elements'
import Headline from '../../../components/Headline/Headline'
import WidgetContainer from '../../../components/Widget/Widget.component'
import { sGrid, sDescription } from '../../../style/style'
import { sFormContainer, sGridOverride, sHeadline } from './styles'
import { useActiveOrdersContext } from '../Order/context/orders-active.context'

const OrderCancel = ({
  setIsCancelProductWidgetOpen,
  isCancelProductWidgetOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  // const [isDialog, setIsDialog] = useState(false)
  const onClickOpen = () => setIsCancelProductWidgetOpen(true)
  const onClickClose = () => setIsCancelProductWidgetOpen(false)

  const { handleAnnulFinishedProd } = useActiveOrdersContext()

  const onAnnul = () => {
    setIsLoading(true)
    const cb = () => {
      setIsLoading(false)
      onClickClose()
    }
    handleAnnulFinishedProd(cb)
  }

  return (
    <React.Fragment>
      {/* <ButtonBorder action={onClickOpen}>Anulowane</ButtonBorder> */}
      <WidgetContainer
        isWidgetOpen={isCancelProductWidgetOpen}
        setIsWidgetOpen={setIsCancelProductWidgetOpen}>
        <Collapse in={isCancelProductWidgetOpen}>
          <div>
            <Headline extraCss={sHeadline}>Anulowanie</Headline>
            <div css={[sGrid, sGridOverride]}>
              <p css={[sDescription, { fontSize: '0.875rem' }]}>
                Czy na pewno chcesz anulować zaznaczone produkty? Kwota
                anulowanych produktów zostanie odliczona od zamówienia i
                zostanie zwrócona na konto klienta.
              </p>
            </div>
            <div css={[sFormContainer]}>
              <Button
                loadingSpinner
                w100
                w100Desktop
                dimmed
                action={onAnnul}
                loading={isLoading}>
                Tak, Anuluj Produkty
              </Button>
              <Button
                loadingSpinner
                w100
                w100Desktop
                action={onClickClose}
                loading={isLoading}>
                Nie, Zamknij
              </Button>
            </div>
          </div>
        </Collapse>
      </WidgetContainer>
    </React.Fragment>
  )
}

export default OrderCancel
