/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import Button from '@material-ui/core/Button'
import OrderFinished from '../views/OrderFinished'


const OrderListLoadMore = ({ orders, onLoadMore, isLoading, noMoreToFetch }) => (
  <React.Fragment>
    {orders?.map(order =>
      <OrderFinished key={order._id} {...order} />)}

    {!noMoreToFetch && (
      <Button
        onClick={() => onLoadMore(orders[orders.length - 1]._id)}
        disabled={isLoading}
        variant="outlined"
        color="primary"
        fullWidth
        css={{ marginTop: 16 }}
      >
        Załaduj więcej
      </Button>
    )}
  </React.Fragment>
)

export default OrderListLoadMore