/** @jsx jsx */
import React, { useState, useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { s, colors, globals, alpha } from '../../../../style'
import { Hidden, ButtonBase } from '@material-ui/core'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Link as LinkScroll } from 'react-scroll'
import { useTopNavContext } from '../../../../context/topnav.context'

const RenderItems = ({ items }) =>
  items?.map((item, id) => (
    <div
      key={id}
      css={[
        {
          scrollSnapAlign: 'start',
          '.navrestmenu-activeClass': {
            color: colors.green,
            backgroundColor: colors.green400.concat(alpha[8]),
          },
          '.navrestmenu-activeClass:hover': {
            color: colors.green,
            backgroundColor: colors.green400.concat(alpha[8]),
            filter: 'brightness(1.1)',
          },
        },
        id === 0 && { paddingLeft: '1.5rem' },
        id === items?.length - 1 && { paddingRight: '1.5rem' },
      ]}>
      <LinkScroll
        containerId='scroller'
        to={encodeURIComponent(item)}
        spy={true}
        smooth={true}
        duration={500}
        offset={-96}
        activeClass='navrestmenu-activeClass'
        css={sItem}>
        <ButtonBase
          css={{
            color: 'inherit',
            width: '100%',
            height: '100%',
            display: 'flex',
            fontWeight: 500,
            padding: '6px 10px 4px',
            [s.md]: { padding: '8px 12px 8px', fontSize: '0.8125rem' },
            borderRadius: 'inherit',
            fontSize: '0.75rem',
            letterSpacing: '-0.0125em',
          }}>
          {item}
        </ButtonBase>
      </LinkScroll>
    </div>
  ))

const NavRestMenu = ({ items }) => {
  const { scrolled: isScrolled } = useTopNavContext()

  return (
    <>
      <div
        css={[
          sContainer,
          !isScrolled && {
            opacity: 0,
            transform: 'translatey(-100px)',
            transition: 'opacity 0s ease-out 0s, transform 0s ease-out 0s',
          },
        ]}>
        <Hidden xsDown>
          <ScrollContainer css={sInner}>
            <RenderItems items={items} />
          </ScrollContainer>
        </Hidden>
        <Hidden smUp>
          <div css={[sInner, { scrollSnapType: 'x mandatory' }]}>
            <RenderItems items={items} />
          </div>
        </Hidden>
      </div>
    </>
  )
}

const sContainer = {
  backgroundColor: '#ffffff',
  width: '100vw',
  position: 'fixed',
  height: 64,
  top: globals.topnavHeight.desktop - 12,
  [s.sm_down]: { top: globals.topnavHeight.mobile - 8, height: 48 },
  left: 0,
  boxShadow: `0px 4px 24px ${colors.text.concat(alpha[8])}`,

  zIndex: 999,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'opacity 0.3s ease-out 0.3s, transform 0.3s ease-in-out 0.3s',
}

const sInner = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  maxWidth: globals.maxWidthMedium,
  height: '100%',
  width: '100%',
  padding: '0 1.5rem',
  overflowX: 'auto',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  [s.touch]: { scrollSnapType: 'x mandatory' },
}

const sItem = {
  display: 'flex',
  transition:
    'background-color 0.3s ease-out, color 0.3s ease-out, filter 0.3s ease-out',
  // padding: '5px 8px 4px',
  borderRadius: '1rem',
  color: colors.gray600,
  [s.sm_down]: { marginLeft: '0.5rem' },
  letterSpacing: '-0.025em',
  whiteSpace: 'nowrap',
  [s.md]: {
    fontSize: 13,
    marginLeft: '0.5rem',
    // padding: '8px 12px 8px'
  },
  '&:hover': {
    [s.hover]: {
      backgroundColor: colors.gray100,
      color: colors.text,
    },
  },
}

export default NavRestMenu
