import React, { createContext, useContext, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import useOrdersHandle from '../hooks/useOrdersHandle'
import {
  wasOrderJustPaid,
  isOrderNew,
  hasOrderNewProducts,
  wasOrderAnnuledByClient,
} from '../helpers/order-checks'
// import { ezwRoutePrefix } from '../pages/settings'

// Context settings
function noop() {}

export const OrdersContext = createContext({
  data: {},
  loading: false,
  error: false,
  isAttentionDisable: true,

  addStyles: noop,
  onClickAttention: noop,
  onCloseAttention: noop,
  showMenu: false,
  menuToggleDrawer: noop,

  attentionOrders: [],
  setAttentionOrders: noop,
  activeOrders: [],
  setActiveOrders: noop,
})

export const useOrdersContext = () => useContext(OrdersContext)

// Context Provider
const ActiveOrdersProvider = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false)
  const [attentionOrdersAmount, setAttentionOrdersAmount] = useState(0)
  const [attentionOrders, setAttentionOrders] = useState([])
  const [activeOrders, setActiveOrders] = useState([])
  const { pathname } = useLocation()

  const {
    data,
    loading,
    error,
    isAttentionDisable,
    setIsAttentionDisable,

    addStyles,
    onClickAttention,
    onCloseAttention,
  } = useOrdersHandle()

  useEffect(() => {
    if (data?.ezrOrdersActive) {
      segregateOrders({
        setAttentionOrders,
        setActiveOrders,
        activeOrders,
        orders: data.ezrOrdersActive,
      })
    }
  }, [data])

  useEffect(() => {
    if (
      !!attentionOrders.length &&
      attentionOrders.length > attentionOrdersAmount
    ) {
      if (pathname !== `/waiter/notifications`) setIsAttentionDisable(false)
      else toast.success('Nowe zamówienie!', { autoClose: false })
    }
    setAttentionOrdersAmount(attentionOrders.length)
  }, [attentionOrders])

  const menuToggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setShowMenu(open)
  }

  return (
    <OrdersContext.Provider
      value={{
        data,
        loading,
        error,
        isAttentionDisable,

        addStyles,
        onClickAttention,
        onCloseAttention,
        showMenu,
        menuToggleDrawer,

        attentionOrders,
        setAttentionOrders,
        activeOrders,
        setActiveOrders,
      }}>
      {children}
    </OrdersContext.Provider>
  )
}

function segregateOrders({ setAttentionOrders, setActiveOrders, orders }) {
  const activeOrders = []
  const attentionOrders = []

  for (const order of orders) {
    if (isAttentionOrder(order)) attentionOrders.push(order)
    else activeOrders.push(order)
  }

  function isAttentionOrder(order) {
    return (
      isOrderNew(order) ||
      hasOrderNewProducts(order) ||
      wasOrderJustPaid(order) ||
      wasOrderAnnuledByClient(order)
    )
  }

  setAttentionOrders(attentionOrders)
  setActiveOrders(activeOrders)
}

export default ActiveOrdersProvider
