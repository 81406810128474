import { gql } from '@apollo/client'

// Product
export const ADD_PRODUCT = gql`
  mutation restAddProduct($restId: ID!, $input: NewProdInput!, $categories: [String], $ingredients: [String], $imgS3Path: String) {
    restAddProduct(restId: $restId, input: $input, categories: $categories, ingredients: $ingredients, imgS3Path: $imgS3Path) {
      success
      message
    }
  }
`

export const UPDATE_PRODUCT = gql`
  mutation restUpdateProduct($prodId: ID!, $input: NewProdInput!, $categories: [String], $ingredients: [String], $imgS3Path: String) {
    restUpdateProduct(prodId: $prodId, input: $input, categories: $categories, ingredients: $ingredients, imgS3Path: $imgS3Path) {
      success
      message
    }
  }
`

export const DELETE_PRODUCT = gql`
  mutation restDeleteProduct($prodId: ID!) {
    restDeleteProduct(prodId: $prodId) {
      success
    }
  }
`

// Restaurant
export const UPDATE_REST = gql`
  mutation restUpdate($restId: ID!, $input: RestaurantInput, $inputMenu: [MenuSectionInput], $newCategories: [String], $avatarUrl:String, $backgroundImgUrl: String) {
    restUpdate(restId: $restId, input: $input, inputMenu: $inputMenu, newCategories: $newCategories, avatarUrl: $avatarUrl, backgroundImgUrl: $backgroundImgUrl) {
      success
      message
    }
  }
`

export const CREATE_REST = gql`
  mutation restCreate($input: RestaurantInput, $newCategories: [String], $avatarUrl:String, $backgroundImgUrl: String) {
    restCreate(input: $input, newCategories: $newCategories, avatarUrl: $avatarUrl, backgroundImgUrl: $backgroundImgUrl) {
      success
      _id
      login
      password
    }
  }
`

export const CHANGE_WAITER_PASSWORD = gql`
  mutation restNewPasswordWaiterDashboard($restId: ID!, $password: String!) {
    restNewPasswordWaiterDashboard(restId: $restId, password: $password) {
      success
    }
  }
`

// Other
export const READ_NOTIFICATION = gql`
  mutation restReadNotification($notifId: ID!, $restId: ID!) {
    restReadNotification(notifId: $notifId, restId: $restId) {
      success
    }
  }
`

// CSV
export const GET_TRANSACTIONS_CSV = gql`
  mutation restDownloadCsvTransactions($restId: ID!, $startDate: Date!, $endDate: Date!) {
    restDownloadCsvTransactions(restId: $restId, startDate: $startDate, endDate: $endDate)
  }
`