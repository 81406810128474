/** @jsx jsx */
import React, { useEffect, useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useParams } from 'react-router-dom'

import { useError } from '../../../hooks/errors.hook'
import { useGetProduct } from '../../hooks/useQueries'
import LoadingBar from '../../components/LoadingBar/LoadingBar'
import MainContainer from '../../components/MainContainer/MainContainer'

import ProductProvider, { useProductContext } from './context/product.provider'

import AvailableDays from './views/AvailableDays'
import Categories from './views/Categories'
import CookMethods from './views/CookMethods'
import Description from './views/Description'
import Flavors from './views/Flavors'
import Ingredients from './views/Ingredients'
import Name from './views/Name'
import NumInputs from './views/NumInputs'
import ProductImage from './views/ProductImage'
import SaveButton from './views/SaveButton'
import MenuHandle from './views/MenuHandle'
import ConsumptionTypes from './views/ConsumptionTypes'


/*
 * TODO: Optimize context / state handling.
 * Details: now we update WHOLE page with WHOLE data after EVERY change on input etc.
*/

const ProductPage = () => {
  const prodId = useParams().id
  const { errorHandling } = useError()

  const { data, loading, error } = useGetProduct(prodId)
  const {
    setAvatarUrl,
    setAvailableHours,

    setProductInfo,
    handleCategories,

    handleIngridients,
    handleFlavors,

    handleCookMethod
  } = useProductContext()

  useEffect(() => {
    if (data?.ezcProduct) {
      const product = data.ezcProduct
      setAvatarUrl(product.photoUrl)
      setAvailableHours(product.availableHours)

      const { name, description, price, cookTime, vat, consumptionTypes } = product
      setProductInfo({
        name,
        description,
        vat,
        price,
        cookTime,
        consumptionTypes
      })

      handleCategories(product.categories.map(el => el._id))
      handleIngridients(product.ingredients.map(el => el._id))

      handleFlavors(product.flavors)
      handleCookMethod(product.cookMethod)
    }
  }, [data])

  if (loading) return <LoadingBar />
  if (error) {
    errorHandling(error)
    return null
  }


  return (
    <MainContainer
      header="Edycja produktu"
      helperComponent={<MenuHandle prodId={prodId} isArchived={data?.ezcProduct?.isArchived} />}
    >

      {/* Input fields */}
      <Name />
      <Description />
      <br />
      <ConsumptionTypes />
      <br />

      <AvailableDays />
      <Flavors />

      <CookMethods />
      <Categories />

      <Ingredients />
      <NumInputs />

      <ProductImage />
      <SaveButton prodId={prodId} />

    </MainContainer>
  )
}


export default () => (
  <ProductProvider>
    <ProductPage />
  </ProductProvider>
)