/** @jsx jsx */
import React, { useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Button from '@material-ui/core/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { useError } from '../../../../hooks/errors.hook'
import { useGetRestaurants } from '../../../hooks/useQueries'
import { krakenUrls } from '../../../../constants/s3-files'
import { useActiveRestaurantContext } from '../../../context/active-restaurant.provider'
import { ezrmRoutePrefix } from '../../settings'

const ChooseRestaurant = () => {
  const [expanded, setExpanded] = React.useState(false)
  const handleExpand = (event, isExpanded) => setExpanded(isExpanded)

  const { errorHandling } = useError()
  const { data, loading, error } = useGetRestaurants()
  const { activeRestaurantId, handleActiveRestaurantId } =
    useActiveRestaurantContext()

  useEffect(() => {
    if (data?.ezrRestaurants?.length && !activeRestaurantId) {
      handleActiveRestaurantId(data.ezrRestaurants[0]._id)
    }
  }, [data])

  if (loading) return null
  if (error) {
    errorHandling(error)
    return null
  }

  if (!data?.ezrRestaurants.length) {
    return (
      <Button
        size='large'
        variant='contained'
        component={Link}
        to={ezrmRoutePrefix + '/new-restaurant'}>
        Stwórz swoją pierwszą restaurację
      </Button>
    )
  }

  const activeRestaurant =
    data?.ezrRestaurants.find((rest) => rest._id === activeRestaurantId) || {}

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'flex-start',
      }}>
      <Accordion
        expanded={expanded}
        onChange={handleExpand}
        css={{
          boxShadow: 'none',
          backgroundColor: 'transparent',
          maxWidth: 560,

          '&:before': {
            content: '',
            height: 0,
          },
        }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <RestItem {...activeRestaurant} />
        </AccordionSummary>

        <AccordionDetails>
          <div css={{ width: '100%' }}>
            <h3>Wybierz restaurację</h3>
            {data?.ezrRestaurants.map((restaurant) => (
              <RestItem
                {...restaurant}
                key={restaurant._id}
                handleActiveRestaurantId={(e) => {
                  handleActiveRestaurantId(e)
                  setExpanded(false)
                }}
              />
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
      <div
        css={{
          margin: '16px 0 0 24px',
        }}>
        <Button
          size='small'
          variant='contained'
          component={Link}
          to={ezrmRoutePrefix + '/new-restaurant'}>
          Dodaj nową restaurację
        </Button>
      </div>
    </div>
  )
}

const RestItem = ({
  _id,
  name,
  address,
  avatarUrl,
  handleActiveRestaurantId,
  coins,
  isActive,
  description,
}) => {
  return (
    <div
      onClick={() => handleActiveRestaurantId && handleActiveRestaurantId(_id)}
      css={{
        display: 'flex',
        padding: '12px 8px',
        cursor: 'pointer',
        width: '100%',

        '&:hover': handleActiveRestaurantId
          ? {
              backgroundColor: '#e7e7e7',
            }
          : undefined,
      }}>
      {avatarUrl && (
        <div
          css={{
            // backgroundColor: '#777',
            borderRadius: 8,
            marginRight: 12,
            width: 50,
            height: 50,
            overflow: 'hidden',
          }}>
          <img
            src={krakenUrls.small + avatarUrl}
            alt=''
            css={[{ width: 50, height: 50 }, !isActive && { opacity: 0.5 }]}
          />
        </div>
      )}

      <div>
        <h3 css={!isActive && { opacity: 0.3 }}>{name}</h3>
        <p css={{ opacity: '.6', fontSize: 13 }}>{address}</p>
        {!isActive && (
          <div css={{ color: 'red', fontSize: 12 }}>Nieaktywna</div>
        )}
      </div>
    </div>
  )
}

export default ChooseRestaurant
