/** @jsx jsx */
import React, { useState, useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useGetProducts } from '../../../hooks/useQueries'

import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';


function not(a, b) {
  return a.filter(aa => b.findIndex(bb => bb._id === aa._id) === -1);
}

function intersection(a, b) {
  return a.filter(aa => b.findIndex(bb => bb._id === aa._id) !== -1);
}


export default ({ sectionId, menuSections, right, setRight }) => {
  const { data, error, loading } = useGetProducts()

  const activeSection = menuSections.find(section => section._id === sectionId) || { products: [] }

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);

  useEffect(() => { setRight([...activeSection.products]) }, [activeSection])

  useEffect(() => {
    if (data?.ezrProducts) {
      const sectionProductsIds = right.map(el => el._id)
      setLeft(data.ezrProducts
        .filter(product => sectionProductsIds.indexOf(product._id) === -1))
    }
  }, [data, right])

  if (loading) return <LinearProgress />
  if (error) return null


  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.findIndex(({ _id }) => _id === value._id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items) => (
    <List dense component="div" role="list">
      {items.map(product => (
        <ListItem key={product._id} role="listitem" button onClick={handleToggle(product)}>
          <ListItemIcon>
            <Checkbox
              checked={checked.findIndex(({ _id }) => product._id === _id) !== -1}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={product.name} />
        </ListItem>
      ))}
      <ListItem />
    </List>
  );

  return (
    <Grid container spacing={3} justify="center" wrap="nowrap" >
      <Grid item css={{ width: 'calc(50% - 45px)' }}>
        <h2>Wszystkie produkty</h2>
        {customList(left)}
      </Grid>

      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>

      <Grid item css={{ width: 'calc(50% - 45px)' }}>
        <h2>{activeSection.name}</h2>
        {customList(right)}
      </Grid>
    </Grid>
  );
}