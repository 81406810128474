/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { s } from '../../style'

const Headline = ({ children, itemChildren, extraCss, textWrap }) => {
  return (
    <div css={[sHeadline, extraCss, textWrap && sHeadlineTextWrap]}>
      <h1 css={[sH1, textWrap && sH1TextWrap]}>{children}</h1>
      <div css={textWrap && { marginBottom: '2rem' }}>{itemChildren}</div>
    </div>
  )
}

const sHeadline = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  [s.sm_down]: {
    padding: '2rem 1.5rem 2rem',
  },
  [s.md]: {
    padding: '0rem 2rem 3rem',
  },
}

const sHeadlineTextWrap = {
  alignItems: 'flex-start',
  [s.sm_down]: {
    padding: '2rem 1.5rem 0rem',
  },
  [s.md]: {
    padding: '0rem 2rem 1rem',
  },
}

const sH1 = {
  fontWeight: 800,
  letterSpacing: '-0.05em',
  [s.sm_down]: {
    fontSize: '2rem',
  },
  [s.md]: {
    fontSize: '2.5rem',
  },
}

const sH1TextWrap = {
  marginBottom: '2rem',
}

export default Headline
