/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'

import { Grid, ButtonBase } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import MoneyEz from '../../../../helpers/MoneyEz'
import { krakenUrls } from '../../../../constants/s3-files'

import { s, colors } from '../../../../style'
import { Collapsable, Bullet, Button } from '../../../../components/Elements'
import FastfoodIcon from '@material-ui/icons/Fastfood'

import X from '@material-ui/icons/Clear'

export default ({
  id,
  orderId,
  restaurantId,
  restaurantName,
  restaurantLogoSrc,
  orderedProducts,
  onDelete,
  onlyOne,
}) => {
  let productsAmount = 0
  for (const product of orderedProducts) {
    productsAmount += product.amount
  }

  const sumProductsPrice = MoneyEz()
    .sumProductArray(
      orderedProducts.map(({ amount, prodId: { price } }) => ({
        amount,
        price,
      }))
    )
    .getAmountWithFullPrecision()

  return (
    <Grid item xs={12} md={6}>
      <Collapsable
        gray200
        openAlready={onlyOne}
        noPadding
        oneLiner
        title={restaurantName}
        description={
          <div css={{ fontWeight: 500 }}>
            <span css={{ color: colors.text }}>{sumProductsPrice}</span> zł
            <Bullet />
            <>{productsAmount}</>
            <FastfoodIcon
              fontSize='inherit'
              css={{ marginLeft: '0.25rem', [s.md]: { marginLeft: '0.5rem' } }}
            />
          </div>
        }
        image={krakenUrls.small + restaurantLogoSrc}
        imageLink={`/restaurant/${restaurantId}`}
        sOuterCss={[
          id % 2 === 1 && { [s.md]: { marginLeft: '0.75rem' } },
          id % 2 === 0 && { [s.md]: { marginRight: '0.75rem' } },
          { marginBottom: '1.5rem', [s.sm_down]: { marginBottom: '1rem' } },
        ]}
        // extraCss={{ backgroundColor: colors.gray100 }}
        // sTextCss={{ [s.sm]: { marginLeft: '1.5rem' } }}
      >
        <div css={sItems}>
          {orderedProducts.map(({ amount, prodId }, orderProductId) => (
            <ProductItem
              key={orderProductId}
              id={prodId._id}
              name={prodId.name}
              photoUrl={prodId.photoUrl}
              price={prodId.price}
              amount={amount}
            />
          ))}
        </div>
        {/* <div css={sSumUp}>
          <span>Łącznie</span>
          <span>{sumProductsPrice} zł</span>
        </div> */}
        <div
          css={{
            display: 'flex',
            padding: '1rem',
            [s.md]: {
              padding: '2rem',
            },
          }}>
          <Button size48 dimmed action={onDelete}>
            <DeleteIcon />
          </Button>
          <div
            css={{
              width: '100%',
              paddingLeft: '1rem',
              [s.md]: { paddingLeft: '2rem' },
            }}>
            <Button w100 link={`/cart-checkout/${orderId}`}>
              Dalej
            </Button>
          </div>
        </div>
      </Collapsable>
    </Grid>
  )
}

export const ProductItem = ({ id, photoUrl, name, price, amount }) => (
  <Link to={`/product/${id}`}>
    <ButtonBase css={sItem}>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <div css={[sImage, !photoUrl && { backgroundColor: colors.gray300 }]}>
          {photoUrl && (
            <img src={krakenUrls.small + photoUrl} alt={name} loading='lazy' />
          )}
        </div>
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          <p className='cart-item-title'>{name}</p>
          <p className='cart-item-desc'>
            {amount > 1 && amount}
            {amount > 1 && (
              <X
                fontSize='inherit'
                css={{
                  margin: '0 0.25rem',
                  opacity: 1,
                }}
              />
            )}
            {price.toFixed(2)}
            <span css={{ opacity: 1 }}>&nbsp;zł</span>
          </p>
        </div>
      </div>
      <div className='cart-item-price'>
        <>{(amount * price).toFixed(2)}</>&nbsp;
        <span css={{ opacity: 0.3 }}>zł</span>
      </div>
    </ButtonBase>
  </Link>
)

const sItems = {
  // padding: '1.25rem 0',
  [s.sm_down]: { padding: 0 },
}

const sItem = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1rem',
  transition: 'background-color 0.3s ease-out',
  [s.md]: { padding: '1.25rem 2rem' },

  '&:hover': {
    [s.hover]: {
      backgroundColor: colors.gray300,
    },
  },

  '.cart-item-title': {
    fontWeight: 800,
    fontSize: '0.75rem',
    letterSpacing: '-0.025em',
    marginBottom: '0.25rem',
    marginRight: '1rem',
    [s.md]: {
      fontSize: '0.875rem',
    },
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textAlign: 'left',
  },
  '.cart-item-desc': {
    opacity: 0.4,
    color: colors.text,
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.75rem',
    fontWeight: 500,
    [s.md]: {
      opacity: 0.6,
    },
  },
  '.cart-item-price': {
    fontWeight: 800,
    fontSize: '0.75rem',
    whiteSpace: 'nowrap',
  },
}

const sImage = {
  width: 40,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  flexShrink: 0,
  marginRight: '0.75rem',
  [s.md]: {
    width: 48,
    height: 48,
    marginRight: '1rem',
  },
  '&:hover': {
    img: {
      transform: 'scale(0.9)',
      filter: 'brightness(1.1)',
    },
  },

  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
    borderRadius: '50%',
    transition: 'transform 0.3s ease-out, filter 0.3s ease-out',
  },
}

const sSumUp = {
  borderTop: `1px solid ${colors.gray400}`,
  borderBottom: `1px solid ${colors.gray400}`,
  padding: '1rem',
  [s.sm]: {
    padding: '2rem 2rem',
  },
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: colors.gray300,
  fontWeight: 800,
  fontSize: '0.875rem',
}
