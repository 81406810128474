import { gql } from '@apollo/client'

export const GET_USER_BY_EMAIL = gql`
  query superAdminGetUserByEmail($email: String!) {
    superAdminGetUserByEmail(email: $email) {
      _id
      email
      isAdmin
    }
  }
`

export const CHANGE_ADMIN_PERMISSION = gql`
  mutation superAdminChangeAdminPermission($userId: ID!) {
    superAdminChangeAdminPermission(userId: $userId) {
      success
    }
  }
`