/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Grid, Collapse } from '@material-ui/core'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import { Collapsable, Row } from '../../../../components/Elements'
import FastfoodIcon from '@material-ui/icons/Fastfood'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import { colors } from '../../../../style'

const OrderPayment = ({
  deliveryCost,
  totalCost,
  productsCost,
  isDelivery,
}) => {
  return (
    <Grid item xs={12} md={6}>
      <Collapsable
        left
        noPadding
        oneLiner
        title={'Płatność'}
        icon={<MonetizationOnIcon />}
        description={
          <>
            <span css={{ color: colors.text }}>{totalCost}</span>&nbsp;zł
          </>
        }>
        <Row desc={'Produkty'} icon={<FastfoodIcon fontSize='inherit' />}>
          {productsCost} zł
        </Row>
        <Collapse in={isDelivery}>
          <Row desc={'Dostawa'} icon={<LocalShippingIcon fontSize='inherit' />}>
            {deliveryCost} zł
          </Row>
        </Collapse>
        <Row
          desc={'Łącznie'}
          icon={<AttachMoneyIcon fontSize='inherit' />}
          focus>
          {totalCost} zł
        </Row>
      </Collapsable>
    </Grid>
  )
}

export default OrderPayment
