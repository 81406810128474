import { useMutation } from '@apollo/client'
import { useError } from '../../hooks/errors.hook'
import { GET_TRANSACTIONS_CSV } from '../graphql/mutations.graphql'


export default () => {
  const restId = localStorage.getItem('activeRestaurant')
  const [restDownloadCsvTransactions] = useMutation(GET_TRANSACTIONS_CSV)
  const { errorHandling } = useError()

  return {
    async getTransactionsCsv(data, cb, errCb) {
      try {
        const { startDate, endDate } = data

        const response = await restDownloadCsvTransactions({
          variables: {
            restId,
            startDate,
            endDate
          }
        })

        openInNewTab(
          process.env[
            'REACT_APP_SERVER_URI_'
              .concat(process.env.REACT_APP_NODE_ENV)
          ].slice(0, -8)
          + response.data.restDownloadCsvTransactions
        )

        if (cb) cb()
      } catch (err) {
        errorHandling(err)
        if (errCb) errCb()
      }
    }


  }
}


function openInNewTab(url) {
  var win = window.open(url, '_blank');
  win.focus();
}
