/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import {
  sOuter,
  sNoMarginTop,
  sGrid,
  sPaddingTop,
  sBottomLinks,
} from '../../../style/style'
import { s } from '../../../style'
import { Grid, Collapse } from '@material-ui/core'
import OrderProduct, { sCheck } from './OrderProduct'
import {
  SectionHeadline,
  Button,
  ButtonBorder,
} from '../../../components/Elements'
import { useOrderAttentionContext } from './context/order-attention.context'
import useOrderMutations from '../../hooks/useOrders.mutations'
import {
  isOrderNew as isOrderNewFn,
  hasOrderNewProducts as hasOrderNewProductsFn,
  wasOrderAnnuledByClient as wasOrderAnnuledByClientFn,
} from '../../helpers/order-checks'
import { useActiveOrdersContext } from './context/orders-active.context'
import { PRODUCT_STATUS_COLOR } from '../../../constants/statuses'
import { useHistory } from 'react-router-dom'

const OrderProducts = ({
  allProductsSorted,
  setIsCancelProductWidgetOpen,
  isCanceled,
}) => {
  const {
    orderWeWorkOn,
    productsToAccept,
    alreadyOrderedProducts,
    handleToggleProduct,
    handleAccept,
    handleDecline,
    handleAcceptProducts,
    handleDeclineAllProducts,
  } = useOrderAttentionContext()
  const {
    isLoading: isLoadingOrderActions,
    finishedProducts,
    handleActiveProdStatus,
    onToggleProduct,
    handleAnnulFinishedProd,
    setFinishedProducts,
    orderDetails,
  } = useActiveOrdersContext()

  const { handleUpdateOrderStatus } = useOrderMutations()
  const [showAllProducts, setShowAllProducts] = useState(false)

  const isOrderNew = isOrderNewFn(orderWeWorkOn)
  const hasOrderNewProducts = hasOrderNewProductsFn(orderWeWorkOn)
  const wasOrderAnnuledByClient = wasOrderAnnuledByClientFn(orderWeWorkOn)
  const { status } = orderWeWorkOn
  const isNewOrder = status[status.length - 1].name === 'WAITING_FOR_ACCEPT'
  const history = useHistory()

  const { justPaidProducts, acceptedProducts } = segregateProducts(
    alreadyOrderedProducts
  )
  const onSubmitPaidOrder = () =>
    handleUpdateOrderStatus({
      orderId: orderWeWorkOn._id,
      status: 'CONFIRMED',
      // productStatuses: justPaidProducts
      //   .map((e) => ({
      //     _id: e._id,
      //     prodId: e.prodId._id,
      //     amount: e.amount,
      //     isStatusChange: true,
      //   }))
      //   .concat(
      //     confirmedProducts?.map((e) => ({
      //       _id: e._id,
      //       prodId: e.prodId._id,
      //       amount: e.amount,
      //       isStatusChange: false,
      //     }))
      //   ),
    })

  const finishedBad = orderDetails[0]?.productsFinishedBad || []
  const declined = orderDetails[0]?.productsDeclined || []
  const doneProducts = [...finishedBad, ...declined]
  const confirmedProducts = finishedProducts.filter(
    (product) => product.status[product.status.length - 1].name !== 'ACCEPTED'
  )

  const needPaymentProducts = allProductsSorted.filter(
    (product) =>
      product.status[product.status.length - 1].name === 'WAITING_FOR_PAYMENT'
  )

  const allCheck = finishedProducts.reduce(
    (sum, curr) => (curr.isStatusChange && sum ? true : false),
    true
  )

  const handleDeclineWithRedirect = () => {
    handleDecline()
    history.push('/waiter/history')
  }

  // console.log(justPaidProducts)

  // console.log(allProductsSorted)
  // console.log(needPaymentProducts)

  return (
    <React.Fragment>
      {/* Nowe i domówione produkty */}
      {productsToAccept?.length > 0 && (
        <div css={[sOuter, sNoMarginTop, sGridSpacingParent]}>
          <Grid
            container
            css={[sGrid, sGridSpacing]}
            spacing={3}
            justify='space-between'>
            <SectionHeadline sOuter={sSectionHeadline}>
              {isNewOrder ? 'Nowe Produkty' : 'Domówione Produkty'}
            </SectionHeadline>
            {productsToAccept?.map((product, productIndex) => (
              <OrderProduct
                key={productIndex}
                data={product}
                action={() => handleToggleProduct(product._id)}
                editable
              />
            ))}
          </Grid>
          {!isCanceled && (
            <Grid
              container
              spacing={3}
              css={[sGrid, sPaddingTop, sButtonsContainer]}>
              <Button
                w100Mobile
                action={isNewOrder ? handleAccept : handleAcceptProducts}>
                Akceptuj zaznaczone
              </Button>
              <Button
                dimmed
                w100Mobile
                action={
                  isNewOrder
                    ? handleDeclineWithRedirect
                    : handleDeclineAllProducts
                }>
                {isNewOrder ? 'Odrzuć zamówienie' : 'Odrzuć wszystkie'}
              </Button>
            </Grid>
          )}
        </div>
      )}

      {/* Nowe i domówione produkty */}
      {justPaidProducts?.length > 0 && (
        <div css={[sOuter, sNoMarginTop, sGridSpacingParent]}>
          <Grid
            container
            css={[sGrid, sGridSpacing]}
            spacing={3}
            justify='space-between'>
            <SectionHeadline sOuter={sSectionHeadline}>
              Niepotwierdzone Produkty
            </SectionHeadline>
            {justPaidProducts?.map((product, productIndex) => (
              <OrderProduct key={productIndex} data={product} />
            ))}
          </Grid>
          {!isCanceled && (
            <Grid
              container
              spacing={3}
              css={[sGrid, sPaddingTop, sButtonsContainer]}>
              <Button w100Mobile action={onSubmitPaidOrder}>
                Potwierdź Produkty
              </Button>
            </Grid>
          )}
        </div>
      )}
      {/* ZAAKCEPTOWANE PRODUKTY */}
      {acceptedProducts?.length > 0 &&
        !confirmedProducts?.length &&
        !needPaymentProducts?.length && (
          <div css={[sOuter, sNoMarginTop, sGridSpacingParent]}>
            <Grid
              container
              css={[sGrid, sGridSpacing]}
              spacing={3}
              justify='space-between'>
              <SectionHeadline sOuter={sSectionHeadline}>
                Zaakceptowane Produkty
              </SectionHeadline>
              {acceptedProducts?.map((product, productIndex) => (
                <OrderProduct key={productIndex} data={product} />
              ))}
            </Grid>
          </div>
        )}
      {/* WYMAGAJĄCE OPŁATY PRODUKTY */}
      {needPaymentProducts?.length > 0 && (
        <div css={[sOuter, sNoMarginTop, sGridSpacingParent]}>
          <Grid
            container
            css={[sGrid, sGridSpacing]}
            spacing={3}
            justify='space-between'>
            <SectionHeadline sOuter={sSectionHeadline}>
              Nieopłacone Produkty
            </SectionHeadline>
            {needPaymentProducts?.map((product, productIndex) => (
              <OrderProduct key={productIndex} data={product} />
            ))}
          </Grid>
        </div>
      )}
      {/* Potwierdzone produkty */}
      {confirmedProducts?.length > 0 && (
        <div css={[sOuter, sNoMarginTop, sGridSpacingParent]}>
          <Grid
            container
            css={[sGrid, sGridSpacing]}
            spacing={3}
            justify='space-between'>
            <SectionHeadline sOuter={sSectionHeadline}>
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                Potwierdzone Produkty
                {!isCanceled && (
                  <div
                    css={sCheck(allCheck)}
                    onClick={() => {
                      setFinishedProducts(
                        finishedProducts.map((prod) => ({
                          ...prod,
                          isStatusChange: allCheck ? false : true,
                        }))
                      )
                    }}>
                    <span />
                  </div>
                )}
              </div>
            </SectionHeadline>
            {confirmedProducts?.map((product, productIndex) => (
              <OrderProduct
                key={productIndex}
                data={product}
                action={() => onToggleProduct(product._id)}
                editable={!isCanceled}
              />
            ))}
            {!isCanceled && (
              <Grid
                item
                xs={12}
                css={[sBottomLinks, sPaddingTop, sButtonBorderContainer]}>
                {productStatusActions.map((action) => (
                  <ButtonBorder
                    action={() => handleActiveProdStatus(action.status)}
                    disable={isLoadingOrderActions}
                    loading={isLoadingOrderActions}
                    color={PRODUCT_STATUS_COLOR[action.status]}>
                    {action.name}
                  </ButtonBorder>
                ))}
                <ButtonBorder
                  action={() => setIsCancelProductWidgetOpen(true)}
                  disable={isLoadingOrderActions}
                  loading={isLoadingOrderActions}>
                  Anulowane
                </ButtonBorder>
              </Grid>
            )}
          </Grid>
        </div>
      )}

      {/* ANULOWANE PRODUKTY */}
      {doneProducts?.length > 0 && (
        <div css={[sOuter, sNoMarginTop, sGridSpacingParent]}>
          <Grid
            container
            css={[sGrid, sGridSpacing]}
            spacing={3}
            justify='space-between'>
            <SectionHeadline sOuter={sSectionHeadline}>
              Anulowane Produkty
            </SectionHeadline>
            {doneProducts?.map((product, productIndex) => (
              <OrderProduct key={productIndex} data={product} />
            ))}
          </Grid>
        </div>
      )}

      {confirmedProducts?.length > 0 && (
        <div css={[sOuter, sNoMarginTop, sGridSpacingParent]}>
          <Grid
            container
            css={[sGrid, sGridSpacing]}
            spacing={3}
            justify='space-between'>
            <SectionHeadline sOuter={sSectionHeadline}>
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <ButtonBorder
                  action={() => setShowAllProducts(!showAllProducts)}>
                  {showAllProducts
                    ? 'Schowaj Wszystkie Produkty'
                    : 'Wyświetl Wszystkie Produkty'}
                </ButtonBorder>
                <div />
              </div>
            </SectionHeadline>
          </Grid>
          <Collapse in={showAllProducts}>
            <Grid
              container
              css={[sGrid, sGridSpacing]}
              spacing={3}
              justify='space-between'>
              {allProductsSorted?.map((product, productIndex) => (
                <OrderProduct key={productIndex} data={product} />
              ))}
            </Grid>
          </Collapse>
        </div>
      )}
    </React.Fragment>
  )
}

const notEditableStatuses = [
  'WAITING_FOR_PAYMENT',
  'AUTO_ANNULED_BECAUSE_OF_NO_PAYMENT',
  'ANNULED',
  'FINISHED',
  'DECLINED_BY_CLIENT',
  'DECLINED_BY_REST',
]

const productStatusActions = [
  // { name: 'Zaakceptowany', status: 'ACCEPTED' },
  // { name: 'Potwierdzony', status: 'CONFIRMED' },
  { name: 'Realizowany', status: 'PREPARING' },
  { name: 'Gotowy', status: 'DONE' },
  { name: 'Dostarczony', status: 'FINISHED' },
  // { name: 'Anulowany', status: 'ANNUL_BY_REST' },
]

export function segregateProducts(products) {
  const _s = (p) => [...p.status].pop().name
  return {
    justPaidProducts: products.filter((p) => _s(p) === 'ACCEPTED'),
    acceptedProducts: products.filter((p) => _s(p) !== 'ACCEPTED'),
  }
}

const sButtonBorderContainer = {
  [s.sm_down]: { paddingTop: '1.5rem !important' },
  [s.sm]: { marginRight: '3rem' },
  [s.md]: { paddingTop: '2rem', justifyContent: 'flex-end' },
}

const sSectionHeadline = {
  // [s.sm_down]: { paddingLeft: '2rem !important' },
  // [s.xs]: { paddingLeft: '2rem !important' },
  // [s.md]: { paddingLeft: '2rem !important' },
}

const sButtonsContainer = {
  gap: '2rem',
  marginTop: '2rem',
  marginBottom: '3.5rem',
  [s.sm_down]: {
    flexDirection: 'column',
    gap: '1rem',
    marginBottom: '0.5rem',
  },
  [s.sm]: {
    padding: '0 1.5rem',
  },
  [s.md]: {
    padding: '0 5.5rem',
  },
}

const sGridSpacingParent = {
  [s.xs]: {
    '.MuiGrid-spacing-xs-3': {
      width: '100%',
      margin: 0,

      '& > .MuiGrid-item': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
}

const sGridSpacing = {
  '.MuiGrid-item': {
    [s.xs]: { paddingBottom: '4px !important' },
  },
}

export default OrderProducts
