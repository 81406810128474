/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import LinearProgress from '@material-ui/core/LinearProgress';

import { useGetPaymentsDetails } from '../../hooks/useQueries'
import { useError } from '../../../hooks/errors.hook'

import MainContainer from '../../components/MainContainer/MainContainer'
import TransactionsTable from './views/TransactionsTable'
import GeneralInfo from './views/GeneralInfo'
// import BankAccountNumber from './views/BankAccountNumber'



export default function EnhancedTable() {
  const { errorHandling } = useError()
  const { data, loading, error } = useGetPaymentsDetails()

  if (loading) return <LinearProgress />
  if (error) {
    errorHandling(error)
    return null
  }

  return (
    <MainContainer header="Transakcje">
      <div css={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start',

        "&>div": {
          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
          borderRadius: 4,
          margin: 12,
          padding: 16
        }
      }}>
        <div css={{ width: 'calc(66% - 24px)' }}>
          <TransactionsTable transactions={data?.ezrTransactions} />
        </div>

        <div css={{ width: 'calc(33% - 24px)' }}>
          <GeneralInfo coinsAmount={data?.ezrRestaurant?.coins} />
        </div>
      </div>
    </MainContainer>
  );
}