import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useError } from '../../../../hooks/errors.hook'

const useOrdersPagination = ({ dataOrders, fetchMore, startDate, endDate }) => {
  const { errorHandling } = useError()
  const [noMoreToFetch, setNoMoreToFetch] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [orders, setOrders] = useState([])

  useEffect(() => {
    setOrders(dataOrders)
  }, [dataOrders])

  const onLoadMore = (lastId) => {
    setIsLoading(true)
    fetchMore({
      variables: {
        paginationInput: {
          lastId,
          limit: 20,
          startDate,
          endDate,
        },
      },
    })
      .then((res) => {
        setIsLoading(false)
        const ords = res?.data?.ezrOrdersFinished || []
        if (ords.length) {
          setOrders([...orders, ...ords])
        } else {
          setNoMoreToFetch(true)
          toast.info('Wszystkie zamówienia zostały załadowane', {
            autoClose: false,
          })
        }
      })
      .catch((err) => {
        setIsLoading(false)
        errorHandling(err)
      })
  }

  return {
    orders,
    onLoadMore,
    isLoading,
    noMoreToFetch,
  }
}

export default useOrdersPagination
