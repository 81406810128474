/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types'
import {
  Button,
  InputText
} from '../'


const FindRestaurant = ({ getRestaurant, loading }) => {
  const [query, setQuery] = useState('')
  const handleQuery = e => setQuery(e.target.value)

  const onSubmit = () => query && getRestaurant({ variables: { query } })
  const onEnterSubmit = e => e.key === "Enter" && onSubmit()

  return (
    <div css={{
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: 32
    }}>
      <InputText
        label="Podaj nazwę restauracji"
        value={query}
        onChange={handleQuery}
        onKeyUp={onEnterSubmit}
      />
      <Button
        css={{ marginLeft: 8 }}
        onClick={onSubmit}
        disabled={loading}
      >
        Szukaj
      </Button>
    </div>
  )
}

FindRestaurant.propTypes = {
  getRestaurant: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

export default FindRestaurant