/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify'
import validateEmail from '../../../helpers/validateEmail'
import Spinner from '../../../components/Spinner'
import useUserMutations from '../../hooks/useUser.mutations'
import { useApolloClient } from '@apollo/client'
import { Input } from './components'
import Headline from '../../../components/Headline/Headline'
import { Grid } from '@material-ui/core'
import {
  sGrid,
  sOuter,
  sDescription,
  sContainerOuter,
  sPaddingTop,
} from '../../../style/style'
import { ButtonBorder, Button } from '../../../components/Elements'
import { s } from '../../../style'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'

const initValues = {
  email: '',
  password: '',
  repeatPassword: '',
  name: '',
  surname: '',
}

export default function ({ isWidget }) {
  const [isLoading, setIsLoading] = useState(false)
  const [inputValues, setInputValues] = useState(initValues)
  const { handleSignup } = useUserMutations()
  const client = useApolloClient()

  const onChange = (e) =>
    setInputValues({ ...inputValues, [e.target.name]: e.target.value })

  const onSubmit = (e) => {
    try {
      inputCheck(inputValues)
      setIsLoading(true)
      handleSignup(inputValues, ({ userCreate }) => {
        if (userCreate) {
          console.log('Apollo Client Reset Signup!')
          client.cache.reset()
          client.clearStore()
          client.resetStore()
        }
        return setIsLoading(false)
      })
    } catch (err) {
      err.errors.forEach((el) => toast.warn(el))
    }
  }

  // const _onSubmitWidget = () => {
  //   try {
  //     inputCheck(inputValues)
  //     setIsLoading(true)
  //     onSubmitWidget(inputValues, () => setIsLoading(false))
  //   } catch (err) {
  //     err.errors.forEach((el) => toast.warn(el))
  //   }
  // }

  return (
    <React.Fragment>
      <TopNav title={'Rejestracja'} />
      <div css={sOuter}>
        <Headline
          itemChildren={<ButtonBorder link={`/login`}>Zaloguj</ButtonBorder>}>
          Rejestracja
        </Headline>
        <Grid container css={sGrid}>
          <p css={sDescription}>
            Eatzon to aplikacja do zamawiana dań oraz rezerwacji w
            restauracjach. Zarejestruj się i doświadczaj nowych doznań
            kulinarnych!
          </p>
        </Grid>
      </div>

      <Grid container css={[sContainerOuter, sGrid, sPaddingTop]}>
        <div css={[sFormContainer]}>
          <Input
            onChange={onChange}
            name='name'
            value={inputValues.name}
            placeholder='Imię'
          />

          <Input
            onChange={onChange}
            name='surname'
            value={inputValues.surname}
            placeholder='Nazwisko'
          />

          <Input
            onChange={onChange}
            name='email'
            type='email'
            value={inputValues.email}
            placeholder='Email'
          />

          <Input
            onChange={onChange}
            name='password'
            value={inputValues.password}
            type='password'
            placeholder='Hasło'
          />

          <Input
            onChange={onChange}
            name='repeatPassword'
            value={inputValues.repeatPassword}
            type='password'
            placeholder='Powtórz Hasło'
          />

          <Button w100 action={isLoading ? () => {} : onSubmit}>
            {isLoading ? (
              <Spinner size={1.5} addCss={{ color: '#fff' }} />
            ) : (
              'Zarejestruj'
            )}
          </Button>

          <p css={[sAgreement]}>
            Rejestrując się, akceptujesz
            <a
              href='https://eatzon.pl/warunki-uslugi'
              rel='noreferrer noopener'
              target='_blank'>
              <strong> Warunki Usługi</strong>
            </a>{' '}
            oraz{' '}
            <strong>
              <a
                href='https://eatzon.pl/polityka-prywatnosci'
                rel='noreferrer noopener'
                target='_blank'>
                Politykę Prywatności
              </a>
            </strong>
          </p>
        </div>
      </Grid>
    </React.Fragment>
  )
}

function inputCheck(inputValues) {
  const errors = []
  if (inputValues.password.length < 8) {
    errors.push('Hasło powinno zawierać min. 8 symboli')
  }

  if (inputValues.password !== inputValues.repeatPassword) {
    errors.push('Hasła nie są zgodne')
  }

  if (!inputValues.name) {
    errors.push('Imię jest wymagane')
  }

  if (!inputValues.surname) {
    errors.push('Nazwisko jest wymagane')
  }

  if (!validateEmail(inputValues.email)) {
    errors.push('Email jest nieprawidłowy')
  }

  if (errors.length) {
    const err = new Error()
    err.errors = errors
    throw err
  }
}

const sFormContainer = {
  paddingBottom: '1.5rem',
}

const sAgreement = {
  textAlign: 'center',
  lineHeight: '1.618em',
  maxWidth: '75%',
  margin: 'auto',
  fontSize: '0.75rem',
  paddingTop: '1.5rem',
  [s.md]: { paddingTop: '2rem' },
}
