import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { useError } from '../../hooks/errors.hook'

import {
  FINISH_ORDER,
  UPDATE_PROD_STATUS,
  UPDATE_ORDER_STATUS,
  ANNUL_DONE_PROD,
  ADD_PRODUCTS_TO_ORDER,
  DANGER_ANNUL_ORDER,
  CONFIRM_ANNUL_ORDER,
  CHANGE_WAITER_NOTE,
} from '../graphql/mutations.graphql'

import { GET_ACTIVE_ORDERS } from '../graphql/orders.graphql'
import { useWaiterAuthContext } from '../context/auth-waiter.context'

const useOrderMutations = () => {
  const { errorHandling } = useError()
  const { restaurantId } = useWaiterAuthContext()
  const restId = restaurantId

  const [orderFinishTheOrder] = useMutation(FINISH_ORDER, {
    refetchQueries: [{ query: GET_ACTIVE_ORDERS, variables: { restId } }],
  })
  const [orderUpdateStatus] = useMutation(UPDATE_ORDER_STATUS, {
    refetchQueries: [{ query: GET_ACTIVE_ORDERS, variables: { restId } }],
  })
  const [orderUpdateProdStatus] = useMutation(UPDATE_PROD_STATUS, {
    refetchQueries: [{ query: GET_ACTIVE_ORDERS, variables: { restId } }],
  })
  const [orderAnnulDoneProd] = useMutation(ANNUL_DONE_PROD, {
    refetchQueries: [{ query: GET_ACTIVE_ORDERS, variables: { restId } }],
  })
  const [orderRestAddProducts] = useMutation(ADD_PRODUCTS_TO_ORDER, {
    refetchQueries: [{ query: GET_ACTIVE_ORDERS, variables: { restId } }],
  })
  const [orderDangerAnnulOrder] = useMutation(DANGER_ANNUL_ORDER, {
    refetchQueries: [{ query: GET_ACTIVE_ORDERS, variables: { restId } }],
  })
  const [orderConfirmAnnulByClient] = useMutation(CONFIRM_ANNUL_ORDER, {
    refetchQueries: [{ query: GET_ACTIVE_ORDERS, variables: { restId } }],
  })
  const [orderAddWaiterNote] = useMutation(CHANGE_WAITER_NOTE, {
    refetchQueries: [{ query: GET_ACTIVE_ORDERS, variables: { restId } }],
  })

  function handleFinishOrder(data, cb) {
    const { orderId } = data
    orderFinishTheOrder({
      variables: { orderId },
    })
      .then(() => {
        if (typeof cb === 'function') cb()
      })
      .catch((err) => {
        if (typeof cb === 'function') cb()
        errorHandling(err)
      })
  }

  function handleUpdateOrderStatus(data, cb) {
    const { orderId, status, productStatuses } = data
    // console.log(data)
    // console.log(productStatuses)
    orderUpdateStatus({
      variables: { orderId, status, productStatuses },
    })
      .then(() => {
        if (typeof cb === 'function') cb()
      })
      .catch((err) => {
        errorHandling(err)
      })
  }

  function handleUpdateProdStatus(data, cb) {
    const { orderId, prodStatuses } = data
    orderUpdateProdStatus({
      variables: { orderId, prodStatuses },
    })
      .then(() => {
        if (typeof cb === 'function') cb()
      })
      .catch((err) => {
        errorHandling(err)
        if (typeof cb === 'function') cb()
      })
  }

  async function handleAnnulDoneProd(data, cb, errCb) {
    try {
      const { orderId, idToAnnul } = data

      await orderAnnulDoneProd({ variables: { orderId, idToAnnul } })

      if (typeof cb === 'function') cb()
    } catch (err) {
      errorHandling(err)
      if (typeof errCb === 'function') errCb()
    }
  }

  async function handleAddProducts(data, cb, errCb) {
    try {
      const { orderId, productsArray } = data

      await orderRestAddProducts({ variables: { orderId, productsArray } })

      if (typeof cb === 'function') cb()
    } catch (err) {
      errorHandling(err)
      if (typeof errCb === 'function') errCb()
    }
  }

  async function handleDangerAnnulOrder(data, cb, errCb) {
    try {
      const { orderId, reason } = data
      if (!reason) throw new Error('Podaj przyczynę anulowania zamówienia')

      await orderDangerAnnulOrder({ variables: { orderId, reason } })
      if (typeof cb === 'function') cb()
      toast.info('Zamówienie zostało anulowane', { autoClose: false })
    } catch (err) {
      errorHandling(err)
      if (typeof errCb === 'function') errCb()
    }
  }

  async function handleConfirmAnnulOrder(data, cb, errCb) {
    try {
      const { orderId } = data
      await orderConfirmAnnulByClient({ variables: { orderId } })
      if (typeof cb === 'function') cb()
    } catch (err) {
      errorHandling(err)
      if (typeof errCb === 'function') errCb()
    }
  }

  /**
   *
   * @param {Object} data orderId: ID & note: String
   */
  async function handleChangeWaiterNote(data) {
    try {
      await orderAddWaiterNote({ variables: data })
    } catch (err) {
      errorHandling(err)
    }
  }

  return {
    handleFinishOrder,
    handleUpdateProdStatus,
    handleUpdateOrderStatus,
    handleAnnulDoneProd,

    handleAddProducts,
    handleDangerAnnulOrder,
    handleConfirmAnnulOrder,
    handleChangeWaiterNote,
  }
}

export default useOrderMutations
