/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { useNoAuthOrdersContext } from '../../../context/NoAuthOrders/NoAuthOrders.provider'
import useOrdersMutations from '../../../hooks/useOrders.mutations'
import OrderItem from '../components/OrderItem.component'

import { Hidden } from '@material-ui/core'
import { sDescription } from '../../../../style/style'
import SkeletonOrder from '../../../../components/SkeletonOrder/SkeletonOrder'

export default () => {
  const { handleDeleteOrder } = useOrdersMutations()
  const { ordersNoAuth, loading } = useNoAuthOrdersContext()

  const onDeleteOrder = (orderId) => handleDeleteOrder({ orderId })

  if (!ordersNoAuth || ordersNoAuth.length === 0)
    return <div css={sDescription}>Twój koszyk jest pusty</div>

  if (loading)
    return (
      <>
        <SkeletonOrder />
        <Hidden mdUp>
          <SkeletonOrder />
          <SkeletonOrder />
          <SkeletonOrder />
        </Hidden>
      </>
    )

  return ordersNoAuth.map((order, id) => (
    <OrderItem
      id={id}
      key={id}
      onDelete={() => onDeleteOrder(order._id)}
      {...makeOrderProps(order)}
    />
  ))
}

function makeOrderProps(order) {
  const { _id, restId, orderDetails } = order

  return {
    orderId: _id,
    restaurantId: restId._id,
    restaurantName: restId.name,
    restaurantAddress: restId.address,
    restaurantLogoSrc: restId.avatarUrl,
    orderedProducts: orderDetails[0].productsInCart,
  }
}
