import { useState } from 'react'
import { useGetIngredients } from '../../../hooks/useQueries'


export default () => {
  const [ingredients, setIngredients] = useState([])
  const asyncIngridients = useGetIngredients()

  const handleIngridients = (i) => setIngredients(i)

  return {
    ingredients,
    asyncIngridients,
    handleIngridients
  }
}