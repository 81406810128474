/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import TextField from '@material-ui/core/TextField';

export default function InputEmail(props) {
  return (
    <TextField
      type="text"
      {...props}
      css={{ maxWidth: 320, width: '100%' }}
    />
  )
}