/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import WarningIcon from '@material-ui/icons/Warning'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import useProdMutations from '../../../hooks/useProd.mutations'
import { ezrmRoutePrefix } from '../../settings'

export default ({ prodId, isArchived }) => {
  const history = useHistory()
  const { handleDeleteProd, handleArchiveProd } = useProdMutations({ prodId })

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClickMenu = (event) => setAnchorEl(event.currentTarget)
  const handleCloseMenu = () => setAnchorEl(null)

  const [openDeleteAlert, setOpenDeleteAlert] = useState(false)
  const handleCloseDelete = () => setOpenDeleteAlert(false)
  const handleOpenDelete = () => {
    handleCloseMenu()
    setOpenDeleteAlert(true)
  }
  const onDelete = () =>
    handleDeleteProd({ prodId }, () => {
      toast.info('Produkt został trwale usunięty')
      history.push(ezrmRoutePrefix + '/products')
    })

  const [openArchiveAlert, setOpenArchiveAlert] = useState(false)
  const handleCloseArchive = () => setOpenArchiveAlert(false)
  const handleOpenArchive = () => {
    handleCloseMenu()
    setOpenArchiveAlert(true)
  }
  const onArchive = () =>
    handleArchiveProd({ prodId, isArchived: !isArchived }, () => {
      setOpenArchiveAlert(false)
      toast.info(
        isArchived
          ? 'Produkt został dearchiwizowany'
          : 'Produkt został zarchiwizowany'
      )
    })

  return (
    <div>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClickMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleCloseMenu}>
        <MenuItem onClick={handleOpenDelete}>Usuń produkt</MenuItem>
        <MenuItem onClick={isArchived ? onArchive : handleOpenArchive}>
          {isArchived ? 'Włącz produkt' : 'Wyłącz produkt'}
        </MenuItem>
      </Menu>

      <AlertDialog
        open={openDeleteAlert}
        handleClose={handleCloseDelete}
        onSubmit={onDelete}
        header='Trwale usunąć ten produkt?'
        paragraph='Trwale usuwając produkt, nie będziesz miał możliwości przywrócenia. Czy na pewno chcesz kontynuować?'
        submitButtonText='Usuń'
        closeButtonText='Anuluj'
      />

      <AlertDialog
        open={openArchiveAlert}
        handleClose={handleCloseArchive}
        onSubmit={onArchive}
        header='Archiwizacja'
        paragraph='Zarchiwizowane produkty nie będą pojawiać się w menu na stronie restauracji, w wynikach wyszukiwań oraz na pozostałych stronach Eatzon. Zawsze możesz przywrócić Produkt do stanu normalnego.'
        submitButtonText='Archiwizuj'
        closeButtonText='Anuluj'
      />
    </div>
  )
}

const AlertDialog = ({
  open,
  handleClose,
  onSubmit,
  header,
  paragraph,
  submitButtonText,
  closeButtonText,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'>
    <DialogTitle css={{ h2: { display: 'flex', alignItems: 'center' } }}>
      {submitButtonText === 'Usuń' && <WarningIcon />}
      {header}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id='alert-dialog-description'>
        {paragraph}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onSubmit} color='primary' variant='outlined'>
        {submitButtonText}
      </Button>
      <Button
        onClick={handleClose}
        color='primary'
        variant='contained'
        autoFocus>
        {closeButtonText}
      </Button>
    </DialogActions>
  </Dialog>
)
