/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { Button } from '../../../../components/Elements'
import useOrdersMutations from '../../../hooks/useOrders.mutations'

const ButtonFinish = ({ orderId }) => {
  const [isLoading, setIsLoading] = useState(false)

  const { handleFinishTheOrder } = useOrdersMutations()

  const onFinish = () => {
    setIsLoading(true)
    handleFinishTheOrder({ orderId }, () => setIsLoading(false))
  }

  return (
    <Button w100Mobile action={onFinish} loading={isLoading}>
      Zakończ Zamówienie
    </Button>
  )
}

export default ButtonFinish
