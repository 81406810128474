/** @jsx jsx */
import React, { useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { ButtonBorder } from '../../../components/Elements'
import Headline from '../../../components/Headline/Headline'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import { sDescription, sGrid, sOuter, sPaddingTop } from '../../../style/style'
import { useParams } from 'react-router-dom'
import { useGetProduct } from '../../hooks/useQueries'
import ProductProvider, { useProductContext } from './context/product.provider'
import VisibilityIcon from '@material-ui/icons/Visibility'
import SkeletonOrder from '../../../components/SkeletonOrder/SkeletonOrder'
import { Grid } from '@material-ui/core'
import Details from './components/Details'
import Price from './components/Price'
import ConsumptionTypes from './components/ConsumptionTypes'
import OpenHours from './components/OpenHours'
import StatusArchive from './components/StatusArchive'
import ButtonSave from './components/ButtonSave'

const ProductPage = () => {
  const prodId = useParams().id

  const { data, loading, error } = useGetProduct(prodId)
  const {
    setAvatarUrl,
    setAvailableHours,

    setProductInfo,
    handleCategories,

    handleIngridients,
    handleFlavors,

    handleCookMethod,
  } = useProductContext()

  useEffect(() => {
    if (data?.ezcProduct) {
      const product = data.ezcProduct
      setAvatarUrl(product.photoUrl)
      setAvailableHours(product.availableHours)

      const {
        name,
        description,
        price,
        cookTime,
        vat,
        consumptionTypes,
        _id,
        isArchived,
      } = product
      setProductInfo({
        name,
        description,
        vat,
        price,
        cookTime,
        consumptionTypes,
        _id,
        isArchived,
      })

      handleCategories(product.categories.map((el) => el._id))
      handleIngridients(product.ingredients.map((el) => el._id))

      handleFlavors(product.flavors)
      handleCookMethod(product.cookMethod)
    }
  }, [data])

  if (loading)
    return (
      <React.Fragment>
        <div css={sOuter}>
          <Headline>Produkty</Headline>
          <Grid container css={sGrid}>
            <p css={sDescription}>
              Modyfikuj szczegóły produktu, zarządzaj godzinami dostępności oraz
              parametrami wyszukiwania.
            </p>
          </Grid>
          <Grid container css={[sGrid, sPaddingTop]}>
            <SkeletonOrder />
            <SkeletonOrder />
          </Grid>
        </div>
      </React.Fragment>
    )

  return (
    <React.Fragment>
      <TopNav title={'Produkt'} offset={60} />
      <div css={sOuter}>
        <Headline
          textWrap
          itemChildren={
            <ButtonBorder link={`/product/${data?.ezcProduct?._id}`}>
              <VisibilityIcon fontSize='inherit' />
              &nbsp;&nbsp;Wyświetl
            </ButtonBorder>
          }>
          {data?.ezcProduct?.name}
        </Headline>
        <Details>
          <StatusArchive />
        </Details>
        <Price />
        <ConsumptionTypes />
        <OpenHours />
      </div>
      <ButtonSave prodId={prodId} />
    </React.Fragment>
  )
}

export default () => (
  <ProductProvider>
    <ProductPage />
  </ProductProvider>
)
