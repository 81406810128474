/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import { Collapsable } from '../../../../components/Elements'
import { CollapsableText } from '../../../../components/Elements/Collapsable/Collapsable'
import { colors, alpha, s } from '../../../../style'
import ButtonDecline from './ButtonDecline'
import ButtonAccept from './ButtonAccept'

const OrderPartially = ({ orderId, amountToPay }) => {
  return (
    <Grid
      item
      xs={12}
      css={{
        [s.xs]: { paddingBottom: '1rem' },
        [s.sm]: { paddingBottom: '1.5rem' },
      }}>
      <Collapsable
        // openAlready
        noPadding
        oneLiner
        title={'Odrzucone Produkty'}
        icon={<WarningIcon />}
        description={'Wymagane potwierdzenie użytkownika'}
        sIconCss={{
          color: colors.orange,
          backgroundColor: colors.orange.concat(alpha[16]),
        }}>
        <CollapsableText>
          Niektóre z wybranych produktów zostały odrzucone przez Restaurację.
          <strong css={{ color: colors.text }}>
            {' '}
            Potwierdź i Opłać
          </strong> lub <strong css={{ color: colors.text }}>Odrzuć </strong>
          zamówienie.
        </CollapsableText>
        <div
          css={{
            display: 'flex',
            [s.xs]: {
              padding: '0 1rem 1.5rem',
              flexDirection: 'column',
              gap: '1rem',
            },
            [s.sm]: {
              padding: '0 1.5rem 1.5rem',
              flexDirection: 'column',
              gap: '1.5rem',
            },
            [s.md]: {
              padding: '0rem 2rem 2rem',
              gap: '2rem',
              flexDirection: 'row',
            },
          }}>
          <ButtonAccept orderId={orderId} amountToPay={amountToPay} />
          <ButtonDecline orderId={orderId} />
        </div>
      </Collapsable>
    </Grid>
  )
}

export default OrderPartially
