import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Button from '@material-ui/core/Button'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import { daysOfWeek } from '../../../../constants/daysOfWeek'
import useRestaurantMutations from '../../../hooks/useRestaurant.mutations'
import { useRestaurantContext } from '../context/restaurant.provider'
import { ezrmRoutePrefix } from '../../settings'

import deliverySettingsCheck from '../helpers/deliverySettingsCheck'
import deliveryParseFloatAmount from '../helpers/deliveryParseFloatAmounts'

export default () => {
  const [isLoading, setIsLoading] = useState(false)
  const [ezwLoginData, setEzwLoginData] = useState(null)
  const history = useHistory()
  const { handleRestCreate } = useRestaurantMutations()
  const {
    openningHours,
    categories,
    restaurantInfo,
    logoFile,
    backgroundFile,
    deliverySettings,
  } = useRestaurantContext()

  const onSubmit = async () => {
    try {
      setIsLoading(true)
      for (const day in openningHours) {
        if (openningHours[day].open > openningHours[day].close) {
          toast.error(
            `${daysOfWeek[day]} - godzina otwarcia nie może być późniejsza od godziny zamknięcia`
          )
          return
        }
      }

      const categoryIds = []
      const newCategories = []
      categories.forEach((el) => {
        if (typeof el === 'string') categoryIds.push(el)
        else newCategories.push(el.inputValue)
      })

      if (restaurantInfo?.consumptionTypes.includes('delivery')) {
        const deliverySettingsErrors = deliverySettingsCheck(deliverySettings)
        if (deliverySettingsErrors.length > 0) {
          const errors = new Error()
          errors.array = deliverySettingsErrors
          throw errors
        }
        deliveryParseFloatAmount(deliverySettings)
      }

      const input = {
        name: restaurantInfo.name,
        description: restaurantInfo.description,
        categories: categoryIds,
        address: restaurantInfo.address,
        lng: restaurantInfo?.location?.coordinates[0],
        lat: restaurantInfo?.location?.coordinates[1],
        consumptionTypes: restaurantInfo?.consumptionTypes,
        openningHours,
        deliverySettings,
      }

      const errors = []
      if (!restaurantInfo.name) errors.push('Podaj nazwę')
      if (!restaurantInfo.address) errors.push('Podaj adres')
      if (errors.length) {
        errors.forEach((el) => toast.error(el))
        setIsLoading(false)
        return
      }

      const res = await handleRestCreate({
        input,
        newCategories,
        logoFile,
        backgroundFile,
      })

      if (res) setEzwLoginData(res.data?.restCreate)
      setIsLoading(false)
    } catch (err) {
      if (err.array && err.array.length > 0) {
        err.array.forEach((e) => toast.warn(e))
      } else {
        toast.warn(err.message)
      }
      setIsLoading(false)
    }
  }

  const onClose = () => history.push(ezrmRoutePrefix.concat('/dashboard'))

  return (
    <>
      <Button
        size='large'
        variant='contained'
        color='primary'
        onClick={onSubmit}
        disabled={isLoading}>
        Dodaj restaurację
      </Button>

      <Dialog open={Boolean(ezwLoginData)} onClose={onClose}>
        <DialogTitle>Hasło do Pulpitu Kelnera</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Twoja restauracja została stworzona!
            <br />
            <br />
            Twoje <b>dane logowania do Pulpitu Kelnera:</b>
            <br />
            Login: <b>{ezwLoginData?.login}</b>
            <br />
            Hasło: <b>{ezwLoginData?.password}</b>
            <br />
            <br />
            Hasło jest pokazywane tylko raz. Zapisz go, aby mieć dostęp do
            Pulpitu Kelnera.
            <br />
            Hasło zawsze można zmienić na stronie Dostępy.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} variant='contained' color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
