/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useWaiterAuthContext } from '../../context/auth-waiter.context'
import { GET_RESTAURANT } from '../../graphql/restaurant.graphql'
import { useQuery } from '@apollo/client'
import RestContainer from '../../../EZC/views/ProductPage/components/RestContainer'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import { Grid } from '@material-ui/core'
import { Button, ButtonBorder } from '../../../components/Elements'
import {
  sContainerOuter,
  sGrid,
  sOuter,
  sPaddingTop,
} from '../../../style/style'
import RestaurantIcon from '@material-ui/icons/Restaurant'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import NotificationsIcon from '@material-ui/icons/Notifications'
import LocalDiningIcon from '@material-ui/icons/LocalDining'
import HistoryIcon from '@material-ui/icons/History'
import PersonIcon from '@material-ui/icons/Person'
import Headline from '../../../components/Headline/Headline'
import { colors, globals, s } from '../../../style'
import { MenuItem } from '../../../EZC/pages/account/Account.page'

const HomePage = () => {
  const { waiterLogout, restaurantId } = useWaiterAuthContext()
  const { data, loading, error } = useQuery(GET_RESTAURANT, {
    variables: { restId: restaurantId },
    skip: !restaurantId,
  })

  const itemsData = [
    {
      to: '/waiter/notifications',
      name: 'Aktywne',
      description: 'Zamówienia wymagające uwagi',
      AvatarIcon: NotificationsIcon,
    },
    {
      to: '/waiter/orders',
      name: 'Zamówienia',
      description: 'Zamówienia realizowane',
      AvatarIcon: LocalDiningIcon,
    },
    {
      to: '/waiter/history',
      name: 'Historia',
      description: 'Zamówienia zakończone',
      AvatarIcon: HistoryIcon,
    },
    {
      to: '/rest',
      name: 'Panel Restauratora',
      AvatarIcon: RestaurantIcon,
    },
    {
      to: '/account',
      name: 'Panel Klienta',
      AvatarIcon: PersonIcon,
    },
  ]

  return (
    <React.Fragment>
      <TopNav title={'Kelner'} offset={60} />
      <div css={sOuter}>
        <Headline
          itemChildren={
            <ButtonBorder action={waiterLogout}>Wyloguj</ButtonBorder>
          }>
          Kelner
        </Headline>
      </div>
      {data ? (
        <RestContainer data={data.ezcRestaurant} />
      ) : (
        <div css={sRestSkeleton}>
          <div css={sRestSkeletonInner} />
        </div>
      )}
      <div css={[sOuter, { marginTop: '2rem', [s.sm]: { marginTop: '2rem' } }]}>
        <Grid container css={sGrid}>
          {itemsData.map((item, id) => (
            <MenuItem key={id} id={id} {...item} />
          ))}
          <MenuItem
            name='Wyloguj'
            AvatarIcon={MeetingRoomIcon}
            onClick={() => waiterLogout()}
          />
        </Grid>
      </div>

      <Grid container css={[sContainerOuter, sGrid, sPaddingTop]}>
        <Button w100 action={waiterLogout}>
          Wyloguj
        </Button>
      </Grid>
    </React.Fragment>
  )
}

export const sRestSkeletonInner = {
  backgroundColor: colors.gray200,
  borderRadius: 32,
  flexShrink: 0,
  width: '100%',
  height: '100%',
}

export const sRestSkeleton = {
  position: 'relative',
  top: 0,
  left: 0,
  maxWidth: globals.maxWidthMedium,
  height: 400,
  width: '100%',
  margin: '0 auto 1.5rem',
  [s.xs]: {
    width: 'calc(100vw - 48px)',
    height: 'calc(9 * calc(100vw - 48px) / 16)',
    borderRadius: 24,
  },
  [s.sm]: { padding: '1.5rem' },
  [s.md]: { padding: '1.5rem 2rem' },
}

export default HomePage
