/** @jsx jsx */
import React, { useState, useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { FormControl, Select, MenuItem } from '@material-ui/core'

import Input, {
  sBase,
  sInput,
  sInputDarker,
} from '../../../views/Auth/components/Input.component'
import { Grid, ButtonBase } from '@material-ui/core'
import { s } from '../../../../style'

const InputFields = ({ onChange, consumptionDetails }) => {
  const { consumptionDate, consumptionTime, consumptionPersons } =
    consumptionDetails

  return (
    <Grid
      container
      css={{
        [s.sm_down]: { padding: '1.5rem 1rem' },
        [s.md]: { padding: '2rem' },
      }}>
      <Grid item xs={12}>
        <Input
          darker
          name='consumptionDate'
          type='date'
          label='Data'
          placeholder='Data'
          pattern='\d{1,2}/\d{1,2}/\d{4}'
          onChange={onChange}
          value={consumptionDate || ''}
          sBaseExtra={{ [s.sm_down]: { marginBottom: '1rem' } }}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          darker
          name='consumptionTime'
          placeholder='Czas'
          type='time'
          label='Czas'
          // pattern='([01]?[0-9]|2[0-3]):[0-5][0-9]'
          pattern='(0[1-9]|1[0-9]|2[0-9]|3[01]).(0[1-9]|1[012]).[0-9]{4}'
          onChange={onChange}
          value={
            consumptionTime.length < 5 ? 0 + consumptionTime : consumptionTime
          }
          sBaseExtra={{ [s.sm_down]: { marginBottom: '1rem' } }}
        />
      </Grid>

      <Grid item xs={12}>
        <ButtonBase
          css={[
            sBase,
            {
              '.MuiSelect-select.MuiSelect-select': { padding: 0 },
              '.MuiSelect-select:focus': { backgroundColor: 'transparent' },
              '.MuiInput-underline:before, .MuiInput-underline:after': {
                border: 'none !important',
              },
              textAlign: 'left',
              svg: {
                marginRight: '1rem',
              },
              marginBottom: 0,
            },
          ]}>
          <FormControl fullWidth>
            <Select
              placeholder='Liczba Osób'
              name='consumptionPersons'
              type='number'
              value={consumptionPersons}
              onChange={onChange}
              css={[sInput, sInputDarker]}>
              {howManyPersonsOptions.map((el) => (
                <MenuItem key={el} value={el}>
                  {el}{' '}
                  {el === 1 ? `osoba` : el > 1 && el < 5 ? `osoby` : 'osób'}
                </MenuItem>
              ))}
              <MenuItem value={10}>10+ osób</MenuItem>
            </Select>
          </FormControl>
        </ButtonBase>
      </Grid>
    </Grid>
  )
}

export default InputFields

const howManyPersonsOptions = Array.from({ length: 9 }, (_, i) => i + 1)
