/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars


const UserItem = ({ email, isAdmin, helperComponent }) => (
  <div css={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 32
  }}>
    <div>
      <p>{email}</p>
      {
        isAdmin
          ? <p css={{ color: 'green', fontWeight: 700 }}>Jest adminem</p>
          : <p css={{ color: 'red', fontWeight: 700 }}>Nie jest adminem</p>
      }
    </div>
    <div>{helperComponent}</div>
  </div>
)

export default UserItem