import { gql } from '@apollo/client'


export const GET_REST_MENU = gql`
  query waiterMenuSections {
    waiterMenuSections {
      _id
      name
      products {
        _id
        name
        photoUrl
        price
        ingredients {
          _id
          name
          type
        }
      }
    }
  }
`