import { useState, useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'

export const storageName = 'waiterData'

export const useWaiterAuth = () => {
  const [waiterToken, setWaiterToken] = useState(null)
  const [restaurantId, setRestaurantId] = useState(null)
  const [ready, setReady] = useState(false)

  const waiterLogin = useCallback((jwtToken, id) => {
    setWaiterToken(jwtToken)
    setRestaurantId(id)

    localStorage.setItem(
      storageName,
      JSON.stringify({
        restaurantId: id,
        waiterToken: jwtToken,
      })
    )
  }, [])

  const waiterLogout = useCallback(() => {
    setWaiterToken(null)
    setRestaurantId(null)
    localStorage.removeItem(storageName)
    localStorage.removeItem('activeRestaurant')
    toast.dark('Wylogowano pomyślnie')
  }, [])

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(storageName))

    if (data && data.waiterToken) {
      waiterLogin(data.waiterToken, data.restaurantId)
    }
    setReady(true)
  }, [waiterLogin])

  return { waiterLogin, waiterLogout, waiterToken, restaurantId, ready }
}
