import { toast } from 'react-toastify'

export const sendToPaynow = async (amount, orderId) => {
  // amount must be Int! in format 24,99, required
  // orderId must be _id of ActiveOrder, not required

  try {
    if (!amount) throw new Error('W celu doładowania konta należy podać kwotę')
    if (amount < 1)
      throw new Error('Kwota doładowania musi być większa od 1 złotych')
    if (!Number.isInteger(amount))
      throw new Error('Kwota doładowania musi być liczbą naturalną')
    if (typeof amount !== 'number')
      throw new Error('Kwota doładowania musi być liczbą')

    const userData = JSON.parse(localStorage.getItem('userData'))
    const token = userData ? userData.token : null

    const response = await fetch(
      `${
        process.env['REACT_APP_SERVER_URI_' + process.env.REACT_APP_NODE_ENV]
      }/paynow/start`,
      {
        method: 'POST',
        body: JSON.stringify({
          // amount: parseInt(amount * 100, 10),
          amount,
          orderId,
        }),
        headers: {
          'Content-Type': 'application/json',
          authorization: token ? `Bearer ${token}` : '',
        },
      }
    )
    const data = await response.json()

    if (!response.ok) {
      if (data.errors) {
        throw new Error(data.errors[0].msg)
      } else if (data.message) {
        throw new Error(data.message)
      }
      throw new Error('Wystąpił błąd. Proszę spróbować ponownie')
    }

    window.location.href = data.redirectUrl
  } catch (err) {
    toast.error(err.message)
    return false
  }
}
