/** @jsx jsx */
import React, { useEffect, useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useParams, useHistory } from 'react-router-dom'
import { useGetOrder } from '../../hooks/useOrders.queries'
import removeDuplicateProducts from '../../../helpers/remove-duplicate-products'
import sortProductsByStatus from '../../../helpers/sort-products-by-status'
import PageLoader from '../../../components/PageLoader/PageLoader'
import { sOuter, sNoMarginTop, sGrid, sBottomLinks } from '../../../style/style'
import { s } from '../../../style'
import { ButtonBorder } from '../../../components/Elements'
import Headline from '../../../components/Headline/Headline'
import { Grid, Hidden } from '@material-ui/core'
import RestContainer from '../../views/ProductPage/components/RestContainer'
import { ORDER_STATUSES, ORDER_STATUS_COLOR } from '../../../constants/statuses'
import { StatusOrder, SectionHeadline } from '../../../components/Elements'
import OrderAddress from '../orders/components/OrderAddress'
import OrderChatHistory from '../orders/components/OrderChatHistory'
import OrderDate from '../orders/components/OrderDate'
import OrderInvoice from '../orders/components/OrderInvoice'
import OrderNote from '../orders/components/OrderNote'
import OrderPaymentLarge from '../orders/components/OrderPaymentLarge'
import OrderType from '../orders/components/OrderType'
import OrderID from '../orders/components/OrderID'
// import OrderPartially from '../orders/components/OrderPartially'
import OrderCanceled from '../orders/components/OrderCanceled'
// import ButtonAccept from '../orders/components/ButtonAccept'
import OrderStatuses from '../orders/components/OrderStatuses'
import OrderProduct from '../orders/components/OrderProduct'
// import OrderNeedPayment from '../orders/components/OrderNeedPayment'
// import MoneyEz from '../../../helpers/MoneyEz'
// import orderNeedToPay from '../../helpers/order-need-to-pay'

import {
  sGridSpacing,
  // sButtonFixed,
  _getActualStatusName,
} from '../../pages/orders/order-active.page'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import StatusGuide from '../../../components/StatusGuide/StatusGuide'

const OrderHistory = () => {
  const orderId = useParams().id
  const history = useHistory()
  const { data, error, loading } = useGetOrder(orderId)
  const [isStatusGuideOpen, setIsStatusGuideOpen] = useState(false)
  // console.log(orderId)

  useEffect(() => {
    if (error) history.push(`/orders-history`)
  }, [error, orderId])

  if (loading) return <PageLoader />
  if (error) {
    // console.log(error)
    // errorHandling(error)
    return (
      <React.Fragment>
        <TopNav title={'Zamówienie'} subtitle={` - nieodnalezione`} />
        <div css={sOuter}>
          <Headline>Nieodnaleziono</Headline>
          <Grid container css={sGrid}>
            <Grid item xs={12} css={sBottomLinks}>
              <ButtonBorder link={'/orders-history'}>
                Historia Zamówień
              </ButtonBorder>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    )
  }

  const { ezcOrder, user } = data

  let allProducts,
    allProductsSorted,
    newAllProducts = []
  if (ezcOrder?.orderDetails?.length) {
    allProducts = [
      ...ezcOrder.orderDetails[0].productsToOrder,
      ...ezcOrder.orderDetails[0].productsInProcess,
      ...ezcOrder.orderDetails[0].productsFinishedGood,
      ...ezcOrder.orderDetails[0].productsFinishedBad,
      ...ezcOrder.orderDetails[0].productsDeclined,
    ]
    newAllProducts = removeDuplicateProducts(allProducts)
    allProductsSorted = sortProductsByStatus(newAllProducts)
  }

  // const { coinsBalance, coinsBlocked } = user
  // const needToPay = orderNeedToPay(ezcOrder)
  // const finisedOrCancelledStatesArray = [
  //   'DECLINED_BY_REST',
  //   'DECLINED_BY_CLIENT',
  //   'FINISHED_BY_USER',
  //   'FINISHED',
  //   'AUTO_FINISHED',
  //   'DECLINED_FINISH_BY_REST',
  //   'ANNUL_BY_CLIENT',
  //   'ANNUL_BY_REST',
  //   'AUTO_ANNULLED_BECAUSE_OF_PAYMENT',
  // ]
  const isCanceled = _getActualStatusName(ezcOrder) === 'ANNUL_BY_CLIENT'
  // const isFinished = finisedOrCancelledStatesArray.includes(
  //   _getActualStatusName(ezcOrder)
  // )

  // let userCoinsBalance, defaultPaymentValue
  // if (typeof coinsBalance === 'number' && typeof coinsBlocked === 'number') {
  //   userCoinsBalance = MoneyEz(coinsBalance)
  //     .subsctract(coinsBlocked)
  //     .getAmount()

  //   // defaultPaymentValue = MoneyEz(ezcOrder.priceSum)
  //   //   .subsctract(ezcOrder.paidSum)
  //   //   .subsctract(userCoinsBalance)
  //   //   .getAmount()
  // }

  const {
    shortId,
    isPaid,
    restId: { name, avatarUrl, _id },
    status: statuses,
    consumptionType,
    deliveryAddress,
    deliveryDetails,
    consumptionDate,
    consumptionTime,
    consumptionPersons, // IKR
    note,
    isInvoice,
    invoiceDetails,
    deliveryCost,
    paidSum,
    priceSum,
    // messages,
  } = ezcOrder

  const status = [...statuses].pop().name

  return (
    <React.Fragment>
      {/* TOP NAV BAR */}
      <TopNav
        title={name}
        subtitle={` - Zamówienie ${shortId}`}
        photo={avatarUrl}
        textCss={{
          [s.xs]: { maxWidth: `calc(100vw - 9rem)` },
        }}
      />

      {/* {JSON.stringify(allProducts, null, 2)} */}

      {/* HEADLINE */}
      <div css={sOuter}>
        <Headline
          itemChildren={
            <div
              css={{ cursor: 'pointer' }}
              onClick={() => setIsStatusGuideOpen(!isStatusGuideOpen)}>
              <StatusOrder color={ORDER_STATUS_COLOR[status]}>
                {ORDER_STATUSES[status]}
              </StatusOrder>
            </div>
          }
          extraCss={{
            alignItems: 'center',
            [s.xs]: {
              padding: '2rem 1.5rem 1.5rem',
              justifyContent: 'center',
              flexDirection: 'column',
            },
          }}>
          Zamówienie
          <Hidden xsDown> {shortId}</Hidden>
        </Headline>
      </div>

      {/* RESTAURANT */}
      <RestContainer data={ezcOrder.restId} isPaid={isPaid} />

      {isCanceled && (
        <div css={[sOuter, sNoMarginTop]}>
          <Grid container css={sGrid}>
            <OrderCanceled name={name} shortId={shortId} />
          </Grid>
        </div>
      )}

      <div css={[sOuter, sNoMarginTop]}>
        <Grid container css={sGrid}>
          {/* ORDER CHAT */}
          <OrderChatHistory orderId={orderId} userId={user._id} />
          {/* ORDER STATUSES */}
          <OrderStatuses
            statuses={statuses}
            open={isStatusGuideOpen}
            setOpen={setIsStatusGuideOpen}
          />
        </Grid>
      </div>

      {/* ORDER PRODUCTS */}
      <div css={[sOuter, sNoMarginTop]}>
        <Grid
          container
          css={[sGrid, sGridSpacing]}
          spacing={3}
          justify='space-between'>
          <SectionHeadline>Produkty</SectionHeadline>
          {allProductsSorted.map((product, productIndex) => (
            <OrderProduct key={productIndex} data={product} />
          ))}
        </Grid>
      </div>

      {/* ORDER DETAILS */}
      <div css={[sOuter, sNoMarginTop]}>
        <Grid
          container
          css={[sGrid, sGridSpacing]}
          spacing={3}
          justify='space-between'>
          <SectionHeadline>Szczegóły</SectionHeadline>

          {/* ORDER TYPE */}
          <OrderType consumptionType={consumptionType} />

          {/* ORDER ADDRESS */}
          {consumptionType === 'delivery' && (
            <OrderAddress
              deliveryAddress={deliveryAddress}
              deliveryDetails={deliveryDetails}
            />
          )}

          {/* ORDER DATE */}
          <OrderDate
            consumptionDate={consumptionDate}
            consumptionTime={consumptionTime}
            consumptionPersons={consumptionPersons}
          />

          {/* ORDER NOTE */}
          {note && <OrderNote note={note} />}

          {/* ORDER INVOICE */}
          {isInvoice && <OrderInvoice invoiceDetails={invoiceDetails} />}

          {/* ORDER ID */}
          <OrderID shortId={shortId} orderId={_id} />

          {/* ORDER PAYMENT */}
          <OrderPaymentLarge
            priceSum={priceSum}
            paidSum={paidSum}
            deliveryCost={deliveryCost}
            isDelivery={consumptionType === 'delivery'}
          />
        </Grid>
      </div>
      <StatusGuide open={isStatusGuideOpen} setOpen={setIsStatusGuideOpen} />
    </React.Fragment>
  )
}

export default OrderHistory
