/** @jsx jsx */
import React, { useRef, useEffect, useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useRoutesEZW } from '../../../routes'
import {
  useWaiterAuthContext,
  WaiterAuthContext,
} from '../../context/auth-waiter.context'
import { useWaiterAuth } from '../../hooks/auth-waiter.hook'
import Layout from '../../components/Layout'
import OrdersProvider, { useOrdersContext } from '../../context/orders.context'
import LoginPage from '../Login/LoginPage'
import PopupWaiter from '../../components/PopupWaiter'

const IndexPage = () => {
  const { waiterLogin, waiterLogout, waiterToken, restaurantId, ready } =
    useWaiterAuth()

  const waiterIsAuth = !!waiterToken

  if (!ready) return null

  return (
    <WaiterAuthContext.Provider
      value={{
        waiterLogin,
        waiterLogout,
        waiterToken,
        restaurantId,
        waiterIsAuth,
      }}>
      <EZWContainer />
    </WaiterAuthContext.Provider>
  )
}

const EZWContainer = () => {
  const { waiterIsAuth } = useWaiterAuthContext()
  const routes = useRoutesEZW(waiterIsAuth)
  return waiterIsAuth ? (
    <OrdersProvider>
      <Layout>
        {routes} <PopupWaiter />
      </Layout>
    </OrdersProvider>
  ) : (
    <Layout>
      <LoginPage />
    </Layout>
  )
}

export default IndexPage
