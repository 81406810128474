/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { s, colors, alpha, globals } from '../../../../style'
import { krakenUrls } from '../../../../constants/s3-files'
import { Link } from 'react-router-dom'
import {
  Bullets,
  Bullet,
  StatusLarge,
  Button,
} from '../../../../components/Elements'
import useDeliveryInfo from '../../../hooks/useDeliveryInfo'
import { ButtonBase } from '@material-ui/core'

const RestContainer = ({ data, isPaid, children, extraCss }) => {
  // console.log(data)
  const { address, avatarUrl, categories, isActive, name, photoUrl, _id } = data

  const categoriesOrder = [
    'POPULAR',
    'MEALTYPE',
    'CUISINE',
    'DIET',
    'DAYTIME',
    'OTHER',
  ]

  const categoriesShow = categoriesOrder
    .map((order) => categories?.filter(({ type }) => type === order))
    .flat()
    .map((e) => e?.name || e)

  // console.log(isPaid)

  return (
    <Link
      to={`/restaurant/${_id}`}
      css={[
        sContainer,
        isPaid !== undefined && {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
        extraCss,
      ]}>
      <div
        css={[
          sImageContainer,
          !photoUrl && { backgroundColor: colors.gray200 },
          isPaid !== undefined && {
            [s.xs]: { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 },
            [s.sm]: { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 },
          },
        ]}>
        {photoUrl && (
          <img
            className='image-scale'
            src={krakenUrls.original + photoUrl}
            alt={name}
            loading='lazy'
            css={sImageContainer.photo}
          />
        )}
        <div
          css={[
            sGradient,
            isPaid !== undefined && {
              [s.xs]: {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              },
              [s.sm]: {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              },
            },
          ]}
        />
        {avatarUrl && (
          <img
            className='image-scale-down'
            src={krakenUrls.original + avatarUrl}
            alt={name}
            loading='lazy'
            css={sAvatar}
          />
        )}
        <div css={sTextContainer}>
          <h2 className='product-restaurant-title'>{name}</h2>
          <div className='product-restaurant-categories' css={sOneLiner}>
            <Bullets array={categoriesShow} />
          </div>
          <div className='product-restaurant-details' css={sOneLiner}>
            <GetDistanceToUser restId={_id} />
            <Bullet />
            {address}
          </div>
        </div>
        <ButtonBase
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 6,
            display: 'flex',
            width: '100%',
            height: '100%',
            borderRadius: 'inherit',
            color: '#ffffff',
            boxShadow: '-4px 8px 32px rgb(0 0 0 / 16%)',
          }}
        />
      </div>
      {isPaid !== undefined && (
        <div css={styleIsPaid(isPaid)}>
          {isPaid ? 'OPŁACONE' : 'NIEOPŁACONE'}
        </div>
      )}
      {children}
      {isActive === false && (
        <div
          css={{
            position: 'absolute',
            left: 12,
            top: 12,
            zIndex: 6,
            [s.sm]: {
              top: 48,
              left: 48,
            },
            [s.md]: {
              top: '11rem',
              left: '4.5rem',
            },
          }}>
          <StatusLarge color={colors.red600}>Nieaktywna</StatusLarge>
        </div>
      )}
    </Link>
  )
}

const GetDistanceToUser = ({ restId }) => {
  const { distanceToUser } = useDeliveryInfo({ restId })
  return (
    <React.Fragment>{(distanceToUser / 1000).toFixed(2)} km</React.Fragment>
  )
}

export const sContainer = {
  animation: 'fadeIn 0.6s ease-in-out',
  // '@keyframes fadeIn': {
  //   from: { opacity: 0 },
  //   to: { opacity: 1 },
  // },
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: globals.maxWidthMedium,
  height: 400,
  borderRadius: 32,
  flexShrink: 0,
  // overflow: 'hidden',
  marginBottom: '1.5rem',
  '&:hover': {
    [s.hover]: {
      '.image-scale': { transform: 'scale(1.1)', filter: 'brightness(1.2)' },
      '.image-scale-down': { transform: 'scale(0.9)' },
    },
  },
  [s.xs]: {
    width: 'calc(100vw - 48px)',
    height: 'calc(9 * calc(100vw - 48px) / 16)',
    borderRadius: 24,
  },
  [s.sm]: { padding: '1.5rem' },
  [s.md]: { padding: '1.5rem 2rem' },
}
const sImageContainer = {
  width: '100%',
  height: '100%',
  position: 'relative',
  [s.xs]: { borderRadius: 24 },
  [s.sm]: { borderRadius: 32 },
  color: '#ffffff',
  overflow: 'hidden',
  photo: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: 'inherit',
    objectFit: 'cover',
    objectPosition: 'center center',
    zIndex: 2,
    transition: 'transform 0.3s ease-out, filter 0.3s ease-out',
  },
}

const sGradient = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: `linear-gradient(to right, ${colors.text.concat(
    alpha[90]
  )} 33%, ${colors.text.concat(alpha[0])} 66%)`,
  [s.xs]: {
    borderRadius: 24,
    background: `linear-gradient(to bottom, ${colors.text.concat(
      alpha[0]
    )}, ${colors.text.concat(alpha[90])} 66%)`,
  },
  [s.sm]: { borderRadius: 32 },
  zIndex: 3,
}

const sAvatar = {
  width: 96,
  height: 96,
  position: 'absolute',
  zIndex: 4,
  top: 32,
  borderRadius: '50%',
  boxShadow: '2px 4px 16px rgba(0,0,0,0.16)',
  transition: 'transform 0.3s ease-out, filter 0.3s ease-out',
  [s.sm_down]: {
    width: 64,
    height: 64,
    right: 16,
    top: 16,
  },
  [s.md]: { left: 32 },
}

const sTextContainer = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  zIndex: 5,
  color: '#ffffff',
  padding: '2rem',
  [s.xs]: { borderRadius: 24 },
  [s.sm]: { borderRadius: 32, padding: '2.5rem' },
  [s.sm_down]: { padding: '1.5rem' },
  '.product-restaurant-title': {
    letterSpacing: '-0.05em',
    fontSize: '2.5rem',
    [s.sm_down]: { fontSize: '1.25rem' },
  },
  '.product-restaurant-categories': {
    fontSize: '0.875rem',
    textTransform: 'capitalize',
    opacity: 0.8,
    marginTop: '0.5rem',
    [s.md]: {
      opacity: 0.6,
      fontSize: '1.25rem',
      marginTop: '0.5rem',
    },
  },
  '.product-restaurant-details': {
    fontSize: '0.75rem',
    opacity: 0.6,
    marginTop: '0.5rem',
    [s.md]: {
      opacity: 0.6,
      fontSize: '1rem',
      marginTop: '2rem',
    },
  },
}

const sOneLiner = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: 'calc(100vw - 6rem)',
}

export const styleIsPaid = (isPaid) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  color: '#ffffff',
  alignItems: 'center',
  background: isPaid
    ? `linear-gradient(to right, ${colors.green}, ${colors.green800})`
    : `linear-gradient(to right, ${colors.red600}, ${colors.red800})`,
  width: '100%',
  borderRadius: '0 0 16px 16px',
  padding: '1rem 1rem',
  textTransform: 'uppercase',
  fontWeight: 800,
  letterSpacing: '0.2em',
  height: 24,
  fontSize: '0.75rem',
  [s.md]: {
    padding: '1rem 2rem',
    paddingLeft: '2.5rem',
  },
  [s.sm_down]: {
    paddingLeft: '1.5rem',
  },
})

export default RestContainer
