import { gql } from "@apollo/client"

export const GET_USER = gql`
  query user {
    user {
      _id
      email
      personal {
        name
        surname
        avatarUrl
      }
      coinsBalance
      coinsBlocked
      isRestaurateur
      settings {
        isSendingClientPushes
        isSendingRestPushes
      }
    }
  }
`

export const GET_USER_TRANSACTIONS = gql`
  query ezcTransactions {
    ezcTransactions {
      _id
      type
      fromUser {
        _id
        personal {
          name
          avatarUrl
        }
      }
      fromRest {
        _id
        name
        avatarUrl
      }
      fromFirm
      fromOther
      toUser {
        _id
        personal {
          name
          avatarUrl
        }
      }
      toRest {
        _id
        name
        avatarUrl
      }
      toFirm
      coinsAmount
      createdAt
      paynowInfo {
        status {
          name
          createdAt
        }
      }
    }
  }
`

export const GET_NOTIFICATIONS = gql`
  query ezcNotifications {
    ezcNotifications {
      _id
      # fromUser
      fromRest {
        avatarUrl
        # name
        # _id
      }
      fromFirm
      type
      isRead
      header
      message
      createdAt
      howManyTimeAgo
      orderId
    }
  }
`

export const SEARCH = gql`
  query ezcSearch($query: String, $prodFilter: ProdFilterInput) {
    ezcSearch(query: $query, prodFilter: $prodFilter) {
      products {
        _id
        name
        # restaurant {
        #   _id
        #   name
        # }
        price
        photoUrl
        #description
        #cookMethod
        ingredients {
          _id
          name
          type
        }
        # flavors
        categories {
          _id
          name
          type
        }
        restAvatarUrl
      }

      restaurants {
        _id
        name
        address
        avatarUrl
        photoUrl
        description
        categories {
          _id
          name
          type
        }
        distCalculated
      }

      categories {
        _id
        name
      }
    }
  }
`

export const GET_SEARCH_AUTOCOMPLETE = gql`
  query ezcSearchAutocomplete($query: String) {
    ezcSearchAutocomplete(query: $query) {
      products {
        _id
        name
        price
        photoUrl
        categories {
          _id
          name
          type
        }
      }

      restaurants {
        _id
        name
        avatarUrl
        photoUrl
        categories {
          _id
          name
          type
        }
      }

      ingredients {
        _id
        name
      }
    }
  }
`

export const READ_NOTIFICATION = gql`
  mutation userReadNotification($notifId: ID!) {
    userReadNotification(notifId: $notifId) {
      success
    }
  }
`

export const USER_UPDATE = gql`
  mutation userUpdate($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      success
    }
  }
`

export const USER_UPDATE_SETTINGS = gql`
  mutation userChangeSettings($input: UserSettingsInput!) {
    userChangeSettings(input: $input) {
      success
    }
  }
`

export const USER_RESET_PASSWORD = gql`
  mutation userResetPassword($email: String!) {
    userResetPassword(email: $email) {
      success
    }
  }
`

export const USER_NEW_PASSWORD = gql`
  mutation userNewPassword($token: String!, $password: String!) {
    userNewPassword(token: $token, password: $password) {
      success
    }
  }
`

export const USER_CONFIRM_EMAIL = gql`
  mutation userConfirmEmail($token: String!) {
    userConfirmEmail(token: $token) {
      success
    }
  }
`

export const USER_LOGIN = gql`
  mutation userLogin($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      userId
      token
      name
    }
  }
`

export const USER_SIGNUP = gql`
  mutation userCreate($input: UserCreateInput!) {
    userCreate(input: $input) {
      userId
      token
    }
  }
`
