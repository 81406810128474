/** @jsx jsx */
import React, { useState, useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useGetProducts } from '../../../hooks/useQueries'

import {
  List,
  Grid,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
} from '@material-ui/core'
import { sGrid } from '../../../../style/style'
import { SectionHeadline } from '../../../../components/Elements'
import ArrowDown from '@material-ui/icons/ExpandMore'
import ArrowUp from '@material-ui/icons/ExpandLess'
import ArrowUpAll from '@material-ui/icons/KeyboardCapslock'
import { IconButton } from '@material-ui/core'
import { colors, s } from '../../../../style'
import BottomSpacing from '../../../../components/BottomSpacing/BottomSpacing'

function not(a, b) {
  return a.filter((aa) => b.findIndex((bb) => bb._id === aa._id) === -1)
}

function intersection(a, b) {
  return a.filter((aa) => b.findIndex((bb) => bb._id === aa._id) !== -1)
}

const AddProductsToSection = ({ sectionId, menuSections, right, setRight }) => {
  const { data, error, loading } = useGetProducts()

  const activeSection = menuSections.find(
    (section) => section._id === sectionId
  ) || { products: [] }

  const [checked, setChecked] = useState([])
  const [left, setLeft] = useState([])

  useEffect(() => {
    setRight([...activeSection.products])
  }, [activeSection])

  useEffect(() => {
    if (data?.ezrProducts) {
      const sectionProductsIds = right.map((el) => el._id)
      setLeft(
        data.ezrProducts.filter(
          (product) => sectionProductsIds.indexOf(product._id) === -1
        )
      )
    }
  }, [data, right])

  if (loading) return <LinearProgress />
  if (error) return null

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = (value) => () => {
    const currentIndex = checked.findIndex(({ _id }) => _id === value._id)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleAllRight = () => {
    setRight(right.concat(left))
    setLeft([])
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const handleAllLeft = () => {
    setLeft(left.concat(right))
    setRight([])
  }

  const customList = (items) => (
    <List
      dense
      component='div'
      role='list'
      css={{
        '.MuiListItem-gutters': {
          padding: '0 0.25rem',
          [s.md]: { padding: '0 1.25rem' },
        },
      }}>
      <Grid container>
        {items.map((product) => {
          const isChecked =
            checked.findIndex(({ _id }) => product._id === _id) !== -1
          return (
            <Grid item xs={12} md={6} key={product._id}>
              <ListItem
                role='listitem'
                button
                onClick={handleToggle(product)}
                css={!isChecked && { color: colors.gray500 }}>
                <ListItemIcon
                  css={[
                    {
                      '.MuiCheckbox-colorSecondary.Mui-checked': {
                        color: colors.orange,
                      },
                    },
                    !isChecked && {
                      '.MuiCheckbox-root': {
                        color: colors.gray500,
                      },
                    },
                  ]}>
                  <Checkbox checked={isChecked} tabIndex={-1} disableRipple />
                </ListItemIcon>
                <ListItemText
                  primary={product.name}
                  css={{
                    '.MuiTypography-body2': {
                      letterSpacing: '-0.02em',
                    },
                  }}
                />
              </ListItem>
            </Grid>
          )
        })}
      </Grid>
      <ListItem />
    </List>
  )

  return (
    <div css={[sGrid, { flexDirection: 'column' }]}>
      <SectionHeadline>{activeSection.name}</SectionHeadline>
      {customList(right)}

      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: '1.5rem 0rem 2rem',
          [s.md]: {
            padding: '3.5rem 1rem 4rem',
            justifyContent: 'flex-start',
          },
        }}>
        <IconButton
          onClick={handleCheckedLeft}
          disabled={rightChecked.length === 0}
          aria-label='move selected down'>
          <ArrowDown />
        </IconButton>
        <IconButton
          onClick={handleAllLeft}
          disabled={right.length === 0}
          aria-label='move all down'>
          <ArrowUpAll css={{ transform: 'rotate(180deg)' }} />
        </IconButton>
        <IconButton
          onClick={handleAllRight}
          disabled={left.length === 0}
          aria-label='move all up'>
          <ArrowUpAll />
        </IconButton>
        <IconButton
          onClick={handleCheckedRight}
          disabled={leftChecked.length === 0}
          aria-label='move selected up'>
          <ArrowUp />
        </IconButton>
      </div>

      <SectionHeadline noPaddingTop>Wszystkie Produkty</SectionHeadline>
      {customList(left)}
      <BottomSpacing />
    </div>
  )
}

export default AddProductsToSection
