/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core'
import {
  StatusLarge,
  Collapsable,
  Row,
  Bullet,
} from '../../../../components/Elements'
import { s } from '../../../../style'
import {
  ORDER_STATUSES,
  ORDER_STATUS_COLOR,
} from '../../../../constants/statuses'
import { dateFc } from '../../../../helpers/date-format'
import UpdateIcon from '@material-ui/icons/Update'

const OrderStatuses = ({ statuses, waiter, open, setOpen }) => {
  // const statusesReversed = statuses.reverse()

  // console.log(statuses)
  const statusCurrent = [...statuses].pop()

  return (
    <Grid item xs={12} md={6}>
      <Collapsable
        right={!waiter}
        noPadding
        oneLiner
        title={'Statusy'}
        icon={<UpdateIcon />}
        description={
          <>
            {ORDER_STATUSES[statusCurrent.name]}
            <Bullet />
            {dateFc(
              statusCurrent.createdAt.split(' ')[0],
              statusCurrent.createdAt.split(' ')[1]
            ).slice(0, -3)}
          </>
        }>
        {statuses.map((status, id) => (
          <Row
            key={id}
            desc={
              <div
                css={{ cursor: 'pointer' }}
                onClick={() => setOpen && setOpen(!open)}>
                <StatusLarge
                  color={ORDER_STATUS_COLOR[status.name]}
                  extraCss={{ whiteSpace: 'nowrap' }}>
                  {ORDER_STATUSES[status.name]}
                </StatusLarge>
              </div>
            }>
            <span css={{ [s.xs]: { fontSize: '0.75rem' } }}>
              {dateFc(
                status.createdAt.split(' ')[0],
                status.createdAt.split(' ')[1]
              ).slice(0, -3)}
            </span>
          </Row>
        ))}
      </Collapsable>
    </Grid>
  )
}

export default OrderStatuses
