import { useState } from 'react'


export default () => {
  const [availableHours, setAvailableHours] = useState(initAvailableHours)

  const handleAvailableHours = (e, type) => {
    setAvailableHours({
      ...availableHours,
      [e.target.name]: {
        ...availableHours[e.target.name],
        [type]: parseInt(e.target.value, 10)
      }
    })
  }

  return {
    availableHours,
    setAvailableHours,
    handleAvailableHours
  }
}

export const initAvailableHours = {
  mon: {
    open: 600,
    close: 1440
  },
  tue: {
    open: 600,
    close: 1440
  },
  wed: {
    open: 600,
    close: 1440
  },
  thu: {
    open: 600,
    close: 1440
  },
  fri: {
    open: 600,
    close: 1440
  },
  sat: {
    open: 600,
    close: 1440
  },
  sun: {
    open: 600,
    close: 1440
  },
}