/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useActiveOrdersContext } from '../context/orders-active.context'



export default () => {
  const { note } = useActiveOrdersContext()

  return note && (
    <div css={{
      margin: '8px 0',
      border: '1px solid #777',
      borderRadius: 8,
      padding: 8
    }}>
      <h3 css={{ marginBottom: 8 }}>Notatka do zamówienia.</h3>
      <p>{note}</p>
    </div>
  )
}