/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core'
import { Collapsable } from '../../components/Elements'
import { krakenUrls } from '../../constants/s3-files'

const ImagePicker = ({
  title,
  desc,
  image,
  imageUrl,
  left,
  right,
  name,
  onChange,
}) => {
  return (
    <Grid item xs={12} md={6} css={{ position: 'relative' }}>
      <Collapsable
        left={left}
        right={right}
        noPadding
        oneLiner
        title={title}
        image={image || krakenUrls.small + imageUrl}
        description={desc}></Collapsable>
      <input
        accept='image/*'
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          appearance: 'none',
          opacity: 0,
        }}
        id={'contained-button-'.concat(title)}
        multiple
        type='file'
        name={name}
        onChange={onChange}
      />
    </Grid>
  )
}

export default ImagePicker
