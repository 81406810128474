import React from 'react'

import Creatable from '../../../components/autocompletes/Creatable'
import { categoryTypes } from '../../../../constants/types'

import { useRestaurantContext } from '../context/restaurant.provider'


export default () => {
  const {
    categories,
    asyncCategories,
    handleCategories
  } = useRestaurantContext()

  return (
    <Creatable
      label="Kategorie"
      placeholder="Kategorie"
      stateArr={categories}
      setStateArr={handleCategories}
      data={asyncCategories?.data?.categories || []}
      language="pl"
      types={categoryTypes}
    />
  )
}