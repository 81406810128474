import { useState } from 'react'
import { toast } from 'react-toastify'
import { useError } from '../../../hooks/errors.hook'

import useRestaurantsMutations from '../../hooks/useRestaurants.mutations'
import { useGetRestaurantsByName } from '../../hooks/useRestaurants.queries'

const useChangeRestaurantOwner = () => {
  const { errorHandling } = useError()
  const { handleChangeRestaurantOwner } = useRestaurantsMutations()
  const [getRestaurant, { data, loading, error }] = useGetRestaurantsByName()

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [changeOwnerData, setChangeOwnerData] = useState({
    restId: '',
    toUserId: '',
  })

  if (error) errorHandling(error)

  const onRestaurantChoose = (restId) => {
    setIsPopupOpen(true)
    setChangeOwnerData({ ...changeOwnerData, restId })
  }

  const onPopupClose = () => {
    setIsPopupOpen(false)
    setChangeOwnerData({ restId: '', toUserId: '' })
  }

  const onChoseOwner = (toUserId) => {
    setChangeOwnerData({ ...changeOwnerData, toUserId })
  }

  const onResetOwner = () => {
    setChangeOwnerData({ ...changeOwnerData, toUserId: '' })
  }

  const onChangeOwner = async () => {
    try {
      const { restId, toUserId } = changeOwnerData

      if (!restId || !toUserId) {
        toast.warning('Wybierz właściciela restauracji')
        return
      }

      const success = await handleChangeRestaurantOwner(restId, toUserId)

      if (success) {
        toast.success('Właściciel restauracji został zmieniony')
        setIsPopupOpen(false)
      }
    } catch (err) {
      errorHandling(error)
    }
  }

  return {
    getRestaurant,
    data,
    loading,
    onRestaurantChoose,
    isPopupOpen,
    onPopupClose,
    onChangeOwner,
    onChoseOwner,
    onResetOwner,
  }
}

export default useChangeRestaurantOwner
