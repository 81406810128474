/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import Spinner from '../../../components/Spinner'
import useUserMutations from '../../hooks/useUser.mutations'
import { Input } from './components'
import { ezwRoutePrefix } from '../../../EZWaiter/pages/settings'
import { useApolloClient } from '@apollo/client'
import { Grid, Hidden } from '@material-ui/core'
import Headline from '../../../components/Headline/Headline'
import {
  sGrid,
  sOuter,
  sDescription,
  sContainerOuter,
  sPaddingTop,
  sBottomLinks,
} from '../../../style/style'
import { ButtonBorder, Button } from '../../../components/Elements'
import { s } from '../../../style'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'

const initValues = {
  email: '',
  password: '',
}

export default function () {
  const [isLoading, setIsLoading] = useState(false)
  const [inputValues, setInputValues] = useState(initValues)
  const { handleLogin } = useUserMutations()
  const client = useApolloClient()

  const onChange = (e) =>
    setInputValues({ ...inputValues, [e.target.name]: e.target.value })

  const onSubmit = () => {
    setIsLoading(true)
    handleLogin(inputValues, ({ userLogin }) => {
      if (userLogin) {
        console.log('Apollo Client Reset Login!')
        client.cache.reset()
        client.clearStore()
        client.resetStore()
      }
      return setIsLoading(false)
    })
  }
  const onEnterSubmit = (e) => e.key === 'Enter' && onSubmit()

  return (
    <React.Fragment>
      <TopNav title={'Logowanie'} offset={60} />
      <div css={sOuter}>
        <Headline
          itemChildren={
            <ButtonBorder link={`/signup`}>Zarejestruj</ButtonBorder>
          }>
          Logowanie
        </Headline>
        <Grid container css={sGrid}>
          <p css={sDescription}>
            Zaloguj się do swojego konta Eatzon i poznaj kulinarny świat
          </p>
        </Grid>
      </div>

      <Grid container css={[sContainerOuter, sGrid, sPaddingTop]}>
        <div css={[sFormContainer]}>
          <Input
            onChange={onChange}
            name='email'
            type='email'
            value={inputValues.email}
            placeholder='Email'
            onKeyUp={onEnterSubmit}
          />

          <Input
            onChange={onChange}
            name='password'
            value={inputValues.password}
            type='password'
            placeholder='Hasło'
            onKeyUp={onEnterSubmit}
          />

          <Button w100 action={isLoading ? () => {} : onSubmit}>
            {isLoading ? (
              <Spinner size={1.5} addCss={{ color: '#fff' }} />
            ) : (
              'Zaloguj'
            )}
          </Button>
        </div>
        <Grid
          item
          xs={12}
          css={[sBottomLinks, { [s.sm_down]: { justifyContent: 'flex-end' } }]}>
          <Hidden smDown>
            <ButtonBorder link={`/signup`}>Załóż Konto</ButtonBorder>
          </Hidden>
          <ButtonBorder link='/reset-password'>
            Nie pamiętasz hasła?
          </ButtonBorder>
          {/* <ButtonBorder link='/kelner'>Waiter Legacy</ButtonBorder> */}
          <ButtonBorder link='/waiter'>Panel Kelnera</ButtonBorder>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const sFormContainer = {
  [s.sm_down]: { paddingBottom: '2rem' },
  paddingBottom: '1.5rem',
}
