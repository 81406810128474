/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useError } from '../../../hooks/errors.hook'
import { useGeolocationContext } from '../../context/Geolocation/Geolocation.provider'
import GooglePlacesAutocomplete, {
  usePlacesAutocomplete,
} from '../../../components/GooglePlacesAutocomplete'
import { s, globals } from '../../../style'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { Button, Collapsable } from '../../../components/Elements'
import LocationSearchingIcon from '@material-ui/icons/LocationSearching'

const RestNearYou = () => {
  const { errorHandling } = useError()
  const {
    geolocation,
    setGeolocation,
    // isLoading,
    handleLocation,
  } = useGeolocationContext()
  const { onSelectAddress } = usePlacesAutocomplete()

  const handleSelectAddress = (address) => {
    onSelectAddress(address)
      .then(({ lng, lat }) => {
        setGeolocation({ address, lng, lat })
        setIsOpen(false)
      })
      .catch((err) => errorHandling(err))
  }
  const onChangeAddres = (address) =>
    setGeolocation({ ...geolocation, address })

  const [isOpen, setIsOpen] = useState(false)

  // console.log(geolocation.address)

  return (
    <Collapsable
      title={`Lokalizacja`}
      description={geolocation.address}
      icon={<LocationOnIcon />}
      parentIsOpen={isOpen}
      parentSetIsOpen={setIsOpen}
      gray200
      sOuterCss={{
        padding: '2rem 1.5rem',
        maxWidth: globals.maxWidthMedium,
        margin: '0 auto',
        [s.md]: {
          padding: '4rem 1.5rem 1rem',
        },
      }}>
      <React.Fragment>
        <div css={sDescription}>
          <h2 css={sDescription.title}>Znajdź restauracje blisko siebie</h2>
          <p css={sDescription.text}>
            Wpisz dowolny adres lub zezwól na udostępnienie lokalizacji, a my
            wybierzemy dania oraz restauracje w Twojej okolicy. Wymarzona może
            być tuż za rogiem!
          </p>
        </div>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            [s.md]: {
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginTop: '1rem',
            },
            [s.sm_down]: {
              paddingBottom: '0.5rem',
            },
          }}>
          <div
            css={{
              width: '100%',
              [s.md]: { paddingRight: '1rem' },
              [s.xs]: {
                margin: '1rem 0 0',
              },
            }}>
            <GooglePlacesAutocomplete
              value={geolocation.address}
              onChange={onChangeAddres}
              onSelect={handleSelectAddress}
              textFieldCss={{
                border: 'none',
                input: {
                  padding: 16,
                  backgroundColor: '#E4E6EB',
                  fontWeight: 800,
                  fontSize: 14,
                  borderRadius: 24,
                },
                div: {
                  '::before, ::after': { display: 'none' },
                },
              }}
            />
          </div>
          <Button
            w100
            action={() => {
              handleLocation(true)
              setIsOpen(false)
            }}>
            <LocationSearchingIcon
              fontSize='small'
              css={{ marginRight: '0.5rem' }}
            />
            Lokalizuj
          </Button>
        </div>
      </React.Fragment>
    </Collapsable>
  )
}

const sDescription = {
  margin: '0 1rem 0',
  padding: '1rem 0 1rem',
  [s.md]: {
    margin: '0 0 0',
    padding: '0 1rem 1rem',
  },
  title: {
    fontSize: '1.25rem',
    letterSpacing: '-0.025em',
    marginBottom: '0.5rem',
    [s.md]: { fontSize: '1.5rem' },
  },
  text: {
    lineHeight: '1.618em',
    fontSize: '0.75rem',
    [s.md]: { fontSize: '0.875rem' },
    opacity: 0.6,
  },
}

export default RestNearYou
