import { gql } from '@apollo/client'

export const GET_ANY_ORDER_MESSAGES = gql`
  query anyOrderMessages($orderId: ID!) {
    anyOrderMessages(orderId: $orderId) {
      _id
      message
      author
      fromUserId
      fromRestId
      createdAt
    }
  }
`

export const GET_ACTIVE_ORDER_MESSAGES = gql`
  query orderMessages($orderId: ID!) {
    orderMessages(orderId: $orderId) {
      _id
      message
      author
      fromUserId
      fromRestId
      createdAt
    }
  }
`

export const GET_FINISHED_ORDER_MESSAGES = gql`
  query finishedOrderMessages($orderId: ID!) {
    finishedOrderMessages(orderId: $orderId) {
      _id
      message
      author
      fromUserId
      fromRestId
      createdAt
    }
  }
`

export const GET_DECLINED_ORDER_MESSAGES = gql`
  query declinedOrderMessages($orderId: ID!) {
    declinedOrderMessages(orderId: $orderId) {
      _id
      message
      author
      fromUserId
      fromRestId
      createdAt
    }
  }
`

export const GET_IMG_URL = gql`
  mutation getS3ImgUrlLink($type: String!) {
    getS3ImgUrlLink(type: $type) {
      imgName
      url
    }
  }
`

export const POST_MSSG_TO_ORDER = gql`
  mutation orderCreateNewMessage($orderId: ID!, $message: String!) {
    orderCreateNewMessage(orderId: $orderId, message: $message) {
      success
    }
  }
`
