/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



export default ({
  isOpen,
  onClose,
  onMainBtn,
  onSecondaryBtn,

  textHeader,
  textContent,
  textMainBtn,
  textSecondaryBtn
}) => (

  <Dialog
    open={isOpen}
    onClose={onClose}
  >
    <DialogTitle>{textHeader}</DialogTitle>
    <DialogContent>
      {
        typeof textContent === 'string'
          ? (
            <DialogContentText>
              {textContent}
            </DialogContentText>
          )
          : textContent
      }
    </DialogContent>
    <DialogActions>
      <Button onClick={onSecondaryBtn} color="primary">
        {textSecondaryBtn}
      </Button>
      <Button onClick={onMainBtn} color="primary" variant="contained">
        {textMainBtn}
      </Button>
    </DialogActions>
  </Dialog>
)