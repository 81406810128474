/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { useProductsContext } from '../context/products.provider'
import { useTopNavContext } from '../../../../context/topnav.context'
import CloseIcon from '@material-ui/icons/Close'

import {
  sButtonSearch,
  sOuter2,
  sGrid2,
  sInput,
  sButtonReset,
} from '../../../../EZC/views/Search/SearchInput'

import { sGrid, sOuter } from '../../../../style/style'
import { Input } from '../../../../EZC/views/Auth/components'
import { ButtonBase } from '@material-ui/core'

export default () => {
  const { searchString, onSearchString } = useProductsContext()

  const { scrolled } = useTopNavContext()
  const [focus, setFocus] = useState(false)

  return (
    <div css={[sOuter, sOuter2]}>
      <div
        css={[
          sGrid,
          sGrid2,
          scrolled && { width: 'calc(100% - 3.5rem)' },
          focus && { width: '100%' },
        ]}>
        <div css={{ position: 'relative', top: 0, left: 0, width: '100%' }}>
          <Input
            onFocus={() => setFocus(true)}
            onMouseLeave={() => setFocus(false)}
            onChange={onSearchString}
            type='text'
            value={searchString}
            placeholder='Szukaj'
            sBaseExtra={{ margin: 0, zIndex: 1002 }}
            sInputExtra={[sInput]}
          />
          <ButtonBase
            css={[
              sButtonSearch,
              sButtonReset,
              !searchString && { opacity: 0, pointerEvents: 'none' },
              { right: 0 },
            ]}
            onClick={() => onSearchString({ target: { value: '' } })}>
            <CloseIcon fontSize='inherit' />
          </ButtonBase>
        </div>
      </div>
    </div>
  )
}
