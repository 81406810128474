/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { useAuthContext } from '../../../context/auth.context'

import AllCartOrders from './views/AllCartOrders'
import AllCartOrdersNoAuth from './views/AllCartOrdersNoAuth'
import Headline from '../../../components/Headline/Headline'

import { Grid } from '@material-ui/core'
import { sBottomLinks, sOuter, sGrid } from '../../../style/style'
import { ButtonBorder } from '../../../components/Elements'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'

// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
// import { GET_USER } from '../../graphql/user.graphql'
// import { useQuery } from '@apollo/client'

// import { ArrowBackConteiner } from '../../../components/Containers'

const Cart = () => {
  const { isAuth } = useAuthContext()
  // const { data, loading } = useQuery(GET_USER)

  // return <div>Koszyk</div>

  return (
    <div>
      <TopNav title={'Koszyk'} />
      <div css={sOuter}>
        <Headline>Koszyk</Headline>
        <Grid container css={sGrid}>
          {isAuth ? <AllCartOrders /> : <AllCartOrdersNoAuth />}
          <Grid item xs={12} css={sBottomLinks}>
            <ButtonBorder link={'/orders'}>Zamówienia</ButtonBorder>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Cart
