/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import {
  ProductList
} from '../../../components'
import { useActiveOrdersContext } from '../context/orders-active.context'
import HeaderCheck from './HeaderCheck'


const FinishedProducts = () => {
  const {
    orderDetails
  } = useActiveOrdersContext()

  const finishedBad = orderDetails[0]?.productsFinishedBad || []
  const declined = orderDetails[0]?.productsDeclined || []
  const doneProducts = [...finishedBad, ...declined]

  return (
    <React.Fragment>
      <HeaderCheck condition={doneProducts.length}>Anulowane produkty</HeaderCheck>
      <ProductList products={doneProducts} />
    </React.Fragment>
  )
}

export default FinishedProducts