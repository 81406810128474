/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

// import Layout from '../../../components/Layout'
import AdminChatAdmin from '../../../views/Chat/AdminChatAdmin.view'

const EzChatAdmin = () => {
  return (
    // <Layout>
    <AdminChatAdmin />
    // </Layout>
  )
}

export default EzChatAdmin
