import { useState } from 'react'
import { toast } from 'react-toastify'
import { useError } from '../../../hooks/errors.hook'

import useUsersMutations from '../../hooks/useUsers.mutations'
import { useGetUserByEmail } from '../../hooks/useUsers.queries'

const useMakeRestaurateur = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { errorHandling } = useError()
  const { handleChangeIsRestaurateur } = useUsersMutations()
  const [getUser, { data, loading, error, refetch }] = useGetUserByEmail()

  if (error) errorHandling(error)

  const onChangeRole = async () => {
    try {
      setIsLoading(true)
      const { _id, isRestaurateur } = data.adminGetUserByEmail
      const noError = await handleChangeIsRestaurateur(_id, !isRestaurateur)

      if (noError) {
        toast.info('Rola użytkownika została zmieniona')
        await refetch()
      }

      setIsLoading(false)
    } catch (err) {
      errorHandling(error)
      setIsLoading(false)
    }
  }

  return {
    getUser,
    data,
    loading,
    isLoading,
    onChangeRole,
  }
}

export default useMakeRestaurateur
