import { cookMethods, flavors } from '../../../../constants/types';

const flavorsTypesKeys = Object.keys(flavors)
const cookMethodsTypes = Object.keys(cookMethods)

function queryCreator(searchConfig) {
  if (!searchConfig) return

  try {
    const configArray = searchConfig
      .substring(1)
      .split('&')

    let prodFilter = undefined
    let query = undefined

    configArray.forEach(el => {

      const valueArr = el.split('=')
      const type = valueArr[0]
      const value = valueArr[1]
      if (!value) return

      if (type === 'query') {
        query = decodeURI(value)

      } else {
        if (!prodFilter) prodFilter = {}

        if (type.includes('price')) {
          const floatValue = parseFloat(value)
          if (Number.isNaN(floatValue)) return
          addFloatValueToFilter(type, prodFilter, floatValue, 'price')
          return
        }

        if (type.includes('cookTime')) {
          const floatValue = parseFloat(value)
          if (Number.isNaN(floatValue)) return
          addFloatValueToFilter(type, prodFilter, floatValue, 'cookTime')
          return
        }

        if (type.includes('categories')) {
          const arrValue = filterIdString(value)
          addStrValueToFilter(type, prodFilter, arrValue, 'categories')
          return
        }

        if (type.includes('ingredients')) {
          const arrValue = filterIdString(value)
          addStrValueToFilter(type, prodFilter, arrValue, 'ingredients')
          return
        }

        if (type.includes('flavors')) {
          const arrValue = filterFlavorsString(value)
          addStrValueToFilter(type, prodFilter, arrValue, 'flavors')
          return
        }

        if (type.includes('cookMethod')) {
          const arrValue = filterCookMethodsString(value)
          addStrValueToFilter(type, prodFilter, arrValue, 'cookMethod')
          return
        }
      }

    })

    return {
      query,
      prodFilter
    }

  } catch (err) {
    console.log(err)
  }
}


function filterIdString(str) {
  const array = str.split(',')
  return array.filter(isStringAnID)
}
function isStringAnID(_id) {
  return _id.length === 24
}

function filterFlavorsString(str) {
  const array = str.split(',')
  return array.filter(isFlavorType)
}
function isFlavorType(str) {
  return flavorsTypesKeys.includes(str)
}

function filterCookMethodsString(str) {
  const array = str.split(',')
  return array.filter(isCookMethodType)
}
function isCookMethodType(str) {
  return cookMethodsTypes.includes(str)
}


function addStrValueToFilter(type, prodFilter, value, key) {

  if (!type.includes('MustNot')) prodFilter[key] = prodFilter[key]
    ? { ...prodFilter[key], must: value }
    : { must: value }

  else prodFilter[key] = prodFilter[key]
    ? { ...prodFilter[key], mustNot: value }
    : { mustNot: value }
}

function addFloatValueToFilter(type, prodFilter, value, key) {

  if (type.includes('Min')) prodFilter[key] = prodFilter[key]
    ? { ...prodFilter[key], min: value }
    : { min: value }

  else prodFilter[key] = prodFilter[key]
    ? { ...prodFilter[key], max: value }
    : { max: value }
}


export default queryCreator