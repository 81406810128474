/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import SearchWrapper from './SearchWrapper'
import { useLocation } from 'react-router-dom'
import SkeletonOrder from '../../../components/SkeletonOrder/SkeletonOrder'
import endWordHelper from '../../../helpers/endWord.helper'

import { useSearch } from '../../hooks/useUser.queries'
import { useError } from '../../../hooks/errors.hook'
import Carousel, {
  sButtonSmall,
} from '../../components/Carousel/Carousel.component'
import { RestItemLarge } from '../../components/RestItem'
import { ProdItemMenuList, ProdItemMini } from '../../components/ProdItem'
import { ButtonBase, Grid } from '@material-ui/core'
import { sOuter, sGrid, sDescription } from '../../../style/style'
import { colors, globals, s } from '../../../style'

import queryCreator from './helpers/queryCreator.search'
import CarouselViewIcon from '@material-ui/icons/ViewModule'
import ListViewIcon from '@material-ui/icons/ViewStream'

const SearchResults = () => {
  const { errorHandling } = useError()
  const searchConfig = useLocation().search
  const searchQuery = queryCreator(searchConfig)
  const { data, loading, error } = useSearch(searchQuery)

  const isReady = !loading && !error
  const restaurants = data?.ezcSearch.restaurants
  const products = data?.ezcSearch.products

  const isNoResults = !(
    (restaurants && restaurants.length) ||
    (products && products.length)
  )

  // console.log(data)

  return (
    <SearchWrapper
      headline={/*searchQuery.query ? searchQuery.query :*/ 'Szukaj'}>
      {isReady ? (
        isNoResults ? (
          <div css={[sGrid, sMaxWidth]}>
            <p css={sDescription}>
              Brak wyników dla podanych filtrów. Spróbuj zmienić ustawienia.
            </p>
          </div>
        ) : (
          <React.Fragment>
            {restaurants && <RenderRestaurants data={restaurants} />}
            {products && <RenderProducts data={products} />}
            <div css={sPaddinBottom} />
          </React.Fragment>
        )
      ) : (
        <Grid container css={[sGrid, sGrid2]} justify='space-between'>
          <SkeletonOrder />
          <SkeletonOrder />
          <SkeletonOrder />
          <SkeletonOrder />
        </Grid>
      )}
    </SearchWrapper>
  )
}

const RenderRestaurants = ({ data }) => {
  const L = data.length

  if (L === 0) return null

  return (
    <div css={sRenderContainer}>
      <div css={[sGrid, sGrid2, sGrid3]}>
        <div css={[sDescription, sDescription2]}>
          <span>{endWordHelper('wynik', L)}</span> w restauracjach
        </div>
      </div>
      <div css={sMaxWidth}>
        <Grid css={[sGrid, sRestList]} container>
          {data.map((restaurant, id) => {
            return (
              <RestItemLarge
                key={id}
                id={id + 1}
                data={restaurant}
                searchView
              />
            )
          })}
        </Grid>
      </div>
    </div>
  )
}

const RenderProducts = ({ data }) => {
  const L = data.length

  const [listView, setListView] = useState(false)
  if (L === 0) return null
  return (
    <div css={sRenderContainer}>
      <div css={[sGrid, sGrid2, sGrid3]}>
        <div css={[sDescription, sDescription2]}>
          <span>{endWordHelper('wynik', L)}</span> w produktach
        </div>
        <ButtonBase css={sButtonSmall} onClick={() => setListView(!listView)}>
          {listView ? (
            <CarouselViewIcon fontSize='inherit' />
          ) : (
            <ListViewIcon fontSize='inherit' />
          )}
        </ButtonBase>
      </div>
      <div css={sMaxWidth}>
        {data.length > 21 && !listView ? (
          <Render7Products
            data={data}
            CarouselItem={ProdItemMini}
            link='/product/'
          />
        ) : (
          <Grid css={[sGrid, sProdList]} container>
            {data.map((product, id) => {
              return (
                <ProdItemMenuList key={id} id={id} data={product} searchView />
              )
            })}
          </Grid>
        )}
      </div>
    </div>
  )
}

const Render7Products = ({ data, CarouselItem }) => {
  if (data.length <= 8)
    return (
      <Carousel
        data={data}
        scrollButtons={182 * 3}
        CarouselItem={CarouselItem}
        link='/product/'
      />
    )

  return (
    <React.Fragment>
      <Carousel
        scrollButtons={182 * 3}
        data={data.slice(0, 8)}
        CarouselItem={CarouselItem}
        link='/product/'
      />
      <Render7Products
        data={data.slice(8)}
        scrollButtons={182 * 3}
        CarouselItem={CarouselItem}
      />
    </React.Fragment>
  )
}

const sDescription2 = {
  [s.sm_down]: { paddingLeft: '1rem', paddingBottom: '2rem' },
  [s.md]: { paddingLeft: '2rem', paddingBottom: '2rem' },
}

const sTitle = {
  fontSize: '1.25rem',
  fontWeight: 800,
  letterSpacing: '-0.05em',
  margin: '0 0.5rem 1.5rem',
  [s.md]: {
    marginBottom: '2rem',
  },
  span: {
    color: colors.gray400,
    letterSpacing: '0.025em',
    fontSize: '0.875rem',
    marginLeft: '0.5rem',
  },
}

const sGrid2 = {
  maxWidth: globals.maxWidthMedium,
  margin: '0 auto',
}

const sGrid3 = {
  display: 'flex',
  justifyContent: 'space-between',
  aligItems: 'center',
}

const sMaxWidth = {
  maxWidth: globals.maxWidthMediumCarousel,
  margin: '0 auto',
}

const sProdList = { [s.md]: { padding: '0 1rem' } }

const sRestList = {
  display: 'flex',
  flexWrap: 'wrap',
  [s.md]: { padding: '0 1rem' },
  [s.sm_down]: {
    flexDirection: 'column',
  },
}

const sPaddinBottom = {
  paddingBottom: '10rem',
}

const sRenderContainer = {
  paddingBottom: '3rem',
  [s.md]: {
    paddingBottom: '4rem',
  },
}

export default SearchResults
