/** @jsx jsx */
import React, { useContext } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { NavLink } from 'react-router-dom'

import NotificationsIcon from '@material-ui/icons/Notifications'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import LocalDiningIcon from '@material-ui/icons/LocalDining'
import PersonIcon from '@material-ui/icons/Person'
import HomeIcon from '@material-ui/icons/Home'
import HistoryIcon from '@material-ui/icons/History'
import ButtonBase from '@material-ui/core/ButtonBase'
import { Badge } from '../../components/Elements'
import { colors, s, alpha } from '../../style'
import { useWaiterAuthContext } from '../context/auth-waiter.context'
import { useOrdersContext } from '../context/orders.context'

export default function LabelBottomNavigation() {
  const { waiterIsAuth } = useWaiterAuthContext()
  const { activeOrders, attentionOrders } = useOrdersContext()
  const ordersL = activeOrders?.length
  const activeL = attentionOrders?.length
  const bothL = activeOrders?.concat(attentionOrders).length

  return (
    <React.Fragment>
      <ButtonBase
        css={[sNav, !waiterIsAuth && { transform: 'translateY(100%)' }]}>
        {waiterIsAuth && (
          <div css={sContainer}>
            <NavLink to='/waiter/notifications' activeStyle={sActiveNavLink}>
              <NotificationsIcon fontSize='inherit' color='inherit' />
              <Badge
                color={colors.orange}
                show={Boolean(activeL)}
                showAnimation>
                {activeL}
              </Badge>
            </NavLink>
            <NavLink to='/waiter/orders' activeStyle={sActiveNavLink}>
              <LocalDiningIcon fontSize='inherit' color='inherit' />
              <Badge color={colors.gray400} show={Boolean(bothL)} showAnimation>
                {bothL}
              </Badge>
            </NavLink>
            <NavLink to='/waiter/history' activeStyle={sActiveNavLink}>
              <HistoryIcon fontSize='inherit' color='inherit' />
            </NavLink>
            <NavLink to='/waiter' exact activeStyle={sActiveNavLink}>
              <HomeIcon fontSize='inherit' color='inherit' />
            </NavLink>
          </div>
        )}
      </ButtonBase>
    </React.Fragment>
  )
}

const sNav = {
  transition: 'transform 0.3s cubic-bezier(0.25, 1, 0.5, 1)',
  boxSizing: 'border-box',
  position: 'fixed',
  bottom: 0,
  left: 0,
  zIndex: 1100,
  width: '100vw',
  height: 72,
  backgroundColor: '#ffffff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '2rem 2rem 0 0',
  boxShadow: `0px -4px 16px ${colors.text.concat(alpha[8])}`,
  color: colors.orange,
  cursor: 'default',

  [s.sm]: {
    boxShadow: `0px -4px 64px ${colors.text.concat(alpha[8])}`,
  },

  '.active:hover': {
    [s.hover]: {
      filter: 'saturate(150%) contrast(120%)',
    },
  },

  '.MuiTouchRipple-rippleVisible': {
    animationDuration: '1.2s',
  },
}

const sLink = {
  transition:
    'color 0.2s ease-out, background-color 0.2s ease-out, filter 0.2s ease-out',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 50,
  height: 50,
  color: colors.text,
  fontSize: 32,
  borderRadius: '50%',
  position: 'relative',

  '&:hover': {
    backgroundColor: colors.gray200,
    color: colors.text.concat(alpha[60]),
  },
}

const sContainer = {
  width: '100%',
  height: '100%',
  maxWidth: 960,
  display: 'flex',

  alignItems: 'center',
  // backgroundColor: '#fff',
  padding: '0 1.5rem',

  [s.xs]: { justifyContent: 'space-between' },
  [s.sm]: { justifyContent: 'space-evenly' },

  a: { ...sLink },
}

const sActiveNavLink = {
  backgroundColor: colors.orange150,
  color: colors.orange,

  '&:hover': {
    filter: 'brightness(120%) contrast(1.5)',
  },
}
