/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { daysOfWeek, numberOfDayJS } from '../../../../constants/daysOfWeek'
import { breakpoint } from '../../../../constants/style'


/*
  1. We are getting openningHours in format like: { mon: { open: 600, close: 1440 }, ... }
  2. Then, we getting today's day: new Date().getDay(). Convert it to String, like: 'mon'.
  3. Finding todays, tomarrows openning/close hours
  4. Sending this information next.
*/

const OpenningHours = ({ openningHours, delivery }) => {
  const day = new Date().getDay()
  const todaysDay = numberOfDayJS[day]
  const todaysHours = openningHours[todaysDay]
  const todayOpen = hourConverter(todaysHours.open)
  const todayClose = hourConverter(todaysHours.close)
  const tomorrowDay = day === 6 ? numberOfDayJS[0] : numberOfDayJS[day + 1]
  const tomorrowOpen = hourConverter(openningHours[tomorrowDay].open)

  return (
    <div css={{
      margin: delivery ? undefined : '7.5rem 0 3rem',
      width: '50%',
      [breakpoint.sm]: { width: '100%', margin: delivery ? undefined : '1.5rem 0 3rem' }
    }}>
      {!delivery && (
        <p css={{
          color: '#9FA2A8',
          fontSize: 24,
          fontWeight: 300,
          marginBottom: 24,
          display: 'none',
          [breakpoint.sm]: { display: 'block' }
        }}>
          Godziny Otwarcia
        </p>
      )}
      <div css={delivery ? {} : {
        backgroundColor: '#FFFFFF',
        borderRadius: 24,
        boxShadow: '0px 4px 16px 0px #00000014',
        padding: '16px 24px',
        marginRight: 24
      }}>
        {
          todayClose && <OpenInfo
            data={checkIsRestOpen(todaysHours)}
            todayOpen={todayOpen}
            todayClose={todayClose}
            tomorrowOpen={tomorrowOpen}
            delivery={delivery}
          />
        }
        {Object.entries(openningHours)
          .map(el => <OpenningHoursItem key={el[0]} data={el} todaysDay={todaysDay} />)}
      </div>
    </div>
  )
}


const OpenInfo = ({ data, todayOpen, todayClose, tomorrowOpen, delivery }) => {
  const i = data.isOpen
    ? todayClose
    : data.isToday
      ? todayOpen
      : tomorrowOpen

  return (
    <div css={{
      color: data.isOpen ? '#00B30F' : '#9FA2A8',
      fontSize: 20,
      fontWeight: 800,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 16
    }}>
      <p css={{ textTransform: 'uppercase' }}>
        {
          delivery
            ? data.isOpen ? "Dostępna" : "Niedostępna"
            : data.isOpen ? "Otwarta" : "Zamknięta"
        }
      </p>
      <p css={{ color: data.isOpen ? '#00B30F4D' : '#9FA2A84D' }}>
        <span css={{ fontSize: 14, marginRight: 4 }}>do</span>
        <span css={{ color: data.isOpen ? '#00B30F' : '#9FA2A8' }}>{i.split(":")[0]}</span>
      :{i.split(":")[1]}
      </p>
    </div>
  )
}

export const OpenningHoursItem = ({ data, todaysDay, isProd }) => {
  if (!daysOfWeek[data[0]]) return null
  const isToday = data[0] === todaysDay
  return (
    <div css={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 32,
      fontSize: 14,
      color: '#9FA2A8',
      fontWeight: isToday ? 800 : 300
    }}>
      <p>{daysOfWeek[data[0]]}</p>

      <HoursItem data={data[1]} isProd={isProd} />
    </div>
  )
}

const HoursItem = ({ data, isProd }) => {
  const open = hourConverter(data.open)
  const close = hourConverter(data.close)

  if (data.open === 0 && data.close === 1440) {
    if (isProd) return <p css={{ color: '#00B30F' }}>Dostępny</p>
    return <p css={{ color: '#00B30F' }}>Otwarta całodobowo</p>
  }

  if (data.open === 0 && data.close === 0) {
    if (isProd) return <p css={{ color: '#FF0000' }}>Niedostępny</p>
    return <p css={{ color: '#FF0000' }}>Zamknięta</p>
  }

  return <p>{open} - {close}</p>
}


export function hourConverter(hour) {
  // hour must be Int, 9:00 === 9 * 60, 15:00 === 15 * 60
  if (typeof hour !== 'number') return new Error('Invalid argument type')
  const a = hour / 60
  const hours = parseInt(a, 10)
  const minutes = hour % 60 || '00'
  return `${hours}:${minutes}`
}

function checkIsRestOpen(todaysHours) {
  const hours = new Date().getHours()
  const minutes = new Date().getMinutes()
  const i = hours * 60 + minutes
  if (todaysHours.open < i && i < todaysHours.close) return { isOpen: true }

  // if rest is close, we want to know will it open today or tomorrow.
  if (todaysHours.open > i) return { isOpen: false, isToday: true }
  if (todaysHours.close < i) return { isOpen: false, isToday: false }
}


export default OpenningHours