import React from 'react'
import LocalDiningIcon from '@material-ui/icons/LocalDining'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import DriveEtaIcon from '@material-ui/icons/DriveEta'
import LocalMallIcon from '@material-ui/icons/LocalMall'
import TransationNew from '@material-ui/icons/AddCircleOutline'
import TransactionConfirmed from '@material-ui/icons/CheckCircle'
import TransactionPending from '@material-ui/icons/Loop'

const ChairIcon = () => (
  <svg
    className='MuiSvgIcon-root MuiSvgIcon-fontSizeInherit'
    aria-hidden='true'
    focusable='false'
    data-prefix='fas'
    data-icon='chair'
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 448 512'>
    <path
      fill='currentColor'
      d='M112 128c0-29.5 16.2-55 40-68.9V256h48V48h48v208h48V59.1c23.8 13.9 40 39.4 40 68.9v128h48V128C384 57.3 326.7 0 256 0h-64C121.3 0 64 57.3 64 128v128h48zm334.3 213.9l-10.7-32c-4.4-13.1-16.6-21.9-30.4-21.9H42.7c-13.8 0-26 8.8-30.4 21.9l-10.7 32C-5.2 362.6 10.2 384 32 384v112c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V384h256v112c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V384c21.8 0 37.2-21.4 30.3-42.1z'></path>
  </svg>
)

export const flavors = {
  SWEET: { pl: 'słodki', en: 'sweet' },
  SOUR: { pl: 'kwaśny', en: 'sour' },
  SALTY: { pl: 'słony', en: 'salty' },
  BITTER: { pl: 'gorzki', en: 'bitter' },
  UMAMI: { pl: 'mięsny', en: 'umami' },
  PUNGENT: { pl: 'pikantny', en: 'pungent' },
  FATTY: { pl: 'oleisty', en: 'fatty' },
}

export const cookMethods = {
  ROAST: { pl: 'pieczenie (mięso)', en: 'roast' },
  BAKE: { pl: 'pieczenie (ciasto)', en: 'bake' },
  BOIL: { pl: 'gotowanie', en: 'boil' },
  STEAM: { pl: 'gotowanie na parze', en: 'steam' },
  FRY: { pl: 'smażenie', en: 'fry' },
  DEEPFRY: { pl: 'smażenie na głębokim oleju', en: 'deep fry' },
  GRILL: { pl: 'grillowannie', en: 'grill' },
  STEW: { pl: 'duszenie', en: 'stew' },
}

export const categoryTypes = {
  CUISINE: { pl: 'KUCHNIA', en: 'CUISINE' },
  MEALTYPE: { pl: 'DANIE', en: 'MEAL' },
  DAYTIME: { pl: 'PORA DNIA', en: 'FOOD TIME' },
  DIET: { pl: 'DIETA', en: 'DIET' },
  OTHER: { pl: 'INNE', en: 'OTHER' },
  POPULAR: { pl: 'POPULARNE', en: 'POPULAR' },
}

export const ingredientTypes = {
  MEAT: { pl: 'MIĘSA', en: 'MEATS' },
  FRUIT: { pl: 'OWOCE', en: 'FRUITS' },
  VEGETABLE: { pl: 'WARZYWA', en: 'VEGETABLES' },
  DAIRY: { pl: 'NABIAŁ', en: 'DAIRY' },
  SPICE: { pl: 'PRZYPRAWY', en: 'SPICES' },
  SAUCE: { pl: 'SOSY', en: 'SAUCES' },
  OTHER: { pl: 'INNE', en: 'OTHER' },
}

export const consumptionTypeTypes = {
  in: 'in',
  out: 'out',
  delivery: 'delivery',
}

export const consumptionTypeNames = {
  [consumptionTypeTypes.in]: 'Na miejscu',
  [consumptionTypeTypes.out]: 'Odbiór',
  [consumptionTypeTypes.delivery]: 'Dostawa',
}

export const consumptionTypeIcons = {
  [consumptionTypeTypes.in]: <ChairIcon fontSize='inherit' />,
  [consumptionTypeTypes.out]: <LocalMallIcon fontSize='inherit' />,
  [consumptionTypeTypes.delivery]: <DriveEtaIcon fontSize='inherit' />,
}

export const transactionTypeIcons = {
  NEW: <TransationNew fontSize='inherit' />,
  PENDING: <TransactionPending fontSize='inherit' />,
  CONFIRMED: <TransactionConfirmed fontSize='inherit' />,
}

export const transactionTypeNames = {
  NEW: 'Nowa',
  PENDING: 'Oczekująca',
  CONFIRMED: 'Zrealizowana',
}
