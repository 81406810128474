import React from 'react'

import AutocompleteInput from '../../../components/autocompletes/AutocompleteInput'
import { useProductContext } from '../context/product.provider'
import { flavors as flavorTypes } from '../../../../constants/types'


export default () => {
  const {
    flavors,
    handleFlavors
  } = useProductContext()

  return (
    <AutocompleteInput
      language="pl"
      options={flavorTypes}
      label="Smaki"
      placeholder="Smaki"
      freeSolo={false}
      stateArr={flavors}
      setStateArr={handleFlavors}
    />
  )
}