import { gql } from '@apollo/client'

// export const SUBS_ORDER_CHANGE = gql`
//   subscription ezrActiveOrderChange($restId: ID!) {
//     ezrActiveOrderChange(restId: $restId) {
//       message
//     }
//   }
// `

export const GET_ACTIVE_ORDERS = gql`
  query ezrOrdersActive($restId: ID!) {
    ezrOrdersActive(restId: $restId) {
      _id
      messages {
        fromRestId
        fromUserId
        isRead
      }
      ownerId {
        personal {
          name
          surname
          avatarUrl
        }
      }
      restId {
        _id
        name
        address
        avatarUrl
        # photoUrl
        # categories {
        #  type
        #  name
        # }
      }
      shortId
      consumptionTime
      consumptionDate
      consumptionType
      consumptionPersons
      deliveryAddress
      deliveryDetails
      waiterNote
      note
      isInvoice
      invoiceDetails {
        address
        city
        firmName
        nip
        zipCode
      }
      deliveryCost
      priceSum
      paidSum
      isPaid
      status {
        _id
        name
        createdAt
      }
      orderDetails {
        userId
        productsToOrder {
          _id
          prodId {
            _id
            photoUrl
          }
          amount
          price
          name
          status {
            name
            createdAt
          }
        }
        productsInProcess {
          _id
          prodId {
            _id
            photoUrl
          }
          amount
          price
          name
          status {
            name
            createdAt
          }
        }
        productsFinishedGood {
          _id
          prodId {
            _id
            photoUrl
          }
          amount
          price
          name
          status {
            name
            createdAt
          }
        }
        productsDeclined {
          _id
          prodId {
            _id
            photoUrl
          }
          amount
          price
          name
          status {
            name
            createdAt
          }
        }
        productsFinishedBad {
          _id
          prodId {
            _id
            photoUrl
          }
          amount
          price
          name
          status {
            name
            createdAt
          }
        }
      }
    }
  }
`

export const GET_FINISHED_ORDERS = gql`
  query ezrOrdersFinished(
    $restId: ID!
    $paginationInput: DatePaginationInput!
  ) {
    ezrOrdersFinished(restId: $restId, paginationInput: $paginationInput) {
      _id
      messages {
        fromRestId
        fromUserId
        isRead
      }
      ownerId {
        personal {
          name
          surname
          avatarUrl
        }
      }
      consumptionTime
      consumptionDate
      consumptionType
      consumptionPersons
      deliveryAddress
      deliveryDetails
      waiterNote
      note
      isInvoice
      invoiceDetails {
        address
        city
        firmName
        nip
        zipCode
      }
      deliveryCost
      priceSum
      paidSum
      isPaid
      shortId
      restId {
        _id
        name
        address
        avatarUrl
      }
      status {
        _id
        name
        createdAt
      }
      orderDetails {
        userId
        productsToOrder {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsInProcess {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsDeclined {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsFinishedGood {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsFinishedBad {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
      }
    }
  }
`

export const GET_FINISHED_ORDER = gql`
  query ezrOrderFinished($restId: ID!, $orderId: ID!) {
    ezrOrderFinished(restId: $restId, orderId: $orderId) {
      _id
      messages {
        fromRestId
        fromUserId
        isRead
      }
      ownerId {
        _id
        personal {
          name
          surname
          avatarUrl
        }
      }
      consumptionTime
      consumptionDate
      consumptionType
      consumptionPersons
      deliveryAddress
      deliveryDetails
      waiterNote
      note
      isInvoice
      invoiceDetails {
        address
        city
        firmName
        nip
        zipCode
      }
      deliveryCost
      priceSum
      paidSum
      isPaid
      shortId
      restId {
        _id
        name
        address
        avatarUrl
      }
      status {
        _id
        name
        createdAt
      }
      orderDetails {
        userId
        productsToOrder {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsInProcess {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsDeclined {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsFinishedGood {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsFinishedBad {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
      }
    }
  }
`

export const GET_DECLINED_ORDER = gql`
  query ezrOrderDeclined($restId: ID!, $orderId: ID!) {
    ezrOrderDeclined(restId: $restId, orderId: $orderId) {
      _id
      messages {
        fromRestId
        fromUserId
        isRead
      }
      ownerId {
        _id
        personal {
          name
          surname
          avatarUrl
        }
      }
      consumptionTime
      consumptionDate
      consumptionType
      consumptionPersons
      deliveryAddress
      deliveryDetails
      waiterNote
      note
      isInvoice
      invoiceDetails {
        address
        city
        firmName
        nip
        zipCode
      }
      deliveryCost
      priceSum
      paidSum
      isPaid
      shortId
      restId {
        _id
        name
        address
        avatarUrl
      }
      status {
        _id
        name
        createdAt
      }
      orderDetails {
        userId
        productsToOrder {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsInProcess {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsDeclined {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsFinishedGood {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsFinishedBad {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
      }
    }
  }
`

export const GET_DECLINED_ORDERS = gql`
  query ezrOrdersDeclined(
    $restId: ID!
    $paginationInput: DatePaginationInput!
  ) {
    ezrOrdersDeclined(restId: $restId, paginationInput: $paginationInput) {
      _id
      messages {
        fromRestId
        fromUserId
        isRead
      }
      ownerId {
        personal {
          name
          surname
          avatarUrl
        }
      }
      consumptionTime
      consumptionDate
      consumptionType
      consumptionPersons
      deliveryAddress
      deliveryDetails
      waiterNote
      note
      isInvoice
      invoiceDetails {
        address
        city
        firmName
        nip
        zipCode
      }
      deliveryCost
      priceSum
      paidSum
      isPaid
      shortId
      restId {
        _id
        name
        address
        avatarUrl
      }
      status {
        _id
        name
        createdAt
      }
      orderDetails {
        userId
        productsToOrder {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsInProcess {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsDeclined {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsFinishedGood {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
        productsFinishedBad {
          _id
          prodId {
            _id
            photoUrl
          }
          name
          price
          amount
          status {
            name
            createdAt
          }
        }
      }
    }
  }
`
