/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Grid, FormControlLabel, Switch } from '@material-ui/core'
import { Collapsable } from '../../../../../components/Elements'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff'
import { CollapsableText } from '../../../../../components/Elements/Collapsable/Collapsable'
// import CloseIcon from '@material-ui/icons/Close'
import { s, colors } from '../../../../../style'
const SettingsPush = ({ data, handleChangeUserSettings }) => {
  const [isOpen, setIsOpen] = useState(false)
  // const [settings, setSettings] = useState(data)
  // const { isSendingClientPushes: clientPush } = settings

  // const onSave = () =>
  //   handleChangeUserSettings({ ...settings }, () => setIsOpen(false))
  // const onReset = () => {
  //   setSettings(data)
  //   setIsOpen(false)
  // }
  // const onChange = (e) => {
  //   setSettings({
  //     ...settings,
  //     [e.target.name]: !settings[e.target.name],
  //   })
  // }

  const onChangeSave = (e) =>
    handleChangeUserSettings(
      { ...data, [e.target.name]: !data[e.target.name] }
      // () => setSettings({ [e.target.name]: !data[e.target.name] })
    )

  return (
    <Grid item xs={12} md={6}>
      <Collapsable
        left
        noPadding
        oneLiner
        title={'Push'}
        icon={
          data.isSendingClientPushes ? (
            <NotificationsActiveIcon />
          ) : (
            <NotificationsOffIcon />
          )
        }
        description={<>Powiadomienia aplikacji</>}
        parentIsOpen={isOpen}
        parentSetIsOpen={setIsOpen}>
        <CollapsableText>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={data.isSendingClientPushes}
                  onChange={onChangeSave}
                  name='isSendingClientPushes'
                  color='primary'
                />
              }></FormControlLabel>
            <span
              css={{
                fontWeight: 800,
                color: colors.text,
                fontSize: '0.875rem',
              }}>
              Powiadomienia Push Klienta
            </span>
          </div>
          {/* <div
            css={{
              display: 'flex',
              gap: '1rem',
              marginTop: '1.5rem',
              [s.md]: { gap: '2rem', marginTop: '2rem' },
            }}>
            <Button size48 dimmed action={onReset}>
              <CloseIcon />
            </Button>
            <Button w100 w100Desktop action={onSave}>
              Zapisz
            </Button>
          </div> */}
        </CollapsableText>
      </Collapsable>
    </Grid>
  )
}

export default SettingsPush
