/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { large } from './styles'
import { priceFn } from '../../../helpers/price.helper'
import { krakenUrls } from '../../../constants/s3-files'
import Spinner from '../../../components/Spinner'


const ProdItemMenu = ({ data, handleAddProduct, loading }) => {
  const { name, photoUrl, price, _id, ingredients, restaurant } = data

  // Displaying ingredients function
  const howManyIngredientsWeAreShowing = 3
  const o = howManyIngredientsWeAreShowing
  let ingrString = ''
  ingredients.forEach(({ name }, index) => {
    if (index >= o + 1) return
    if (index === o - 1) {
      ingrString = ingrString.concat(name)
    } else if (index === o) {
      ingrString = ingrString.concat('...')
    } else if (index + 1 < ingredients.length) {
      ingrString = ingrString.concat(name + ', ')
    }
  })

  return (
    <div css={[large.outer, { minWidth: 280, maxWidth: 280 }]}>
      <Link css={large.inner} to={`/product/${_id}`}>

        <ImgSection name={name} photoUrl={photoUrl} />
        <div css={large.bottomSection}>
          <div css={{ opacity: '.6', fontSize: 14 }}>
            {ingrString}
          </div>
          <Price
            price={price}
            onClick={e => handleAddProduct(
              e,
              _id,
              { _id, price, name, photoUrl },
              {
                _id: restaurant._id,
                name: restaurant.name,
                avatarUrl: restaurant.avatarUrl,
                address: restaurant.address
              }
            )}
            loading={loading}
          />
        </div>

      </Link>
    </div>
  )
}


const ImgSection = ({ name, photoUrl }) => (
  <div css={{ backgroundImage: `url(${krakenUrls.small + photoUrl})`, ...large.imgContainer }}>
    <p css={large.name}>
      {name}
    </p>
    <div css={large.gradient} />
  </div>
)

const Price = ({ price, onClick, loading }) => {
  const a = priceFn(price)
  const b = a ? a.split('.') : ['', '']

  return (
    <p
      onClick={onClick}
      css={[
        large.price,
        {
          border: '4px solid #ff3c0029',
          borderRadius: 16,
          padding: '4px 16px',
          marginLeft: 8,
          fontSize: 16,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#ff3c00',
          transition: 'all .1s ease-out',

          svg: { height: 16, width: 16, marginRight: 4 },
          span: { color: '#ff3c00', transition: 'all .1s ease-out' },

          "&:hover": {
            color: '#fff',
            backgroundColor: '#ff3c00',
            transition: 'all .1s ease-in',

            span: { color: '#fff', transition: 'all .1s ease-in' }
          }
        }
      ]}
    >
      {
        loading
          ? <Spinner size="1" />
          : <React.Fragment><AddCircleIcon />{b[0]}<span>.{b[1]}</span></React.Fragment>
      }
    </p>
  )
}

export default ProdItemMenu