/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Button } from '../../../../components/Elements'
import usePayment from '../../../views/Payment/hooks/usePayment'

const ButtonNeedPayment = ({ orderId, defaultValue, buttonTransparent }) => {
  const { amount, isLoading, onSubmit, onPayFromWallet } = usePayment({
    defaultValue,
    orderId,
  })

  return (
    <Button
      w100
      w100Desktop
      action={defaultValue > 0 ? onSubmit : onPayFromWallet}
      loading={isLoading}
      buttonTransparent={buttonTransparent}>
      {/* {defaultValue > 0
        ? `Opłać Zamówienie - ${amount.toFixed(2)} zł`
        : 'Opłać Zamówienie'} */}
      Opłać Zamówienie
    </Button>
  )
}

export default ButtonNeedPayment
