/** @jsx jsx */
import React, { useState, useContext } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
// import Layout from '../../components/Layout'
import ResetPasswordView from '../../views/Auth/ResetPassword.view'

export default function () {
  return (
    // <Layout>
    <ResetPasswordView />
    // </Layout>
  )
}
