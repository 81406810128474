/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { ezrmRoutePrefix } from '../../settings'
import MoneyEz from '../../../../helpers/MoneyEz'
import { krakenUrls } from '../../../../constants/s3-files'



export default ({ products, onDeleteProduct }) =>
  <TableContainer css={{ width: '100%' }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Zdjęcie</TableCell>
          <TableCell>Nazwa</TableCell>
          <TableCell align="right">Cena</TableCell>
          <TableCell>Kategorie</TableCell>
          <TableCell>Strona produktu</TableCell>
          <TableCell align="center">Usuń z Sekcji</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {products.map(product => (
          <TableRow key={product._id}>
            <TableCell>
              {product.photoUrl
                && <img src={krakenUrls.small + product.photoUrl} alt={product.name} css={{ width: 50, borderRadius: 4 }} />
              }
            </TableCell>
            <TableCell component="th" scope="row">
              {product.name}
            </TableCell>
            <TableCell align="right">
              {MoneyEz(product.price).getAmountWithFullPrecisionAndZl()}
            </TableCell>
            <TableCell>{product.categories.map(el => el.name).join(', ')}</TableCell>
            <TableCell>
              <Link
                to={ezrmRoutePrefix + '/product/' + product._id}
                css={{ "&:hover": { borderBottom: '1px solid #444' } }}
              >Więcej</Link>
            </TableCell>
            <TableCell align="center">
              <IconButton onClick={() => onDeleteProduct(product._id)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>