const xs = '@media screen and (max-width: 576px)'
const sm = '@media screen and (max-width: 991.99999px)'
const md = '@media screen and (min-width: 992px)'
const hover = '@media (hover:hover) and (pointer: fine)'
const touch = '@media (hover: none)'

export const breakpoint = {
  xs,
  sm,
  md,
  touch,
  hover,
}