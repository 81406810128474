/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { useOrderInCartByRestaurant } from '../../context/OrderInCartByRestaurant/OrderInCartByRestaurant.provider'
import { ProdItemMenuList } from '../../components/ProdItem'
import { Grid } from '@material-ui/core'
import { s, colors, globals } from '../../../style'

import { Element as ElementScroll } from 'react-scroll'

const RestaurantMenuSections = ({ menuSections, measuredRef }) => {
  const {
    order,
    restaurantsOrderInCartId,
    handleAddProd,
    handleDeleteProduct,
  } = useOrderInCartByRestaurant()

  if (!menuSections.length) return null

  // console.log(order)

  return (
    <div ref={measuredRef} css={sOuter}>
      {menuSections.map((section, sectionId) => (
        <ElementScroll name={encodeURIComponent(section.name)} key={sectionId}>
          <div
            key={sectionId}
            css={{ paddingTop: '3rem', [s.md]: { paddingTop: '4rem' } }}>
            <p
              css={{
                fontSize: '1.5rem',
                fontWeight: 800,
                whiteSpace: 'nowrap',
                letterSpacing: '-0.05em',
                paddingLeft: '1rem',
                marginBottom: '1.5rem',
                [s.xs]: { fontSize: '1.5rem' },
                [s.md]: { paddingLeft: '2rem' },
              }}>
              {section.name}
            </p>
            <Grid container>
              {section.products
                .filter((product) => !product.isArchived)
                .map((product, productId) => {
                  const onDeleteProduct = () =>
                    handleDeleteProduct({
                      orderId: restaurantsOrderInCartId,
                      prodId: product._id,
                    })

                  const onAddProduct = (e) =>
                    handleAddProd(
                      e,
                      product._id,
                      {
                        _id: product._id,
                        price: product.price,
                        name: product.name,
                        photoUrl: product.photoUrl,
                      },
                      {
                        _id: product.restaurant._id,
                        name: product.restaurant.name,
                        avatarUrl: product.restaurant.avatarUrl,
                        address: product.restaurant.address,
                      }
                    )

                  return (
                    <ProdItemMenuList
                      id={productId}
                      key={productId}
                      data={product}
                      handleAddProduct={onAddProduct}
                      handleDeleteProduct={onDeleteProduct}
                      productInOrder={order?.orderDetails[0].productsInCart.find(
                        (orderProd) => orderProd.prodId._id === product._id
                      )}
                    />
                  )
                })}
            </Grid>
          </div>
        </ElementScroll>
      ))}
    </div>
  )
}

export const sOuter = {
  width: '100vw',
  maxWidth: globals.maxWidthMedium,
  margin: '0 auto',
  padding: '0 1.5rem',
  paddingTop: '1.5rem',
  [s.md]: {
    paddingTop: '4rem',
  },
}

export default RestaurantMenuSections
