
/**
 * 
 * @param {Array} status Array of statuses [{name: String, ...}]
 * @returns {String}
 */
function getStatusName(status) {
  const s = status
  return s[s.length - 1].name
}

export default getStatusName