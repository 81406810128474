/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import { Grid } from '@material-ui/core'
import { SectionHeadline } from '../../../../components/Elements'
import { sDescription, sGrid } from '../../../../style/style'
import OpenHoursTable from '../../../components/OpenHoursTable'
import { useProductContext } from '../context/product.provider'

const OpenHours = () => {
  const { availableHours, handleAvailableHours } = useProductContext()
  return (
    <Grid container css={sGrid}>
      <SectionHeadline noPaddingLeft>Godziny Otwarcia</SectionHeadline>
      <p css={[sDescription, { marginBottom: '1rem' }]}>
        Określ godziny dostępności Produktu. Zakres 0:00 - 24:00 oznacza, że
        produkt jest dostępny w pełnym zakresie działania Restauracji. Zakres
        0:00 - 0:00 oznacza, że tego dnia produkt jest niedostępny.
      </p>
      <OpenHoursTable setter={handleAvailableHours} getter={availableHours} />
    </Grid>
  )
}

export default OpenHours
