/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import ProductItem from './ProductItem'


export default ({ products }) => {

  return (
    <div css={{
      display: 'flex',
      flexWrap: 'wrap'
    }}>
      {products?.map(product => <ProductItem key={product._id} {...product} />)}
    </div>
  )
}