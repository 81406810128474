import { gql } from '@apollo/client'

export const GET_RESTAURANT = gql`
  query ezcRestaurant($restId: ID!) {
    ezcRestaurant(restId: $restId) {
      _id
      name
      avatarUrl
      photoUrl
      address
      description
      categories {
        name
        type
      }
      isActive
    }
  }
`
