import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { useGetAdminAllActiveChats } from '../../../hooks/useChat.queries'
import { useAdminChatMutations } from '../../../hooks/useChat.mutations'
import { SUBS_ADMIN_CHAT_ADMIN } from '../../../graphql/chat.graphql'

const useAdminChatAdmin = ({
  newMssg,
  setNewMssg,
  scrollToBottom,
  setMssgDiv,
  userId,
}) => {
  const [activeChat, setActiveChat] = useState('')
  const { data, error, subscribeToMore, refetch } = useGetAdminAllActiveChats()

  const {
    onAddMssgAdminChat,
    onReadMssgsAdminChat,
    onFinishAdminChat,
  } = useAdminChatMutations()

  useEffect(() => {
    refetch()
  }, [])
  useEffect(() => {
    scrollToBottom()
  }, [data, activeChat])

  useEffect(() => {
    const isNoActiveChatInState = activeChat.length === 0
    const isActiveChatOnServer =
      data && data.adminAllActiveChats && data.adminAllActiveChats[0]

    if (isNoActiveChatInState && isActiveChatOnServer) {
      setActiveChat(data.adminAllActiveChats[0]._id)
    }

    const isNoActiveChatOnServer =
      data && data.adminAllActiveChats && data.adminAllActiveChats.length === 0

    if (isNoActiveChatOnServer) {
      setActiveChat('')
    }
  }, [data])

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: SUBS_ADMIN_CHAT_ADMIN,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const newMssg = subscriptionData.data.adminChatAdmin
        const mssgOwner = newMssg.fromUserId

        // if newMssg is from admin
        if (userId === mssgOwner) return addMssgToActiveChat()
        function addMssgToActiveChat() {
          return {
            // Find mssg in adminAllActiveChats & add newMssg to it.
            adminAllActiveChats: prev.adminAllActiveChats.map((el) =>
              el._id === activeChat
                ? { ...el, messages: [...el.messages, newMssg] }
                : el
            ),
          }
        }

        // if newMssg is from client AND chat is new.
        const isNewChat = !prev.adminAllActiveChats.find(
          (el) => el.userId === mssgOwner
        )
        if (isNewChat) return addNewChatWithNewMssg()
        function addNewChatWithNewMssg() {
          return {
            adminAllActiveChats: [
              ...prev.adminAllActiveChats,
              { userId: mssgOwner, messages: [newMssg] },
            ],
          }
        }

        // if newMssg is from client AND chat is already exists.
        return {
          adminAllActiveChats: prev.adminAllActiveChats.map((el) =>
            el.userId === mssgOwner
              ? { ...el, messages: [...el.messages, newMssg] }
              : el
          ),
        }
      },
    })

    return () => unsubscribe()
  }, [activeChat])

  const setChat = (id) => setActiveChat(id)

  const onAdminFinishChat = () => {
    onFinishAdminChat({ chatId: activeChat }, changeActiveChatId)

    function changeActiveChatId() {
      setMssgDiv('')
      toast.warning('Czat został zamknięty')
      const id = data.adminAllActiveChats.length
        ? data.adminAllActiveChats[0]._id
        : ''
      const wasIdOfAnActiveChat = activeChat === id
      if (wasIdOfAnActiveChat) {
        const idSecond =
          data.adminAllActiveChats.length > 1
            ? data.adminAllActiveChats[1]._id
            : ''
        setChat(idSecond)
      } else {
        setChat(id)
      }
    }
  }

  const onSend = () => {
    if (newMssg.length === 0) return

    onAddMssgAdminChat(
      {
        chatId: activeChat,
        message: newMssg,
      },
      () => {
        setNewMssg('')
        scrollToBottom()
      }
    )
  }

  const onReadMssgs = () => {
    const chat = data.adminAllActiveChats.find((el) => el._id === activeChat)
    const isUnreadMssgs = chat.messages.find(
      (mssg) => mssg.fromUserId !== userId && !mssg.isRead
    )

    if (!isUnreadMssgs) return

    onReadMssgsAdminChat({ chatId: activeChat })
  }

  return {
    data,
    error,
    activeChat,
    setChat,

    onSend,
    onReadMssgs,
    onAdminFinishChat,
  }
}

export default useAdminChatAdmin
