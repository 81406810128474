/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core'
import {
  Collapsable,
  Bullet,
  Button,
  Row,
} from '../../../../components/Elements'
import { Link } from 'react-router-dom'
import { CollapsableText } from '../../../../components/Elements/Collapsable/Collapsable'
import { colors, alpha, s } from '../../../../style'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import MoneyEz from '../../../../helpers/MoneyEz'
import usePayment from '../../../views/Payment/hooks/usePayment'

const OrderNeedPayment = ({
  orderId,
  defaultValue,
  userCoinsBalance,
  priceSum,
  paidSum,
}) => {
  const { amount, isLoading, onSubmit, onPayFromWallet } = usePayment({
    defaultValue,
    orderId,
  })

  return (
    <Grid
      item
      xs={12}
      css={{
        [s.xs]: { paddingBottom: '1rem' },
        [s.sm]: { paddingBottom: '1.5rem' },
      }}>
      <Collapsable
        // openAlready
        noPadding
        oneLiner
        is12
        title={'Opłata Zamówienia'}
        icon={<MonetizationOnIcon />}
        description={
          <>
            <span css={{ color: colors.text, fontWeight: 800 }}>
              {(priceSum - paidSum).toFixed(2)} zł
            </span>
            &nbsp;zł
            {amount > 0 && (
              <>
                <Bullet />
                <span css={{ color: colors.red, fontWeight: 800 }}>
                  {amount.toFixed(2)}
                </span>
                &nbsp;zł brakujących środków
              </>
            )}
            <Bullet />
            <span css={{ color: colors.text, fontWeight: 800 }}>
              {userCoinsBalance.toFixed(2)}
            </span>
            &nbsp;zł dostępnych środków
          </>
        }
        sIconCss={{
          color: colors.red,
          backgroundColor: colors.red.concat(alpha[16]),
        }}>
        <CollapsableText>
          <p>
            Zamówienie należy{' '}
            <strong css={{ color: colors.text }}>
              opłacić w ciągu 20 minut
            </strong>
            . Po tym czasie nowe zamówienie lub domówione produkty zostaną
            automatycznie anulowane
          </p>
        </CollapsableText>
        <Row desc={'Opłata'} icon={<MonetizationOnIcon fontSize='inherit' />}>
          {(priceSum - paidSum).toFixed(2)} zł
        </Row>
        {amount > 0 && (
          <Row
            desc={'Brakująca Kwota'}
            icon={<MoneyOffIcon fontSize='inherit' />}
            extraCss={styleNotEnough}>
            {amount.toFixed(2)} zł
          </Row>
        )}
        <Row
          desc={'Portfel'}
          icon={<AccountBalanceWalletIcon fontSize='inherit' />}
          // extraCss={userCoinsBalance < amount && styleNotEnough}
        >
          {userCoinsBalance.toFixed(2)} zł
        </Row>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            [s.xs]: {
              padding: '1.5rem 1.5rem 1.5rem',
            },
            [s.sm]: {
              padding: '1.5rem 1.5rem 1.5rem',
            },
            [s.md]: {
              padding: '2rem 2rem 2rem',
            },
          }}>
          <div
            css={{
              display: 'flex',
              [s.xs]: {
                flexDirection: 'column',
                gap: '1rem',
              },
              [s.sm]: {
                flexDirection: 'column',
                gap: '1.5rem',
              },
              [s.md]: {
                gap: '2rem',
                flexDirection: 'row',
              },
            }}>
            <Button
              w100
              action={defaultValue > 0 ? onSubmit : onPayFromWallet}
              loading={isLoading}>
              {defaultValue > 0
                ? `Opłać Zamówienie - ${amount.toFixed(2)} zł`
                : 'Opłać Zamówienie'}
            </Button>
            <Button
              w100
              dimmed={userCoinsBalance >= amount}
              link='/account/wallet'>
              Doładuj Konto
            </Button>
          </div>
          <p
            css={{
              paddingTop: '1.5rem',
              fontSize: '0.75rem',
              color: colors.text.concat(alpha[60]),
            }}>
            Przechodząc do bramki płatniczej, potwierdzasz zapoznanie się z{' '}
            <Link
              to='/terms'
              css={{
                color: colors.text,
                fontWeight: 800,
                textDecoration: 'underline',
              }}>
              Regulaminem
            </Link>
          </p>
        </div>
      </Collapsable>
    </Grid>
  )
}

const styleNotEnough = {
  backgroundColor: colors.red.concat(alpha[16]),
  color: colors.red,
  '.row-desc': {
    color: colors.red,
  },
  boxShadow: `0px 0px 0px 1px ${colors.red.concat(alpha[30])}`,
  border: 'none',
  '&:hover': {
    [s.hover]: { backgroundColor: colors.red.concat(alpha[24]) },
  },
}

export default OrderNeedPayment
