import { gql } from '@apollo/client'

export const GET_RESTAURANT_AND_CART_ORDER = gql`
  query ezcRestaurant($restId: ID!, $geoInput: GeolocationInput) {
    ezcOrdersInCartByRest(restId: $restId) {
      _id
      orderDetails {
        productsInCart {
          _id
          prodId {
            _id
            name
            price
            photoUrl
          }
          amount
        }
      }
    }

    ezcRestaurant(restId: $restId, geoInput: $geoInput) {
      name
      address
      description
      photoUrl
      avatarUrl
      location {
        coordinates
      }
      distCalculated
      consumptionTypes
      categories {
        _id
        name
        type
      }
      menuSections {
        _id
        name
        products {
          _id
          restaurant {
            _id
            name
            avatarUrl
            address
          }
          name
          photoUrl
          price
          description
          ingredients {
            _id
            name
          }
          isArchived
        }
      }
      openningHours {
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
        sun {
          open
          close
        }
      }
      isActive
    }
  }
`

export const GET_RESTAURANTS = gql`
  query ezcRestaurants($geoInput: GeolocationInput) {
    ezcRestaurants(geoInput: $geoInput) {
      _id
      name
      address
      avatarUrl
      photoUrl
      categories {
        _id
        name
        type
      }
      distCalculated
    }
  }
`

export const GET_RESTAURANT_MENU = gql`
  query ezcRestaurant($restId: ID!) {
    ezcRestaurant(restId: $restId) {
      name
      avatarUrl
      menuSections {
        _id
        name
        products {
          _id
          restaurant {
            _id
            name
            avatarUrl
            address
          }
          name
          photoUrl
          price
          description
          ingredients {
            _id
            name
          }
          isArchived
        }
      }
    }
  }
`

export const GET_RESTAURANT_CONSUMPTION_TYPES = gql`
  query ezcRestaurant($restId: ID!) {
    ezcRestaurant(restId: $restId) {
      consumptionTypes
    }
  }
`
