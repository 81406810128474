import React, { useEffect } from 'react'

import { useGetDeclinedOrderMssgs } from '../../../hooks/useGraphql.hook'
import useChat from './hooks/useChat'
import {
  ChatContainer,
  ChatWindow,
  Messages,
  MessagesContainer,
  HeaderInfo,
} from './components'

const DeclinedOrderChat = ({ orderId, name, avatarUrl, onCloseChat }) => {
  const { data, error, loading } = useGetDeclinedOrderMssgs(orderId)
  const { measuredRef, scrollToBottom, errorHandling, userId } = useChat()

  useEffect(() => {
    scrollToBottom()
  }, [])

  if (error) {
    errorHandling(error)
    onCloseChat()
    return null
  }
  if (loading) return <div />
  if (!data) {
    onCloseChat()
    return null
  }

  return (
    <ChatContainer>
      <ChatWindow>
        <HeaderInfo
          name={name}
          avatarUrl={avatarUrl}
          onCloseChat={onCloseChat}
        />

        <MessagesContainer measuredRef={measuredRef}>
          <Messages userId={userId} messages={data.declinedOrderMessages} />
        </MessagesContainer>
      </ChatWindow>
    </ChatContainer>
  )
}

export default DeclinedOrderChat
