import React from 'react'
export default (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 32 32'>
    <path
      d='M16.258,32c-.555,0-1.114-.028-1.662-.083s-1.084-.136-1.614-.242-1.048-.238-1.558-.394-1.007-.335-1.494-.538-.96-.427-1.421-.674-.908-.514-1.34-.8-.851-.6-1.252-.921-.788-.672-1.155-1.033-.72-.743-1.049-1.136-.645-.809-.936-1.232-.566-.869-.814-1.319-.481-.924-.685-1.4-.39-.974-.547-1.47a15.7,15.7,0,0,1-.647-3.122,15.983,15.983,0,0,1,0-3.272,15.7,15.7,0,0,1,.647-3.122c.157-.5.341-.991.547-1.47s.434-.945.685-1.4.522-.894.814-1.319.605-.838.936-1.232S4.4,5.047,4.762,4.686s.755-.708,1.155-1.033.822-.635,1.252-.921.883-.557,1.34-.8.939-.473,1.421-.674.989-.384,1.494-.538S12.458.431,12.981.325,14.055.137,14.6.083,15.7,0,16.258,0a16.456,16.456,0,0,1,5.4.9c.419.145.837.309,1.242.488s.8.374,1.189.583A16.289,16.289,0,0,1,28.22,5.164,16.156,16.156,0,0,1,29.841,7.2c.242.361.472.736.683,1.115s.411.775.591,1.172.347.811.494,1.224.28.844.392,1.271l-20.164,9.25c1.525,3.219,5.095,5.218,9.318,5.218a14.008,14.008,0,0,0,3-.331,14.982,14.982,0,0,0,3.021-1.016l.082-.038a14.5,14.5,0,0,0,3.885-2.631,15.958,15.958,0,0,1-2.453,3.87,16.293,16.293,0,0,1-7.657,4.99A16.508,16.508,0,0,1,16.258,32ZM15.669,7.753a6.969,6.969,0,0,0-1.49.163,7.371,7.371,0,0,0-1.492.5A6.638,6.638,0,0,0,9.17,12.074,4.978,4.978,0,0,0,8.86,13.9a4.415,4.415,0,0,0,.425,1.8l11.257-5.166A5.225,5.225,0,0,0,15.669,7.753Z'
      fill='#343340'
    />
  </svg>
)
