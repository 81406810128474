/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import { useWaiterAuthContext } from '../../../context/auth-waiter.context'


export default ({ list }) => {
  const { waiterLogout } = useWaiterAuthContext()

  return (
    <List>
      {list?.map((text, index) => (
        <ListItem button key={text}>
          <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      ))}
      <ListItem button component={Link} to="/">
        <ListItemIcon><CallMissedOutgoingIcon /></ListItemIcon>
        <ListItemText primary="Panel Klienta" />
      </ListItem>
      <ListItem button onClick={waiterLogout}>
        <ListItemIcon><ExitToAppIcon /></ListItemIcon>
        <ListItemText primary="Wyloguj się" />
      </ListItem>
    </List>
  )
}