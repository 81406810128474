/** @jsx jsx */
import _, { sortBy } from 'lodash'
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { ButtonBorder, Button } from '../../../components/Elements'
import Headline from '../../../components/Headline/Headline'
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import {
  sGrid,
  sOuter,
  sNoMarginTop,
  sNoPaddingTop,
  sDescription,
  sPaddingTop,
} from '../../../style/style'
import { useActiveRestaurantContext } from '../../context/active-restaurant.provider'
import { GET_RESTAURANT } from '../../graphql/restaurants.graphql'
import { useQuery } from '@apollo/client'
import {
  sRestSkeleton,
  sRestSkeletonInner,
} from '../../../EZW/pages/Home/HomePage'
import { RestContainerWithSaldo } from '../Home/HomePage'
import { useGetPaymentsDetails } from '../../hooks/useQueries'
import SkeletonOrder from '../../../components/SkeletonOrder/SkeletonOrder'
import {
  Grid,
  Hidden,
  Collapse,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core'
import Transaction from './Transaction'
import { colors, s } from '../../../style'
import WidgetContainer from '../../../components/Widget/Widget.component'
import { sGridOverride, sHeadline } from '../../../EZW/pages/Popups/styles'
import { Input } from '../../../EZC/views/Auth/components'
import useCsv from '../../hooks/useCsv.mutations'
import ChartSales from './ChartSales'
import ChartOrders from './ChartOrders'
import BottomSpacing from '../../../components/BottomSpacing/BottomSpacing'

const PaymentsPage = () => {
  const date = new Date()
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 2)
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1)

  const [sortKey, setSortKey] = useState('createdAt')
  const [sortDir, setSortDir] = useState('asc')
  const [sortOpen, setSortOpen] = useState(false)

  const { getTransactionsCsv } = useCsv()

  const [startDate, setStartDate] = useState(
    firstDay.toISOString().slice(0, 10)
  )
  const [endDate, setEndDate] = useState(lastDay.toISOString().slice(0, 10))
  const [raportOpen, setRaportOpen] = useState(false)
  const { activeRestaurantId } = useActiveRestaurantContext()
  const {
    data: dataRestaurant,
    loading: loadingRestaurant,
    error: errorRestaurant,
  } = useQuery(GET_RESTAURANT, {
    variables: { restId: activeRestaurantId },
    skip: !activeRestaurantId,
  })

  const { data, loading, error } = useGetPaymentsDetails()

  const onDownload = () => {
    getTransactionsCsv({
      startDate, //: new Date(startDate),
      endDate, //: new Date(endDate),
    })
  }

  const handleSort = (event) => {
    setSortKey(event.target.value)
    setSortOpen(false)
  }

  const handleSortDir = (event) => {
    setSortDir(event.target.value)
    setSortOpen(false)
  }

  const transactionsSorted = _.orderBy(
    data?.ezrTransactions,
    [sortKey],
    [sortDir === 'asc' ? 'asc' : 'desc']
  )
  const transactions = sortDir
    ? _.reverse(transactionsSorted)
    : transactionsSorted

  return (
    <React.Fragment>
      <TopNav title={'Transakcje'} offset={60} />
      <div css={sOuter}>
        <Headline
          itemChildren={
            <div css={{ display: 'flex' }}>
              <ButtonBorder action={() => setSortOpen(!sortOpen)}>
                Sortuj
              </ButtonBorder>
              <Hidden smDown>
                <div css={{ padding: '0 0.5rem' }} />
                <ButtonBorder action={() => setRaportOpen(!raportOpen)}>
                  Raport CSV
                </ButtonBorder>
              </Hidden>
            </div>
          }>
          Transakcje
        </Headline>
      </div>
      {dataRestaurant ? (
        <RestContainerWithSaldo
          dataRestaurant={dataRestaurant}
          saldoChildren={
            <ButtonBorder action={() => setRaportOpen(!raportOpen)}>
              CSV
            </ButtonBorder>
          }
        />
      ) : (
        <div css={sRestSkeleton}>
          <div css={sRestSkeletonInner} />
        </div>
      )}
      <div css={[sOuter, sNoMarginTop, sNoPaddingTop]}>
        <Grid
          container
          css={[
            sGrid,
            {
              [s.sm_down]: { marginTop: '1.5rem' },
              [s.md]: { marginTop: '1.5rem' },
            },
          ]}
          justify='space-between'>
          {loading ? (
            <>
              <SkeletonOrder />
              <SkeletonOrder />
              <SkeletonOrder />
              <SkeletonOrder />
            </>
          ) : (
            transactions.map((data, id) => (
              <Transaction id={id} data={data} key={id} />
            ))
          )}
        </Grid>
        <Grid
          container
          css={[
            sGrid,
            {
              [s.sm_down]: { marginTop: '1.5rem' },
              [s.md]: { marginTop: '1.5rem' },
            },
          ]}>
          <ChartSales />
          <ChartOrders />
        </Grid>
      </div>
      <WidgetContainer isWidgetOpen={sortOpen} setIsWidgetOpen={setSortOpen}>
        <Collapse in={sortOpen}>
          <Headline extraCss={sHeadline}>Sortowanie</Headline>
          <div css={[sGrid, sGridOverride]}>
            <p css={[sDescription, { fontSize: '0.875rem' }]}>
              Wybierz właściwość, względem której lista transakcji zostanie
              posortowana. Zaznacz kierunek rosnący lub malejący
            </p>
          </div>
          <Grid
            container
            css={[
              sGrid,
              sGridOverride,
              {
                justifyContent: 'space-between',
                paddingTop: '1rem',
                [s.xs]: { paddingBottom: '4rem' },
              },
            ]}>
            <Grid item xs={6}>
              <RadioGroup
                aria-label='sort key'
                name='sort key'
                value={sortKey}
                onChange={handleSort}
                css={sRadio}>
                <FormControlLabel
                  value='createdAt'
                  control={<Radio />}
                  label='Data'
                />
                <FormControlLabel
                  value='coinsAmount'
                  control={<Radio />}
                  label='Kwota'
                />
                <FormControlLabel
                  value='fromUser.personal.name'
                  control={<Radio />}
                  label='Klient'
                />
                <FormControlLabel
                  value='type'
                  control={<Radio />}
                  label='Typ'
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={6}>
              <RadioGroup
                aria-label='sort direction'
                name='sort direction'
                value={sortDir}
                onChange={handleSortDir}
                css={sRadio}>
                <FormControlLabel
                  value='asc'
                  control={<Radio />}
                  label='Malejąco'
                />
                <FormControlLabel
                  value='desc'
                  control={<Radio />}
                  label='Rosnąco'
                />
              </RadioGroup>
            </Grid>
          </Grid>
          {/* <BottomSpacing /> */}
        </Collapse>
      </WidgetContainer>

      <WidgetContainer
        isWidgetOpen={raportOpen}
        setIsWidgetOpen={setRaportOpen}>
        <Collapse in={raportOpen}>
          <Headline extraCss={sHeadline}>Raport CSV</Headline>
          <div css={[sGrid, sGridOverride]}>
            <p css={[sDescription]}>
              Wybierz zakres daty z jakich pobrać transakcje, a następnie
              wygeneruj dokument w formacie .csv
            </p>
          </div>
          <Grid
            container
            css={[
              sGrid,
              sGridOverride,
              { justifyContent: 'space-between', paddingTop: '1rem' },
            ]}>
            <Grid item xs={5}>
              <Input
                darker
                type='date'
                label='Od'
                pattern='\d{1,2}/\d{1,2}/\d{4}'
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
            </Grid>
            <Grid item xs={5}>
              <Input
                darker
                type='date'
                label='Od'
                pattern='\d{1,2}/\d{1,2}/\d{4}'
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />
            </Grid>
            <Grid item xs={12}>
              <div css={{ padding: '0 0 2rem' }}>
                <Button w100 w100Mobile w100Dektop action={onDownload}>
                  Generuj
                </Button>
              </div>
            </Grid>
          </Grid>
        </Collapse>
      </WidgetContainer>
    </React.Fragment>
  )
}

const sRadio = {
  '.MuiRadio-colorSecondary.Mui-checked': {
    color: colors.orange,
  },
  '.MuiTypography-body1': {
    fontWeight: 500,
    letterSpacing: '-0.025em',
    color: colors.text,
  },
}

export default PaymentsPage
