/** @jsx jsx */
import React, { useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import LinearProgress from '@material-ui/core/LinearProgress';

import { useError } from '../../../../hooks/errors.hook'
import { useGetDeclinedOrders } from '../../../hooks/useQueries'
import useOrdersPagination from '../hooks/useOrdersPagination'
import OrderListLoadMore from '../components/OrderListLoadMore'



export default ({
  startDate,
  endDate
}) => {

  const { errorHandling } = useError()

  const { data, error, loading, refetch, fetchMore } = useGetDeclinedOrders({
    startDate,
    endDate
  })

  const {
    orders,
    onLoadMore,
    isLoading,
    noMoreToFetch

  } = useOrdersPagination({
    dataOrders: data?.ezrOrdersDeclined,
    fetchMore,
    startDate,
    endDate
  })

  useEffect(() => { refetch() }, [])

  if (loading) return <LinearProgress />
  if (error) {
    errorHandling(error)
    return null
  }

  if (!orders?.length) {
    return <p>Brak odrzuconych zamówień.</p>
  }

  return (
    <OrderListLoadMore
      noMoreToFetch={noMoreToFetch}
      isLoading={isLoading}
      orders={orders}
      onLoadMore={onLoadMore}
    />
  )
}