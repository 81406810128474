import EzcContainer from './_container/ezc-container'

import Account from './account/Account.page'
import EzChat from './account/ez-chat/ez-chat.page'
import EzChatAdmin from './account/ez-chat-admin/ez-chat-admin.page'
import Transactions from './account/transactions/Transactions.page'

import Notifications from './account/notifications/Notifications.page'
import Wallet from './account/wallet/Wallet.page'
import Info from './account/info/Info.page'
import Settings from './account/settings/Settings.page'

import Cart from './cart/cart.page'
import CartCheckout from './cart/cart-order-checkout.page'

import EzcIndex from './ezc-index/ezc-index.page'
import Login from './login/login.page'
import NewPassword from './new-password/new-password.page'

import OrderActive from './orders/order-active.page'
import OrderActiveMenu from './orders/order-active-menu.page'
import Orders from './orders/orders.page'
import OrdersChat from './orders-chat/orders-chat.page'
// import OrdersFinished from './orders-finished/OrdersFinished.page'
import OrdersHistory from './orders-history/OrdersHistory.page'
import OrderHistory from './order-history/OrderHistory.page'

import Product from './product/product.page'
import Restaurant from './restaurant/restaurant.page'
import ResetPassword from './reset-password/reset-password.page'

import Search from '../views/Search/Search'
import SearchResults from '../views/Search/SearchResults'
import Signup from './signup/signup.page'

import UserConfirmEmail from './user-confirm-email/user-confirm-email.page'

import Terms from './subpages/Terms.page'
import Privacy from './subpages/Privacy.page'

const mainRoutes = [
  {
    path: '/',
    component: EzcIndex,
    exact: true,
  },
  {
    path: '/restaurant/:id',
    component: Restaurant,
  },
  {
    path: '/product/:id',
    component: Product,
  },
  {
    path: '/cart',
    component: Cart,
    exact: true,
  },
  {
    path: '/cart-checkout/:id',
    component: CartCheckout,
  },
  {
    path: '/orders',
    component: Orders,
    exact: true,
  },
  {
    path: '/orders/:id',
    component: Orders,
  },
  {
    path: '/order-active/:id',
    component: OrderActive,
  },
  {
    path: '/order-active-menu',
    component: OrderActiveMenu,
  },
  {
    path: '/orders-history',
    component: OrdersHistory,
    exact: true,
  },
  {
    path: '/orders-history/:id',
    component: OrderHistory,
  },
  // soon deprecated
  // {
  //   path: '/orders-finished',
  //   component: OrdersFinished,
  //   exact: true,
  // },
  {
    path: '/terms',
    component: Terms,
    exact: true,
  },
  {
    path: '/privacy',
    component: Privacy,
    exact: true,
  },
]

const searchRoutes = [
  {
    path: '/search',
    component: Search,
    exact: true,
  },
  {
    path: '/search/results',
    component: SearchResults,
    exact: true,
  },
]

const authRoutes = [
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/signup',
    component: Signup,
    exact: true,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    exact: true,
  },
  {
    path: '/new-password/:token',
    component: NewPassword,
  },
  {
    path: '/user-confirm-email/:token',
    component: UserConfirmEmail,
  },
]

const authOnlyRoutes = [
  {
    path: '/account',
    component: Account,
    authOnly: true,
    exact: true,
  },
  {
    path: '/account/ez-chat',
    component: EzChat,
    authOnly: true,
    exact: true,
  },
  {
    path: '/account/ez-chat-admin',
    component: EzChatAdmin,
    authOnly: true,
    exact: true,
  },
  {
    path: '/account/transactions',
    component: Transactions,
    authOnly: true,
    exact: true,
  },
  {
    path: '/account/notifications',
    component: Notifications,
    authOnly: true,
    exact: true,
  },
  {
    path: '/account/wallet',
    component: Wallet,
    authOnly: true,
    exact: true,
  },
  {
    path: '/account/info',
    component: Info,
    authOnly: true,
    exact: true,
  },
  {
    path: '/account/settings',
    component: Settings,
    authOnly: true,
    exact: true,
  },
  {
    path: '/orders-chat/:id',
    component: OrdersChat,
    authOnly: true,
    exact: true,
  },
]

const ezcRoutes = [
  ...mainRoutes,
  ...searchRoutes,
  ...authRoutes,
  ...authOnlyRoutes,
]

export { EzcContainer, ezcRoutes }
