/** @jsx jsx */
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed'
import { sDescription, sGrid, sOuter } from '../../../style/style'
import Headline from '../../../components/Headline/Headline'
import { ButtonBorder } from '../../../components/Elements'
import { Grid, Hidden } from '@material-ui/core'
import { s } from '../../../style'
import { useOrdersContext } from '../../context/orders.context'
import ActiveOrderItem from '../../../EZC/pages/orders/components/ActiveOrderItem'
import SkeletonOrder from '../../../components/SkeletonOrder/SkeletonOrder'

const OrdersPage = () => {
  const { attentionOrders } = useOrdersContext()

  const orders = attentionOrders
    .sort((a, b) => {
      const dateA = new Date(a.consumptionDate + ' ' + a.consumptionTime)
      const dateB = new Date(b.consumptionDate + ' ' + b.consumptionTime)
      return dateA - dateB
    })
    .sort((a, b) =>
      a.isPaid && !b.isPaid ? -1 : !a.isPaid && b.isPaid ? 1 : 0
    )

  // console.log(orders)
  // console.log(attentionOrders)

  return (
    <React.Fragment>
      <TopNav title={'Nowe Zamówienia'} offset={60} />
      <div css={sOuter}>
        <Headline
        // itemChildren={
        //   <ButtonBorder link='/waiter/order'>Zamówienia</ButtonBorder>
        // }
        >
          Nowe Zamówienia
        </Headline>
        <Grid container css={sGrid}>
          {!orders ||
            (orders.length === 0 && (
              <>
                {/* <SkeletonOrder />
                <SkeletonOrder /> */}
                <p
                  css={[
                    sDescription,
                    { animation: 'fadeIn 1s ease-out 0s both alternate' },
                  ]}>
                  Brak zamówień wymagających uwagi
                </p>
              </>
            ))}

          {orders?.length > 0 &&
            orders.map((order, id) => (
              <ActiveOrderItem
                id={id}
                key={order._id}
                orderId={order._id}
                date={order.consumptionDate}
                time={order.consumptionTime}
                consumptionType={order.consumptionType}
                restaurantAvatar={order.ownerId.personal.avatarUrl}
                shortId={order.shortId}
                isInvoice={order.isInvoice}
                restaurantName={`${order.ownerId.personal.name} ${
                  order.ownerId.personal.surname
                    ? order.ownerId.personal.surname
                    : ''
                }
                `}
                priceSum={order.priceSum}
                paidSum={order.paidSum}
                isPaid={order.isPaid}
                status={[...order.status].pop().name}
                waiter
                needAttention={
                  attentionOrders.find((a) => a._id === order._id) ||
                  order.messages.find(
                    (message) => message.fromUserId && !message.isRead
                  )
                }
              />
            ))}
          <Grid item xs={12} css={sButtons}>
            <ButtonBorder link={'/waiter/orders'}>Zamówienia</ButtonBorder>
            {/* <ButtonBorder link={'/waiter/history'}>Historia</ButtonBorder> */}
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}
const sButtons = {
  display: 'flex',
  [s.sm_down]: {
    paddingTop: '0.5rem',
  },
  [s.md]: {
    paddingTop: '1.5rem',
  },
}

export default OrdersPage
