/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { Logo } from '../../'


export default () => (
  <div css={{
    svg: {
      width: '60%',
      padding: '0 16px'
    }
  }}>
    <Logo />
  </div>
)