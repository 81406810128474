/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars

import Avatar from '@material-ui/core/Avatar';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { krakenUrls } from '../../../constants/s3-files'



export default ({ onChange, preview, imgUrl, header, subheader, name }) => (

  <React.Fragment>
    <input
      accept="image/*"
      css={{ display: 'none' }}
      id={"contained-button-".concat(header)}
      multiple
      type="file"
      name={name}
      onChange={onChange}
    />

    <label htmlFor={"contained-button-".concat(header)}>
      <AddImg
        src={preview || krakenUrls.small + imgUrl}
        header={header}
        subheader={subheader}
      />
    </label>
  </React.Fragment>
)



const AddImg = ({ header, subheader, src, ...otherProps }) => (
  <div css={styles} {...otherProps}>
    <Avatar src={src} css={{ color: 'black', backgroundColor: '#E4E6EB' }} />

    <div css={{ flexGrow: 1, paddingLeft: 16 }}>
      <p>{header}</p>
      <p css={{ fontWeight: 300, fontSize: 12, marginTop: 4 }}>
        {subheader}
      </p>
    </div>

    <ChevronRightIcon css={{ color: '#343340', opacity: '.3', width: 32, height: 32 }} />
  </div>
)

const styles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '16px 0',
  backgroundColor: '#F7F8FA',
  borderRadius: 16,
  padding: 16,
  fontWeight: 800,
  cursor: 'pointer'
}