/** @jsx jsx */
import React, { useEffect } from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import { useGetDeclinedOrder } from '../../hooks/useQueries'
import { useParams, useHistory } from 'react-router-dom'
import OrderView from './OrderView'

const OrderCancelPage = () => {
  const history = useHistory()
  const orderId = useParams().id
  const { data, error, loading } = useGetDeclinedOrder(orderId)

  useEffect(() => {
    if (error) history.push('/waiter/history')
  }, [error, orderId])

  return <OrderView order={data?.ezrOrderDeclined} loading={loading} />
}

export default OrderCancelPage
