import React from 'react'
import { useHistory } from 'react-router-dom'
import { useRoutesEzAdmin } from '../../../routes'
import { useError } from '../../../hooks/errors.hook'
import { Layout } from '../../components'
import { useCheckPermission } from '../../hooks/useUsers.queries'


const EzAdminContainer = () => {
  const history = useHistory()
  const { errorHandling } = useError()
  const routes = useRoutesEzAdmin()
  const { data, loading, error } = useCheckPermission()

  if (loading) return null
  if (error) {
    errorHandling(error)
    history.push('/404')
    return null
  }
  if (!data?.adminCheckPermission) {
    history.push('/404')
    return null
  }

  return <Layout>{routes}</Layout>
}

export default EzAdminContainer