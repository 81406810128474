/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core' // eslint-disable-line no-unused-vars
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'

import { krakenUrls } from '../../../../constants/s3-files'

const HeaderInfo = ({
  name,
  avatarUrl,
  onCloseChat,
  onAdminFinishChat,
  userId,
}) => (
  <div
    css={{
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid #f0f2f5',
      padding: '4px 0',
    }}>
    <Avatar alt={name} src={krakenUrls.small + avatarUrl} />

    <div css={{ flexGrow: 1, paddingLeft: 12 }}>
      <p
        css={{
          fontWeight: 800,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}>
        {name}&nbsp;
        <span
          css={{
            fontSize: 12,
            opacity: 0.3,
            fontWeight: 500,
            fontStyle: 'italic',
          }}>
          {userId}
        </span>
      </p>
    </div>

    {onAdminFinishChat && (
      <Button onClick={onAdminFinishChat}>Zakończ czat</Button>
    )}
    {onCloseChat && (
      <IconButton onClick={onCloseChat}>
        <CloseIcon />
      </IconButton>
    )}
  </div>
)

export default HeaderInfo
