import React from 'react'
import {
  Button,
  ProductListHandle
} from '../../../components'
import { useOrderAttentionContext } from '../context/order-attention.context'
import HeaderCondition from './HeaderCondition'



export default () => {
  const {
    isLoading,
    productsToAccept,
    handleToggleProduct,
    handleDecline,
    handleAccept,
    handleAcceptProducts,
    handleDeclineAllProducts,
    orderWeWorkOn
  } = useOrderAttentionContext()

  const { status } = orderWeWorkOn
  const isNewOrder = status[status.length - 1].name === 'WAITING_FOR_ACCEPT'


  return (
    <>
      <HeaderCondition condition={productsToAccept.length}>
        Produkty do zaakceptowania
      </HeaderCondition>

      <ProductListHandle
        products={productsToAccept}
        onToggleProduct={handleToggleProduct}
      />

      {isNewOrder
        ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAccept}
              disabled={isLoading}
              css={{
                marginRight: 8,
                fontWeight: 700
              }}
            >
              Akceptuj zaznaczone produkty
            </Button>
            <Button
              variant="outlined"
              onClick={handleDecline}
              disabled={isLoading}
            >
              Odrzuć zamówienie
            </Button>
          </>
        )
        : (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAcceptProducts}
              disabled={isLoading}
              css={{
                marginRight: 8,
                fontWeight: 700
              }}
            >
              Akceptuj zaznaczone produkty
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleDeclineAllProducts}
              disabled={isLoading}
              css={{
                marginRight: 8,
                fontWeight: 700
              }}
            >
              Odrzuć wszystkie
            </Button>
          </>
        )
      }
    </>
  )
}